import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  __,
  isMobile,
  wait,
  Event,
  sendNotfication,
  SITE_URL,
  decode,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import Translation from "../Translation";
import FreeCredit from "../../../Components/User/FreeCredit";
import isLogged from "../../../../Auth";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      games: [],
      menus: [
        {
          title: this.props.t("Affiliate"),
          url: "/affiliate",
          icon: "mdi-account-multiple-plus",
        },
        {
          title: this.props.t("Promotion"),
          url: "/promotion",
          icon: "mdi-trophy",
        },
        {
          title: "Daily Contest",
          url: "/daily-contest",
          icon: "mdi-emoticon-cool",
        },
        {
          title: "VIP " + this.props.t("levels"),
          url: "/vip-levels",
          icon: "mdi-emoticon-cool",
        },
        {
          title: "Tournament",
          url: "/tournament",
          icon: "mdi-assistant",
        },
      ],
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this.state.games.length === 0) socket.emit(C.GAMES);

    wait(500).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);

    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    socket.off(C.GAMES);
  }

  handleResize() {
    if (isMobile()) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }

  render() {
    return (
      <>
        {this.state.show && (
          <ul className="list-unstyled topbar-nav mb-0 menu-items">
            <MobileDrawer
              menus={this.state.menus}
              games={this.state.games}
              t={this.props.t}
            />
          </ul>
        )}
      </>
    );
  }
}

function MobileDrawer(props) {
  const [drawer, setDrawer] = React.useState(false);
  const [subCat, setSubCat] = React.useState(0);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const setOfferwal = () => {
    if (isLogged()) {
      Event.emit("offerwall");
      setDrawer(false);
    } else {
      sendNotfication(props.t("you_must_log_in_first"));
    }
  };

  const anchor = "left";
  const { t, menus } = props;

  const menuLinks = menus.map((menu, i) => (
    <>
      <Link
        to={menu.url}
        className="b-subtitle"
        onClick={() => setDrawer(false)}
      >
        <i className={"mdi font-15 " + menu.icon}></i>
        {menu.title}
      </Link>
    </>
  ));

  const openOffer = (e) => {
    Event.emit("clear_search");
    return this.state.isLogged
      ? Event.emit("offerwall")
      : Event.emit("register_form");
  };

  const showCat = (n) => {
    setSubCat(subCat === n ? 0 : n);
  };

  return (
    <>
      <React.Fragment key={anchor}>
        {/* <li className={'nav-link ml-0 mobile-header-menu-btn'} onClick={toggleDrawer(anchor, true)}> */}
        <li
          className={"nav-link ml-0 mobile-header-menu-btn"}
          onClick={toggleDrawer(true)}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h48v48H0z" fill="none" />
            <g id="Shopicon">
              <rect
                x="8.036"
                y="12"
                width="31.964"
                height="4"
                style={{ fill: "white" }}
              />
              <rect
                x="8.036"
                y="22"
                width="31.964"
                height="4"
                style={{ fill: "white" }}
              />
              <rect
                x="8.036"
                y="32"
                width="31.964"
                height="4"
                style={{ fill: "white" }}
              />
            </g>
          </svg>
        </li>
        <Drawer
          anchor={anchor}
          open={drawer}
          onClose={toggleDrawer(false)}
          PaperProps={{ style: { width: "100%", maxWidth: "380px" } }}
          className="mobile-menu-drawer"
        >
          <div className="menu-body m-0 mobilemenusection">
            <button
              className={"btn menu-room-back-btn ms-2"}
              onClick={() => setDrawer(false)}
            >
              <CloseIcon />
            </button>
            <div className="menu-pane d-flex flex-column">
              <img
                src="/assets/images/betsmixer-g.svg"
                alt="logo"
                class="img-fluid footer-brand-mark logomobiletop"
              />

              <p className="in-house-game-title in-house-game-title-2 p-2 m-0">
                {t("in_house_game")}
              </p>
              <Row
                xs={4}
                md={4}
                className="in-house-game-wrapper w-100 p-2 m-0"
              >
                <Col className="col-3 p-1">
                  <Link to="/crash" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/1.svg"
                        className="img-fluid w-100 side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/roulette" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/2.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/diamond" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/3.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/plinko" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/4.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/3-card-monte" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/5.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/keno" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/6.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/videopoker" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/7.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
                <Col className="col-3 p-1">
                  <Link to="/classic-dice" onClick={() => setDrawer(false)}>
                    <div className="position-relative">
                      <img
                        src="/assets/images/sidebar/in-house/8.svg"
                        className="img-fluid w-100  side-inhome"
                        alt=""
                      />
                    </div>
                  </Link>
                </Col>
              </Row>

              <div className="menu-link-wrapper p-2 d-flex flex-column gap-2">
                <Link
                  to={"#"}
                  className="b-subtitle"
                  onClick={() => openOffer()}
                >
                  <button
                    className={`btn menu-link-btn bonusbtn`}
                  >
                    <div className="menu-link-btn-img-wrapper">
                      <span>
                        GET <span className="hightlight-bonus"> 15 USD</span>
                      </span>
                    </div>
                  </button>
                </Link>

                {/*
                                <Link to="/sports">
                                    <button className={`btn`} onClick={() => setDrawer(false)}>
                                        <div className='menu-link-btn-img-wrapper'>
                                            <img className="img-fluid" src="/assets/images/sidebar/recent.svg" />
                                            <span>Sports</span>
                                        </div>
                                    </button>
                                </Link>
                                */}

                <button
                  className={`subs btn menu-link-btn`}
                  onClick={() => showCat(1)}
                >
                  <div className="menu-link-btn-img-wrapper">
                    <img class="img-fluid img-default" src="/assets/images/sidebar/games-info/cherry.png" />
                    
                    <span>{t("Casino")}</span>
                  </div>
                  {subCat === 1 ? (
                    <svg
                      className="sub-btn"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.6806 13.9783L15.4706 10.7683L13.5106 8.79828C12.6806 7.96828 11.3306 7.96828 10.5006 8.79828L5.32056 13.9783C4.64056 14.6583 5.13056 15.8183 6.08056 15.8183H11.6906H17.9206C18.8806 15.8183 19.3606 14.6583 18.6806 13.9783Z"
                        fill="#ffffff"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="sub-btn"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                        fill="#ffffff"
                      />
                    </svg>
                  )}
                </button>

                {subCat === 1 && (
                  <div className="sub-cat animated fadeIn">
                    <Link to={"/new-release"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            width="20px"
                            height="20px"
                            viewBox="0 0 48 48"
                            className="sub-menu-icon"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="icons_Q2" data-name="icons Q2">
                                <path
                                  fill="#646e8b"
                                  d="M44,14H4a2,2,0,0,0-2,2V32a2,2,0,0,0,2,2H44a2,2,0,0,0,2-2V16A2,2,0,0,0,44,14ZM17.3,29H14.8l-3-5-.7-1.3h0V29H8.7V19h2.5l3,5,.6,1.3h.1s-.1-1.2-.1-1.6V19h2.5Zm9.1,0H18.7V19h7.6v2H21.2v1.8h4.4v2H21.2v2.1h5.2Zm10.9,0H34.8l-1-4.8c-.2-.8-.4-1.9-.4-1.9h0s-.2,1.1-.3,1.9L32,29H29.6L26.8,19h2.5l1,4.2a20.1,20.1,0,0,1,.5,2.5h0l.5-2.4,1-4.3h2.3l.9,4.3.5,2.4h0l.5-2.5,1-4.2H40Z"
                                />
                              </g>
                            </g>
                          </svg>
                          <span>{t("New Release")}</span>
                        </div>
                      </button>
                    </Link>

                    <Link to={"/recent"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            fill="#646e8b"
                            width="17.5px"
                            height="17.5px"
                            className="sub-menu-icon"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z" />
                          </svg>
                          <span>{t("Recent")}</span>
                        </div>
                      </button>
                    </Link>

                    <Link to={"/gameshow"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            width="20px"
                            className="sub-menu-icon"
                            height="20px"
                            viewBox="0 0 512 512"
                          >
                            <g>
                              <path
                                d="M511.883,298.395c-2.781-27.281-54.391-46.141-129.406-51.844c-7.172-42.047-15.469-90.563-17.891-103.75
    c-5.563-30.203-45.344-47.094-74.891-25.313c-15.5,11.422-29.359,12.234-36.703,12.234s-15.5,1.625-36.703-12.234
    c-30.719-20.094-69.328-4.891-74.875,25.313c-2.969,16.109-14.688,84.844-22.391,130.203
    C45.211,293.817-2.711,323.114,0.117,350.723c4.25,41.625,122.266,63.671,263.578,49.218
    C405.039,385.488,516.148,340.036,511.883,298.395z M132.289,308.348l8.156-42.406c0,0,145.188,22.828,226.75-19.578l8.156,35.891
    C375.352,282.254,287.258,337.708,132.289,308.348z"
                                fill="#646e8b"
                              />
                            </g>
                          </svg>
                          <span>{t("Game Shows")}</span>
                        </div>
                      </button>
                    </Link>

                    <Link to={"/table-games"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            className="sub-menu-icon"
                            fill="#646e8b"
                            width="20px"
                            height="20px"
                            viewBox="0 0 256 256"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M202.16064,200.833a103.80643,103.80643,0,0,0,0-145.666,7.39654,7.39654,0,0,0-1.32666-1.32666,103.80639,103.80639,0,0,0-145.668,0A7.39654,7.39654,0,0,0,53.83936,55.167a103.80643,103.80643,0,0,0,0,145.666,7.39012,7.39012,0,0,0,1.32714,1.3269,103.80581,103.80581,0,0,0,145.667,0,7.39012,7.39012,0,0,0,1.32714-1.3269ZM40.36768,136h24.1499a63.6708,63.6708,0,0,0,12.95605,31.21216l-17.06884,17.0686A87.60592,87.60592,0,0,1,40.36768,136ZM60.40479,71.71924l17.06884,17.0686A63.6708,63.6708,0,0,0,64.51758,120H40.36768A87.60592,87.60592,0,0,1,60.40479,71.71924ZM215.63232,120h-24.1499a63.6708,63.6708,0,0,0-12.95605-31.21216l17.06884-17.0686A87.60592,87.60592,0,0,1,215.63232,120ZM167.21191,77.47339A63.66789,63.66789,0,0,0,136,64.51782V40.36743a87.60765,87.60765,0,0,1,48.28076,20.03736ZM120,64.51782A63.66789,63.66789,0,0,0,88.78809,77.47339L71.71924,60.40479A87.60765,87.60765,0,0,1,120,40.36743ZM88.78809,178.52661A63.66789,63.66789,0,0,0,120,191.48218v24.15039a87.60765,87.60765,0,0,1-48.28076-20.03736ZM136,191.48218a63.66789,63.66789,0,0,0,31.21191-12.95557l17.06885,17.0686A87.60765,87.60765,0,0,1,136,215.63257Zm42.52637-24.27A63.6708,63.6708,0,0,0,191.48242,136h24.1499a87.60592,87.60592,0,0,1-20.03711,48.28076Z" />
                          </svg>
                          <span>{t("Table Games")}</span>
                        </div>
                      </button>
                    </Link>

                    <Link to={"/blackjack-games"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            className="sub-menu-icon"
                            fill="#646e8b"
                            width="20px"
                            height="20px"
                            viewBox="0 0 14 14"
                            role="img"
                            focusable="false"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m 5.9879637,6.1783 c -1.89881,-0.907 -4.69189,0.1749 -4.03105,2.6155 0.64399,2.3784 3.42513,1.8564 4.38685,0.4087 -0.40121,2.745 -1.10584,3.1724 -1.52817,3.7683 l 4.48387,0 c -0.4543,-0.6373 -1.27925,-1.0233 -1.76303,-3.806 0.96908,1.4454 3.8823703,1.9464 4.5170603,-0.3976 0.62435,-2.3058 -2.1900103,-3.619 -4.0614903,-2.5751 1.79457,-1.2051 2.4390503,-5.1629 -0.95987,-5.1629 -3.43247,0 -2.85977,4.0352 -1.04417,5.1491 z" />
                          </svg>
                          <span>{t("Blackjack")}</span>
                        </div>
                      </button>
                    </Link>

                    <Link to={"/card-games"}>
                      <button
                        className={`btn menu-link-btn`}
                        onClick={() => setDrawer(false)}
                      >
                        <div className="menu-link-btn-img-wrapper">
                          <svg
                            className="sub-menu-icon"
                            fill="#646e8b"
                            width="20px"
                            height="20px"
                            viewBox="0 0 14 14"
                            role="img"
                            focusable="false"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m 3.6869427,5.1442 c -3.93797403,4.0232 -0.0777,6.9415 3.0358505,5.0207 -0.58789,2.0957 -0.91118,2.2606 -1.26809,2.8125 l 3.36166,0 c -0.43151,-0.5573 -0.88469,-0.7168 -1.34383,-2.8005 C 10.651373,12.0029 14.001703,8.8184 10.474403,5.1442 8.1675332,3.1264 7.2719332,1.2842 7.0806732,1.0226 6.8971532,1.2405 5.8159332,3.1638 3.6869427,5.1442 Z" />
                          </svg>
                          <span>{t("Cards")}</span>
                        </div>
                      </button>
                    </Link>
                  </div>
                )}

                <Link to={"/live"}>
                  <button
                    className={`btn menu-link-btn`}
                    onClick={() => setDrawer(false)}
                  >
                    <div className="menu-link-btn-img-wrapper">
                      <img
                        className="img-fluid img-default"
                        src="/assets/images/sidebar/games-info/live_casino.svg"
                      />
                      <img
                        className="img-fluid img-top"
                        src="/assets/images/sidebar/games-info/live_casino_active.svg"
                      />
                      <span>{t("live_casino")}</span>
                    </div>
                  </button>
                </Link>
                <Link to={"/slots"}>
                  <button
                    className={`btn menu-link-btn`}
                    onClick={() => setDrawer(false)}
                  >
                    <div className="menu-link-btn-img-wrapper">
                      <img
                        className="img-fluid img-default"
                        src="/assets/images/sidebar/games-info/slots.svg"
                      />
                      <img
                        className="img-fluid img-top"
                        src="/assets/images/sidebar/games-info/slots_active.svg"
                      />
                      <span>{t("slots")}</span>
                    </div>
                  </button>
                </Link>

                <Link to={"/"}>
                  <button
                    className={`btn menu-link-btn`}
                    onClick={() => setOfferwal()}
                  >
                    <div className="menu-link-btn-img-wrapper">
                      <img
                        className="img-fluid img-default unionimg"
                        src="/assets/images/union.png"
                      />
                      <img
                        className="img-fluid img-top unionimg"
                        src="/assets/images/union.png"
                      />
                      <span>Offerwall</span>
                    </div>
                  </button>
                </Link>
              </div>

              <div className="menu-text-wrapper p-2 d-flex flex-column gap-2">
                <Link
                  to={"#"}
                  className="b-title"
                  onClick={() => setDrawer(false)}
                >
                  {t("other")}
                </Link>
                {menuLinks}

                <Link
                  to={"#"}
                  className="b-subtitle"
                  onClick={() => setOfferwal()}
                >
                  <i class="mdi mdi-coin font-15"></i>Offerwall
                </Link>
                <FreeCredit t={t} disableButton={true} />
              </div>

              <div className="menu-footer-wrapper menu-footer-wrapper-mobile p-2 d-flex flex-column gap-2">
                <div className="d-flex align-items-center">
                  <Link
                    to={"#"}
                    className="b-subtitle my_custom_intercom"
                    onClick={() => setDrawer(false)}
                  >
                    Live Support
                    <svg
                      className="iconchat"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#646e8b"
                        d="M18.72 14.76c.35-.85.54-1.76.54-2.76c0-.72-.11-1.41-.3-2.05c-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.34a9.21 9.21 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.27c1.05 0 2.06-.23 2.97-.64c.57 1.09.83 1.63.81 1.63c-1.64.55-2.91.82-3.78.82c-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6a8.991 8.991 0 0 1 2.47 4.6H22v4.55h-.06L18.38 18l-5.3-.6v-1.67h4.83l.81-.97m-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33c-.63 0-1.14-.5-1.14-1.13c0-.63.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14c0 .63-.5 1.13-1.13 1.13c-.63 0-1.14-.5-1.14-1.13a1.14 1.14 0 0 1 1.14-1.14Z"
                      />
                    </svg>
                  </Link>
                </div>

                <Link
                  className="termslink"
                  to={"/rules?tab=1"}
                  onClick={() => setDrawer(false)}
                >
                  {t("terms_conditions")}
                </Link>
                <div className="mobilelang">
                  <Translation mobileDrawer={setDrawer} />
                </div>
              </div>

              <div className="menu-social-wrapper socialiconsmenu p-2 justify-content-start mt-3 d-flex">
                <a href="https://x.com/drakebetcasino" target="_blank">
                  <button className={"btn btn-xs menu-social-btn"}>
                    <img
                      className="img-fluid "
                      src="/assets/images/sidebar/social/twitter.png"
                    />
                  </button>
                </a>
                <a
                  href="https://t.me/drakebetcasino"
                  target="_blank"
                  className="telegramsidebar"
                >
                  <button className={"btn btn-xs menu-social-btn"}>
                    <img
                      className="img-fluid "
                      src="/assets/images/sidebar/social/telegram.png"
                    />
                  </button>
                </a>
                <Link to={"#"} className="b-subtitle my_custom_intercom">
                  <button className={"btn btn-xs menu-social-btn"}>
                    <img
                      className="img-fluid livesupporticon"
                      src="/assets/images/sidebar/social/livesupport.svg"
                    />
                  </button>
                </Link>

                <a href="#" className="mobilelangbutton">
                  <button className={"btn btn-xs menu-social-btn"}>
                    <Translation mobileDrawer={setDrawer} />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default MobileMenu;
