import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { __, Event, isMobile, isTablet, wait } from "../../../Helper";
import Canvas from "./Canvas";
// import BaccaratGameEngine from "./Rules/gameEngine/baccaratGameEngine";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      canvas: null,
      loading: true,
      started: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine, amount } = this.state;

    engine.on("play", (data) => this.play(data));
    engine.on("busted", (data) => this.busted());
    Event.on("loading_baccarat_done", () => this.progress());
    Event.on("change_amount", (data) => this.setAmount(data));
    Event.on("coin_changed", (data) => this.setCoin(data));

    var canvas = new Canvas(engine);
    canvas.init();
    wait(1000).then(() => {
      canvas.setCoin(this.props.coin);
    });

    this.setState({ canvas: canvas });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  progress() {
    this.setState({ loading: false });
  }

  setAmount(value) {
    if (this._isMounted) {
      this.state.canvas.setAmount(value);
    }
  }

  setCoin(value) {
    if (this._isMounted) {
      this.state.canvas.clearTable();
      this.state.canvas.setCoin(value);
    }
  }

  setPlay(data) {
    let { engine, started } = this.state;
    const { amount, coin } = data;

    if (started) return;

    this.setState({ started: true });

    /*
      // Create game engine (8 decks default)
      const gameEngine = new BaccaratGameEngine();
      gameEngine.shoe.createDecks();
      gameEngine.shoe.shuffle();
      let burnCard = gameEngine.burnCards();

      if (gameEngine.isBurnNeeded) {
        gameEngine.shoe.shuffle();
        // Burn card is the first card drawn that determined the burn cards to use.
        let burnCard = gameEngine.burnCards();
      }

      const hand = gameEngine.dealGame();
      const result = gameEngine.resultsEngine.calculateGameResult(hand);

      this.setState({ hand, result });
    */
  }

  play(data) {
    if (this._isMounted) {
      this.state.canvas.play(data);
    }
  }

  busted() {
    if (this._isMounted) {
      this.state.canvas.busted();
      this.setState({ started: false });
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn">
          <canvas id="game" />
        </div>
      </>
    );
  }
}

Game.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, {})(Game);
