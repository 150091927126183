import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Accordion,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Media,
  ProgressBar
} from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setChartCoin } from "../../../../actions/gameCoin";
import Chart from "../Chart";
import Withdrawl from "../Wallet/Withdrawl";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import ReactTooltip from "react-tooltip";
import storage from "../../../../Storage";
import {
  __,
  encode,
  wait,
  sendNotfication,
  Event,
  toUSD,
} from "../../../../Helper";
import { crossOriginResourcePolicy } from "helmet";

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      showChart: false,
      openedChart: false,
      changeCoinLoader: false,
      currentCoin: this.props.coin
        ? this.props.coin
        : storage.getKey("coin") !== null
        ? storage.getKey("coin")
        : "USDT",
      message: "[ " + props.t('please_wait') + " ]",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.haveData === "no") {
      //force open empty modal
      this.setState({ loader: true });
    } else {
      //update state/modal after receiving socket USER_INFO
      var props = nextProps;
      var dd = props.data;
      let since = sinceConvert(dd.created);
      let color = dd.profit < 0 ? "text-danger" : "text-success";
      let coin = dd.coin;
      let level = dd.level;
      let name = props.name;
      this.setState({
        since: since,
        currentCoin: coin,
        name: name,
        coin: coin,
        level: level,
        color: color,
        avatar: dd.avatar,
        profit: dd.profit,
        date: dd.created,
        played: dd.played,
        wined: dd.wined,
        vip_level: dd.vip_level,
        favorite_games: dd.favorite_games,
      });

      wait(200).then(() => {
        if (!__.isEmpty(name) && !__.isUndefined(name))
          if (!__.isEmpty(name) || !__.isUndefined(name))
            this.setState({ loader: false });
      });
    }
  }

  componentDidMount() {
    this.props.setChartCoin(this.state.currentCoin);
  }

  componentWillUnmount() {
    this.props.setChartCoin("USDT");
    // socket.off(C.USER_INFO);
    // socket.off(C.USER_CHART);
  }

  selectCoin(coin) {
    //Set For Redux
    this.props.setChartCoin(coin);
    this.setState({ currentCoin: coin, changeCoinLoader: true });
    socket.emit(
      C.USER_INFO,
      encode({ name: this.props.name, coin: coin, page: 1 })
    );
  }

  getChart(game) {
    Event.emit("single_game_modal_by_chart");
    socket.emit(
      C.USER_CHART,
      encode({ id: this.props.id, coin: this.state.currentCoin, game: game })
    );
    this.setState({ openedChart: true, message: "" });
  }

  back = (e) => {
    Event.emit("back_from_chart");
    this.setState({ openedChart: false });
  };

  showChart = (e) => {
    this.setState({ showChart: !this.state.showChart });
  };

  handleShowTip = () => {
    if (storage.getKey("logged") === null) {
      return sendNotfication(
        this.props.t('please_login_to_use_this_option'),
        "warning",
        "top-center"
      );
    }
    Event.emit("force_modal_tip", { direct: false });
  };
  closeModal = () => {
    Event.emit("close_user_modal");
  };

  moveToSetting = () => {
    Event.emit('close_user_modal');
    this.props.history.push('/setting')
  }

  render() {
    let {
      name,
      since,
      avatar,
      profit,
      favorite_games,
      date,
      level,
      played,
      wined,
      vip_level,
    } = this.state;

    const { t } = this.props;

    if (!__.isUndefined(date)) date = date.substr(0, 10);

    const makeStars = (num) => {
      let stars = 5;
      let userLevel = stars - parseFloat(num);
      var g = [],
        o = [],
        f = [];
      for (var i = 0; i < userLevel; i++) {
        g.push(
          <span key={i + 1} className="graysi">
            🏆
          </span>
        );
      }
      for (var i = 0; i < num; i++) {
        o.push(<span key={i + 1}>🏆</span>);
      }
      f.push(o, g);
      return f;
    };

    played = played ? played : null;
    wined = wined ? wined : 0;

    // let g = [],
    //   i = 0;
    // for (i in favorite_games) {
    //   var game = favorite_games[i];

    //   let thisGame = {
    //     game: game.game,
    //     slot: false,
    //   };

    //   var find = __.find(g, function (o) {
    //     return o.game === game.game;
    //   });
    //   if (!find) {
    //     g.push(thisGame);
    //   }
    // }

    // let g =
    //   favorite_games?.length > 0
    //     ? favorite_games.map((game) => ({
    //         game: game.name,
    //         game_as: game.surfix,
    //         slot: false,
    //       }))
    //     : [];

    // const getName = (game) => {
    //   let gameName = __.replace(game, "_", " ");
    //   gameName = __.replace(gameName, "snakeandladders", "Snake");
    //   gameName = __.replace(gameName, "magic wheel", "M-Wheel");
    //   gameName = __.replace(gameName, "singlekeno", "S-Keno");
    //   gameName = __.replace(gameName, "3 cardmonte", "3-Monte");
    //   return gameName;
    // };

    // const chartGames = g.map(
    //   (item, i) =>
    //     !item.slot && (
    //       <Col key={__.toString(i)} md={4} className="col-4">
    //         <button
    //           onClick={(e) => this.getChart(item.game)}
    //           className={"btn fma btn-xs bg-cs2 text-white btn-block mb-1 txu"}
    //         >
    //           {getName(item.game)}
    //         </button>
    //       </Col>
    //     )
    // );

    let trophy = 0;

    if (parseFloat(toUSD(profit)) < 100) trophy = 3;
    else if (parseFloat(toUSD(profit)) < 1000) trophy = 2;
    else if (parseFloat(toUSD(profit)) < 3000) trophy = 1;
    else if (parseFloat(toUSD(profit)) > 3000) trophy = 1;

    return (
      <div>
        {this.state.loader ? (
          <div className="text-center" style={{ minHeight: 250 }}>
            <div
              className="spinner-grow text-white my-5"
              role="status"
            />
          </div>
        ) : (
          <div>
            {!this.state.openedChart ? (
              <div className={"animated fadeIn"}>
                <Row>
                  <Col sm="12" md="6">
                    <Card className="mb-1">
                      <Card.Body className="p-1">
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between user-detail text-center mb-1">
                          <span className="hatA userprofile">
                            <img
                              src={avatar}
                              className={"thumb-md user-av av-onmodal"}
                              alt="Avatar"
                            />
                            <h4
                              className={
                                "mb-0 text-white uname float-right ml-2 mt-3"
                              }
                            >
                              {name}
                            </h4>
                          </span>
                        
                        </div>
                        {this.props.name !== storage.getKey("name") ? (
                          <div
                            className={
                              "btn-group w-100 button-items text-center px-1 mt-1"
                            }
                          >
                            <button
                              onClick={this.handleShowTip}
                              className="mb-1 btn btn-cs-9 btn-sm shadow-none text-white rounded -12 text-uppercase"
                            >
                              <i className="mdi mdi-bank-transfer font-17 align-middle" />{" "}
                              {t('tip')}
                            </button>
                          </div>
                        )
                        :
                        (
                          <div
                            className={
                              "btn-group w-100 button-items text-center px-1 mt-1"
                            }
                          >
                              <button
                                onClick={() => {this.moveToSetting()}}
                                className="mb-1 btn btn-cs-9 btn-sm shadow-none text-white rounded -12 text-uppercase"
                              >
                                <i className="mdi mdi-bank-transfer font-17 align-middle" />{" "}
                                {t('my_profile')}
                              </button>
                          </div>
                        )
                      }
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6">
                    <Card className="mb-1">
                      <Card.Body className="p-1 text-center">
                        <h5 className="mt-2 mb-x3 0 p-0 header-title font-14 text-white ml-3 text-left">
                            <i className="mdi mdi-trophy align-top" /> VIP {t('levels')}
                        </h5>
                        <div className="img-group text-center">
                          <div className="user-stars mt-2 mx-2 viplevelbox d-flex justify-content-center">
                            <img src="/assets/images/t1.gif" className="" />

                            <div className="text-yellow">VIP&nbsp;</div>
                            <div className="r badge badge-warning -2 text-dark font-weight-bold font-14 mr-1">
                                {t('level')} {vip_level}  
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div className="details-parent">
                  {/* <Row>
                    <Col lg="6" md="6" sm="12">
                      <Card className={"mb-1 brd15"}>
                        <Card.Body className="p-1">
                          <div className="media">
                            <div className="l">{t('joined')}</div>
                            <div className="r">
                              <span className={"num-style text-white"}>
                                {date}{" "}
                                <span className="font-11">({since})</span>
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <Card className={"mb-1 brd15"}>
                        <Card.Body className="p-1">
                          <div className="media">
                            <div className="l">{t('total_wagered')}</div>
                            <div className="r">
                              {this.state.changeCoinLoader ? (
                                <div className="text-center">
                                  <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                  />
                                </div>
                              ) : (
                                <>
                                  <b className={"num-style text-success"}>
                                    {toUSD(profit)}
                                  </b>
                                </>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  */}
                  <Row>

                    <Col lg="6" md="6" sm="12">
                      {played !== null && (
                        <>
                          <Card className={"mb-1 brd15"}>
                            <Card.Body className="p-1">
                              <div className="media ycenter">
                                <div className="l">{t('total_wins')}</div>
                                <div className="r badge badge-success -2 text-dark font-weight-bold font-14 mr-1">
                                  {wined}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </>
                      )}
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      {played !== null && (
                        <>
                          <Card className={"mb-1 brd15"}>
                            <Card.Body className="p-1">
                              <div className="media ycenter">
                                <div className="l">{t('total_bets')}</div>
                                <div className="r badge badge-warning -2 text-dark font-weight-bold font-14 mr-1">
                                  {played}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </>
                      )}
                    </Col>
                    {/* <Col sm="12" className="">
                      {chartGames.length > 0 && (
                        <button
                          onClick={this.showChart}
                          className="btn btn-outline-light btn-user-profile btn-block fma btn-sm mb-2"
                        >
                          SHOW GAMES CHART{" "}
                          <span
                            className={
                              this.state.showChart ? "tran caret" : "caret"
                            }
                          ></span>
                        </button>
                      )}
                      {this.state.showChart && (
                        <Row className="mt-2">{chartGames}</Row>
                      )}
                    </Col> */}
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex mb-1 vipprogress">
                              <img
                                src="/assets/images/diamonds.png"
                                alt="VIP Progress"
                              />

                        <div className="text-yellow">VIP&nbsp;</div>
                        <div className="text-white">{t('progress')}</div>
                      </div>
                      <div className="usercard">

                        <div className="progress">
                          <div role="progressbar" className="progress-bar" aria-valuenow={vip_level} aria-valuemin="0" aria-valuemax="6" style={vip_level > 0 ? {width: `${vip_level * 100 / 5}%`, height: '20px'} : {width: '20px', height: '20px'}}>
                          </div>
                        </div>

                        <div className="progressbar-tooltip-container d-flex">
                          <div className='position-absolute'><span className="progressbar-tooltip">VIP 0</span></div>
                          {vip_level > 0 && vip_level < 5 && <div className='position-absolute' style={{left:`calc(${vip_level * 100 / 5}% - 20px)`}}><span className="progressbar-tooltip-yellow">VIP {vip_level}</span></div>}
                          {vip_level == 5 && <div className='position-absolute' style={{left:'calc(100% - 40px)'}}><span className="progressbar-tooltip-yellow">VIP {vip_level}</span></div>}
                          {vip_level < 5 && <div className='position-absolute' style={{left:'calc(100% - 40px)'}}><span className="progressbar-tooltip">VIP 5</span></div>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <Row>
                <Col sm={12}>
                  <div className={"animated slideI8nDown my-1"}>
                    <ul className={"list-inline ico-socials text-center mb-0"}>
                      {played !== null && (
                        <>
                          <div id={"userChart"}>{this.state.message}</div>
                          <Chart t={t} />
                          <button
                            onClick={this.back}
                            className="btn btn-xs btn-outline-light mb-1 mt-2"
                          >
                            {t('back')}
                          </button>
                        </>
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

function sinceConvert(time, lang) {
  if (time === undefined) return;
  lang = lang || {
    postfixes: {
      "<": " ago",
      ">": " from now",
    },
    1000: {
      singular: "a few moments",
      plural: "a few moments",
    },
    60000: {
      singular: "some minutes",
      plural: "# minutes",
    },
    3600000: {
      singular: "some hour",
      plural: "# hours",
    },
    86400000: {
      singular: "a day",
      plural: "# days",
    },
    31540000000: {
      singular: "a year",
      plural: "# years",
    },
  };

  var timespans = [1000, 60000, 3600000, 86400000, 31540000000];
  var parsedTime = Date.parse(time.replace(/\-00:?00$/, ""));

  if (parsedTime && Date.now) {
    var timeAgo = parsedTime - Date.now();
    var diff = Math.abs(timeAgo);
    var postfix = lang.postfixes[timeAgo < 0 ? "<" : ">"];
    var timespan = timespans[0];

    for (var i = 1; i < timespans.length; i++) {
      if (diff > timespans[i]) {
        timespan = timespans[i];
      }
    }

    var n = Math.round(diff / timespan);

    return (
      lang[timespan][n > 1 ? "plural" : "singular"].replace("#", n) + postfix
    );
  }
}

Details.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { setChartCoin })(withRouter(Details));