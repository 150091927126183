import React from "react";
import { connect } from "react-redux";

function Loading() {
  return (
    <div className="text-center">
      <div className="spinner-grow text-white my-5" role="status" />
    </div>
  );
}

export default Loading;
