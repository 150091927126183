import React from "react";
import md5 from "md5";
import { Card, Col, Row, Stack } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { __, isMobile, wait, isLogged, decode, DEVELOPMENT } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import { width } from "@mui/system";
import Recent from '../../../Recent';

class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            col: 'col-12',
            mdCol: '14.2%',
            iPadd: '10px',
            isMin: false,
            loading: true,
            games_original: [],
            games_slots: []
        };
        socket.emit(C.FAVOURITES);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.specific == 'recent' && props.hgames?.length > 0) {
            return { games: props.hgames, loading: false}
        }
        return null;
    }

    componentDidMount() {

        if (isMobile()) {
            this.setState({ col: 'col-6' });
        }

        window.addEventListener('resize', this.handleResize);

        wait(50).then(() => {
            this.handleResize();
        })

        socket.on(C.FAVOURITES, data => {
            try{
                    
                let decoded = decode(data);
                this.setState({ games_original: decoded.original, games_slots: decoded.slots, loading: false });
            }
            catch(e){

            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        socket.off(C.FAVOURITES);
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-content');

        if (pageContent !== null) {
            const width = parseFloat(pageContent.style.width);

            if (width < 700) {
                this.setState({ mdCol: '14.2%!important', isMin: true, iPadd:'5px' });
            }
            else {
                this.setState({ mdCol: '14.2%', isMin: false, iPadd: '10px' });
            }
        }
    }

    render() {
        let { games_original, games_slots, col, mdCol, isMin } = this.state;
        const { t } = this.props;
        const original_game_list = games_original.map((game, i) =>
                !game.uuid && <AddGame t={t} key={__.toString(i)} col={col} mdCol={mdCol} item={game} isMin={isMin} />
        );
        const slot_game_list = games_slots.map((game, i) =>
            game.uuid && <Col key={i.toString()} className={'ovh animated fadeInUp game-items'} style={{ maxWidth: '14.2%'}}>
                        <Link to={game.name !== null ? '/slots/' + game.uuid : '#'} className="slot-link slot-pan game-card-wrapper">
                            <div className={game.name !== null ? 'slot-image' : 'slot-image orange'}>
                                {/* <img src={"https://app.betsmixer.com/assets/slotegrator/" + game.provider + "/" + game.image} className="img-fluid" alt={game.name} /> */}
                                <img src={game.origin_url} className="img-fluid" alt={game.name} />
                            </div>
                            <div className="slot-title">
                                <span className="slot-t-name">{game.name}</span>
                                <span className="slot-t-provider">{game.provider}</span>
                            </div>
                        </Link>
                    </Col>
        );
        

        return (
            <>
                {
                    this.state.loading ?
                        <>
                            {/* <div className="ycenter text-center">
                                <div className="spinner-grow text-white my-5" role="status" />
                            </div> */}
                        </>
                        : 
                        <>
                            {
                                games_original.length + games_slots > 0 ?
                                    <Card className="favorites-tab">
                                        <Card.Body className="p-4"> 
                                            <Stack direction="horizontal" gap={3} className="mb-2">
                                                <div className="d-flex align-items-center">
                                                    <img className='img-fluid home-page-icons' src="/assets/images/icons/slot-game.svg" />
                                                    <p className="m-0 home-part-t">{t('favorites')}</p>
                                                </div>
                                            </Stack>

                                                    {
                                                        original_game_list.length > 0 ?
                                                            <>
                                                                <h4 className="mt-0 mb-0 section-title"> {t('original_games')}</h4>
                                                                <Row xs={7} md={7} lg={7} xl={7} sm={7} className="d-flex game-cards m-0">
                                                                    {original_game_list}
                                                                </Row>
                                                            </>
                                                        :<></>
                                                    }
                                                    {
                                                        slot_game_list.length > 0 ?
                                                            <>
                                                                <h4 className="mt-0 mb-0 section-title"> {t('slots_games')}</h4>
                                                                <Row xs={7} md={7} lg={7} xl={7} sm={7} className="d-flex game-cards m-0">
                                                                    {slot_game_list}
                                                                </Row>
                                                            </>
                                                        :<></>
                                                    }
                                        </Card.Body>
                                    </Card>
                                :
                                    <></>
                            }
                        </>
                }
                {isLogged &&
                    <Recent search={this.props.search} t={t} history={this.props.history} />
                }
            </>
        );
    }
}

class AddGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
        };
    }

    render() {
        let { mdCol, item, isMin, iPadd, t } = this.props;
        let style;
        
        if (item.prefix === 'crash') {
            mdCol = '28.5%'
            style = {
                width: 'auto'
            }
        }
        return (
            <>
                {!item.disabled ?
                    // <Col className={'ovh animated fadeInUp game-items'} style={{maxWidth: mdCol, padding: iPadd}}>
                    <Col className={'game-items'} style={{maxWidth: mdCol, padding: iPadd}}>
                        <Link to={item.prefix !== null ? '/' + item.prefix : '#'} className="game2-link">
                            <div className={item.prefix !== null ? 'game2-image' : 'game2-image orange'}>
                                <img src={item.image} className={'img-fluid'} alt={item.name} />
                                <div className="game2-home-title">
                                    {item.name ? <span>{item.name}</span> : ''}
                                </div>
                            </div>
                        </Link>
                    </Col>
                    :
                    // <Col className={'ovh animated fadeInUp game-items'} style={{maxWidth: mdCol, padding: iPadd}}>
                    <Col className={'game-items'} style={{maxWidth: mdCol, padding: iPadd}}>
                        <Link to={'#'} className="game2-link">
                            <div className={item.prefix !== null ? 'game2-image' : 'game2-image orange'}>
                                <img src={item.image} className="img-fluid" alt={item.name} />
                            </div>
                        </Link>
                    </Col>
                }
            </>
        );


    }

}

export default Favorites;
