import React from "react";

export default class UserAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <p className={'text-white font-light mb-2 font-14 l30 mb-4 agreementpage'}>
                 <div id="north" className="north panel-body panel-body-noheader layout-body" title="" data-options="region:'north'">
<p><strong>Last updated on:</strong> August 7, 2023 by <strong className="seller-link flex flex-justify-center tbody-3">Mrs Shannon Kathe (attorney)</strong>.</p>
<p>By using BetsMixer&rsquo;s services or products, you, the end user (referred to as "User" or "you"), acknowledge the legally binding agreement (the "Agreement") between you and BetsMixer ("us" or "we"). Clicking the "I Agree" button or using the Service implies your consent to the terms and conditions outlined in this Agreement. This Agreement governs your use of BetsMixer Suite of online business productivity and collaboration software for online gaming, representing a contract between you (or the entity you represent) and the applicable BetsMixer contracting entity ("BetsMixer.com," "BetsMixer," "We").</p>
<p><strong>ACCEPTANCE OF THE AGREEMENT</strong></p>
<p>You must be of legal age to enter into a binding agreement in order to accept the Agreement. If you do not agree to these Terms, do not use any of our Services. If you agree to the Terms and do not agree to any Service Specific Terms, do not use the corresponding Service. You can accept the Agreement by checking a checkbox or clicking on a button indicating your acceptance of the Agreement or by actually using the Services.</p>
<p><strong>SERVICES</strong></p>
<p><strong>User Eligibility:</strong> We offer services exclusively to users who are 18 years of age or older. Users below this age are strictly prohibited from engaging in any gaming activities on this platform. If any user is discovered attempting to bypass this age restriction, legal actions will be pursued in accordance with the regulations specified by the state of California regarding underage casino games. Under California law, individuals caught bypassing this restriction may be subject to fines, community service, mandatory educational programs, and possible suspension or postponement of their driver's license.&nbsp;&nbsp;&nbsp;</p>
<p><strong>User Responsibility.</strong> Users are expected to utilize the platform responsibly and adhere to all relevant laws and regulations. This entails refraining from engaging in fraudulent activities, sharing account information, or participating in any form of cheating.</p>
<p><strong>Google Account Integration.</strong> To streamline the process of fetching user data, our service prompts users to log in to their Google accounts. It is the responsibility of users to enter their login details accurately. We or our operators cannot be held liable for any legal actions, disputes, or damages arising from the use of the Google account integration feature. Users' Google login credentials are not collected or stored by our service.</p>
<p><strong>Permissible Use.</strong> You may use our services for personal and business purposes or for internal business use within the organization you represent. By accessing and using our services, you agree to comply with all applicable laws and regulations. It is your responsibility to obtain access to the Internet and the necessary equipment to use our services effectively.</p>
<p><strong>WAGERING CONDITIONS</strong></p>
<p>For protection our platform and our players before money laundering or any other illegal activity or abuse our platform. Wagering condition is setup on 1x wagering requirement of deposited amount of user. <br />For more detailed informations and comparasition to other "well-known" casino brands which use same practicy, please follow article:<br /><a href="https://bitcointalk.org/index.php?topic=5448871.msg62091698#msg62091698">https://bitcointalk.org/index.php?topic=5448871.msg62091698#msg62091698</a></p>
<p>Thank you again for following our rules. This way we can provide proper support and service to all our customers. We value every player who follow our terms and conditions and wagering conditions.</p>
<ol>
<li><strong> Grant of License</strong></li>
</ol>
<p>1.1. BetsMixer grants the User a non-exclusive, personal, non-transferable right to use the Service on their personal computer or device to access the games.</p>
<p>1.2. The Service is not intended for use by individuals under 18 years of age, individuals under the legal age of majority in their jurisdiction, or individuals accessing the Service from jurisdictions where it is illegal. Users are responsible for ensuring their use of the Service is lawful.</p>
<p>1.3. BetsMixer and its licensors hold all rights to the Service, code, structure, and organization, including copyrights and intellectual property rights. Unauthorized Use, including copying, distributing, reverse engineering, modifying, or using the Service contrary to applicable laws, is prohibited. Users are liable for any damages resulting from Unauthorized Use and must report such instances to BetsMixer.</p>
<ol start="2">
<li><strong> No Warranties</strong></li>
</ol>
<p>2.1. BetsMixer disclaims all warranties regarding the Service, provided "as is," without representation of quality, fitness for purpose, completeness, or accuracy.</p>
<p>2.2. BetsMixer does not guarantee uninterrupted, timely, or error-free service, and defects may not be corrected.</p>
<ol start="3">
<li><strong> Authority/Terms of Service</strong></li>
</ol>
<p>Users must comply with the game rules described on the BetsMixer website. BetsMixer retains authority over the issuance, maintenance, and closing of the Service, and its management decisions are final.</p>
<ol start="4">
<li><strong>Your Obligations as a Player</strong></li>
</ol>
<p>4.1. Users declare and warrant that they are of legal age and allowed to participate in the games offered on the website for recreational purposes.</p>
<p>4.2. Users agree to provide accurate and up-to-date information and report any changes promptly.</p>
<p>4.3. Users are responsible for reporting and accounting for any applicable taxes on their winnings.</p>
<p>4.4. Users must not engage in fraudulent, collusive, or unlawful activities related to the games.</p>
<p>4.5. Users acknowledge that Virtual Funds (Bitcoin) have no intrinsic value and may experience value fluctuations.</p>
<p>4.6. Users are not allowed to transfer, sell, or acquire user accounts.</p>
<p>4.7. Courteous behavior is expected from users during games.</p>
<ol start="5">
<li><strong> Prohibited Uses</strong></li>
</ol>
<p>5.1. The Service is for the User's personal use only. Creating multiple accounts for collusion or service abuse is prohibited.</p>
<p>5.2. Users from Prohibited Jurisdictions are not permitted to use the Service.</p>
<ol start="6">
<li><strong> Know Your Customer (KYC)</strong></li>
</ol>
<p>BetsMixer may request KYC documentation to verify a User's identity and location. Service and payment may be restricted until identity is confirmed.</p>
<ol start="7">
<li><strong> Breach</strong></li>
</ol>
<p>7.1. BetsMixer reserves the right to take appropriate action, including terminating this Agreement, against Users who breach any provision herein.</p>
<p>7.2. Users are liable for indemnifying BetsMixer against any claims arising from their breach of this Agreement.</p>
<ol start="8">
<li><strong> Limitations and Liability</strong></li>
</ol>
<p>8.1. BetsMixer shall not be liable for any special, incidental, direct, indirect, or consequential damages arising from using the Service, even if BetsMixer was aware of the possibility of such damages.</p>
<p>8.2. This limitation does not apply to BetsMixer's liability for death or personal injury resulting from negligence.</p>
<ol start="9">
<li><strong> Disputes</strong></li>
</ol>
<p>Users may make complaints to BetsMixer's customer service team. Unresolved disputes may be pursued in the jurisdiction of California.</p>
<ol start="10">
<li><strong> Amendment</strong></li>
</ol>
<p>BetsMixer reserves the right to update or modify this Agreement at any time without notice. Users are bound by the amended Agreement upon posting.</p>
<ol start="11">
<li><strong> Governing Law</strong></li>
</ol>
<p>This Agreement shall be governed by and construed in accordance with the laws of California. Users agree to the exclusive jurisdiction of the courts in California.</p>
<ol start="12">
<li><strong> Assignment</strong></li>
</ol>
<p>BetsMixer may assign this Agreement at any time without notice. Users may not assign their rights or obligations under this Agreement.</p>
<ol start="13">
<li><strong> Advantage Play</strong></li>
</ol>
<p>Users engaging in advantage play, attempting to secure favorable bonuses or exploit system errors, may have winnings confiscated and accounts closed.</p>
<ol start="14">
<li><strong> Miscellaneous</strong></li>
</ol>
<p>If any provision of this Agreement becomes illegal, invalid, or unenforceable, it does not affect the validity of other provisions.</p>
<p>&nbsp;</p>
<p><strong>User Agreement</strong></p>
<p><strong>Company Information:</strong></p>
<p>Company Name: BMI Holding Ltd.</p>
<p>Company Address: Global Gateway, Rue de la Perle, Mahe, Seychelles</p>
<p>Contact mail: info@betsmixer.com</p>
<p>Contact Phone: +44 7537134539</p>
<p><strong>Website Information:</strong></p>
<ul>
<li>E-gaming platform and crypto casino</li>
<li>Website URL: <a href="https://betsmixer.com">https://betsmixer.com</a></li>
</ul>
<p>Please read this User Agreement carefully before using the BetsMixer platform and make sure you fully understand and accept all its terms and conditions. If you have any questions or concerns regarding this agreement, feel free to contact the provided customer support information before proceeding.</p>
<p><strong>Definitions:</strong></p>
<p>BetsMixer is referred to as 'we' or 'us'.</p>
<p>The Player is referred to as "you" or 'the Player'.</p>
<p>'The Website' means BetsMixer through desktop, mobile, or other platforms utilized by the Player.</p>
<ol>
<li><strong> General:</strong></li>
</ol>
<p>1.1. This User Agreement applies to the usage of games accessible through BetsMixer.</p>
<p>1.2. This User Agreement comes into force as soon as you complete the registration process, which includes checking the box accepting this User Agreement and successfully creating an account. By using any part of the Website following account creation, you agree to this User Agreement.</p>
<p>1.3. You must read this User Agreement carefully in its entirety before creating an account. If you do not agree with any provision of this User Agreement, you must not create an account or continue to use the Website.</p>
<p>1.4. We are entitled to make amendments to this User Agreement at any time and without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes to your attention (such as by e-mail or placing a notice on a prominent position on the Website, together with the amended User Agreement) but it shall be your sole responsibility to check for any amendments, updates, and/or modifications. Your continued use of BetsMixer E-Gaming Site services and Website after any such amendment to the User Agreement will be deemed as your acceptance and agreement to be bound by such amendments, updates, and/or modifications.</p>
<p>1.5. This User Agreement may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us, and in the case of any discrepancy with respect to a translation of any kind, the English version of this User Agreement shall prevail.</p>
<ol start="2">
<li><strong> Binding Declarations:</strong></li>
</ol>
<p>2.1. By agreeing to be bound by this User Agreement, you also agree to be bound by the BetsMixer&rsquo;s Rules and Privacy Policy that are hereby incorporated by reference into this User Agreement. In the event of any inconsistency, this User Agreement will prevail. You hereby represent and warrant that:</p>
<p>2.1.1. You are over (a) 18 or (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever age is greater;</p>
<p>2.1.2. You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;</p>
<p>2.1.3. All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;</p>
<p>2.1.4. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;</p>
<p>2.1.5. You understand that by using our services you take the risk of losing money deposited into your Member Account and accept that you are fully and solely responsible for any such loss;</p>
<p>2.1.6. You are permitted in the jurisdiction in which you are located to use online casino services;</p>
<p>2.1.7. In relation to deposits and withdraws of funds into and from your Member Account, you shall only use Cryptocurrency that is valid and lawfully belongs to you;</p>
<p>2.1.8. You understand that the value of Cryptocurrency can change dramatically depending on the market value;</p>
<p>2.1.9. The computer software, the computer graphics, the Websites, and the user interface that we make available to you are owned by BetsMixer or its associates and are protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, User Agreement we have established and in accordance with all applicable laws, rules, and regulations;</p>
<p>2.1.10. You understand that Cryptocurrency is not considered a legal currency or tender and as such on the Website, they are treated as virtual funds with no intrinsic value.</p>
<p>2.1.11. You affirm that you are not an officer, director, employee, consultant, or agent of BetsMixer or working for any company related to BetsMixer, or a relative or spouse of any of the foregoing;</p>
<p>2.1.12. You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such problem gambling arises whilst using our services but will endeavor to inform of relevant assistance available. We reserve the right to implement cool off periods if we believe such actions will be of benefit.</p>
<p>2.1.13. You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay, and screen capture techniques. These steps may include, but are not limited to, the examination of Player's device properties, detection of geo-location and IP masking, transactions, and blockchain analysis;</p>
<p>2.1.14. You accept our right to terminate and/or change any games or events being offered on the Website, and to refuse and/or limit bets.</p>
<p>2.1.15. You accept that we have the right to ban/block multiple accounts and freely control the assets in such accounts.</p>
<p>2.1.16. You are aware of possible errors or incompleteness in the software; you agree to refrain from taking advantage of them. Moreover, you agree to report any error or incompleteness immediately to BetsMixer. Should you fail to fulfill the obligations stated in this clause, BetsMixer has the right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification by the user.</p>
<p>2.1.17. You are aware that BetsMixer has the right to carry out &ldquo;KYC&rdquo; (Know Your Customer) verification procedures. The access to your user account may be blocked or closed if we determine that you have supplied false or misleading information.</p>
<p>2.2. We reserve the right to declare a wager void partially or in full if BetsMixer, at its own discretion, would deem it obvious that any of the following circumstances have occurred:</p>
<p>2.2.1. You, or people associated with you, may directly or indirectly influence the outcome of an event, to obtain an unlawful advantage.</p>
<p>2.2.2. You and or people associated with you are directly or indirectly avoiding the rules of BetsMixer.</p>
<p>2.2.3. The result of an event has been directly or indirectly affected by criminal activity.</p>
<p>2.2.4. Wagers have been placed that would not have been accepted otherwise, but that were accepted during periods when the website has been affected by technical problems.</p>
<p>2.2.5. Due to an error, such as a mistake, vulnerabilities, technical error, force majeure or otherwise, wagers have been offered, placed, and or accepted due to this error.</p>
<p>2.2.6. If a player's deposit fee is too low and is flagged by blockchain or similar site as &ldquo;not enough fee to relay&rdquo; BetsMixer reserves the right to confiscate the winnings if BetsMixer at their own discretion deems the transaction and behavior of the player to be fraudulent in nature.</p>
<ol start="3">
<li><strong> Restricted Territories:</strong></li>
</ol>
<p>3.1. Blacklisted Territories: We allow all countries. You hereby consent to such disclosures.</p>
<ol start="4">
<li><strong> General Betting Rules:</strong></li>
</ol>
<p>4.1. A bet can only be placed by a registered account holder.</p>
<p>4.2. A bet can only be placed over the internet.</p>
<p>4.3. You can only place a bet if you have a sufficient balance in your account with BetsMixer.</p>
<p>4.4. The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being accepted.</p>
<p>4.5. Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at which the bet was placed.</p>
<p>4.6. BetsMixer reserves the right to adjust a bet payout credited to a BetsMixer account if it is determined by BetsMixer in its sole discretion that such a payout has been credited due to an error.</p>
<p>4.7. A bet, which has been placed and accepted, cannot be amended, withdrawn, or canceled by you.</p>
<p>4.8. The list of all the bets, their status, and details are available to you on the Website.</p>
<p>4.9. When you place a bet you acknowledge that you have read and understood in full all of this User Agreement regarding the bet as stated on the Website.</p>
<p>4.10. BetsMixer manages your account, and calculates the available funds, the pending funds, the betting funds as well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to be accurate.</p>
<p>4.11. You are fully responsible for the bets placed.</p>
<p>4.12. Winnings will be paid into your account after the final result is confirmed.</p>
<ol start="5">
<li><strong> Bonuses and Promotions:</strong></li>
</ol>
<p>5.1. BetsMixer reserves the right to cancel any promotion, bonus, or bonus program (including, but not limited to top-up rewards, invite friends to reward bonuses, and loyalty programs) with immediate effect if we believe the bonus has been set up incorrectly or is being abused, and if the said bonus has been paid out, we reserve the right to decline any Withdraw request and to deduct such amount from your account. Whether or not a bonus is deemed to be set up incorrectly or abused shall be determined solely by BetsMixer.</p>
<p>5.2. If you use a Deposit Bonus, no Withdrawal of your original deposit will be accepted before you have reached the requirements stipulated under the User Agreement of the Deposit Bonus.</p>
<p>5.3. Where any term of the offer or promotion is breached or there is any evidence of a series of bets placed by a customer or group of customers, which due to a deposit bonus, enhanced payments, free bets, risk-free bets, or any other promotional offer results in guaranteed customer profits irrespective of the outcome, whether individually or as part of a group, BetsMixer reserves the right to reclaim the bonus element of such offers and in their absolute discretion either settle bets at the correct odds, void the free bet bonus and risk-free bets or void any bet funded by the deposit bonus. In addition, BetsMixer reserves the right to levy an administration charge on the customer up to the value of the deposit bonus, free bet bonus, risk-free bet, or additional payment to cover administrative costs. We further reserve the right to ask any customer to provide sufficient documentation for us to be satisfied in our absolute discretion as to the customer's identity prior to us crediting any bonus, free bet, risk-free bet, or offer to their account.</p>
<p>5.4. All BetsMixer offers are intended for recreational players, and BetsMixer may in its sole discretion limit the eligibility of customers to participate in all or part of any promotion.</p>
<p>5.5. BetsMixer reserves the right to amend, cancel, reclaim or refuse any promotion at its discretion.</p>
<p>5.6. Bonuses can only be received once per person/account, family, household, address, e-mail address, IP addresses, and environments where computers are shared (university, fraternity, school, public library, workplace, etc.). The Operator reserves the right to close your account and confiscate any existing funds if evidence of abuse/fraud is found.</p>
<p>5.7. You acknowledge and understand that separate User Agreements exist with respect to promotions, bonuses, and special offers and are in addition to this User Agreement. This User Agreement is set forth in the respective content page on this website, or have been made available to you personally, as the case may be. In the event of a conflict between the provisions of such promotions, bonuses, and special offers, and the provisions of this User Agreement, the provisions of such promotions, bonuses, and special offers will prevail.</p>
<p>5.8. We may insist that you bet a certain amount of your own deposit before you can bet with any free/bonus funds we credit to your account.</p>
<p>5.9. You accept that certain promotions may be subject to Withdrawal restrictions and/or requirements which need to be met before funds credited under the promotion can be withdrawn. Such terms shall be duly published and made available as part of the promotion. If you opt to make a Withdrawal before the applicable wagering requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use of the bonus amounts before approving any Withdrawal.</p>
<ol start="6">
<li><strong> Live Chat:</strong></li>
</ol>
<p>6.1. As part of your use of the Website, we may provide you with a live chat facility, which is moderated by us and subject to controls. We reserve the right to review the chat and to keep a record of all statements made on the facility. Your use of the chat facility should be for recreational and socializing purposes.</p>
<p>6.2. We have the right to remove the chat room functionality or immediately terminate your Member Account and refund your account balance if you:</p>
<p>(a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred, or profanity;</p>
<p>(b) make statements that are abusive, defamatory, or harassing or insulting;</p>
<p>(c) use the chat facility to advertise, promote, or otherwise relate to any other online entities;</p>
<p>(d) make statements about BetsMixer, or any other Internet site(s) connected to the Website that are untrue and/or malicious and/or damaging to BetsMixer;</p>
<p>(e) use the chat facility to collude, engage in unlawful conduct or encourage conduct we deem seriously inappropriate. Any suspicious chats will be reported to the competent authority.</p>
<p>6.3. Live Chat is used as a form of communication between us and you and should not be copied or shared with any forums or third parties.</p>
<ol start="7">
<li><strong> Limitation of Liability:</strong></li>
</ol>
<p>7.1. You enter the Website and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever, whether expressed or implied.</p>
<p>7.2. Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers:</p>
<p>7.3. Do not warrant that the software, Games, and the Websites are fit for their purpose.</p>
<p>7.4. Do not warrant that the software, Games, and the Websites are free from errors.</p>
<p>7.5. Do not warrant that the software, Games, and the Websites will be accessible without interruptions.</p>
<p>7.6. Shall not be liable for any loss, costs, expenses, or damages, whether direct, indirect, special, consequential, incidental, or otherwise, arising in relation to your use of the Websites or your participation in the Games.</p>
<p>7.7. You understand and acknowledge that if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such funds.</p>
<p>7.8. You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss, damages, claims, and liabilities, howsoever caused that may arise in relation to your use of the Website or participation in the Games.</p>
<p>7.9. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty, or otherwise), will not exceed &euro;100.</p>
<ol start="8">
<li><strong> Breaches, Penalties, and Termination:</strong></li>
</ol>
<p>8.1. If you breach any provision of this User Agreement or we have reasonable grounds to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Member Account, or withhold payment of your winnings and apply such funds to any damages due by you.</p>
<p>8.2. You acknowledge that BetsMixer shall be the final decision-maker of whether you have violated BetsMixer&rsquo;s rules, terms, or conditions in a manner that results in your suspension or permanent barring from participation in our site.</p>
<ol start="9">
<li><strong> Self-exclusion:</strong></li>
</ol>
<p>9.1. By requesting a period of self-exclusion, you agree to follow the below terms and conditions, which will be in effect from the time that CS implements the chosen period of self-exclusion.</p>
<p>9.2. You may self-exclude for periods of 1, 3, 6, 12 month/s or permanent. Self-exclusion requests are to be made via Live Support.</p>
<p>9.3. Once you have self-excluded, you will not be able to access your account or withdraw during this period.</p>
<p>9.4. If you have excluded your account whilst you have pending bets on your account, bets placed will remain valid and settle according to official results.</p>
<p>9.5. Once the period of self-exclusion has lapsed, you may withdraw winnings from qualifying bets. BetsMixer does not cancel or void any bets placed before a self-exclusion has been affected.</p>
<p>9.6. Once you have self-excluded, you will not be able to change or alter the period for a shorter length of time or have your self-exclusion canceled until the period that you selected for self-exclusion has passed.</p>
<p>9.7. Please contact our customer services team if you wish to extend your self-exclusion period.</p>
<p>9.8. Once your self-exclusion period has elapsed, reinstatement of the account can be done by emailing the request to support@betsmixer.com.</p>
<p>9.9. By self-excluding, you agree that:</p>
<p>(a) You will not create another account during this period.</p>
<p>(b) You will not deposit or attempt to deposit funds into a BetsMixer account.</p>
<p>(c) You will not wager on this website during this period.</p>
<p>(d) This is a voluntary act initiated by yourself, and BMI Holding Ltd. will not be liable for any losses you may incur during the period of self-exclusion in any form.</p>
<p><strong>Privacy Policy:</strong></p>
<p>You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal data to allow you access and use of the Websites and in order to allow you to participate in the Games. We respect your privacy and will protect your personal information in accordance with best business practices and applicable laws. We will use your personal data to allow you to participate in the Games and to carry out operations relevant to your participation in the Games. We may also use your personal data to inform you of changes, new services, and promotions that we think you may find interesting. If you do not wish to receive such direct marketing correspondences, you may opt out of the service.</p>
<p>Your personal data will not be disclosed to third parties unless required by law. As BetsMixer's business partners or suppliers or service providers may be responsible for certain parts of the overall functioning or operation of the Website, personal data may be disclosed to them. The employees of BetsMixer have access to your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.</p>
<p>We shall keep all information provided as personal data. You have the right to access personal data held by us about you. No data shall be destroyed unless required by law or unless the information held is no longer required to be kept for the purpose of the relationship.</p>
<p>In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites, and to improve the service, we collect a small piece of information sent from your browser, called a cookie. You can, if you wish, turn off the collection of cookies. You must note, however, that turning off cookies may severely restrict or completely hinder your use of the Websites.</p>
<p><strong>CHANGES TO THIS POLICY</strong></p>
<p>We may revise this Policy from time to time and without prior notice to you. Except as otherwise noted in this Policy, such changes may apply to any personal information we already hold about you or personal information collected after the Policy is modified. Changes will be posted on this page and are effective as of the &ldquo;Last Modified&rdquo; date at the top of this Policy. Please visit this page regularly so that you are aware of our latest updates. Continuing to access or use the Sites or Services after any changes become effective indicates your acceptance of the revised Policy. In addition, we may provide you with &ldquo;just-in-time&rdquo; disclosures or additional information about the data processing practices of specific parts of our Sites or Services. Such notices may supplement this Policy or provide you with additional choices about how we process your personal information.</p>
<p><strong>PERSONAL INFORMATION</strong></p>
<p>We collect and process the following types of information. We may or may not create anonymous records from Personal Data for certain business purposes of BetsMixer and our Affiliates as defined below. Any information that is anonymized or aggregated is no longer Personal Data and we may indefinitely use it, share it, and retain it for the reasons outlined herein.</p>
<ol>
<li><strong>Contact Data.</strong> Personal Data about you used to contact you. For example, your name, the game title, email address, phone number, or mobile phone number.</li>
<li><strong>Profile Data.</strong>Personal Data related to a free or paid Customer account on our Services. For example Personal name, mobile phone number, e-mail address, website, physical address, username, password, and credit card account information.</li>
<li><strong>User Data.</strong> Personal Data of a Customer&rsquo;s Users utilized by BetsMixer on behalf of Customers to send invoices and/or estimates to and provide associated services to a Customer&rsquo;s Users. For example:</li>
<li><strong>Phone Number</strong>: We may collect your phone number if you provide it to us voluntarily. This information may be used to facilitate communication with you regarding our services or to provide important updates or notifications related to your account.</li>
<li><strong>Emails:</strong> We may collect email addresses associated with your account. These email addresses may include your primary email as well as any additional emails you provide. This allows us to communicate with you effectively and deliver important information regarding your account, such as service-related updates or notifications.</li>
<li><strong>Name and Address:</strong> To provide personalized services and enhance your user experience, we may collect your name and address. This information helps us address you properly.</li>
<li><strong>Device Invoices:</strong> As part of our service, we securely store and organize digital invoices for your accounts. This includes collecting and storing information such as the serial number, order number, date of purchase, and brand. These details enable us to accurately track and manage your gaming accounts subriptions.</li>
<li><strong>Insurance Invoices:</strong> If you provide us with insurance invoices related to your gaming accounts, we may collect and store relevant information from these invoices. This information may include policy numbers, coverage details, dates of coverage, and insurance provider information. It allows us to assist you in managing and tracking your device insurance.</li>
<li><strong>Emails from Retailers and Insurance Providers:</strong> In order to provide comprehensive services, we may collect and store emails you receive from retailers and insurance providers regarding electronic devices. This includes emails related to purchases, warranties, repairs, or insurance claims. These emails help us gather relevant information and assist you in managing your device-related matters effectively.</li>
<li><strong>Images Containing Device Invoices and Insurance Invoices:</strong> To provide a more user-friendly experience, we may offer the option to upload images containing games invoices. These images are securely stored and used to extract relevant information for managing your device warranties and insurance coverage. By entering User Data into our systems via the Sites and/or Services, you understand that BetsMixer is acting as a data processor providing services to you. You represent and warrant that you have the requisite authority to provide such Personal Data to us, and that the disclosure does not violate any applicable law or regulation, including but not limited to the Payment Card Industry Data Security Standard (PCI DSS), the Health Insurance Portability and Accountability Act (HIPAA), the California Consumer Privacy Act of 2018 (CCPA), the Personal Information Protection and Electronic Documents Act (PIPEDA), the EU General Data Protection Regulation (GDPR) or the UK General Data Protection Regulation (UK GDPR).</li>
</ol>
<p><strong>YOUR RIGHTS AND CHOICES</strong></p>
<p><strong>Rights. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship have the following rights in relation to your Personal Data, in each case to the extent required/permitted under applicable law, and subject to our rights to limit or deny access or disclosure under applicable law. Users and other consumers who do business with Customers utilizing BetsMixer Services must contact the Customers utilizing BetsMixer Services to exercise these rights. Users can request that the Customer provide you with access to the Personal Data BetsMixer stores about you on its behalf, that it make changes to that Personal Data, and/or that the Personal Data be deleted from BetsMixer systems. BetsMixer cannot honor such requests directly from Users but will assist Customers with honoring them.</p>
<p><strong>Access. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship, may request a list of your Personal Data that we process by submitting an official request in writing via email to address provided below.</p>
<p><strong>Rectification. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship may correct any Personal Data that we hold about you by emailing us at the address provided below and indicating both the inaccurate and corrected information. Customers may also login to your BetsMixer.com user account and modify your Personal Data.</p>
<p><strong>Erasure. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship may request that we delete your Personal Data from our systems once per year by making an official request in writing via email to the address provided below and indicating the specific information you would like permanently deleted from our systems. Note that Customers who request removal of their Personal Data will no longer have access to any existing BetsMixer account and will not be able to use any BetsMixer product or service. BetsMixer reserves the right to retain certain account information for its recordkeeping or compliance purposes.</p>
<p>Customers may also login to their BetsMixer user account and delete any Profile Data, Contact Data or Customer Data to which they have access. However, to ensure that Personal Data is completely removed from our systems, you must submit an official request in writing to BetsMixer at the address provided below, as using a system delete function may merely restrict viewing that data from any system interface and prevent utilizing that data for any system function rather than permanently deleting it.</p>
<p><strong>Data Export. </strong>Customers and Visitors may request a copy of your Personal Data in a common portable format of our choice by submitting an official request in writing via email to the address provided below.</p>
<p>We may require that you provide additional Personal Data to exercise these rights, e.g., information necessary to prove your identity. We also reserve the right to retain this additional information for our recordkeeping or compliance purposes.</p>
<p><strong>Choices. </strong>It is possible for you to access and use the Sites without providing any Personal Data, but you may not be able to access certain features or view certain content and some portions of the Sites may not function properly.&nbsp; You must provide Personal Data in order to utilize the Services. You have the following choices regarding Personal Data we process:</p>
<p><strong>Consent. </strong>If you consent to processing you may withdraw your consent at any time to the extent required by law.</p>
<p><strong>Cancellation. </strong>Customers may cancel their BetsMixer accounts by contacting us using the contact information provided below.</p>
<p><strong>Opt-Out. </strong>You may opt-out of all information collection from your user account by deactivating the account or contacting us to deactivate the account on your behalf.</p>
<p>You may opt-out of receiving marketing communications from us by following the opt-out instructions included in such communications. Any communications from us that are not service-related or transactional in nature will offer you an &ldquo;unsubscribe&rdquo; option. To the extent required by law, you may choose to opt-out of sharing Personal Data with third parties.</p>
<p><strong>Cookies. </strong>If you do not want information collected through the use of cookies or similar technologies, you can manage/deny cookies (and certain technologies) using your browser&rsquo;s settings menu or by using</p>
<p><strong>a variety of tools.</strong></p>
<ul>
<li>You can visit the Google Ads Settings page&nbsp;here.</li>
<li>You can use the Google Analytics Opt Out Browser add-on.</li>
<li>Digital Advertising Alliance&rsquo;s opt-out page&nbsp;here&nbsp;allows you to opt out from receiving third party advertiser cookies.</li>
<li>You can visit the Network Advertising Initiative opt-out page&nbsp;here.</li>
<li>To learn more about cookies and similar tracking technologies, and how they can affect your privacy, visit&nbsp;allaboutcookies.org.</li>
</ul>
<p>As there is no consistent industry understanding of how to respond to &ldquo;Do Not Track&rdquo; signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.</p>
<p><strong>SECURITY</strong></p>
<p>We have implemented reasonable security measures designed to secure your Personal Data from accidental loss, unauthorized access, use, alteration and disclosure, however we do not provide any guarantee that your Personal Data will remain secure. We are not responsible for the circumvention of any privacy settings or security measures contained on the Sites or Services. All information you provide to us is stored on our service provider&rsquo;s servers which utilize industry-standard security controls and maintain Payment Card Industry (PCI) Compliance where applicable; however, we do not have control over and will not be liable for third parties&rsquo; security processes. Any transmission of information or Personal Data is at your own risk. If you believe that you have experienced unauthorized access or use of your account, please contact us immediately.</p>
<p><strong>MINORS</strong></p>
<p>Our services are neither directed at nor intended for direct use by individuals under the age of 18 or the age of majority in the jurisdiction where they reside. Further we do not intentionally gather information about such individuals. If we learn that we have inadvertently done so, we will promptly delete it. Do not access or use the Sites or Services if you are not the age of majority in your jurisdiction.</p>
<p><strong>CONTACT US</strong></p>
<p>If you have any questions or concerns regarding this Privacy Policy, please contact us at</p>
<p>Email: info@betsmixer.com</p>
<p><strong>Cancellation Policy</strong></p>
<p><strong>Cancellation Policy Overview:</strong></p>
<p>The cancellation policy allows users to cancel or modify their subscription under certain conditions. All cancellations must be made in writing (e-mail, letter) by the guest, and the support will confirm the cancellation in writing (e-mail, letter).</p>
<p><strong>Cancellation Policy for Standard e-Gaming;</strong></p>
<ol>
<li>Cancellation up to 1 day before the subscription start date: Free of charge.</li>
<li>Cancellation 1 &ndash; 7 days after subscribing into our gaming services: Cancellation fee of 100% of the total price.</li>
</ol>
<p><strong>Flexible Price Reservations:</strong></p>
<p>Users can cancel or modify their subscription before the provision of new package start taking place(before the start date of the new subscription). Payment can be made through a secure payment gateway or upon subscribtion into our services.</p>
<p><strong>Early Termination of Stay:</strong></p>
<p>If a user terminates their subscription early, a cancellation fee of 100% of the price of the entire order will be charged, and the remaining subscription period will be canceled.</p>
<p><strong>Flexible Price Cancellation Policy:</strong></p>
<ol>
<li>8 days or more before the start date of the new subscription: Free of charge.</li>
<li>7 days or less before the start date of the new subscription: Cancellation fee of 100% of the total price.</li>
</ol>
<p><strong>Non-Refundable Price:</strong></p>
<p>Non-refundable prices are discounted rates for our gaming services. Subscriptions under this category cannot be canceled or modified. A 100% prepayment is required when making the subscription, and later cancellations will incur a cancellation fee of 100% of the subscription price.</p>
<p><strong>Method of Payment:</strong></p>
<p>Payments can be made using VISA, MAESTRO, MASTERCARD payment cards, Paypal, Payoneer, or through bank transfer.</p>
<p><strong>Exceptional Situations:</strong></p>
<p>In exceptional situations or cases, BetsMixer will assess and resolve issues on an individual basis.</p>
<p><strong>Calculation of Cancellation Fee:</strong></p>
<p>The cancellation fee is calculated from the total amount of the subscription and not from the amount of the advance payment.</p>
<p>Note: Please refer to individual stay packages for specific cancellation conditions as they may vary.</p>
<p><strong>Cookies Policy</strong></p>
<p><strong>What are cookies?</strong></p>
<p>Cookies are small text files placed on an internet user's computer by a web page server. They act as an identification card, allowing the website to recognize the user upon return visits. Cookies are harmless and do not store any personally identifiable information.</p>
<p><strong>Why do we use cookies on BetsMixer?</strong></p>
<p>BetsMixer uses two types of cookies: cookies set by us and cookies set by third parties. These cookies help us keep you signed in to your account during your visit and personalize the information displayed on the site based on your preferences.</p>
<p><strong>What cookies do we use on BetsMixer?</strong></p>
<ol>
<li>Below are the main cookies set by BetsMixer and their purposes:</li>
<li>_fp: Stores the browser's fingerprint. Lifetime: forever.</li>
<li>_t: Stores the timestamp of the user's first visit in the current session for unique visits statistics. Lifetime: browsing session.</li>
<li>_r: Stores the HTTP referrer for the current session to track external traffic sources. Lifetime: browsing session.</li>
<li>_c: Stores the identifier of the affiliate campaign for affiliate statistics. Lifetime: forever.</li>
<li>Cookies set by third parties for the wildcard domain: BetsMixer.</li>
<li>Google Analytics: _ga, _gat, _gid.</li>
<li>Zendesk: __zlcmid.</li>
<li>Cloudflare: __cfuid.</li>
<li>Please note that some browsers may keep background processes running, which can lead to session cookies being set between sessions. Third-party scripts may also set cookies to their domains.</li>
</ol>
<p><strong>How can I manage my cookies on BetsMixer?</strong></p>
<p>If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.</p>
<p><strong>Personal Data Protection Policy:</strong></p>
<p>BetsMixer prioritizes data security and employs various measures, including encryption, firewalls, VPN access, and DDoS mitigations, to safeguard your data.</p>
<p><strong>Server Protection Policy:</strong></p>
<ol>
<li>All servers have full encryption, including backups.</li>
<li>Firewalls and VPN access ensure secure connections.</li>
<li>Critical services are allowed only over VPN.</li>
<li>SSH port forwarding tunnels are utilized.</li>
<li>Services are protected and allowed only over VPN.</li>
<li>Servers have firewalls and only allow SSH port access.</li>
<li>Alerts are set up for critical services.</li>
</ol>
<p><strong>Data Breach Notification:</strong></p>
<p>In case of a personal data breach, BetsMixer will notify affected users following GDPR timeframes.</p>
<p><strong>Data International Transfer:</strong></p>
<ol>
<li>Personal data is only disclosed to third parties when necessary for high-quality service or to comply with lawful requests from authorities.</li>
<li>Specific data may be shared with Zendesk Inc. for customer support purposes.</li>
</ol>
<p><strong>Collecting and Using Your Personal Data:</strong></p>
<ol>
<li>BetsMixer may collect personally identifiable information to contact or identify users.</li>
<li>Usage Data, including IP addresses, browser information, and diagnostic data, may be collected automatically.</li>
<li>Users may choose to create accounts or log in via Third-Party Social Media Services, and relevant data may be collected accordingly.</li>
</ol>
<p><strong>Delete Personal Data:</strong></p>
<p>Users can request the deletion of their personal data by contacting support@betsmixer.com, subject to legal obligations.</p>
<p><strong>Registration and Login:</strong></p>
<ol>
<li>Users must be at least 18 years old to register.</li>
<li>Accurate and up-to-date information must be provided during registration.</li>
<li>Adding 2FA for added security is recommended.</li>
<li>Changes to registered email addresses are not supported; users may need to create a new account.</li>
</ol>
<p>This agreement was issued on August 7, 2023 by <strong className="seller-link flex flex-justify-center tbody-3">Mrs Shannon Kathe (ATTORNEY appointed BMI Holding Ltd.)</strong></p>
</div>
                </p>
            </>
        );
    }
}
