const data = [
  {
    name: "Repeator",
    content: `
   var config = {
    amount: 1,
    payout: 2.00
   } 

   engine.on('started_crash', onStart);

   function onStart(){
    engine.amount = config.amount;
    engine.payout = parseInt( config.payout * 100 );
    engine.play();
   }

    `,
  },
];

export default data;
