const initialState = {
    search_game: '',
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'SEARCH_GAME': {
            return {
                ...state,
                search_game: action.payload
            };
        }

        default:
            return state
    }
}

export default rootReducer;
