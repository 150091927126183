import React from "react";
import { Button, Row, Col, InputGroup, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import storage from "../../../../../Storage";
import { gameCoin } from "../../../../../actions/gameCoin";
import { setWallet } from "../../..../../../../../actions/gameWallet";
import {
  Event,
  wait,
  isValidNumber,
  forceSatoshiFormat,
  __,
} from "../../../../../Helper";

class AutoBet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      formData: this.props.formData,
      isLogged: storage.getKey("logged") !== null ? true : false,
      ButtonText: "Start Auto Mode",
      stop: false,
      started: false,
      disabled: false,
      disabledRate: false,
      onStart: false,
      betCount: 10,
      onWin: 0,
      onLost: 0,
      stopOnWin: 0,
      onWinReset: true,
      onLoseReset: true,
      disabledWin: true,
      disableLose: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      formData: props.formData,
    };
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    this.props.gameCoin();

    if (this._isMounted) {
      engine.on("play", () => this.play());
      engine.on("busted", (data) => this.busted(data));
      engine.on("error", () => this.error());
      engine.on("stop", () => this.stop());
    }
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.value;
      if (!isValidNumber(value)) return console.log("not valid number");
      this.setState({ [target.name]: value });
    }
  }

  play() {
    if (this._isMounted) {
      this.setState((prevState) => ({
        started: true,
        betCount: prevState.betCount - 1,
        disabled: true,
        ButtonText: "Stop Auto Mode",
        onStart: false,
      }));
    }
  }

  busted(data) {
    if (this._isMounted) {
      const { profit } = data;
      let { engine, betCount, stop } = this.state;

      engine.round = betCount;

      if (engine.auto) {
        if (profit != 0.0) {
          engine.isWin = true;
          engine.roundProfit = forceSatoshiFormat(profit);
        } else {
          engine.isWin = false;
        }

        if (betCount <= 0) {
          this.setState({
            started: false,
            ButtonText: "Start Auto Mode",
            disabled: false,
          });
          engine.init = false;
          this.setState({ betCount: 10 });
          if (engine.game === "plinko") {
            engine.round = 0;
            engine.stop();
          }
        } else {
          if (engine.init) if (!stop) this.placeAutoBet();
        }
      }
    }
  }

  error() {
    this.setState({
      started: false,
      ButtonText: "Start Auto Mode",
      disabled: false,
      onStart: false,
    });
  }

  stop() {
    this.setState({
      started: false,
      ButtonText: "Start Auto Mode",
      disabled: false,
      onStart: false,
    });
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { engine, isLogged, started, onStart } = this.state;

      if (!started) {
        this.setState({ onStart: true });
      }

      // Check User
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      if (started) {
        this.setState({
          stop: true,
          started: false,
          ButtonText: "Start Auto Mode",
          disabled: false,
        });
        engine.init = false;
        return;
      }

      this.placeAutoBet();
    }
  }

  placeAutoBet() {
    if (this._isMounted) {
      let { engine, formData, betCount, onWin, onLost, stopOnWin, started } =
        this.state;

      // if (engine.init) return;

      wait(1000).then(() => {
        // Auto add forms data
        __.forEach(formData, function (value, name) {
          engine[name] = value;
        });
        engine.init = true;
        engine.auto = true;
        engine.round = betCount;
        engine.amount = this.props.amount;
        engine.coin = this.props.coin;
        engine.betNumber = betCount;
        engine.stopOnWin = stopOnWin;
        engine.onWin = parseInt(onWin);
        engine.onLost = parseInt(onLost);
        engine.autoStart();
      });
    }
  }

  setNumber = (e, num) => {
    if (this._isMounted) {
      e.preventDefault();
      this.setState({ betCount: num });
    }
  };

  changeType = (e, name, type) => {
    if (this._isMounted) {
      e.preventDefault();

      if (name === "onwin") {
        if (type === "reset") {
          this.setState({ onWinReset: true, disabledWin: true });
        } else {
          this.setState({ onWinReset: false, disabledWin: false });
        }
      } else {
        if (type === "reset") {
          this.setState({ onLoseReset: true, disableLose: true });
        } else {
          this.setState({ onLoseReset: false, disableLose: false });
        }
      }
    }
  };

  render() {
    let { disabled, disabledWin, disableLose, started, onStart } = this.state;
    return (
      <div>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <InputGroup className="mb-1 bet-input">
              <InputGroup.Text>Bets Number</InputGroup.Text>
              <Form.Control
                type="number"
                disabled={disabled}
                className="form-control"
                required={true}
                value={this.state.betCount}
                onChange={(e) => this.setNumber(e, e.target.value)}
              />
              <InputGroup.Text>
                <Button
                  onClick={(e) => this.setNumber(e, 10)}
                  variant={
                    disabled
                      ? "btn btn-s-4 btn-xs disabled"
                      : "btn btn-s-4 btn-xs"
                  }
                >
                  10
                </Button>
                <Button
                  onClick={(e) => this.setNumber(e, 50)}
                  variant={
                    disabled
                      ? "btn btn-s-4 btn-xs disabled"
                      : "btn btn-s-4 btn-xs"
                  }
                >
                  50
                </Button>
                <Button
                  onClick={(e) => this.setNumber(e, 100)}
                  variant={
                    disabled
                      ? "btn btn-s-4 btn-xs disabled"
                      : "btn btn-s-4 btn-xs"
                  }
                >
                  100
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Col>

          <Col xl={6} md={6} sm={12}>
            <InputGroup className="mb-1 bet-input">
              <InputGroup.Text>Stop On Win</InputGroup.Text>
              <Form.Control
                disabled={disabled}
                type="number"
                className="form-control"
                name="stopOnWin"
                autoComplete={"off"}
                value={this.state.stopOnWin}
                onChange={this.handleInputChange}
              />
              <InputGroup.Text>
                <img
                  src={"/assets/images/" + __.toLower(this.props.coin) + ".png"}
                  className={"mini-coin-2 mr-1"}
                  alt=""
                />{" "}
                {this.props.coin}
              </InputGroup.Text>
            </InputGroup>
          </Col>

          <Col xl={6} md={6} sm={12}>
            <InputGroup className="mb-1 bet-input">
              <InputGroup.Text>On Win</InputGroup.Text>
              {!this.state.started ? (
                <>
                  <InputGroup.Text>
                    <Button
                      onClick={(e) => this.changeType(e, "onwin", "reset")}
                      variant={
                        this.state.onWinReset
                          ? "btn bg-cs2 after-text no-hover nh active2"
                          : "btn bg-cs2 after-text no-hover nh"
                      }
                      type="Button"
                    >
                      Reset
                    </Button>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <Button
                      onClick={(e) => this.changeType(e, "onwin", "increase")}
                      variant={
                        !this.state.onWinReset
                          ? "btn bg-cs2 after-text no-hover nh active2"
                          : "btn bg-cs2 after-text no-hover nh"
                      }
                      type="Button"
                    >
                      Increase by
                    </Button>
                  </InputGroup.Text>
                </>
              ) : (
                <>
                  <InputGroup.Text>
                    <Button
                      variant={"btn bg-cs2 after-text no-hover nh disabled"}
                      type="Button"
                    >
                      Reset
                    </Button>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <Button
                      variant={"btn bg-cs2 after-text no-hover nh disabled"}
                      type="Button"
                    >
                      Increase by
                    </Button>
                  </InputGroup.Text>
                </>
              )}
              <Form.Control
                disabled={disabledWin}
                type="text"
                className="form-control"
                name="onWin"
                min="0"
                max="100"
                autoComplete={"off"}
                value={this.state.onWin}
                onChange={this.handleInputChange}
              />
              <InputGroup.Text>
                <span className="bg-transparent after-text no-hover npt nh">
                  %
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Col>

          <Col xl={6} md={6} sm={12}>
            <InputGroup className="mb-1 bet-input">
              <InputGroup.Text>On Lose</InputGroup.Text>
              {!this.state.started ? (
                <>
                  <InputGroup.Text>
                    <Button
                      onClick={(e) => this.changeType(e, "onlose", "reset")}
                      variant={
                        this.state.onLoseReset
                          ? "btn bg-cs2 after-text no-hover nh active2"
                          : "btn bg-cs2 after-text no-hover nh"
                      }
                      type="Button"
                    >
                      Reset
                    </Button>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <Button
                      onClick={(e) => this.changeType(e, "onlose", "increase")}
                      variant={
                        !this.state.onLoseReset
                          ? "btn bg-cs2 after-text no-hover nh active2"
                          : "btn bg-cs2 after-text no-hover nh"
                      }
                      type="Button"
                    >
                      Increase by
                    </Button>
                  </InputGroup.Text>
                </>
              ) : (
                <>
                  <InputGroup.Text>
                    <Button
                      variant={"btn bg-cs2 after-text no-hover nh disabled"}
                      type="Button"
                    >
                      Reset
                    </Button>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <Button
                      variant={"btn bg-cs2 after-text no-hover nh disabled"}
                      type="Button"
                    >
                      Increase by
                    </Button>
                  </InputGroup.Text>
                </>
              )}
              <Form.Control
                disabled={disableLose}
                type="text"
                className="form-control"
                name="onLost"
                min="0"
                max="100"
                autoComplete={"off"}
                value={this.state.onLost}
                onChange={this.handleInputChange}
              />
              <InputGroup.Text>
                <span className="bg-transparent after-text no-hover npt nh">
                  %
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col xl={8} md={8} sm={12} className={"m-auto"}>
            <div className={"form-group mt-0 mb-0 rev"}>
              <Button
                variant={"btn btn-md btn-block my-2 btn-bet"}
                disabled={onStart}
                onClick={(e) => this.handleBet(e)}
                type="submit"
              >
                {this.state.ButtonText}
                {started && (
                  <>
                    <div className={"ml-2 spinner-border spinner-border-sm"} />
                  </>
                )}
              </Button>
            </div>
          </Col>
          <Col xl={4} md={4} sm={12} className={"m-auto"}>
            <p className="m-0">
              Use of auto bets is optional and players must take full
              responsibility for any attendant risks. We will not be held liable
              in this regard.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

AutoBet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(AutoBet);
