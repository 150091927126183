import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import List from "./List";

export default class Tournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  componentWillMount() {}

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>Tournament</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/tournament" />
        </Helmet>
        <div className="tournament-wrapper tournament-page">
          <h1>Tournaments</h1>

          <Card className="bg-transparent">
            <Card.Body>
              <Row>
                <Col sm={6} className="border py-2">
                  <Link to="/tournament/high-roller">
                    <img
                      className="img-fluid w-100"
                      src="/assets/images/tournamments/autumn-festival.png"
                    />
                    <div className="tournamentinside">
                      <h2>$150,000 Autumn Festival </h2>
                      <Link to="/tournament/high-roller" className="text-warning">
                        Read More <i className="mdi mdi-arrow-right" />
                      </Link>
                    </div>
                  </Link>
                </Col>
                <Col sm={6} className="border py-2">
                  <Link to="/tournament/summer-drops">
                    <img
                      className="img-fluid w-100"
                      src="/assets/images/tournamments/moneyrain.png"
                    />
                    <div className="tournamentinside">
                      <h2>Catch $25,000 Money Rain</h2>
                      <Link
                        to="/tournament/summer-drops"
                        className="text-warning"
                      >
                        Read More <i className="mdi mdi-arrow-right" />
                      </Link>
                    </div>
                  </Link>
                </Col>
                <Col sm={6} className="border py-2">
                  <img
                    className="img-fluid w-100"
                    src="/assets/images/tournamments/summer-drops-2.png"
                  />
                    <div className="tournamentinside">
                        <h2>Winner: BiggieBob - $10,000</h2>
                        <span className="text-grey">Event Finished</span>
                    </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}
