import React, { Component } from "react";
import { Modal, Col, Row, Dropdown, Button } from "react-bootstrap";
import storage from "../../../../Storage";
import Swal from "sweetalert2";
import socket from "../../../../Socket";
import { connect } from "react-redux";
import {
  __,
  decode,
  encode,
  forceSatoshiFormat,
  fixDate,
  wait,
  sendNotfication,
  Event,
} from "../../../../Helper";
import C from "../../../../Constant";
import { t } from "i18next";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'
import { width } from "@mui/system";
import { userUID } from "../../../../Auth";
import { withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";

const C_ = React.createElement;
// const stripePromise = loadStripe(
//   "pk_test_51P442OIM6R1qJmTWfMWnub5BUycbe8QSLIpxHEPrNj8T0m5P2BsaWJPf9ilMhnuj7vdr1WFyVuknuMANx5Wox0Px00Rk2zX4ch"
// );
const stripePromise = loadStripe(
  "pk_live_JSHe45dEgB2CD3h8vWp7NAY5"
);
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000000', // White text for high contrast against a dark background
      backgroundColor: '#ffffff', // Dark background for card elements
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif', // Standard font
      fontSize: '16px', // Reasonable font size for readability
      lineHeight: '48px', // Consistent line height
      // fontSmoothing: 'antialiased', // Smoother text rendering
      '::placeholder': {
        color: '#000000', // Grey placeholder text for subtlety
      },
      iconColor: '#000000', // Light icon color for consistency with dark theme
    },
    invalid: {
      color: '#ff4c4c', // Bright red for error indication
      iconColor: '#ff4c4c', // Matching icon color for errors
    },
  },
};

class Providers extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      amount: 30,
      providerLists: [],
      effect: "zoomIn",
      selectedProvider: "",
      url: null,
      token: null,
      cardNumber: "",
      holderName: null,
      holderFirstName: null,
      holderLastName: null,
      cardholderemail: null,
      validity_m: null,
      validity_y: null,
      cvv: "",
      remoteIP: null,
      sweetAlertInstance: null,
      icorePayUrl: "",
      icorePayModal: false,
      country: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      phone: "",
      redirectHTML: "",
      country_code: "",
      country_name: "",
      client_ip: "",
      bankCode: "",
      asianCountryCodes: [
        "BD", // Bangladesh
        "IN", // India
        "MY", // Malaysia
        "PK", // Pakistan
        "PH", // Philippines
        "TH", // Thailand
        "VN", // Vietnam
      ],
    };
    this.selectProvider = this.selectProvider.bind(this);
    this.purchase = this.purchase.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeIcore = this.closeIcore.bind(this);
    this.checkoutFormRef = React.createRef();
  }

  componentDidMount() {

    this._isMounted = true;
    this.setState({
      remoteIP: this.props.remoteIP,
      country_code: this.props.country_code,
      country_name: this.props.country_name,
      client_ip: this.props.remoteIP,
    });
    // socket.on(C.ACTIVE_PAYMENT, (data) => {
    //   let providers = decode(data);
    //   this.setState({ providerLists: providers });
    //   if (this.state.providerLists.length != 0)
    //     document.getElementById("lib-lbl").click();
    // });

    this.setState({ providerLists: [...this.props.providers]}, () => {
      if (this.state.providerLists.length != 0) {
        document.getElementById("lib-lbl").click();
      }
    });

    socket.on(C.CREATE_PAYMENT, (data) => {
      let paymentResult = decode(data);
      if (paymentResult.success) {
        if (paymentResult.provider == "ICore") {
          this.setState({
            icorePayModal: true,
            icorePayUrl: paymentResult.paymentUrl,
          });
        } else {
          if (this.state.selectedProvider === "Libernetix") {
            this.setState({ url: paymentResult.data.direct_post_url });
          } else if (this.state.selectedProvider === "Flexify") {
            this.setState({
              token:
                paymentResult.data.data.attributes.flow_data.metadata.token,
            });
          }
          let paymentDetails = {
            provider: this.state.selectedProvider,
            url: this.state.url,
            remoteIP: this.state.remoteIP,
            token: this.state.token,
            card_number: this.state.cardNumber,
            cardholder_name: this.state.holderName,
            expires:
              this.state.validity_m +
              "/" +
              this.state.validity_y.substring(2, 4),
            cvc: this.state.cvv,
          };
          socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        }
      } else {
        sendNotfication(
          this.props.t("there_is_some_issues_on_our_server"),
          "success",
          "bottom-left"
        );
      }
    });
    socket.on(C.BUY_CREDIT, (data) => {
      let result = decode(data);

      if (result.provider === "gatewaypay") {
        if (!result.success)
          sendNotfication(result.msg, "success", "bottom-left");
        else {
          if (result.data.responseCode == "7") {
            window.location.href = result.data["3dsUrl"];
          } else {
            sendNotfication(
              result.data.responseMessage,
              "success",
              "bottom-left"
            );
          }
        }
      } else {
        this.state.sweetAlertInstance.close();
        if (result.provider === "RAGA") {
          if (!result.success) {
            sendNotfication(
              result.data.decline_reason,
              "success",
              "bottom-left"
            );
          }
          if (typeof result.data === "string") {
            this.openPopup(result.data);
          }
        } else if (result.provider === "Flexify") {
          if (typeof result.data === "string") {
            this.openPopup(result.data);
          }
        } else if (result.provider === "Libernetix") {
          if (!result.success) {
            sendNotfication(
              this.props.t("there_is_wrong_card_detail_so_please_type_exactly"),
              "success",
              "bottom-left"
            );
          }
        } else if (result.provider === "feex") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            // this.setState({redirectHTML: atob(result.data), icorePayModal: true});
            const newWindow = window.open("", "_blank");
            newWindow.document.write(atob(result.data));
            newWindow.document.close();
          }
        } else if (result.provider === "payplanet") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            // this.setState({redirectHTML: atob(result.data), icorePayModal: true});
            window.location.href = result.data;
          }
        } else if (result.provider === "p2pay") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            window.open(result.data.paymentUrl);
          }
        } else if (result.provider === "pspline") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            window.open(result.data);
          }
        } else if (result.provider === "pear2pay") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            window.open(result.data);
          }
        } else if (result.provider === "flexify") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            window.open(result.data);
          }
        } else if (result.provider === "paycc") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            const width = 800;
            const height = 600;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            window.open(
              result.data,
              "_blank",
              `width=${width},height=${height},left=${left},top=${top}`
            );
          }
        } else if (result.provider === "paycos") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            const width = 800;
            const height = 600;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            window.open(
              result.data,
              "_blank",
              `width=${width},height=${height},left=${left},top=${top}`
            );
          }
        } else if (result.provider === "ampay") {
          if (!result.success)
            sendNotfication(result.msg, "success", "bottom-left");
          else {
            const width = 800;
            const height = 600;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            window.open(
              result.data,
              "_blank",
              `width=${width},height=${height},left=${left},top=${top}`
            );
          }
        } else if (result.provider === "stripe") {
          sendNotfication(result.msg, "success", "bottom-left");
        } 
      }
    });

    socket.on(C.NOTIFY_PAYMENT, (data) => {
      let purchase = decode(data);

      if (purchase.hasOwnProperty("error") && purchase.error) {
        sendNotfication(purchase.message, "danger", "bottom-left");
      } else {
        sendNotfication(
          this.props.t("successfully_you_bought_credits_with_fiat") +
          " " +
          purchase.amount +
          " " +
          purchase.coin,
          "success",
          "bottom-left"
        );
      }
      this.state.sweetAlertInstance.close();
      // Event.emit("deposit_close");
      socket.emit(C.GET_USER_PROFIT);
    });
    if (!__.isNull(document.getElementById("lib-lbl")))
      document.getElementById("lib-lbl").click();
    // let libSec = document.querySelector('.card-detials-lib');
    // this.expandSection(libSec)
    // libSec.setAttribute('data-collapsed', 'false');
  }

  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.CREATE_PAYMENT);
    socket.off(C.BUY_CREDIT);
    socket.off(C.NOTIFY_PAYMENT);
    socket.off(C.ACTIVE_PAYMENT);
  }

  async purchase(event) {
    event.preventDefault();

    if(this.state.amount < 30) {

      Swal.fire({
        html: `<span class="text-white">Minimum amount for deposit is 30 usd</span>`,
        showConfirmButton: true,
        customClass: {
          popup: "my-sweet-popup",
          htmlContainer: "my-htmlContainer",
        },
        allowOutsideClick: true,
        allowEscapeKey: true,
      });

      return;
    }
	
	
    if (this.state.selectedProvider == "gatewaypay") {
      const { location } = this.props;
      const currentUri = location.pathname;

      let paymentDetails = {
        provider: this.state.selectedProvider,
        first_name: this.state.holderFirstName,
        last_name: this.state.holderLastName,
        customer_order_id: userUID(),
        email: this.state.cardholderemail,
        amount: this.state.amount,
        currency: "USD",
        ip_address: this.state.remoteIP,
        response_url: `https://betsmixer.com${currentUri}`,
        webhook_url: "https://api.betsmixer.com/gatewaypay/callback",
      };
      socket.emit(C.BUY_CREDIT, encode(paymentDetails));
    } else {
      let sweetAlertInstance1 = Swal.fire({
        html: `<svg class="pro-spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>`,
        showConfirmButton: false,
        customClass: {
          popup: "my-sweet-popup",
          htmlContainer: "my-htmlContainer",
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      this.setState({ sweetAlertInstance: sweetAlertInstance1 });
      if (
        !this.state.amount ||
        this.state.amount === "" ||
        this.state.amount === 0 ||
        this.state.amount < 0
      ) {
        sendNotfication(
          "Please type USD amount you want to deposit.",
          "success",
          "bottom-left"
        );
        return;
      }
      if (
        this.state.selectedProvider === "feex" &&
        _.includes(this.state.asianCountryCodes, this.state.country_code)
      ) {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          asia: true,
          country_code: this.state.country_code,
          bankCode: this.state.bandCode,
          amount: this.state.amount,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "p2pay") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          amount: this.state.amount,
          country_code: this.state.country_code,
          country_name: this.state.country_name,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "pspline") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          amount: this.state.amount,
          client_ip: this.state.client_ip,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "pear2pay") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          amount: this.state.amount,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "flexify") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          amount: this.state.amount,
          id: storage.getKey("uid"),
          email: storage.getKey("email"),
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "paycos") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          product: "USDT Deposit",
          amount: this.state.amount * 100, //min:100, max: 50000
          currency: "INR",
          callbackUrl: "https://api.betsmixer.com/paycos/callback",
          redirectSuccessUrl: "https://api.betsmixer.com/paycos/success",
          redirectFailUrl: "https://api.betsmixer.com/paycos/fail",
          extraReturnParam: userUID(),
          orderNumber: `${userUID()}${new Date().getTime()}`,
        };

        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "paycc") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          client_orderid: userUID(),
          order_desc: "Crypto Deposit",
          first_name: this.state.holderFirstName,
          last_name: this.state.holderLastName,
          // address1: this.state.address,
          // city: this.state.city,
          // state: this.state.state,
          // zip_code: this.state.zip_code,
          address1: "Test st",
          city: "San Antonio",
          state: "TX",
          zip_code: "00000",
          country: this.state.country.toUpperCase(),
          phone: this.state.phone,
          amount: this.state.amount,
          email: storage.getKey("email")
            ? storage.getKey("email")
            : this.state.cardholderemail,
          currency: "USD",
          ipaddress: this.state.remoteIP,
          redirect_success_url: "https://api.betsmixer.com/paycc/success",
          redirect_fail_url: "https://api.betsmixer.com/paycc/fail",
          server_callback_url: "https://api.betsmixer.com/paycc/callback",
        };

        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }
      if (this.state.selectedProvider === "gumballpay") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          client_orderid: userUID(),
          order_desc: "Crypto Deposit",
          first_name: this.state.holderFirstName,
          last_name: this.state.holderLastName,
          // address1: this.state.address,
          // city: this.state.city,
          // state: this.state.state,
          // zip_code: this.state.zip_code,
          address1: "Test st",
          city: "San Antonio",
          state: "TX",
          zip_code: "00000",
          country: this.state.country.toUpperCase(),
          phone: this.state.phone,
          amount: this.state.amount,
          email: storage.getKey("email")
            ? storage.getKey("email")
            : this.state.cardholderemail,
          currency: "USD",
          ipaddress: this.state.remoteIP,
          redirect_success_url: "https://api.betsmixer.com/gumballpay/success",
          redirect_fail_url: "https://api.betsmixer.com/gumballpay/fail",
          server_callback_url: "https://api.betsmixer.com/gumballpay/callback",
        };

        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      } else if (this.state.selectedProvider === "stripe") {
        if (this.checkoutFormRef.current) {
          this.checkoutFormRef.current.handleSubmit(); // Ensure this method exists in the child
        }
        return;
      }
      const fullNameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
      if (!fullNameRegex.test(this.state.holderName)) {
        sendNotfication(
          "You should type holder's full name.",
          "success",
          "bottom-left"
        );
        this.state.sweetAlertInstance.close();
        return;
      }
      if (!this.state.validity_m || this.state.validity_m === "MM") {
        sendNotfication(
          "Please choose expiry month.",
          "success",
          "bottom-left"
        );
        this.state.sweetAlertInstance.close();
        return;
      }
      if (!this.state.validity_y || this.state.validity_y === "YYYY") {
        sendNotfication("Please choose expiry year.", "success", "bottom-left");
        this.state.sweetAlertInstance.close();
        return;
      }
      if (!this.state.cvv) {
        sendNotfication("Please type CVC/CVV.", "success", "bottom-left");
        this.state.sweetAlertInstance.close();
        return;
      }

      if (!this.state.selectedProvider) {
        sendNotfication(
          "Please choose a provider you want to deposit.",
          "success",
          "bottom-left"
        );
        return;
      }
      if (this.state.selectedProvider === "payplanet") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          // card_number: this.state.cardNumber,
          // cardholder_name: this.state.holderName,
          // expires: this.state.validity_m + '/' + this.state.validity_y,
          // cvc: this.state.cvv,
          amount: this.state.amount,
          country: "US",
          phone: "+1 479 802 0192",
          street: "Test st",
          state: "TX",
          city: "San Antonio",
          zip_code: "00000",
          email: storage.getKey("email"),
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
        return;
      }

      if (this.state.selectedProvider === "raga") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          url: this.state.url,
          remoteIP: this.state.remoteIP,
          token: this.state.token,
          card_number: this.state.cardNumber,
          cardholder_name: this.state.holderName,
          expires: this.state.validity_m + "/" + this.state.validity_y,
          cvc: this.state.cvv,
          amount: this.state.amount,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
      } else if (this.state.selectedProvider === "feex") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          card_number: this.state.cardNumber,
          cardholder_name: this.state.holderName,
          expires: this.state.validity_m + "/" + this.state.validity_y,
          cvc: this.state.cvv,
          amount: this.state.amount,
          country: this.state.country,
          phone: this.state.phone,
          street: "Test st",
          state: "Kiev",
          post: "00000",
          email: storage.getKey("email"),
          country_code: this.state.country_code,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
      } else if (this.state.selectedProvider === "ampay") {
        let paymentDetails = {
          provider: this.state.selectedProvider,
          token: "CARDEUR",
          // amount: "10",
          amount: this.state.amount,
          client_transaction_id: `${userUID()}${new Date().getTime()}`,
          client_merchant_id: userUID(),
          // call_back_url: 'https://fdb0-103-214-44-138.ngrok-free.app/ampay/callback',
          call_back_url: "https://api.betsmixer.com/ampay/callback",
          card: {
            card_number: this.state.cardNumber.replace(/\s/g, ""),
            card_expire:
              this.state.validity_m + "/" + this.state.validity_y.slice(-2),
            card_cvc: this.state.cvv,
            card_holder: this.state.holderName,
          },
          // card: {
          //     card_number: "4213523348070471",
          //     card_expire: "04/26",
          //     card_cvc: "908",
          //     card_holder: "Mikita Lohvin"
          // },
          // card: {
          //     card_number: "5350678700020074",
          //     card_expire: "02/26",
          //     card_cvc: "026",
          //     card_holder: "ALEXANDR SHMELEV"
          // },
          // test_mode: true,
        };
        socket.emit(C.BUY_CREDIT, encode(paymentDetails));
      } else {
        let paymentData = {
          amount: this.state.amount,
          provider: this.state.selectedProvider,
        };
        socket.emit(C.CREATE_PAYMENT, encode(paymentData));
      }
    }
  }

  onChange(value) {
    this.setState({ amount:parseFloat(value) });
  }

  selectProvider(provider_name) {
    if (provider_name == "paycos") this.setState({ amount: 100 });
    else this.setState({ amount: 30 });
    this.setState({ selectedProvider: provider_name });
    if (provider_name !== this.state.selectedProvider) {
      let section;
      this.state.providerLists.map((provider, index) => {
        if (provider.name != "icorepay" && provider.name != "payplanet") {
          section = document.getElementById(provider.name);
          if (provider.name == provider_name) this.expandSection(section);
          else this.collapseSection(section);
        }
      });
      // var isCollapsed = section.getAttribute('data-collapsed') === 'true';
      // if(isCollapsed) {
      //     this.expandSection(section)
      //     section.setAttribute('data-collapsed', 'false')
      // } else {
      //     this.collapseSection(section)
      // }
      // if (provider === this.state.providerLists[2].name) {
      //     section = document.querySelector('.card-detials-raga');
      //     // libSec = document.querySelector('.card-detials-lib');
      //     // flexSec = document.querySelector('.card-detials-flex');
      //     // let libIsCollapsed = libSec.getAttribute('data-collapsed') === 'true';
      //     // if (!libIsCollapsed) this.collapseSection(libSec)
      //     // let flexIsCollapsed = flexSec.getAttribute('data-collapsed') === 'true';
      //     // if (!flexIsCollapsed) this.collapseSection(flexSec)
      // } else if (provider === this.state.providerLists[1].name) {
      //     section = document.querySelector('.card-detials-flex');
      //     // libSec = document.querySelector('.card-detials-lib');
      //     // let libIsCollapsed = libSec.getAttribute('data-collapsed') === 'true';
      //     // if (!libIsCollapsed) this.collapseSection(libSec)
      //     // ragaSec = document.querySelector('.card-detials-raga');
      //     // let RagaIsCollapsed = ragaSec.getAttribute('data-collapsed') === 'true';
      //     // if (!RagaIsCollapsed) this.collapseSection(ragaSec)
      // } else if (provider === this.state.providerLists[0].name) {
      //     section = document.querySelector('.card-detials-lib');
      //     // ragaSec = document.querySelector('.card-detials-raga');
      //     // let RagaIsCollapsed = ragaSec.getAttribute('data-collapsed') === 'true';
      //     // if (!RagaIsCollapsed) this.collapseSection(ragaSec)
      //     // flexSec = document.querySelector('.card-detials-flex');
      //     // let flexIsCollapsed = flexSec.getAttribute('data-collapsed') === 'true';
      //     // if (!flexIsCollapsed) this.collapseSection(flexSec)
      // }
      // var isCollapsed = section.getAttribute('data-collapsed') === 'true';
      // if(isCollapsed) {
      //     this.expandSection(section)
      //     section.setAttribute('data-collapsed', 'false')
      // } else {
      //     this.collapseSection(section)
      // }
    }
  }

  collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = "";
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + "px";
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + "px";
      });
    });

    // mark the section as "currently collapsed"
    element.setAttribute("data-collapsed", "true");
  }

  expandSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    if (element.id != 'stripe') element.style.height = sectionHeight + "px";
    else element.style.height = "49px";

    // when the next css transition finishes (which should be the one we just triggered)
    const handleTrans = (e) => {
      // remove "height" from the element's inline styles, so it can return to its initial value
      element.style.height = null;
    };
    element.addEventListener("transitionend", handleTrans);
    // remove this event listener so it only gets triggered once
    element.removeEventListener("transitionend", handleTrans);

    // mark the section as "currently not collapsed"
    element.setAttribute("data-collapsed", "false");
  }

  openPopup(content) {
    const htmlContent = content;
    const width = 400;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const popupWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    popupWindow.document.open();
    popupWindow.document.write(htmlContent);
    popupWindow.document.close();
  }
  cc_format(value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }
    // return parts.length > 1 ? parts.join(" ") : value;
    return parts.join(" ");
  }

  cvc_format(value) {
    const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    return v;
  }
  icorePay() {
    let paymentData = {
      amount: this.state.amount,
      provider: "iCore_Pay",
    };
    socket.emit(C.CREATE_PAYMENT, encode(paymentData));
  }
  closeIcore() {
    this.setState({ icorePayModal: false });
  }
  selectCountry(value) {
    this.setState({ country: value });
  }
  setPhone(phone, country) {
    this.setState({ phone: phone, country: country.country.iso2 });
  }
  render() {
    const { t } = this.props;
    const { providerLists, selectedProvider, amount, sweetAlertInstance } = this.state;
    return (
      <>

        <Row className="m-0">
          <div className="m-4 provider-top">
            <div className="provider-container">
              <AmountBox
                selectedProvider={selectedProvider}
                amount={amount}
                onChange={this.onChange}
                t={t}
              />
              <div className="pp-container">
                <div className="card__container">
                  {providerLists.length > 0 && (
                    <>
                      <div className="provider-card">
                        {providerLists.map((provider, index) => {
                          return (
                            <>
                              {/* <h2>{this.state.selectedProvider}</h2> */}
                              {/* <h2>{provider.name}</h2> */}
                              <div key={index}>
                                <div className="credit">
                                  <div className="left">
                                    <input
                                      id={`cd-${provider.name}`}
                                      type="radio"
                                      checked={
                                        this.state.selectedProvider ===
                                        provider.name
                                      }
                                      name="payment"
                                    />
                                    <div className="radio"></div>
                                    <label
                                      htmlFor={`cd-${provider.name}`}
                                      id="lib-lbl"
                                      onClick={() =>
                                        this.selectProvider(provider.name)
                                      }
                                      style={{ color: "#1c9a36" }}
                                    >
                                      MasterCard / VISA{" "}
                                      {provider.name != "stripe" && (
                                        <span class="providernamecard">
                                          ({provider.name})
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <div className="right-part">
                                    <div className="right">
                                      <svg
                                        className="svg-icon"
                                        style={{
                                          width: "20px",
                                          height: "1em",
                                          verticalAlign: "middle",
                                          fill: "white",
                                          overflow: "hidden",
                                          transform:
                                            "rotate(" +
                                            (this.state.selectedProvider ===
                                              provider.name
                                              ? 0
                                              : 90) +
                                            "deg)",
                                        }}
                                        viewBox="0 0 1902 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M155.204535 74.710455C126.615552 46.121472 80.483035 45.902117 51.719826 74.665326L51.719826 74.665326C23.155712 103.22944 23.012571 149.397723 51.378322 177.763474L827.770953 954.156105C884.758821 1011.143973 977.122889 1011.175424 1034.142208 954.156105L1810.534839 177.763474C1838.910318 149.387995 1838.956544 103.428535 1810.193335 74.665326L1810.193335 74.665326C1781.629294 46.101285 1735.477321 45.94176 1706.708626 74.710455L982.721463 798.697618C954.132553 827.286601 907.960393 827.466313 879.191698 798.697618L155.204535 74.710455 155.204535 74.710455Z" />
                                      </svg>
                                    </div>
                                    <img
                                      src={
                                        "/assets/images/visa-mastercard-badge.png"
                                      }
                                      className="badgecreditcard"
                                    />
                                  </div>
                                </div>
                                <div
                                  id={provider.name}
                                  className="card-detials-lib"
                                  data-collapsed="true"
                                  style = {{background: "#ffffff"}}
                                >
                                  {provider.name == "gatewaypay" && (
                                    <>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderfirstname">
                                            {t("first_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Richard"
                                            class="cardholdername"
                                            value={this.state.holderFirstName}
                                            id="cardholderfirstname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderFirstName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderlastname">
                                            {t("last_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Bovell"
                                            class="cardholdername"
                                            value={this.state.holderLastName}
                                            id="cardholderlastname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderLastName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderemail">
                                            {t("email_address")}
                                          </label>
                                          <input
                                            type="email"
                                            id="cardholderemail"
                                            autoComplete={"off"}
                                            className="form-control"
                                            value={this.state.cardholderemail}
                                            required={true}
                                            onChange={(e) =>
                                              this.setState({
                                                cardholderemail: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {provider.name == "paycc" && (
                                    <>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderfirstname">
                                            {t("first_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Richard"
                                            class="cardholdername"
                                            value={this.state.holderFirstName}
                                            id="cardholderfirstname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderFirstName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderlastname">
                                            {t("last_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Bovell"
                                            class="cardholdername"
                                            value={this.state.holderLastName}
                                            id="cardholderlastname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderLastName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      {!storage.getKey("email") && (
                                        <div className="pp-cardholder">
                                          <div className="info">
                                            <label htmlFor="cardholderemail">
                                              {t("email_address")}
                                            </label>
                                            <input
                                              type="email"
                                              id="cardholderemail"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder="Enter Your Email"
                                              value={this.state.cardholderemail}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  cardholderemail:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div className="pp-cardholder d-none">
                                        <div className="info">
                                          <label htmlFor="country">
                                            {t("country")}
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              id="country"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder={t(
                                                "enter_your_country"
                                              )}
                                              value={this.state.country.toUpperCase()}
                                              required={true}
                                              disabled={false}
                                              onChange={(e) =>
                                                this.setState({
                                                  country: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pp-cardholder d-none">
                                        <div className="info">
                                          <label htmlFor="address">
                                            {t("address")}
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              id="address"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder={t(
                                                "enter_your_address"
                                              )}
                                              value={this.state.address}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  address: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pp-cardholder d-none">
                                        <div className="info">
                                          <label htmlFor="city">
                                            {t("city")}
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              id="city"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder={t("enter_your_city")}
                                              value={this.state.city}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  city: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pp-cardholder d-none">
                                        <div className="info">
                                          <label htmlFor="state">State</label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              id="state"
                                              autoComplete={"off"}
                                              placeholder="Enter Your State"
                                              className="form-control"
                                              value={this.state.state}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  state: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pp-cardholder d-none">
                                        <div className="info">
                                          <label htmlFor="zip_code">
                                            {t("zip_code")}
                                          </label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              id="zip_code"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder={t(
                                                "enter_your_zip_code"
                                              )}
                                              value={this.state.zip_code}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  zip_code: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="mt-2"
                                        style={{ width: "100%" }}
                                      >
                                        <PhoneInput
                                          defaultCountry="de"
                                          value={this.state.phone}
                                          onChange={(phone, country) =>
                                            this.setPhone(phone, country)
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                  {provider.name == "gumballpay" && (
                                    <>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderfirstname">
                                            {t("first_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Richard"
                                            class="cardholdername"
                                            value={this.state.holderFirstName}
                                            id="cardholderfirstname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderFirstName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="pp-cardholder">
                                        <div className="info">
                                          <label htmlFor="cardholderlastname">
                                            {t("last_name")}
                                          </label>
                                          <input
                                            placeholder="e.g. Bovell"
                                            class="cardholdername"
                                            value={this.state.holderLastName}
                                            id="cardholderlastname"
                                            type="text"
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                holderLastName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      {!storage.getKey("email") && (
                                        <div className="pp-cardholder">
                                          <div className="info">
                                            <label htmlFor="cardholderemail">
                                              {t("email_address")}
                                            </label>
                                            <input
                                              type="email"
                                              id="cardholderemail"
                                              autoComplete={"off"}
                                              className="form-control"
                                              placeholder="Enter Your Email"
                                              value={this.state.cardholderemail}
                                              required={true}
                                              onChange={(e) =>
                                                this.setState({
                                                  cardholderemail:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        className="mt-2"
                                        style={{ width: "100%" }}
                                      >
                                        <PhoneInput
                                          defaultCountry="de"
                                          value={this.state.phone}
                                          onChange={(phone, country) =>
                                            this.setPhone(phone, country)
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                  {provider.name != "gatewaypay" &&
                                    provider.name != "paycc" &&
                                    provider.name != "gumballpay" && (
                                      <>
                                        {(provider.name !== "pear2pay" &&
                                          provider.name != "flexify" &&
                                          provider.name != "pspline" &&
                                          provider.name !== "p2pay" &&
                                          provider.name !== "feex" &&
                                          provider.name !== "paycos") ||
                                          (provider.name === "feex" &&
                                            !_.includes(
                                              this.state.asianCountryCodes,
                                              this.state.country_code
                                            )) ? (
                                          provider.name == "stripe" ?
                                            <div>
                                                <Elements stripe={stripePromise}>
                                                  <InjectedCheckoutForm ref={this.checkoutFormRef} amount={amount} sweetAlertInstance={sweetAlertInstance} />
                                                </Elements>
                                            </div>
                                            :
                                            <>
                                              <div className="pp-cardholder">
                                                <div className="info">
                                                  <label
                                                    htmlFor="cardholdername"
                                                    className="cardholdernames"
                                                  >
                                                    {t("name")}
                                                  </label>
                                                  <input
                                                    placeholder="Your Name"
                                                    class="cardholdername"
                                                    value={this.state.holderName}
                                                    id="cardholdername"
                                                    type="text"
                                                    onKeyDown={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                    onChange={(e) =>
                                                      this.setState({
                                                        holderName:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="pp-number">
                                                <div className="info">
                                                  <label
                                                    htmlFor="cardnumber"
                                                    class="cardnumber"
                                                  >
                                                    {t("card_number")}
                                                  </label>
                                                  <input
                                                    id="cardnumber"
                                                    type="tel"
                                                    value={this.cc_format(
                                                      this.state.cardNumber
                                                    )}
                                                    pattern="[0-9]{16,19}"
                                                    inputMode="numeric"
                                                    maxLength="19"
                                                    placeholder="____-____-____-____"
                                                    onChange={(e) => {
                                                      this.setState({
                                                        cardNumber:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <div className="pp-details">
                                                <div className="left">
                                                  <label
                                                    htmlFor="expiry-date"
                                                    class="expiry-date"
                                                  >
                                                    {t("expiry")}
                                                  </label>
                                                  <select
                                                    id="expiry-date-m"
                                                    name="expiry-month"
                                                    class="expiry-month"
                                                    value={this.state.validity_m}
                                                    style={{ direction: "rtl" }}
                                                    onChange={(e) => {
                                                      this.setState({
                                                        validity_m:
                                                          e.target.value,
                                                      });
                                                      let color;
                                                      if (
                                                        e.target.value === "MM"
                                                      ) {
                                                        color = "#444880";
                                                      } else {
                                                        color = "white";
                                                      }
                                                      document
                                                        .getElementsByName(
                                                          "expiry-month"
                                                        )
                                                        .forEach(
                                                          (item) =>
                                                          (item.style.color =
                                                            color)
                                                        );
                                                    }}
                                                  >
                                                    <option
                                                      value="MM"
                                                      className="d-none"
                                                    >
                                                      MM
                                                    </option>
                                                    <option value="01">01</option>
                                                    <option value="02">02</option>
                                                    <option value="03">03</option>
                                                    <option value="04">04</option>
                                                    <option value="05">05</option>
                                                    <option value="06">06</option>
                                                    <option value="07">07</option>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                  </select>
                                                  <span>/</span>
                                                  <select
                                                    id="expiry-date-y"
                                                    name="expiry-year"
                                                    class="expiry-year"
                                                    value={this.state.validity_y}
                                                    onChange={(e) => {
                                                      this.setState({
                                                        validity_y:
                                                          e.target.value,
                                                      });
                                                      let color;
                                                      if (
                                                        e.target.value === "YYYY"
                                                      ) {
                                                        color = "#444880";
                                                      } else {
                                                        color = "white";
                                                      }
                                                      document
                                                        .getElementsByName(
                                                          "expiry-year"
                                                        )
                                                        .forEach(
                                                          (item) =>
                                                          (item.style.color =
                                                            color)
                                                        );
                                                    }}
                                                  >
                                                    <option
                                                      value="YYYY"
                                                      className="d-none"
                                                    >
                                                      YYYY
                                                    </option>
                                                    {Array.from(
                                                      { length: 11 },
                                                      (_, i) => {
                                                        const year =
                                                          new Date().getFullYear() +
                                                          i;
                                                        return (
                                                          <option
                                                            key={year}
                                                            value={year}
                                                          >
                                                            {year}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </select>
                                                </div>
                                                <div className="right">
                                                  <label
                                                    htmlFor="cvv"
                                                    class="cvv"
                                                  >
                                                    CVV
                                                  </label>
                                                  <input
                                                    type="tel"
                                                    id="p_cvv"
                                                    value={this.cvc_format(
                                                      this.state.cvv
                                                    )}
                                                    class="cvvf"
                                                    maxlength="3"
                                                    placeholder="_ _ _"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        cvv: e.target.value,
                                                      })
                                                    }
                                                  />
                                                  <span
                                                    data-balloon-length="medium"
                                                    data-balloon="The 3 or 4-digit number on the back of your card."
                                                    data-balloon-pos="up"
                                                  >
                                                    i
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                        ) : (
                                          <>
                                            {this.state.country_code ==
                                              "BGD" && (
                                                <div className="pp-cardholder">
                                                  <div className="info">
                                                    <label htmlFor="bankCode">
                                                      Bank Code
                                                    </label>
                                                    <input
                                                      class="cardholdername"
                                                      value={this.state.bankCode}
                                                      id="bankCode"
                                                      type="text"
                                                      onKeyDown={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      onChange={(e) =>
                                                        this.setState({
                                                          bankCode:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </>
                                    )}
                                </div>
                              </div >
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                {/* {
                                    (selectedProvider == 'feex') &&
                                    <div className="mt-2" style={{ width: '100%' }}>
                                        <PhoneInput
                                            defaultCountry="ua"
                                            value={this.state.phone}
                                            onChange={(phone, country) => this.setPhone(phone, country)}
                                        />
                                    </div>
                                } */}
                <div className="provider-button">
                  {selectedProvider != "gatewaypay" &&
                    selectedProvider != "paycc" &&
                    selectedProvider != "gumballpay" && (
                      <button
                        type="submit"
                        disabled={
                          (this.state.bankCode == "" &&
                            selectedProvider == "feex" &&
                            this.state.country_code == "BGD") ||
                          (!(
                            _.includes(
                              this.state.asianCountryCodes,
                              this.state.country_code
                            ) && selectedProvider == "feex"
                          ) &&
                            selectedProvider != "p2pay" &&
                            selectedProvider != "pspline" &&
                            selectedProvider != "flexify" &&
                            selectedProvider !== "pear2pay" &&
                            selectedProvider !== "paycos" &&
                            selectedProvider !== "stripe" &&
                            !(
                              this.state.cardNumber &&
                              this.state.holderName &&
                              this.state.validity_m !== "MM" &&
                              this.state.validity_m &&
                              this.state.validity_y !== "YYYY" &&
                              this.state.validity_y &&
                              this.state.cvv
                            ))
                        }
                        onClick={this.purchase}
                      >
                        <i className="mdi mdi-cart-outline cardicon"></i>
                        <div className="hidden paycos_status">
                          {providerLists[0]?.status}
                        </div>
                        {selectedProvider == "ampay" &&
                          `Deposit € ${this.state.amount ? this.state.amount.toFixed(2) : 0}`}
                        {selectedProvider == "paycos" &&
                          `Deposit ₹ ${this.state.amount ? this.state.amount.toFixed(2) : 0}`}
                        {selectedProvider != "ampay" &&
                          selectedProvider != "paycos" &&
                          `Deposit $${this.state.amount ? this.state.amount.toFixed(2) : 0}`}
                      </button>
                    )}
                  {selectedProvider == "gatewaypay" && (
                    <button
                      type="submit"
                      disabled={
                        !(
                          this.state.holderFirstName &&
                          this.state.holderLastName &&
                          this.state.cardholderemail &&
                          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                            this.state.cardholderemail
                          )
                        )
                      }
                      onClick={this.purchase}
                    >
                      <i className="mdi mdi-cart-outline cardicon"></i>
                      Deposit ${this.state.amount.toFixed(2)}
                    </button>
                  )}
                  {selectedProvider == "paycc" ||
                    (selectedProvider == "gumballpay" && (
                      <button
                        type="submit"
                        disabled={
                          !(
                            this.state.holderFirstName &&
                            this.state.holderLastName &&
                            (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                              this.state.cardholderemail
                            ) ||
                              /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                storage.getKey("email")
                              ))
                          )
                        }
                        onClick={this.purchase}
                      >
                        <i className="mdi mdi-cart-outline cardicon"></i>
                        Deposit ${this.state.amount.toFixed(2)}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Row >
        <Modal
          size="xl"
          scrollable={true}
          backdrop="static"
          centered={true}
          show={this.state.icorePayModal}
          onHide={this.closeIcore}
          className={"animated"}
        >
          <Modal.Header className="Header">
            Deposit with CorePay
            <button
              type="button"
              className="close p-2"
              onClick={this.closeIcore}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body style={{ height: "900px", maxHeight: "900px" }}>
            {this.state.redirectHTML == "" ? (
              <iframe
                src={this.state.icorePayUrl}
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: this.state.redirectHTML }}
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
class CheckoutForm extends React.Component {
  buttonRef = React.createRef();

  handleSubmit = async (event) => {
    // event.preventDefault();
    try {
      const cardElement = this.props.elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error("Card Element not found");
      }
      const result = await this.props.stripe.createToken(cardElement);
      if (result.error) {
        throw new Error(result.error.message);
      }
      const paymentDetails = {
        provider: "stripe",
        amount: this.props.amount,
        token: result.token.id,
      };
      socket.emit(C.BUY_CREDIT, encode(paymentDetails));
      // Handle server response here
    } catch (err) {
      console.error("Error in handleSubmit:", err);
      sendNotfication(
        "You should input correct card details",
        "danger",
        "bottom-left"
      );
      this.props.sweetAlertInstance.close();
      // Notify the user about the error
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <button ref={this.buttonRef} id="stripe_payment_button" type="submit" style={{ display: 'none' }}>Submit Payment</button>
      </form>
    );
  }
}

const InjectedCheckoutForm = React.forwardRef(({ amount, sweetAlertInstance }, ref) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm ref={ref} elements={elements} stripe={stripe} amount={amount} sweetAlertInstance = {sweetAlertInstance} />
      )}
    </ElementsConsumer>
  );
});
class AmountBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      depositamount: 30,
    };

    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return { depositamount: props.amount };
  }

  onChange(e) {
    let inputValue = _.toNumber(e.target.value);
    if (!/^[0-9\b]+$/.test(inputValue)) {
      return;
    }
    this.props.onChange(e.target.value);
    this.setState({ depositamount: inputValue });
  }

  increaseDepositAmount = () => {
    this.props.onChange(this.state.depositamount + 1);
    this.setState({ depositamount: this.state.depositamount + 1 });
  };

  decreaseDepositAmount = () => {
    if (this.state.depositamount < 1) return;
    this.props.onChange(this.state.depositamount - 1);
    this.setState({ depositamount: this.state.depositamount - 1 });
  };

  render() {
    const { t, selectedProvider } = this.props;
    return (
      <div className="full-width amount-list">
        <div className="media dollardeposit col-7 col">
          <img
            src="/assets/images/dollar_symbols.png"
            className="mr-3 thumb-sm align-self-center rounded-circle icondollar"
            alt="Coin"
          />
          <div className="media-body align-self-center">
            <div className="coin-bal">
              <h4 className="m-0 text-white">{t("deposit_amount")}:</h4>
              <p className="text-muted mb-0">
                USDT {t("wallet_s")}
                <span className="text-muted font-12"> ($)</span>
              </p>
            </div>
          </div>
        </div>
        <div className="amount-input-container col-5 text-right col">
          <div className="amount-arrow d-flex flex-column">
            <svg
              className="amount-arrow-up"
              onClick={this.increaseDepositAmount}
              width="17px"
              height="17px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                <path fill="none" d="M0 0h24v24H0z"></path>
              </g>
            </svg>
            <svg
              className="amount-arrow-down"
              onClick={this.decreaseDepositAmount}
              width="17px"
              height="17px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              transform="matrix(1, 0, 0, -1, 0, 0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                <path fill="none" d="M0 0h24v24H0z"></path>
              </g>
            </svg>
          </div>
          <input
            className="amount-input"
            type="number"
            placeholder="100.00"
            value={this.state.depositamount}
            onChange={(e) => this.onChange(e)}
          ></input>
          <div className="hidden"></div>
          {selectedProvider == "ampay" && (
            <div className="amount-label">EUR</div>
          )}
          {selectedProvider == "paycos" && (
            <div className="amount-label">INR</div>
          )}
          {selectedProvider != "ampay" && selectedProvider != "paycos" && (
            <div className="amount-label">USD</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  remoteIP: state.ip.remoteIP,
  country_code: state.ip.country_code,
  country_name: state.ip.country_name,
  providers: state.ip.providers,
});

export default withRouter(connect(mapStateToProps)(Providers));
