import React from "react";

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <p className={'text-white font-light mb-2 font-14 l30 mb-4 agreementpage'}>

                   <p className=""><strong>Last updated on:</strong> August 7, 2023 by <strong className="seller-link flex flex-justify-center tbody-3">Mrs Shannon Kathe (attorney)</strong>.</p>
<p className="">Definitions:<br />In these Terms following terms have the following meanings, Operator BetsMixer.com:<br /><br /><strong className="mt-4 text-yellow">"BMI" BMI Holding Ltd.</strong>&nbsp;and registered address: Global Gateway, Rue de la Perle, Mahe, Seychelles, BetsMixer brand, wholly owned subsidiary of BMI Holding Ltd.<br /><br />Registration number:&nbsp;<strong className="mt-4 text-yellow">215319</strong><br />Contact email: <a href="mailto:info@betsmixer.com"><strong className="mt-4 text-yellow">info@betsmixer.com</strong></a></p>
<p><strong>Privacy Policy:</strong></p>
<p>You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal data to allow you access and use of the Websites and in order to allow you to participate in the Games. We respect your privacy and will protect your personal information in accordance with best business practices and applicable laws. We will use your personal data to allow you to participate in the Games and to carry out operations relevant to your participation in the Games. We may also use your personal data to inform you of changes, new services, and promotions that we think you may find interesting. If you do not wish to receive such direct marketing correspondences, you may opt out of the service.</p>
<p>Your personal data will not be disclosed to third parties unless required by law. As BetsMixer's business partners or suppliers or service providers may be responsible for certain parts of the overall functioning or operation of the Website, personal data may be disclosed to them. The employees of BetsMixer have access to your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.</p>
<p>We shall keep all information provided as personal data. You have the right to access personal data held by us about you. No data shall be destroyed unless required by law or unless the information held is no longer required to be kept for the purpose of the relationship.</p>
<p>In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites, and to improve the service, we collect a small piece of information sent from your browser, called a cookie. You can, if you wish, turn off the collection of cookies. You must note, however, that turning off cookies may severely restrict or completely hinder your use of the Websites.</p>
<p><strong>CHANGES TO THIS POLICY</strong></p>
<p>We may revise this Policy from time to time and without prior notice to you. Except as otherwise noted in this Policy, such changes may apply to any personal information we already hold about you or personal information collected after the Policy is modified. Changes will be posted on this page and are effective as of the &ldquo;Last Modified&rdquo; date at the top of this Policy. Please visit this page regularly so that you are aware of our latest updates. Continuing to access or use the Sites or Services after any changes become effective indicates your acceptance of the revised Policy. In addition, we may provide you with &ldquo;just-in-time&rdquo; disclosures or additional information about the data processing practices of specific parts of our Sites or Services. Such notices may supplement this Policy or provide you with additional choices about how we process your personal information.</p>
<p><strong>PERSONAL INFORMATION</strong></p>
<p>We collect and process the following types of information. We may or may not create anonymous records from Personal Data for certain business purposes of BetsMixer and our Affiliates as defined below. Any information that is anonymized or aggregated is no longer Personal Data and we may indefinitely use it, share it, and retain it for the reasons outlined herein.</p>
<ol>
<li><strong>Contact Data.</strong> Personal Data about you used to contact you. For example, your name, the game title, email address, phone number, or mobile phone number.</li>
<li><strong>Profile Data.</strong>Personal Data related to a free or paid Customer account on our Services. For example Personal name, mobile phone number, e-mail address, website, physical address, username, password, and credit card account information.</li>
<li><strong>User Data.</strong> Personal Data of a Customer&rsquo;s Users utilized by BetsMixer on behalf of Customers to send invoices and/or estimates to and provide associated services to a Customer&rsquo;s Users. For example:</li>
<li><strong>Phone Number</strong>: We may collect your phone number if you provide it to us voluntarily. This information may be used to facilitate communication with you regarding our services or to provide important updates or notifications related to your account.</li>
<li><strong>Emails:</strong> We may collect email addresses associated with your account. These email addresses may include your primary email as well as any additional emails you provide. This allows us to communicate with you effectively and deliver important information regarding your account, such as service-related updates or notifications.</li>
<li><strong>Name and Address:</strong> To provide personalized services and enhance your user experience, we may collect your name and address. This information helps us address you properly.</li>
<li><strong>Device Invoices:</strong> As part of our service, we securely store and organize digital invoices for your accounts. This includes collecting and storing information such as the serial number, order number, date of purchase, and brand. These details enable us to accurately track and manage your gaming accounts subriptions.</li>
<li><strong>Insurance Invoices:</strong> If you provide us with insurance invoices related to your gaming accounts, we may collect and store relevant information from these invoices. This information may include policy numbers, coverage details, dates of coverage, and insurance provider information. It allows us to assist you in managing and tracking your device insurance.</li>
<li><strong>Emails from Retailers and Insurance Providers:</strong> In order to provide comprehensive services, we may collect and store emails you receive from retailers and insurance providers regarding electronic devices. This includes emails related to purchases, warranties, repairs, or insurance claims. These emails help us gather relevant information and assist you in managing your device-related matters effectively.</li>
<li><strong>Images Containing Device Invoices and Insurance Invoices:</strong> To provide a more user-friendly experience, we may offer the option to upload images containing games invoices. These images are securely stored and used to extract relevant information for managing your device warranties and insurance coverage. By entering User Data into our systems via the Sites and/or Services, you understand that BetsMixer is acting as a data processor providing services to you. You represent and warrant that you have the requisite authority to provide such Personal Data to us, and that the disclosure does not violate any applicable law or regulation, including but not limited to the Payment Card Industry Data Security Standard (PCI DSS), the Health Insurance Portability and Accountability Act (HIPAA), the California Consumer Privacy Act of 2018 (CCPA), the Personal Information Protection and Electronic Documents Act (PIPEDA), the EU General Data Protection Regulation (GDPR) or the UK General Data Protection Regulation (UK GDPR).</li>
</ol>
<p><strong>YOUR RIGHTS AND CHOICES</strong></p>
<p><strong>Rights. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship have the following rights in relation to your Personal Data, in each case to the extent required/permitted under applicable law, and subject to our rights to limit or deny access or disclosure under applicable law. Users and other consumers who do business with Customers utilizing BetsMixer Services must contact the Customers utilizing BetsMixer Services to exercise these rights. Users can request that the Customer provide you with access to the Personal Data BetsMixer stores about you on its behalf, that it make changes to that Personal Data, and/or that the Personal Data be deleted from BetsMixer systems. BetsMixer cannot honor such requests directly from Users but will assist Customers with honoring them.</p>
<p><strong>Access. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship, may request a list of your Personal Data that we process by submitting an official request in writing via email to address provided below.</p>
<p><strong>Rectification. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship may correct any Personal Data that we hold about you by emailing us at the address provided below and indicating both the inaccurate and corrected information. Customers may also login to your BetsMixer.com user account and modify your Personal Data.</p>
<p><strong>Erasure. </strong>Customers and Visitors with whom BetsMixer has a data controller relationship may request that we delete your Personal Data from our systems once per year by making an official request in writing via email to the address provided below and indicating the specific information you would like permanently deleted from our systems. Note that Customers who request removal of their Personal Data will no longer have access to any existing BetsMixer account and will not be able to use any BetsMixer product or service. BetsMixer reserves the right to retain certain account information for its recordkeeping or compliance purposes.</p>
<p>Customers may also login to their BetsMixer user account and delete any Profile Data, Contact Data or Customer Data to which they have access. However, to ensure that Personal Data is completely removed from our systems, you must submit an official request in writing to BetsMixer at the address provided below, as using a system delete function may merely restrict viewing that data from any system interface and prevent utilizing that data for any system function rather than permanently deleting it.</p>
<p><strong>Data Export. </strong>Customers and Visitors may request a copy of your Personal Data in a common portable format of our choice by submitting an official request in writing via email to the address provided below.</p>
<p>We may require that you provide additional Personal Data to exercise these rights, e.g., information necessary to prove your identity. We also reserve the right to retain this additional information for our recordkeeping or compliance purposes.</p>
<p><strong>Choices. </strong>It is possible for you to access and use the Sites without providing any Personal Data, but you may not be able to access certain features or view certain content and some portions of the Sites may not function properly.&nbsp; You must provide Personal Data in order to utilize the Services. You have the following choices regarding Personal Data we process:</p>
<p><strong>Consent. </strong>If you consent to processing you may withdraw your consent at any time to the extent required by law.</p>
<p><strong>Cancellation. </strong>Customers may cancel their BetsMixer accounts by contacting us using the contact information provided below.</p>
<p><strong>Opt-Out. </strong>You may opt-out of all information collection from your user account by deactivating the account or contacting us to deactivate the account on your behalf.</p>
<p>You may opt-out of receiving marketing communications from us by following the opt-out instructions included in such communications. Any communications from us that are not service-related or transactional in nature will offer you an &ldquo;unsubscribe&rdquo; option. To the extent required by law, you may choose to opt-out of sharing Personal Data with third parties.</p>
<p><strong>Cookies. </strong>If you do not want information collected through the use of cookies or similar technologies, you can manage/deny cookies (and certain technologies) using your browser&rsquo;s settings menu or by using</p>
<p><strong>a variety of tools.</strong></p>
<ul>
<li>You can visit the Google Ads Settings page&nbsp;here.</li>
<li>You can use the Google Analytics Opt Out Browser add-on.</li>
<li>Digital Advertising Alliance&rsquo;s opt-out page&nbsp;here&nbsp;allows you to opt out from receiving third party advertiser cookies.</li>
<li>You can visit the Network Advertising Initiative opt-out page&nbsp;here.</li>
<li>To learn more about cookies and similar tracking technologies, and how they can affect your privacy, visit&nbsp;allaboutcookies.org.</li>
</ul>
<p>As there is no consistent industry understanding of how to respond to &ldquo;Do Not Track&rdquo; signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.</p>
<p><strong>SECURITY</strong></p>
<p>We have implemented reasonable security measures designed to secure your Personal Data from accidental loss, unauthorized access, use, alteration and disclosure, however we do not provide any guarantee that your Personal Data will remain secure. We are not responsible for the circumvention of any privacy settings or security measures contained on the Sites or Services. All information you provide to us is stored on our service provider&rsquo;s servers which utilize industry-standard security controls and maintain Payment Card Industry (PCI) Compliance where applicable; however, we do not have control over and will not be liable for third parties&rsquo; security processes. Any transmission of information or Personal Data is at your own risk. If you believe that you have experienced unauthorized access or use of your account, please contact us immediately.</p>
<p><strong>MINORS</strong></p>
<p>Our services are neither directed at nor intended for direct use by individuals under the age of 18 or the age of majority in the jurisdiction where they reside. Further we do not intentionally gather information about such individuals. If we learn that we have inadvertently done so, we will promptly delete it. Do not access or use the Sites or Services if you are not the age of majority in your jurisdiction.</p>
<p><strong>CONTACT US</strong></p>
<p>If you have any questions or concerns regarding this Privacy Policy, please contact us at</p>
<p>Email: info@betsmixer.com</p>
<p><strong>Cancellation Policy</strong></p>
<p><strong>Cancellation Policy Overview:</strong></p>
<p>The cancellation policy allows users to cancel or modify their subscription under certain conditions. All cancellations must be made in writing (e-mail, letter) by the guest, and the support will confirm the cancellation in writing (e-mail, letter).</p>
<p><strong>Cancellation Policy for Standard e-Gaming;</strong></p>
<ol>
<li>Cancellation up to 1 day before the subscription start date: Free of charge.</li>
<li>Cancellation 1 &ndash; 7 days after subscribing into our gaming services: Cancellation fee of 100% of the total price.</li>
</ol>
<p><strong>Flexible Price Reservations:</strong></p>
<p>Users can cancel or modify their subscription before the provision of new package start taking place(before the start date of the new subscription). Payment can be made through a secure payment gateway or upon subscribtion into our services.</p>
<p><strong>Early Termination of Stay:</strong></p>
<p>If a user terminates their subscription early, a cancellation fee of 100% of the price of the entire order will be charged, and the remaining subscription period will be canceled.</p>
<p><strong>Flexible Price Cancellation Policy:</strong></p>
<ol>
<li>8 days or more before the start date of the new subscription: Free of charge.</li>
<li>7 days or less before the start date of the new subscription: Cancellation fee of 100% of the total price.</li>
</ol>
<p><strong>Non-Refundable Price:</strong></p>
<p>Non-refundable prices are discounted rates for our gaming services. Subscriptions under this category cannot be canceled or modified. A 100% prepayment is required when making the subscription, and later cancellations will incur a cancellation fee of 100% of the subscription price.</p>
<p><strong>Method of Payment:</strong></p>
<p>Payments can be made using VISA, MAESTRO, MASTERCARD payment cards, Paypal, Payoneer, or through bank transfer.</p>
<p><strong>Exceptional Situations:</strong></p>
<p>In exceptional situations or cases, BetsMixer will assess and resolve issues on an individual basis.</p>
<p><strong>Calculation of Cancellation Fee:</strong></p>
<p>The cancellation fee is calculated from the total amount of the subscription and not from the amount of the advance payment.</p>
<p>Note: Please refer to individual stay packages for specific cancellation conditions as they may vary.</p>
<p><strong>Cookies Policy</strong></p>
<p><strong>What are cookies?</strong></p>
<p>Cookies are small text files placed on an internet user's computer by a web page server. They act as an identification card, allowing the website to recognize the user upon return visits. Cookies are harmless and do not store any personally identifiable information.</p>
<p><strong>Why do we use cookies on BetsMixer?</strong></p>
<p>BetsMixer uses two types of cookies: cookies set by us and cookies set by third parties. These cookies help us keep you signed in to your account during your visit and personalize the information displayed on the site based on your preferences.</p>
<p><strong>What cookies do we use on BetsMixer?</strong></p>
<ol>
<li>Below are the main cookies set by BetsMixer and their purposes:</li>
<li>_fp: Stores the browser's fingerprint. Lifetime: forever.</li>
<li>_t: Stores the timestamp of the user's first visit in the current session for unique visits statistics. Lifetime: browsing session.</li>
<li>_r: Stores the HTTP referrer for the current session to track external traffic sources. Lifetime: browsing session.</li>
<li>_c: Stores the identifier of the affiliate campaign for affiliate statistics. Lifetime: forever.</li>
<li>Cookies set by third parties for the wildcard domain: BetsMixer.</li>
<li>Google Analytics: _ga, _gat, _gid.</li>
<li>Zendesk: __zlcmid.</li>
<li>Cloudflare: __cfuid.</li>
<li>Please note that some browsers may keep background processes running, which can lead to session cookies being set between sessions. Third-party scripts may also set cookies to their domains.</li>
</ol>
<p><strong>How can I manage my cookies on BetsMixer?</strong></p>
<p>If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.</p>
<p><strong>Personal Data Protection Policy:</strong></p>
<p>BetsMixer prioritizes data security and employs various measures, including encryption, firewalls, VPN access, and DDoS mitigations, to safeguard your data.</p>
<p><strong>Server Protection Policy:</strong></p>
<ol>
<li>All servers have full encryption, including backups.</li>
<li>Firewalls and VPN access ensure secure connections.</li>
<li>Critical services are allowed only over VPN.</li>
<li>SSH port forwarding tunnels are utilized.</li>
<li>Services are protected and allowed only over VPN.</li>
<li>Servers have firewalls and only allow SSH port access.</li>
<li>Alerts are set up for critical services.</li>
</ol>
<p><strong>Data Breach Notification:</strong></p>
<p>In case of a personal data breach, BetsMixer will notify affected users following GDPR timeframes.</p>
<p><strong>Data International Transfer:</strong></p>
<ol>
<li>Personal data is only disclosed to third parties when necessary for high-quality service or to comply with lawful requests from authorities.</li>
<li>Specific data may be shared with Zendesk Inc. for customer support purposes.</li>
</ol>
<p><strong>Collecting and Using Your Personal Data:</strong></p>
<ol>
<li>BetsMixer may collect personally identifiable information to contact or identify users.</li>
<li>Usage Data, including IP addresses, browser information, and diagnostic data, may be collected automatically.</li>
<li>Users may choose to create accounts or log in via Third-Party Social Media Services, and relevant data may be collected accordingly.</li>
</ol>
<p><strong>Delete Personal Data:</strong></p>
<p>Users can request the deletion of their personal data by contacting support@betsmixer.com, subject to legal obligations.</p>
<p><strong>Registration and Login:</strong></p>
<ol>
<li>Users must be at least 18 years old to register.</li>
<li>Accurate and up-to-date information must be provided during registration.</li>
<li>Adding 2FA for added security is recommended.</li>
<li>Changes to registered email addresses are not supported; users may need to create a new account.</li>
</ol>
<p>This agreement was issued on August 7, 2023 by <strong className="seller-link flex flex-justify-center tbody-3">Mrs Shannon Kathe (ATTORNEY registered in US court)</strong></p>


                </p>
            </>
        );
    }
}
