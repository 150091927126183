import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import UserModal from "../../Components/User/Stat/Modal";
import { encode, decode, wait, fixDate } from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
    };
  }

  componentDidMount() {
    socket.emit(C.LEADERBOARD);
    socket.on(C.LEADERBOARD, (data) => {
      this.makeList(decode(data));
    });
  }

  componentWillUnmount() {
    socket.off(C.LEADERBOARD);
  }

  makeList = (data) => {
    this.setState({ loading: false, list: data });
  };

  render() {
    const { t } = this.props;

    const list = this.state.list.map((row, i) => <List key={i} user={row} />);

    return (
      <Card className="m-2">
        <Card.Body className="affiliatebox">
          <div className="table-responsive num-style mb-0 tww">
            {this.state.loading ? (
              <div className="text-center">
                <div className="spinner-border text-info my-3" role="status" />
              </div>
            ) : (
              <>
                {list.length === 0 ? (
                  <>{t("you_have_not_introduced_anyone_yet")}</>
                ) : (
                  <Table
                    className={"mb-2 affiliatetable table table-striped mb-0"}
                  >
                    <thead>
                      <tr>
                        <th className="bg-transparent text-white">Username</th>
                        <th className="bg-transparent text-white">
                          Affiliate Revenue
                        </th>
                      </tr>
                    </thead>
                    <tbody>{list}</tbody>
                  </Table>
                )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

class List extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <tr>
        <td className="bg-transparent text-white-50">
          <UserModal
            id={user.uid}
            avatar={user.avatar}
            username={user.name}
            cssClass="user-avatar font-14 text-muted"
          />
        </td>
        <td className="bg-transparent text-white-50">
          ${parseFloat(user.wagered).toFixed(2)}
        </td>
      </tr>
    );
  }
}

export default Leaderboard;
