import React, { Component, createRef } from 'react';
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import {Event, wait, decode, encode, sendNotfication} from "../../../Helper";
import C from "../../../Constant";
class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            loading: true
        };
    }
    componentDidMount() {
        socket.emit(C.GET_BLOGS_QUIZ);
        socket.on(C.GET_BLOGS_QUIZ, data => {
            let decoded = decode(data);
            if (decoded && decoded.length > 0) {
                this.setState({blogs: decoded, loading: false});
            }
        });
    }
    componentWillUnmount() {
        socket.off(C.GET_BLOGS);
    }

    render() {
        const { t } = this.props;
        let host = window.location.protocol + '//' + window.location.host;
        return (
            <>
              <Helmet>
                <title>Blogs | Crypto Casino Games</title>
                <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <link rel="canonical" href="/blog" />
              </Helmet>
                <p className='blog-title'>{t('blog')}</p>
                <div className='blog-container d-flex flex-column'>
                    {this.state.loading ? 
                        <div className="text-center">
                            <div className="spinner-grow text-white my-5" role="status" />
                        </div>
                    :
                        this.state.blogs.map((blog, index) => {
                            let {title, id} = blog;
                            let url_title = title.toLowerCase().replace(/\s/g, '-').replace(/\?/g, '');
                            return (
                                <a href={host + '/' + url_title + '/' + id} key={index} className='blog-wrapper-btn' rel="noopener noreferrer" target="_blank">
                                    {blog.title}
                                    <svg className='arrow-in-blogs' fill="#6779e3" height="14px" width="14px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.005 512.005" stroke="#6779e3">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="5"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <g> 
                                                <path d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005 L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251 l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"></path> 
                                            </g> 
                                        </g>
                                    </svg>
                                </a>
                            )
                        })
                    }
                </div>
            </>
        );
    }
}

export default withRouter(Blogs);
