class Rules {
  constructor() {
    this.hand = [];
    this.handSize = 5;
  }

  sortByVal(card1, card2) {
    this.hand.sort(function compare(card1, card2) {
      if (card1.value < card2.value) return -1;
      if (card1.value > card2.value) return 1;
      return 0;
    });
  }

  cardValue(card) {
    if (card === "J" || card === "Q" || card === "K") {
      return 10;
    } else if (card === "A") return 11;
    else return parseFloat(card);
  }

  jacksOrBetter() {
    for (let i = this.handSize - 1; i > 0; i--) {
      let card = this.hand[i].value;

      if (card > 9 || card === 0) {
        if (this.hand[i].value === this.hand[i - 1].value) return true;
      }
    }
    return false;
  }

  twoPair() {
    let firstPair = -1;
    for (let i = this.handSize - 1; i > 0; i--) {
      if (i > 0) {
        if (this.hand[i].value === this.hand[i - 1].value)
          firstPair = this.hand[i].value;
      }
    }

    if (firstPair !== -1) {
      for (let i = this.handSize - 1; i > 0; i--) {
        if (i > 0) {
          if (
            this.hand[i].value === this.hand[i - 1].value &&
            this.hand[i].value !== firstPair
          )
            return true;
        }
      }
    }
    return false;
  }

  threeOfAKind() {
    for (let i = this.handSize - 1; i > 0; i--) {
      if (i > 1) {
        if (
          this.hand[i].value === this.hand[i - 1].value &&
          this.hand[i].value === this.hand[i - 2].value
        )
          return true;
      }
    }
    return false;
  }

  straight() {
    for (let i = 0; i < this.handSize - 1; i++) {
      if (this.hand[i].value !== this.hand[i + 1].value - 1) return false;
    }
    return true;
  }

  royalStraight() {
    if (
      this.hand[0].value === 0 &&
      this.hand[1].value === 9 &&
      this.hand[2].value === 10 &&
      this.hand[3].value === 11 &&
      this.hand[4].value === 12
    )
      return true;
    return false;
  }

  flush() {
    for (let i = 0; i < this.handSize; i++) {
      if (i < this.handSize - 1 && this.hand[i].suit !== this.hand[i + 1].suit)
        return false;
    }
    return true;
  }

  fullHouse() {
    if (this.twoPair() && this.threeOfAKind()) return true;
    return false;
  }

  fourOfAKind() {
    for (let i = this.handSize - 1; i > 0; i--) {
      if (i > 2) {
        if (!_.isUndefined(this.hand[i])) {
          if (
            this.hand[i].value === this.hand[i - 1].value &&
            this.hand[i].value === this.hand[i - 2].value &&
            this.hand[i].value === this.hand[i - 3].value
          )
            return true;
        }
      }
    }
    return false;
  }

  straightFlush() {
    if (this.straight() && this.flush()) return true;
    return false;
  }

  royalFlush() {
    if (this.royalStraight() && this.flush()) return true;
    return false;
  }
}

export default Rules;
