import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { gameCoin } from "../../../actions/gameCoin";
import Canvas from "./Canvas";
import {
  __,
  isMobile,
  isTablet,
  Event,
  forceSatoshiFormat,
} from "../../../Helper";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      canvas: null,
      interval: [],
      risk: "medium",
      amount: 10,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    engine.on("play", (data) => this.play(data));
    engine.on("busted", () => this.busted());

    var canvas = new Canvas();
    this.setState({ canvas: canvas });
    canvas.init();

    Event.on("diamond_risk", (data) => {
      this.setState({ risk: __.toLower(data) });
    });

    Event.on("set_amount", (value) => {
      this.setState({ amount: value });
    });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  play(data) {
    if (this._isMounted) {
      this.state.canvas.setStart();
      this.state.canvas.start(data.target);
    }
  }
  
  busted() {
    if (this._isMounted) {
      this.state.canvas.busted();
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn">
          <div id="loading" className="ycenter" />
          <canvas id="game" />

          <div className="wheel-payout">
            {this.state.risk === "medium" && (
              <>
                <Payout
                  amount={this.state.amount}
                  color="primary-2"
                  coin={this.props.coin}
                  payout="1.20"
                />
                <Payout
                  amount={this.state.amount}
                  color="warning-2"
                  coin={this.props.coin}
                  payout="1.50"
                />
                <Payout
                  amount={this.state.amount}
                  color="purple"
                  coin={this.props.coin}
                  payout="1.80"
                />
                <Payout
                  amount={this.state.amount}
                  color="yellow"
                  coin={this.props.coin}
                  payout="2.00"
                />
                <Payout
                  amount={this.state.amount}
                  color="pink"
                  coin={this.props.coin}
                  payout="0.00"
                />
              </>
            )}
            {this.state.risk === "high" && (
              <>
                <Payout
                  amount={this.state.amount}
                  color="primary-2"
                  coin={this.props.coin}
                  payout="3.00"
                />
                <Payout
                  amount={this.state.amount}
                  color="warning-2"
                  coin={this.props.coin}
                  payout="6.00"
                />
                <Payout
                  amount={this.state.amount}
                  color="purple"
                  coin={this.props.coin}
                  payout="8.00"
                />
                <Payout
                  amount={this.state.amount}
                  color="yellow"
                  coin={this.props.coin}
                  payout="10.00"
                />
                <Payout
                  amount={this.state.amount}
                  color="pink"
                  coin={this.props.coin}
                  payout="0.00"
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

function Payout(props) {
  const { amount, payout, coin, color } = props;

  const calculateChance = (payout) => {
    payout = parseFloat(payout) === 0 ? 1 : payout;
    var c = ((1 / payout) * 55).toFixed(0);
    return Math.max(c, 1) + " %";
  };

  return (
    <>
      <OverlayTrigger
        key={payout}
        placement={"bottom"}
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            <div className="form-group mb-1 d-flex">
              <div className="input-group">
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img
                      src={"/assets/images/" + __.toLower(coin) + ".png"}
                      className={"mini-coin-7"}
                      alt="coin"
                    />
                    Win
                  </span>
                </div>
                <input
                  disabled={true}
                  type="number"
                  className="form-control"
                  value={forceSatoshiFormat(payout * amount)}
                />
              </div>
              <div className="input-group">
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="mdi mdi-information align-middle font-20 text-warning mr-1"></i>
                    Chance
                  </span>
                </div>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={calculateChance(payout)}
                />
              </div>
            </div>
          </Tooltip>
        }
      >
        <div className="progress">
          <div className={"progress-bar w-100 bg-" + color}>{payout}x</div>
        </div>
      </OverlayTrigger>
    </>
  );
}

Game.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { gameCoin })(Game);
