import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { isMobile, wait, Event } from "../../../Helper";
import storage from "../../../Storage";
import socket, { disconnect } from "../../../Socket";
import BankRoll from "../../Components/Game/BankRoll";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Help from "./includes/Help";
import Bet from "./Bet";
import Bottom from "./Bottom";
import MobileBottom from "./MobileBottom";
import Loading from "../Loading";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      height: null,
      mobile: false,
      isLogged: storage.getKey("logged") !== null ? true : false,
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this._Mounted) {
      socket.emit("crash");

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      wait(600).then(() => {
        this.handleResize();
      });
      window.addEventListener("resize", this.handleResize);

      var { engine } = this.state;

      engine.started = true;

      // Start Engine
      engine.listen();

      wait(500).then(() => {
        engine.getStatus();
      });
    }
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
    disconnect("crash");
  }

  handleResize() {
    if (this.getWidth() < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  getWidth() {
    return document.documentElement.clientWidth || document.body.clientWidth;
  }

  render() {
    let { engine, col, padding, mobile, ovh } = this.state;
    const { gid } = this.props;
    return (
      <>
        <Helmet>
          <title>Crash | Crypto Casino Games</title>
          <meta
            name="description"
            content="Play Online Crash - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Play Online Crash - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Crash - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/crash" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <Col sm={12} className={"m-auto fullsize " + col + " " + padding}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            Crash
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"crash"} />%
                          </span>
                        </p>
                        <BankRoll game={"crash"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <Help gid={gid} t={this.props.t} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* History List */}
                <Card className="mb-0 animated fadeIn">
                  <Card.Body className="p-1 card-body crashbody">
                    <HistoryList
                      engine={engine}
                      game={"crash"}
                      t={this.props.t}
                    />
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body className={"p-1 animated fadeIn"} id="roll-panel">
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <div id="rocketcrashcontainer"></div>
                        {!mobile ? (
                          <Canvas engine={engine} mobile={this.state.mobile} />
                        ) : (
                          <MobileCanvas
                            engine={engine}
                            mobile={this.state.mobile}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0 crashcontent">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet
                          engine={engine}
                          mobile={this.state.mobile}
                          isLogged={this.state.isLogged}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Bottom */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row className="crash-leaderboard">
                      <Col sm={12} md={12} className={"m-auto"}>
                        {!mobile ? (
                          <Bottom
                            engine={engine}
                            mobile={this.state.mobile}
                            isLogged={this.state.isLogged}
                          />
                        ) : (
                          <MobileBottom engine={engine} t={this.props.t} />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <Rakeback t={this.props.t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
