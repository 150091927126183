// reducer.js
import { SET_IP_ADDRESS, SET_IP_DETAILS, SET_PROVIDERS } from '../actions/types';

const initialState = {
  ipAddress: null,
  remoteIP: null,
  country_code: null,
  country_name: null,
  providers: [],
};

const ipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IP_ADDRESS:
      console.log(SET_IP_ADDRESS, action.payload);
      return {
        ...state,
        ipAddress: action.payload
      };
    case SET_IP_DETAILS:
      return {
        ...state,
        remoteIP: action.payload.remoteIP,
        country_code: action.payload.country_code,
        country_name: action.payload.country_name
      }
    case SET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      }
    default:
      return state;
  }
};

export default ipReducer;
