import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import {
  __,
  randomString,
  wait,
  isMobile,
  isTablet,
  cryptoToUsd,
  getRandomInt,
  Event,
} from "../../../Helper";
import storage from "../../../Storage";
import useHover from "../../../utils/hooks";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      token: storage.getKey("token"),
      isLogged: storage.getKey("logged") !== null ? true : false,
      canvas: null,
      buttons: <ButtonSection clicked={this.clicked} />,
      data: [],
      last: [],
      lastBet: [],
      started: false,
      amounts: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    if (this._isMounted) {
      this.setUpCanvas();
      engine.on("play", (data) => this.play(data));
      engine.on("busted", (data) => this.busted(data));
      Event.on("coin_changed", (c) => this.coinChanged(c));
      Event.on("reset_table", () => this.resetTable());
      if (isMobile() || isTablet()) {
        let c = document.getElementById("game");
        c.style.width = "100%";
        c.style.height = "100%";
      }
    }
  }

  componentWillUnmount() {
    this.state.canvas.destroy();
    this.destroy();
    this._isMounted = false;
  }

  destroy = () => {
    this.setState({ engine: null, canvas: null });
    const element = document.getElementById("game");
    element.remove();
  };

  setUpCanvas() {
    if (this._isMounted) {
      const c = document.getElementById("game");
      let canvas = new Canvas();
      this.setState({ canvas: canvas });
      canvas.init();
    }
  }

  coinChanged(c) {
    if (this._isMounted) {
      this.resetTable();
    }
  }

  play = (data) => {
    if (this._isMounted) {
      this.setState({ started: true });
      this.state.canvas.play(data.target);

      this.setState({ lastBet: this.state.engine.data });
    }
  };

  busted(data) {
    if (this._isMounted) {
      this.setState({ started: false });
      this.state.engine.data = [];
      this.state.engine.emit("busted_roulette");
      this.refresh();
      this.resetTable();
    }
  }

  resetTable = () => {
    this.setState({
      loading: true,
      buttons: null,
    });
    this.setState({
      loading: false,
      buttons: <ButtonSection clicked={this.clicked} />,
    });
  };

  refresh = () => {
    if (!this.state.started) {
      this.state.engine.data = [];
      this.setState({
        loading: true,
      });
      wait(5).then(() => {
        this.setState({
          loading: false,
          amounts: 0,
          buttons: <ButtonSection clicked={this.clicked} />,
        });
      });

      this.state.engine.emit("roulette_reset_amount");
    }
  };

  clicked = (e, buttonNumber, amount = null) => {
    if (this.state.started) return;

    if (e) {
      // console.log(e.target.children);
    }

    if (__.isNull(amount)) amount = this.props.selected_number;

    var am = this.state.amounts + parseFloat(amount);

    if (cryptoToUsd(am, this.props.coin) > 100) return console.log(am);

    this.setState({ amounts: am });

    this.setState((state) => ({ last: [buttonNumber, ...state.last] }));

    this.setState({ lastAmount: amount });

    let m = isMobile() ? 12 : 22;
    const left = getRandomInt(1, m);
    const right = getRandomInt(1, m);
    const bottom = getRandomInt(1, 10);
    const top = getRandomInt(1, 9);

    e.target.innerHTML += `<img id="i${buttonNumber}" style="right: ${right}px; left: ${left}px; bottom: ${bottom}px; top: ${top}px" src="/assets/images/chip-green.png" class="custom-chip2" />`;

    this.state.engine.data.push({
      table: buttonNumber,
      amount: amount,
    });

    this.state.engine.emit("roulette_add_amount", amount);
  };

  undo = () => {
    if (this.state.started) return;

    var getLast = __.first(this.state.last);

    let chips = document.querySelectorAll("#i" + getLast);

    for (var i = 0; i < chips.length; i++) {
      var getLast = _.last(chips);
      getLast.remove();
    }

    __.remove(this.state.engine.data, { table: getLast });
    __.pull(this.state.last, getLast);

    if (this.state.lastAmount)
      this.state.engine.emit("roulette_reduce_amount", this.state.lastAmount);

    this.setState({ amounts: this.state.amounts - this.state.lastAmount });
  };

  repeat = () => {
    if (this.state.started) return;
    this.refresh();
    wait(500).then(() => {
      const { lastBet } = this.state;
      for (var i = 0; i < lastBet.length; i++) {
        var bet = lastBet[i];
        let table = bet.table;
        let amount = bet.amount;
        this.clicked(null, table, amount);
      }
    });
  };

  render() {
    return (
      <div className=" animated fadeIn">
        <Row>
          <Col md="7" sm="12">
            <div class="roulette-cr">
              <div className="roulette_options">
                <button
                  className="btn bg-cs14 btn-xs px-4"
                  onClick={this.refresh}
                >
                  <i className="mdi mdi-refresh mr-1" />
                  Refresh
                </button>
                <button
                  className="btn bg-cs14 btn-xs px-4"
                  onClick={this.repeat}
                >
                  <i className="mdi mdi-repeat mr-1" />
                  Repeat Bet
                </button>
                <button className="btn bg-cs14 btn-xs px-4" onClick={this.undo}>
                  <i className="mdi mdi-undo-variant mr-1" />
                  Undo
                </button>
              </div>
              {this.state.loading ? (
                <div>
                  <div className="game-loader  l-2  text-center">
                    <div
                      className="spinner-grow text-purple text-info my-3"
                      role="status"
                    />
                  </div>
                </div>
              ) : (
                this.state.buttons
              )}
            </div>
          </Col>
          <Col md="5" sm="12">
            <div className="text-center" id="cc">
              <canvas id={"game"}></canvas>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function ButtonSection(props) {
  const { clicked } = props;

  const [hoverRef1, isHovered1] = useHover();
  const [hoverRef2, isHovered2] = useHover();
  const [hoverRef3, isHovered3] = useHover();
  const [hoverRef4, isHovered4] = useHover();
  const [hoverRef5, isHovered5] = useHover();
  const [hoverRef6, isHovered6] = useHover();
  const [hoverRef7, isHovered7] = useHover();
  const [hoverRef8, isHovered8] = useHover();
  const [hoverRef9, isHovered9] = useHover();
  const [hoverRef10, isHovered10] = useHover();
  const [hoverRef11, isHovered11] = useHover();
  const [hoverRef12, isHovered12] = useHover();

  const row1 = [3, 6, 9, 12, 15, 18, 12, 24, 27, 30, 33, 36];
  const row2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
  const row3 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];

  const even = [
    2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
  ];
  const odd = [
    1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35,
  ];

  const reds = [
    1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
  ];
  const blacks = [
    2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
  ];

  if (isHovered4) {
    row1.forEach((num, i) => {
      if (document.getElementById("d" + num)) {
        document.getElementById("d" + num).classList.add("active");
      }
    });
  } else if (isHovered5) {
    row2.forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.add("active");
    });
  } else if (isHovered6) {
    row3.forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.add("active");
    });
  } else {
    [row1, row2, row3].forEach((num, i) => {
      if (document.getElementById("d" + num))
        document.getElementById("d" + num).classList.remove("active");
    });

    if (isHovered7) {
      for (var i = 1; i < 19; i++) {
        if (document.getElementById("d" + i))
          document.getElementById("d" + i).classList.add("active");
      }
    } else if (isHovered8) {
      for (var i = 19; i < 37; i++) {
        if (document.getElementById("d" + i))
          document.getElementById("d" + i).classList.add("active");
      }
    } else {
      if (isHovered9) {
        even.forEach((num, i) => {
          if (document.getElementById("d" + num))
            document.getElementById("d" + num).classList.add("active");
        });
      } else if (isHovered10) {
        odd.forEach((num, i) => {
          if (document.getElementById("d" + num))
            document.getElementById("d" + num).classList.add("active");
        });
      } else {
        if (isHovered11) {
          reds.forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.add("active");
          });
        } else if (isHovered12) {
          blacks.forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.add("active");
          });
        } else {
          [even, odd].forEach((num, i) => {
            if (document.getElementById("d" + num))
              document.getElementById("d" + num).classList.remove("active");
          });

          for (var i = 1; i < 37; i++) {
            if (document.getElementById("d" + i))
              document.getElementById("d" + i).classList.remove("active");
          }

          if (isHovered1) {
            for (var i = 1; i < 13; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else if (isHovered2) {
            for (var i = 13; i < 25; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else if (isHovered3) {
            for (var i = 25; i < 37; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.add("active");
            }
          } else {
            for (var i = 1; i < 37; i++) {
              if (document.getElementById("d" + i))
                document.getElementById("d" + i).classList.remove("active");
            }
          }
        }
      }
    }
  }

  return (
    <div className="roulette">
      <table>
        <tr className="nums">
          <td
            id={"d0"}
            onClick={(e) => clicked(e, 0)}
            className="num green zero"
            rowspan="3"
          >
            <span>0</span>
          </td>
          <td id={"d3"} onClick={(e) => clicked(e, 3)} className="num red">
            <span>3</span>
          </td>
          <td id={"d6"} onClick={(e) => clicked(e, 6)} className="num black">
            <span>6</span>
          </td>
          <td id={"d9"} onClick={(e) => clicked(e, 9)} className="num red">
            <span>9</span>
          </td>
          <td id={"d12"} onClick={(e) => clicked(e, 12)} className="num red">
            <span>12</span>
          </td>
          <td id={"d15"} onClick={(e) => clicked(e, 15)} className="num black">
            <span>15</span>
          </td>
          <td id={"d18"} onClick={(e) => clicked(e, 18)} className="num red">
            <span>18</span>
          </td>
          <td id={"d21"} onClick={(e) => clicked(e, 21)} className="num red">
            <span>21</span>
          </td>
          <td id={"d24"} onClick={(e) => clicked(e, 24)} className="num black">
            <span>24</span>
          </td>
          <td id={"d27"} onClick={(e) => clicked(e, 27)} className="num red">
            <span>27</span>
          </td>
          <td id={"d30"} onClick={(e) => clicked(e, 30)} className="num red">
            <span>30</span>
          </td>
          <td id={"d33"} onClick={(e) => clicked(e, 33)} className="num black">
            <span>33</span>
          </td>
          <td id={"d36"} onClick={(e) => clicked(e, 36)} className="num red">
            <span>36</span>
          </td>
          <td
            id={"d2_1"}
            onClick={(e) => clicked(e, "2_1")}
            ref={hoverRef4}
            className="sector"
            data-sector="1"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr className="nums">
          <td className="hidden"></td>
          <td id={"d2"} onClick={(e) => clicked(e, 2)} className="num black">
            <span>2</span>
          </td>
          <td id={"d5"} onClick={(e) => clicked(e, 5)} className="num red">
            <span>5</span>
          </td>
          <td id={"d8"} onClick={(e) => clicked(e, 8)} className="num black">
            <span>8</span>
          </td>
          <td id={"d11"} onClick={(e) => clicked(e, 11)} className="num black">
            <span>11</span>
          </td>
          <td id={"d14"} onClick={(e) => clicked(e, 14)} className="num red">
            <span>14</span>
          </td>
          <td id={"d17"} onClick={(e) => clicked(e, 17)} className="num black">
            <span>17</span>
          </td>
          <td id={"d20"} onClick={(e) => clicked(e, 20)} className="num black">
            <span>20</span>
          </td>
          <td id={"d23"} onClick={(e) => clicked(e, 23)} className="num red">
            <span>23</span>
          </td>
          <td id={"d26"} onClick={(e) => clicked(e, 26)} className="num black">
            <span>26</span>
          </td>
          <td id={"d29"} onClick={(e) => clicked(e, 29)} className="num black">
            <span>29</span>
          </td>
          <td id={"d32"} onClick={(e) => clicked(e, 32)} className="num red">
            <span>32</span>
          </td>
          <td id={"d35"} onClick={(e) => clicked(e, 35)} className="num black">
            <span>35</span>
          </td>
          <td
            id={"d2_2"}
            onClick={(e) => clicked(e, "2_2")}
            ref={hoverRef5}
            className="sector"
            data-sector="2"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr className="nums">
          <td className="hidden"></td>
          <td id={"d1"} onClick={(e) => clicked(e, 1)} className="num red">
            <span>1</span>
          </td>
          <td id={"d4"} onClick={(e) => clicked(e, 4)} className="num black">
            <span>4</span>
          </td>
          <td id={"d7"} onClick={(e) => clicked(e, 7)} className="num red">
            <span>7</span>
          </td>
          <td id={"d10"} onClick={(e) => clicked(e, 10)} className="num black">
            <span>10</span>
          </td>
          <td id={"d13"} onClick={(e) => clicked(e, 13)} className="num black">
            <span>13</span>
          </td>
          <td id={"d16"} onClick={(e) => clicked(e, 16)} className="num red">
            <span>16</span>
          </td>
          <td id={"d19"} onClick={(e) => clicked(e, 19)} className="num red">
            <span>19</span>
          </td>
          <td id={"d22"} onClick={(e) => clicked(e, 22)} className="num black">
            <span>22</span>
          </td>
          <td id={"d25"} onClick={(e) => clicked(e, 25)} className="num red">
            <span>25</span>
          </td>
          <td id={"d28"} onClick={(e) => clicked(e, 28)} className="num black">
            <span>28</span>
          </td>
          <td id={"d31"} onClick={(e) => clicked(e, 31)} className="num black">
            <span>31</span>
          </td>
          <td id={"d34"} onClick={(e) => clicked(e, 34)} className="num red">
            <span>34</span>
          </td>
          <td
            id={"d2_3"}
            onClick={(e) => clicked(e, "2_3")}
            ref={hoverRef6}
            className="sector"
            data-sector="3"
          >
            <span className="vt">2:1</span>
          </td>
        </tr>
        <tr>
          <td className="empty"></td>
          <td
            id={"d112"}
            onClick={(e) => clicked(e, 112)}
            ref={hoverRef1}
            data-num="112"
            colspan="4"
            className="sector"
            data-sector="4"
          >
            {" "}
            1st 12
          </td>
          <td
            id={"d212"}
            onClick={(e) => clicked(e, 212)}
            ref={hoverRef2}
            data-num="212"
            colspan="4"
            className="sector"
            data-sector="5"
          >
            {" "}
            2nd 12
          </td>
          <td
            id={"d312"}
            onClick={(e) => clicked(e, 312)}
            ref={hoverRef3}
            data-num="312"
            colspan="4"
            className="sector"
            data-sector="6"
          >
            {" "}
            3rd 12
          </td>
          <td className="empty"></td>
        </tr>
        <tr>
          <td className="empty"></td>
          <td
            id={"d1_18"}
            onClick={(e) => clicked(e, "1_18")}
            ref={hoverRef7}
            colspan="2"
            className="sector"
            data-sector="7"
          >
            1 to 18
          </td>
          <td
            id={"deven"}
            onClick={(e) => clicked(e, "even")}
            ref={hoverRef9}
            colspan="2"
            className="sector"
            data-sector="8"
          >
            EVEN
          </td>
          <td
            id={"dred"}
            onClick={(e) => clicked(e, "red")}
            ref={hoverRef11}
            colspan="2"
            className="sector red"
            data-sector="9"
          ></td>
          <td
            id={"dblack"}
            onClick={(e) => clicked(e, "black")}
            ref={hoverRef12}
            colspan="2"
            className="sector black"
            data-sector="10"
          ></td>
          <td
            id={"dodd"}
            onClick={(e) => clicked(e, "odd")}
            ref={hoverRef10}
            colspan="2"
            className="sector"
            data-sector="11"
          >
            ODD
          </td>
          <td
            id={"d19_36"}
            onClick={(e) => clicked(e, "19_36")}
            ref={hoverRef8}
            colspan="2"
            className="sector"
            data-sector="12"
          >
            19 to 36
          </td>
          <td className="empty"></td>
        </tr>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  selected_number: state.items.selected_number,
});

export default connect(mapStateToProps, null)(Game);
