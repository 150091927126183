import React from "react";
import { Stack, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import socket from "../Socket";
import C from "../Constant";
import { encode, decode } from "../Helper";
import Game from './Pages/Parts/Home/Games';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            home_games: [],
            slot_games: []
        };
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        socket.emit(C.RECENT);
        socket.on(C.RECENT, data => {
            try{
                
                let recent_games = decode(data); 
                this.setState({ loading: false, home_games: recent_games.home_games, slot_games: recent_games.slot_games })
            }
            catch(e){

            }
        });
    }

    componentWillUnmount() {
        socket.off(C.RECENT);
    }

    render() {
        const { t, history } = this.props;
        const slot_games = this.state.slot_games.map((item, i) =>
            <Col key={i.toString()} className={'ovh animated fadeInUp game-items'} style={{ maxWidth: '14.2%'}}>
                <Link to={item.name !== null ? '/slots/' + item.uuid : '#'} className="slot-link slot-pan game-card-wrapper">
                    <div className={item.name !== null ? 'slot-image' : 'slot-image orange'}>
                        {/* <img src={"https://app.betsmixer.com/assets/slotegrator/" + item.provider + "/" + item.image} className="img-fluid" alt={item.name} /> */}
                        <img src={item.origin_url} className="img-fluid" alt={item.name} />
                    </div>
                    <div className="slot-title">
                        <span className="slot-t-name">{item.name}</span>
                        <span className="slot-t-provider">{item.provider}</span>
                    </div>
                </Link>
            </Col>
        );
        return <>
                    {
                        this.state.loading ?
                            <>
                                <div className="ycenter text-center">
                                    <div className="spinner-grow text-white my-5" role="status" />
                                </div>
                            </>
                            :
                            <Card className="lastplayed-tab">
                                <Card.Body className="p-4">
                                    {
                                        !this.props.search && this.state.home_games.length > 0 || this.state.slot_games.length > 0?
                                        <Stack direction="horizontal" gap={3} className="mb-2">
                                            <div className="d-flex align-items-center">
                                                <img className='img-fluid home-page-icons' src="/assets/images/icons/in-house-game.svg" />
                                                <p className="m-0 home-part-t">{t('last_played')}</p>
                                            </div>
                                        </Stack>
                                        : 
                                        null
                                    }
                                    <div id="st">
                                        {!this.props.search && this.state.home_games.length > 0 ?
                                            <>
                                                <h4 className="mt-0 mb-0 section-title"> {t('original_games')}</h4> 
                                                <Game specific='recent' hgames={this.state.home_games} t={t} history={history} />
                                            </>
                                            : 
                                            <></>
                                        } 
                                    </div>
                                    { this.state.slot_games.length > 0 ? 
                                        <>
                                            <h4 className="mt-0 mb-0 section-title"> {t('slots_games')}</h4> 
                                            <Row id="allslots" className="d-flex game-cards m-0">
                                                {slot_games}
                                            </Row>
                                        </>
                                        : 
                                        <></> 
                                    }
                                </Card.Body>
                            </Card>
                    }
        </>
    }
}

export default Index;
