import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import storage from "../../../../Storage";
import RangeCredit from "../../../Components/Game/Addons/Range";
import { gameCoin } from "../../../../actions/gameCoin";
import { setWallet } from "../../../../actions/gameWallet";
import { userUID } from "../../../../Auth";
import {
  setWinnerText,
  setMeToGame,
  setCrashAmountAndCoin,
} from "../../../../actions/crashGame";
import {
  isLogged,
  Event,
  defaultBetAmount,
  getDefaultBetAmount,
  __,
  isValidNumber,
  forceSatoshiFormat,
  Game,
  sendNotfication,
} from "../../../../Helper";

class ManualBet extends Component {
  _isMounted = false;
  buttonProgress;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      buttonText: "BET",
      buttonType: "btn-bet",
      inputDisabled: false,
      gameStatus: null,
      clicked: false,
      added: false,
      holding: false,
      payout: "2.00",
      uid: userUID(),
      amount: defaultBetAmount(this.props.coin),
      hotkey: storage.getKey("hotkey") ? storage.getKey("hotkey") : "ON",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.setBet = this.setBet.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      let { engine } = this.state;

      //Load Coin From Redux
      this.props.gameCoin();

      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("mousedown", this.handleClickOutside2);

      engine.getStatus();

      // Game Event
      engine.on("game_status", (data) => this.checkStatus(data));

      engine.on("waiting_crash", () => this.checkWaitingGame());
      engine.on("busted_crash", () => this.checkBustedGame());
      engine.on("started_crash", (data) => this.checkStartedGame(data));

      // User Event
      engine.on("play_crash", (data) => this.handlePlay(data));
      engine.on("finish_crash", (data) => this.handleFinish(data));

      //Error
      engine.on("error_crash", (data) => this.handleError(data));

      // Stop manual bet
      engine.on("stop_playing", () => this.stopManual());

      //Events on auto bet
      engine.on("auto_bet", (data) => this.handleAuto(data));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      let value = parseFloat(this.state.payout);

      if (value < 1.01) value = 1.01;

      if (value > 1000000) value = 1000000;

      this.setState({ payout: parseFloat(value).toFixed(2) });
    }
  }

  handleClickOutside2(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      this.setState({ amount: value });
    }
  }

  stopManual() {
    this.props.setMeToGame(false);
    this.setState({ clicked: false, holding: false });
    this.setDefaultButton();
  }

  checkStatus(data) {
    if (this._isMounted) {
      switch (data.status) {
        case "waiting":
          this.checkWaitingGame();
          break;
        case "started":
          this.checkStartedGame(data);
          break;
        case "busted":
          this.checkBustedGame();
          break;
        default:
      }
    }
  }

  handleAuto = (data) => {
    if (this._isMounted) {
      let { amount, payout } = data;

      if (!payout) return alert("payout is empty.");
      if (!amount) return alert("amount is empty.");

      this.setState({ amount: amount, payout: payout });

      if (this.state.gameStatus === "started") {
        this.holdBet();
      } else if (this.state.gameStatus === "waiting") {
        this.setBet();
      }
    }
  };

  handleError(data) {
    if (this._isMounted) {
      this.props.setMeToGame(false);
      this.setState({ clicked: false, holding: false });
      this.setDefaultButton();

      if (!__.isUndefined(data.code)) {
        if (data.code === "credit") this.props.setWallet(true, data.uid);
      }

      sendNotfication(data.message, "info", "top-center");
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    if (name === "payout") {
      if (value > 999) return;
    }

    this.setState({ [name]: value });
  }

  setDefaultButton = () => {
    if (this._isMounted) {
      clearInterval(this.buttonProgress);
      this.setState({
        added: false,
        holding: false,
        inputDisabled: false,
        buttonType: "btn-bet",
        buttonText: "BET",
      });
    }
  };

  setWaitingButton = () => {
    if (this._isMounted) {
      this.setState({
        added: true,
        inputDisabled: true,
        buttonType: "btn-bet-success-crash text-white btn-p no-shadow",
        buttonText: "Please Wait...",
      });
    }
  };

  setCancelButton = () => {
    if (this._isMounted) {
      this.setState({
        inputDisabled: false,
        buttonType: "btn-bet-success",
        buttonText: "CANCEL (Next Round)",
      });
    }
  };

  setCashoutButton = (amount, coin) => {
    if (this._isMounted) {
      this.setState({
        inputDisabled: false,
        buttonType: "btn-bet-success-crash",
      });

      let counter = 0;
      let self = this;

      this.buttonProgress = setInterval(
        function () {
          let calc = amount * (Game["current_amount"] - 1);

          if (__.isNaN(calc)) {
            return self.setDefaultButton();
          }

          self.setState({
            buttonText: "CashOut " + forceSatoshiFormat(calc) + " " + coin,
          });
          counter++;
        }.bind(this),
        50
      );
    }
  };

  setBet() {
    if (this._isMounted) {
      this.setState({ clicked: true });
      let { engine, amount, payout } = this.state;
      engine.coin = this.props.coin;
      engine.amount = amount;
      engine.payout = parseInt(payout * 100);
      engine.play();
    }
  }

  cashOut() {
    if (this._isMounted) {
      let { engine } = this.state;
      engine.finish(Game["current_amount"]);
      clearInterval(this.buttonProgress);
    }
  }

  handlePlay(data) {
    if (this._isMounted) {
      if (parseFloat(data.uid) === parseFloat(this.state.uid)) {
        let { amount, coin } = data;
        this.props.setMeToGame(true);
        this.props.setCrashAmountAndCoin({ amount, coin });
      }
    }
  }

  handleFinish(data) {
    if (this._isMounted) {
      if (parseFloat(data.uid) === parseFloat(this.state.uid)) {
        clearInterval(this.buttonProgress);
        this.props.setWinnerText("   You Cashed Out at " + data.current / 100);
        this.props.setMeToGame(false);
        this.setDefaultButton();
      }
    }
  }

  checkWaitingGame() {
    if (this._isMounted) {
      this.props.setWinnerText("");

      clearInterval(this.buttonProgress);

      this.setState({ gameStatus: "waiting" });

      if (this.state.holding) {
        this.setState({ holding: false });
        this.placeBet();
      }
    }
  }

  checkStartedGame(data) {
    if (this._isMounted) {
      let { im_in_game, amount_coin_crash } = this.props;

      clearInterval(this.buttonProgress);

      this.setState({ gameStatus: "started" });

      let amount = this.state.amount,
        coin = this.props.coin,
        inGame = false;

      if (!__.isUndefined(amount_coin_crash)) {
        amount = amount_coin_crash.amount;
        coin = amount_coin_crash.coin;
      } else {
        //for refreshing page

        if (!__.isUndefined(data.players)) {
          for (var i = 0; i < data.players.length; i++) {
            var player = data.players[i];
            if (parseFloat(player.uid) === parseFloat(this.state.uid)) {
              amount = player.amount;
              coin = player.coin;
              if (player.winner === "false") {
                inGame = true;
                this.props.setMeToGame(true);
              }
            }
          }
        }
      }

      if (this.state.buttonText === "Please Wait...") {
        this.setCashoutButton(amount, coin);
      } else {
        if (im_in_game === true || this.state.clicked || inGame) {
          this.setCashoutButton(amount, coin);
        }
      }
    }
  }

  checkBustedGame() {
    if (this._isMounted) {
      clearInterval(this.buttonProgress);

      this.setState({ gameStatus: "busted", clicked: false });

      let { im_in_game } = this.props;

      if (!this.state.holding) {
        this.props.setMeToGame(false);
        this.setDefaultButton();
      }

      if (im_in_game === true) {
        this.props.setMeToGame(false);
        this.setDefaultButton();
      }
    }
  }

  isCorrectBet() {
    let { amount, payout } = this.state;

    if (!isValidNumber(amount)) return false;

    if (payout * 100 < 100) return false;

    if (payout * 100 === 100) return false;

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    return true;
  }

  placeBet() {
    if (this._isMounted) {
      let { engine } = this.state;

      engine.isHolding = false;
      this.setWaitingButton();
      this.setBet();
    }
  }

  holdBet() {
    if (this._isMounted) {
      let { engine } = this.state;
      engine.isHolding = true;
      this.setState({ holding: true });
      this.setCancelButton();
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { payout, holding, gameStatus } = this.state;
      let { im_in_game } = this.props;

      // Check User
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      // Check is Correct Bet
      if (!this.isCorrectBet()) return false;

      this.setState({ payout: (payout * 1).toFixed(2) });

      // Check Game Status to Play
      switch (gameStatus) {
        case "waiting":
          this.placeBet();
          break;

        case "busted":
          if (holding) {
            this.setDefaultButton();
          } else this.holdBet();
          break;

        case "started":
          if (im_in_game === true) this.cashOut();
          else if (holding) this.setDefaultButton();
          else this.holdBet();
          break;
      }
    }
  }

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  changePayout(type) {
    const { payout } = this.state;
    var value;
    if (type === "up") {
      value = parseFloat(payout) + 1;
    } else {
      value = parseFloat(payout) - 1;
    }
    this.setState({ payout: value.toFixed(2) });
  }

  render() {
    let { amount, inputDisabled, payout, buttonType, buttonText, hotkey } =
      this.state;
    let { coin } = this.props;
    return (
      <div>
        <Form
          className="w-100 mt-1"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={7} md={7} sm={12} className="crash-bet">
              <div className={"form-group mb-1 bet-input payout"}>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <img
                        src={"/assets/images/" + __.toLower(coin) + ".png"}
                        className={"mini-coin-7"}
                        alt="Coin"
                      />
                      BET
                    </span>
                  </div>
                  <input
                    ref={this.outRef}
                    disabled={inputDisabled}
                    type="number"
                    className="form-control text-left"
                    id="amount"
                    name="amount"
                    placeholder="..."
                    value={amount}
                    autoComplete={"off"}
                    onKeyUp={this.handleInputChange}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-text pr-0">
                    <RangeCredit set={this.setAmount} amount={amount} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={5} md={7} sm={12} className="crash-payout">
              <div className={"form-group mb-1 bet-input payout mb-2"}>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text">PAYOUT</span>
                  </div>
                  <input
                    ref={this.wrapperRef}
                    disabled={inputDisabled}
                    type="number"
                    className="form-control text-left"
                    id="payout"
                    name="payout"
                    min="1.01"
                    max="99999"
                    step="0.01"
                    placeholder=".."
                    value={payout}
                    autoComplete={"off"}
                    onKeyUp={this.handleInputChange}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-text pr-1">
                    <button
                      onClick={() => this.changePayout("down")}
                      className={"btn btn-s-4 btn-xs"}
                      type="button"
                    >
                      <i className="mdi mdi-arrow-left-bold" />
                    </button>
                    <button
                      onClick={() => this.changePayout("up")}
                      className={"btn btn-s-4 btn-xs"}
                      type="button"
                    >
                      <i className="mdi mdi-arrow-right-bold" />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={5} md={5} sm={12} className="m-auto buttoncrashcontainter">
              <div className={"form-group rev mt-0 mb-0 btncrash"}>
                <Button
                  variant={"btn btn-block " + buttonType}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

class HotKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      effect: "zoomIn",
    };
  }

  toggleModal = (e) => {
    if (e !== undefined) e.preventDefault();

    this.setState({
      show: !this.state.show,
      effect: !this.state.show ? "zoomIn" : "zoomOut",
    });
  };

  render() {
    return (
      <>
        <button
          className={"btn btn-xs stc mt-1 pl-1"}
          onClick={(e) => this.toggleModal(e)}
        >
          <i className={"cp mdi mdi-information text-info font-15"} />
        </button>
        <Modal
          size="md"
          centered={true}
          show={this.state.show}
          onHide={this.toggleModal}
          aria-labelledby="help-lg-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header>
            Hot Keys
            <button
              type="button"
              className="close p-2"
              onClick={(e) => this.toggleModal(e)}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body closeButton className="modal-helper text-center">
            <Row className="shortcut-helper">
              <Col sm="12">
                <span className="badge badge-soft-danger">SPACE</span>
                <i className="mdi mdi-arrow-right align-middle"></i>
                <span className="badge badge-soft-secondary"> BET</span>
              </Col>
              <Col sm="12" className="my-2">
                <span className="badge badge-soft-danger"> E</span>
                <i className="mdi mdi-arrow-right align-middle"></i>
                <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
              </Col>
              <Col sm="12">
                <span className="badge badge-soft-danger"> F</span>
                <i className="mdi mdi-arrow-right align-middle"></i>
                <span className="badge badge-soft-secondary">
                  {" "}
                  DOUBLE PAYOUT
                </span>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ManualBet.propTypes = {
  coin: PropTypes.string,
  im_in_game: PropTypes.bool,
  credit: PropTypes.string,
  amount_coin_crash: PropTypes.object,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  im_in_game: state.items.im_in_game,
  credit: state.items.credit,
  amount_coin_crash: state.items.amount_coin_crash,
});

export default connect(mapStateToProps, {
  gameCoin,
  setWinnerText,
  setMeToGame,
  setCrashAmountAndCoin,
  setWallet,
})(ManualBet);
