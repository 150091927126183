import * as PIXI from "pixi.js";
import Effect from "./components/Effect";
import Loader from "../Loader";
import { wait, __, secureRandomNumber } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "/assets/images/magic-wheel";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 450,
      height: 450,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });

    this.engine = engine;
    this.numbers = ["2", "NEXT", "36", "24", "12"];
    this.number = null;
    this.isPlaying = false;
    this.wheel = [];
    this.round = 0;
    this.container = null;
    this.target = null;
    this.filter = new PIXI.filters.ColorMatrixFilter();
    this.blurFilter = new PIXI.filters.BlurFilter();
    this.loader = new Loader("magic_wheel", this.app);

    this.spinTotalTime = 0;
    this.spinStartTime = 0;
    this.spinFinalTime = 0;
    this.finalPosition = 0;
    this.startPosition = 0;
    this._worldAngle = -((2 * Math.PI) / 180 / 100) * Date.now() - Date.now();
  }

  init() {
    this.loader.add([
      ASSETS_PATH + "/frame.png",
      ASSETS_PATH + "/frame3.png",
      ASSETS_PATH + "/frame4.png",
      ASSETS_PATH + "/holder.png",
      ASSETS_PATH + "/wheel0.png",
      ASSETS_PATH + "/wheel1.png",
      ASSETS_PATH + "/wheel2.png",
    ]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load() {
    if (this.app === null) return;
    const container = new PIXI.Container();
    container.sortableChildren = true;
    this.app.stage.sortableChildren = true;
    container.width = container.height = 540;
    container.position.set(
      this.app.screen.width / 2,
      this.app.screen.height / 2
    );
    this.app.stage.addChild(container);
    this.container = container;

    this.configureWheel();
    this.configureHolder();
    this.configureFrame();

    this.app.ticker.add((delta) => this.animation(delta, 0));
  }

  animation(delta, count) {
    count = 0.1;
    let time = 2;
    if (!this.isPlaying) {
      return;
    }

    if (Math.round(time % delta) == 1) {
      this.framec.texture = PIXI.Texture.from(ASSETS_PATH + `/frame4.png`);
    } else {
      this.framec.texture = PIXI.Texture.from(ASSETS_PATH + `/frame3.png`);
    }

    time++;

    this.spinTimeElapsed = Date.now() - this.spinStartTime;

    let f = this.spinTimeElapsed / this.spinTotalTime;
    f = f > 1 ? 1 : f;

    this.holder.rotation = this.finalPosition / this.easeInOutExpo(f);
    this.framec.rotation = this.finalPosition / this.easeInOutQuart(f);
    this.blurFilter.blur = 1 * delta * f;
    this.holder.filters = [this.blurFilter];
    this.framec.filters = [this.blurFilter];

    if (this.finalPosition === this.holder.rotation) {
      this.holder.filters = [];
      this.framec.filters = [];
      let w = this.round;

      if (this.round === 0) {
        w = 2;
      } else if (this.round === 2) {
        w = 0;
      }

      Effect.Filter(this.wheel[w], "OldFilmFilter");
      if (this.result[this.round] === "NEXT") {
        wait(1500).then(() => {
          this.round++;
          this.play(this.result);
        });
      } else {
        this.engine.init = true;
        this.engine.round = this.round;
        this.engine.busted();
        this.round = 0; // Reset Game
        wait(800).then(() => {
          Effect.Clear(this.wheel[0]);
        });
        wait(1100).then(() => {
          Effect.Clear(this.wheel[1]);
        });
        wait(1400).then(() => {
          Effect.Clear(this.wheel[2]);
        });
      }

      this.isPlaying = false;
    }
  }

  configureWheel() {
    for (var i = 0; i < 3; i++) {
      this.wheel[i] = new PIXI.Sprite.from(ASSETS_PATH + `/wheel${i}.png`);
      this.wheel[i].anchor.set(0.5);
      this.wheel[i].angle += 40;
      if (i === 0) {
        this.wheel[i].width = this.wheel[i].height = 420;
      } else if (i === 1) {
        this.wheel[i].width = this.wheel[i].height = 320;
      } else if (i === 2) {
        this.wheel[i].width = this.wheel[i].height = 230;
      }
      this.container.addChild(this.wheel[i]);
    }
  }

  configureHolder() {
    this.holder = new PIXI.Sprite.from(ASSETS_PATH + `/holder.png`);
    this.holder.anchor.set(0.5);
    this.holder.width = this.holder.height = 180;
    this.holder.y = 1;
    this.container.addChild(this.holder);
  }

  configureFrame() {
    this.framec = new PIXI.Sprite.from(ASSETS_PATH + `/frame.png`);
    this.framec.anchor.set(0.5);
    this.framec.width = this.framec.height = 480;
    this.framec.y = 1;
    this.container.addChild(this.framec);
  }

  play(result) {
    this.result = result;
    let slot = this.findIndexOfSlot(this.result[this.round]);
    this.spinTotalTime = randomBetween(3, 5, false) * 1000;
    this.spinStartTime = Date.now();
    this.spinFinalTime = this.spinStartTime + this.spinTotalTime;
    this.finalPosition = randomBetween(2, 5) * 2 * Math.PI + slot.position;
    this.startPosition = this.finalPosition;
    this.isPlaying = true;

    let w = this.round;

    if (this.round === 0) {
      w = 2;
    } else if (this.round === 2) {
      w = 0;
    }

    Effect.HeartBeat(this.wheel[w], 5);

    Effect.Filter(this.wheel[w], "BloomFilter");
  }

  findIndexOfSlot(num) {
    if (this.round === 2) {
      this.numbers = ["2", "45", "36", "24", "12"];
    } else {
      // fix for next resetting game
      this.numbers = ["2", "NEXT", "36", "24", "12"];
    }
    let slotNum = this.numbers.indexOf(`${num}`);
    let pos = (2 * Math.PI) / 5;
    return {
      position: pos * slotNum,
    };
  }

  easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }

  easeInOutExpo(x) {
    return x === 0
      ? 0
      : x === 1
      ? 1
      : x < 0.5
      ? Math.pow(2, 20 * x - 10) / 2
      : (2 - Math.pow(2, -20 * x + 10)) / 2;
  }

  easeInOutQuart(x) {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

function randomBetween(min, max, floor = true) {
  let r = Math.random() * max + min;
  if (floor === true) {
    return Math.floor(r);
  }
  return r;
}

export default Canvas;
