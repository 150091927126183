import React from "react";
import socket from "../../../../../Socket";
import C from "../../../../../Constant";
import { encode, decode } from "../../../../../Helper";

class HouseEdge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      game: props.game,
      houseEdge: null,
    };
  }
  componentDidMount() {
    socket.emit(C.GET_HOUSEEDGE, encode({ game: this.state.game }));
    socket.on(C.GET_HOUSEEDGE, (data) => {
      let { edge } = decode(data);
      this.setState({ houseEdge: edge });
    });
  }
  componentWillUnmount() {
    socket.off(C.GET_HOUSEEDGE);
  }
  render() {
    return <>{this.state.houseEdge}</>;
  }
}

export default HouseEdge;
