import _ from "lodash";
import * as PIXI from "pixi.js";
import * as Filter from "pixi-filters";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/*
 * Register Pixi Plugin for GSAP
 */
gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const Effect = {};

/**
 *  Light On/Off Object
 */
Effect.Light = function (object, time) {
  let stop = false;

  const start = () => {
    if (stop) return;
    gsap.to(object, {
      duration: 0.5,
      pixi: { brightness: 3, combineCMF: true },
    });
    wait(500).then(() => {
      min();
    });
  };

  const min = () => {
    gsap.to(object, {
      duration: 0.5,
      pixi: { brightness: 1, combineCMF: true },
    });
    wait(500).then(() => {
      start();
    });
  };

  start();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 *  Heartbeat Animate Object
 */
Effect.HeartBeat = function (object, time) {
  let stop = false;

  const start = () => {
    if (stop) return;
    gsap.to(object.scale, {
      x: object.scale.x + 0.02,
      y: object.scale.y + 0.02,
    });
    wait(500).then(() => {
      min();
    });
  };

  const min = () => {
    gsap.to(object.scale, {
      x: object.scale.x - 0.02,
      y: object.scale.y - 0.02,
    });
    wait(500).then(() => {
      start();
    });
  };

  start();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 * Animate Flash object
 */
Effect.Flash = function (object, time) {
  let stop = false;

  const up = () => {
    gsap.to(object, { duration: 1, pixi: { alpha: 1 } });
    wait(700).then(() => {
      down();
    });
  };
  const down = () => {
    if (stop) return;
    gsap.to(object, { duration: 1, pixi: { alpha: 0.1 } });
    wait(700).then(() => {
      up();
    });
  };

  up();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 * Animate Zoom In Object
 */
Effect.ZoomIn = function (object, s, d) {
  object.scale.set(0);
  gsap.to(object, { duration: d, pixi: { scale: s } });
};

/**
 * Animate Fade In Object
 */
Effect.FadeIn = function (object, d) {
  object.alpha = 0;
  gsap.to(object, { duration: d, pixi: { alpha: 1 } });
};

/**
 * Animate Fade Out Object
 */
Effect.FadeOut = function (object, d) {
  object.alpha = 1;
  gsap.to(object, { duration: d, pixi: { alpha: 0 } });
};

/**
 * Animate Rotate Object
 */
Effect.Angle = function (object, value) {
  gsap.to(object, { duration: 0.5, pixi: { angle: value } });
};

/**
 * Animate inverse Object
 */
Effect.inverse = function (object, texture, value) {
  if (object.scale.x > -1.04569112692752891) {
    object.scale.x -= Math.sin(value) / Math.PI;
  } else {
    object.ok = false;
    object.texture = PIXI.Texture.from(texture);
    object.scale.x = 0.55;
  }
};

/**
 * Animate Scale  Object
 */
Effect.Scale = function (object, value) {
  gsap.to(object, { duration: 0.5, pixi: { scale: value } });
};

/**
 * Animate Move In Object
 */
Effect.MoveIn = function (object, x, y) {
  if (y) {
    gsap.to(object, { duration: 0.4, pixi: { x: x, y: y } });
  } else {
    gsap.to(object, { duration: 0.4, pixi: { x: x } });
  }
};

/**
 * Add Filter to Object
 ** Etc: KawaseBlurFilter, GlowFilter, DropShadowFilter, BulgePinchFilter, BloomFilter , AsciiFilter, AdvancedBloomFilter , OutlineFilter, OldFilmFilter, SimpleLightmapFilter
 */
Effect.Filter = function (object, filter) {
  const f = new Filter[filter]();
  object.filters = [f];
};

/**
 * Clear Filter
 */
Effect.Clear = function (object) {
  if (!_.isUndefined(object)) object.filters = [];
};

/**
 *  Add a Text
 */
Effect.Text = function (str, config) {
  let bold = config.bold ? 700 : 300;
  let style = new PIXI.TextStyle({
    fontFamily: config.font ? config.font : "Tahoma",
    fontSize: config.size,
    fontWeight: bold,
    fill: [config.color],
  });
  return new PIXI.Text(str, style);
};

export default Effect;
