import React, { useEffect, useRef, createRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {
  decode,
  encode,
  wait,
  sendNotfication,
  Event,
  __,
  removeTrailingZerosFixNum,
  forceSatoshiFormat,
} from "../../../../Helper";
import C from "../../../../Constant";
import {
  CChart,
  CChartBar,
  CChartHorizontalBar,
  CChartLine,
  CChartDoughnut,
  CChartRadar,
  CChartPie,
  CChartPolarArea,
} from "@coreui/react-chartjs";

class Statistics extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    wait(500).then(() => {
      this.setState({ loading: false });
    });

    if (storage.getKey("uid"))
      socket.emit(C.USER_STATS, encode({ id: storage.getKey("uid") }));

    socket.on(C.USER_STATS, (data) => this.userStats(decode(data)));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  userStats(data) {
    this.setState(data);
  }

  render() {
    const { t } = this.props;
    let { wins, bets, created, withdrawals, deposits, wagered } = this.state;

    wagered = wagered ? wagered : 0;
    return (
      <>
        {this.state.loading ? (
          <div className="text-center">
            <div className="spinner-grow text-white my-2" role="status" />
          </div>
        ) : (
          <>
            <Row className="mt-">
              <Col sm={6}>
                <div className="pro-order-box">
                  <h4 className="header-title">
                    <i className="mdi mdi-refresh text-danger font-15"></i>{" "}
                    Wagered
                  </h4>
                  <p className="text-warning mb-0 text-center font-20">
                    ${parseFloat(wagered).toFixed(2)}
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="pro-order-box">
                  <h4 className="header-title">
                    <i className="mdi mdi-refresh text-danger font-15"></i> Bets
                  </h4>
                  <p className="text-warning mb-0 text-center font-20">
                    {bets}
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="pro-order-box">
                  <h4 className="header-title">
                    <i className="mdi mdi-wallet text-purple font-15"></i>{" "}
                    Deposits
                  </h4>
                  <p className="text-warning mb-0 text-center font-20">
                    {deposits}
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="pro-order-box">
                  <h4 className="header-title">
                    <i className="mdi mdi-wallet text-purple font-15"></i>{" "}
                    Withdrawals
                  </h4>
                  <p className="text-warning mb-0 text-center font-20">
                    {withdrawals}
                  </p>
                </div>
              </Col>
              <Col sm={12}>
                <div className="pro-order-box">
                  <h4 className="header-title">
                    <i className="mdi mdi-wallet text- font-15"></i> Wins / Lose
                  </h4>
                  <p>
                    <Chart wins={wins} bets={bets} />
                  </p>
                </div>
              </Col>
              <Col sm={12}>
                <div className="pro-order-box">
                  <div className="history-page">
                    <h4 className="header-title">
                      <i className="mdi mdi-wallet text- font-15"></i> My Bets
                    </h4>
                    <p>
                      <History t={this.props.t} />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { wins, bets } = this.props;

    const lose = bets - wins;

    return (
      <CChart
        type="pie"
        data={{
          datasets: [
            {
              label: "Wins",
              backgroundColor: "rgb(76 175 80 / 50%)",
              hoverBackgroundColor: "#34a154",
              data: [wins],
            },
            {
              label: "Lose",
              backgroundColor: "rgba(255,99,132,0.2)",
              hoverBackgroundColor: "#f44336",
              data: [lose],
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              backgroundColor: "#FFF",
              titleFontSize: 16,
              titleFontColor: "#FFF",
              bodyFontColor: "#000",
              bodyFontSize: 14,
              displayColors: false,
            },
          },

          aspectRatio: 3.9,
        }}
      />
    );
  }
}

class History extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDetails: false,
      next: false,
      history: [],
      pageIndex: 1,
      itemsPerPage: 10,
      total_items: 0,
    };
    this.socketEventHistory = null;
  }

  componentDidMount() {
    this._isMounted = true;
    wait(1000).then(() => {
      if (document.getElementById("list") !== null) {
        const ps = new PerfectScrollbar("#list", {
          wheelSpeed: 1,
          suppressScrollX: true,
          wheelPropagation: true,
          minScrollbarLength: 2,
        });
        ps.update();
      }
    });
    wait(500).then(() => {
      socket.emit(
        C.MY_HISTORY,
        encode({
          pageIndex: this.state.pageIndex,
          items: this.state.itemsPerPage,
        })
      );
    });
    this.socketEventHistory = socket.on(C.MY_HISTORY, (data) => {
      this.makeHistory(decode(data));
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.socketEventHistory.off();
  }

  makeHistory = (arr) => {
    if (this._isMounted) {
      var result = arr.history;
      let history = [];
      result.forEach((player, i) => {
        history.push(
          <HistoryTable
            clicked={(e) => this.clicked(player)}
            key={__.toString(i)}
            player={player}
            self={true}
          />
        );
      });
      this.setState((state) => ({
        history: [...history],
        total_items: result.length > 0 ? Number(result[0].total_rows) : 0,
      }));

      this.setState({ loading: false });
    }
  };

  clicked = (data) => {
    if (this._isMounted) {
      Event.emit("single_game_modal_close");
      Event.emit("single_game_modal", { data: data });
      this.setState({ showDetails: true });
    }
  };

  nextPage = () => {
    if (this._isMounted) {
      socket.emit(
        C.MY_HISTORY,
        encode({
          pageIndex: this.state.pageIndex + 1,
          items: this.state.itemsPerPage,
        })
      );
      this.setState({ pageIndex: this.state.pageIndex + 1 });
    }
  };

  back = () => {
    if (this._isMounted) {
      this.setState({ showDetails: false });
    }
  };

  render() {
    let { loading, history } = this.state;

    const { t } = this.props;

    return (
      <>
        <>
          {loading ? (
            <div className="text-center my-3">
              <div
                className="spinner-grow text-white my-3"
                role="status"
                style={{ backgroundColor: "#FFF" }}
              />
            </div>
          ) : (
            <>
              <>
                <div className="user-history" style={{ maxHeight: 500 }}>
                  <div className="table-responsive mt-2" id="list">
                    <table className="table table-hover font-13">
                      <thead>
                        <tr>
                          <th scope="col">{t("game")}</th>
                          <th scope="col">{t("amount")}</th>
                          <th scope="col">{t("profit")}</th>
                        </tr>
                      </thead>
                      <tbody>{history.length > 0 && history}</tbody>
                    </table>

                    {history.length === 0 && (
                      <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                        [ {t("you_have_not_played_yet")} ]
                      </div>
                    )}
                    {this.state.total_items / this.state.itemsPerPage >
                      this.state.pageIndex && (
                      <div className={"text-center"}>
                        <button
                          onClick={this.nextPage}
                          className={"mb-2 btn bg-cs btn-xs"}
                        >
                          <i
                            className={
                              "mdi mdi-arrow-right-bold-circle font-12 align-middle"
                            }
                          />{" "}
                          {t("next_page")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </>
          )}
        </>
      </>
    );
  }
}

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  render() {
    let {
      username,
      amount,
      profit,
      coin,
      game,
      slot_round_id,
      provider,
      image,
    } = this.props.player;
    let isWinner = false;

    console.log(this.props.player);

    amount = removeTrailingZerosFixNum(
      Number(forceSatoshiFormat(amount, coin)),
      2
    );
    profit = removeTrailingZerosFixNum(
      Number(forceSatoshiFormat(profit, coin)),
      2
    );

    if (__.toNumber(profit) !== 0.0) isWinner = true;

    game = __.replace(game, "snakeandladders", "snake");
    game = __.replace(game, "cardmonte", "Card Monte");
    game = __.replace(game, "videopoker", "Video Poker");

    return (
      <tr className="cpt">
        <td onClick={this.props.clicked}>
          <div className="historyrow historyrowfirst">
            <img
              src={
                slot_round_id
                  ? image
                  : "/assets/images/covers_new/" + game + ".svg"
              }
              className={"img-fluid mr-2 w-10"}
              alt={game}
            />
            {__.replace(__.upperFirst(game), "_", " ")}
          </div>
        </td>
        <td
          onClick={this.props.clicked}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          <div className="historyrow">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-2 hidden-sm"}
              alt={coin}
            />
            {amount} {__.upperCase(coin)}
          </div>
        </td>
        <td
          onClick={this.props.clicked}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          <div className="historyrow historyrowlast">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-2 hidden-sm"}
              alt={coin}
            />
            {profit} {__.upperCase(coin)}
          </div>
        </td>
      </tr>
    );
  }
}

export default Statistics;
