import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat } from "../../../Helper";
/**
 * Game Engine
 */
function Engine() {
  var self = this;

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Game Name
   */
  self.game = null;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Intialize Starting Game
   */
  self.init = false;

  /**
   * Game Status
   */
  self.gameStatus = null;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /**
   * banker / player / tie
   */
  self.target = [];

  /**
   * Game hands
   */
  self.hand = null;

  /**
   * Game result
   */
  self.result = null;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_BACCARAT, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_BACCARAT);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Start playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }
  self.ws.emit(
    C.PLAY_BACCARAT,
    encode({
      amount: self.amount,
      target: self.target,
      coin: self.coin,
    })
  );
};

/** Start Game Engine **/
export default Engine;
