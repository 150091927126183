import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import socket from "../../../Socket";
import storage from "../../../Storage";
import { decode, encode, getElement, sendNotfication, isEmail, Event, DEVELOPMENT, wait, randomString, secureRandomNumber } from "../../../Helper";
import C from "../../../Constant";
import { FormatColorResetOutlined } from '@mui/icons-material';
// import { reset } from '../../../../backend/General/Queue/Played';

class Resetpass extends Component {
    reset_token = '';
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            password: '',
            password_confirm: '',
            status: false,
            disabled: false,
            submitted: false,
        };
        this.submitForm = this.submitForm.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.on(C.UPDATE_PASSWORD, data => this.setResetProcess(decode(data)));
        let searchParams = new URLSearchParams(this.props.location.search);
        this.reset_token = searchParams.get('token');
        this.reset_token && this.setState({show: true});
    }

    componentWillUnmount() {
        socket.off(C.UPDATE_PASSWORD);
    }

    setResetProcess = (data) => {
        if (data.status == true) {
            this.setState({ submitted: false });
            sendNotfication(this.props.t('successfully_registered'), 'success', 'bottom-left');
            this.login();
        }
        else {
            this.setState({ disabled: false, submitted: false });
            sendNotfication(data.status, 'danger', 'bottom-left');
        }
    };

    handleShow(e) {
        this.setState({ show: true });
    }

    handleClose() {
        // this.setState({ show: false, submitted: false, disabled: FormatColorResetOutlined });
        this.setState({ show: false, submitted: false, disabled: false });
    }

    submitForm(e) {
        e.preventDefault();
        const { t } = this.props;

        this.setState({ submitted: true, disabled: true });

        const { password, password_confirm } = this.state;

        if (!(password && password_confirm)) {
            this.setState({ disabled: false });
            return;
        }

        if(password != password_confirm) {
            this.setState({ disable: false });
            sendNotfication(this.props.t('successfully_registered') + "..", 'danger', 'bottom-left');
            return;
        }

        this.setState({ password: password, password_confirm: password_confirm });
        socket.emit(C.UPDATE_PASSWORD, encode({
            password: password,
            token: this.reset_token
        }));
    }

    login = e => {
        this.handleClose();
        Event.emit('login_form');
    }

    render() {
        const { submitted, password, password_confirm, disabled } = this.state;
        const { t } = this.props;
        return (
            <>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="register-md-modal"
                    className={'modalAuth animated ' + this.state.effect}
                >
                    <Modal.Header>
                        <Link to='/' className='register-close-link'>
                            <button type="button" className="close p-2" onClick={this.handleClose}>
                                <i className={'mdi mdi-close'} />
                            </button>
                        </Link>
                    </Modal.Header>
                    <Modal.Body className={"rev auth-modal p-0 animated " + this.state.regEffect}>
                        <div>
                            <div>
                                <div className="text-center">
                                    <img src="/assets/images/betsmixer-g.svg" className={'img-fluid auth-logo'} alt="Logo" />
                                </div>
                                <div className="px-4">
                                    <form className="form-horizontal auth-form my-4 registerform" onSubmit={(e) => { this.submitForm(e) }}>
                                        <div className="form-group mb-2">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text bgin">{t('new_password')}</span>
                                                </div>
                                                <input type="password"
                                                    className="form-control"
                                                    value={password}
                                                    autoComplete="off"
                                                    onChange={e => this.setState({ password: e.target.value })}
                                                    style={{ height: 40 }}
                                                />
                                            </div>
                                            {submitted && !password &&
                                                <div className="help-block">{t('password_is_required')}</div>
                                            }
                                        </div>
                                        <div className="form-group mb-2">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text bgin">{t('confirm_password')}</span>
                                                </div>
                                                <input type="password"
                                                    className="form-control"
                                                    value={password_confirm}
                                                    autoComplete="off"
                                                    onChange={e => this.setState({ password_confirm: e.target.value })}
                                                    style={{ height: 40 }}
                                                />
                                            </div>
                                            {
                                                (submitted && password && !password_confirm &&
                                                    <div className="help-block">{t('confirm_password_is_required')}</div>) ||
                                                (password != password_confirm &&
                                                    <div className="help-block">{t('confirm_password_is_matched')}</div>)
                                            }
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-auth btn-block font-weight-bold no-shadow" disabled={disabled}>
                                                {
                                                    this.state.disabled &&
                                                    <span className="spinner-grow spinner-grow-sm mr-1" role="loading"></span>
                                                }
                                                <i className="mdi mdi-login mr-1 float-left font-18" /> {t('reset_password')}
                                            </button>
                                        </div>
                                        <div className="mt-3 alreadyaccount">
                                            <img src="/assets/images/footer/footer-i1.svg" alt="logo"/>
                                            <a href="#" onClick={this.login}>{t('not_change_password')} ?</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withRouter(Resetpass)
