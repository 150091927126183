import React from "react";
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Nav,
  Dropdown,
  Form,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import Slots from "./Slots";
import { CheckboxDropdown } from "../Components/CheckboxDropdown";
import { RadioboxDropdown } from "../Components/RadioboxDropdown";
import socket from "../../Socket";
import C from "../../Constant";
import {
  wait,
  decode,
  __,
  encode,
  Event,
  isMobile,
  isTablet,
} from "../../Helper";
import storage from "../../Storage";

class Index extends React.Component {
  sortObj = {
    providers: [],
    types: [],
    mobile: false,
    tab: storage.getKey("slot_game_tab")
      ? storage.getKey("slot_game_tab")
      : "all",
    sub_filter: storage.getKey("slot_game_filter")
      ? storage.getKey("slot_game_tab") == "all"
        ? storage.getKey("slot_game_filter")
        : "lobby"
      : "lobby",
  };

  providers = [];
  types = [];

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      provider_items: [],
      type_items: [],
      mobile_items: [
        { id: "mobile_1", label: props.t("yes"), checked: false },
        { id: "mobile_2", label: props.t("no"), checked: true },
      ],
      b_sort: false,
      mobile: isMobile(),
    };
    this.handleResize = this.handleResize.bind(this);

    Event.emit("toggle_chat", false, true);
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.state.mobile) Event.emit("toggle_chat", false);
    else {
      if (storage.getKey("chat_view")) {
        Event.emit(
          "toggle_chat",
          storage.getKey("chat_view") == "true" ? true : false
        );
      } else {
        if (isMobile() || isTablet()) {
          Event.emit("toggle_chat", false);
        } else {
          //desktop
          Event.emit("toggle_chat", true);
        }
      }
    }

    window.addEventListener("resize", this.handleResize);

    socket.emit(C.GET_PROVIDER_TYPE_LIST);
    socket.on(C.GET_PROVIDER_TYPE_LIST, (data) => {
      let decoded_sort_items = decode(data);
      let providers = decoded_sort_items.providers;
      let types = decoded_sort_items.types;
      let provider_list = [];
      providers.map((item, i) => {
        provider_list.push({
          id: "provider_" + String(i + 1),
          label: item,
          checked: false,
        });
      });
      this.providers = provider_list;
      let type_list = [];
      types.map((item, i) => {
        type_list.push({
          id: "type_" + String(i + 1),
          label: item,
          checked: false,
        });
      });
      this.types = type_list;
      this.setState({ provider_items: provider_list, type_items: type_list });
    });

    try {
      let path = this.props.params.pathname.toLowerCase();
      let c = _.words(path);

      if (c[0] === "provider") {
        wait(2000).then(() => {
          let provider = c[1];
          if (c[2]) {
            provider = c[1] + " " + c[2];
          }
          if (c[3]) {
            provider = c[1] + " " + c[2] + " " + c[3];
          }
          this.setState((prevState) => {
            console.log({ provider });
            const updatedItems = prevState.provider_items.map((item) => {
              if (item.label.toLowerCase() === provider.toLowerCase()) {
                return { ...item, checked: true };
              }
              return item;
            });

            //Get Checked providers' Name List.
            this.sortObj.providers = updatedItems
              .filter((obj) => obj.checked)
              .map((obj) => obj.label);
            debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);

            return { provider_items: updatedItems };
          });
        });
      }
    } catch (e) {}
  }

  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.GET_PROVIDER_TYPE_LIST);
    socket.off(C.GET_SORTED_SLOTS);
    socket.off(C.GET_LOADER_SLOTS);
    window.removeEventListener("resize", this.handleResize);
  }

  sortItemListUpdate = (sort_variant, search) => {
    if (sort_variant == "providers") {
      this.setState((prevState) => {
        const searchedItems = this.providers.filter((obj) =>
          obj.label.toLowerCase().includes(search.toLowerCase())
        );
        return { provider_items: searchedItems };
      });
    } else if (sort_variant == "types") {
      this.setState((prevState) => {
        const searchedItems = this.types.filter((obj) =>
          obj.label.toLowerCase().includes(search.toLowerCase())
        );
        return { type_items: searchedItems };
      });
    }
  };

  sortSlotsTabsChange = (eventKey) => {
    this.sortObj.tab = eventKey;
    if (eventKey != "all") {
      this.sortObj.sub_filter = "lobby";
      storage.setKey("slot_game_filter", "lobby");
      document.getElementById("slots_sub_filter_lobby").click();
    }
    storage.setKey("slot_game_tab", eventKey);
    debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);
  };

  providerCheckerUpdate = (sort_variant, i, v) => {
    if (sort_variant == "providers") {
      this.setState((prevState) => {
        const updatedItems = prevState.provider_items.map((item) => {
          if (item.id === i) {
            return { ...item, checked: v };
          }
          return item;
        });

        //Get Checked providers' Name List.
        this.sortObj.providers = updatedItems
          .filter((obj) => obj.checked)
          .map((obj) => obj.label);
        debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);

        return { provider_items: updatedItems };
      });
    } else if (sort_variant == "types") {
      this.setState((prevState) => {
        const updatedItems = prevState.type_items.map((item) => {
          if (item.id === i) {
            return { ...item, checked: v };
          }
          return item;
        });

        //Get Checked Types' Name List.
        this.sortObj.types = updatedItems
          .filter((obj) => obj.checked)
          .map((obj) => obj.label);
        debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);
        return { type_items: updatedItems };
      });
    } else if (sort_variant == "mobile") {
      this.setState((prevState) => {
        const updatedItems = prevState.mobile_items.map((item) => {
          if (item.id === i) {
            return { ...item, checked: v };
          }
          return { ...item, checked: false };
        });

        //Get isMobile
        this.sortObj.mobile = updatedItems[0].checked;
        debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);
        return { mobile_items: updatedItems };
      });
    }
  };

  handleSlotGameSubFilter = (eventKey) => {
    if (!eventKey) {
      console.error("Slot Game Sub Fileter Error");
      return;
    }
    this.sortObj.sub_filter = eventKey;
    storage.setKey("slot_game_filter", eventKey);
    if (eventKey == "lobby") {
      debounce_get_sorted_slots_fun(this.sortObj, this, this.props.t);
    } else {
      this.sortObj.tab = "all";
      storage.setKey("slot_game_tab", "all");
      document.getElementById("slots-filter-tabs-tab-all").click();
    }
  };

  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  completedBSort = () => {
    this.setState({ b_sort: false });
  };

  render() {
    const { t, live } = this.props;

    return (
      <>
        {this._isMounted ? (
          <>
            <Helmet>
              <title>Slot Games | Crypto Casino Games</title>
              <meta
                name="description"
                content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
              />
              <meta
                name="og:title"
                content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
              />
              <meta
                name="og:description"
                content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
              />
              <link rel="canonical" href="/slots" />
            </Helmet>
            {!live ? (
              <div>
                <Row className="mb-2 justify-content-between slots-sorting">
                  <Col className="col-4 d-flex gap-2" md="4">
                    <CheckboxDropdown
                      t={t}
                      variant={t("providers")}
                      items={this.state.provider_items}
                      checkUpdate={this.providerCheckerUpdate}
                      listUpdate={this.sortItemListUpdate}
                    />
                    <CheckboxDropdown
                      t={t}
                      variant={t("types")}
                      items={this.state.type_items}
                      checkUpdate={this.providerCheckerUpdate}
                      listUpdate={this.sortItemListUpdate}
                    />
                    <RadioboxDropdown
                      t={t}
                      variant={t("mobile")}
                      items={this.state.mobile_items}
                      checkUpdate={this.providerCheckerUpdate}
                    />
                  </Col>
                  <Col className="d-flex gap-2 justify-content-end">
                    <Tabs
                      defaultActiveKey={this.sortObj.tab}
                      id="slots-filter-tabs"
                      className="sort-slots-tab"
                      justify
                      onSelect={this.sortSlotsTabsChange}
                    >
                      <Tab eventKey="all" title={t("all")}></Tab>
                      {/* <Tab eventKey="featured_game" title={t('game_shows')}>
                                    </Tab> */}
                      {/* <Tab eventKey="popular_game" title={t('popular_game')}>
                                    </Tab> */}
                      <Tab eventKey="top_game" title={t("top_game")}></Tab>
                    </Tabs>
                  </Col>
                </Row>
                <hr className="side-border w-100" />
                <Nav
                  variant="underline"
                  className="slot-game-sub-filter"
                  defaultActiveKey={this.sortObj.sub_filter}
                  onSelect={this.handleSlotGameSubFilter}
                >
                  <Nav.Item>
                    <Nav.Link eventKey={"lobby"} id="slots_sub_filter_lobby">
                      <div className="item-wrapper">
                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m496 128v16a8 8 0 0 1 -8 8h-24v12c0 6.627-5.373 12-12 12h-392c-6.627 0-12-5.373-12-12v-12h-24a8 8 0 0 1 -8-8v-16a8 8 0 0 1 4.941-7.392l232-88a7.996 7.996 0 0 1 6.118 0l232 88a8 8 0 0 1 4.941 7.392zm-24 304h-432c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zm-376-240v192h-36c-6.627 0-12 5.373-12 12v20h416v-20c0-6.627-5.373-12-12-12h-36v-192h-64v192h-64v-192h-64v192h-64v-192z" />
                        </svg>
                        {t("lobby")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="megaways">
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1" clipPath="url(#clip0_1871_8889)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.4026 2.29279L20.0817 6.39009L23.9889 8.29014L22.0185 12.0053L24 15.7205L20.0594 17.6205L19.3803 21.7178L15.0501 21.0704L11.9666 24L8.87201 21.0278L4.57514 21.7072L3.88497 17.5781L0 15.6887L1.98145 11.9735L0.0111317 8.29014L3.91837 6.36886L4.59741 2.3034L8.90538 2.98275L12 0L15.0835 2.94029L19.4026 2.29279ZM6.4401 17.25L7.64156 7.39499H10.4091L11.9886 13.74H12.0156L13.5951 7.39499H16.3626L17.5641 17.25H15.2556L14.7426 10.581H14.7156L13.1631 16.17H10.8411L9.28856 10.581H9.26156L8.74856 17.25H6.4401Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1871_8889">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {t("megaways")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="egypt">
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1" clipPath="url(#clip0_1871_8810)">
                            <path
                              d="M19.3826 15.2223L19.2598 15.1934L14.2285 14.0108C14.2284 
                                                14.0108 14.2283 14.0108 14.2282 14.0108C13.9767 13.9533 13.7125 14.0253 13.5267 14.203L19.3826
                                                15.2223ZM19.3826 15.2223L19.3826 15.0961L19.3822 10.936L19.3822 10.8082L19.2581 10.8389L14.0074
                                                12.1406C14.0072 12.1406 14.0071 12.1406 14.007 12.1407C13.679 12.2188 13.3379 12.0771 13.1683
                                                11.7874C12.9984 11.4971 13.0409 11.1296 13.2729 10.8861L13.2729 10.886C15.1918 8.86682 15.8765
                                                    7.15666 16.0708 5.92866C16.2651 4.70124 15.9681 3.96432 15.9374 3.89302L15.8455 3.93258L19.3826
                                                    15.2223ZM10.8206 11.7573C10.9992 11.395 10.8987 10.9574 10.581 10.7074C10.5381 10.6732 6.30919
                                                    7.29899 7.68108 4.08319C7.69315 4.05318 7.70427 4.02412 7.71366 3.99334C7.72056 3.96945 8.49323
                                                    1.56119 12.1367 1.72214C12.1685 1.72291 12.2063 1.72214 12.2381 1.72042C12.2509 1.71966 12.3101
                                                        1.71622 12.4046 1.71622L10.8206 11.7573ZM10.8206 11.7573C10.6429 12.1195 10.2359 12.3088 9.84191
                                                        12.2111M10.8206 11.7573L9.84191 12.2111M9.84191 12.2111L4.81491 10.9607V10.9358H4.71491L4.69077
                                                        11.0329L4.71491 11.0389V14.7092L4.69592 14.7129L4.71491 14.8111H4.81491V14.7918L9.39127 13.9067C9.67467
                                                        13.8486 9.96729 13.9419 10.1691 14.1491L9.84191 12.2111ZM15.9096 3.82069C15.58 2.8248 14.9072 2.27167
                                                            14.2153 1.96962C13.5264 1.66887 12.8179 1.61622 12.4046 1.61622L15.9096 3.82069ZM12.2324 1.62058C12.2032
                                                            1.62215 12.1686 1.62285 12.1401 1.62219C10.2941 1.54088 9.1644 2.11081 8.49454 2.71412C7.83725 3.3061 7.63064
                                                            3.92291 7.61801 3.96416C7.60998 3.9905 7.60025 4.01613 7.58869 4.04492C6.88194 5.70291 7.62311 7.38983 8.51907
                                                            8.64644C9.41823 9.90754 10.4947 10.7665 10.5187 10.7856L10.5192 10.786C10.8 11.007 10.8884 11.3934 10.7309
                                                                11.713L10.7308 11.7132C10.574 12.033 10.2145 12.2004 9.86601 12.114C9.866 12.114 9.86599 12.114 9.86597 12.114L4.73904
                                                                10.8388L4.6149 10.8079V10.9358V14.8111V14.9323L4.73389 14.9093L9.41026 14.0049L9.41135 14.0047C9.66054 13.9536 9.91881
                                                                14.0355 10.0974 14.2189L10.0976 14.219C10.2758 14.4012 10.3501 14.6607 10.2954 14.9095L8.67884 22.2462L8.65214 22.3674L8.77624
                                                                22.3677L14.671 22.3832L14.7931 22.3836L14.7693 22.2638L13.3091 14.8943C13.2592 14.6415 13.3409 14.3816 13.5266 14.2031L12.2324
                                                                    1.62058ZM12.063 2.96132L12.0629 2.9616L12.0705 2.96205C12.4883 2.98662 13.8746 3.19148 14.5824 4.84712C14.6103 4.93026 14.7725
                                                                    5.48132 14.5888 6.27032C14.4031 7.06819 13.8613 8.11993 12.4475 9.18306C12.3137 9.28301 12.1529 9.33521 11.9905 9.33521C11.9093
                                                                    9.33521 11.8273 9.32235 11.7486 9.29589C10.6906 8.94009 8.74949 7.60257 8.93277 5.09276L8.93278 5.09259C8.93649 5.04064 8.94558
                                                                    4.99017 8.95999 4.94013L8.96004 4.93995C9.06765 4.56349 9.34906 3.9995 9.85082 3.565C10.3493 3.13336 11.068 2.82661 12.063 2.96132Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="0.2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1871_8810">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {t("egypt")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="dragon">
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1" clipPath="url(#clip0_1871_8815)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.82254 17.1262C1.59482 18.1885 9.03126 25.2453 9.63816 23.8037C10.1694 22.4379 10.0176 19.4026 11.5352
                                                 17.8851C12.977 16.3676 14.0393 17.2781 14.7981 18.4163C15.4051 19.3269 15.6329 20.2374 14.1911 21.0721C16.5434 21.6792 18.8197 20.9962 19.4271 19.0234C19.5788
                                                  18.5681 18.5165 19.0993 17.7577 18.5681L18.2889 17.2023L16.8472 17.6575C16.6954 17.4298 16.5436 17.2023 16.3159 16.8988L16.9989 15.3812L15.6331 15.7607C14.9501
                                                   14.6984 14.4948 13.5601 14.7983 13.2567C15.2536 12.7254 15.7089 12.7254 16.4677 13.1807L16.2399 14.5465L17.3782 13.8635C17.606 14.0153 17.8335 14.167 18.0613
                                                    14.3188L17.9095 15.7605L19.1236 15.0018C19.3513 15.1535 19.6548 15.3813 19.8823 15.533L19.6546 17.2023L20.8687 16.5193C21.3999 17.0505 21.7792 16.6711 21.3999
                                                     18.1128C21.0204 19.5546 24.9663 16.5193 23.4487 15.2295C22.3864 14.3189 23.4487 12.4219 22.4622 10.9801C22.4622 10.9801 22.0827 13.6359 21.2481 12.8772C19.1235
                                                      11.0561 20.0341 10.0696 19.4271 8.93135C18.744 7.64134 18.0612 6.57905 16.9989 5.89623C15.7849 4.45447 13.4325 3.46817 12.0666 3.24044C10.3213 2.86096 9.79026
                                                       1.64694 9.25899 0.58465C8.72777 -0.401836 7.89314 -0.0223663 8.12069 0.812372C8.50018 1.57113 9.18301 4.07518 11.3836 4.60643C11.9148 4.75819 12.5219 4.90995
                                                        13.0529 5.13765C12.0664 5.21361 11.08 5.2894 10.0935 5.44116C8.65174 4.15114 6.07183 3.54413 4.63013 3.54413C2.80904 3.46816 2.12603 2.40585 1.36725 1.49535C0.68424
                                                         0.584807 -0.074535 1.11588 0.228957 1.87483C0.760181 2.55783 1.8983 4.90996 4.17485 5.0617C5.31315 5.0617 6.60296 5.36521 7.58952 5.89644C7.36179 5.97241
                                                          6.67895 6.88293 6.37546 7.3382C7.28602 7.3382 8.57607 7.3382 8.12079 8.09696C7.7413 8.85572 6.2997 9.00751 5.0856 9.08344C4.85787 9.46292 4.63031 9.91819
                                                           4.40258 10.3735C5.38909 10.3735 6.8307 10.2975 6.29965 11.1322C5.76843 11.967 4.70612 12.1187 3.49201 12.1945C3.34025 12.6498 3.11253 13.1051 2.96079
                                                            13.4845C3.9473 13.5605 5.23716 13.7122 4.70612 14.5468C4.32663 15.1538 3.18852 15.4574 2.12621 15.6091C1.97445 16.1403 1.82269 16.7474 1.82269 17.1267L1.82254
                                                             17.1262ZM15.9363 9.46236C16.6193 9.61412 16.9228 10.6764 16.9228 11.1317C16.9228 11.6629 16.4675 11.5111 15.7845 11.2076C15.1775 10.9799 14.6462 10.6006
                                                              14.6462 10.1453C14.6462 9.61413 15.1775 9.3106 15.9363 9.46234L15.9363 9.46236ZM10.9282 9.46236C11.6112 9.46236 11.9905 9.84184 11.9905 10.3729C11.9905
                                                               10.9041 11.5352 11.0559 10.9282 11.2076C10.4729 11.2076 8.87938 10.8282 8.12056 10.4489C7.58934 10.2212 7.36179 9.99362 8.12056 9.99362C9.86589 9.91765
                                                                10.3212 9.4624 10.9282 9.4624V9.46236ZM9.33467 15.153C9.9417 14.9253 10.5487 15.0771 10.7005 15.5325C10.9282 16.0637 10.6245 16.4431 10.0175 16.7466C9.71398
                                                                 16.9743 7.96868 17.2778 7.20986 17.2018C6.60283 17.2018 6.3751 17.1259 6.98214 16.8224C8.49973 16.0636 8.72746 15.4565 9.33449 15.153H9.33467ZM9.71415
                                                                  12.042C10.3212 11.966 10.8525 12.2697 10.9282 12.8007C11.0042 13.332 10.5487 13.5595 9.9417 13.7872C9.56222 13.939 7.81708 13.7113 7.05831 13.4837C6.52708
                                                                   13.332 6.29953 13.1802 6.98234 13.0284C8.65168 12.7249 9.03116 12.1937 9.71399 12.042L9.71415 12.042Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1871_8815">
                              <rect width="24" height="23.9999" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {t("dragon")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="myth">
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.9302 2.21238L19.8956 2.42344L15.0892 7.22151C11.449 10.8553 10.2352 12.0443 10.0873 12.1215C9.7494 12.2976 9.47075 12.358 9.07559 12.3406C8.77537 12.3274 8.67742 12.3051 8.44854 12.198C8.09269 12.0314 7.86439 11.8397 7.67768 11.5508C7.33053 11.0137 7.27591 10.3683 7.52975 9.80261C7.65681 9.51938 7.7137 9.45984 11.0806 6.08513L14.5023 2.65548L14.2874 2.67807C14.0678 2.70116 9.04128 3.08761 8.96084 3.08761C8.93646 3.08761 9.10926 3.28101 9.3449 3.51735L9.77335 3.94713L7.32893 6.39883C5.88695 7.84519 4.8336 8.93234 4.76031 9.05002C4.49509 9.4757 4.32436 10.0653 4.32436 10.5552C4.32436 11.1112 4.55741 11.7722 4.92186 12.2497C5.16484 12.5681 11.6416 19.0249 11.875 19.1815C12.6154 19.678 13.5008 19.8151 14.31 19.5585C14.9365 19.3598 14.9225 19.3719 17.5988 16.7059L20.0702 14.244L20.4998 14.6723C20.7361 14.9078 20.9294 15.0806 20.9294 15.0562C20.9294 14.9758 21.3158 9.9492 21.3389 9.72966L21.3615 9.51477L17.9319 12.9382C14.9478 15.917 14.4738 16.3749 14.283 16.463C13.675 16.7438 13.0229 16.6992 12.465 16.3386C12.1773 16.1526 11.9855 15.924 11.8191 15.5685C11.5991 15.0988 11.6198 14.4782 11.8723 13.97C11.9238 13.8663 13.5954 12.1663 16.7717 8.98732L21.5929 4.16206L21.8081 3.0896C21.9265 2.49979 22.0234 2.01187 22.0234 2.00538C22.0234 1.98874 22.0671 1.98049 20.9302 2.21238ZM4.6856 15.0532L4.05117 15.6885L6.19041 17.8275L8.32969 19.9666L8.96416 19.3312L9.59859 18.6959L7.45935 16.5569L5.32007 14.4179L4.6856 15.0532ZM3.21076 19.0677L2 20.2789L2.85956 21.1385L3.71911 21.998L4.94937 20.7673L6.17963 19.5366L5.34023 18.6965C4.87857 18.2345 4.48302 17.8564 4.46122 17.8564C4.43942 17.8564 3.87672 18.4015 3.21076 19.0677Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("myth")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"fantasy"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M15.2244 4.84785C15.3737 4.76486 15.5479 4.68188 15.747 4.62649C16.3689 4.37754 16.5182 3.65807 16.0704 3.1326C15.8962
                                                2.91125 15.7222 2.71768 15.5231 2.52394C15.2743 2.27499 14.8763 2.49634 14.9012 2.88366C14.9012 2.96665 14.9012 3.04963 14.926
                                                3.13262C14.9508 3.43696 14.9758 3.74128 14.9508 4.01804C14.926 4.18401 14.8016 4.32238 14.6523 4.43296C15.0752 3.68592 14.5777
                                                3.07725 14.4533 2.41336C14.3786 1.99844 13.9059 1.86008 13.6571 2.16441C13.3586 2.52413 13.284 3.02202 13.4332 3.46473C13.4332
                                                    3.49232 13.458 3.49232 13.458 3.52012C13.5326 3.74147 13.6323 3.96282 13.7069 4.18416C13.6821 4.21176 13.6323 4.21176 13.6074
                                                    4.23955C13.4582 4.04579 13.2343 3.87982 13.1597 3.65845C12.9109 2.93917 12.3139 3.02217 11.8164 2.93917C11.4681 2.88379 11.1198
                                                    2.85619 10.7715 2.85619C10.9207 3.02216 11.0452 3.16053 11.1696 3.35409C11.269 3.49246 11.3686 3.65843 11.4681 3.79679C11.5675
                                                    3.96276 11.6423 4.10114 11.7169 4.29469L10.8711 3.74121C10.5974 3.57525 10.3238 3.40928 10.0501 3.24332C9.8759 3.13274 9.67703
                                                        3.04956 9.50281 2.93898C9.0799 2.99436 8.65699 3.10495 8.23406 3.24332C8.58235 3.43708 8.90582 3.68603 9.20432 3.9626C9.45317
                                                        4.21155 9.70183 4.46049 9.92569 4.76482C10.0251 4.90319 10.1247 5.06916 10.2242 5.20753C10.3236 5.3735 10.3984 5.51187 10.473
                                                        5.70543L9.62722 5.15195C9.35355 4.98598 9.0799 4.82001 8.80624 4.65405C8.25891 4.34971 7.71155 4.04539 7.13939 3.82401C7.06477
                                                        3.79641 6.99015 3.76862 6.91553 3.74103C6.61705 3.8794 6.34338 4.01777 6.06972 4.18373C6.11952 4.21133 6.14434 4.21133 6.19414
                                                            4.23912C6.84093 4.48807 7.43805 4.8754 7.96058 5.37349C8.20944 5.62245 8.45811 5.87139 8.68196 6.17572C8.7814 6.31409 8.88101
                                                            6.48006 8.98045 6.61843C9.07989 6.7844 9.15468 6.92277 9.2293 7.11633L8.38349 6.56285C8.10982 6.39688 7.83617 6.23091 7.56251
                                                            6.06495C7.01519 5.76061 6.46782 5.45629 5.89566 5.23491C5.622 5.12433 5.32351 5.01355 5.04985 4.95817C4.8508 5.12414 4.65176
                                                            5.31789 4.45273 5.48388C4.62696 5.53927 4.7762 5.59446 4.95025 5.64985C5.59704 5.8988 6.19416 6.28613 6.71669 6.78422C6.96554
                                                                7.03317 7.21421 7.28212 7.43807 7.58645C7.53751 7.72482 7.63712 7.89079 7.73656 8.02916C7.83599 8.19513 7.91079 8.3335 7.98541
                                                                8.52705L7.1396 7.97358C6.86593 7.80761 6.59227 7.64164 6.31861 7.47568C5.77129 7.17134 5.22393 6.86702 4.65177 6.64564C4.35329
                                                                6.53505 4.02982 6.42428 3.70635 6.3413C3.68154 6.39668 3.63173 6.42428 3.60692 6.47967C3.48249 6.61804 3.38305 6.78401 3.28345
                                                                6.92238C3.43269 6.97776 3.55712 7.00536 3.70635 7.06075C4.35314 7.3097 4.95027 7.69703 5.4728 8.19512C5.72165 8.44407 5.97032
                                                                    8.69302 6.19418 8.99735C6.29361 9.13572 6.39323 9.30169 6.49266 9.44006C6.5921 9.60602 6.6669 9.7444 6.74152 9.93795L5.8957
                                                                    9.38448C5.62203 9.21851 5.34838 9.05254 5.07472 8.88658C4.5274 8.58224 3.98003 8.27792 3.40788 8.05654C3.15902 7.97355 2.93515
                                                                    7.89057 2.6865 7.80758C2.56207 8.00134 2.41283 8.1949 2.28841 8.41624C2.33821 8.44384 2.41283 8.47163 2.46264 8.47163C3.10943
                                                                    8.72059 3.70655 9.10791 4.22908 9.60601C4.47793 9.85496 4.7266 10.1039 4.95046 10.4082C5.0499 10.5466 5.14951 10.7126 5.24895
                                                                        10.8509C5.34838 10.9893 5.42318 11.1553 5.4978 11.3488L4.65198 10.7954L3.831 10.2975C3.28368 9.99312 2.73632 9.6888 2.16416
                                                                        9.46742C2.03973 9.41203 1.89049 9.35684 1.74125 9.32905C1.61683 9.55041 1.4924 9.74397 1.36815 9.96533C1.94031 10.2143 2.48763
                                                                        10.574 2.96036 11.0167C3.20921 11.2657 3.45788 11.5146 3.68174 11.8189C3.78118 11.9573 3.88079 12.1233 3.98022 12.2616C4.07966
                                                                        12.4276 4.15446 12.566 4.22908 12.7595L3.38326 12.2061C3.10959 12.0401 2.83594 11.8741 2.56228 11.7082C2.01496 11.4038 1.46759
                                                                            11.1271 0.895438 10.9059C0.771011 11.1273 0.671574 11.3762 0.547152 11.5976C0.945244 11.8189 1.34334 12.0955 1.69164 12.4276C1.94049
                                                                            12.6766 2.18916 12.9255 2.41302 13.2299C2.51246 13.3682 2.61207 13.5342 2.7115 13.6726C2.81094 13.8385 2.88574 13.9769 2.96036
                                                                            14.1705L2.11454 13.617C1.84087 13.451 1.56722 13.2851 1.29356 13.1191C0.870653 12.8701 0.447746 12.6488 0.0248158 12.4828L0
                                                                            12.5104L8.53323 22V21.9724C8.8069 21.3361 9.18002 20.6996 9.35422 20.008C9.60307 19.0396 9.72732 18.0436 9.85173 17.0476C10.001
                                                                                16.0516 10.0756 15.0002 10.2746 14.0042C10.4737 12.9806 10.2995 12.0398 9.95117 11.0991C9.80193 10.6842 9.75212 10.2137 9.65269
                                                                                9.77121C9.70249 9.77121 9.75212 9.74361 9.80193 9.74361C9.95117 10.2415 10.1254 10.712 10.2747 11.2099C10.4489 11.7632 10.7224
                                                                                12.2335 11.1205 12.5933C11.2449 12.7038 11.4439 12.8422 11.5932 12.8146C12.2898 12.6762 12.5386 13.3679 12.9365 13.7552C13.1854
                                                                                14.0042 13.3843 14.3639 13.6331 14.6128C13.9566 14.9448 13.9814 15.3321 13.882 15.7472C13.8572 15.8856 13.8074 16.0239 13.7576
                                                                                    16.1621C13.6083 16.7432 13.7078 16.9922 14.1805 17.2965C14.3795 17.4349 14.5786 17.6008 14.7526 17.7668C14.9517 17.9606 15.0761
                                                                                    17.9328 15.1755 17.6838C15.275 17.4901 15.3994 17.2965 15.5238 17.1027C15.5736 17.1027 15.5984 17.1303 15.6482 17.1303C15.474 
                                                                                    16.5492 15.3 15.9683 15.1257 15.3873C15.1009 15.2767 15.1009 15.1383 15.1009 15V14.9446C15.2003 15 15.2751 15.0276 15.3746
                                                                                    15.0829C15.3994 15.1105 15.4244 15.1105 15.4492 15.1383C15.8721 15.4151 15.9467 15.9684 16.1209 16.4111C16.2952 16.8538
                                                                                    16.4692 17.2411 17.0166 17.1581C17.0912 17.1305 17.2156 17.2687 17.315 17.3519C17.7131 17.6562 18.0364 17.5179 18.0364
                                                                                        16.9646C18.0364 16.4667 18.2853 16.1623 18.6086 15.8856C19.0067 15.5536 19.1061 15.0555 18.7578 14.696C18.3349 14.2533
                                                                                        18.1857 13.7554 18.0364 13.1743C17.6882 11.9294 17.2402 10.7396 16.8423 9.49468C16.7677 9.21794 16.7179 8.91359 16.7429
                                                                                        8.63707C16.7927 8.19436 16.6185 7.86241 16.4444 7.5027C16.4196 7.44731 16.3698 7.36433 16.345 7.30894L24 2.93917L15.4991
                                                                                        5.20639C15.2992 5.06918 15.2493 4.95862 15.2244 4.84785ZM17.2147 15.1121C17.2147 14.9183 17.2645 14.7802 17.389
                                                                                            14.6694C17.4884 14.5588 17.6378 14.4756 17.8119 14.4756H17.8367C17.9611 14.4756 18.0856 14.5032 18.2098 14.6416C18.2346
                                                                                            14.6416 18.2346 14.6692 18.2346 14.697C18.2844 14.7524 18.3092 14.8076 18.3341 14.863C18.3092 14.863 18.2842 14.8354
                                                                                            18.2594 14.8354C18.2594 14.8907 18.2842 14.9459 18.2842 15.0013C18.2096 14.9183 18.135 14.8354 18.0604 14.78C17.7369
                                                                                            14.78 17.4633 15.0843 17.4633 15.444C17.4633 15.5546 17.4881 15.6378 17.5131 15.7484C17.3638 15.6654 17.2642 15.527
                                                                                                17.2146 15.361V15.278C17.2396 15.2227 17.2147 15.1673 17.2147 15.1121ZM15.3489 8.25082C15.3737 8.25082 15.3737 8.25082
                                                                                                15.3489 8.25082H15.3737H15.3985H15.4233H15.4481C15.5974 8.25082 15.7466 8.30621 15.871 8.38919C16 9.21433 15 9.21433
                                                                                                14.7266 8.69353C14.7514 8.63814 14.8012 8.55516 14.851 8.52756L14.8758 8.49997C15.0502 8.36159 15.1995 8.27861 15.3489
                                                                                                8.25082Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("fantasy")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"fruits"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1" clipPath="url(#clip0_1871_8860)">
                            <path
                              d="M12.0016 20.102C11.9342 20.2369 11.8669 20.3379 11.7995 20.4389C11.5974 20.7084 11.3953 20.9441 11.1257 21.18L12.0016 20.102ZM12.0016
                                                20.102C13.7536 17.1716 13.7872 14.3084 12.069 12.1527L12.0016 20.102ZM10.8536 16.8407L10.8525 16.8404C10.6825 17.7916 10.1389 18.6068 9.35803
                                                19.1499M10.8536 16.8407L9.3404 19.1255M10.8536 16.8407L10.8532 16.8363M10.8536 16.8407L10.8532 16.8363M9.35803 19.1499C9.35788 19.15 9.35772
                                                19.1501 9.35757 19.1502L9.3404 19.1255M9.35803 19.1499C9.35817 19.1498 9.35831 19.1497 9.35845 19.1496L9.3404 19.1255M9.35803 19.1499C9.21626
                                                    19.2561 9.04056 19.2902 8.86873 19.2902C8.58938 19.2902 8.31075 19.1508 8.13663 18.9071M9.3404 19.1255C9.20574 19.2265 9.03723 19.2601 8.86873
                                                    19.2601C8.59918 19.2601 8.32962 19.1255 8.1611 18.8896M8.13663 18.9071C8.13668 18.9072 8.13673 18.9072 8.13678 18.9073L8.1611 18.8896M8.13663
                                                    18.9071C7.85664 18.522 7.96258 17.964 8.38037 17.6855C8.77705 17.4211 9.04266 16.9905 9.1424 16.4921C9.24177 15.9953 9.14273 15.5321 8.84419
                                                    15.1007C8.56439 14.7157 8.67014 14.158 9.08748 13.8795C9.47267 13.5999 10.0306 13.7058 10.309 14.1234L10.3095 14.1242L10.3095 14.1242C10.8177
                                                        14.9376 11.0218 15.8867 10.8532 16.8363M8.13663 18.9071L8.1611 18.8896M8.1611 18.8896L10.8532 16.8363M16.9275 10.532L16.9351 10.5303C18.6255
                                                        10.2481 20.1383 10.6007 21.3334 11.478C22.5309 12.3571 23.4119 13.765 23.8316 15.5951C24.2005 17.3386 23.8649 19.1155 22.8928 20.5567C21.9206
                                                        21.9644 20.4116 22.9373 18.6665 23.34C18.1286 23.4407 17.5917 23.5078 17.0891 23.5078C15.118 23.5078 13.2808 22.6762 12.0737 21.2128C12.271
                                                        20.9498 12.4684 20.6856 12.6337 20.4211L12.6339 20.4209C14.4851 17.3923 14.5277 14.258 12.8527 11.9243C13.2805 11.6308 13.7094 11.4012 14.2029
                                                            11.2038L14.2382 11.1897L14.2163 11.1586C12.5362 8.77353 12.0653 6.11876 11.9644 4.1345L11.9592 4.03045L11.9082 4.12131C10.3547 6.89021 10.2536
                                                            9.65871 10.2872 11.0082L10.288 11.0376H10.3173C10.4512 11.0376 10.5571 11.046 10.6497 11.0742C10.7414 11.1021 10.8214 11.1499 10.9023 11.2308L10.9018
                                                            11.2314L10.9078 11.2352C11.3422 11.5025 11.7105 11.8368 12.0466 12.1728C13.7537 14.3158 13.723 17.1642 11.9758 20.0866L11.9757 20.0865L11.9747
                                                            20.0886C11.9083 20.2213 11.8422 20.3208 11.7749 20.4216C11.5738 20.6896 11.3733 20.9234 11.106 21.1573C10.3354 21.8273 9.36277 22.2968 8.32274
                                                                22.5317L8.3226 22.5318C7.88772 22.6321 7.45208 22.6656 6.98184 22.6656C5.74148 22.6656 4.50003 22.2969 3.35868 21.6258C1.81541 20.6865 0.742109
                                                                19.278 0.27258 17.635C-0.196893 15.9249 0.0380498 14.1484 0.976792 12.6401L0.977042 12.6397C1.88374 11.1289 3.08313 10.1067 4.41065 9.62102C5.73792
                                                                9.13543 7.19538 9.18535 8.62055 9.82235L8.66092 9.84039L8.66287 9.79622C8.76376 7.51053 9.43625 3.94751 12.2606 0.788342L12.261 0.787862C12.4566
                                                                0.559613 12.8149 0.4308 13.1045 0.527335L13.1052 0.527542C13.5271 0.657267 13.7548 1.04519 13.6911 1.46785C13.6902 1.47061 13.6895 1.47326 13.689
                                                                    1.47504C13.6879 1.47932 13.6866 1.48479 13.6852 1.49131C13.6824 1.50438 13.6788 1.52251 13.6747 1.5454C13.6664 1.59123 13.6558 1.65671 13.6442
                                                                    1.74008C13.6209 1.90686 13.5935 2.14555 13.5724 2.44229C13.5302 3.03573 13.5133 3.86166 13.6061 4.80889C13.7918 6.70305 14.4165 9.08463 16.1572
                                                                    11.0615L16.1809 11.0884L16.2032 11.0604C16.3376 10.8925 16.4451 10.7771 16.5556 10.6952C16.6655 10.6139 16.7793 10.565 16.9275 10.532ZM21.0941
                                                                    17.1119L21.0973 17.1127L21.0956 17.1047C21.4994 15.171 20.277 13.2724 18.3762 12.831L18.3757 12.8309C17.8873 12.7263 17.4339 13.0402 17.3293
                                                                        13.4936L17.3292 13.4941C17.2246 13.9823 17.5385 14.4356 17.992 14.5402L17.9929 14.5404C18.4895 14.6396 18.8871 14.9375 19.1527 15.3356C19.4185
                                                                        15.7677 19.5174 16.2648 19.4182 16.7278C19.3142 17.2133 19.6241 17.6643 20.0734 17.7722C20.0965 17.7914 20.1265 17.7997 20.1552 17.8038C20.1868
                                                                        17.8083 20.2223 17.8083 20.2551 17.8083H20.2563C20.642 17.8083 20.9901 17.5282 21.0941 17.1119Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="0.060156"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1871_8860">
                              <rect width="24" height="23.9999" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        {t("fruits")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"animals"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1" clipPath="url(#clip0_1871_8866)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.76 3.22151C19.4375 3.22151 19.1763 3.48266 19.1763 3.80517C19.1763 4.12769 19.4375 4.38778 19.76 4.38778C20.0811
                                                4.38778 20.3426 4.12771 20.3426 3.80517C20.3426 3.48264 20.0811 3.22151 19.76 3.22151ZM18.4206 1.57437C19.0808 1.56862 19.7586 1.72231 20.3216 2.04483C20.7887
                                                2.3121 21.1785 2.70157 21.3981 3.21073C21.5219 3.49743 21.5968 3.64393 21.7893 3.76486C22.1882 4.01394 22.6254 4.14875 23.0359 4.32585C23.2391 4.41278 23.4096
                                                4.50043 23.5287 4.60086C23.9304 4.93831 24.0665 5.45051 23.9992 5.90009C23.9319 6.34931 23.6529 6.75697 23.208 6.8511L20.4695 7.43619C19.739 7.59295 19.2092
                                                    8.38882 19.2882 8.97137C19.5314 10.7589 19.1074 12.034 18.6862 13.1698C18.2669 14.3048 17.8711 15.2902 18.1518 16.5622L19.0208 20.1684C19.4866 20.3131 19.8737
                                                    20.5399 20.1198 20.8851C20.3858 21.2566 20.5143 21.7317 20.5143 22.2826C20.5143 22.3571 20.4533 22.417 20.3781 22.417H18.1473C17.631 22.417 17.2858 22.1003
                                                    17.1861 21.7929L15.3893 16.2507L14.6026 17.0404C14.2219 16.0831 13.223 15.398 11.7115 15.398C11.4223 15.398 11.4223 15.7432 11.7115 15.7432C13.3184 15.7432
                                                    14.153 16.6033 14.3173 17.6183C14.4802 18.6334 13.9079 19.8727 12.6433 20.5926C12.376 20.7451 12.5479 21.0361 12.8134 20.8808C13.2137 20.6824 13.5511 20.4326
                                                        13.8303 20.1537C14.7084 20.4506 15.3879 21.3061 15.3879 22.2828C15.3879 22.3573 15.3263 22.4172 15.2516 22.4172L7.62099 22.4197C7.04758 22.4197 6.5384 22.2363
                                                        6.12477 21.9511C5.0483 22.4869 4.05818 22.5333 3.21319 22.2331C2.35627 21.9284 1.65131 21.2868 1.12411 20.4949C0.0713673 18.9151 -0.297562 16.7474 0.305527
                                                        15.3784C0.652174 14.5928 1.36435 14.3315 1.94085 14.4553C2.22901 14.5183 2.48583 14.6734 2.64982 14.9063C2.81414 15.1405 2.87696 15.4531 2.78139 15.7918C2.28105
                                                        17.5624 2.4351 19.1406 3.18167 20.0201C3.46065 20.3484 3.85051 20.4814 4.11056 20.4384C4.24015 20.4175 4.33447 20.3578 4.3926 20.262C4.45074 20.1666 4.47773
                                                            20.0232 4.42842 19.8079C4.05027 18.1833 4.14314 16.1438 5.69587 14.6938L12.5893 8.24896C13.0805 7.79056 13.2315 7.78587 13.772 8.0985C14.3006 8.4032 15.0412
                                                            8.66129 15.583 8.47321C15.8756 8.36738 16.1177 8.1165 16.2 7.76105C16.2 7.76105 16.6836 5.67206 16.9239 4.62858C16.9763 4.40721 16.6656 4.29509 16.5985
                                                            4.55065L15.777 7.66404C15.65 8.14657 15.1498 8.16726 14.7619 8.05081C14.2886 7.90755 13.4763 7.50728 13.3271 6.99669C13.1883 6.5219 13.5974 5.75449 14.0499
                                                            5.15843L15.1251 3.73268C15.5136 3.21758 15.794 2.53205 16.3123 2.14673C16.8226 1.76752 17.7128 1.5809 18.4203 1.57477L18.4206 1.57437Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1871_8866">
                              <rect
                                width="24"
                                height="23.9999"
                                fill="white"
                                transform="matrix(-1 0 0 1 24 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        {t("animals")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"asia"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M11.9988 13.3816C12.4318 12.0207 13.1123 10.5671 14.2567 9.4228C14.7517 8.9279 15.3083 8.52599 15.8652 8.15468C15.03 4.87633 12.5246 2.49479 12.401 2.37107L11.9988 2L11.5658 2.40214C11.4421 2.52575 8.90571 4.90728 8.10156 8.18574C8.68931 8.52597 9.21508 8.92808 9.71003 9.45386C10.8852 10.5982 11.5658 12.0518 11.9988 13.3816V13.3816Z"
                              fill="white"
                            />
                            <path
                              d="M3.49525 14.9277C1.76317 15.639 0.556863 16.7216 0.494945 16.7835L0 17.2474L0.494945 17.7112C0.618575 17.804 3.18589 20.1855 6.46463 20.1855C7.26878 20.1855 8.04205 20.0309 8.75358 19.8145C8.8155 19.4434 9.06298 18.763 9.92901 18.0825C8.16583 17.8351 5.66048 17.1546 3.86654 15.3299C3.71205 15.1752 3.58821 15.0516 3.49543 14.9278L3.49525 14.9277Z"
                              fill="white"
                            />
                            <path
                              d="M23.5042 16.7835C23.4423 16.7216 22.2671 15.6393 20.5039 14.9277C20.3803 15.0514 20.2564 15.1752 20.1328 15.3299C18.3079 17.1546 15.8026 17.835 14.0703 18.0825C14.9364 18.7938 15.1838 19.4742 15.2457 19.8144C15.9571 20.031 16.7304 20.1855 17.5347 20.1855C20.8133 20.1855 23.3807 17.804 23.5044 17.7112L23.9993 17.2473L23.5042 16.7835Z"
                              fill="white"
                            />
                            <path
                              d="M12.625 16.9389C13.9549 16.877 17.2027 16.5059 19.2752 14.4338C21.3475 12.3616 21.7186 9.14492 21.7805 7.78418C20.4506 7.8461 17.2028 8.24802 15.1304 10.2893C13.0891 12.3923 12.6869 15.6088 12.625 16.9389Z"
                              fill="white"
                            />
                            <path
                              d="M11.3821 16.9389C11.3202 15.6091 10.9491 12.3616 8.87673 10.2893C6.80435 8.21712 3.5566 7.84609 2.22656 7.78418C2.28849 9.11399 2.65959 12.3615 4.73192 14.4338C6.8043 16.506 10.021 16.877 11.3821 16.9389Z"
                              fill="white"
                            />
                            <path
                              d="M11.9946 18.2686C11.0977 18.6707 9.95312 19.4439 9.95312 20.0316C9.95312 20.5882 11.0666 21.3614 11.9946 21.7946C12.8915 21.3925 14.0361 20.6193 14.0361 20.0316C14.0361 19.4748 12.9226 18.7016 11.9946 18.2686Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("asia")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"books"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M11.4669 5.15475C11.4669 5.08146 11.4157 4.99014 11.3527 4.95201C11.3527 4.95201 9.47302 3.80963 7.20018 3.1324C4.92735 2.45518 2.80033
                                                2.40237 2.80033 2.40237C2.72704 2.40088 2.66696 2.45947 2.66696 2.53276V16.1316C2.66696 16.2049 2.72704 16.27 2.79977 16.2761C2.79977 16.2761
                                                5.0226 16.4649 8.80018 17.7654C9.80836 18.1126 11.3461 18.8116 11.3461 18.8116C11.4127 18.8419 11.4674 18.8068 11.4674 18.7335L11.4669
                                                5.15475ZM12.5329 18.7336C12.5329 18.8069 12.5876 18.842 12.6542 18.8117C12.6542 18.8117 14.1917 18.1125 15.2001 17.7655C18.9776 16.465 21.2005
                                                    16.2762 21.2005 16.2762C21.2733 16.2701 21.3333 16.205 21.3333 16.1317V2.53285C21.3333 2.45956 21.2733 2.40079 21.2 2.40265C21.2 2.40265 19.0728
                                                    2.45547 16.8001 3.13268C14.5273 3.80991 12.6476 4.95229 12.6476 4.95229C12.5846 4.9906 12.5334 5.08174 12.5334 5.15484L12.5329 18.7336ZM1.60017
                                                    16.1317C1.60017 16.205 1.60017 16.3249 1.60035 16.3984L1.60147 17.1085C1.60166 17.1818 1.66174 17.2471 1.73447 17.2536L2.44184 17.3159C2.51495
                                                    17.3222 2.63436 17.333 2.70746 17.3399C2.70746 17.3399 4.87001 17.5408 8.45216 18.7739C9.28715 19.0611 10.7726 19.721 10.7726 19.721C10.8396
                                                        19.7506 10.845 19.809 10.7843 19.8501L9.19154 20.9305C9.13202 20.9737 9.08347 21.0092 9.08328 21.0088C9.08328 21.0088 9.0273 20.9867 8.95903
                                                        20.9596C8.95903 20.9596 8.26223 20.6824 7.84466 20.5388C4.54093 19.4016 2.53567 19.1997 2.53567 19.1997C2.46294 19.1924 2.34315 19.1812
                                                        2.27042 19.1746L0.132251 18.9858C0.0600802 18.9794 0 18.9143 0 18.8407V6.26605C0 6.19276 0.0373883 6.08581 0.0829612 6.02816C0.0829612
                                                        6.02816 0.307849 5.74395 0.718907 5.33274C1.13017 4.9215 1.49737 4.61794 1.49737 4.61794C1.55355 4.57126 1.60005 4.59283 1.60005
                                                            4.66612L1.60017 16.1317ZM13.6838 21.5279C13.7562 21.5154 13.7655 21.4713 13.7043 21.4306L12.1126 20.4385C12.0505 20.3996 11.9489 20.3996
                                                            11.8864 20.4385L10.2949 21.4306C10.2339 21.4713 10.2434 21.5154 10.3154 21.5279C10.3154 21.5279 10.7655 21.6062 11.9991 21.6062C13.2329
                                                            21.6062 13.6838 21.5279 13.6838 21.5279H13.6838ZM22.3999 4.66598C22.3999 4.59269 22.4463 4.57112 22.5028 4.61781C22.5028 4.61781
                                                            22.87 4.92154 23.2813 5.3326C23.6925 5.74384 23.9172 6.02802 23.9172 6.02802C23.9626 6.08549 24 6.19244 24 6.26591V18.841C24 18.9143
                                                                23.9399 18.9796 23.8672 18.9861L21.729 19.1749C21.6563 19.1812 21.5365 19.1926 21.4638 19.2C21.4638 19.2 19.4582 19.4022 16.1548
                                                                20.5392C15.7374 20.6829 15.0404 20.9599 15.0404 20.9599C14.9721 20.987 14.9162 21.0092 14.9162 21.0092C14.916 21.0094 14.8671 20.974
                                                                14.8079 20.9309L13.2151 19.8504C13.1543 19.8093 13.1599 19.7509 13.2268 19.7214C13.2268 19.7214 14.7125 19.0615 15.5473
                                                                18.7743C19.1296 17.5411 21.292 17.3402 21.292 17.3402C21.3651 17.3335 21.4845 17.3229 21.5576 17.3162L22.265 17.2539C22.3377
                                                                    17.2476 22.3978 17.1821 22.398 17.1089L22.3991 16.3987C22.3998 16.3251 22.3998 16.2051 22.3998 16.1318L22.3999 4.66598Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("books")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"winter"}>
                      <div className="item-wrapper">
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M21.7903 12.7654L18.2465 14.5587L16.3057 13.4406L19.0903 11.9848L16.3269 10.5291L18.2466 9.41091L21.8117 11.2253L22.3813 10.1071L19.5546 8.67259L21.5376 7.53341L20.9047 6.43631L18.9217 7.57549L19.0905 4.4109L17.8247 4.34762L17.6137 8.335L15.673 9.45316L15.7995 6.30963L13.1416 7.99745V5.7822L16.4746 3.60918L15.7785 2.55431L13.1205 4.28436V2.00586H11.8548V4.28436L9.19686 2.55431L8.50072 3.60918L11.8337 5.7822L11.8334 7.99745L9.1755 6.33084L9.30205 9.47437L7.38238 8.35621L7.17137 4.36883L5.9056 4.43211L6.07437 7.5967L4.09141 6.45752L3.45853 7.55462L5.44149 8.6938L2.61481 10.1283L3.18441 11.2465L6.72828 9.45315L8.64795 10.5713L5.88456 12.0271L8.66916 13.4828L6.72843 14.601L3.16335 12.7866L2.59375 13.9047L5.42043 15.3393L3.43747 16.4785L4.07036 17.5756L6.05332 16.4364L5.88454 19.601L7.15031 19.6643L7.36132 15.6769L9.28099 14.5587L9.15445 17.6811L11.8124 15.9933V18.2295L8.47937 20.4025L9.1755 21.4574L11.8334 19.7274V22.0059H13.0992V19.7274L15.7571 21.4574L16.4532 20.4025L13.1203 18.2295V16.0143L15.7782 17.6809L15.6516 14.5585L17.5713 15.6767L17.7823 19.6641L19.0481 19.6008L18.8793 16.4362L20.8623 17.5754L21.4951 16.4783L19.5122 15.3391L22.3389 13.9046L21.7903 12.7654ZM16.39 12.0059L15.0189 12.7231L13.7954 12.0059L15.04 11.2886L16.39 12.0059ZM14.4493 8.65145L14.386 10.1915L13.1415 10.9088L13.1413 9.47424L14.4493 8.65145ZM10.5889 8.65145L11.8968 9.47424V10.9088L10.6522 10.1915L10.5889 8.65145ZM8.66924 12.0059L10.0193 11.2886L11.2639 12.0059L10.0404 12.7231L8.66924 12.0059ZM10.5889 15.3392L10.6522 13.8202L11.8968 13.103V14.5165L10.5889 15.3392ZM14.4493 15.3392L13.1625 14.5165V13.0819L14.4071 13.7992L14.4493 15.3392Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("winter")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"recommend"}>
                      <div className="item-wrapper">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M2.99183 22.3581H6.2844C6.72019 22.3581 7.11378 22.0655 7.23719 21.6458C7.26328 21.558 7.27621 21.4604 7.27621 21.3693L7.27644
                                                12.1308C7.27644 11.8967 7.19522 11.6724 7.04867 11.5C6.85357 11.2691 6.57703 11.1391 6.28439 11.1391L2.99182 11.1389C2.44555 11.1389
                                                2 11.5844 2 12.1306L2 21.3696C1.99977 21.9161 2.44533 22.3581 2.99183 22.3581ZM5.2014 19.4345C5.7023 19.4345 6.10541 19.8378 6.10541
                                                20.3385C6.10541 20.8393 5.70205 21.2424 5.2014 21.2424C4.7039 21.2424 4.29739 20.8391 4.29739 20.3385C4.29739 19.8376 4.70392 19.4345 5.2014 19.4345Z"
                              fill="white"
                            />
                            <path
                              d="M7.92893 21.3693C7.92893 21.4571 7.91918 21.5481 7.90625 21.6361C8.29327 22.0165 8.81368 22.2346 9.35994 22.2346H19.0092C19.4321
                                                22.2346 19.8353 22.0655 20.1476 21.7629C20.4469 21.4572 20.6159 21.054 20.6159 20.628C20.6159 20.2637 20.4956 19.9223 20.2711 19.6363C20.2613
                                                19.6265 20.2581 19.6136 20.2515 19.6004C20.2386 19.5809 20.2289 19.5614 20.2223 19.5419C20.2125 19.5192 20.2094 19.4963 20.2059 19.4768C20.2059
                                                19.4605 20.1994 19.4475 20.1994 19.4346C20.1994 19.428 20.2025 19.4217 20.2025 19.4151C20.2025 19.3924 20.2091 19.3729 20.2155 19.35C20.2221
                                                    19.3305 20.2252 19.311 20.235 19.2946C20.2416 19.2751 20.2545 19.2622 20.2674 19.2459C20.2804 19.2264 20.2935 19.21 20.3096 19.1939C20.3162
                                                    19.1908 20.3162 19.1842 20.3226 19.181C20.3323 19.1712 20.3452 19.1681 20.3584 19.1615C20.3779 19.1486 20.3974 19.1388 20.4169 19.1322C20.4396
                                                    19.1225 20.4625 19.1193 20.4852 19.1159C20.4981 19.1159 20.5113 19.1093 20.5242 19.1093H20.5698C20.9959 19.1093 21.3992 18.9403 21.7082 18.6377C22.0106
                                                    18.3319 22.1765 17.9288 22.1765 17.5028C22.1765 17.1224 22.0465 16.7678 21.8024 16.4752L21.8028 16.4718C21.7801 16.4457 21.7606 16.4133 21.7475
                                                        16.3808V16.3711C21.7377 16.3484 21.7377 16.3255 21.7346 16.2996C21.7346 16.2833 21.728 16.2638 21.7314 16.2443C21.7314 16.2248 21.738 16.2084 21.7443
                                                        16.1889C21.7509 16.1662 21.7509 16.1433 21.7606 16.1238C21.7704 16.1109 21.7833 16.0977 21.7931 16.0816C21.806 16.0621 21.8192 16.0426 21.8387
                                                        16.0231C21.8419 16.0231 21.8419 16.0199 21.8453 16.0165C21.8616 16.0036 21.8777 16.0002 21.894 15.9904C21.9104 15.9807 21.9233 15.9678 21.9396
                                                        15.9612C22.1608 15.88 22.3625 15.7529 22.5315 15.5871C22.8307 15.2813 22.9997 14.8782 22.9997 14.4522C22.9997 13.8604 22.6549 13.301 22.1184
                                                            13.0279C22.1118 13.0247 22.1054 13.0181 22.102 13.0149C22.0891 13.0083 22.0759 13.002 22.0662 12.9922C22.0532 12.9825 22.0401 12.9727 22.0272
                                                            12.9598C22.0174 12.9532 22.0108 12.9435 22.0045 12.9337C21.9915 12.9208 21.9784 12.9013 21.9686 12.8849C21.9686 12.8818 21.9655 12.8784 21.9621
                                                            12.8752C21.9589 12.8686 21.9621 12.8654 21.9589 12.8623C21.9425 12.8298 21.9328 12.794 21.9296 12.755V12.7484V12.732C21.9296 12.706 21.9362 12.6801
                                                            21.9425 12.654C21.9457 12.6443 21.9457 12.6313 21.9491 12.6216C21.9621 12.5891 21.9784 12.563 21.9979 12.537C22.0045 12.5304 22.0077 12.5206 22.0142
                                                                12.5143C22.0369 12.4882 22.0662 12.4687 22.0988 12.4492C22.1054 12.446 22.1086 12.4426 22.1152 12.4394C22.2647 12.368 22.4047 12.267 22.5283
                                                                12.1402C22.8307 11.8344 22.9999 11.4313 22.9999 11.0053C22.9999 10.1209 22.2778 9.40199 21.3933 9.40199L15.4284 9.40176C15.2496 9.40176
                                                                15.103 9.25545 15.103 9.07646V4.19519C15.103 3.60994 14.8753 3.06028 14.459 2.64722C14.0427 2.23095 13.493 2 12.9077 2C12.3775 2 11.9452
                                                                2.43259 11.9452 2.96249V5.9024C11.9452 6.09749 11.8801 6.2894 11.763 6.4423L8.40006 11.0212C8.07474 11.4765 8 12 8 12C7.92843 13.0667
                                                                    7.92843 12.7545 7.92843 13.0667L7.92893 21.3693Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                        {t("recommend")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <div className="mt-4 ntext-left d-flex align-items-center">
                  <img
                    className="img-fluid home-page-icons"
                    src="/assets/images/icons/in-house-game.png"
                  />

                  {!this.props.blackjack &&
                    !this.props.table  &&
                    !this.props.release  &&
                    !this.props.gameshow  &&
                    !this.props.card && 
                      <>
                      <p className="m-0 home-part-t"> Slots</p>
                      </>
                    }

                  {this.props.card && (
                    <p className="m-0 home-part-t"> Card Games</p>
                  )}
                  {this.props.blackjack && (
                    <p className="m-0 home-part-t"> Blackjack Games</p>
                  )}
                  {this.props.table && (
                    <p className="m-0 home-part-t"> Table Games</p>
                  )}
                  {this.props.gameshow && (
                    <p className="m-0 home-part-t"> Game Shows</p>
                  )}
                  {this.props.release && (
                    <p className="m-0 home-part-t"> New Release</p>
                  )}
                </div>

                <div id="st" className="slots-home">
                  <Card className="brd7 cardslots">
                    <Card.Body className="py-2 cardslots cardslotstop">
                      <div className="s-flex align-items-center">
                        {!this.props.roulette ||
                          !this.props.blackjack ||
                          !this.props.table ||
                          (!this.props.card && (
                            <>
                              {" "}
                              {this.sortObj.tab == "all" &&
                              this.sortObj.sub_filter == "lobby" ? (
                                <Card className="brd7 cardslots">
                                  <Slots
                                    t={t}
                                    home={true}
                                    specific="top_game"
                                    items={14}
                                  />
                                </Card>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}

                        {!this.props.card ||
                        !this.props.blackjack ||
                        !this.props.gameshow ||
                        !this.props.release ||
                        !this.props.table ? (
                          <Slots
                            t={t}
                            auto_scroll_load={true}
                            b_sort={this.state.b_sort}
                            completedBSort={this.completedBSort}
                            sortObj={this.sortObj}
                          />
                        ) : (
                          <>
                            <Slots
                              t={t}
                              data={this.props}
                              onPage={true}
                              auto_scroll_load={true}
                              b_sort={this.state.b_sort}
                              completedBSort={this.completedBSort}
                              sortObj={this.sortObj}
                            />
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : (
              <Slots t={t} live={live} auto_scroll_load={true} />
            )}
          </>
        ) : (
          <>
            <div className="text-center">
              <div className="spinner-grow text-white my-5" role="status" />
            </div>
          </>
        )}
      </>
    );
  }
}

let debounce_get_sorted_slots_fun = function (sortObj, self, t) {
  Event.emit("get_sorted_slots", sortObj);
  // socket.emit(C.GET_SORTED_SLOTS, encode(sortObj));
  self.setState({ b_sort: true });
};

export default Index;
