import React from "react";
import { Row, Col } from "react-bootstrap";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/icons-material/FeaturedPlayList";

import useStyles from "../../../../Style";
import storage from "../../../../Storage";
import { Event, wait } from "../../../../Helper";
import Statistics from "./Statistics";
import General from "./General";
import Security from "./Security";
import Sessions from "./Sessions";

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey("logged"),
      tab: 0,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.state.isLogged !== "true") this.props.history.push("../");

    if (this.props.history.location.pathname === "/setting") {
      this.setState({ tab: 1 });
    } else if (this.props.history.location.pathname === "/statistics") {
      this.setState({ tab: 0 });
    }

    Event.on("confirm_two_fa", () => {
      this.setState({ tab: 3 });
      wait(500).then(() => {
        this.setState({ tab: 2 });
      });
    });
  }

  change = (tab) => {
    this.setState({ tab });

    if (tab === 0 || tab === 1) {
      let target;

      if (tab === 0) target = "statistics";
      else if (tab === 1) target = "setting";

      this.props.history.push("./" + target);
    }
  };

  render() {
    const classes = useStyles({
      backgroundColor: "#121725",
      position: "relative",
      borderRadius: 10,
    });
    const { t } = this.props;
    return (
      <>
        <div>
          <div className={"container mt-2 p-3 " + classes}>
            <Row>
              <Col sm={5} md={3}>
                <div className="setting-tab">
                  <List component="nav" aria-label="main setting">
                    <ListItem
                      className={this.state.tab === 0 ? "active" : ""}
                      button
                      onClick={(e) => this.change(0)}
                    >
                      <i className="mdi mdi-google-circles-communities font-16" />
                      <span className="ml-2">{t("Statistics")}</span>
                    </ListItem>
                    <ListItem
                      className={this.state.tab === 1 ? "active" : ""}
                      button
                      onClick={(e) => this.change(1)}
                    >
                      <i className="mdi mdi-gradient font-16" />
                      <span className="ml-2">{t("setting")}</span>
                    </ListItem>
                    <ListItem
                      className={this.state.tab === 2 ? "active" : ""}
                      button
                      onClick={(e) => this.change(2)}
                    >
                      <i className="mdi mdi-security font-16" />
                      <span className="ml-2">{t("security")}</span>
                    </ListItem>
                    <ListItem
                      className={this.state.tab === 3 ? "active" : ""}
                      button
                      onClick={(e) => this.change(3)}
                    >
                      <i className="mdi mdi-developer-board font-16" />
                      <span className="ml-2">{t("sessions")}</span>
                    </ListItem>
                  </List>
                </div>
              </Col>
              <Col sm={7} md={9}>
                <div className="mt-4">
                  {this.state.tab === 0 && <Statistics t={t} />}
                  {this.state.tab === 1 && <General t={t} />}
                  {this.state.tab === 2 && <Security t={t} />}
                  {this.state.tab === 3 && <Sessions t={t} />}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default Setting;
