import MyEmitter from "events";
import socket from "../../../Socket";
import { encode, decode, forceSatoshiFormat } from "../../../Helper";
import C from "../../../Constant";
/**
 * Keno Engine
 */
function Engine() {
  let self = this;

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Game Name
   */
  self.game = "singlekeno";

  /**
   * Event Trigger
   */
  self.trigger = new MyEmitter();

  /**
   * Initialize User Play
   */
  self.init = false;

  /**
   * Round amount
   */
  self.amount = null;

  /**
   * Round Numbers
   */
  self.numbers = [];

  /**
   * User Coin
   */
  self.coin = null;

  /**
   * Event On Playing Game
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_SINGLE_KENO, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_SINGLE_KENO);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Event for add user to game
 */
Engine.prototype.start = function () {
  let self = this;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_SINGLE_KENO,
    encode({
      amount: self.amount,
      numbers: self.numbers,
      coin: self.coin
    })
  );
};

/** Start Game Engine **/
export default Engine;
