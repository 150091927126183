import React from "react";
import md5 from "md5";
import { Modal, Row, Col, Image } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { __, encode, decode, DEVELOPMENT, sendNotfication, Event } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import { width } from "@mui/system";
import Recent from '../../../Recent';
import "../../../../Static/css/loginbonus.css";
import { color } from "highcharts";
import { yellow } from "@mui/material/colors";
import isLogged from "../../../../Auth";
import storage from "../../../../Storage";
class LoginBonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isLogged: storage.getKey('logged'),
            show: false,
            activeDay: 1,
            loginbonusstate: [],
            success: false,
            bonusAmount: 0,
            effect: 'zoomIn',
        };
        this.handleShow = this.handleShow.bind(this);
    }

    componentDidMount() {
        if (this.state.isLogged) {
            socket.emit(C.LOGIN_BONUS);
            socket.on(C.LOGIN_BONUS, (data) => {
                if (decode(data).activeDay == null) 
                {
                    this.setState({ show: false });
                }
                else {
                    this.setState({show: true, bonusAmount: decode(data).bonusAmount});
                    if (!decode(data).loginBonusState) this.setState({ loginbonusstate: [] });
                    else 
                    {
                        this.setState({ activeDay: decode(data).activeDay, loginbonusstate: decode(data).loginBonusState });
                    }
                }
            });
            Event.on("login_bonus", () => {
                this.handleShow();
            })
        }
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        if (this.state.isLogged) {
            socket.off(C.LOGIN_BONUS);
            socket.off(C.BONUS_CLAIM);
        }
    }
    handleShow(e){
        // this.setState({ show: true, effect: 'zoomIn' });
        socket.emit(C.LOGIN_BONUS);
        socket.on(C.LOGIN_BONUS, (data) => {
            if (decode(data).activeDay == null) 
            {  
                this.setState({ show: false });
            }
            else {
                this.setState({show: true, bonusAmount: decode(data).bonusAmount});
                if (!decode(data).loginBonusState) this.setState({ loginbonusstate: [] });
                else 
                {
                    this.setState({ activeDay: decode(data).activeDay, loginbonusstate: decode(data).loginBonusState });
                }
            }
        });
        document.addEventListener('mousedown', this.handleOutsideClick);
    }
    handleClaim(day) {
        let bonusdata = this.state.loginbonusstate;
        if(!bonusdata.includes(this.state.activeDay.toString())){
            bonusdata.push(this.state.activeDay.toString());
            this.setState({ loginbonusstate: bonusdata });
            socket.emit(C.BONUS_CLAIM, encode(bonusdata));
            socket.on(C.BONUS_CLAIM, (data) => {
                let decoded = decode(data);
                this.setState({ success: decoded });
                if (decoded) 
                {
                    sendNotfication( this.props.t('you_got') + " $"+ this.state.bonusAmount + this.props.t('as_login_bonus_successfully'));
                    this.setState({show: false});
                }
                else sendNotfication(this.props.t('bonus_claim_err'));
                socket.off(C.BONUS_CLAIM);
            });
        } else {
            sendNotfication(this.props.t('you_already_got_login_bonus'));
        }
    }
    handleClose() {
        this.setState({ show: false, effect: 'zoomOut' });
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (event) => {
        if (this.state.show && event.target.classList.contains('modal')) {
            this.handleClose();
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Modal
                    size="lg"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="login-md-modal"
                    className={'modalAuth animated luckyModal ' + this.state.effect}
                >
                    <Modal.Header className="bonuslogo-header">
                    </Modal.Header>
                    <Modal.Body className="auth-modal p-0">
                        <div className="container pb-2 pt-2 rounded bonusrounded" style={{ backgroundColor: '#181E31', marginTop: '34px' }}>
                            <div className="loginbonus-row row mx-2" style={{ position: 'relative', justifyContent: 'center' }}>
                                <div className="justify-content-center d-flex bonuslogo-left" style={{ width: '287px', position: 'absolute', left: '-85px', bottom: '-53px' }}>
                                    <img src="/assets/images/login-bonus/ox.png" alt="" style={{ width: '100%', height: '100%' }} />
                                </div>
                                <div className="d-flex justify-content-center bonuslog-mid" style={{ textAlign: 'end', marginTop: '30px', marginRight: '-93px' }}>
                                    <div className="bonus-logo-title" style={{width: '41%'}}>
                                        <h2 className="text-warnings bonustoptext" style={{ marginTop: '-14px', marginBottom: '1px', marginRight: '23px', fontSize: '38px', color: '#ffc107', fontWeight: 'bold', textTransform: 'uppercase' }}>{t('bonus')}</h2>
                                        <div className="d-flex bonusinfotop">
                                            <div className="rounded px-2 sevendays" style={{ backgroundColor: '#ffc107', color: 'rgb(16,37,74)' }}><h3 style={{ margin: '1px', textTransform: 'uppercase' }}>7 {t('day')}</h3></div>
                                            <h3 className="text-white" style={{ margin: '1px' }}>  {t('login')}</h3>
                                        </div>
                                        <h6 className="text-white validtime" style={{ margin: '1px', fontSize: '9px' }}>{t('valid_time')}: 2024/07/21 - 2024/07/07</h6>
                                    </div>
                                </div>
                                <div className="bonuslogo-right" style={{ textAlign: 'end', position: 'absolute', right: '-59px', bottom: '-68px', width: '188px' }}>
                                    <img src="/assets/images/login-bonus/dice.png" alt="image that shows description" style={{ height: '100%', width: '100%' }} />
                                </div>
                            </div>
                            <div className="px-2">
                                <div className="rounded" style={{ backgroundColor: '#101629', marginLeft: '-7px', marginRight: '-7px' }}>
                                    <h5 className="" style={{ marginLeft: "33px", marginRight: "10%", marginTop: '2px', paddingTop: '12px', marginBottom: "3px", color: '#8780f7' }}>{t('new_user')} <span className="text-warning">7-{t('day_s')}</span> {t('login_bonus')}</h5>
                                    <h6 className="pb-2 text-white" style={{ marginLeft: "33px", marginRight: "10%", marginTop: "0px", fontSize: "11px"  }}>{t('note') + t('login_bonus_note_1')}</h6>
                                </div>
                                <div className="text-white loginbonus-row row rounded" style={{ backgroundColor: '#21283d' }}>
                                    <div className={`col-4 rounded ${this.state.activeDay === 1 && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 1</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 1 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 1 ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 1 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("1") ? <h6><span className="text-purple claimed">{t('claimed')}</span></h6> : this.state.activeDay > 1  && !this.state.success ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 1 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(1)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-4 rounded ${this.state.activeDay === 2 && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 2</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 2 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 2 ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 2 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("2") ? <h6><span className="text-purple claimed">{t('claimed')}</span></h6> : this.state.activeDay > 2 ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 2 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(2)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-4 rounded ${this.state.activeDay === 3  && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 3</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 3 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 3 ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 3 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("3") ? <h6><span className="text-purple claimed">{t('claimed')}</span></h6> : this.state.activeDay > 3 ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 3 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(3)} disabled={this.state.success}><span className="text-white"> </span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-white loginbonus-row mt-2 row rounded" style={{ backgroundColor: '#21283d' }}>
                                    <div className={`col-4 rounded ${this.state.activeDay === 4  && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 4</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 4 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 4 ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 4 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("4") ? <h6><span className="text-purple claimed">{t('claim')}</span></h6> : this.state.activeDay > 4 ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 4 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(4)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-4 rounded ${this.state.activeDay === 5  && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 5</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 5 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 5 ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 5 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("5") ? <h6><span className="text-purple claimed">{t('claim')}</span></h6> : this.state.activeDay > 5 ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 5 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(5)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-4 rounded ${this.state.activeDay === 6  && !this.state.success ? 'loginbonus-active' : ''}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className="mt-2 topbonus">
                                            <h6 className="text-center">{t('day')} 6</h6>
                                        </div>
                                        <div className="rounded-bottom" style={{ backgroundColor: '#101629' }}>
                                            <div className="d-flex justify-content-center bonusbomb">
                                                <img src={`/assets/images/login-bonus/skull${this.state.activeDay === 6 ? '_open' : ''}.png`} alt="Sweepstake Cash image" className={`${this.state.activeDay === 6  && !this.state.success ? 'active-img bonusicon' : 'w-75 bonusicon'}`} />
                                            </div>
                                            <div className="mt-1 text-center pb-2 bonusinfotext">
                                                {this.state.activeDay === 6 ?
                                                    <h6 className="text-light text-center"><span className="text-white">{t('bonus')} </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                    : this.state.loginbonusstate.includes("6") ? <h6><span className="text-purple claimed">{t('claim')}</span></h6> : this.state.activeDay > 6 ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                                }
                                                {this.state.activeDay === 6 ? <button type="button" className="btn btn-purple w-75 bonusbutton" onClick={() => this.handleClaim(6)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                    : <button type="button" className="btn btn-purple w-75 bonusbutton" disabled><span className="text-white">{t('claim')}</span></button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`loginbonus-row row rounded mt-2 text-white ${this.state.activeDay === 7  && !this.state.success ? 'loginbonus-active' : ''}`} style={{ backgroundColor: '#21283d', marginBottom: '5px' }}>
                                    <div className="mt-2 topbonus">
                                        <h6 className="text-center">{t('day')} 7 : <span className="text-warning">{t('super_gift')}</span></h6>
                                    </div>
                                    <div className="loginbonus-row row rounded-bottom align-items-center" style={{ backgroundColor: '#101629', marginLeft: 0}}>
                                        <div className="col-6 d-flex justify-content-center bonusbomb4">
                                            <img src="/assets/images/login-bonus/skull_7_day.png" alt="..." className="w-75" />
                                        </div>
                                        <div className="col-6 text-center pb-3">
                                            {this.state.activeDay === 7 ?
                                                <h6 className="text-light text-center"><span className="text-white">Bonus </span><span className="text-purple claimed">+{this.state.bonusAmount} USDT</span></h6>
                                                : this.state.loginbonusstate.includes("7") ? <h6><span className="text-purple claimed">{t('claim')}</span></h6> : this.state.activeDay > 7  && !this.state.success ? <h6><span className="text-purple claimed">{t('missed')}</span></h6> : <h6><span className="text-purple claimed">{t('waiting')}</span></h6>
                                            }
                                            {this.state.activeDay === 7 ? <button type="button" className="btn btn-purple w-75 bonusbutton bonusbuttonsuper" onClick={() => this.handleClaim(7)} disabled={this.state.success}><span className="text-white">{t('claim')}</span></button>
                                                : <button type="button" className="btn btn-purple w-75 bonusbutton bonusbuttonsuper" disabled><span className="text-white">{t('claim')}</span></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default LoginBonus;
