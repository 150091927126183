import * as PIXI from "pixi.js";
import { wait } from "../Helper/index.js";

class Text {
  constructor(text, opt = {}) {
    this.text = text;
    this.opt = opt;
    this.object = null;
    this.ticker = null;

    this.show();
  }

  show() {
    const style = new PIXI.TextStyle({
      fontFamily: this.opt.family ? this.opt.family : "Roboto",
      fontStyle: this.opt.style ? this.opt.style : "normal",
      fontSize: this.opt.size ? this.opt.size : 10,
      fill: [this.opt.color ? this.opt.color : 0xffffff],
      fontWeight: this.opt.bold ? 700 : 500,
      align: this.opt.align ? this.opt.align : "center",
      stroke: this.opt.stroke ? this.opt.stroke : "",
      wordWrap: true,
    });

    let t = new PIXI.Text(this.text, style);
    t.x = this.opt.x ? this.opt.x : 0;
    t.y = this.opt.y ? this.opt.y : 0;
    t.anchor.set(0.5);

    if (this.opt.button) {
      t.interactive = true;
      t.buttonMode = true;
    }

    if (this.opt.active) {
      t.interactive = true;
    }

    let index = this.opt.index ? this.opt.index : 1;

    t.sortChildren(index);
    t.zIndex = index;
    t.type = "text";
    this.object = t;
  }

  animate(delay, callback) {
    this.ticker = new PIXI.Ticker();

    let self = this;

    if (typeof delay === "function") {
      callback = delay;
      delay = 0;
    }

    var c = function (delta) {
      return callback(self.object, delta);
    };

    wait(delay).then(() => {
      this.ticker.add(c);
      this.ticker.start();
    });
  }

  stop() {
    if (this.ticker !== null) this.ticker.stop();
  }
}

export default Text;
