import _ from "lodash";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import Loader from "../Loader";
import Effect from "./components/Effect";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "/assets/images/diamond";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 750,
      height: 200,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.place = [];
    this.diamond = [];
    this.multi = [];
    this.onStart = true;
    this.container = null;
    this.loader = new Loader("diamond", this.app);
  }

  init() {
    this.loader.add([
      ASSETS_PATH + "/place.png",
      ASSETS_PATH + "/place-on.png",
      ASSETS_PATH + "/1.png",
      ASSETS_PATH + "/2.png",
      ASSETS_PATH + "/3.png",
      ASSETS_PATH + "/4.png",
      ASSETS_PATH + "/5.png",
    ]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load() {
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true;
    this.container.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configPlace();
    this.start();
  }

  setStart() {
    this.onStart = false;
  }

  configPlace() {
    for (var i = 0; i < 5; i++) {
      var place = PIXI.Sprite.from(ASSETS_PATH + "/place.png");
      place.y = this.app.screen.height - 70;
      place.x = 150 + i * 150 - 150;
      this.container.addChild(place);
      this.place.push(place);
    }
  }

  start(payout = [1.2, 1.5, 0.0, 1.8, 2.0]) {
    for (var i = 0; i < this.diamond.length; i++) {
      let place = this.place[i];
      let diamond = this.diamond[i];
      let text = this.multi[i];

      wait(i * 200).then(() => {
        gsap.to(diamond, { alpha: 0, y: -50 });
        wait(200).then(() => {
          if (!_.isUndefined(place)) {
            place.removeChild(diamond);

            if (!_.isUndefined(text)) place.removeChild(text);

            place.texture = PIXI.Texture.from(ASSETS_PATH + "/place.png");
          }
          this.diamond.splice(0, 1);
        });
      });
    }

    wait(this.onStart ? 100 : 1800).then(() => {
      this.play(payout);
    });
  }

  play(payout) {
    for (var i = 0; i < 5; i++) {
      let diamond = this.getDiamond(payout[i]);
      let sprite = PIXI.Sprite.from(ASSETS_PATH + `/${diamond}.png`);
      sprite.y = -60;
      sprite.x = 25;
      sprite.alpha = 0;
      sprite.id = diamond;
      this.diamond.push(sprite);
      this.place[i].addChild(sprite);

      let style = new PIXI.TextStyle({
        fontSize: 13,
        fontWeight: 300,
        fill: [0x000000],
      });
      let t = new PIXI.Text(payout[i] + "x", style);

      if (!this.onStart) {
        t.alpha = 0;
        t.y = 60;
        t.x = 61;
        t.id = diamond;
        this.multi[i] = t;
        this.place[i].addChild(t);
      }

      wait(i * 300).then(() => {
        gsap.to(sprite, { alpha: 1, y: -80 });

        if (!this.onStart) gsap.to(t, { alpha: 1, y: 14 });
      });
    }

    wait(1800).then(() => {
      if (this.onStart) return;

      let diamond0 = this.place[0].children[0].id;
      let diamond1 = this.place[1].children[0].id;
      let diamond2 = this.place[2].children[0].id;
      let diamond3 = this.place[3].children[0].id;
      let diamond4 = this.place[4].children[0].id;

      if (diamond0 === diamond1) {
        this.place[0].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        this.place[1].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );

        Effect.HeartBeat(this.place[0].children[0], 3);
        Effect.HeartBeat(this.place[1].children[0], 3);
        Effect.Particle(this.place[0].children[0], diamond1);
        Effect.Particle(this.place[1].children[0], diamond1);
      }
      if (diamond1 === diamond2) {
        this.place[1].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        this.place[2].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        Effect.HeartBeat(this.place[1].children[0], 3);
        Effect.HeartBeat(this.place[2].children[0], 3);
        Effect.Particle(this.place[1].children[0], diamond2);
        Effect.Particle(this.place[2].children[0], diamond2);
      }
      if (diamond2 === diamond3) {
        this.place[2].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        this.place[3].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        Effect.HeartBeat(this.place[2].children[0], 3);
        Effect.HeartBeat(this.place[3].children[0], 3);
        Effect.Particle(this.place[2].children[0], diamond3);
        Effect.Particle(this.place[3].children[0], diamond3);
      }
      if (diamond3 === diamond4) {
        this.place[3].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        this.place[4].texture = PIXI.Texture.from(
          ASSETS_PATH + "/place-on.png"
        );
        Effect.HeartBeat(this.place[3].children[0], 3);
        Effect.HeartBeat(this.place[4].children[0], 3);
        Effect.Particle(this.place[3].children[0], diamond4);
        Effect.Particle(this.place[4].children[0], diamond4);
      }
    });
  }

  busted() {}

  getDiamond(payout) {
    switch (payout) {
      case 1.2:
        return 1;
        break;
      case 1.5:
        return 2;
        break;
      case 1.8:
        return 4;
        break;
      case 2.0:
        return 5;
        break;
      case 0.0:
        return 3;
        break;

      case 3.0:
        return 1;
        break;
      case 6.0:
        return 2;
        break;
      case 8.0:
        return 4;
        break;
      case 10.0:
        return 5;
        break;
      case 0.0:
        return 3;
        break;
    }
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
