import React, {Component} from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, decode, encode, forceSatoshiFormat, fixDate, wait} from "../../../../Helper";
import C from "../../../../Constant";

const C_ = React.createElement;

class DepositArchive extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            deposits: [],
            pageIndex: 1,
            itemsPerPage: 10,
            total_items: 0
        };
    }

    componentDidMount(){
        this._isMounted = true;
        socket.emit(C.WALLET_HISTORY,  encode({pageIndex: this.state.pageIndex, items: this.state.itemsPerPage}));
        socket.on(C.WALLET_HISTORY, data => this.makeHistory(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off(C.WALLET_HISTORY);
    }

    makeHistory(data){
        if(this._isMounted)
        {
            let array = data.deposit;
            let deposits_history = [];
            if(array) {
                array.forEach((deposit, i) => {
                    // let block = <DepositTable data={deposit} key={i}/>;
                    // this.setState(state => ({deposits: [block, ...state.deposits]}));
                    deposits_history.push(<DepositTable data={deposit} key={i}/>);
                });
            }
            this.setState({ deposits: [...deposits_history], total_items: array.length > 0 ? Number(array[0].total_rows) : 0, loading: false });
        }
    }

    nextPage = () => {
        if(this._isMounted)
        {
            // this.setState({ next: true });
            socket.emit(C.WALLET_HISTORY, encode({pageIndex: this.state.pageIndex + 1, items: this.state.itemsPerPage}));
            this.setState({ pageIndex: this.state.pageIndex + 1 });
        }
    };

    render() {
        let { loading } = this.state;
        const { t } = this.props;
        return(
            <>
                <>
                    <div className="table-responsive mt-2" style={{ minHeight: 460 }}>
                        <table className="table table-hover deposit">
                            <thead>
                            <tr>
                                <th scope="col">{t('date')}</th>
                                <th scope="col">{t('amount')}</th>
                                <th scope="col">{t('status')}</th>
                                <th scope="col">{t('transaction')}</th>
                            </tr>
                            </thead>
                            {this.state.deposits.length > 0 &&
                                this.state.deposits 
                            }
                        </table>
                        {loading ?
                            <div className="text-center child-center" style={{ minHeight: 460 }}>
                                <div className="spinner-grow text-white my-3" role="status" />
                            </div>
                        :
                            <>
                            {this.state.deposits.length === 0 &&
                                <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                                    [ {t('your_deposit_list_is_empty')} ]
                                </div>
                            }
                            </>
                        }
                        { (this.state.total_items / this.state.itemsPerPage) > (this.state.pageIndex) &&
                            <div className={'text-center'}>
                                <button onClick={this.nextPage} className={'mb-2 btn bg-cs btn-xs'}>
                                    <i className={'mdi mdi-arrow-right-bold-circle'} /> {t('next_page')}
                                </button>
                            </div>
                        }
                    </div>
                </>
            </>
        );
    }
}

function DepositTable(props){
    const { amount, status, date, txtid, coin } = props.data;
    return(
            <tr>
                <td>{fixDate(date)}</td>
                <td>{forceSatoshiFormat(amount) + ' ' + __.upperCase(coin)}</td>
                <td>{status}</td>
                <td>
                    <input className="form-control" readOnly={true} value={txtid} />
                </td>
            </tr>
    );
}

export default DepositArchive;
