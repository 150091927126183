const games = [
    {
        id: 1,
        name: "Crash",
        prefix: "crash",
        surfix: "crash",
        image: "/assets/images/covers/minified/crash.png",
        color: 'linear-gradient(114deg, rgb(63 81 181 / 55%) 0%, rgb(109, 39, 201) 100%)',
        icon: "dripicons-rocket",
        hedge: 1
    },
    {
        id: 2,
        name: "Dice",
        prefix: "classic-dice",
        surfix: "classic_dice",
        image: "/assets/images/covers/minified/classic_dice.png",
        color: 'linear-gradient(10deg, rgb(255 93 160) 0%, rgb(220, 93, 32) 100%)',
        icon: "mdi mdi-dice-d10",
        hedge: 1
    },
    /*
    {
        id: 3,
        name: "Mines",
        prefix: "mine",
        surfix: "mine",
        image: "/assets/images/covers/minified/mine.png",
        color: 'linear-gradient(162deg, rgb(255 83 31) 0%, rgb(233 30 30) 100%)',
        icon: "mdi mdi-bomb",
        hedge: 1
    },
    */
    {
        id: 4,
        name: "Keno",
        prefix: "keno",
        surfix: "keno",
        image: "/assets/images/covers/minified/keno.png",
        color: 'linear-gradient(rgb(16 169 52) 0%, rgb(65 220 52) 40%)',
        icon: "mdi mdi-numeric-7-box",
        hedge: 1
    },
    /*
    {
        id: 5,
        name: "Black Jack",
        prefix: "blackjack",
        surfix: "blackjack",
        image: "/assets/images/covers/minified/blackjack.png",
        color: 'linear-gradient(0deg, #ae1d3e 0%, #dc3545 100%)',
        icon: "mdi mdi-dice-6",
        hedge: 1
    }
    {
        id: 6,
        name: "Roulette",
        prefix: "roulette",
        surfix: "roulette",
        image: "/assets/images/covers/minified/roulette.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-plus-circle-multiple-outline",
        hedge: 1
    },
    */
    {
        id: 7,
        name: "Wheel",
        prefix: "wheel",
        surfix: "wheel",
        image: "/assets/images/covers/minified/wheel.png",
        color: 'linear-gradient(110deg, rgba(111, 66, 194, 0.68) 0%, #7551e9 100%)',
        icon: "mdi mdi-ship-wheel",
        hedge: 1
    },
    {
        id: 8,
        name: "Magic Wheel",
        prefix: "magic-wheel",
        surfix: "magic_wheel",
        image: "/assets/images/covers/minified/magic-wheel.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-pinwheel",
        hedge: 1
    },
    /*
    {
        id: 9,
        name: "Hi-lo",
        prefix: "hilo",
        surfix: "hilo",
        image: "/assets/images/covers/minified/hilo.png",
        color: 'linear-gradient(10deg, #9c27b0 0%, rgb(87 39 212) 100%)',
        icon: "mdi mdi-houzz-box",
        hedge: 1
    },
    */
    {
        id: 10,
        name: "Single Keno",
        prefix: "singlekeno",
        surfix: "singlekeno",
        image: "/assets/images/covers/minified/singlekeno.png",
        color: 'linear-gradient(0deg, rgb(233 30 99) 0%, rgb(254 71 71) 100%)',
        icon: "mdi mdi-cube-outline",
        hedge: 1
    },
    {
        id: 11,
        name: "Video Poker",
        prefix: "videopoker",
        surfix: "videopoker",
        image: "/assets/images/covers/minified/videopoker.png",
        color: 'linear-gradient(110deg, #8bc34a 0%, #009688 100%)',
        icon: "mdi mdi-dice-3",
        hedge: 1
    },
    {
        id: 12,
        name: "Plinko",
        prefix: "plinko",
        surfix: "plinko",
        image: "/assets/images/covers/minified/plinko.png",
        color: 'linear-gradient(271deg, rgb(90 68 255) 0%, #5e7edb 100%)',
        icon: "mdi mdi-baseball-bat",
        hedge: 1
    },
    /*
    {
        id: 13,
        name: "Goal",
        prefix: "goal",
        surfix: "goal",
        image: "/assets/images/covers/minified/goal.png",
        color: 'linear-gradient(110deg, #8bc34a 0%, #4caf50 100%)',
        icon: "mdi mdi-volleyball",
        hedge: 1
    },
    {
        id: 14,
        name: "Snake Ladders",
        prefix: "snake-and-ladders",
        surfix: "snakeandladders",
        image: "/assets/images/covers/minified/sal.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-blur-linear",
        hedge: 1
    },
    */
    {
        id: 15,
        name: "3 Card Monte",
        prefix: "3-card-monte",
        surfix: "3_cardmonte",
        image: "/assets/images/covers/minified/3-card-monte.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-cards",
        hedge: 1
    },
    {
        id: 16,
        name: "Diamond",
        prefix: "diamond",
        surfix: "diamond",
        image: "/assets/images/covers/minified/diamond.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-diamond",
        hedge: 1
    },
    /*
    {
        id: 18,
        name: "Trading",
        prefix: "crypto-trading",
        surfix: "crypto-trading",
        image: "/assets/images/covers/minified/crypto-trading.png",
        color: 'linear-gradient(10deg, rgb(119 63 255) 0%, rgb(114, 58, 246) 100%)',
        icon: "mdi mdi-dice-6",
        hedge: 1
    },
    */
    {
        id: 17,
        name: "Limbo",
        prefix: "limbo",
        surfix: "limbo",
        image: "/assets/images/covers/minified/limbo.png",
        color: 'linear-gradient(10deg, rgb(255 234 0) 0%, #ff9800 100%)',
        icon: "mdi mdi-rocket",
        hedge: 1
    },
    /*
    {
        id: 18,
        name: "Baccarat",
        prefix: "baccarat",
        surfix: "baccarat",
        image: "/assets/images/covers/minified/baccaratt.png",
        color: 'linear-gradient(10deg, rgb(119 63 255) 0%, rgb(114, 58, 246) 100%)',
        icon: "mdi mdi-dice-6",
        hedge: 1
    },
    {
        id: 19,
        name: "Hash Dice",
        prefix: "hash-dice",
        surfix: "hash_dice",
        image: "/assets/images/covers/minified/hashdice.png",
        color: 'linear-gradient(10deg, rgb(119 63 255) 0%, rgb(114, 58, 246) 100%)',
        icon: "mdi mdi-dice-6",
        hedge: 1
    },
    */
    {
        id: 20,
        name: "",
        prefix: "slots",
        surfix: "slots",
        image: "/assets/images/covers/minified/bullmore.png",
        color: 'linear-gradient(10deg, #673ab7 0%, #3f51b5 100%)',
        icon: "mdi mdi-diamond",
        hedge: 1
    }
];

// Export the Hash module
export default games;