import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { wait, isMobile, isTablet, Event, __, hasClass } from "./Helper";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chat: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (isMobile() || isTablet()) {
      this.setState({ chat: false });
    }

    window.addEventListener("resize", this.handleResize);

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("DOMMouseScroll", this.handleScroll, false); // older FF
    window.addEventListener(wheelEvent, this.handleScroll, wheelOpt); // modern desktop
    window.addEventListener("touchmove", this.handleScroll, wheelOpt); // mobile
    window.addEventListener("keydown", this.preventDefaultForScrollKeys, false);

    wait(500).then(() => {
      this.handleResize();
    });

    Event.on("toggle_sidebar", (show) => {
      wait(100).then(() => {
        this.handleResize();
      });
    });

    // TODO
    // Event.on("direction_change", (rtl) => {
    //   var direction;
    //   if (rtl) {
    //     direction = "body {direction: rtl !important;}";
    //   } else {
    //     direction = "body {direction: ltr !important;}";
    //   }
    //   var styleElement = document.createElement("style");
    //   styleElement.type = "text/css";
    //   styleElement.id = "direction_css";
    //   document.getElementsByTagName("head")[0].appendChild(styleElement);
    //   styleElement.appendChild(document.createTextNode(direction));
    // });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleScroll(e) {
    // let body = document.querySelector("body");
    // if (hasClass(body, "modal-open")) {
    //   e.preventDefault();
    // }
  }

  preventDefaultForScrollKeys(e) {
    let body = document.querySelector("body");
    if (hasClass(body, "modal-open")) {
      var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
      if (keys[e.keyCode]) {
        e.preventDefault();
        return false;
      }
    }
  }

  handleResize = () => {
    const leftSidenav = document.querySelector(".left-sidenav");
    const leftSidebar = document.querySelector(".left-sidebar");
    const pageContent = document.querySelector("#page-content");
    // const chatContent = document.getElementById('chat_div');

    const clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (pageContent !== null) {
      if (!isMobile()) {
        if (clientWidth < 1200) {
          if (leftSidebar) {
            leftSidebar.classList.remove("big");
            leftSidebar.classList.add("min");
            leftSidenav.classList.remove("big");
            leftSidenav.classList.add("min");
          }
        } else {
          if (leftSidebar) {
            leftSidebar.classList.remove("min");
            leftSidebar.classList.add("big");
            leftSidenav.classList.remove("min");
            leftSidenav.classList.add("big");
          }
        }
      }
    }
    
    // let body = document.querySelector("body");
    // if (hasClass(body, "modal-open")) {
    //   e.preventDefault();
    // }

  };

  render() {
    return <>{this.props.children}</>;
  }
}

export default Layout;
