import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { forceSatoshiFormat, decode, encode } from "../../../Helper";
/**
 * Game Engine
 */
function Engine() {
  let self = this;

  /**
   * Game Prefix
   */
  self.prefix = "_CLASSIC_DICE";

  /**
   * Game Name
   */
  self.game = "classic_dice";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Game Status
   * 1 type: started
   */
  self.gameStatus = null;

  /**
   * Intialize Auto Mode
   */
  self.init = false;

  /**
   * Current User Coin
   */
  self.coin = null;

  /**
   * Round amount
   */
  self.amount = null;

  /**
   * Bets Number
   */
  self.betNumber = 10;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Roll Type
   */
  self.type = "Under";

  /**
   * Player is the Win or Lost
   */
  self.isWin = false;

  /**
   * Stop Playing if Won Amount
   */
  self.stopOnWin = 0;

  /**
   * Round Profit
   */
  self.roundProfit = 0;

  /*
   * Payout Range / Default is 1.9800
   */
  self.payout = 1.98;

  /*
   * Chance is Range
   */
  self.chance = 50;

  /*
   * Under or Over
   */
  self.under_over = 50;

  /*
   * is this guest
   */
  self.guest = true;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_CLASSIC_DICE, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_CLASSIC_DICE);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_CLASSIC_DICE,
    encode({
      game: self.game,
      coin: self.coin,
      type: self.type,
      amount: self.amount,
      payout: self.payout,
      chance: self.chance,
      under_over: self.under_over,
    })
  );
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
  let self = this;

  if (!self.init) return;

  // Increase Amount on Win (player set increase amount on win in %)
  if (self.onWin !== 0) {
    // It Mean Player has win in this round
    if (self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onWin;
    }
  }

  // Increase Amount on Lost (player set increase amount on lost in %)
  if (self.onLost !== 0) {
    // It Mean Player has lost in this round
    if (!self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onLost;
    }
  }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.roundProfit) >= forceSatoshiFormat(self.stopOnWin)
    ) {
      self.roundProfit = 0;
      self.trigger.emit("stop");
      return;
    }
  }

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }
  self.ws.emit(
    C.PLAY_CLASSIC_DICE,
    encode({
      game: self.game,
      coin: self.coin,
      type: self.type,
      amount: self.amount,
      payout: self.payout,
      chance: self.chance,
      under_over: self.under_over,
    })
  );
};

/** Start Game Engine **/
export default Engine;
