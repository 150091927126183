import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat, __ } from "../../../Helper";
/**
 * Wheel Engine
 */
function Engine() {
  var self = this;

  /**
   * Game Prefix
   */
  self.prefix = "_WHEEL";

  /**
   * Game Name
   */
  self.game = "wheel";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Game Status
   * 1 type: started
   */
  self.gameStatus = null;

  /**
   * Intialize Auto Mode
   */
  self.init = false;

  /**
   * Current User Coin
   */
  self.coin = null;

  /**
   * Round amount
   */
  self.amount = null;

  /**
   * Risk
   */
  self.risk = null;

  /**
   * Segment
   */
  self.segment = null;

  /**
   * Win or Lost
   */
  self.win = null;

  /**
   * Busted Game Hash
   */
  self.hash = null;

  /**
   * Bet Number
   */
  self.betNumber = null;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Player is the Win or Lost
   */
  self.isWin = false;

  /**
   * Stop Playing if Won Amount
   */
  self.stopOnWin = 0;

  /**
   * Round Profit
   */
  self.roundProfit = 0;

  /**
   * Result Number
   */
  self.resultNumber = null;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_WHEEL, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.stop = function () {
  let self = this;
  self.started = false;
  self.trigger = null;
  self.ws.off(C.PLAY_WHEEL);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
  let self = this;
  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_WHEEL,
    encode({
      command: "play",
      risk: self.risk,
      coin: self.coin,
      amount: self.amount,
      segment: self.segment
    })
  );
};

/**
 * End Playing Game
 */
Engine.prototype.busted = function () {
  let self = this;

  if (!self.init) return;

  self.ws.emit(
    C.PLAY_WHEEL,
    encode({
      command: "busted",
      win: self.win,
      result: self.resultNumber,
    })
  );
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
  let self = this;

  if (!self.init) return;

  // Increase Amount on Win (player set increase amount on win in %)
  if (self.onWin !== 0) {
    // It Mean Player has win in this round
    if (self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onWin;
    }
  }

  // Increase Amount on Lost (player set increase amount on lost in %)
  if (self.onLost !== 0) {
    // It Mean Player has lost in this round
    if (!self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onLost;
    }
  }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.roundProfit) >= forceSatoshiFormat(self.stopOnWin)
    ) {
      self.roundProfit = 0;
      self.trigger.emit("stop");
      return;
    }
  }

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_WHEEL,
    encode({
      command: "play",
      risk: self.risk,
      coin: self.coin,
      amount: self.amount,
      segment: self.segment,
    })
  );
};

/** Start Game Engine **/
export default Engine;
