import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isMobile, wait, Event, encode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import Loading from "../Loading";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    engine.started = true;

    // listen to socket
    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }

  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;
    const help = (
      <p className={"font-light text-white font-15"}>
        Baccarat is the card game played at casinos. It is a comparing card game
        played between two hands, the player and the banker. Each baccarat coup
        (round of play) has three possible outcomes: player (player has the
        higher score), banker and tie.
        <br />
        Player's rule
        <br />
        If Player has an initial total of 0–5, he draws a third card. If Player
        has an initial total of 6 or 7, he stands.
        <br />
        Banker's rule
        <br />
        If Player stood pat (i.e., has only two cards), the banker regards only
        his own hand and acts according to the same rule as Player. That means
        Banker draws a third card with hands 0–5 and stands with 6 or 7.
        <br />
         If
        Player drew a third card, the Banker acts according to the following
        more complex rules: 
        <br />If Player drew a 2 or 3, Banker draws with 0–4 and
        stands with 5–7. 
        <br />If Player drew a 4 or 5, Banker draws with 0–5 and
        stands with 6–7.
        <br /> If Player drew a 6 or 7, Banker draws with 0–6 and
        stands with 7. 
        <br />If Player drew an 8, Banker draws with 0–2 and stands
        with 3–7. 
        <br />If Player drew an ace, 9, 10, or face-card, the Banker draws
        with 0–3 and stands with 4–7.
      </p>
    );
    return (
      <>
        <Helmet>
          <title>Baccarat | Crypto Casino Games</title>
          <meta
            name="keywords"
            content="Crypto Baccarat Game, Online Baccarat Game, Bitcoin Baccarat Game, Blockchain Baccarat Game, Best Crypto Baccarat Game, BetsMixer.com Baccarat"
          />
          <meta
            name="description"
            content="Objective of the Crypto Baccarat Game: Each participant attempts to beat the dealer by getting a count as close to 21 as possible, without going over 21. Card values and scoring: It is up to each individual player if an ace is worth 1 or 11."
          />
          <meta
            name="og:title"
            content="Play Online Baccarat - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Baccarat - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/Baccarat" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">Crypto Baccarat Description: {help}</div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* TOP MENUS */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            Baccarat
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"baccarat"} />%
                          </span>
                        </p>
                        <BankRoll game={"Baccarat"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Baccarat" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body className={"bg-dice-options p-1"} id="roll-panel">
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <Game engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    {/* <Stack direction="horizontal" gap={3} className="mt-3 mb-1 ms-3">
                                            <div className="d-flex align-items-center">
                                                <img className='img-fluid home-page-icons' src="/assets/images/icons/last-bets.svg" />
                                                <p className="m-0 home-part-t"> Last Bets</p>
                                            </div>
                                        </Stack>

                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto px-1'}>
                                                <Queue t={this.props.t} game={'Baccarat'} />
                                            </Col>
                                        </Row> */}

                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
