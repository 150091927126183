import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import { CLIENT_ID, encode, randomString } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Google extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogined: false,
            accessToken: '',
            ip: null
        };
        this.login = this.login.bind(this);
    }
    componentDidMount() {
        fetch('https://api.ipify.org?format=json')
        .then(response => {
            return response.json();
        }, "jsonp")
        .then(res => {
            this.setState({ip: res.ip});
        })
    }
    login(resp) {
        if (resp.accessToken) {
            this.setState(state => ({ isLogined: true, accessToken: resp.accessToken }));

            let profile = resp.profileObj;

            socket.emit(C.LOGIN_USER_GOOGLE, encode({
                username: profile.givenName + profile.familyName,
                email: profile.email,
                token: resp.accessToken,

                //F A K E S
                user_token: randomString(50),
                security_key: randomString(10),
                secret_user: randomString(44),
                secret_realtime: randomString(50),
                client_system: randomString(23),
                token_key: randomString(23),
                secret_token: randomString(25),
            }));
        }
    }

    render() {
        return (
            <div className="google">
                {!this.state.isLogined &&
                    <GoogleLogin
                        clientId={CLIENT_ID}
                        buttonText='Login by Google'
                        onSuccess={this.login}
                        cookiePolicy={'single_host_origin'}
                        responseType='code,token'
                    />
                }
            </div>
        )
    }
}

export default withRouter(Google);
