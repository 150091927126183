import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Row, Col, Stack, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import Swal from "sweetalert2";
import TopGames from "./TopGames";
import Services from "./Services";
import Services2 from "./Services2";
import Games from "./Games";
import Bets from "./Bets";
import Winners from "../../../Winners";
import Slots from "../../../Slots/Slots";
import { BRAND } from "../../../../Helper";
import Betscounter from "./Betscounter";
import Help from "../../../Components/Game/Addons/TopBar/Help";
import Cookies from "js-cookie";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Event,
  decode,
  encode,
  wait,
  isMobile,
  isTablet,
  sendNotfication,
} from "../../../../Helper";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import LoginBonus from "./LoginBonus";
import FreeCredit from "../../../Components/User/FreeCredit";
import { userUID } from "../../../../Auth";
import HomeSports from "./HomeSports";

class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey("logged") !== null ? true : false,
      email_verified:
        storage.getKey("email_verified") == "null" ||
        !storage.getKey("email_verified")
          ? false
          : true,
      show: false,
      value: 0,
      isSentVerLink: false,
      email: storage.getKey("email") ? storage.getKey("email") : null,
      email_bonus: null,
      showFreeCredit: false,
      verify_email_status: null,
      baseURL:
        "https://wall.lootably.com/?placementID=cligjs0lq03j8012t1m5nhiul&sid=",
      mobile: isMobile(),
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const queryParams = new URLSearchParams(window.location.search);
    const affCid = queryParams.get("aff_cid");
    if (affCid) Cookies.set("aff_cid", affCid, { expires: 14 });

    Event.on("user_email_verified", () => {
      this.setState({ email_verified: true });
    });
    if (this.state.mobile) Event.emit("toggle_chat", false);
    else {
      if (storage.getKey("chat_view")) {
        Event.emit(
          "toggle_chat",
          storage.getKey("chat_view") == "true" ? true : false
        );
      } else {
        if (isMobile() || isTablet()) {
          Event.emit("toggle_chat", false);
        } else {
          //desktop
          Event.emit("toggle_chat", true);
        }
      }
    }
    socket.emit(C.GET_SETTING);
    socket.on(C.GET_SETTING, (data) => {
      const { status } = decode(data);
      this.setState({ verify_email_status: parseFloat(status) });
    });
    socket.emit(C.EMAIL_BONUS);
    socket.on(C.EMAIL_BONUS, (data) => {
      if (!decode(data)) {
        this.setState({ email_verified: false });
      } else this.setState({ email_bonus: decode(data) });
    });
    socket.on(C.SEND_EMAIL_VERIFICATION, (status) => {
      let { success } = decode(status);
      if (success) {
        this.setState({ isSentVerLink: true });
      }
    });

    socket.on(C.LOOTABLY_POSTBACK, (data) => {
      let result = decode(data);
      if (result.success) {
        sendNotfication(result.message, "success", "bottom-left");
      } else {
        sendNotfication(result.message, "info", "bottom-left");
      }
    });

    socket.on(C.TOROX_POSTBACK, (data) => {
      let result = decode(data);
      if (result.success) {
        sendNotfication(result.message, "success", "bottom-left");
      } else {
        sendNotfication(result.message, "info", "bottom-left");
      }
    });

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    socket.off(C.SEND_EMAIL_VERIFICATION);
    socket.off(C.EMAIL_BONUS);
    socket.off(C.LOOTABLY_POSTBACK);
    socket.off(C.TOROX_POSTBACK);
  }

  handleClose() {
    this.setState({ showFreeCredit: false, value: 0 });
  }
  closeModal() {
    this.setState({ show: false });
  }
  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  handleSubmit() {
    Swal.fire({
      title: this.props.t("will_send_info_to_registered_email"),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.props.t("yes") + "!",
    }).then((result) => {
      if (result.value) {
        socket.emit(C.SEND_EMAIL_VERIFICATION);
      }
    });
  }
  openFreeCredit() {
    if (this.state.isLogged) {
      this.setState({ showFreeCredit: true });
      this.setState({
        baseURL:
          "https://wall.lootably.com/?placementID=cligjs0lq03j8012t1m5nhiul&sid=" +
          userUID(),
      });
    } else {
      Event.emit("login_form");
    }
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    let { content, t } = this.props;
    return (
      <>
        {this._isMounted && (
          <>
            <Helmet>
              <title>BETSMIXER | Win huge crypto prizes, Instant Wins</title>
              <meta
                name="description"
                content="BetsMixer, Best crypto casino Site. Enjoy the thrill of Instant Win. Top the leaderboards with 300+ games and lightning fast payouts."
              />
              <meta
                name="og:title"
                content="BetsMixer, Best crypto casino Site. Enjoy the thrill of Instant Win. Top the leaderboards with 300+ games and lightning fast payouts."
              />
              <meta
                name="og:description"
                content="BetsMixer, Best crypto casino Site. Enjoy the thrill of Instant Win. Top the leaderboards with 300+ games and lightning fast payouts."
              />
            </Helmet>

            {/* Page Modal Content */}
            {content}
            {/* <LoginBonus t={t} /> */}
            <div className="p-2 home-wrapper">
              {this.state.verify_email_status === 1 && (
                <>
                  {this.state.isLogged &&
                  !this.state.email_verified &&
                  this.state.email_bonus ? (
                    <div className="p-2 home-wrapper verified-wrapper">
                      <div className="verified-box">
                        <div className="verify-email-inner">
                          <div
                            className="verify-email-content"
                            onClick={(e) =>
                              this.setState({ show: !this.state.show })
                            }
                          >
                            <span className="p">{t("verify_your_email")}</span>
                            <span>
                              {t("now_to_receive")} {this.state.email_bonus}{" "}
                              USDT {t("bonus_s")}.
                            </span>
                          </div>
                        </div>
                        <button
                          className="close-icon"
                          onClick={(e) =>
                            this.setState({
                              email_verified: !this.state.email_verified,
                            })
                          }
                        >
                          <i className="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              <TopGames t={t} />

              {/* <Popular t={t} /> */}
              <Services t={t} />

              <Stack direction="horizontal" gap={3} className="home-game-title">
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid home-page-icons"
                    src="/assets/images/icons/in-house-game.svg"
                  />
                  <p className="m-0 home-part-t"> {t("in_house_game")}</p>
                </div>
                <button
                  id="game_all_btn"
                  type="button"
                  className="ms-auto game_all_btn d-none"
                  onClick={() => this.openFreeCredit()}
                >
                  <i className="mdi mdi-currency-usd freecreditsicon2 text-muted mr-2 text-drop"></i>{" "}
                  {t("offerwall_c")}
                  <span></span>
                </button>
                <Modal
                  size={"lg"}
                  backdrop={"static"}
                  centered={true}
                  scrollable={false}
                  show={this.state.showFreeCredit}
                  onHide={this.handleClose}
                  aria-labelledby="wallet-md-modal"
                >
                  <Modal.Header className={this.state.paddingHeader}>
                    {t("free_credits")}
                    <button
                      type="button"
                      className="close p-2"
                      onClick={this.handleClose}
                    >
                      <i className={"mdi mdi-close"} />
                    </button>
                  </Modal.Header>
                  <Modal.Body
                    className={
                      this.state.padding + " wallet-modal free-money-btns"
                    }
                  >
                    <AppBar position="static" color="transparent">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                        className="tabsfreemoney"
                      >
                        <Tab label={"OfferToro"} />
                        <Tab label={"Lootably"} />
                        <Tab label={"AdGateMedia"} />
                      </Tabs>
                    </AppBar>
                    <div className="pt-2 offerwalltop">
                      <SwipeableViews
                        axis={"x" === "rtl" ? "x-reverse" : "x"}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                      >
                        <div
                          role="tabpanel"
                          hidden={this.state.value !== 0}
                          id={`full-width-tabpanel-0`}
                          aria-labelledby={`full-width-tab-0`}
                        >
                          {this.state.value === 0 && (
                            <Box p={0}>
                              <Typography component={"span"}>
                                <iframe
                                  className="offerwalls"
                                  src={`https://torox.io/ifr/show/30454/${storage.getKey(
                                    "uid"
                                  )}/15512`}
                                  frameBorder="0"
                                  style={{ width: "100%", height: "600px" }}
                                ></iframe>
                              </Typography>
                            </Box>
                          )}
                        </div>
                        <div
                          role="tabpanel"
                          hidden={this.state.value !== 1}
                          id={`full-width-tabpanel-0`}
                          aria-labelledby={`full-width-tab-0`}
                        >
                          {this.state.value === 1 && (
                            <Box p={0}>
                              <Typography component={"span"}>
                                <iframe
                                  className="offerwalls"
                                  title="Lootably Offer Wall"
                                  src={this.state.baseURL}
                                  style={{ width: "100%", height: "600px" }}
                                />
                              </Typography>
                            </Box>
                          )}
                        </div>
                        <div
                          role="tabpanel"
                          hidden={this.state.value !== 2}
                          id={`full-width-tabpanel-0`}
                          aria-labelledby={`full-width-tab-0`}
                        >
                          {this.state.value === 2 && (
                            <Box p={0}>
                              <Typography component={"span"}>
                                <iframe
                                  className="offerwalls"
                                  src={`https://wall.adgaterewards.com/nqaTp20/${storage.getKey(
                                    "uid"
                                  )}`}
                                  frameBorder="0"
                                  style={{ width: "100%", height: "600px" }}
                                ></iframe>
                              </Typography>
                            </Box>
                          )}
                        </div>
                      </SwipeableViews>
                    </div>
                  </Modal.Body>
                </Modal>
              </Stack>

              <Games t={t} />

              <Slots t={t} home={true} specific="top_game" />

              <Services2 t={t} />

              <HomeSports t={t} />

              <Slots t={t} home={true} specific="pragmatic_play" />

              <Slots t={t} home={true} specific="popular_game" />

              <Slots t={t} home={true} specific="featured_game" />
            </div>

            <div className="p-2 mt-3 home-last-bets-wrapper">
              <Bets t={t} />
            </div>

            <div className="p-2 dailycontesthome">
              <Stack direction="horizontal" gap={3} className="mt-2 mb-3">
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid home-page-icons"
                    src="/assets/images/icons/last-bets.svg"
                  />
                  <p className="m-0 home-part-t"> {t("top_winners")}</p>
                </div>
              </Stack>
              <Winners t={t} />
            </div>

            <div className="p-2 mt-2">
              <Stack
                direction="horizontal"
                gap={3}
                className="mt-2 mb-3 slot-providers-title"
              >
                <div className="d-flex align-items-center">
                  <img src="/assets/images/icons/magician.png" />
                  <p className="m-0 home-part-t">
                    {" "}
                    {t("game_providers")}{" "}
                    <Link
                      to="/providers"
                      className="font-12 text-warning providers-view-all"
                    >
                      View All
                    </Link>
                  </p>
                </div>
              </Stack>

              <div className="-container">
                {this.state.mobile ? (
                  <>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={4}
                      slidesPerGroup={3}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Pagination, Autoplay]}
                      className="home-services"
                      breakpoints={{
                        300: {
                          slidesPerView: 2,
                          centeredSlides: false,
                        },
                        450: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        568: {
                          slidesPerView: 4,
                          spaceBetween: 35,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <Link
                          to="/provider/PragmaticPlay"
                          className="game2-link"
                        >
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/evolution" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/evolution.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/nolimit" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/nolimit.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/playngo" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/playngo.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/hacksaw" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/hacksaw.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/relax" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/relax.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/bgaming" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/bgaming.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/pushgaming" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/pushgaming.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/elk" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/elk.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/spinomenal" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/spinomenal.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                    </Swiper>
                  </>
                ) : (
                  <>
                    <Swiper
                      spaceBetween={3}
                      slidesPerView={5}
                      slidesPerGroup={1}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Pagination, Autoplay]}
                      className="home-services"
                    >
                      <SwiperSlide>
                        <Link
                          to="/provider/PragmaticPlay"
                          className="game2-link"
                        >
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/evolution" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/evolution.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/spinomenal" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/spinomenal.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/bgaming" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/bgaming.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/netent" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/netent.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/platipus" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/platipus.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/habanero" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/habanero.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/betsoft" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/betsoft.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/endorphina" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/endorphina.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/gamebeat" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/gamebeat.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/quickspin" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/quickspin.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/novomatic" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/novomatic.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/habanero" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/habanero.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/wazdan" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/wazdan.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/gameart" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/gameart.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/mascot" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/mascot.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/slotmill" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/slotmill.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link to="/provider/blueprint" className="game2-link">
                          <Col className="p-2 provider-wrapper">
                            <div className="provider-box">
                              <img
                                className="img-fluid"
                                src="https://cdn2.softswiss.net/logos/providers/white/blueprint.svg"
                              />
                            </div>
                          </Col>
                        </Link>
                      </SwiperSlide>
                    </Swiper>
                  </>
                )}
              </div>
            </div>

            <div className="p-2 mt-5 betswagered">
              <div className="d-flex justify-content-center mb-4">
                <div className="d-flex bets-wagerd-wrapper">
                  <Badge className="icon-wrapper p-0">
                    <Help content={""} t={t} />
                  </Badge>
                  {t("bets_wagered")}
                </div>
              </div>
              <div className="bets-counter">
                <Betscounter />
              </div>
            </div>
            <Modal
              size={"lg"}
              backdrop={"static"}
              centered={true}
              scrollable={false}
              show={this.state.show}
              onHide={this.closeModal}
              aria-labelledby="wallet-md-modal"
            >
              <Modal.Header>
                {t("email_verification")}
                <button
                  type="button"
                  className="close p-2"
                  onClick={this.closeModal}
                >
                  <i className={"mdi mdi-close"} />
                </button>
              </Modal.Header>
              <Modal.Body
                className={this.state.padding + " wallet-modal lootablywall"}
              >
                <label>
                  {t("according_policy_registered_email_cannot_changed")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  disabled={true}
                />
                {!this.state.isSentVerLink ? (
                  <button
                    type="submit"
                    className="btn mt-3 btn-purple px-4 no-shadow promocodebutton"
                    style={{ marginTop: "10px" }}
                    onClick={this.handleSubmit}
                  >
                    {t("verify")}
                  </button>
                ) : (
                  <div className="email-verificaton-sent">
                    {t("email_verification_link_was_sent")}
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default Home;
