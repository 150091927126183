import React from "react";
import { Row } from "react-bootstrap";
import socket from "../../../../Socket";
import { __, decode, encode, sendNotfication } from "../../../../Helper";
import C from "../../../../Constant";
import 'react-international-phone/style.css';
import { withRouter } from 'react-router-dom';
import { userUID } from "../../../../Auth";

class Providers extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            amount: 30
        };
        this.purchase = this.purchase.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        socket.on(C.BUY_COINBASE, data => {
            let result = decode(data);
            if (!result.success) sendNotfication(result.msg, 'success', 'bottom-left');
            else {
                let result = decode(data);

                const width = 800;
                const height = 600;
                const left = window.innerWidth / 2 - width / 2;
                const top = window.innerHeight / 2 - height / 2;

                window.open(result.data, "_blank", `width=${width},height=${height},left=${left},top=${top}`);
            }
        });

        socket.on(C.NOTIFY_PAYMENT, data => {
            let purchase = decode(data);
            
            if(purchase.hasOwnProperty('error') && purchase.error) {
                sendNotfication(purchase.message, 'danger', 'bottom-left');
            } else {
                sendNotfication(this.props.t('successfully_you_bought_credits_with_fiat') + ' ' + purchase.amount + ' ' + purchase.coin, 'success', 'bottom-left');
            }
            socket.emit(C.GET_USER_PROFIT);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off(C.BUY_COINBASE);
        socket.off(C.NOTIFY_PAYMENT);
    }

    purchase(event) {
        event.preventDefault();

        const paymentDetails = {
            local_price: {
                amount: this.state.amount.toFixed(2), 
                currency: 'USD', 
            },
            pricing_type: 'fixed_price',
            name: 'Coinbase Deposit',
            description: 'Coinbase Deposit',
            metadata: {
                id: userUID(),
            },
        };

        socket.emit(C.BUY_COINBASE, encode(paymentDetails));
        return;
    }

    onChange(value) {
        this.setState({ amount: _.toNumber(value) });
    }

    render() {
        const { t } = this.props;
        const { amount } = this.state;
        return (
            <>
                <Row className="m-0">
                    <div className="m-4 provider-top">
                        <div className="provider-container">
                            <AmountBox amount={amount} onChange={this.onChange} t={t} />
                            <div className="pp-container">
                                <div className="provider-button">
                                    <button
                                        type="submit"
                                        onClick={this.purchase}>
                                        <i className="mdi mdi-coins cardicon"></i>
                                        Open CoinBase
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </>
        );
    }
}

class AmountBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            depositamount: 30
        }

        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {depositamount: props.amount };
    }

    onChange(e) {
        let inputValue = _.toNumber(e.target.value);
        if (!/^[0-9\b]+$/.test(inputValue)) {
            return;
        }
        this.props.onChange(e.target.value);
        this.setState({ depositamount: inputValue })
    }

    increaseDepositAmount = () => {
        this.props.onChange(this.state.depositamount + 1);
        this.setState({ depositamount: this.state.depositamount + 1 })
    }

    decreaseDepositAmount = () => {
        if (this.state.depositamount < 1) return;
        this.props.onChange(this.state.depositamount - 1);
        this.setState({ depositamount: this.state.depositamount - 1 })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="full-width amount-list">
                <div className="media dollardeposit col-7 col">
                    <img src="/assets/images/dollar_symbols.png" className="mr-3 thumb-sm align-self-center rounded-circle icondollar" alt="Coin" />
                    <div className="media-body align-self-center">
                        <div className="coin-bal">
                            <h4 className="m-0 text-white">{t('deposit_amount')}:</h4>
                            <p className="text-muted mb-0">USDT {t('wallet_s')}<span className="text-muted font-12"> ($)</span></p>
                        </div>
                    </div>
                </div>
                <div className="amount-input-container col-5 text-right col">
                    <div className="amount-arrow d-flex flex-column">
                        <svg className="amount-arrow-up" onClick={this.increaseDepositAmount} width="17px" height="17px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                            </g>
                        </svg>
                        <svg className="amount-arrow-down" onClick={this.decreaseDepositAmount} width="17px" height="17px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="matrix(1, 0, 0, -1, 0, 0)">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3.9 15L12 6.9l8.1 8.1z"></path>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                            </g>
                        </svg>
                    </div>
                    <input className="amount-input" type="number" placeholder="100.00" value={this.state.depositamount} onChange={(e) => this.onChange(e)}></input>
                    <div className="hidden"></div>
                    <div className="amount-label">USD</div>
                </div>
            </div>
        )
    }
}

export default withRouter(Providers);
