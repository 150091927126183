import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import storage from "../../../Storage";
import socket from "../../../Socket";
import { __, encode, decode, toUSDFormat, Event } from "../../../Helper";
import { v4 as uuidv4 } from 'uuid';
export default class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token')
        };
    }

    componentWillMount() {
        if(this.state.token === null)
        {
            if(this.props.params)
            {
                let path = this.props.params.pathname;
                let query = new URLSearchParams(this.props.params.search);
                let tk = path.replace('/aff/','');
                if (tk === 'partner' && query.toString()) {
                    storage.setKey('aff', tk);
                    // This is the second type of tracking link with query parameters
                    let utm_source = query.get('utm_source');
                    let utm_medium = query.get('utm_medium');
                    let aff_cid = query.get('click_id');
                    if (utm_source) {
                        storage.setKey('utm_source', utm_source);
                    }
                    if (utm_medium) {
                        storage.setKey('utm_medium', utm_medium);
                    }
                    if (aff_cid) {
                        storage.setKey('aff_cid', aff_cid);
                    } else {
                        aff_cid = uuidv4();
                        storage.setKey('aff_cid', aff_cid);
                    }
                    this.props.history.push('/register');
                } else {
                    // This is the first type of tracking link without query parameters
            
                    if (tk) {
                        storage.setKey('aff', tk);
                        this.props.history.push('/register');
                    }
                }
            }
        }
        else {
            this.props.history.push('/');
        }
    }

    render(){
        return(
            <>
            </>
        );
    }
}
