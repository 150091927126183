import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import C from "../../Constant";
import { __, encode, decode, wait, Event, getGameID, sendNotfication } from "../../Helper";
import socket from "../../Socket";
import storage from "../../Storage";
import { gameCoin } from "../../actions/gameCoin";
import { setIPAddress } from '../../actions/setIPAddress';
class Frame extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            started: false,
            message: null,
            height: 505,
            token: storage.getKey('token'),
            game: this.props.game,
            url: null,
            isDemoLoading: false,
            isRealLoading: false,
            visible: false,
        }
    }


    componentDidMount() {
        this._isMounted = true;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        Event.on('coin_changed', () => this.restartGame());
        Event.on('game_changed', () => this.checkAndStart());
    }

    componentWillUnmount() {
        this._isMounted = false;
        URL.revokeObjectURL(this.state.url);
        socket.off(C.PLAT_SLOTS);
        socket.off(C.GET_DEMO_GAME);
        socket.off(C.GET_REAL_GAME);
    }

    showButton = () => {
        this.setState({ visible: true});
    };
    hideButton = () => {
        setTimeout(() => this.setState({ visible: false}), 3000); // Hides after 2 seconds of inactivity
    }
    handleScroll = () => {
        const halfHeight = document.documentElement.scrollHeight / 2;
          window.scrollBy({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
          });
      }

    checkAndStart() {
        this.setState({ started: false });
    }

    restartGame = () => {
        if (this._isMounted) {
            if (this.state.started) {
                this.setState({ started: false, url: null });
                wait(400).then(() => {
                    this.startReal();
                })
            }
        }
    }

    startDemo = () => {
        this.setState({ isDemoLoading: true });
        socket.emit(C.GET_DEMO_GAME, encode({ game_uuid: this.state.game.uuid, language: 'en', redirect_url: 'https://betsmixer.com/slot-end', country_name: this.props.ipAddress }));
        socket.on(C.GET_DEMO_GAME, data => {
            socket.off(C.GET_DEMO_GAME);
            this.setState({ isDemoLoading: false });
            let res = decode(data);
            if (res.success) {
                if (_.isUndefined(res.source)) {
                    this.setState({ started: true, url: res.url });
                } else {
                    const blob = new Blob([res.source], { type: 'text/html' });
                    const url = URL.createObjectURL(blob);
                    this.setState({ started: true, url: url });
                }
            } else {
                sendNotfication(this.props.t('issue_in_game_provider'), 'success', 'bottom-left');
            }
        });
    }

    startReal = () => {
        if (this.state.token !== null && this.state.isLogged) {
            this.setState({ isRealLoading: true });
            this.setState({ message: null });
            socket.emit(C.GET_REAL_GAME, encode({ game: this.state.game.uuid, coin: this.props.coin, country_name: this.props.ipAddress }))
            socket.on(C.GET_REAL_GAME, (data) => {
                socket.off(C.GET_REAL_GAME);
                this.setState({ isRealLoading: false });
                let res = decode(data);
                if (res.success) {
                    if (_.isUndefined(res.source)) {
                        this.setState({ started: true, url: res.url });
                    } else {
                        const blob = new Blob([res.source], { type: 'text/html' });
                        const url = URL.createObjectURL(blob);
                    }
                } else {
                    sendNotfication(this.props.t('issue_in_game_provider'), 'success', 'bottom-left');
                }
            });
        } else {
            Event.emit('login_form');
        }
    }

    render() {
        // const background = 'https://app.betsmixer.com/assets/slotegrator/' + encodeURI(this.state.game.provider) + '/' + this.state.game.image;
        const background = this.state.game.origin_url;
        const { t } = this.props;

        return (
            <>
                {this.state.started ?
                    <>
                    <div style={{display: "flex", width: "100%"}}>
                        <div  className="mobile-only" style={{width: 20, zIndex: 100}} onTouchStart={this.showButton} onTouchEnd={this.hideButton}></div>
                        <iframe className={'fullScreen'} src={this.state.url}></iframe>
                        <div  className="mobile-only" style={{width: 20, zIndex: 100, marginLeft: 'auto'}} onTouchStart={this.showButton} onTouchEnd={this.hideButton}></div>
                        {this.state.visible && (
                        <button
                            className="mobile-only"
                            onClick={this.handleScroll}
                            style={{
                            position: 'fixed',
                            bottom: 60,
                            right: 10,
                            width: '42px', // Specific width for the semi-circle
                            height: '40px', // Half of the width to form a perfect semi-circle
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '30px 0 0 30px', // Full border-radius on one side
                            cursor: 'pointer',
                            zIndex: 300
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="30px" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                        </button>)}
                    </div>

                    </>
                    // <iframe className={'fullScreen'} srcDoc={this.state.iframe_source}></iframe>
                    :
                    <div style={{ background: `url(${background}) center center / 100% no-repeat` }} className="sc-jEUCeg bSWQnZ">
                        <div className="mask">
                            <div className="tips">
                                <p className="ftw">
                                    {t('third_party_game_description_1')}
                                    <br />
                                    {t('third_party_game_description_2')}
                                    <br />
                                    {this.state.message}
                                </p>
                                <div className="btn-wrap">
                                    <button className="sc-kEjbxe sc-crrsfI cLOWRl jcNNmq btn btn-normal realplay" onClick={this.startReal}>
                                        <div className="button-inner">
                                            {this.state.isRealLoading ?
                                                <>
                                                    <div className="spinner-border spinner-slots-real spinner-border-sm text-white mr-1" role="status" />
                                                </>
                                                :
                                                <>
                                                    <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                                </>
                                            }
                                            {t('real_play')}
                                            <span></span>
                                        </div>
                                    </button>
                                    {this.state.game.featured_game !== '1' && <button className="sc-kEjbxe sc-iqHYGH cLOWRl fRWXki btn btn-normal demoplay" onClick={this.startDemo}>
                                        <div className="button-inner">
                                            {this.state.isDemoLoading ?
                                                <>
                                                    <div className="spinner-border spinner-slots spinner-border-sm text-white mr-1" role="status" />
                                                </>
                                                :
                                                <>
                                                    <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                                </>
                                            }
                                            {t('demo_play')}
                                            <span></span>
                                        </div>
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

Frame.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => (
    {
        coin: state.items.coin,
        credit: state.items.credit,
        ipAddress: state.ip.ipAddress,
    });

export default connect(mapStateToProps, { gameCoin })(Frame);
