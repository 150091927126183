import React from "react";
import { __, Event, isMobile, isTablet, playAudio } from "../../../Helper";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      canvas: null,
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    engine.on("play", (data) => this.play(data));
    engine.on("busted", (data) => this.busted());
    Event.on("loading_blackjack_done", () => this.progress());
    Event.on("hit", () => this.hit());
    Event.on("stand", () => this.stand());
    Event.on("set_split", () => this.split());
    Event.on("set_insurance", () => this.insurance());

    var canvas = new Canvas(engine);
    canvas.init();

    this.setState({ canvas: canvas });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  progress() {
    this.setState({ loading: false });
  }

  play(data) {
    if (this._isMounted) {
      this.state.canvas.deal(data.target);

      // if the user back to the game, game still continue
      if (data.history) {
        for (var i = 0; i < data.history.length; i++) {
          let actions = data.history[i];

          if (actions === "hit") {
            this.state.canvas.hit();
          } else if (actions === "split") {
            this.state.canvas.split();
          } else if (actions === "insurance") {
            this.state.canvas.insurance();
          }
        }
      }
    }
  }

  hit() {
    if (this._isMounted) {
      this.state.canvas.hit();
    }
  }

  split() {
    if (this._isMounted) {
      this.state.canvas.split();
    }
  }

  insurance() {
    if (this._isMounted) {
      this.state.canvas.insurance();
    }
  }

  stand() {
    if (this._isMounted) {
      this.state.canvas.stand();
    }
  }

  busted() {
    if (this._isMounted) {
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn blackjack-table">
          {this.state.loading && (
            <div className="game-loader text-center h300 ycenter">
              <div
                className="spinner-grow text-purple text-info my-3"
                role="status"
              />
            </div>
          )}
          <canvas
            style={{ display: this.state.loading ? "none" : "" }}
            id="game"
          />
        </div>
      </>
    );
  }
}

export default Game;
