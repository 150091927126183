import React from "react";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  Event,
  forceSatoshiFormat,
  isValidNumber,
  wait,
  isLogged,
  funPlay,
  sendNotfication,
  playAudio,
  defaultBetAmount,
  getDefaultBetAmount,
  __,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      showCashOut: false,
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
      inputDisabled2: true,
      buttonText: "Bet",
      tprofit: forceSatoshiFormat(0.001),
      hprofit: forceSatoshiFormat(0.001),
      lprofit: forceSatoshiFormat(0.001),
      firstWin: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    this.props.gameCoin();
    engine.on("play", () => this.play());
    engine.on("busted", () => this.busted());
    engine.on("next", (data) => this.next(data));
    engine.on("error", (data) => this.error(data));
    engine.on("lost", () => this.lost());
    engine.on("stop_playing", () => this.stopManual());
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    this.setState({
      started: false,
      buttonText: "Bet",
      inputDisabled: false,
    });
  }

  handleBet(e) {
    e.preventDefault();
    let { amount, showCashOut } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    } else {
      amount = Math.min(
        parseFloat(storage.getKey("credit")),
        parseFloat(amount)
      );
      this.setState({ amount: forceSatoshiFormat(amount) });
    }

    this.setState({ inputDisabled: true });

    if (showCashOut) {
      this.cashOut();
    } else {
      this.placeBet();
    }
  }

  play() {
    if (this._isMounted) {
      this.setState({
        showCashOut: true,
        inputDisabled2: false,
        inputDisabled: false,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  cashOut() {
    if (this._isMounted) {
      let { amount, tprofit, engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.profit = tprofit;
        engine.coin = this.props.coin;
        engine.cashOut();
        playAudio("win.mp3");
      });
    }
  }

  busted() {
    if (this._isMounted) {
      this.setState({
        showCashOut: false,
        inputDisabled: false,
        inputDisabled2: true,
        firstWin: true,
      });
      this.setState({
        tprofit: this.state.amount,
        lprofit: this.state.amount,
        hprofit: this.state.amount,
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;

    this.setState({ tprofit: value, hprofit: value, lprofit: value });

    this.setState({ [target.name]: value });
  }

  setAmount = (val) => {
    var active = this.state.showCashOut ? true : false;
    if (!active) {
      var am = val;
      if (!__.isNaN(am)) {
        this.setState({ amount: am });
        storage.setKey("lam", am);
        this.setState({ tprofit: am, hprofit: am, lprofit: am });
      }
    }
  };

  skip = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      this.setState({ inputDisabled2: true, inputDisabled: true });
      let { engine } = this.state;
      engine.next("skip");
    }
  };

  higher = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      this.setState({ inputDisabled2: true, inputDisabled: true });
      let { engine } = this.state;
      engine.next("low");
    }
  };

  lower = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      this.setState({ inputDisabled2: true, inputDisabled: true });
      let { engine } = this.state;
      engine.next("high");
    }
  };

  next = (data) => {
    if (this._isMounted) {
      const { win } = data;

      this.setState({
        inputDisabled2: false,
        inputDisabled: true,
        firstWin: false,
      });

      if (win) {
        if (!this.state.firstWin) {
          this.setState({
            inputDisabled: false,
            tprofit: this.state.tprofit * 2,
            lprofit: this.state.lprofit * 2,
            hprofit: this.state.hprofit,
          });
        }
      }
    }
  };

  lost = () => {
    if (this._isMounted) {
      this.setState({
        inputDisabled: false,
        showCashOut: false,
        inputDisabled2: true,
      });
      playAudio('lost.mp3');
    }
  };

  render() {
    let { inputDisabled, inputDisabled2, showCashOut, buttonText } = this.state;
    return (
      <>
        <form
          className="w-100 hilo-form"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Col md={12} sm={12} className="m-auto">
            <Row>
              <Col md={3} sm={12}>
                <div className={"hhilo form-group rev mb-3"}>
                  <Button
                    variant={
                      "btn btn-md btn-block mt-2 btn-bet btn-bet-success"
                    }
                    disabled={inputDisabled}
                    type="submit"
                  >
                    {showCashOut ? "CashOut" : "BET"}
                  </Button>
                </div>
              </Col>
              <Col md={3} xl={3} className={"col-4"}>
                <div className={"form-group rev mb-3"}>
                  <Button
                    variant={"btn btn-md btn-block mt-2 btn-hilo"}
                    disabled={inputDisabled2}
                    onClick={(e) => this.lower(e)}
                  >
                    <i
                      className={
                        "mdi mdi-clipboard-arrow-up mr-2 text-success align-middle font-17"
                      }
                    />
                    Higher Or Some
                  </Button>
                </div>
              </Col>
              <Col md={3} xl={3} className={"col-4"}>
                <div className={"form-group rev mb-3"}>
                  <Button
                    variant={"btn btn-md btn-block mt-2 btn-hilo"}
                    disabled={inputDisabled2}
                    onClick={(e) => this.higher(e)}
                  >
                    <i
                      className={
                        "mdi mdi-clipboard-arrow-down mr-2 text-danger align-middle font-17"
                      }
                    />
                    Lower Or Some
                  </Button>
                </div>
              </Col>

              <Col md={3} xl={3} className={"col-4"}>
                <div className={"form-group rev mb-3"}>
                  <Button
                    variant={"btn btn-md btn-block mt-2 btn-hilo"}
                    disabled={inputDisabled2}
                    onClick={(e) => this.skip(e)}
                  >
                    <i
                      className={
                        "mdi mdi-arrow-right-bold-circle mr-2 align-middle font-17"
                      }
                    />
                    Skip
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={12} sm={12} className="m-auto">
            <Row>
              <Col md={6} sm={12}>
                <InputGroup className="my-1">
                  <InputGroup.Text>
                    <img
                      src={
                        "/assets/images/" +
                        __.toLower(this.props.coin) +
                        ".png"
                      }
                      className={"mini-coin-7"}
                      alt="COIN"
                    />
                    BET
                  </InputGroup.Text>
                  <Form.Control
                    ref={this.outRef}
                    disabled={showCashOut ? true : false}
                    type="text"
                    className="form-control text-left"
                    id="amount"
                    name="amount"
                    placeholder=".."
                    value={this.state.amount}
                    autoComplete={"off"}
                    onKeyUp={this.handleInputChange}
                    onChange={this.handleInputChange}
                  ></Form.Control>
                  <InputGroup.Text>
                    <RangeCredit
                      set={this.setAmount}
                      amount={this.state.amount}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>

              <Col md={6} sm={12}>
                <InputGroup className="my-1">
                  <InputGroup.Text>Total Profit</InputGroup.Text>
                  <Form.Control
                    disabled={true}
                    type="text"
                    className="form-control text-left"
                    name="tprofit"
                    value={forceSatoshiFormat(this.state.tprofit / 3)}
                  ></Form.Control>
                  <InputGroup.Text>
                    <img
                      src={
                        "/assets/images/" +
                        __.toLower(this.props.coin) +
                        ".png"
                      }
                      className={"mini-coin-2"}
                      alt=""
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>

              <Col md={6} sm={12}>
                <InputGroup className="my-1">
                  <InputGroup.Text>
                    <i className={"mdi mdi-arrow-up-bold text-success"} /> High
                    Profit
                  </InputGroup.Text>
                  <Form.Control
                    disabled={true}
                    type="text"
                    className="form-control text-left"
                    name="hprofit"
                    value={forceSatoshiFormat(this.state.hprofit / 3)}
                  ></Form.Control>
                  <InputGroup.Text>
                    <img
                      src={
                        "/assets/images/" +
                        __.toLower(this.props.coin) +
                        ".png"
                      }
                      className={"mini-coin-2"}
                      alt=""
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>

              <Col md={6} sm={12}>
                <InputGroup className="my-1">
                  <InputGroup.Text>
                    <i className={"mdi mdi-arrow-down-bold text-danger"} /> Low
                    Profit
                  </InputGroup.Text>
                  <Form.Control
                    disabled={true}
                    type="text"
                    className="form-control text-left"
                    name="lprofit"
                    value={forceSatoshiFormat(this.state.lprofit / 3)}
                  ></Form.Control>
                  <InputGroup.Text>
                    <img
                      src={
                        "/assets/images/" +
                        __.toLower(this.props.coin) +
                        ".png"
                      }
                      className={"mini-coin-2"}
                      alt=""
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
