import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Card, InputGroup, Form } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import { setRisk } from "../../../actions/gameOption";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  Event,
  isValidNumber,
  forceSatoshiFormat,
  isLogged,
  sendNotfication,
  getDefaultBetAmount,
  wait,
  playAudio,
  funPlay,
  __,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      started: false,
      risk: "medium",
      amount: defaultBetAmount(this.props.coin),
      credit: storage.getKey("credit"),
      rollDiceDisabled: false,
      cashoutDisabled: true,
      buttonText: "Roll Dice",
      profit: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef2 = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this.props.gameCoin();
    engine.on("play", (data) => this.play(data));
    engine.on("clicked", (data) => this.clicked(data));
    engine.on("cashout", (data) => this.cashout(data));
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    this._isMounted = false;
    engine.init = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  handleRisk = (e, value) => {
    if (this._isMounted) {
      e.preventDefault();
      if (this.state.started) return;
      for (var i = 0; i < this.wrapperRef2.current.children.length; i++) {
        this.wrapperRef2.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      if (this.state.inputDisabled) return;

      this.setState({ risk: value });
      this.props.setRisk(value);
      Event.emit("risk", value);
      this.state.engine.risk = value;
    }
  };

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ rollDiceDisabled: false, cashoutDisabled: true });
      this.setState({
        started: false,
        buttonText: "Roll Dice",
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
    Event.emit("set_amount", value);
  }

  handleBet(e) {
    e.preventDefault();
    let { amount, bet, started } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ rollDiceDisabled: true, cashoutDisabled: true });

    if (started) {
      this.rollDice();
    } else {
      this.placeBet();
    }
  }

  handleCashout(e) {
    if (this._isMounted) {
      let { engine, amount, profit } = this.state;
      this.setState({ started: false });

      wait(100).then(() => {
        engine.init = true;
        engine.profit = profit;
        engine.cashout();
      });
    }
  }

  rollDice() {
    let { engine, profit } = this.state;
    wait(100).then(() => {
      engine.init = true;
      engine.profit = profit;
      engine.next();
    });
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount, risk } = this.state;
      this.setState({ started: true });
      wait(100).then(() => {
        engine.init = true;
        engine.risk = risk;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  cashout(data) {
    if (this._isMounted) {
      this.setState({
        rollDiceDisabled: false,
        cashoutDisabled: true,
        started: false,
        profit: 0,
      });
      this.state.engine.init = false;
      playAudio("win.mp3");
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { engine, bet } = this.state;

      wait(100).then(() => {
        this.setState({
          rollDiceDisabled: false,
          cashoutDisabled: true,
          started: false,
          profit: 0,
        });
        engine.init = false;
      });
    }
  }

  clicked(data) {
    if (this._isMounted) {
      this.setState({ rollDiceDisabled: false, cashoutDisabled: false });
      this.setState({
        profit: parseFloat(this.state.profit) + parseFloat(data.profit),
      });
    }
  }

  play(data) {
    if (this._isMounted) {
    }
  }

  setAmount = (value) => {
    if (!this.state.rollDiceDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        Event.emit("set_amount", value);
      }
    }
  };

  render() {
    let { rollDiceDisabled, cashoutDisabled, amount } = this.state;

    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} className={"m-auto"}>
            <Card className={"mb-0"}>
              <Card.Body className={"px-2 pt-2 py-0"}>
                <Row>
                  <Col xl={6} md={8} sm={12}>
                    <InputGroup className="mb-1">
                      <InputGroup.Text>
                        <img
                          src={
                            "/assets/images/" +
                            __.toLower(this.props.coin) +
                            ".png"
                          }
                          className={"mini-coin-7"}
                          alt=""
                        />
                        BET
                      </InputGroup.Text>
                      <Form.Control
                        ref={this.outRef}
                        disabled={rollDiceDisabled}
                        className="form-control text-left"
                        type="text"
                        id="amount"
                        name="amount"
                        placeholder=".."
                        value={amount}
                        autoComplete={"off"}
                        onKeyUp={this.handleInputChange}
                        onChange={this.handleInputChange}
                      ></Form.Control>
                      <InputGroup.Text>
                        <RangeCredit
                          set={this.setAmount}
                          amount={this.state.amount}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>

                  {/* 
                  <Col xl={6} md={4} sm={12}>
                    <InputGroup className="mb-1 bg-form">
                      <InputGroup.Text>Risk</InputGroup.Text>
                      <InputGroup.Text ref={this.wrapperRef2}>
                        <button
                          onClick={(e) => {
                            this.handleRisk(e, "medium");
                          }}
                          className={"btn btn-s-4 btn-sm active"}
                        >
                          Medium
                        </button>
                        <button
                          onClick={(e) => {
                            this.handleRisk(e, "high");
                          }}
                          className={"btn btn-s-4 btn-sm"}
                        >
                          High
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </Col> */}

                  <Col xl={6} md={4} sm={12}>
                    <InputGroup className="mb-1 bg-form">
                      <InputGroup.Text>Risk</InputGroup.Text>
                      <InputGroup.Text ref={this.wrapperRef2}>
                        <button
                          onClick={(e) => {
                            this.handleRisk(e, "medium");
                          }}
                          className={"btn btn-s-4 btn-sm active"}
                        >
                          Medium
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className={"m-auto"}>
                    <Button
                      variant={"btn my-1 btn-block btn-bet btn-sm"}
                      onClick={(e) => {
                        this.handleBet(e);
                      }}
                      disabled={rollDiceDisabled}
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  </Col>
                  <Col md={6} className={"m-auto"}>
                    <Button
                      variant={"btn my-1 btn-block btn-bet btn-sm"}
                      onClick={(e) => {
                        this.handleCashout(e);
                      }}
                      disabled={cashoutDisabled}
                      type="submit"
                    >
                      Cashout {forceSatoshiFormat(this.state.profit)}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

Bet.propTypes = {
  setRisk: PropTypes.number,
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { setRisk, gameCoin, setWallet })(Bet);
