import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
  }

  handleShow() {
    this.setState({ show: true, effect: "zoomIn" });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <button
          onClick={this.handleShow}
          className={
            "game-header-btn-topbar btn btn-sm bg-cs2 cpt animated fadeInDown helpbutton"
          }
          data-tip="Help"
          data-place="left"
        >
          <i className={"mdi mdi-help-circle font-15"} />
        </button>
        <Modal
          size="lg"
          scrollable={true}
          backdrop="static"
          centered={true}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-lg-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header className="Header">
            {!this.props.content && "Bets Wagered"}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className="modal-helper">
            {this.props.content && (
              <h4 className="text-white text-success titlegames">
                {this.props.image}{" "}
                <span className="text-yellow titlegameinfo">(In-House)</span>
              </h4>
            )}

            <p className={"font-light font-15 text-white"}>
              {this.props.content
                ? this.props.content
                : t("slot_game_help_content_2")}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Help;
