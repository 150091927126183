import React, { Component } from "react";
import { Alert, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Scripts from "./Script";
// import Scripts from "./_Script";

class AutoBet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { isLogged, engine } = this.props;
    return (
      <>
        {isLogged ? (
          <Card>
            <Card.Body>
              <Scripts engine={engine} />
            </Card.Body>
          </Card>
        ) : (
          <Alert className="alert-purple text-darker rounded-0 mx-2 mt-4">
            You must be logged to use this option.
          </Alert>
        )}
      </>
    );
  }
}

export default AutoBet;
