import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat, wait } from "../../../Helper";
/**
 * Game Engine
 */
function Engine() {
  var self = this;

  /**
   * Game Prefix
   */
  self.prefix = "_MINE";

  /**
   * Game Name
   */
  self.game = "mine";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Itialize Game
   */
  self.trigger = new MyEmitter();

  /**
   * Itialize Game
   */
  self.init = false;

  /**
   * Game Status
   */
  self.gameStatus = null;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Round Profit
   */
  self.profit = null;

  /**
   * Number of Mines
   */
  self.mine = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /**
   * Selected Land
   */
  self.land = null;

  /*
   * Start Engine
   */
  self.started = true;

  /**
   * Bets Number
   */
  self.betNumber = 10;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Set Game to Auto Play
   */
  self.setAuto = false;

  /**
   *  Playing Rounds
   */
  self.played = 0;

  /**
   * Lands for auto playing
   */
  self.autoLands = [];

  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_MINE, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_MINE);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_MINE,
    encode({
      command: "play",
      amount: self.amount,
      mine: self.mine,
      coin: self.coin,
    })
  );
};

/**
 * Clicked On the Land
 */
Engine.prototype.clicked = function () {
  let self = this;
  self.ws.emit(
    C.PLAY_MINE,
    encode({
      command: "clicked",
      land: parseInt(self.land),
    })
  );
};

/**
 * CashOut
 */
Engine.prototype.cashout = function () {
  let self = this;
  self.ws.emit(
    C.PLAY_MINE,
    encode({
      command: "cashout",
      game: self.game,
      profit: self.profit,
    })
  );
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
  let self = this;

  if (!self.init) return;

  self.played++;

  // Increase Amount on Win (player set increase amount on win in %)
  if (self.onWin !== 0) {
    // It Mean Player has win in this round
    if (self.isWin) {
      self.amount *= parseInt(self.onWin);
    }
  }

  // Increase Amount on Lost (player set increase amount on lost in %)
  if (self.onLost !== 0) {
    // It Mean Player has lost in this round
    if (!self.isWin) {
      self.amount *= parseInt(self.onLost);
    }
  }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.roundProfit) >= forceSatoshiFormat(self.stopOnWin)
    ) {
      self.played = 0;
      self.roundProfit = 0;
      self.trigger.emit("stop");
      return;
    }
  }

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  if (self.played === 1) {
    self.ws.emit(
      C.PLAY_MINE,
      encode({
        command: "play",
        amount: self.amount,
        mine: self.mine,
        coin: self.coin,
      })
    );
  } else {
    wait(1200).then(() => {
      self.ws.emit(
        C.PLAY_MINE,
        encode({
          command: "clicked",
          land: self.autoLands,
        })
      );
    });
  }
};

/** Start Game Engine **/
export default Engine;
