import React from 'react';
import { isMobile, wait, secureRandomNumber } from "../../../Helper";

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: null,
            font: null,
            number: Math.floor(secureRandomNumber() * 10).toFixed(2)
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (isMobile()) {
            this.setState({ height: '18rem', font: '2.6875rem' });
        }
    }

    render() {
        return (
            <>
                <div className="limbo-content" id="limbo-game-canvas" style={{ minHeight: this.state.height }}>
                    <div className="game-rocket notranslate animated zoomIn">
                        <div className="rocket-number animated zoomIn" style={{ fontSize: this.state.font }}>
                            {this.state.number}
                            <span className="gv-x">×</span>
                        </div>
                    </div>
                    <div className="rocket">
                        <div className="rocket-body">
                            <div className="body"></div>
                            <div className="fin fin-left"></div>
                            <div className="fin fin-right"></div>
                            <div className="window"></div>
                        </div>
                        <div className="exhaust-flame"></div>
                        <ul className="exhaust-fumes">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <ul className="star">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default Game;
