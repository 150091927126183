import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { __, wait } from "../../../Helper";

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 500,
      height: 300,
      resolution: 1,
      backgroundAlpha: false,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("hilo", this.app);
    this.cards = [];
    this.sub_cards = [];
    this.currentCard = null;
    this.container = null;
    this.round = 0;
  }

  init() {
    let images = [
      "/assets/images/hilo/back.png",
      "/assets/images/hilo/sub.png",
    ];

    const SUIT = ["C", "D", "H", "S"];

    for (var i = 1; i < 14; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = "/assets/images/video-poker/new/" + i + SUIT[j] + ".png";
        images.push(asset);
      }
    }

    const NAMES = ["A", "J", "K", "Q"];

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        var asset =
          "/assets/images/video-poker/new/" + NAMES[j] + SUIT[i] + ".png";
        images.push(asset);
      }
    }
    this.loader.add([images]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    this.engine.emit("ready");
    this.res = res;
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true;
    this.container.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configCards();
    this.configSub();
    this.configSubCards();
    this.configAnim();
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        count -= 0.05;
      }
    });
  }

  configCards() {
    for (var i = 0; i < 3; i++) {
      let c = PIXI.Sprite.from("/assets/images/hilo/back.png");
      let space = 185;
      let x = (this.app.screen.width / 3) * (i / 2.3) + space;
      let y = this.app.screen.height / 3;
      c.x = x;
      c.y = y;
      if (i === 1) {
        c.zIndex = 1;
        this.currentCard = c;
        c.width = 140;
        c.height = 201;
      }
      c.anchor.set(0.5);
      this.cards.push(c);
      Effect.FadeIn(c, 0.7 * i);
      this.container.removeChild(c);
      this.container.addChild(c);
    }
  }

  configSub() {
    this.sub = PIXI.Sprite.from("/assets/images/hilo/sub.png");
    this.sub.width = 450;
    this.sub.height = 85;
    this.sub.x = this.app.screen.width / 2;
    this.sub.y = 240;
    this.sub.anchor.set(0.5);
    this.container.addChild(this.sub);
  }

  configSubCards() {
    for (var i = 0; i < 7; i++) {
      let c = PIXI.Sprite.from("/assets/images/hilo/back.png");
      c.x = -17 * (i / 4) + 12.8;
      c.y = 0;
      c.width = 4;
      c.height = 24;
      c.zIndex = 1;
      c.anchor.set(0.5);
      this.sub_cards.push(c);
      Effect.FadeIn(c, 0.7);
      this.sub.addChild(c);
    }
  }

  showCard() {
    this.currentCard.texture = PIXI.Texture.from(
      `/assets/images/video-poker/new/${this.result}.png`
    );
    this.currentCard.width = 125;
    this.currentCard.height = 181;
    this.currentCardNumber = this.result;
    Effect.ZoomIn(this.currentCard, 0.5, 0.5);
    this.round++;
  }

  next(result, isLost = false) {
    this.result = result;
    let c = PIXI.Sprite.from(
      `/assets/images/video-poker/new/${this.currentCardNumber}.png`
    );
    c.x = -17 * (this.sub_cards.length / 4) + 12.8;
    c.y = 0;
    c.width = 3.5;
    c.height = 22;
    c.zIndex = 1;
    c.anchor.set(0.5);
    Effect.FadeIn(c, 0.3);
    this.sub_cards.push(c);
    this.sub.addChild(c);
    this.showCard();

    if (this.sub_cards.length > 6) this.sub_cards.shift();

    if (isLost) {
      this.busted();
    }
  }

  play(rounds, result) {
    if (this.started) return;

    this.started = true;
    this.result = result;

    this.showCard();

    for (var i = 0; i < this.sub_cards.length; i++) {
      this.sub.removeChild(this.sub_cards[i]);
    }
    this.sub_cards = [];

    // back on the game
    if (rounds !== 0) {
      for (var i = 0; i < rounds.length; i++) {
        this.next(rounds[i]);
      }
    }
  }

  busted(card) {
    this.started = false;

    this.currentCard.texture = PIXI.Texture.from(
      `/assets/images/video-poker/new/${card}.png`
    );

    wait(500).then(() => {
      for (var i = 0; i < this.cards.length; i++) {
        let card = this.cards[i];
        this.container.removeChild(card);
      }

      this.configCards();
      this.configSubCards();
      Effect.ZoomIn(this.currentCard, 1.1, 0.5);
    });
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
