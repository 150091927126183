import React from "react";
import { Button, InputGroup, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  isMobile,
  Event,
  wait,
  isValidNumber,
  getDefaultBetAmount,
  forceSatoshiFormat,
  __,
  isLogged,
  sendNotfication,
  playAudio,
  cryptoToUsd,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      buttonText: "BET",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      total: forceSatoshiFormat(
        storage.getKey("lam") ? storage.getKey("lam") : 0.001
      ),
      disabled: false,
      payout: "101",
      type: "low",
      betNumber: 10,
      firstBetNumber: 10,
      onWin: 0,
      onLost: 0,
      stopOnWin: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine, amount } = this.state;
    this._isMounted = true;
    this.props.gameCoin();
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    Event.on("add_amount", (data) => this.totalBet(data));
    Event.on("clear_amount", () => this.clearAmount());

    wait(1000).then(() => {
      Event.emit("change_amount", forceSatoshiFormat(amount));
    });

    if (isMobile()) {
      this.setState({ mt: "mt-2" });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    this.setState({ started: false, disabled: false });
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });
    }
  }

  totalBet(amount) {
    if (this._isMounted) {
      var plus = parseFloat(amount) + parseFloat(this.state.total);

      if (cryptoToUsd(plus, this.props.coin) > 100) return;

      this.setState({ total: forceSatoshiFormat(plus) });
    }
  }

  clearAmount() {
    if (this._isMounted) {
      this.setState({ total: forceSatoshiFormat(0.0) });
    }
  }

  busted(data) {
    if (this._isMounted) {
      let isWin = false;

      if (parseFloat(data.profit) !== 0) {
        isWin = true;
      }

      wait(1000).then(() => {
        this.setState({ disabled: false });
      });

      wait(500).then(() => {
        if (isWin) {
          playAudio("win.mp3");
        } else {
          // playAudio('lost.mp3');
        }
      });
    }
  }

  setBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount } = this.state;

      // Check User
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      this.setState({ disabled: true });

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.placeBet();
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  handleChangeType = (value) => {
    this.setState({ type: value });
  };

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;

    storage.setKey("lam", value);
    Event.emit("change_amount", value);

    this.setState({ [target.name]: value });
  }

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        Event.emit("change_amount", forceSatoshiFormat(value));
      }
    }
  };

  render() {
    let { disabled, amount } = this.state;
    return (
      <>
        <Form onSubmit={(e) => this.setBet(e)}>
          <Row className={"mt-1"}>
            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  Chip Amount
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                  required={true}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit set={this.setAmount} amount={amount} />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  TOTAL BET
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={this.state.total}
                ></Form.Control>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md={5} sm={12} className="m-auto">
              <div className={"form-group rev mt-2 mb-0"}>
                <Button
                  variant={"btn btn-block btn-bet btn-xl mb-2"}
                  id={"bet"}
                  disabled={disabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
