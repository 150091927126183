import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import storage from "../../../Storage";
import { searchBar } from "../../../actions/searchGame";
import {
  isLogged,
  decode,
  __,
  Event,
  filterSlotTitle,
  wait,
  isMobile,
} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Recent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      home_games: [],
      slot_games: [],
    };
  }

  componentWillMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (!isLogged) {
      this.props.history.push("../register");
    } else {
      socket.emit(C.RECENT);

      socket.on(C.RECENT, (data) => {
        let { home_games, slot_games } = decode(data);

        this.setState({ home_games, slot_games });
      });
    }
  }

  render() {
    const homeGames = this.state.home_games.map((game, i) => (
      <MakeGame t={this.props.t} key={__.toString(i)} data={game} />
    ));
    const slotGames = this.state.slot_games.map((game, i) => (
      <MakeSlot t={this.props.t} key={__.toString(i)} data={game} />
    ));
    return (
      <>
        <Helmet>
          <title>Recent | Crypto Casino Games</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/recent" />
        </Helmet>
        <div>
          <Card>
            <Card.Body>
              <h2 className="font-17">In-House Games</h2>
              <Row>{homeGames}</Row>
              <hr />
              <h2 className="font-17">Slot Games</h2>
              <Row>{slotGames}</Row>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

class MakeGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.data,
    };
  }

  render() {
    let { mdCol, data, isMin, iPadd, chat_show, t } = this.props;
    let style;
    if (data.prefix === "crash") {
      mdCol = "28.5%";
      style = {
        width: "auto",
      };
    }

    return (
      <>
        {!data.disabled ? (
          <Col
            className={
              chat_show
                ? "game-items game-in-shrink"
                : "game-items game-in-expand"
            }
            style={{ maxWidth: mdCol, padding: iPadd }}
          >
            {/* <Col className={chat_show ? 'game-items game-in-shrink' : 'game-items game-in-expand'}> */}
            <Link
              to={data.prefix !== null ? "/" + data.prefix : "#"}
              className="game2-link"
            >
              <div
                className={
                  data.prefix !== null ? "game2-image" : "game2-image orange"
                }
              >
                <img src={data.image} className={"img-fluid"} alt={data.name} />
                <div className="game2-home-title">
                  {data.name ? <span>{data.name}</span> : ""}
                </div>
              </div>
            </Link>
          </Col>
        ) : (
          <></>
        )}
      </>
    );
  }
}

class MakeSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chat_show: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    Event.on("toggle_chat", (show) => {
      wait(200).then(() => {
        this.handleResize(show);
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize());
  }

  handleResize = (chat_show) => {
    this.bMobile = isMobile();
    const pageContent = document.getElementById("page-content");

    if (pageContent !== null) {
      const width = parseFloat(pageContent.offsetWidth);
      if (width < 630) {
        this.setState({ chat_show: chat_show, bMobile: true });
      } else {
        this.setState({ chat_show: chat_show, bMobile: isMobile() });
      }
    }
  };

  getGame(slot_provider) {
    Event.emit("toggle_chat", false, true);
    storage.setKey("slot_provider", slot_provider);
  }

  render() {
    let item = this.props.data;

    return (
      <>
        <Col
          className={
            this.state.chat_show
              ? "game-items game-in-shrink"
              : "game-items game-in-expand"
          }
          style={{ maxWidth: "14.2%" }}
          onClick={() => this.getGame(item.provider)}
        >
          <Link
            to={item.name !== null ? "/slots/" + item.uuid : "#"}
            className="slot-link slot-pan game-card-wrapper"
          >
            <div
              className={
                item.name !== null ? "slot-image" : "slot-image orange"
              }
            >
              <img
                src={item.origin_url}
                className="img-fluid"
                alt={item.name}
              />
            </div>
            <div className="slot-title">
              <span className="slot-t-name">{filterSlotTitle(item.name)}</span>
              <span className="slot-t-provider">{item.provider}</span>
            </div>
          </Link>
        </Col>
      </>
    );
  }
}

export default connect(null, { searchBar })(Recent);
