import _ from "lodash";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const baseName = (str) => _.toString(_.reverse(_.split(str, "/"))[0]);

let saveResource = [];

class Loader {
  constructor(game, app) {
    this.find = _.find(saveResource, function (o) {
      return o === game;
    });
    if (!this.find) saveResource.push(game);
    this.game = game;
    this.app = app;
    this.loaded = false;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;

    let style = new PIXI.TextStyle({
      fontFamily: "Tahoma",
      fontSize: 23,
      fontWeight: 300,
      fill: 0xffffff,
    });

    let style2 = new PIXI.TextStyle({
      fontFamily: "Tahoma",
      fontSize: 12,
      fontWeight: 300,
      fill: 0xffffff,
    });

    this.loading = new PIXI.Text("Loading " + "0%", style);
    this.loading.anchor.set(0.5);
    this.loading.x = this.centerX;
    this.loading.y = this.centerY;
    this.loading_sub = new PIXI.Text(" ", style2);
    this.loading_sub.anchor.set(0.5);
    this.loading_sub.x = this.centerX;
    this.loading_sub.y = this.centerY + 50;
    this.app.stage.addChild(this.loading, this.loading_sub);
    this.resource = [];
  }

  add(resource) {
    if (!this.find) {
      var check = _.find(this.resource, function (r) {
        return r === resource[0];
      });

      if (!_.isUndefined(check)) return;

      this.resource.push(resource[0]);

      this.app.loader.add(resource);
    } else {
      gsap.to(this.loading_sub, { duration: 0.2, pixi: { alpha: 0 } });
      gsap.to(this.loading, { duration: 0.2, pixi: { alpha: 0 } });
    }
  }

  set(name, resource) {
    if (!this.find) this.app.loader.add(name, resource);
    else {
      gsap.to(this.loading_sub, { duration: 0.2, pixi: { alpha: 0 } });
      gsap.to(this.loading, { duration: 0.2, pixi: { alpha: 0 } });
    }
  }

  addArray(assets) {
    if (!this.find) {
      const { path, names, resource } = assets;

      for (var i = 0; i < names.length; i++) {
        this.app.loader.add(names[i], path + "/" + resource[i]);
      }
    } else {
      gsap.to(this.loading_sub, { duration: 0.2, pixi: { alpha: 0 } });
      gsap.to(this.loading, { duration: 0.2, pixi: { alpha: 0 } });
    }
  }

  addSpin(name, resource) {
    this.app.loader.add(name, resource);
  }

  show(progress, res) {
    if (progress >= 99) {
      this.loading.text = "Loading 100%";
      this.loaded = true;

      this.loading.alpha = 1;
      gsap.to(this.loading, { duration: 0.2, pixi: { alpha: 0 } });

      this.loading_sub.alpha = 1;
      gsap.to(this.loading_sub, { duration: 0.2, pixi: { alpha: 0 } });

      wait(1200).then(() => {
        this.app.stage.removeChild(this.loading, this.loading_sub);
      });
    } else {
      this.loading.text = "Loading " + progress.toFixed(2) + "%";
      this.loading_sub.text = "[ " + baseName(res.url) + " ]";
    }
  }
}

export default Loader;
