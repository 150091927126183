import React, { Component } from 'react'
import storage from "../../../../../Storage";
import ReactTooltip from "react-tooltip";
import socket from "../../../../../Socket";
import { getUID, encode, decode, __ } from "../../../../../Helper";
import C from "../../../../../Constant";

class Favourites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: getUID,
            favourites: false,
        };
    }

    toggleFavourite = () => {
        this.setState({favourites: !this.state.favourites});
        socket.emit(C.UPDATEFAVOURITES, encode({gid: this.props.gid, state:!this.state.favourites}));
    }

    componentWillMount() {
        socket.emit(C.FAVOURITES);
        socket.on(C.FAVOURITES, data => {
            let favorites = decode(data);
            if (__.some(favorites, {id: this.props.gid})) {
                this.setState({favourites: decode(data)});
            }
            if (__.some(favorites, {uuid: this.props.gid})) {
                this.setState({favourites: decode(data)});
            }
        });

        socket.on(C.UPDATEFAVOURITES, data => {
            if (decode(data)) {
                // this.setState({favourites: !this.state.favourites});
            }  
        });
    }

    componentWillUnmount() {
        socket.off(C.FAVOURITES);
        socket.off(C.UPDATEFAVOURITES);
    }

    render() {
        return (
            <>
                <ReactTooltip />
                <button onClick={this.toggleFavourite} data-tip={'Toggle Favourite'} data-place="left"  className={"game-header-btn-topbar btn btn-sm bg-cs2 mr-1 cpt animated fadeInDown"}>
                    <i className={this.state.favourites ? 'mdi mdi-heart font-15' : 'mdi mdi-heart-outline font-15'} />
                </button>
            </>
        );
    }
}

export default Favourites;
