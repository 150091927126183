import React from "react";
import ReactTooltip from "react-tooltip";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Col, Row, Dropdown } from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {
  __,
  decode,
  encode,
  getElement,
  wait,
  Event,
  isMobile,
  sendNotfication,
  forceSatoshiFormat,
  removeTrailingZeros,
  getElementByXpath,
} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import Providers from "./Providers";
import CoinbaseProvider from "./CoinbaseProvider";
import CryptoProvider from "./CryptoProvider";
import C from "../../../../Constant";

class Deposit extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      currentCoin: "ETH",
      credits: {},
      list: [],
      content: [],
      wallet: "eth",
      deposit: true,
      slide: false,
      height: 573,
      margin: "mt-1",
      depositAddress: props.t("please_wait"),
      value: 0,
      deposit_settings: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    console.log("__.toLower(coin)", __.toLower("usdt_erc20"));
    this._isMounted = true;
    socket.emit(C.CREDIT, encode({ coin: "eth" }));
    socket.on(C.GET_ADDRESS, (data) => this.getAddress(decode(data)));
    socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));
    socket.emit(C.GET_DEPOSIT_SETTING);
    socket.on(C.GET_DEPOSIT_SETTING, (data) => {
      let { deposit_setting, deposit_fee } = decode(data);
      this.setState({ deposit_settings: deposit_setting, deposit_fee });
    });
    socket.on(C.NOTIFY_PAYMENT_CRYPTO, (data) => {
      let purchase = decode(data);
      sendNotfication(purchase.message, "success", "bottom-left");
    });

    Event.on("force_show_cards", () => {
      this.setState({ value: 2 });
    });

    Event.on("deposit_archive", () => {
      this.showArchive();
    });
    wait(400).then(() => {
      this.loadAddress(true, "ETH");
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.GET_ADDRESS);
    socket.off(C.NOTIFY_PAYMENT_CRYPTO);
    socket.off(C.CREDIT);
    socket.off(C.GET_DEPOSIT_SETTING);
  }

  getCreditAndCoins = (data) => {
    if (this._isMounted) {
      let { credit } = data;
      this.setState({ credits: credit });
      this.setUpCoins();
    }
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.toUpper(coin);
    });
    return find.min;
  };

  getNetwork = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.toUpper(coin);
    });
    return find.network;
  };

  getCoinName = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.toUpper(coin);
    });
    return find.name;
  };

  setUpCoins = () => {
    if (isMobile()) {
      this.setState({ height: 685, margin: "mt-1" });
    }
    this.setState({ list: [] });
    coins.forEach((item, i) => {
      if (item.preffix === "NC") return;

      let credit = forceSatoshiFormat(
        this.state.credits[__.toLower(item.preffix)]
      );

      let list = (
        <Dropdown.Item
          key={__.toString(i)}
          as={"button"}
          className={"animated fadeIn"}
          onClick={(e) => this.loadAddress(item.active, item.preffix)}
        >
          <span>
            <img
              src={"/assets/images/" + item.image}
              className={"img-fluid mini-coin mr-1"}
              alt="Coin"
            />{" "}
            {__.replace(item.preffix, "_", "-")}
          </span>
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
    });
  };

  showArchive = () => {
    if (this.state.slide) Event.emit("deposit_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(300).then(() => {
      this.setState({ deposit: !this.state.deposit });
    });

    wait(300).then(() => {
      this.loadAddress(true, "ETH");
    });
  };

  copyAddress(id) {
    var address = getElement("#" + id);
    address.select();
    address.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    sendNotfication(this.props.t("copied"), "success", "top-right");
  }

  loadAddress(active, coin) {
    if (this._isMounted) {
      this.setState({
        currentCoin: coin,
        depositAddress: this.props.t("please_wait"),
      });
      if (active) socket.emit(C.GET_ADDRESS, encode({ coin: coin }));
      else {
        this.setState({
          depositAddress: `${coin} ${this.props.t("not_supported_yet")}`,
        });
      }
    }
  }

  getAddress(data) {
    if (!data) return;
    let { address } = data;
    this.setState({ depositAddress: address });
  }

  otherCoins() {
    // show other coins modal
    Event.emit("other_coins");
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  render() {
    let size = "w-25";
    let font = "font-10";

    if (isMobile()) {
      size = "w-50";
    }

    const tipNotice = "deposit_minium_amount_note";
    const { t } = this.props;

    return (
      <>
        {this.state.deposit ? (
          this.state.deposit_settings != null ? (
            <>
              <AppBar position="static" color="transparent">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs"
                  centered
                  className="tabsdeposit"
                >
                  <Tab
                    icon={<i className={"mdi mdi-chart-bar deposittab"} />}
                    label={t("crypto_deposit_btn")}
                    {...this.a11yProps(0)}
                  />
                  <Tab
                    icon={
                      <i className={"mdi mdi-chart-areaspline deposittab"} />
                    }
                    label={"Coinbase"}
                    {...this.a11yProps(1)}
                  />
                  <Tab
                    className="buycrypto"
                    icon={
                      <i className={"mdi mdi-chart-areaspline deposittab"} />
                    }
                    label={t("buy_crypto_btn")}
                    {...this.a11yProps(2)}
                  />
                  <Tab
                    className="creditcarddeposit"
                    icon={<i className={"mdi mdi-chart-bar deposittab"} />}
                    label={t("credit_card_deposit_btn")}
                    {...this.a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <div className="pt-3 deposit-workspace">
                <SwipeableViews
                  axis={"x" === "rtl" ? "x-reverse" : "x"}
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                >
                  {this.state.deposit_settings ? (
                    <TabPanel value={this.state.value} index={0} dir={"x"}>
                      <Row className="m-0 deposittoppanel">
                        <Col className="col-7">
                          <div className="media">
                            <img
                              src={
                                "/assets/images/" +
                                __.replace(
                                  __.toLower(this.state.currentCoin),
                                  "_",
                                  "-"
                                ) +
                                ".png"
                              }
                              className="mr-3 thumb-sm align-self-center rounded-circle"
                              alt="Coin"
                            />
                            <div className="media-body align-self-center">
                              <div className="coin-bal">
                                <h4 className="m-0 text-white">
                                  {removeTrailingZeros(
                                    forceSatoshiFormat(
                                      this.state.credits[
                                        __.toLower(this.state.currentCoin)
                                      ]
                                    )
                                  ) || 0}
                                </h4>
                                <p className="text-muted mb-0">
                                  {this.getCoinName(this.state.currentCoin)}
                                  <span className="text-muted font-12">
                                    {" "}
                                    (
                                    {__.replace(
                                      this.state.currentCoin,
                                      "_",
                                      "-"
                                    )}
                                    )
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-5 text-right">
                          <Dropdown bottom="true">
                            <Dropdown.Toggle
                              split
                              align="end"
                              variant="mt-2 text-white"
                              id="dropdown-split-coins"
                            >
                              <span style={{ fontSize: 17 }}>
                                <img
                                  src={
                                    "/assets/images/" +
                                    __.replace(
                                      __.toLower(this.state.currentCoin),
                                      "_",
                                      "-"
                                    ) +
                                    ".png"
                                  }
                                  className={"img-fluid mini-coin-12 mr-2"}
                                  alt="Coin"
                                />
                                {__.replace(this.state.currentCoin, "_", "-")}
                              </span>
                              <span className="ml-1 nav-user-name">
                                <span className="caret"></span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className={
                                "droper clist-coins user-dropdown-detail"
                              }
                            >
                              {this.state.list}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <hr className="border-mod" />
                      <Row className="mb-1 m-0">
                        <Col className="ycenter" md="3" lg="3" sm="12">
                          <div className="text-center">
                            {/* <img
                              src={
                                "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
                                this.state.depositAddress
                              }
                              className={"img-thumbnail qri mt-2 " + size}
                              alt="Deposit Address"
                            /> */}
                            <img
                              src={
                                "https://qrcode.tec-it.com/API/QRCode?data=" +
                                this.state.depositAddress
                              }
                              className={"img-thumbnail qri mt-2 " + size}
                              alt="Deposit Address"
                            />
                          </div>
                        </Col>
                        <Col md="9" lg="9" sm="12" className="walletsection">
                          <h5 className="text-white text-left 15 my-2 deposittext">
                            <ReactTooltip />
                            {t("deposit_modal_t_1")}{" "}
                            <span className="text-yellow">
                              {this.state.currentCoin}{" "}
                            </span>{" "}
                            {t("deposit_modal_t_2")}{" "}
                            <span className="text-yellow minimumwallet font-13">
                              ( {t("minimum")}:{" "}
                              {this.getFee(this.state.currentCoin)} ){" "}
                              <i
                                data-tip={t(tipNotice)}
                                className="mdi mdi-help-circle font-15 float-right"
                              />
                            </span>
                          </h5>
                          <div className="input-group">
                            <div className="input-group-append btn-clipboard-deposit">
                              <button
                                onClick={(e) =>
                                  this.copyAddress(this.state.currentCoin)
                                }
                                type="button"
                                className="btn bg-cs no-shadow btn-clipboard buttonclipboard hv"
                              >
                                <i className="far fa-copy text-white" />
                              </button>
                            </div>
                            <input
                              type="text"
                              id={this.state.currentCoin}
                              className={"form-control mspace"}
                              style={{
                                background: "rgb(31 40 62)",
                                fontSize: 11,
                              }}
                              readOnly={true}
                              value={this.state.depositAddress}
                            />
                            <div className="input-group-append btn-clipboard-deposit">
                              <button
                                className={
                                  "bg-cs6 no-shadow btn-clipboard clipboardname hv npt " +
                                  font
                                }
                              >
                                {this.getNetwork(this.state.currentCoin)}
                              </button>
                            </div>
                          </div>
                          {this.state.currentCoin.toLowerCase() == "usdt" && (
                            <h5 className="text-white text-left 15 my-2 deposittext"></h5>
                          )}
                          <div className="bns">
                            <div className="c1 ctss">
                              {t("first_deposit_bonus")}
                            </div>
                            <div className="c2">1%</div>
                          </div>

                          { (this.state.currentCoin.toLowerCase() === 'usdt') && (
                            <div className="alert alert-warning-shadow mb-0 mt-1 fade bg-soft-warning bg-soft-warning-deposit show">
                              <div className="alert-text font-12">
                                <i className="dripicons-warning text-warning" />{" "}
                                Send assets only on the{" "}
                                {this.state.currentCoin.toLowerCase() === "usdt"
                                  ? "TRC-20"
                                  : "ERC-20"}{" "}
                                network to this address.
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <div className="depositinfo">
                        {isMobile() ? (
                          <></>
                        ) : (
                          <div className="alert bg-soft-info nhv mb-0 infodeposit">
                            <p className="text-white font-13 mb-0">
                              <span className="text-yellow">{t("note")} </span>
                              {t("deposit_note")}
                            </p>
                          </div>
                        )}
                        <span className="encryption">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="lock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="svg-inline--fa fa-lock fa-w-14"
                          >
                            <path
                              fill="currentColor"
                              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                            ></path>
                          </svg>
                          256-bit {t("encryption")}
                        </span>
                      </div>
                    </TabPanel>
                  ) : (
                    <TabPanel value={this.state.value} index={0} dir={"x"}>
                      <Providers t={t} />
                      <div className="depositinfo">
                        {isMobile() ? (
                          <></>
                        ) : (
                          <div className="alert bg-soft-info nhv mb-0 infodeposit">
                            <p className="text-white font-13 mb-0">
                              <span className="text-yellow">{t("note")} </span>
                              {t("deposit_note")}
                            </p>
                          </div>
                        )}
                        <span className="encryption">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="lock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="svg-inline--fa fa-lock fa-w-14"
                          >
                            <path
                              fill="currentColor"
                              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                            ></path>
                          </svg>
                          256-bit {t("encryption")}
                        </span>
                      </div>
                    </TabPanel>
                  )}
                  <TabPanel value={this.state.value} index={1} dir={"x"}>
                    <CoinbaseProvider t={t} />
                    <div className="depositinfo">
                      {isMobile() ? (
                        <></>
                      ) : (
                        <div className="alert bg-soft-info nhv mb-0 infodeposit">
                          <p className="text-white font-13 mb-0">
                            <span className="text-yellow">{t("note")} </span>
                            {t("deposit_note")}
                          </p>
                        </div>
                      )}
                      <span className="encryption">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="lock"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="svg-inline--fa fa-lock fa-w-14"
                        >
                          <path
                            fill="currentColor"
                            d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                          ></path>
                        </svg>
                        256-bit {t("encryption")}
                      </span>
                    </div>
                  </TabPanel>
                  <TabPanel value={this.state.value} index={2} dir={"x"}>
                    <CryptoProvider t={t} />
                    <div className="depositinfo">
                      {isMobile() ? (
                        <></>
                      ) : (
                        <div className="alert bg-soft-info nhv mb-0 infodeposit">
                          <p className="text-white font-13 mb-0">
                            <span className="text-yellow">{t("note")} </span>
                            {t("deposit_note")}
                          </p>
                        </div>
                      )}
                      <span className="encryption">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="lock"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="svg-inline--fa fa-lock fa-w-14"
                        >
                          <path
                            fill="currentColor"
                            d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                          ></path>
                        </svg>
                        256-bit {t("encryption")}
                      </span>
                    </div>
                  </TabPanel>
                  {this.state.deposit_settings === 1 ? (
                    <TabPanel value={this.state.value} index={3} dir={"x"}>
                      <Row className="m-0 deposittoppanel">
                        <Col className="col-8">
                          <div className="media">
                            <img
                              src={
                                "/assets/images/" +
                                __.toLower(this.state.currentCoin) +
                                ".png"
                              }
                              className="mr-3 thumb-sm align-self-center rounded-circle"
                              alt="Coin"
                            />
                            <div className="media-body align-self-center">
                              <div className="coin-bal">
                                <h4 className="m-0 text-white">
                                  {removeTrailingZeros(
                                    forceSatoshiFormat(
                                      this.state.credits[
                                        __.toLower(this.state.currentCoin)
                                      ]
                                    )
                                  )}
                                </h4>
                                <p className="text-muted mb-0">
                                  {this.getCoinName(this.state.currentCoin)}
                                  <span className="text-muted font-12">
                                    {" "}
                                    ({this.state.currentCoin})
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-4 text-right">
                          <Dropdown bottom="true">
                            <Dropdown.Toggle
                              split
                              align="end"
                              variant="mt-2 text-white"
                              id="dropdown-split-coins"
                            >
                              <span style={{ fontSize: 17 }}>
                                <img
                                  src={
                                    "/assets/images/" +
                                    __.toLower(this.state.currentCoin) +
                                    ".png"
                                  }
                                  className={"img-fluid mini-coin-12 mr-2"}
                                  alt="Coin"
                                />
                                {this.state.currentCoin}
                              </span>
                              <span className="ml-1 nav-user-name">
                                <span className="caret"></span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className={
                                "droper clist-coins user-dropdown-detail"
                              }
                            >
                              {this.state.list}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>

                      <hr className="border-mod" />

                      <Row className="mb-1 m-0">
                        <Col className="ycenter" md="3" lg="3" sm="12">
                          <div className="text-center">
                            {/* <img
                              src={
                                "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
                                this.state.depositAddress
                              }
                              className={"img-thumbnail qri mt-2 " + size}
                              alt="Deposit Address"
                            /> */}
                            <img
                              src={
                                "https://qrcode.tec-it.com/API/QRCode?data=" +
                                this.state.depositAddress
                              }
                              className={"img-thumbnail qri mt-2 " + size}
                              alt="Deposit Address"
                            />
                          </div>
                        </Col>
                        <Col md="9" lg="9" sm="12" className="walletsection">
                          <h5 className="text-white text-left 15 my-2 deposittext">
                            <ReactTooltip />
                            {t("deposit_modal_t_1")}{" "}
                            <span className="text-yellow">
                              {this.state.currentCoin}{" "}
                            </span>{" "}
                            {t("deposit_modal_t_2")}{" "}
                            <span className="text-yellow minimumwallet font-13">
                              ( {t("minimum")}:{" "}
                              {this.getFee(this.state.currentCoin)} ){" "}
                              <i
                                data-tip={t(tipNotice)}
                                className="mdi mdi-help-circle font-15 float-right"
                              />
                            </span>
                          </h5>
                          <div className="input-group">
                            <div className="input-group-append">
                              <button
                                onClick={(e) =>
                                  this.copyAddress(this.state.currentCoin)
                                }
                                type="button"
                                className="btn bg-cs no-shadow btn-clipboard buttonclipboard hv"
                              >
                                <i className="far fa-copy text-white" />
                              </button>
                            </div>
                            <input
                              type="text"
                              id={this.state.currentCoin}
                              className={"form-control mspace"}
                              style={{
                                background: "rgb(31 40 62)",
                                fontSize: 11,
                              }}
                              readOnly={true}
                              value={this.state.depositAddress}
                            />
                            <div className="input-group-append">
                              <button
                                className={
                                  "bg-cs6 no-shadow btn-clipboard hv npt " +
                                  font
                                }
                              >
                                {t("network")}{" "}
                                {this.getNetwork(this.state.currentCoin)}
                              </button>
                            </div>
                          </div>
                          {this.state.currentCoin.toLowerCase() == "usdt" && (
                            <h5 className="text-white text-left 15 my-2 deposittext">
                              <span
                                className="text-yellow"
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "normal",
                                }}
                              ></span>
                            </h5>
                          )}
                          <div className="bns">
                            <div className="c1 ctss">
                              {t("first_deposit_bonus")}
                            </div>
                            <div className="c2">1%</div>
                          </div>
                        </Col>
                      </Row>
                      <div className="depositinfo">
                        {isMobile() ? (
                          <></>
                        ) : (
                          <div className="alert bg-soft-info nhv mb-0 infodeposit">
                            <p className="text-white font-13 mb-0">
                              <span className="text-yellow">{t("note")} </span>
                              {t("deposit_note")}
                            </p>
                          </div>
                        )}
                        <span className="encryption">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="lock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="svg-inline--fa fa-lock fa-w-14"
                          >
                            <path
                              fill="currentColor"
                              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                            ></path>
                          </svg>
                          256-bit {t("encryption")}
                        </span>
                      </div>
                    </TabPanel>
                  ) : (
                    <TabPanel value={this.state.value} index={3} dir={"x"}>
                      <Providers t={t} />
                      <div className="depositinfo">
                        {isMobile() ? (
                          <></>
                        ) : (
                          <div>
                            <div className="alert bg-soft-info nhv mb-0 infodeposit">
                              <p className="text-white font-13 mb-0">
                                <span className="text-yellow">
                                  {t("note")}{" "}
                                </span>
                                {t("deposit_note")}
                              </p>
                            </div>

                            <div className="alert bg-soft-info nhv mb-0 infodeposit infodepositcard">
                              <img
                                src="/assets/images/assets/3ds.png"
                                class="mr-3 dslogo align-self-center"
                              />
                              <p className="text-white font-13 mb-0">
                                <span className="text-blue-deposit">
                                  Safe payment:
                                </span>{" "}
                                Please, keep in mind that we use ceriticated 3D
                                Secure code to process the deposit transaction
                                to protect deposit.
                              </p>
                            </div>
                          </div>
                        )}
                        <span className="encryption">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="lock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            className="svg-inline--fa fa-lock fa-w-14"
                          >
                            <path
                              fill="currentColor"
                              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                            ></path>
                          </svg>
                          256-bit {t("encryption")}
                        </span>
                      </div>
                    </TabPanel>
                  )}
                </SwipeableViews>
              </div>
            </>
          ) : null
        ) : (
          <div className={this.state.slide == false ? "animated fadeOut" : ""}>
            <DepositArchive t={t} />
          </div>
        )}
      </>
    );
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default Deposit;
