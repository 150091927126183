import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import List from "./List";

export default class HighRoller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  componentWillMount() {}

  render() {
    const { t } = this.props;

    return (
      <>
        <Card className="bg-transparent">
          <Card.Body>
            <Row>
              <Col sm={12} className="text-center">
                <img
                  className="img-fluid"
                  src="/assets/images/tournamments/autumn-festival.png"
                />
              </Col>

              <Col sm="12">
                <h1 className="font-30">
                  Drake's $500,000 High Roller Showdown
                </h1>
                <hr style={{ borderColor: "#555" }} />

                <h2 className="font-20">
                  Total Prize <span className="text-warning">$500,000</span>
                </h2>
                <h2 className="font-20">Duration:  3 month (90 days)</h2>
                <hr style={{ borderColor: "#555" }} />

                <h2 className="font-20">About</h2>
                <p className="font-14">
                  Drake is hosting an exclusive $500,000 tournament at BetsMixer,
                  aiming to attract more players to the casino. The more players
                  invited, the higher the winnings, encouraging participants to
                  spread the word and invite friends, family, and followers to
                  join the action.
                </p>

                <h2 className="font-20">Main Goal</h2>

                <p className="font-14">
                  Invite as many players as possible to join BetsMixer. The more
                  players you invite, the higher your chances of winning a share
                  of the $500,000 prize pool.
                </p>

                <h2 className="font-20">Prize Distribution</h2>

                <h3>Top Referrers (Invitation Leaders):</h3>

                <ul>
                  <li>
                    1st Place: <span className="text-warning">$100,000</span>
                  </li>
                  <li>2nd Place: $50,000</li>
                  <li>3rd Place: $25,000</li>
                  <li>4th-10th Place: $10,000 each</li>
                  <li>11th-50th Place: $2,500 each</li>
                </ul>

                <h3>Top Players (Activity Leaders):</h3>

                <ul>
                  <li>
                    1st Place: <span className="text-warning">$75,000</span>
                  </li>
                  <li>2nd Place: $40,000</li>
                  <li>3rd Place: $20,000</li>
                  <li>4th-10th Place: $7,500 each</li>
                  <li>11th-50th Place: $1,500 each</li>
                </ul>

                <h3>Random Draw Prizes:</h3>

                <ul>
                  <li>
                    100 players randomly drawn from the list of participants who
                    made at least one referral and participated in games: $1,000
                    each.
                  </li>
                </ul>

                <h3>Term & Conditions</h3>

                <ul>
                  <li>Players must be 18 years or older to participate.</li>
                  <li>
                    The tournament is subject to the casino's standard terms and
                    conditions.
                  </li>
                  <li>
                    Winnings are subject to verification and compliance with all
                    relevant regulations.
                  </li>
                </ul>

                <Link
                  to="/affiliate"
                  className="btn btn-lg btn-warning text-dark"
                >
                  Get my invite link
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <List t={t} />
      </>
    );
  }
}
