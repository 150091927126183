import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { secureRandomNumber } from "../../../Helper";

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const rand = (length) => Math.floor(secureRandomNumber() * length);

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "/assets/images/hashdice";
const SHEET_PATH = "/assets/images/hashdice/spritesheet";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 610,
      height: 180,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.number = [];
    this.container = null;
    this.started = false;
    this.container = null;
    this.type = "low";
    this.loader = new Loader("hashdice", this.app);
  }

  init() {
    this.loader.add([ASSETS_PATH + "/high.png", ASSETS_PATH + "/low.png"]);
    this.loader.set("machine", SHEET_PATH + "/machine.json");

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.app.stage.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configAnim();
    this.configMachine();
    this.configLabel();
    this.configNumbers();
  }

  configAnim() {
    let time = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        if (time % 5 == 0) {
          for (var i = 0; i < 5; i++) {
            this.number[i].text = rand(9);
          }
        }
        time++;
      }
    });
  }

  configMachine() {
    const textures = [];
    let i;

    for (i = 0; i < 2; i++) {
      const framekey = `${i}.png`;
      const texture = PIXI.Texture.from(framekey);

      textures.push(texture);
    }

    this.machine = new PIXI.AnimatedSprite(textures);
    this.machine.animationSpeed = 0.02;
    this.machine.play();
    this.container.addChild(this.machine);
  }

  configLabel() {
    this.label = new PIXI.Sprite.from(ASSETS_PATH + "/low.png");
    this.label.x = this.app.screen.width - 70;
    this.label.y = 50;
    this.label.width = 70;
    this.label.height = 70;
    this.label.interactive = true;
    this.label.buttonMode = true;
    this.label.on("click", (e) => this.changeLabel());
    this.label.on("touchstart", (e) => this.changeLabel());
    this.container.addChild(this.label);
  }

  configNumbers() {
    const style = new PIXI.TextStyle({
      fontFamily: "Roboto, sans-serif",
      fontSize: 60,
      fill: 0xffffff,
      fontWeight: 700,
    });
    for (var i = 0; i < 5; i++) {
      this.number[i] = new PIXI.Text("0", style);
      this.number[i].x = 90 * i + 83;
      this.number[i].y = 57;
      this.container.addChild(this.number[i]);
      Effect.FadeIn(this.number[i]);
    }
  }

  changeLabel() {
    if (this.started) return;
    this.type = this.type === "low" ? "high" : "low";
    this.label.texture = PIXI.Texture.from(ASSETS_PATH + `/${this.type}.png`);
    this.engine.type = this.type;
  }

  play(target) {
    this.started = true;

    if (target.length === 4) {
      target = target + "0";
    }

    wait(2000).then(() => {
      for (var i = 0; i < 5; i++) {
        this.number[i].text = target[i];
      }
      this.started = false;
    });
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
