import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { __ } from "../../Helper";

const CheckboxMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      onSelectAll,
      onSelectNone
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={`${className} CheckboxMenu`}
        aria-labelledby={labeledBy}
      >
        <div
          className="d-flex flex-column"
          style={{ maxHeight: "calc(100vh)", overflow: "none" }}
        >
          <ul
            className="list-unstyled flex-shrink mb-0"
            style={{ overflow: "auto" }}
          >
            {children}
          </ul>
          <div className="dropdown-item border-top pt-2 pb-0">
            <ButtonGroup size="sm">
              <Button variant="link" onClick={onSelectAll}>
                Select All
              </Button>
              <Button variant="link" onClick={onSelectNone}>
                Select None
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    );
  }
);

const CheckDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange }, ref) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
          className="d-flex align-items-center sort-dropdown-item"
        />
      </Form.Group>
    );
  }
);

export const CheckboxDropdown = observer(({ variant, items, checkUpdate, listUpdate, t }) => {
  const handleChecked = (key, event) => {
    checkUpdate(variant, key, event.target.checked);
    // items.find((i) => i.id === key).checked = event.target.checked;
  };

  const handleSelectAll = () => {
    items.forEach((i) => (i.checked = true));
  };

  const handleSelectNone = () => {
    items.forEach((i) => (i.checked = false));
  };

  const dontCloseDropdown = (e) => {
    // React.findDOMNode(this.refs.callIdInput).focus();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation()
  }

  const searchDropdownList = (variant, e) => {
    listUpdate(variant, e.target.value);
  }

  return (
    <Dropdown>
        <Dropdown.Toggle variant="custom" id={"dropdown_" + variant + "_list"}>
            <span className="caret ms-1">{__.capitalize(variant)}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu
            // as={CheckboxMenu}
            // onSelectAll={handleSelectAll}
            // onSelectNone={handleSelectNone}
            className="sort-dropdown"
        >
            <Dropdown.Item
                // key={i.id}
                // as={CheckDropdownItem}
                // id={i.id}
                // checked={i.checked}
                // onChange={handleChecked}
            >
                <Form.Control className="sort-dropdown-search" placeholder={t('search_for_' + variant)} onClick={dontCloseDropdown} onChange={(e) => { searchDropdownList(variant, e)} } />

            </Dropdown.Item>
            {items.map((i) => (
            <Dropdown.Item
                key={i.id}
                as={CheckDropdownItem}
                id={i.id}
                checked={i.checked}
                onChange={handleChecked}
            >
                {i.label}
            </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
  );
});
