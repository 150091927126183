import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { __, wait } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

const ASSETS_PATH = "/assets/images/three_card_monte";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 600,
      height: 320,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });

    this.engine = engine;
    this.started = false;
    this.end = false;
    this.moveCards1 = false;
    this.moveCards2 = false;
    this.moveCards3 = false;
    this.moveCards4 = false;
    this.moveCards5 = false;
    this.activePlayer = false;
    this.bustedGame = false;
    this.clicked = null;
    this.winner = null;
    this.container = null;
    this.cards = [];
    this.loader = new Loader("three_card_monte", this.app);
  }

  init() {
    let images = [ASSETS_PATH + "/back.png"];

    let cards = ["heart", "spade", "wajit"];
    cards.forEach((name, i) => {
      images.push(ASSETS_PATH + `/card-${name}.png`);
    });

    this.loader.add([images]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    if (this.app === null) return;
    this.engine.emit("ready");
    this.res = res;
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true;
    this.container.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configureCards();
    this.configureMessage();

    this.app.ticker.add((delta) => this.animation(delta, 0));
  }

  animation(delta, count) {
    let count2 = 0;

    if (this.started) {
      count += 1.0;

      //Game Started

      this.moveCards1 = true;
      this.started = false;
    }

    count2 -= 0.04 * delta * Math.PI;

    // if (this.flipCard) {
    //   let m = Math.min(-0.05, count2);
    //   this.clicked.scale.x += count2;

    //   if (this.clicked.scale.x < -1) {
    //     this.clicked.texture = new PIXI.Texture.from(
    //       ASSETS_PATH + `/card-${this.clicked.target}.png`
    //     );
    //     this.clicked.scale.set(0.24);
    //     this.flipCard = false;
    //   }
    // }

    if (this.end) {
      let cards = ["first", "second", "third"];
      cards.forEach((name, i) => {
        let m = Math.min(-0.05, count2);
        this.cards[name].scale.x += count2;
        if (this.cards[name].scale.x < -1) {
          this.cards[name].texture = new PIXI.Texture.from(
            ASSETS_PATH + `/card-${this.cards[name].target}.png`
          );
          this.cards[name].scale.set(0.24);
          if (i === 2) {
            this.flipCard = false;
            this.end = false;
          }
        }
      });
    }

    if (this.moveCards1) {
      wait(500).then(() => {
        count += 4.0;

        if (this.cards["first"].x < 490)
          this.cards["first"].x += count * Math.PI;

        if (this.cards["third"].x > 110)
          this.cards["third"].x -= count * Math.PI;

        this.moveCards1 = false;
        this.moveCards2 = true;
      });
    }

    if (this.moveCards2) {
      wait(500).then(() => {
        count += 4.0;

        if (this.cards["second"].x < 480)
          this.cards["second"].x += count * Math.PI;

        if (this.cards["first"].x > 310)
          this.cards["first"].x -= count * Math.PI;

        this.moveCards2 = false;
        this.moveCards3 = true;
      });
    }

    if (this.moveCards3) {
      wait(500).then(() => {
        count += 4.0;

        if (this.cards["second"].x < 290)
          this.cards["second"].x += count * Math.PI;

        if (this.cards["first"].x > 110)
          this.cards["first"].x -= count * Math.PI;

        this.moveCards3 = false;
        this.moveCards4 = true;
      });
    }

    if (this.moveCards4) {
      wait(500).then(() => {
        count += 4.0;

        if (this.cards["third"].x < 290)
          this.cards["third"].x += count * Math.PI;

        if (this.cards["second"].x > 310)
          this.cards["second"].x -= count * Math.PI;

        this.moveCards4 = false;
        this.moveCards5 = true;
      });
    }

    if (this.moveCards5) {
      wait(500).then(() => {
        count += 4.0;

        if (this.cards["third"].x < 490)
          this.cards["third"].x += count * Math.PI;

        if (this.cards["first"].x > 110)
          this.cards["first"].x -= count * Math.PI;

        this.message.x = this.app.screen.width / 3.7;
        this.message.text = "FIND THE ACE OF SPADE";
        this.activePlayer = true;
        this.moveCards5 = false;

        let cards = ["first", "second", "third"];
        cards.forEach((name, i) => {
          this.cards[name].interactive = true;
          this.cards[name].buttonMode = true;
        });
      });
    }
  }

  configureMessage() {
    const style = new PIXI.TextStyle({
      fontFamily: "Tahoma",
      fontSize: 22,
      fill: "#FFF",
      fontWeight: 700,
    });
    this.message = new PIXI.Text("", style);
    this.message.x = this.app.screen.width / 3.1;
    this.message.y = 15;
    this.container.addChild(this.message);
  }

  reset() {
    this.message.text = "";
    this.message.x = this.app.screen.width / 3.1;
    this.started = false;
    this.moveCards1 = false;
    this.moveCards2 = false;
    this.moveCards3 = false;
    this.moveCards4 = false;
    this.moveCards5 = false;
    this.activePlayer = false;
    this.clicked = null;
    this.winner = null;

    this.cardPlace.children.forEach((card, i) => {
      this.cardPlace.removeChild(card);
    });

    this.cardPlace.removeChildren();

    this.container.removeChild(this.cardPlace);

    wait(100).then(() => {
      this.configureCards();
    });
  }

  configureCards() {
    this.cards = [];
    this.cardPlace = new PIXI.Container();
    this.cardPlace.sortableChildren = true;

    let cards = ["first", "second", "third"];

    cards.forEach((name, i) => {
      this.cards[name] = new PIXI.Sprite.from(ASSETS_PATH + "/back.png");
      this.cards[name].anchor.set(0.5);
      this.cards[name].width = 147;
      this.cards[name].height = 213;
      this.cards[name].name = name;
      this.cards[name].x = 200 * i + 100;
      this.cards[name].id = i;
      this.cards[name].scale.set(0.8963414634146342);
      this.cards[name].y = this.app.screen.height / 1.8;
      this.cards[name].on("click", (e) => this.onClick(this.cards[name]));
      this.cards[name].on("touchstart", (e) => this.onClick(this.cards[name]));
      this.cards[name].on("pointerover", (e) => this.onOver(this.cards[name]));
      this.cards[name].on("pointerout", (e) => this.onOut(this.cards[name]));
      this.cardPlace.addChild(this.cards[name]);
      this.cards[name].interactive = false;
      this.cards[name].buttonMode = false;
    });

    this.container.addChild(this.cardPlace);
  }

  onOver(card) {
    if (card === this.clicked) return;
    Effect.Particle(card, ASSETS_PATH + "/effect.png");
  }

  onOut(card) {
    if (card === this.clicked) return;
  }

  onClick(card) {
    if (!this.activePlayer) return;
    this.clicked = card;
    this.flipCard = true;
    Effect.Filter(card, "GlowFilter");
    this.message.text = "Checking the Card";
    this.engine.init = true;
    this.engine.target = card.id;
    this.engine.clicked();
  }

  play() {
    this.reset();
    this.message.text = "";
    this.message.x = this.app.screen.width / 2.6;
    this.message.text = "SHUFFLING...";
    this.started = true;
  }

  busted(result, isWin) {
    this.result = result;
    this.end = true;
    wait(50).then(() => {
      this.winner = isWin;

      result.forEach((card, i) => {
        let name;
        if (i === 0) {
          name = "first";
        } else if (i === 1) {
          name = "second";
        } else {
          name = "third";
        }
        this.cards[name].target = card;
      });

      if (this.winner === true) {
        this.message.x = this.app.screen.width / 2.4;
        this.message.text = "Winner !";
      } else if (this.winner === false) {
        this.message.x = this.app.screen.width / 2.3;
        this.message.text = "Lost !";
      }
    });
    this.engine.init = false;
    this.activePlayer = false;
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
