import React from "react";
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  isMobile,
  Event,
  wait,
  getDefaultBetAmount,
  isValidNumber,
  forceSatoshiFormat,
  __,
  isLogged,
  funPlay,
  sendNotfication,
  playAudio,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      buttonText: "Roll",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      disabled: false,
      formData: {
        payout: "1.0102",
        chance: 99.9999,
      },
    };
    this.handleInputChangePayout = this.handleInputChangePayout.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.outRef = React.createRef();
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
    Event.on("amount", (value) => {
      this.setState({ amount: value });
    });
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside2);
    this.props.gameCoin();
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    if (isMobile()) {
      this.setState({ mt: "mt-2" });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      let value = parseFloat(this.state.formData.payout);

      if (value < 1.0102) value = 1.0102;

      if (value > 9000) value = 9000;

      if (!isValidNumber(value)) value = 1.0102;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          payout: parseFloat(value).toFixed(4),
        },
      }));
    }
  }

  handleClickOutside2(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  busted(data) {
    if (this._isMounted) {
      let isWin = false;

      if (parseFloat(data.profit) !== 0) {
        isWin = true;
      }

      wait(1000).then(() => {
        this.setState({ disabled: false });
      });

      wait(500).then(() => {
        if (isWin) {
          playAudio("win.mp3");
        } else {
          playAudio('lost.mp3');
        }
      });
    }
  }

  setBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount, bet, started } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      this.setState({ disabled: true });

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.placeBet();
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;
      let { payout, chance } = this.state.formData;

      wait(100).then(() => {
        engine.game = "hash_dice";
        engine.init = true;
        engine.amount = amount;
        engine.payout = payout;
        engine.chance = chance;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  handleInputChangePayout(event) {
    let value = event.target.value;

    if (value.length > 5) return;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        payout: value,
      },
    }));
    this.setChance(value);
  }

  setChance(value) {
    var c = ((1 / value) * 99).toFixed(5);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        chance: Math.min(c, 98.0198),
      },
    }));
  }

  betMode = (type) => {
    this.setState({ bet: type });
  };

  setAmount = (value) => {
    if (!this.state.disabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  changePayout(type) {
    const { payout } = this.state.formData;
    var value;
    if (type === "up") {
      value = parseFloat(payout) + 1;
    } else {
      value = parseFloat(payout) - 1;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        payout: value.toFixed(2),
      },
    }));

    this.setChance(value);
  }

  render() {
    let { disabled, amount, bet } = this.state;
    return (
      <>
        <Form onSubmit={(e) => this.setBet(e)}>
          <Row className={"mt-1"}>
            <Col xl={5} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit set={this.setAmount} amount={amount} />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={4} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>PAYOUT</InputGroup.Text>
                <Form.Control
                  ref={this.wrapperRef}
                  disabled={disabled}
                  type="text"
                  className="form-control text-left"
                  id="payout"
                  name="payout"
                  min="1.0102"
                  max="9900.0"
                  value={this.state.formData.payout}
                  autoComplete={"off"}
                  onChange={this.handleInputChangePayout}
                  required={true}
                ></Form.Control>
                <InputGroup.Text>
                  <button
                    onClick={() => this.changePayout("down")}
                    className="btn btn-sm bg-cs2 after-text no-hover npt nh cpt"
                    type="button"
                  >
                    <i className="mdi mdi-arrow-left-bold" />
                  </button>
                  <button
                    onClick={() => this.changePayout("up")}
                    className="btn btn-sm bg-cs2 after-text no-hover npt nh cpt"
                    type="button"
                  >
                    <i className="mdi mdi-arrow-right-bold" />
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>Win Chance</InputGroup.Text>
                <Form.Control
                  disabled={true}
                  type="number"
                  className="form-control text-left"
                  id="chance"
                  name="chance"
                  placeholder=".."
                  value={this.state.formData.chance}
                ></Form.Control>
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md={5} sm={12} className="m-auto">
              {bet === "manual" && (
                <div className={"form-group rev mt-2 mb-0"}>
                  <Button
                    variant={"btn btn-block btn-bet btn-xl mb-2"}
                    id={"bet"}
                    disabled={disabled}
                    type="submit"
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Form>

        {bet === "auto" && (
          <AutoBet
            engine={this.state.engine}
            formData={this.state.formData}
            amount={this.state.amount}
          />
        )}
        <div
          className="btn-group btn-group-toggle mb-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={disabled}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={disabled}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
