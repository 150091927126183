class Deck {
  constructor() {
    this.deck = [];
  }

  add() {
    const suits = ["H", "D", "C", "S"];
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    this.deck = suits.reduce((a, suit) => {
      return [
        ...a,
        ...numbers.map((value) => {
          return {
            name: this.getName(value),
            value: this.getValue(value),
            texture: this.getName(value) + suit,
            suit,
          };
        }),
      ];
    }, []);
  }

  get() {
    return this.deck.pop();
  }

  shuffle() {
    let cardsRemaining = this.deck.length;
    let index;
    let swap;
    while (cardsRemaining > 0) {
      index = Math.floor(Math.random() * cardsRemaining);
      cardsRemaining--;
      swap = this.deck[cardsRemaining];
      this.deck[cardsRemaining] = this.deck[index];
      this.deck[index] = swap;
    }
  }

  getName(value) {
    if (value === 1) {
      return "A";
    } else if (value === 11) {
      return "J";
    } else if (value === 12) {
      return "Q";
    } else if (value === 13) {
      return "K";
    } else return value.toString();
  }

  getValue(value) {
    let name = this.getName(value);
    if (name === "A") {
      return 11;
    } else if (name === "J" || name === "Q" || name === "K") {
      return 10;
    } else return parseFloat(name);
  }
}

export default Deck;
