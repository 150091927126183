import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown, Modal, Button, Row, Col, Card } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import Deposit from "../../../Components/User/Wallet/Deposit";
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import {
  __,
  wait,
  decode,
  encode,
  forceSatoshiFormat,
  Event,
  secureRandomNumber,
  getUID,
  sendNotfication,
} from "../../../../Helper";
import C from "../../../../Constant";
import { withRouter } from "react-router-dom";

class Credit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showManage: false,
      showBonusMd: false,
      token: storage.getKey("token"),
      coin: storage.getKey("coin") ? storage.getKey("coin") : "USDT",
      activeClientCoins: storage.getKey("active_coins")
        ? storage.getKey("active_coins")
        : null,
      selectedCoin: storage.getKey("coin"),
      credits: {},
      coins: [],
      manage_coins: [],
      different: [],
      credit: false,
      usd: storage.getKey("usd") === "true" ? true : false,
      showDepositMd: false,
      wallet: null,
      headerText: "",
      padding: "",
      paddingHeader: "",
      backFrom: null,
      wagered_amount: 0,
      unlockable_bonus: 0,
      isHasLocked: false,
    };
    this.selectCoin = this.selectCoin.bind(this);
    this.wrapperRef = React.createRef();

    this.handleWallet = this.handleWallet.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showArchive = this.showArchive.bind(this);
    this.closeBonus = this.closeBonus.bind(this);
  }

  handleWallet(wallet) {
    let text = "Deposit History";
    this.setState({ showDepositMd: true, wallet: wallet, headerText: text });
  }

  handleClose() {
    this.setState({ showDepositMd: false });
  }
  closeBonus() {
    this.setState({ showBonusMd: false });
  }
  showArchive() {
    this.setState({ headerText: "Back" });
    if (this.state.wallet === "deposit") {
      Event.emit("deposit_archive");
    } else if (this.state.wallet === "withdraw") {
      Event.emit("withdraw_archive");
    }
  }

  componentDidMount() {
    //Get Redux Coin
    this.props.gameCoin();
    Event.on("other_coins", () => {
      this.setState({ showManage: true });
    });

    if (this.state.token !== null) {
      socket.emit(C.GET_USER_PROFIT);
      socket.emit(C.WAGERED_AMOUNT_FOR_CLAIM, encode(storage.getKey("name")));
    }
    socket.on(C.GET_USER_PROFIT, (data) => {
      this.getCredit(decode(data));
    });
    socket.on(C.WAGERED_AMOUNT_FOR_CLAIM, (data) => {
      this.setState({
        wagered_amount: decode(data),
        unlockable_bonus: decode(data) / 500,
      });
    });

    if (this.state.activeClientCoins === null) {
      var arr = [];
      for (var i = 1; i < 8; i++) {
        arr.push(i);
      }
      storage.setKey("active_coins", arr);
    }

    this.setUpManageCoins();

    if (this.state.showManage) {
      wait(1000).then(() => {
        const ps = new PerfectScrollbar(".ccoins", {
          wheelSpeed: 1,
          suppressScrollX: true,
          wheelPropagation: true,
          minScrollbarLength: 2,
        });
        ps.update();
      });
    }

    window.addEventListener("wheel", this.handleScroll, { passive: false });

    Event.on("deposit_archive_back", () => {
      this.setState({ headerText: "Deposit History", backFrom: null });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // URL has changed, you can perform your actions here
      socket.emit(C.GET_USER_PROFIT);
      socket.on(C.GET_USER_PROFIT, (data) => {
        this.getCredit(decode(data));
      });
      // You can perform any actions or updates here
      // For example, fetching data, updating the UI, etc.
    }
  }
  handleScroll(e) {
    function sicimsc(e) {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
    let creditBody = document.querySelector(".prevscrol");
    if (creditBody) {
      creditBody.scrollTop -= e.wheelDeltaY;
      sicimsc(e);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll, { passive: false });

    socket.off(C.GET_USER_PROFIT);
    socket.off(C.WAGERED_AMOUNT_FOR_CLAIM);
    socket.off(C.BONUS_TO_PROFIT);
  }

  activeCoinChange = (id, active) => {
    if (id === 1) return;
    const activeClientCoins = storage.getKey("active_coins");
    let update;

    if (!active) {
      update = activeClientCoins + "," + id;
    } else {
      update = __.replace(activeClientCoins, "," + id, "");
    }

    update = __.replace(update, null, "");

    const compareNumbers = (a, b) => a - b;

    let arr = [];

    const regex = /(\d+)/gi;
    let m;

    while ((m = regex.exec(update)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      m.forEach((match, groupIndex) => {
        match = parseFloat(match);
        arr.push(match);
      });
    }

    arr.sort(compareNumbers);
    var c = __.xor(arr);

    storage.setKey("active_coins", c);

    this.setUpManageCoins();
  };

  setUpManageCoins = () => {
    const { t } = this.props;
    let self = this;

    this.setState({ manage_coins: [] });

    const activeCoins = storage.getKey("active_coins");
    let ac = __.split(activeCoins, ",");
    coins.forEach((item, key) => {
      //Default
      if (activeCoins === null) {
        let add = (
          <Dropdown.Item
            key={key}
            className={"num-style d-flex justify-content-between"}
          >
            <div className="float-left text-white ncpt">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-12"}
              />
              {item.preffix}
            </div>
            <div className="float-right">
              <div className="custom-control custom-switch switch-success">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"switch_" + key}
                  checked={true}
                />
                <label
                  onClick={(e) =>
                    self.activeCoinChange(parseFloat(item.id), true)
                  }
                  className={"custom-control-label"}
                  htmlFor={"switch_" + key}
                >
                  {item.name}
                </label>
              </div>
            </div>
          </Dropdown.Item>
        );
        return self.setState((state) => ({
          manage_coins: [add, ...state.manage_coins],
        }));
      }

      let active = false;

      let check = ac.some(
        (element) => parseFloat(element) === parseFloat(item.id)
      );

      if (check) {
        active = true;
      }

      var disabled = false;

      if (item.preffix === "BTC") disabled = true;

      let add = (
        <Dropdown.Item
          key={key}
          className={"num-style d-flex justify-content-between"}
        >
          <div className="float-left text-white ncpt">
            <img
              src={"/assets/images/" + item.image}
              alt="coin"
              className={"mini-coin-12"}
            />
            {item.preffix}
          </div>
          <div className="float-right">
            <div className="custom-control custom-switch switch-success">
              <input
                type="checkbox"
                className="custom-control-input"
                id={"switch_" + key}
                checked={active}
                disabled={disabled}
              />
              <label
                onClick={(e) =>
                  self.activeCoinChange(parseFloat(item.id), active)
                }
                className="custom-control-label"
                htmlFor={"switch_" + key}
              >
                {item.name}
              </label>
            </div>
          </div>
        </Dropdown.Item>
      );
      self.setState((state) => ({
        manage_coins: [add, ...state.manage_coins],
      }));
    });
  };

  updateAllCoins(newCoin = null, newVal = null) {
    const activeClientCoins = storage.getKey("active_coins");
    this.setState({ coins: [] });
    let ac = null,
      style,
      value,
      coin;
    if (activeClientCoins !== null) {
      ac = activeClientCoins.split(",");

      __.reverse(ac).forEach((num, key) => {
        var item = __.find(coins, { id: parseFloat(num) });
        if (__.isUndefined(item)) return;
        style =
          __.toLower(item.preffix) === __.toLower(this.state.selectedCoin)
            ? "actc"
            : "";

        if (newVal !== null) value = newVal;
        else {
          value = this.state.usd
            ? forceSatoshiFormat(
                this.state.credits[__.toLower(item.preffix)],
                item.preffix,
                this.state.usd
              )
            : this.state.credits[__.toLower(item.preffix)];
        }

        if (__.toLower(item.preffix) === newCoin) {
          this.state.credits[newCoin] = value;
        }

        if (
          this.state.credits.hasOwnProperty(
            __.toLower(item.preffix) + " bonus"
          ) &&
          __.toNumber(this.state.credits[__.toLower(item.preffix) + " bonus"]) >
            0
        ) {
          this.setState({ isHasLocked: true });
        }

        if (newCoin !== null) {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix, newCoin, newVal)}
              key={key}
              className={
                "num-style " + style + " d-flex justify-content-between"
              }
            >
              <div className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              {__.toLower(item.preffix) === newCoin ? (
                <div className="float-right">{value}</div>
              ) : (
                <div className="float-right"></div>
              )}
            </Dropdown.Item>
          );
        } else {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={
                "num-style " + style + " d-flex justify-content-between"
              }
            >
              <div className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div className="float-right">{value}</div>
            </Dropdown.Item>
          );
        }

        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    } else {
      coins.forEach((item, key) => {
        if (ac !== null) {
          let check = ac.some(
            (element) => parseFloat(element) === parseFloat(item.id)
          );

          if (check) {
            style =
              __.toLower(item.preffix) === __.toLower(this.state.selectedCoin)
                ? "actc"
                : "";
            value = this.state.usd
              ? forceSatoshiFormat(
                  this.state.credits[__.toLower(item.preffix)],
                  item.preffix,
                  this.state.usd
                )
              : this.state.credits[__.toLower(item.preffix)];
            coin = (
              <Dropdown.Item
                onClick={(e) => this.selectCoin(item.preffix)}
                key={key}
                className={
                  "num-style " + style + " d-flex justify-content-between"
                }
              >
                <div className="float-left">
                  <img
                    src={"/assets/images/" + item.image}
                    alt="coin"
                    className={"mini-coin-7"}
                  />
                  {item.preffix}
                </div>
                <div className="float-right">{value}</div>
              </Dropdown.Item>
            );
          }
        } else {
          style =
            __.toLower(item.preffix) === __.toLower(this.state.selectedCoin)
              ? "actc"
              : "";
          value = this.state.usd
            ? forceSatoshiFormat(
                this.state.credits[__.toLower(item.preffix)],
                item.preffix,
                this.state.usd
              )
            : this.state.credits[__.toLower(item.preffix)];
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={
                "num-style " + style + " d-flex justify-content-between"
              }
            >
              <div className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div className="float-right">{value}</div>
            </Dropdown.Item>
          );
        }
        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    }
  }

  getCredit(data) {
    let { credit } = data;
    if (typeof credit === "string") {
      this.setState({ credits: JSON.parse(credit) });
    } else {
      this.setState({ credits: credit });
    }

    let currentCoin = __.toLower(this.props.coin);
    let currentCredit = this.state.usd
      ? forceSatoshiFormat(
          this.state.credits[currentCoin],
          this.props.coin,
          this.state.usd
        )
      : this.state.credits[currentCoin];
    this.setState({ credit: currentCredit });
    storage.setKey("credit", currentCredit);

    //Set for Redux
    this.props.setCredit(currentCredit);
    this.updateAllCoins();
  }

  updateCredit(data) {
    let credit = data.value;
    let coin = data.coin;

    let different;
    let arr;

    if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
      different = __.toNumber(credit) - __.toNumber(this.state.credit);
      arr = {
        amount: different,
        color: "text-success",
      };
    } else {
      different = __.toNumber(this.state.credit) - __.toNumber(credit);
      arr = {
        amount: different,
        color: "text-danger",
      };
    }

    let check = this.state.usd
      ? forceSatoshiFormat(different, data.coin, this.state.usd)
      : different;

    if (check.toString() !== "0.00000000") {
      this.setState((state) => ({ different: [arr, ...state.different] }));
    }

    var realCredit = credit;
    credit = this.state.usd
      ? forceSatoshiFormat(credit, data.coin, this.state.usd)
      : credit;

    this.setState({ credit: credit });
    storage.setKey("credit", credit);

    this.updateParentCoin(__.toLower(coin), credit);
    this.updateAllCoins(__.toLower(coin), realCredit);

    //Set for Redux
    this.props.setCredit(credit);
  }

  updateParentCoin(coin, amount) {
    this.setState({ coins: [] });

    coins.forEach((item, key) => {
      if (__.toLower(item.preffix) === coin) {
        const style =
          __.toLower(item.preffix) === __.toLower(this.state.selectedCoin)
            ? "actc"
            : "";
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(__.upperCase(coin))}
            key={key}
            className={"num-style " + style + " d-flex justify-content-between"}
          >
            <div className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div className="float-right">
              {this.state.usd
                ? forceSatoshiFormat(amount, item.preffix, this.state.usd)
                : amount}
            </div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      } else {
        const style =
          __.toLower(item.preffix) === __.toLower(this.state.selectedCoin)
            ? "actc"
            : "";
        let value = this.state.usd
          ? forceSatoshiFormat(
              this.state.credits[__.toLower(item.preffix)],
              item.preffix,
              this.state.usd
            )
          : this.state.credits[__.toLower(item.preffix)];
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(item.preffix)}
            key={key}
            className={"num-style " + style + " d-flex justify-content-between"}
          >
            <div className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div className="float-right">{value}</div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      }
    });
  }

  selectCoin(name, newCoin, newVal) {
    //Fix For Double selection
    if (storage.getKey("coin") === name) return;
    storage.setKey("coin", name);

    let credit = this.state.credits[__.toLower(name)];
    this.setState({ coin: name, credit: credit, selectedCoin: name });

    //Set Coin For Redux
    this.props.setCoin(name);
    this.props.setCredit(credit);

    //Socket emit after set coin
    socket.emit(C.SELECTED_COIN, encode(__.toLower(name)));
    wait(200).then(() => {
      this.updateAllCoins(newCoin, newVal);
      Event.emit("coin_changed", name);
    });
  }

  addDiff(data, i) {
    // let id = 'id_' + Math.floor(Math.random() * 1000 + 1);
    let id = "id_" + Math.floor(secureRandomNumber() * 1000 + 1);

    wait(2000).then(() => {
      try {
        document.getElementById(id).classList.remove("frd");
        document.getElementById(id).classList.add("fadeOutDown");
      } catch (e) {}
      this.state.different.splice(i, 1);
    });

    return (
      <li
        key={i}
        id={id}
        className={"list-inline w-100 text-right animated frd " + data.color}
      >
        {" "}
        {data.color === "text-danger" ? "-" : "+"}
        {this.state.usd
          ? forceSatoshiFormat(data.amount, this.state.coin, this.state.usd)
          : data.amount}
      </li>
    );
  }

  closeManage = () => {
    this.setState({ showManage: false });
    wait(200).then(() => {
      this.updateAllCoins();
    });
  };

  manageCoins = () => {
    this.setState({ showManage: true });
  };

  usdformat = () => {
    this.setState({ usd: !this.state.usd });
    wait(200).then(() => {
      this.updateAllCoins();
    });
    wait(1000).then(() => {
      storage.setKey("usd", this.state.usd);
    });
  };
  claimBonus = (bonusAmount) => {
    let data = {
      bonus: bonusAmount,
      coin: this.state.selectedCoin + "bonus",
    };
    socket.emit(C.BONUS_TO_PROFIT, encode(data));
    socket.on(C.BONUS_TO_PROFIT, (data) => {
      if (decode(data)) {
        sendNotfication(this.props.t("bonus_converted_to_balance"));
        this.getCredit(decode(data));
        this.closeBonus();
      } else {
        sendNotfication(
          "There was an issue converting your bonus to your balance."
        );
      }
      socket.off(C.BONUS_TO_PROFIT);
    });

    socket.emit(C.WAGERED_AMOUNT_FOR_CLAIM, encode(storage.getKey("name")));
    socket.on(C.WAGERED_AMOUNT_FOR_CLAIM, (data) => {
      const wagered_amount = decode(data) ? decode(data) : 0;
      this.setState({ wagered_amount, unlockable_bonus: wagered_amount / 500 });
      socket.off(C.WAGERED_AMOUNT_FOR_CLAIM);
    });
  };

  openDeposit(mode) {
    if (mode === 2) {
      Event.emit("e_sevice_deposit");
      wait(1000).then(() => {
        Event.emit("force_show_cards");
      });
    } else {
      Event.emit("e_sevice_deposit");
    }
  }

  render() {
    let { credit, different, coin, coins } = this.state;
    credit = forceSatoshiFormat(credit, coin);
    let diff = different.map((data, i) => this.addDiff(data, i));
    const { t } = this.props;
    return (
      <>
        <div className="crypto-balance d-flex align-items-center mobile-coin-list">
          <span id="cup" className={"mt-2 mr-1"}>
            {diff}
          </span>
          <div className="btc-balance">
            <Dropdown className={"clist mt-0"}>
              <div className="d-flex">
                <Dropdown.Toggle
                  split
                  variant="block"
                  className={"p-0 mt-0"}
                  id="dropdown-split-coins"
                >
                  <img
                    src={"/assets/images/" + __.toLower(coin) + ".png"}
                    className={"mini-coin-5 mr-1"}
                    alt=""
                  />
                  <span className={"text-white header-credit-wrapper"}>
                    {Number(credit).toFixed(4)} {coin}
                  </span>
                  <span className="caret ms-1"></span>
                </Dropdown.Toggle>
                <button
                  onClick={this.openDeposit}
                  className="btn float-right text-white plusbuttontop"
                >
                  <i className="mdi mdi-plus font-15 float-right text-white plusbutton" />
                </button>
              </div>
              <Dropdown.Menu
                className={
                  "dopdown-menu-right num-style mobile-coins-dropdown-list"
                }
              >
                {coins}
                {this.state.isHasLocked && (
                  <div
                    className="locked-balance"
                    onClick={() => {
                      this.setState({ showBonusMd: true });
                    }}
                  >
                    <span className="mdi mdi-lock locked-balance-icon"></span>
                    {" Bonus: "}
                    {this.state.usd
                      ? forceSatoshiFormat(
                          this.state.credits[__.toLower("usdt") + " bonus"],
                          "usdt",
                          this.state.usd
                        )
                      : this.state.credits[__.toLower("usdt") + " bonus"]}
                  </div>
                )}

                <Dropdown.Item className={"creditcardbutton"}>
                  <div
                    className="text-center"
                    onClick={() => {
                      this.openDeposit(2);
                    }}
                  >
                    <span>Buy Crypto</span>
                    <img
                      className="img-fluid w-50 float-right"
                      src="/assets/images/mastercard.png"
                    />
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Modal
          size={"lg"}
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.showBonusMd}
          onHide={this.closeBonus}
          aria-labelledby="wallet-md-modal"
        >
          <Modal.Header className={this.state.paddingHeader}>
            <Row xs={1} md={2} style={{ width: "100%" }}>
              <Col xs={12} md={12}>
                <h4 className="text-white">
                  {__.replace(this.state.selectedCoin, "_", "_")} BONUS
                </h4>
              </Col>
            </Row>
            <button
              type="button"
              className="close p-2"
              onClick={this.closeBonus}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body
            className={
              this.state.padding +
              " wallet-modal buttonswallet buttonswalletbonus mobile"
            }
          >
            <img
              src="/assets/images/bull-rewards.png"
              className="img-fluid bullrewards-mobile"
              alt=""
            />
            <div className="container-fluid mduser topheader card bonustotal d-flex flex-row justify-content-center align-items-center w-50 my-3">
              <img
                src={
                  "/assets/images/" +
                  __.replace(__.toLower(this.state.selectedCoin), "_", "_") +
                  ".png"
                }
                alt="coin"
                className={"mini-coin-7"}
              />
              <h4 className="text-success">
                &nbsp;
                {parseFloat(
                  this.state.credits[
                    __.toLower(this.state.selectedCoin) + " bonus"
                  ]
                ) > this.state.unlockable_bonus.toFixed(3)
                  ? this.state.unlockable_bonus.toFixed(3)
                  : this.state.usd
                  ? forceSatoshiFormat(
                      this.state.credits[
                        __.toLower(this.state.selectedCoin) + " bonus"
                      ],
                      this.state.selectedCoin,
                      this.state.usd
                    )
                  : this.state.credits[
                      __.toLower(this.state.selectedCoin) + " bonus"
                    ]}
              </h4>
            </div>
            <div>
              <div className="d-flex justify-content-center flex-row w-100">
                <button
                  className="btn btn-success w-25 claim-link"
                  onClick={() =>
                    this.claimBonus(
                      parseFloat(
                        this.state.credits[
                          __.toLower(this.state.selectedCoin) + " bonus"
                        ]
                      ) > this.state.unlockable_bonus.toFixed(3)
                        ? this.state.unlockable_bonus.toFixed(3)
                        : this.state.credits[
                            __.toLower(this.state.selectedCoin) + " bonus"
                          ]
                    )
                  }
                  disabled={
                    this.state.unlockable_bonus < 5 ||
                    this.state.credits[
                      __.toLower(this.state.selectedCoin) + " bonus"
                    ] < 5
                      ? true
                      : false
                  }
                >
                  CLAIM
                </button>
              </div>
              <div className="d-flex justify-content-center flex-row w-100">
                <h6>{t("minimum_to_claim")}:</h6>
                <h6 className="text-white">&nbsp;5 USD</h6>
              </div>
            </div>
            <div className="container-fluid mduser card bonustotal2 d-flex flex-row justify-content-between align-items-center px-4  my-3">
              <h5>
                <span className="mdi mdi-lock"></span>Locked{" "}
                {__.replace(this.state.selectedCoin, "_", "_")}:
              </h5>
              <h5 className="text-white">
                {this.state.usd
                  ? forceSatoshiFormat(
                      this.state.credits[
                        __.toLower(this.state.selectedCoin) + " bonus"
                      ],
                      this.state.selectedCoin,
                      this.state.usd
                    )
                  : this.state.credits[
                      __.toLower(this.state.selectedCoin) + " bonus"
                    ]}
              </h5>
              {/* <h4 className="text-white">{this.state.wagered_amount} USD</h4> */}
              <i></i>
            </div>
            <h4 className="text-center text-yellow">
              {t("how_to_claim_bonus")}?
            </h4>
            <div className="container-fluid mduser card bonustotal3 px-4 py-2">
              <h6 className="text-white">
                {t("unlock_amount")} = {t("wager_amount")} * 1% * 20%
              </h6>
              <h6>
                <span className="text-yellow">{t("note")} </span>
                {this.state.wagered_amount < 2500 ? (
                  <span>
                    {t("header_credit_txt_1_1")}{" "}
                    <strong style={{ color: "#f3c74d" }}>
                      ${Math.floor(2500 - this.state.wagered_amount)}
                    </strong>{" "}
                    {t("header_credit_txt_1_2")}
                  </span>
                ) : (
                  <span>{t("header_credit_txt_2")}</span>
                )}
              </h6>
            </div>
            <span className="encryption encryption2">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="lock"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-lock fa-w-14"
              >
                <path
                  fill="currentColor"
                  d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                ></path>
              </svg>
              256-bit {t("encryption")}
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

Credit.propTypes = {
  setCoin: PropTypes.func.isRequired,
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(
  withRouter(Credit)
);
