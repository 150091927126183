import React, { Component } from 'react'
import {Modal, Dropdown, Row, Col} from "react-bootstrap";
import {__, forceSatoshiFormat, decode, encode, wait, Event, isMobile, sendNotfication} from "../../../../Helper";
import C from "../../../../Constant";
import socket from "../../../../Socket";

class Main extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            promo_code: '',
            paddingHeader: '',
            disabled: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(isMobile()){
            this.setState({ padding: 'p-1', paddingHeader: 'px-2' });
        }
        socket.on(C.UPDATE_PROMO, data => {
            let result = decode(data);
            if (result.success) {
                sendNotfication(this.props.t(result.msg), 'success','bottom-left');
                this.setState({disabled: true});
            } else {
                sendNotfication(this.props.t(result.msg), 'danger','bottom-left');
            }
        })
    }

    handleClose() {
        this.setState({ show: false })
    }

    handleSubmit() {
        if (this.state.promo_code == "" || this.state.promo_code == null) {
            sendNotfication(this.props.t('please_type_promotional_code'), 'danger','bottom-left');
            return;
        }
        socket.emit(C.UPDATE_PROMO, encode({code: this.state.promo_code}));
    }

    componentWillUnmount() {
        socket.off(C.UPDATE_PROMO);
    }

    render() {
        const { t } = this.props;
        return(
            <>
                <button onClick={ e => this.setState({ show: !this.state.show }) } className={"dropdown-item reedem-wrapper"}>
                   <svg className="mr-2 text-drop redeemicon" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15px" height="15px" fill="#7a88af" stroke="#7a88af" strokeWidth="0.00512">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"> <g> 
                                <rect y="94.103" width="48.569" height="323.795"></rect> 
                                <rect x="72.854" y="94.103" width="24.284" height="323.795"></rect>
                                <polygon style={{fill: "#7a88af!important"}} points="121.423,94.103 121.423,417.897 145.708,417.897 169.992,417.897 169.992,94.103 145.708,94.103 "></polygon> 
                                <polygon points="194.277,94.103 194.277,417.897 218.561,417.897 242.846,417.897 242.846,94.103 218.561,94.103 "></polygon> 
                                <rect x="267.13" y="94.103" width="24.285" height="323.795"></rect> 
                                <polygon points="339.984,94.103 315.7,94.103 315.7,417.897 339.984,417.897 364.269,417.897 388.554,417.897 388.554,94.103 364.269,94.103 "></polygon> 
                                <polygon points="412.838,94.103 412.838,417.897 437.122,417.897 462.083,417.897 462.083,94.103 437.122,94.103 "></polygon> 
                                <rect x="487.044" y="94.103" width="24.956" height="323.795"></rect> 
                            </g> 
                        </g>
                    </svg>
                    {t('reedem')}
                </button>
                <Modal
                    size={'lg'}
                    backdrop={'static'}
                    centered={true}
                    scrollable={false}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-md-modal"
                >
                    <Modal.Header className={this.state.paddingHeader}>
                        {t('reedem')}
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={ this.state.padding + ' wallet-modal redeemmodal'}>
                        <label>{t('enter_promo_code')}*</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.promo_code}
                            onChange={(e) =>
                            this.setState({ promo_code: e.target.value })
                            }
                        />
                        <button
                              type="submit"
                              className="btn mt-3 btn-purple px-4 no-shadow promocodebutton"
                              style={{marginTop: '10px'}}
                              disabled={this.state.disabled}
                              onClick={this.handleSubmit}
                        >
                                {t('redeem_promotion')}
                        </button>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Main;
