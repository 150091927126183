import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SummerDrops from "./SummerDrops";
import HighRoller from "./HighRoller";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
    };
  }
  componentDidMount() {
    let name = this.props.params.pathname;
    name = _.replace(name, "/tournament/", "");

    let content;
    if (name === "summer-drops") {
      content = <SummerDrops t={this.props.t} />;
    } else if (name === "high-roller") {
      content = <HighRoller t={this.props.t} />;
    }

    this.setState({ content });
  }
  componentWillMount() {}

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>Tournament</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/tournament" />
        </Helmet>
        <div className="tournament-wrapper main-tournaments">
          {this.state.content}
        </div>
      </>
    );
  }
}
