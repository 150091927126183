import React from "react";
import {Row, Col} from "react-bootstrap";
import UAParser from "ua-parser-js";
import {decode, encode, wait, isEmail, sendNotfication} from "../../../../Helper";

class Parent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null
        };
    }

    componentDidMount(){
        var parser = new UAParser();
        var result = parser.getResult();
        var info = `${this.props.t('browser')}: ${result.browser.name} ${this.props.t('version')}: ${result.browser.version} / OS: ${result.os.name} ${this.props.t('version')}: ${result.os.version} / ${this.props.t('user_agent')}: ${result.ua}`;
        this.setState({ result: info });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Row>
                    <Col md={12}>
                        <div className="alert alert bg-soft-info cs2 font-14 l30 text-muted">
                            {this.state.result}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Parent;
