import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, InputGroup, Form, Col, Button } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  Event,
  __,
  isLogged,
  funPlay,
  isValidNumber,
  wait,
  forceSatoshiFormat,
  sendNotfication,
  playAudio,
  defaultBetAmount,
  getDefaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
      buttonText: "Bet",
      formData: {
        payout: "1.98",
        chance: 50,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.outRef = React.createRef();
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;

    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);

    try {
      if (started) document.getElementById("bet").click();
    } catch (e) {}
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside2);

    this.props.gameCoin();
    engine.on("play", (data) => this.play(data));
    engine.on("busted", () => this.busted());
    engine.on("error", (data) => this.error(data));
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      let value = parseFloat(this.state.formData.payout);

      if (value < 1.01) value = 1.01;

      if (value > 1000000) value = 1000000;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          payout: parseFloat(value).toFixed(2),
        },
      }));
    }
  }

  handleClickOutside2(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  play(data) {
    if (this._isMounted) {
      let isWin = false;
      if (parseFloat(data.profit) !== 0 && parseFloat(data.profit) !== 0.0) {
        isWin = true;
      }

      this.setState({ isWin: isWin });

      this.setCanvas(data.target);

      wait(500).then(() => {
        if (isWin) {
          playAudio("win.mp3");
        }
      });
    }
  }

  busted() {
    if (this._isMounted) {
      wait(500).then(() => {
        this.setState({ inputDisabled: false });
      });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;
      let { payout, chance } = this.state.formData;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.payout = payout;
        engine.chance = chance;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  handleBet(e) {
    e.preventDefault();
    let { amount, bet, started } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }
    this.setState({ inputDisabled: true });
    this.placeBet();
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    if (target.name === "payout") {
      if (!isValidNumber(value)) return;
    }

    if (target.name === "payout") {
      if (value < 1.01) value = 1.01;
      if (value > 1000000) value = 1000000;
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          payout: value,
        },
      }));
      this.setChance(value);
      return;
    }
    this.setState({ [target.name]: value });
  }

  showAnimation(busted) {
    let self = this;
    var counter = 50;
    var interval = setInterval(function () {
      counter--;
      document.querySelector(".rocket-number").innerText =
        counter.toFixed(2) + "x";
      if (counter == 0) {
        document.querySelector(".rocket-number").innerText = busted + "x";
        if (self.state.isWin) {
          document.querySelector(".rocket-number").style.color = "#c8ff4d";
        } else {
          document.querySelector(".rocket-number").style.color = "#ff4949";
        }
        clearInterval(interval);
      }
    }, 1);
  }

  setCanvas(busted) {
    if (this._isMounted) {
      let self = this;

      let SEC = 30,
        LIMIT = 100,
        plused = 0,
        start = 0,
        cnt = 0;

      let progress = setInterval(function () {
        let cnt_str = cnt < 10 ? "0" + cnt : "" + cnt;

        if (cnt_str >= 99) {
          plused = parseInt(start + 1);
          start++;
        }

        if (document.querySelector(".rocket-body") !== null)
          document.querySelector(".rocket-body").style.bottom = cnt * 35 + "px";

        if (cnt >= 13) {
          clearInterval(progress);

          if (document.querySelector(".rocket-number") !== null) {
            self.showAnimation(busted);
          }
          if (document.querySelector(".rocket-body") !== null) {
            document.querySelector(".rocket-body").style.bottom = "0px";
          }
        }

        cnt = (cnt + 1) % LIMIT;
      }, SEC);
    }
  }

  betMode = (type) => {
    this.setState({ bet: type });
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  setNumber = (e, num) => {
    e.preventDefault();
    this.setState({ betNumber: num, firstBetNumber: num });
  };

  setChance(value) {
    var c = ((1 / value) * 99).toFixed(5);
    c = Math.max(c, 0.0001);

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        chance: c,
      },
    }));
  }

  changePayout(type) {
    let { payout } = this.state.formData;
    var value;
    if (type === "up") {
      value = parseFloat(payout) + 1;
    } else {
      value = parseFloat(payout) - 1;
    }
    
    if (value < 1.01) value = 1.01;
    if (value > 1000000) value = 1000000;
    
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        payout: value,
      },
    }));

    this.setChance(value);
  }

  render() {
    let { bet, started, inputDisabled } = this.state;
    return (
      <>
        <form
          className="w-100"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={5} md={8} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  type="number"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={4} md={4} sm={12}>
              <InputGroup className="mb-1 bet-input">
                <InputGroup.Text>{this.props.t("payout")}</InputGroup.Text>
                <Form.Control
                  ref={this.wrapperRef}
                  disabled={inputDisabled}
                  type="text"
                  className="form-control text-left"
                  id="payout"
                  name="payout"
                  placeholder=".."
                  value={this.state.formData.payout}
                  autoComplete={"off"}
                  onChange={this.handleInputChange}
                  required={true}
                ></Form.Control>
                <InputGroup.Text>
                  <Button
                    onClick={() => this.changePayout("down")}
                    variant="btn btn-sm bg-cs2 after-text no-hover npt nh cpt"
                    type="button"
                  >
                    <i className="mdi mdi-arrow-left-bold" />
                  </Button>
                  <Button
                    onClick={() => this.changePayout("up")}
                    variant="btn btn-sm bg-cs2 after-text no-hover npt nh cpt"
                    type="button"
                  >
                    <i className="mdi mdi-arrow-right-bold" />
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={4} sm={12}>
              <InputGroup className="mb-1 bet-input">
                <InputGroup.Text>Win Chance</InputGroup.Text>
                <Form.Control
                  disabled={true}
                  type="number"
                  className="form-control text-left"
                  id="chance"
                  name="chance"
                  placeholder=".."
                  value={this.state.formData.chance}
                ></Form.Control>
                <InputGroup.Text>
                  <Button
                    variant="btn bg-cs2 after-text no-hover npt nh"
                    type="button"
                  >
                    %
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={12} className="m-auto">
              {bet === "manual" && (
                <Button
                  variant={"btn btn-md btn-block my-1 btn-bet"}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              )}
            </Col>
          </Row>
        </form>
        {bet === "auto" && (
          <AutoBet
            engine={this.state.engine}
            formData={this.state.formData}
            amount={this.state.amount}
          />
        )}
        <div
          className="btn-group btn-group-toggle mb-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={started}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={started}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
