import { Row, Col, Card } from "react-bootstrap";

export default function Loading(props) {
  let classList = props.classList ? props.classList : "l-2";

  let messages = props.messages ? props.messages : "";

  return (
    <Row className={"animated fadeIn ovh"}>
      <Col className={"m-auto"}>
        <Card className="mb-0">
          <Card.Body className={"p-1"} id="roll-panel">
            <Row>
              <Col sm={12} md={10} className={"m-auto"}>
                <div className={`game-loader text-center ${classList}`}>
                  <div
                    className="spinner-grow text-purple text-info my-3"
                    role="status"
                  />
                  <h5 className="ml-2">{messages}</h5>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
