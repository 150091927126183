import React from 'react'
import { Modal } from "react-bootstrap";
import { Event } from "../../../Helper";
export default class Contestinfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <button onClick={this.handleShow} className={'btn btn- btn-sm infocontest'}>
                    <span className="dripicons-information text-muted"></span>
                </button>
                <Modal
                    size="lg"
                    backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="affiliate-lg-modal"
                    className="dailycontestmodal"
                >
                    <Modal.Header className={'font-light'}>
                        {t('prize_calculation_formula')}
                        <button type="button" className="close" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className={'modal-reader'}>
                        <p className={'text-white font-light mb-2 font-14'}>
                            <ul className="font-15 p-0 text-white chatinfo">
                                <li>{t('1st_daily_contest_prize_pool')}</li>
                                <li>{t('2st_daily_contest_prize_pool')}</li>
                                <li>{t('3st_daily_contest_prize_pool')}</li>
                                <li>{t('4st_daily_contest_prize_pool')}</li>
                                <li>{t('5st_daily_contest_prize_pool')}</li>
                                <li>{t('6st_daily_contest_prize_pool')}</li>
                                <li>{t('7st_daily_contest_prize_pool')}</li>
                                <li>{t('8st_daily_contest_prize_pool')}</li>
                                <li>{t('9st_daily_contest_prize_pool')}</li>
                                <li>{t('10st_daily_contest_prize_pool')}</li>
                            </ul>
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
