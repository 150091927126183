import * as PIXI from "pixi.js";
import { wait } from "../Helper/index.js";

class Message {
  constructor(m, opt = {}) {
    this.message = m;
    this.opt = opt;
    this.object = null;
    this.ticker = null;

    this.show();
  }

  show() {
    const style = new PIXI.TextStyle({
      fontFamily: "Roboto",
      fontSize: 40,
      fontStyle: "italic",
      fontWeight: "bold",
      fill: ["#000", "#333"], // gradient
      stroke: "yellow",
      strokeThickness: 5,
      dropShadow: true,
      dropShadowColor: "#000000",
      dropShadowBlur: 4,
      dropShadowAngle: Math.PI / 6,
      dropShadowDistance: 6,
      wordWrap: true,
      wordWrapWidth: 440,
      lineJoin: "round",
    });

    const msg = new PIXI.Text(this.message, style);
    msg.anchor.set(0.5);
    msg.x = this.opt.x;
    msg.y = this.opt.y;
    msg.zIndex = 1111;
    msg.interactive = true;
    this.object = msg;
  }

  animate(delay, callback) {
    this.ticker = new PIXI.Ticker();

    let self = this;

    if (typeof delay === "function") {
      callback = delay;
      delay = 0;
    }

    var c = function (delta) {
      return callback(self.object, delta);
    };

    wait(delay).then(() => {
      this.ticker.add(c);
      this.ticker.start();
    });
  }

  stop() {
    if (this.ticker !== null) this.ticker.stop();
  }
}

export default Message;
