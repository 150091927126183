import React, { Component, createRef } from 'react';
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import {Event, wait, decode, encode, sendNotfication} from "../../../Helper";
import C from "../../../Constant";
class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
        };
    }
    componentDidMount() {
        socket.emit(C.GET_BLOGS);
        socket.on(C.GET_BLOGS, data => {
            let decoded = decode(data);
            if (decoded && decoded.length > 0) {
                this.setState({blogs: decoded});
            }
        });
    }
    componentWillUnmount() {
        socket.off(C.GET_BLOGS);
    }
    render() {
        return (
            <>
              <Helmet>
                <title>Help Center | Crypto Casino Games</title>
                <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                <link rel="canonical" href="/blog" />
              </Helmet>
                <Accordion defaultActiveKey="0" flush data-bs-theme="dark" className="my-accordion">
                    {
                        this.state.blogs.map((blog, index) => {
                            return (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{blog.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: blog.description }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </>
        );
    }
}

export default withRouter(Blog);
