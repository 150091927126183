import React from "react";
import { __, wait, isMobile, isTablet } from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      canvas: null,
      interval: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    //Check player in game
    engine.on("ready", () => {
      wait(500).then(() => {
        engine.ws.emit(C.CHECK_THREE_CARD_MONTE);
      });
    });

    engine.on("play", () => this.play());
    engine.on("busted", (data) => this.busted(data));

    var canvas = new Canvas(engine);
    canvas.init();

    this.setState({ canvas: canvas });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  play() {
    if (this._isMounted) {
      this.state.canvas.play();
    }
  }

  busted(data) {
    if (this._isMounted) {
      this.state.canvas.busted(data.result, data.win);
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn">
          <div id="loading" className="ycenter" />
          <canvas id="game" />
        </div>
      </>
    );
  }
}

export default Game;
