import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Script from "./Script";

const E_ = React.createElement;

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
  }

  handleShow() {
    this.setState({ show: true, effect: "zoomIn" });
  }

  submitForm(e) {
    e.preventDefault();
    const { name, content } = this.state;

    var data = { name, content };

    var script = new Script();

    script.set(data);

    this.handleClose();
  }

  render() {
    return (
      <>
      <ReactTooltip />
        <Button
        //   onClick={this.handleShow}
          variant="- btn-purple shadow-none btn-sm"
          size="md"
          data-tip={"Under maintenance"}
        >
          <i className="mdi mdi-plus" /> Add Script
        </Button>
        <Modal
          size="md"
          backdrop="static"
          centered={true}
          show={this.state.show}
          onHide={this.handleClose}
          className={"animated " + this.state.effect}
        >
          <Modal.Header>
            Add Script
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={(e) => {
                this.submitForm(e);
              }}
            >
              <div className="form-group ">
                <label>Script Name</label>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Enter script name"
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                  <span className="input-group-append" />
                </div>
              </div>
              <div className="form-group ">
                <label>Script Codes</label>
                <div className="input-group">
                  <textarea
                    rows="8"
                    placeholder="Enter script codes"
                    resizeable={true}
                    resize={true}
                    spellCheck={true}
                    className="form-control"
                    value={this.state.content}
                    onChange={(e) => this.setState({ content: e.target.value })}
                  />
                  <span className="input-group-append" />
                </div>
              </div>
              <div className="form-group text-center mb-0">
                <Button type="submit" variant="purple" size="md" block>
                  <i className="mdi mdi-content-save-all mr-1" />
                  Save
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Add;
