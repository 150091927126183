import * as PIXI from "pixi.js";
import { Spine, SpineParser } from "pixi-spine";
import * as particles from "@pixi/particle-emitter";
import { gsap } from "gsap";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Effect from "./Effect";
import {
  __,
  wait,
  Game,
  Event,
  secureRandomNumber,
  cryptoToUsd,
} from "../../../../Helper";

const counterFont = "Roboto";
const AXIS_FONT = "Roboto";

class Canvas extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      gameCanvas: new Crash(),
      canvasWidth: 600,
      canvasHeight: 400,
      hash: null,
      status: "",
      timer: "",
      rate: "",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.handleResize();
      this.setState({ width: window.innerWidth });
      window.addEventListener("resize", this.handleResize);

      wait(500).then(() => {
        this.initCanvas();
      });

      let { engine } = this.state;

      engine.on("game_status", (data) => this.status(data));
      engine.on("finish_crash", (data) => this.syncFinishPlayer(data));
      engine.on("waiting_crash", (data) => this.waiting(data));
      engine.on("started_crash", (data) => this.started(data));
      engine.on("busted_crash", (data) => this.busted(data));

      Event.on("connect", () => {
        engine.getStatus();
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.state.gameCanvas.destroy();
    if (document.getElementById("game") !== null)
      document.getElementById("game").remove();
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ width: window.innerWidth });
    wait(500).then(() => {
      if (!__.isUndefined(document.getElementsByClassName("-crash")[0])) {
        let updatedCanvasWidth = document
          .getElementsByClassName("-crash")[0]
          .getBoundingClientRect().width;
        this.setState({ canvasWidth: updatedCanvasWidth });
        this.setState({ canvasHeight: (updatedCanvasWidth * 250) / 600 });
      }
    });
  }

  initCanvas = () => {
    if (this._isMounted) {
      this.state.gameCanvas.init(
        this.state.canvasWidth,
        this.state.canvasHeight
      );
    }
  };

  syncFinishPlayer(data) {
    if (this._isMounted) {
      this.state.gameCanvas.winedPlayer(data);
    }
  }

  waiting(data) {
    if (this._isMounted) {
      let { time } = data;
      this.setState({ status: "waiting" });
      this.state.gameCanvas.setWaiting(time);
    }
  }

  started(data) {
    if (this._isMounted) {
      let { time, md5 } = data;
      this.setState({ status: "started" });
      this.state.gameCanvas.setStarted(time, md5);
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { amount, time, force } = data;
      let rate = (amount / 100).toFixed(2);
      this.state.gameCanvas.setBusted(rate, time);
      this.setState({
        status: "busted",
        timer: time,
        rate: rate,
        force: force,
      });
    }
  }

  status(data) {
    if (this._isMounted) {
      let { amount, time, md5, status } = data;

      this.setState({ status: status, timer: time, connecting: false });

      switch (status) {
        case "started":
          this.state.gameCanvas.setStarted(time, md5);
          break;

        case "waiting":
          this.state.gameCanvas.setWaiting(time);
          break;

        case "busted":
          let rate = (amount / 100).toFixed(2);
          this.state.gameCanvas.setBusted(rate, time);
          break;
      }
    }
  }

  render() {
    return (
      <Card className={"no-shadow mb-1"}>
        <Card.Body
          className="game-aria text-center no-shadow pb-3 static-snow pt-0 p-0"
          id="canvasPart"
        >
          <div className="game-holder">
            <Card className={"mb-1 bg-transparent"}>
              <Card.Body className={"p-0"}>
                <div className="-crash rev">
                  <canvas id="game" />
                </div>
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

class Crash {
  constructor() {
    PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;
    this.start_time = 0;
    this.nextround_text = "Starting in {time}s";

    this.States = {
      Loading: "Loading",
      Starting: "TakingBets",
      Active: "Running",
      Over: "Over",
    };
    this.state = this.States.Loading;
    this.startedTime = 0;
    this.particleElapsed = Date.now();
    this.elapsedTime = 0;
    this.finalElapsed = 0;
    this.finalMultiplier = 0;
    this.waitingRocket = null;
    this.crashPoint = null;
    this.betAmount = 0;
    this.graphWidth = 0;
    this.graphHeight = 0;
    this.plotWidth = 0;
    this.plotHeight = 0;
    this.plotOffsetX = 0;
    this.plotOffsetY = 0;
    this.xAxis = 0;
    this.yAxis = 0;
    this.xIncrement = 0;
    this.yIncrement = 0;
    this.xAxisMinimum = 1e3;
    this.yAxisMinimum = -1;
    this.elapsedOffset = 0;
    this.yAxisMultiplier = 1.5;
    this.lastGameTick = null;
    this.tickTimeout = null;
    this.lag = !1;
    this.lastGameTick = null;
    this.lagTimeout = null;
    this.xTickWidth = 10;
    this.xLabelIndex = 0;
    this.yTickWidth = 15;
    this.yLabelIndex = 1;
    this.CrashSpeed = 6e-5;
    this.PredictingLapse = 500;
    this.RocketVisibleStates = [this.States.Active, this.States.Over];
    this.isMobile = !1;
    this.isLarge = !1;
    this.rocketsConfig = JSON.parse(
      '{"rockets":{"default":{"background":"/images/games/crash/background-2.png","skins":["./assets/images/crash/rockets/rocket_1.png","./assets/images/crash/rockets/rocket_2.png","./assets/images/crash/rockets/rocket_3.png","./assets/images/crash/rockets/rocket_4.png","./assets/images/crash/rockets/rocket_5.png","./assets/images/crash/rockets/rocket_6.png","./assets/images/crash/rockets/rocket_7.png","./assets/images/crash/rockets/rocket_8.png","./assets/images/crash/rockets/rocket_9.png","./assets/images/crash/rockets/rocket_10.png"],"emitter":{"lifetime":{"min":0.7,"max":0.9},"frequency":0.001,"emitterLifetime":0,"maxParticles":500,"addAtBack":false,"pos":{"x":0,"y":0},"behaviors":[{"type":"alpha","config":{"alpha":{"list":[{"value":0.62,"time":0},{"value":0,"time":1}],"isStepped":false}}},{"type":"moveSpeed","config":{"speed":{"list":[{"value":300,"time":0},{"value":250,"time":0.7},{"value":250,"time":1}],"isStepped":true},"minMult":1}},{"type":"scale","config":{"scale":{"list":[{"value":0.19,"time":0},{"value":0.2,"time":1}],"isStepped":false},"minMult":1}},{"type":"color","config":{"color":{"list":[{"value":"fff191","time":0},{"value":"ff622c","time":0.6},{"value":"111111","time":0.8},{"value":"333333","time":1}],"isStepped":false}}},{"type":"rotation","config":{"accel":0,"minSpeed":50,"maxSpeed":50,"minStart":70,"maxStart":90}},{"type":"textureRandom","config":{"textures":["./assets/images/crash/tail/particle.png","./assets/images/crash/tail/fire.png"]}},{"type":"spawnShape","config":{"type":"torus","data":{"x":0,"y":0,"radius":5,"innerRadius":0,"affectRotation":false}}}]}}}}'
    );
    this.currentRocketConfig = null;
    this.bg = null;
    this.particle = null;
    this.tailEmitter = null;
    this.rocketBody = null;
    this.yAxisMarker = new yAxisMarker(this);
    this.newTailEmitter = null;
    // this.cashoutYAxisMarker = new yAxisMarker(this, 5025616);
    this.currentViewKey = "active";
    this.cnt = 0;
    this.coins = {
      usdt: "./assets/images/crash/usdt.png",
      btc: "./assets/images/crash/btc.png",
      eth: "./assets/images/crash/eth.png",
      bch: "./assets/images/crash/bch.png",
      ltc: "./assets/images/crash/ltc.png",
      doge: "./assets/images/crash/doge.png",
    };
    this.cashouts = [];
    this.pe = 600;
    this.lastCashoutTick = null;
  }

  getRocketConfig() {
    var t = this.rocketsConfig.rockets.default;
    return {
      ...t,
      emitter: t.emitter,
    };
  }

  updateRocketConfig() {
    this.currentRocketConfig = this.getRocketConfig();
  }

  createSeededRocket() {
    var currentRocketConfig = this.currentRocketConfig;
    var skins = currentRocketConfig.skins;
    var emitter = currentRocketConfig.emitter;

    // var randomIndex = Math.floor(Math.random() * 10);
    var randomIndex = Math.floor(secureRandomNumber() * 10);
    var selectedSkin = skins[randomIndex];
    return this.createRocket(selectedSkin, emitter);
  }

  createRocket(selectedSkin, emitter) {
    var RocketContainer = new PIXI.Container();
    this.rocketBodyContainer = new PIXI.Container();
    this.rocketTailContainer = new PIXI.Container();
    this.rocketBody = PIXI.Sprite.from(selectedSkin);
    this.rocketBody.anchor.set(0.5, 0);
    this.rocketBodyContainer.addChild(this.rocketBody);
    this.rocketBodyContainer.scale.x = this.rocketBodyContainer.scale.y = 0.4;
    RocketContainer.sortableChildren = true;
    // this.rocketBody.visible = false;
    // this.tailEmitter = new particles.Emitter(this.rocketTailContainer, emitter);
    this.rocketTailContainer.scale.x = this.rocketTailContainer.scale.y = 0.75;
    this.rocketBody.texture.on("update", () => {
      this.rocketTailContainer.y = this.rocketBodyContainer.height;
    });
    // this.rocketTailContainer.y = this.rocketBodyContainer.height;
    this.newTailEmitter = new NewTail(this.rocketTailContainer);
    this.rocketTailContainer.angle = 180;
    RocketContainer.addChild(
      this.rocketTailContainer,
      this.rocketBodyContainer
    );
    return RocketContainer;
  }

  configApp(width, height) {
    (this.graphWidth = width),
      (this.graphHeight = height),
      (this.plotOffsetX = 50),
      (this.plotOffsetY = 40),
      (this.plotWidth = width - this.plotOffsetX),
      (this.plotHeight = height - this.plotOffsetY);
    this.yAxisMarker.onResize();
  }

  updateRain() {
    var t,
      e = this.graphWidth,
      r = this.graphHeight,
      i = this.rainEmitter,
      n = this.rainContainer,
      a = "active" === this.currentViewKey,
      o = i.getBehavior("spawnShape");
    (o.shape.x = 0), (o.shape.y = -r), (o.shape.w = e), (o.shape.h = 2 * r);
    var s = a ? 60 : 0;
    (i.maxParticles = a ? 50 : 100),
      (window.rainEmitter = i),
      (i.minLifetime = i.maxLifetime = a ? 0.5 : 1),
      this.updateRainSpeed(a ? 500 : 50),
      null === (t = this.rainAnimation) || void 0 === t || t.pause();
    s !== n.angle &&
      (this.rainAnimation = gsap.timeline({
        angle: s,
        targets: n,
        easing: "easeOutExpo",
        duration: 1e3,
      }));
  }

  updateRainSpeed(t) {
    var e,
      r =
        null === (e = this.rainEmitter) || void 0 === e
          ? void 0
          : e.getBehavior("moveSpeedStatic");
    r.min = r.max = t;
  }

  resize() {
    if (this.app) {
      // Get the p
      const parent = this.app.view.parentNode;

      // Resize the renderer
      this.app.renderer.resize(
        parent.clientWidth,
        (parent.clientWidth * 400) / 600
      );
      this.configApp(parent.clientWidth, (parent.clientWidth * 400) / 600);
      this.counter.x = this.plotWidth / 2;
      this.counter.y = this.plotHeight / 2;
      if (this.game_status === "waiting") {
        this.clearAxis();
      } else {
        this.clearAxis();
        this.tick();
        this.drawX();
        this.drawY();
        var t = this.getElapsedPosition(this.elapsedTime);
        this.drawLine(this.mainLine, 9073604, 0, this.elapsedTime, t.x);
      }
    }
  }

  destroy() {
    this.app = null;
    this.gameContainer = null;
  }

  init(width, height) {
    this.updateRocketConfig();
    this.configApp(width, height);
    this.app = new PIXI.Application({
      width: width,
      height: height,
      resolution: 1,
      transparent: true,
      autoResize: true,
      view: document.getElementById("game"),
    });
    this.width = width;
    this.height = height;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.app.loader.use(SpineParser.use);
    this.app.loader
      .add(
        "explosions",
        "./assets/images/crash/explosion_atlas/explosions.json"
      )
      .add("point_red", "./assets/images/crash/point_red.png")
      .add("point_green", "./assets/images/crash/point_green.png")
      .add("point_yellow", "./assets/images/crash/point_yellow.png")
      .add("btc", "./assets/images/crash/btc.png")
      .add("eth", "./assets/images/crash/eth.png")
      .add("usdt", "./assets/images/crash/usdt.png")
      .add("bch", "./assets/images/crash/bch.png")
      .add("ltc", "./assets/images/crash/ltc.png")
      .add("doge", "./assets/images/crash/doge.png");
    this.app.loader.load((e) => this.load());
  }

  load(loader, res) {
    if (this.app === null) return;
    this.explosions = new Spine(this.app.loader.resources.explosions.spineData);
    this.explosions.scale.set(0.5);
    this.explosions.visible = !1;
    (this.explosions.x = -15), (this.explosions.y = this.plotHeight);
    this.app.stage.sortableChildren = true;
    this.gameContainer = new PIXI.Container();
    this.rainContainer = new PIXI.Container();
    this.labelsContainer = new PIXI.Container();
    this.textGraphics = new PIXI.Graphics();
    this.lagText = new PIXI.Text("crash.lagDetected", {
      fontFamily: AXIS_FONT,
      fontWeight: "500",
      fontSize: 14,
      fill: 15121995,
    });
    this.lagText.visible = this.lag;
    this.lagText.anchor.set(0);
    this.lagText.x = 5;
    this.lagText.y = 5;
    this.lagText.alpha = 0.5;

    this.gameContainer.interactive = true;
    var _self = this;
    this.gameContainer.on("mousemove", function (e) {
      var r = e.data.global,
        i = r.x,
        n = r.y,
        o = _self.graphWidth,
        s = _self.graphHeight,
        l = n >= 0 && i >= 0 && i <= o && n < s;
      if (_self.state == _self.States.Active)
        _self.yAxisMarker.container.visible = l;
      else _self.yAxisMarker.container.visible = !1;
      (_self.yAxisMarker.targetY = n + _self.yAxisMarker.tooltipHeight / 2),
        _self.yAxisMarker.container.visible ||
          (_self.yAxisMarker.y = n > s / 2 ? s : 0);
    });

    // Create an empty array to store the text labels
    this.xLabels = [];

    // Loop 20 times to create 20 text labels
    for (var r = 0; r < 20; r++) {
      // Create a new text label using PIXI.Text
      var label = new PIXI.Text("0s", {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "500",
        fill: 6250869,
      });

      // Set the visibility of the label to false
      label.visible = false;

      // Set the anchor point of the label to the center (horizontally) and top (vertically)
      label.anchor.set(0.5, 0);

      // Add the label to the labelsContainer (assuming labelsContainer exists)
      this.labelsContainer.addChild(label);

      // Add the label to the xLabels array
      this.xLabels.push(label);
    }
    // Create an empty array to store the text labels
    this.yLabels = [];

    // Loop 50 times to create 50 text labels
    for (var r = 0; r < 50; r++) {
      // Create a new text label using PIXI.Text
      var label = new PIXI.Text(r === 0 ? "1.00x" : "", {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "500",
        fill: 6250869,
      });

      // Set the visibility of the label to false
      label.visible = false;

      // Set the anchor point of the label to the left (horizontally) and center (vertically)
      label.anchor.set(0, 0.5);

      // Set the y position of the label to 100 (assuming labelsContainer exists)
      label.y = 100;

      // Add the label to the labelsContainer
      this.labelsContainer.addChild(label);

      // Add the label to the yLabels array
      this.yLabels.push(label);
    }

    this.configWaitingRocket();

    this.mainLine = new PIXI.Graphics();
    this.xGraphics = new PIXI.Graphics();
    this.yGraphics = new PIXI.Graphics();
    // this.configBg();
    this.createRainEmitter();
    this.configRocketContainer();
    this.configNumber();
    this.configPrepareRound();
    this.configCurrentPayout();
    this.updateRain();
    this.cashoutsContainer = new PIXI.Container();
    this.rainEmitter.emit = true;

    this.gameContainer.addChild(
      this.xGraphics,
      this.yGraphics,
      this.labelsContainer,
      this.mainLine,
      this.cashoutsContainer,
      this.rocket,
      this.explosions,
      this.lagText,
      this.counter,
      this.preparinground,
      this.currentpayout
    ),
      this.yAxisMarker &&
        this.gameContainer.addChild(this.yAxisMarker.container);
    this.gameContainer.sortableChildren = true;
    this.app.stage.addChild(this.gameContainer, this.rainContainer);
    this.app.ticker.add((delta) => this.animation(delta, 0));
  }

  animation(delta, count) {
    var now = Date.now();
    if (this.rainEmitter) {
      this.rainEmitter.update((now - this.particleElapsed) * 0.001);
    }
    this.updateRain();

    if (this.game_status === "waiting") {
      this.xGraphics.clear();
      this.yGraphics.clear();
      this.mainLine.visible = !1;
      this.drawWaitingText();
      this.drawPrepareText();
      this.rocket.visible = !1;
      this.clearAxis();
      this.rainContainer.angle = 0;
    }

    if (this.game_status === "started") {
      var multiplier = Math.pow(
        Math.E,
        this.CrashSpeed * this.elapsedTime
      ).toFixed(2);
      this.tick();
      this.drawX();
      this.drawY();
      this.drawRunningText(multiplier);
      this.drawCurrentPayout();
      var t = this.getElapsedPosition(this.elapsedTime),
        e = t.x,
        r = t.y;
      this.rocket.x = e;
      this.rocket.y = r;
      this.rocket.visible = true;
      this.rocket.angle =
        this.getRocketAngle(e, r) > this.rocket.angle
          ? this.rocket.angle
          : this.getRocketAngle(e, r);
      this.drawLine(this.mainLine, 9073604, 0, this.elapsedTime, e);
      this.rainContainer.angle = this.rocket.angle;
      this.newTailEmitter.render();
      if (this.yAxisMarker && this.yAxisMarker.container.visible) {
        this.yAxisMarker.tick();
      }
      this.updateCashouts();
    }

    if (this.game_status === "busted") {
      this.mainLine.visible = !1;
    }
    this.particleElapsed = now;
  }

  clearAxis() {
    this.xGraphics.clear();
    this.xLabels.map((label) => (label.visible = !1));
    this.yGraphics.clear();
    this.yLabels.map((label) => (label.visible = !1));
  }

  configBg() {
    this.bg = PIXI.Sprite.from("./assets/images/crash/bg.jpg");
    this.bg.alpha = 0.07;
    if (!__.isUndefined(document.getElementById("game"))) {
      this.bg.width = document
        .getElementById("game")
        .getBoundingClientRect().width;
      this.bg.height = document
        .getElementById("game")
        .getBoundingClientRect().height;
    }
  }

  configWaitingRocket() {
    this.gameContainer.removeChild(this.waitingRocket);
    this.waitingRocket = new PIXI.Sprite(
      PIXI.Texture.from("./assets/images/crash/rockets/rocket_2.png")
    );

    this.waitingRocket.width = 80;
    this.waitingRocket.height = 101;
    this.waitingRocket.y = 550;
    this.waitingRocket.x = this.centerX / 1.1;
    // this.waitingRocket.visible = false;
  }

  configRocketContainer() {
    this.rocket = this.createSeededRocket();
    this.rocket.angle = 90;
    this.rocket.y = 0;
    this.rocket.x = 0;
    this.rocket.visible = false;
  }

  configNumber() {
    const style = new PIXI.TextStyle({
      fontFamily: counterFont,
      fontSize: 100,
      fontWeight: 800,
      fill: "#FFFFFF",
    });
    this.counter = new PIXI.Text(1.0 + "x", style);
    this.counter.anchor.set(0.5);
    this.counter.x = this.plotWidth / 2;
    this.counter.y = this.plotHeight / 2;
    this.counter.interactive = true;
  }

  configPrepareRound() {
    const style = new PIXI.TextStyle({
      fontFamily: counterFont,
      fontSize: 100,
      fontWeight: 800,
      fill: "#FFFFFF",
    });

    this.preparinground = new PIXI.Text("", style);
    this.preparinground.anchor.set(0.5);
    this.preparinground.x = this.plotWidth / 2;
    this.preparinground.y = this.plotHeight / 2 - 10;
    this.preparinground.interactive = true;
  }

  configCurrentPayout() {
    const style = new PIXI.TextStyle({
      fontFamily: counterFont,
      fontSize: 100,
      fontWeight: 800,
      fill: "#FFFFFF",
    });

    this.currentpayout = new PIXI.Text("", style);
    this.currentpayout.anchor.set(0.5);
    this.currentpayout.x = this.plotWidth / 2;
    this.currentpayout.y = this.plotHeight / 2 + 55;
    this.currentpayout.interactive = true;
  }

  createRainEmitter() {
    this.rainEmitter = new particles.Emitter(this.rainContainer, {
      lifetime: {
        min: 1,
        max: 1,
      },
      frequency: 0.008,
      emitterLifetime: 0,
      maxParticles: 200,
      addAtBack: !1,
      pos: {
        x: 0,
        y: 0,
      },
      behaviors: [
        {
          type: "alpha",
          config: {
            alpha: {
              list: [
                {
                  time: 0,
                  value: 0,
                },
                {
                  time: 0.6,
                  value: 0.02,
                },
                {
                  time: 1,
                  value: 0,
                },
              ],
            },
          },
        },
        {
          type: "moveSpeedStatic",
          config: {
            min: 100,
            max: 100,
          },
        },
        {
          type: "scaleStatic",
          config: {
            min: 1,
            max: 1,
          },
        },
        {
          type: "rotationStatic",
          config: {
            min: 90,
            max: 90,
          },
        },
        {
          type: "textureRandom",
          config: {
            textures: ["./assets/images/crash/rain.419d73514d2d229d3fe5.png"],
          },
        },
        {
          type: "spawnShape",
          config: {
            type: "rect",
            data: {
              x: 0,
              y: -600,
              w: 800,
              h: 600,
            },
          },
        },
      ],
    });
  }

  getSize(t) {
    var e =
      Math.sqrt(
        this.plotWidth * this.plotWidth + this.plotHeight * this.plotHeight
      ) / 1800;
    return e > 1 ? (e = 1) : e < 0.4 && (e = 0.4), t * e;
  }

  getRocketAngle(t, e) {
    var r = this.getElapsedPosition(this.elapsedTime - 1e3),
      i = (180 * Math.atan2(r.x - t, r.y - e)) / Math.PI;
    return (i = i < 0 ? Math.abs(i) : 360 - i);
  }

  drawX() {
    var e = this.xAxis,
      r = this.plotWidth,
      i = this.plotHeight,
      n = this.stepValues(e, 5, 2),
      a = r / (e / n);
    (this.xLabelIndex = 0), this.xGraphics.clear();
    for (var o = 0, s = 0, l = 0; s < e; s += n, l++, o++) {
      if (o > 0) {
        var c = s / 1e3,
          u = 0 === o ? 4 : l * a,
          h = i;
        0.2 !== this.xGraphics._lineStyle &&
          this.xGraphics.lineStyle(2, 16777215, 0.25),
          this.updateXLabel(
            "".concat(c.toFixed(0), "s"),
            u,
            h + this.xTickWidth / 2
          ),
          this.xGraphics.moveTo(u, h - this.xTickWidth / 2),
          this.xGraphics.lineTo(u, h + this.xTickWidth - this.xTickWidth / 2);
      }
      if (o > 100) break;
    }
    for (var f = this.xLabelIndex + 1; f < this.xLabels.length; f++)
      this.xLabels[f].visible && (this.xLabels[f].visible = !1);
  }

  drawY() {
    var e = this.yAxis,
      r = this.plotWidth,
      i = this.plotHeight,
      n = this.multiplier,
      a = this.stepValues(n || 1),
      o = i / e,
      s = a * o,
      l = Math.max(2, Math.min(16, ~~(s / Math.max(3, e / a))));
    l % 2 != 0 && (l += 1),
      (this.yTickWasHighlighted = !1),
      (this.yLabelIndex = 0),
      this.yGraphics.clear();
    for (var c = a, u = 0; c < e; c += a, u++) {
      0.25 !== this.yGraphics._lineStyle &&
        this.yGraphics.lineStyle(2, 16777215, 0.25);
      var h = 5.5 + ~~r,
        f = i - c * o;
      this.yGraphics.moveTo(h - this.yTickWidth, f),
        this.yGraphics.lineTo(h, f);
      var d = this.updateYLabel("".concat((c + 1).toFixed(2), "x"), f);
      if (
        (d &&
          12 !== d.style.fontSize &&
          ((d.style.fill = 6250869),
          (d.style.fontSize = 12),
          (d.style.fontWeight = 500)),
        this.isMobile || this.drawYSubTicks(h, f, l, s),
        u > 100)
      )
        break;
    }
    this.isMobile || this.drawYSubTicks(5.5 + ~~r, i - c * o, l, s),
      this.yGraphics.lineStyle(2, 16777215, 0.25),
      this.yGraphics.moveTo(5.5 + ~~r - this.yTickWidth, i),
      this.yGraphics.lineTo(5.5 + ~~r, i),
      this.yTickWasHighlighted || (this.yAxisMarkerWidthTarget = 0);
    for (var p = this.yLabelIndex + 1; p < this.yLabels.length; p++)
      this.yLabels[p].visible && (this.yLabels[p].visible = !1);
  }

  drawLine(t, e, r, i, x) {
    t.clear(), (t.visible = !0);
    for (var n = null, a = r, o = 0; a <= i; a += 100, o++) {
      var s = this.getElapsedPosition(a),
        l = s.x,
        c = s.y;
      if (
        l >
        x -
          (this.rocketBodyContainer.height *
            Math.sin(this.rocket.angle * (Math.PI / 180))) /
            2
      ) {
        t.lineStyle(this.isMobile ? 2 : this.isLarge ? 5 : 3, 922659);
      } else {
        t.lineStyle(this.isMobile ? 2 : this.isLarge ? 5 : 3, e);
      }
      if (a === r) t.moveTo(l, c);
      else {
        if (o > 20 && null !== n && l - n < 10) continue;
        t.lineTo(l, c), (n = l);
      }
    }
  }

  drawWaitingText() {
    if (this.counter) {
      this.counter.style.fill = "#e6be4b";
      this.counter.style.fontSize = 19;
      this.counter.y = this.plotHeight / 2 + 35;
      let timer = (this.start_time - new Date().getTime()) / 1e3;
      timer = timer <= 0 ? "0.0" : timer.toFixed(1);
      this.counter.text = this.nextround_text.replace("{time}", timer);
      this.currentpayout.text = "";

      if (timer <= 0.5) {
        if (this.waitingRocket) {
          gsap.to(this.waitingRocket, { duration: 2.7, pixi: { y: -500 } });
          gsap.to(this.waitingRocket, { duration: 2.5, pixi: { scale: 0.5 } });
        }
      }
    }
  }

  drawRunningText(multiplier) {
    if (this.counter) {
      this.counter.style.fill = "#FFFFFF";
      this.counter.style.fontSize = 90;
      this.counter.y = this.plotHeight / 2 - 10;
      this.current_amount = multiplier;
      this.counter.text = this.current_amount + "x";
      Game["current_amount"] = this.current_amount;
      this.preparinground.text = "";
      this.currentpayout.style.fontSize = 18;
      this.currentpayout.text = "Current Payout";
    }
  }

  drawRoundOverText(multiplier) {
    if (this.counter) {
      this.counter.style.fill = "#e33e36";
      this.counter.style.fontSize = 90;
      this.counter.y = this.plotHeight / 2 - 10;
      this.current_amount = multiplier;
      this.counter.text = this.current_amount + "x";

      this.currentpayout.style.fill = "#e33e36";
      this.currentpayout.style.fontSize = 18;
      this.currentpayout.text = "Round Over";
    }
  }

  drawPrepareText() {
    if (this.preparinground) {
      this.preparinground.style.fill = "#FFFFFF";
      this.preparinground.style.fontSize = 37;
      this.preparinground.text = "Preparing Round";
    }
  }

  drawCurrentPayout() {
    if (this.currentpayout) {
      this.currentpayout.style.fill = "#e6be4b";
      this.currentpayout.y = this.plotHeight / 2 + 59;
      this.currentpayout.style.fontSize = 18;
      this.currentpayout.text = "Current Payout";
    }
  }

  stepValues(t) {
    var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 5,
      r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 2;
    console.assert(Number.isFinite(t));
    for (var i = 0.4, n = 0.1; ; ) {
      if (t < i) return n;
      if (((n *= r), t < (i *= e))) return n;
      (i *= r), (n *= e);
    }
  }

  updateXLabel(t, e, h) {
    if (((this.xLabelIndex += 1), this.xLabelIndex >= this.xLabels.length))
      return !1;
    var r = this.xLabels[this.xLabelIndex];
    return (
      r.text !== t && (r.text = t),
      r.x !== e && (r.x = e),
      r.y !== h && (r.y = h),
      r.visible || (r.visible = !0),
      r
    );
  }

  updateYLabel(t, e) {
    if (((this.yLabelIndex += 1), this.yLabelIndex >= this.yLabels.length))
      return !1;
    var r = this.yLabels[this.yLabelIndex];
    return (
      r.text !== t && (r.text = t),
      r.y !== e && ((r.y = e), (r.x = this.plotWidth + 10)),
      r.visible || (r.visible = !0),
      r
    );
  }

  drawYSubTicks(t, e, r, i, n, a) {
    for (var o = 1; o < r; o++) {
      var s = o === r / 2,
        l = s ? 12 : 7,
        c = 0.5 + ~~(e + (i / r) * o),
        u = 16777215;
      if (s) {
        0.12 !== this.yGraphics._lineStyle &&
          this.yGraphics.lineStyle(2, u, 0.12);
        var h = this.getYMultiplier(c),
          f = this.updateYLabel("".concat(h.toFixed(2), "x"), c);
        f &&
          11 !== f.style.fontSize &&
          ((f.style.fill = 3487308),
          (f.style.fontSize = 11),
          (f.style.fontWeight = 500));
      } else
        0.07 !== this.yGraphics._lineStyle &&
          this.yGraphics.lineStyle(2, u, 0.07);
      this.yGraphics.moveTo(t - l, c), this.yGraphics.lineTo(t, c);
    }
  }

  tick() {
    (this.elapsedTime = this.getElapsedTime()),
      (this.multiplier =
        this.state !== this.States.Over
          ? this.getElapsedPayout(this.elapsedTime)
          : this.finalMultiplier),
      (this.yAxisMinimum = this.yAxisMultiplier),
      (this.yAxis = this.yAxisMinimum),
      (this.xAxis = Math.max(
        this.elapsedTime + this.elapsedOffset,
        this.xAxisMinimum
      )),
      this.multiplier > this.yAxisMinimum && (this.yAxis = this.multiplier),
      (this.xIncrement = this.plotWidth / this.xAxis),
      (this.yIncrement = this.plotHeight / this.yAxis);
  }

  clearTickTimeouts() {
    clearTimeout(this.tickTimeout), clearTimeout(this.lagTimeout);
  }

  getElapsedTime() {
    return this.state === this.States.Over
      ? this.finalElapsed
      : this.state !== this.States.Active
      ? this.start_time
      : Date.now() - this.startedTime;
  }

  getElapsedPosition(e) {
    var r = this.getElapsedPayout(e) - 1;
    return {
      x: e * this.xIncrement + 10,
      y: this.plotHeight - r * this.yIncrement,
    };
  }

  getYMultiplier(t) {
    return (
      Math.ceil(1e3 * (this.yAxis - (t / this.plotHeight) * this.yAxis + 1)) /
      1e3
    );
  }

  getMultiplierY(t) {
    return this.plotHeight - (t - 1) * this.yIncrement;
  }

  getMultiplierElapsed(e) {
    return (
      100 * Math.ceil(Math.log(e) / Math.log(Math.E) / this.CrashSpeed / 100)
    );
  }

  getElapsedPayout(e) {
    var r = ~~(100 * Math.pow(Math.E, this.CrashSpeed * e)) / 100;
    if (!Number.isFinite(r)) throw new Error("Infinite payout");
    return Math.max(r, 1);
  }

  addCashout(t, e) {
    var r = this,
      i = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
      n = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3];
    this.plotWidth <= this.pe && (n = !1),
      (this.flipCashout = !this.flipCashout);
    let skin = this.coins[e.coin];
    var a = this.getMultiplierElapsed(t),
      o = new cashOut(
        t,
        a,
        e,
        this.cashoutsContainer.children.length > 2,
        i,
        this.flipCashout,
        {
          animated: n,
          isLarge: this.isLarge,
          isMobile: this.plotWidth <= 500,
        },
        skin
      );
    var s = this.getElapsedPosition(a);
    (o.container.x = s.x),
      (o.container.y = s.y),
      this.cashouts.push(o),
      this.cashouts.sort(function (t, e) {
        return t.elapsed - e.elapsed;
      }),
      this.cashoutsContainer.addChild(o.container);
  }

  destoryCashoutChilds() {
    while (this.cashoutsContainer.children[0]) {
      this.cashoutsContainer.removeChild(this.cashoutsContainer.children[0]);
    }
  }

  updateCashouts() {
    for (
      var t = this.cashouts,
        e = Date.now(),
        r = null === this.lastCashoutTick || e - this.lastCashoutTick > 300,
        i = 0;
      i < t.length;
      i++
    ) {
      var n = t[i],
        a = t[i - 1];
      if (i > 0) {
        var o = !a.flipped;
        n.flipped !== o && ((n.flipped = o), n.updateFlipped());
      }
      var s = this.getElapsedPosition(n.elapsed);
      if (
        ((n.container.x = norMath.t7(n.container.x, s.x, 0.15)),
        (n.container.y = norMath.t7(n.container.y, s.y, 0.15)),
        !n.hovered && r)
      ) {
        for (var l = t[i - 2], c = i - 2; c >= 0; c -= 2)
          if (t[c].flipped === n.flipped && t[c].visible) {
            l = t[c];
            break;
          }
        if ((n.unminimize(), l))
          norMath.Sp(
            n.container.x,
            n.container.y,
            l.container.x,
            l.container.y
          ) > 60
            ? n.minimized && n.unminimize()
            : n.minimized || n.minimize();
      }
    }
    r && (this.lastCashoutTick = e);
  }
}

/**
 * Game is Waiting
 */
Crash.prototype.setWaiting = function (time) {
  this.game_status = "waiting";
  this.yAxisMarker.container.visible = !1;
  if (this.explosions) this.explosions.visible = !1;
  this.start_time = new Date(Date.now() + time)
  this.state = this.States.TakingBets;

  if (__.isUndefined(this.gameContainer)) return;

  this.gameContainer.addChild(this.waitingRocket);
  if (this.waitingRocket) {
    this.waitingRocket.visible = true;
    gsap.to(this.waitingRocket, { duration: 0.7, pixi: { scale: 0.3 } });
    gsap.to(this.waitingRocket, { duration: 0.7, pixi: { y: 400 } });
    Effect.Particle(this.waitingRocket, "./assets/images/crash/tail/fire2.png");
  }
};

/**
 * Game is Started
 */
Crash.prototype.setStarted = function (time) {
  if (this.explosions) {
    (this.explosions.visible = time <= 2e3),
      this.explosions.scale.set(0.5),
      this.explosions.visible &&
        ((this.explosions.x = -15),
        (this.explosions.y = this.plotHeight),
        this.explosions.state.setAnimation(0, "Fx04"));
  }
  this.game_status = "started";
  this.startedTime = new Date().getTime() - time;
  this.state = this.States.Active;
  this.yAxisMarker.container.visible = !0;

  if (this.waitingRocket) {
    this.gameContainer.removeChild(this.waitingRocket);
  }
};

/**
 * Game is Busted
 */
Crash.prototype.setBusted = function (rate, time) {
  this.game_status = "busted";
  this.state = this.States.Over;
  this.startedTime = new Date().getTime() - time;
  if (this.rocket) {
    (this.rocket.visible = !1),
      (this.rocket.angle = 90),
      (this.explosions.visible = !0),
      (this.explosions.x = this.rocket.x - 25),
      (this.explosions.y = this.rocket.y + 5),
      this.explosions.state.setAnimation(0, "Fx03_text");
  }
  this.yAxisMarker.container.visible = !1;
  if (this.rocketBody) {
    // this.rocketBody.texture = PIXI.Texture.from(this.currentRocketConfig.skins[Math.floor(Math.random() * 10)]);
    this.rocketBody.texture = PIXI.Texture.from(
      this.currentRocketConfig.skins[Math.floor(secureRandomNumber() * 10)]
    );
    this.rocketBody.texture.on("update", () => {
      wait(500).then(() => {
        this.rocketTailContainer.y = this.rocketBodyContainer.height;
      });
    });
  }
  this.cnt = 0;
  this.drawRoundOverText(rate);
  // this.drawRoundOverText(Game['current_amount']);
  this.cashouts = [];
  if (this.cashoutsContainer) this.destoryCashoutChilds();

  if (this.waitingRocket) {
    this.waitingRocket.visible = false;
    this.waitingRocket.y = 450;
  }
};

/**
 * Finisher [ TODO ]
 */
Crash.prototype.winedPlayer = function (data) {
  this.addCashout((data.current / 100).toFixed(2), data, false, false);
};

class NewTail {
  constructor(parent) {
    this.wrapper = parent;
    this.initialize();
    this.emitter = {
      x: 0,
      y: 0,
      particles: [],
      tick: 0,
    };
  }

  initialize() {
    this.psContainer = new PIXI.Container();
    this.wrapper.addChild(this.psContainer);
    this.particles = [];
    this.hue = 360;
    this.psGraphics = new PIXI.Graphics();
    this.psContainer.addChild(this.psGraphics);
  }

  createParticle(x, y, hue) {
    return {
      x: x,
      y: y,
      // radius: 10 + Math.random() * 5,
      radius: 10 + secureRandomNumber() * 5,
      alpha: 1,
      // decay: Math.random() * 0.05 + 0.01,
      decay: secureRandomNumber() * 0.05 + 0.01,
      // vx: Math.sin(this.emitter.tick / 30) * Math.random() * 0.6 + 0.12,
      vx: Math.sin(this.emitter.tick / 30) * secureRandomNumber() * 0.6 + 0.12,
      // vy: Math.random() * -0.5 - 1,
      vy: secureRandomNumber() * -0.5 - 1,
      hue: hue,
    };
  }

  renderParticles() {
    this.psGraphics.clear();

    this.psGraphics.blendMode = PIXI.BLEND_MODES.ADD;
    this.psGraphics.globalCompositeOperation = "lighter";

    this.emitter.particles.forEach((particle) => {
      particle.y += particle.vy;
      particle.alpha -= particle.decay;
      particle.radius -= particle.decay * 11;

      if (particle.radius < 0 || particle.alpha < 0) {
        return;
      }
      particle.hue = 360 + (1 - particle.alpha) * 50;
      const color = `hsla(${particle.hue},100%,55%,${particle.alpha * 0.5})`;
      // Function to convert HSLA to RGBA color
      function hslaToRgba(h, s, l, a) {
        h = ((h % 360) + 360) % 360;
        s = Math.max(0, Math.min(100, s)) / 100;
        l = Math.max(0, Math.min(100, l)) / 100;
        a = Math.max(0, Math.min(1, a));

        const c = (1 - Math.abs(2 * l - 1)) * s;
        const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
        const m = l - c / 2;
        let r, g, b;

        if (h >= 0 && h < 60) {
          [r, g, b] = [c, x, 0];
        } else if (h >= 60 && h < 120) {
          [r, g, b] = [x, c, 0];
        } else if (h >= 120 && h < 180) {
          [r, g, b] = [0, c, x];
        } else if (h >= 180 && h < 240) {
          [r, g, b] = [0, x, c];
        } else if (h >= 240 && h < 300) {
          [r, g, b] = [x, 0, c];
        } else {
          [r, g, b] = [c, 0, x];
        }

        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);

        return [r, g, b, a];
      }
      const rgbaColor = hslaToRgba(...color.match(/[\d.]+/g));
      function componentToHex(c) {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }
      let fillColor =
        "0x" +
        componentToHex(rgbaColor[0]) +
        componentToHex(rgbaColor[1]) +
        componentToHex(rgbaColor[2]);
      this.psGraphics.beginFill(fillColor, particle.alpha);
      this.psGraphics.drawCircle(
        particle.x + this.emitter.x,
        particle.y + this.emitter.y,
        particle.radius
      );
      this.psGraphics.endFill();
    });

    this.emitter.particles = this.emitter.particles.filter(
      (p) => p.radius >= 0 && p.alpha >= 0
    );
  }

  createFire() {
    for (let i = 0; i < 20; i++) {
      const particle = this.createParticle(0, 0, 1 * this.hue);
      this.emitter.particles.push(particle);
    }
  }

  render() {
    this.emitter.tick++;
    this.renderParticles();

    if (this.emitter.particles.length < 100) {
      this.createFire();
    }
  }
}
class yAxisMarker {
  constructor(t) {
    var n =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 4934748;
    var mobileConfig =
      arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
    // Assign options object to 'this.options'
    this.options = Object.assign({ isMobile: false }, mobileConfig);
    // Set 'this.tooltipFill' as the provided 'n'
    this.tooltipFill = n;
    // Set 'this.crashEngine' as the provided 't'
    this.crashEngine = t;
    // Set 'this.targetMultiplier' as null initially
    this.targetMultiplier = null;

    // Set 'this.targetY', 'this.lastY', and 'this.lastYAxis' as 0 initially
    this.targetY = 0;
    this.lastY = 0;
    this.lastYAxis = 0;
    // Set 'this.tooltipHeight' as 18
    this.tooltipHeight = 18;

    this.height = t.graphHeight;
    // Create PIXI graphics object for drawing
    this.graphics = new PIXI.Graphics();
    this.graphics.cacheAsBitmap = true;
    // Create PIXI text object for label
    this.label = new PIXI.Text("0.00x", {
      fontFamily: "Roboto",
      fontSize: 11,
      fontWeight: "800",
      fill: 16777215,
      dropShadow: true,
      dropShadowAngle: Math.PI / 2,
      dropShadowAlpha: 0.5,
      dropShadowDistance: 2,
    });
    this.label.y = this.tooltipHeight / 2;
    this.label.anchor.set(0, 0.5);
    // Apply filters if 'isMobile' is true
    // Create PIXI graphics object for target
    this.targetGraphics = new PIXI.Graphics();
    this.container = new PIXI.Container();
    this.container.addChild(this.targetGraphics, this.graphics, this.label);
  }
  setTargetMultiplier(t) {
    (this.targetMultiplier = t),
      (this.label.text = "".concat(parseFloat(t).toFixed(2), "x")),
      (this.alpha = 0);
  }

  tick() {
    if (this.container.visible) {
      if (null !== this.targetMultiplier)
        return (
          (this.y =
            this.crashEngine.getMultiplierY(this.targetMultiplier) -
            this.tooltipHeight / 2),
          this.targetGraphics.clear(),
          this.targetGraphics.beginFill(this.tooltipFill, 0.04),
          this.targetGraphics.drawRect(
            0,
            this.tooltipHeight / 2 + 1,
            this.crashEngine.plotWidth,
            this.crashEngine.plotHeight - this.y - 10
          ),
          void this.targetGraphics.endFill(this.y)
        );
      if (Math.abs(this.targetY - this.y) > 0.1) {
        var t =
          Math.min(
            this.targetY,
            this.crashEngine.plotHeight + this.tooltipHeight / 2 + 3
          ) - this.height;
        this.y = norMath.t7(this.y, t, 0.1);
      }
      if (
        ~~this.y !== this.lastY ||
        !this.lastYAxis !== this.crashEngine.yAxis
      ) {
        var e = this.crashEngine.getYMultiplier(
          this.y - this.tooltipHeight / 2
        );
        e < 1 && (e = 1),
          (this.label.text = "".concat(e.toFixed(2), "x")),
          (this.lastY = ~~this.y),
          (this.lastYAxis = this.crashEngine.yAxis);
      }
      this.container.y = this.y - this.tooltipHeight;
    }
  }
  drawTooltip(t) {
    var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1,
      r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0,
      i = this.crashEngine.plotWidth,
      n = this.crashEngine.plotOffsetX;
    this.graphics.beginFill(t, e),
      this.graphics.moveTo(i, this.tooltipHeight / 2 + r),
      this.graphics.lineTo(i + 10, r),
      this.graphics.lineTo(i + n, r),
      this.graphics.lineTo(i + n, this.tooltipHeight + r),
      this.graphics.lineTo(i + 10, this.tooltipHeight + r),
      this.graphics.closePath(),
      this.graphics.endFill(),
      this.graphics.beginFill(16777215, 0.08),
      this.graphics.moveTo(i, this.tooltipHeight / 2 + r),
      this.graphics.lineTo(i + 10, r),
      this.graphics.lineTo(i + n, r),
      this.graphics.lineTo(i + n, this.tooltipHeight / 2 + r),
      this.graphics.lineTo(i, this.tooltipHeight / 2 + r),
      this.graphics.closePath(),
      this.graphics.endFill();
  }
  onResize() {
    this.crashEngine.plotWidth;
    var t = this.tooltipHeight / 2;
    (this.label.x = this.crashEngine.plotWidth + 16),
      (this.graphics.cacheAsBitmap = !1),
      this.graphics.clear(),
      this.graphics.lineStyle(2, this.tooltipFill, 0.8),
      this.graphics.moveTo(0, t),
      (function (t, e, r) {
        for (
          var i =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : 16,
            n =
              arguments.length > 4 && void 0 !== arguments[4]
                ? arguments[4]
                : 8,
            a = t.currentPath.points,
            o = {
              x: a[a.length - 2] || 0,
              y: a[a.length - 1] || 0,
            },
            s = Math.abs(e),
            l = Math.abs(r);
          Math.abs(o.x) < s || Math.abs(o.y) < l;

        )
          (o.x = Math.abs(o.x + i) < s ? o.x + i : e),
            (o.y = Math.abs(o.y + i) < l ? o.y + i : r),
            t.lineTo(o.x, o.y),
            (o.x = Math.abs(o.x + n) < s ? o.x + n : e),
            (o.y = Math.abs(o.y + n) < l ? o.y + n : r),
            t.moveTo(o.x, o.y);
      })(this.graphics, this.crashEngine.graphWidth, t, 3, 3),
      this.graphics.lineStyle(0),
      this.drawTooltip(this.tooltipFill),
      (this.graphics.cacheAsBitmap = !0);
  }
}

class cashOut {
  constructor(t, i, n) {
    var o = !(arguments.length > 3 && void 0 !== arguments[3]) || arguments[3],
      l = arguments.length > 4 && void 0 !== arguments[4] && arguments[4],
      c = arguments.length > 5 && void 0 !== arguments[5] && arguments[5],
      u = arguments.length > 6 && void 0 !== arguments[6] ? arguments[6] : {},
      s =
        arguments.length > 7 && void 0 !== arguments[7]
          ? arguments[7]
          : "./assets/images/crash/btc.png";
    this.options = Object.assign(
      {
        animated: !0,
        isLarge: !1,
        isMobile: !1,
      },
      u
    );
    var h = this.options.animated;
    (this.targetSize = this.getTargetSize()),
      (this.hovered = !1),
      (this.minimized = o),
      (this.hidden = u.isMobile),
      (this.elapsed = i),
      // this.multiplier = _.toNumber(t),
      (this.multiplier = __.toNumber(t)),
      (this.userCashout = l),
      (this.flipped = c),
      (this.targetPosition = null);

    this.payoutValue = cryptoToUsd(n.dollar);
    // this.payoutValue = __.toNumber(n.dollar) > 1 ? __.toNumber(n.dollar).toFixed(2) : n.dollar;
    var f = (this.point = PIXI.Sprite.from(s));
    this.container = new PIXI.Container();
    if (
      ((f.alpha = h ? 0 : 1),
      f.anchor.set(0.5),
      (f.width = f.height = h ? 3 * this.targetSize : this.targetSize),
      this.container.addChild(f),
      !this.hidden)
    ) {
      var d = (this.name = new PIXI.Text(this.multiplier.toFixed(2) + "x", {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "800",
        fill: 16777215,
      }));
      d.visible = !this.minimized;
      var p = (this.amount = new PIXI.Text("$ " + this.payoutValue, {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "400",
        fill: 9159498,
      }));
      (p.visible = !this.minimized),
        this.updateFlipped(),
        this.container.addChild(d, p);
    }
    this.onResize();
  }

  getTargetSize() {
    var t = this.options,
      e = t.isLarge;
    return t.isMobile ? 15 : e ? 40 : 25;
  }
  onResize() {
    if (
      ((this.targetSize = this.getTargetSize()),
      (this.point.width = this.point.height = this.targetSize),
      !this.hidden)
    ) {
      var t = this.options.isLarge ? 18 : 14;
      (this.name.style.fontSize = t),
        (this.amount.style.fontSize = t),
        this.updateFlipped();
    }
  }
  updateFlipped() {
    if (!this.hidden) {
      var t = this.name,
        e = this.amount;
      this.flipped
        ? ((t.y = 3 - this.targetSize),
          t.anchor.set(0.5, 1),
          (e.y = t.y - t.height),
          e.anchor.set(0.5, 1))
        : ((t.y = this.targetSize - 3),
          t.anchor.set(0.5, 0),
          (e.y = t.y + t.height),
          e.anchor.set(0.5, 0));
    }
  }
  setHovered(t) {
    this.hidden ||
      ((this.hovered = t),
      t
        ? ((this.name.visible = !0), (this.amount.visible = !0))
        : this.minimized &&
          ((this.name.visible = !1), (this.amount.visible = !1)));
  }
  minimize() {
    this.minimized ||
      this.hidden ||
      ((this.minimized = !0),
      (this.name.visible = !1),
      (this.amount.visible = !1));
  }
  unminimize() {
    this.hidden ||
      ((this.minimized = !1),
      (this.name.visible = !0),
      (this.amount.visible = !0));
  }
}

let norMath = {
  t7: function (t, e, r) {
    return (1 - r) * t + r * e;
  },
  Sp: function (t, e, r, i) {
    var n = t - r,
      a = e - i;
    return Math.sqrt(n * n + a * a);
  },
  dN: function (t) {
    return n()(t.toFixed(t > 1 ? 2 : 4)).format("$0,0.00[0]");
  },
};

Canvas.propTypes = {
  profit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  profit: state.items.profit,
});

export default connect(mapStateToProps, {})(Canvas);
