import * as PIXI from "pixi.js";
import * as Blackjack from "engine-blackjack";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { __, Event, wait, rand } from "../../../Helper";

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "./assets/images/blackjack";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 600,
      height: 350,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.cards = [];
    this.pCards = [];
    this.canSplit = false;
    this.canInsurance = false;
    this.dealerMove = false;
    this.hands = 0;
    this.container = null;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("blackjack", this.app);
  }

  init() {
    let images = [ASSETS_PATH + "/back.png"];

    const SUIT = ["C", "D", "H", "S"];

    for (var i = 2; i < 10; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/cards/" + SUIT[j] + "/" + i + ".png";
        images.push(asset);
      }
    }

    const NAMES = ["J", "K", "Q", "A"];

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/cards/" + SUIT[i] + "/" + NAMES[j] + ".png";
        images.push(asset);
      }
    }

    this.loader.add([
      images,
      ASSETS_PATH + "/tables.png",
      ASSETS_PATH + "/frame.png",
      ASSETS_PATH + "/win.png",
      ASSETS_PATH + "/lose.png",
      ASSETS_PATH + "/push.png",
      ASSETS_PATH + "/bust.png",
    ]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load() {
    Event.emit("loading_blackjack_done");
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true;
    this.container.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configTable();
    this.configPlayers();
    this.configScores();
    this.configAnim();
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      count += 0.01;
    });
  }

  configTable() {
    this.table = PIXI.Sprite.from(ASSETS_PATH + "/tables.png");
    this.table.anchor.set(0.5);
    this.table.x = this.centerX;
    this.table.y = this.centerY;
    this.table.width = 600;
    this.table.height = 300;

    this.card = PIXI.Sprite.from(ASSETS_PATH + "/back.png");
    this.card.y = -265;
    this.card.x = 280;
    this.card.width = 96;
    this.card.height = 127;

    this.table.addChild(this.card);
    this.container.addChild(this.table);
  }

  configPlayers() {
    this.playerContainer = new PIXI.Container();
    this.playerContainer.x = 90;
    this.playerContainer.y = 230;
    this.playerContainer.scale.set(0.65);

    this.dealerContainer = new PIXI.Container();
    this.dealerContainer.x = 90;
    this.dealerContainer.y = 70;
    this.dealerContainer.scale.set(0.65);

    this.splitContainer = new PIXI.Container();
    this.splitContainer.x = this.app.screen.width - 150;
    this.splitContainer.y = 200;
    this.splitContainer.scale.set(0.4);

    this.container.addChild(
      this.playerContainer,
      this.dealerContainer,
      this.splitContainer
    );
  }

  configScores() {
    this.interface = new PIXI.Container();
    this.interface.x = 10;
    this.interface.y = 10;

    this.playerScore = Effect.Text("", {
      font: "Roboto",
      size: 15,
      color: 0xffffff,
    });
    this.playerScore.x = 20;
    this.playerScore.y = this.app.screen.height - 40;
    this.playerScore.visible = false;

    this.dealerScore = Effect.Text("", {
      font: "Roboto",
      size: 15,
      color: 0xffffff,
    });
    this.dealerScore.x = this.app.screen.width - 90;
    this.dealerScore.y = this.app.screen.height - 40;
    this.dealerScore.visible = false;

    this.resultFrame = PIXI.Sprite.from(ASSETS_PATH + `/frame.png`);
    this.resultFrame.anchor.set(0.5);
    this.resultFrame.width = 200;
    this.resultFrame.height = 55;
    this.resultFrame.position.set(this.centerX, this.app.screen.height + 50);
    this.resultFrame.visible = false;

    this.interface.addChild(
      this.resultFrame,
      this.playerScore,
      this.dealerScore
    );

    this.container.addChild(this.interface);
  }

  updateCards(card, sort, player) {
    // sort = this.hands;
    let suit = this.getSuite(card.suite);
    let rank = card.value;
    let name = card.text;

    if (!__.includes(this.pCards, rank + suit)) {
      this.hands++;
      this.pCards.push(rank + suit);
      this.cards[sort] = PIXI.Sprite.from(
        ASSETS_PATH + `/cards/${suit}/${__.toString(name)}.png`
      );
      this.cards[sort].alpha = 0.9;
      this.cards[sort].width = 145;
      this.cards[sort].height = 195;
      this.cards[sort].anchor.set(0.5);
      this.cards[sort].x = 500;
      this.cards[sort].y = -1;

      Effect.MoveIn2(this.cards[sort], sort * 150, 25, 0.3);

      if (player) {
        this.playerContainer.addChild(this.cards[sort]);
      } else if (player === null) {
        this.splitContainer.addChild(this.cards[sort]);
      } else {
        this.dealerContainer.addChild(this.cards[sort]);
      }
    }
  }

  getSuite(name) {
    switch (name) {
      case "spades":
        return "S";

      case "hearts":
        return "H";

      case "clubs":
        return "C";

      case "diamonds":
        return "D";
    }
  }

  getCards(isSplit = false) {
    let dealerCards = this.game.getState().dealerCards;
    let playerCards = this.game.getState().handInfo.right.cards;

    for (var i = 0; i < playerCards.length; i++) {
      this.updateCards(playerCards[i], i, true);
    }

    for (var i = 0; i < dealerCards.length; i++) {
      this.updateCards(dealerCards[i], i, false);
    }

    if (isSplit) {
      for (var i = 0; i < playerCards.length; i++) {
        this.updateCards(playerCards[i], i, null);
      }
      return;
    }
  }

  end(type) {
    this.result = type;
    this.resultFrame.texture = PIXI.Texture.from(ASSETS_PATH + `/${type}.png`);
    Effect.FadeIn(this.resultFrame, 0.5);
    this.resultFrame.visible = true;

    Effect.MoveIn(this.resultFrame, this.centerX, this.app.screen.height - 40);

    this.engine.pCards = this.game.getState().handInfo.right.cards;
    this.engine.dCards = this.game.getState().dealerCards;
    this.engine.result = this.result;
    this.engine.busted();

    this.result = "fail";
    this.started = false;
    this.pCards = [];
    this.cards = [];
  }

  deal(cardResult) {
    if (this.started) return;
    this.hands = 0;
    this.playerContainer.removeChildren();
    this.dealerContainer.removeChildren();
    this.splitContainer.removeChildren();
    Effect.FadeOut(this.resultFrame, 0.4);
    Effect.MoveIn(this.resultFrame, this.centerX, this.app.screen.height + 50);
    this.playerScore.text = "";
    this.dealerScore.text = "";
    this.started = true;
    this.dealerMove = false;

    this.game = new Blackjack.Game();
    this.actions = Blackjack.actions;

    if (this.game.getState().stage !== "ready") return;

    cardResult = __.shuffle(cardResult);

    this.game.setState({ deck: cardResult });

    this.game.dispatch(this.actions.deal());

    this.getCards();

    this.updateStatus();
  }

  hit() {
    if (!this.started) return;

    let pos = this.dealerMove ? "left" : "right";

    this.game.dispatch(this.actions.hit({ position: pos }));

    this.getCards();

    this.updateStatus();

    this.engine.history.push("hit");
    this.engine.saveHistory();
  }

  stand() {
    if (!this.started) return;

    let pos = this.dealerMove ? "left" : "right";

    this.game.dispatch(this.actions.stand({ position: pos }));

    this.getCards();

    this.updateStatus();
  }

  split() {
    if (!this.started) return;
    if (!this.canSplit) return;

    this.game.dispatch(this.actions.split({ position: "right" }));

    this.getCards(true);

    this.updateStatus();

    this.engine.history.push("split");
    this.engine.saveHistory();
  }

  insurance() {
    if (!this.started) return;
    if (!this.canInsurance) return;

    this.game.dispatch(this.actions.insurance({ position: "right" }));

    this.getCards();

    this.updateStatus();

    this.engine.history.push("insurance");
    this.engine.saveHistory();
  }

  updateStatus() {
    Event.emit("actions", this.game.getState().handInfo.right.availableActions);

    let dScore = this.game.getState().dealerValue.lo;
    let pScore = this.game.getState().handInfo.right.playerValue.lo;

    this.dealerScore.text = "Dealer: " + dScore;
    this.playerScore.text = "Player: " + pScore;
    this.playerScore.visible = true;
    this.dealerScore.visible = true;

    this.canSplit = this.game.getState().handInfo.right.availableActions.split;
    this.canInsurance =
      this.game.getState().handInfo.right.availableActions.insurance;

    if (this.game.getState().stage === "done") {
      this.getCards();

      if (this.game.getState().handInfo.right.playerHasBusted) {
        return this.end("bust");
      }

      if (this.game.getState().handInfo.right.playerHasBlackjack) {
        return this.end("blackjack");
      }

      if (this.game.getState().dealerHasBusted) {
        return this.end("win");
      }

      if (this.game.getState().dealerHasBlackjack) {
        return this.end("win");
      }

      if (this.game.getState().wonOnRight !== 0) return this.end("win");
      else return this.end("lose");
    }

    //Dealer Hits
    if (this.dealerMove) return;
    if (this.game.getState().stage === "player-turn-left") {
      this.dealerMove = true;
      Event.emit(
        "actions",
        this.game.getState().handInfo.left.availableActions
      );
    }
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
