import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import Deck from "./components/Deck";
import Player from "./components/Player";
import Rules from "./components/Rules";
import { __, wait } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

const ASSETS_PATH = "/assets/images/video-poker/new/";

class Canvas extends Rules {
  constructor(engine) {
    super();
    this.app = new PIXI.Application({
      width: 700,
      height: 250,
      transparent: true,
      resolution: 1,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("videopoker", this.app);
    this.dealCards = [];
    this.cards = [];
    this.tempHand = [];
    this.hand = [];
    this.count = 0;
    this.container = null;
    this.ccards = [];
    this.shuffleAgain = false;
    this.user = new Player();
  }

  init() {
    let images = [];

    const SUIT = ["C", "D", "H", "S"];

    for (var i = 1; i < 14; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/" + i + SUIT[j] + ".png";
        images.push(asset);
      }
    }

    const NAMES = ["A", "J", "K", "Q"];

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/" + NAMES[j] + SUIT[i] + ".png";
        images.push(asset);
      }
    }
    this.loader.add([images]);
    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.app.stage.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configCardPlace();

    for (var i = 0; i < 5; i++) {
      this.addCard("back", i);
    }
    this.configAnim();
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        count += 0.05;
      }
    });
  }

  configCardPlace() {
    this.cardPlace = new PIXI.Container();
    this.cardPlace.sortableChildren = true;
    this.container.addChild(this.cardPlace);
  }

  addCard(name, num) {
    if (name === "back") {
      this.cards[num] = PIXI.Sprite.from(
        PIXI.Texture.from(ASSETS_PATH + "/" + name + ".png")
      );
    } else {
      this.cards[num].texture = PIXI.Texture.from(
        ASSETS_PATH + "/" + name + ".png"
      );
      this.cards[num].interactive = true;
      this.cards[num].buttonMode = true;
      this.cards[num].started = this.started;
      this.cards[num].on("click", this.setHold);
      this.cards[num].on("touchstart", this.setHold);
      this.cards[num].on("pointerover", this.setOver);
      this.cards[num].on("pointerout", this.setOut);
    }

    let space = 70;
    let x = (this.app.screen.width / 5) * num + space;
    let y = this.app.screen.height / 2;
    this.cards[num].selected = false;
    this.cards[num].id = num;
    this.cards[num].x = x;
    this.cards[num].y = y;
    this.cards[num].width = 125;
    this.cards[num].height = 181;
    this.cards[num].anchor.set(0.5);
    this.cardPlace.addChild(this.cards[num]);
  }

  play(result) {
    if (this.started) return;
    this.reset();

    this.result = result;

    wait(500).then(() => {
      this.started = true;
      this.rules = new Rules();

      this.createDeck = new Deck();
      this.createDeck.add();
      this.createDeck.shuffle();

      this.deck = this.createDeck.deck;
      let clone = this.createDeck;

      this.hand = [];

      for (var i = 0; i < 5; i++) {
        this.hand[i] = this.createDeck.get();
        this.addCard(this.hand[i].texture, i);
        this.ccards.push(this.hand[i]);
        this.tempHand[i] = clone.get();
      }

      // this.tempHand = _.clone(this.hand);
      this.rules.hand = this.hand;
    });
  }

  deal() {
    if (!this.started) return;
    this.count++;

    if (this.count >= 200) {
      this.gameOver();
      return;
    }

    if (this.shuffleAgain) {
      this.createDeck.shuffle();
      this.dealCards = [];
    }

    for (var i = 0; i < 5; i++) {
      if (!this.cards[i].selected) {
        this.hand[i] = this.createDeck.get();

        if (_.isUndefined(this.hand[i])) {
          this.hand[i] = this.tempHand[i];
        }

        this.addCard(this.hand[i].texture, i);
        this.dealCards.push(this.hand[i]);
      }
    }

    this.rules.hand = this.hand;

    let check = this.checkRules();
    if (check) return this.gameOver();

    this.shuffleAgain = true;
    return this.deal();
  }

  setOver(e) {
    if (!this.started) return;
    if (this.selected) return;

    Effect.MoveIn(this, this.x, 120);
  }

  setOut(e) {
    if (!this.started) return;
    if (this.selected) return;

    Effect.MoveIn(this, this.x, 125);
  }

  setHold(e) {
    if (!this.started) return;

    if (this.selected) {
      this.selected = false;
      this.removeChildren();
    } else {
      this.selected = true;
      var graphics = new PIXI.Graphics();
      var text = Effect.Text("HOLD ", {
        size: 40,
        color: 0xffffff,
        bold: 700,
      });
      text.y = -22;
      text.x = -70.5;
      graphics.beginFill(0x000000);
      graphics.drawRoundedRect(
        -135,
        -195,
        this.width * 2.12,
        this.height * 2.12
      );
      graphics.endFill();
      graphics.alpha = 0.6;
      graphics.addChild(text);
      this.addChild(graphics);
    }
  }

  checkRules() {
    this.shuffleAgain = false;
    this.rules.sortByVal();
    if (this.rules.royalFlush()) {
      this.status = this.user.winStats[0].name;
      this.payout = this.user.winStats[0].payout;
    } else if (this.rules.straightFlush()) {
      this.status = this.user.winStats[1].name;
      this.payout = this.user.winStats[1].payout;
    } else if (this.rules.fourOfAKind()) {
      this.status = this.user.winStats[2].name;
      this.payout = this.user.winStats[2].payout;
    } else if (this.rules.fullHouse()) {
      this.status = this.user.winStats[3].name;
      this.payout = this.user.winStats[3].payout;
    } else if (this.rules.flush()) {
      this.status = this.user.winStats[4].name;
      this.payout = this.user.winStats[4].payout;
    } else if (this.rules.straight()) {
      this.status = this.user.winStats[5].name;
      this.payout = this.user.winStats[5].payout;
    } else if (this.rules.threeOfAKind()) {
      this.status = this.user.winStats[6].name;
      this.payout = this.user.winStats[6].payout;
    } else if (this.rules.twoPair()) {
      this.status = this.user.winStats[7].name;
      this.payout = this.user.winStats[7].payout;
    } else if (this.rules.jacksOrBetter()) {
      this.status = this.user.winStats[8].name;
      this.payout = this.user.winStats[8].payout;
    } else {
      this.status = "Nothing";
      this.payout = this.user.winStats[9].payout;
    }

    if (this.status !== this.result) {
      return false;
    }

    return true;
  }

  gameOver() {
    const engine = this.engine;
    engine.init = true;
    engine.cards = this.ccards;
    engine.dealCards = this.dealCards;
    engine.winning = this.user.score;
    engine.scoreName = this.status;
    engine.busted();

    this.started = false;
    this.rules.hand = [];
    this.hand = [];
    this.deck = [];
    this.tempHand = [];
    this.dealCards = [];
    this.cards = [];
    this.ccards = [];
    this.normalMode = true;
  }

  reset() {
    this.cardPlace.removeChildren();
    for (var i = 0; i < 5; i++) {
      this.addCard("back", i);
    }
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
