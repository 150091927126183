import React from "react";
import ManualBet from "./includes/ManualBet";
import AutoBet from "./includes/AutoBet";
import { isMobile, Event } from "../../../Helper";

class Bet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bet: "manual",
      disabled: false,
      style: "",
    };
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ style: "w-100 mx-auto mt-2" });
    }

    Event.on("toggle_script", () => {
      this.setState({ disabled: !this.state.disabled });
    });
  }

  betMode = (type) => {
    this.setState({ bet: type });
  };

  render() {
    let { style, bet } = this.state;
    let { isLogged, mobile, engine } = this.props;
    return (
      <>
        {bet === "manual" ? (
          <ManualBet engine={engine} mobile={mobile} isLogged={isLogged} />
        ) : (
          <AutoBet engine={engine} isLogged={isLogged} />
        )}
        <div
          className={"btn-group btn-group-toggle 'tcr ml-1 mt-1 mb-2 " + style}
          data-toggle="buttons"
        >
          <label
            disabled={this.state.disabled}
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input onClick={(e) => this.betMode("manual")} type="radio" />{" "}
            Manual
          </label>
          <label
            disabled={this.state.disabled}
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input onClick={(e) => this.betMode("auto")} type="radio" /> Auto
          </label>
        </div>
      </>
    );
  }
}

export default Bet;
