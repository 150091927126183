import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import $ from "jquery";
import rangeslider from "rangeslider.js";
import stroage from "../../../../../Storage";
import {
  __,
  Event,
  forceSatoshiFormat,
  getDefaultBetAmount,
  defaultBetAmount,
  usdTo100Crypto,
} from "../../../../../Helper";
import "../../../../../Static/css/range.css";

function CreditRange(props) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [maxCrypto, setMaxCrypto] = useState(null);
  const ref = useRef(null);
  var credit = forceSatoshiFormat(props.credit); // From Redux
  var currentValue =
    stroage.getKey("lam") !== null ? stroage.getKey("lam") : 0.001;

  const getMaxBet = () => {
    var value = usdTo100Crypto(props.coin);
    value = forceSatoshiFormat(value);
    setMaxCrypto(value);
    return value;
  };

  const handleClick = (event) => {
    event.preventDefault();
    setShow(!show);
    setTarget(event.target);
    setMaxCrypto(getMaxBet());
  };

  const double = (amount) => {
    var a = parseFloat(amount) * 2;
    a = forceSatoshiFormat(a);

    props.set(a);
  };

  const handelAmount = (e, multiplied) => {
    e.preventDefault();

    var amount = parseFloat(props.amount);

    if (multiplied) {
      var a = Math.min(getDefaultBetAmount(props.coin), amount * 2);

      a = forceSatoshiFormat(a);

      props.set(a);
    } else {
      amount = Math.max(0.001, amount / 2);
      props.set(amount);
    }
  };

  useEffect(() => {
    Event.on("coin_changed", (coin) => {
      var a = defaultBetAmount(coin);
      props.set(a);
    });

    Event.on("double", (amount) => double(amount));
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      $("#range_c").rangeslider("destroy");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const setupRange = () => {
    $("#range_c").rangeslider({
      polyfill: false,

      // Callback function
      onInit: function (e) {},

      // Callback function
      onSlide: function (position, value) {
        props.set(value);
      },

      // Callback function
      onSlideEnd: function (position, value) {},
    });
  };

  setTimeout(() => {
    setupRange();
  }, 1);

  const min = (e) => {
    e.preventDefault();
    let min = 0.001;
    props.set(min);
    var r = $("#range_c");
    r.val(min).change();
  };

  const max = (e) => {
    e.preventDefault();
    var value = getMaxBet();
    var r = $("#range_c");
    r.val(value).change();
    setMaxCrypto(value);
  };

  return (
    <div ref={ref}>
      <Button
        onClick={(e) => {
          handelAmount(e, false);
        }}
        variant={"btn btn-s-4 btn-xs"}
      >
        /2
      </Button>
      <Button
        onClick={(e) => {
          handelAmount(e, true);
        }}
        variant={"btn btn-s-4 btn-xs"}
      >
        x2
      </Button>
      <Button onClick={handleClick} variant={"btn btn-s-4 btn-xs mr-1"}>
        <i className="mdi mdi-unfold-more-horizontal font-20 align-middle" />
      </Button>
      {show && (
        <div className="tooltip_credit d-flex animated fadeIn">
          <Button
            variant="btn bg-cs9 btn-xs text-white bet-button"
            onClick={min}
          >
            MIN
          </Button>
          <div
            type="range"
            id="range_c"
            min="0.001"
            max={maxCrypto}
            value={currentValue}
            step="0.001"
            data-rangesliders
          />
          <Button
            variant="btn bg-cs9 btn-xs text-white bet-button"
            onClick={max}
          >
            MAX
          </Button>
        </div>
      )}
    </div>
  );
}

CreditRange.propTypes = {
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  credit: state.items.credit,
  coin: state.items.coin,
});

export default connect(mapStateToProps, {})(CreditRange);
