import React from "react";
import {Modal, Button} from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import { wait, isMobile, isTablet, Event, __, hasClass, encode } from "../../../../Helper";
import C from "../../../../Constant";
import socket from "../../../../Socket";
import Messages from "./Messages";
import Submit from "./Submit";
import Country from "./Country";
import Rain from "./Rain";
import Rules from "./Rules";
import { ReactComponent as ChatLogo } from './chatlogo.svg'
import storage from "../../../../Storage";
import isLogged from "../../../../Auth";
const chatSideBarWidth = 330;

class Chat extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            isLogged: storage.getKey('logged') !== null ? true : false,
        };
        this.updateChatStateListener = this.updateChatStateListener.bind(this);
    }

    componentDidMount() {
        this.isMobileTablet = isMobile() || isTablet();

        if(storage.getKey("chat_view")){
            this.setState({ show: storage.getKey("chat_view") == "true" ? true : false });
        }
        else
        {
            if(isMobile() || isTablet()){
                // storage.setKey("chat_view", false);
                // this.setState({ show: false });
                Event.emit('toggle_chat', false);
            }
            else {  //desktop
                Event.emit('toggle_chat', true);
            }
        }
        
        window.addEventListener('resize', this.handleResize);

        wait(500)
            .then( () => {
                this.handleResize();
        });

        Event.on('toggle_sidebar', () => {
            wait(100)
                .then( () => {
                    this.handleResize();
                });
        })

        Event.on('toggle_chat', this.updateChatStateListener);
    }

    componentWillUnmount() {
        Event.removeListener('toggle_chat', this.updateChatStateListener);
        Event.removeListener('toggle_sidebar', () => {
            wait(100)
                .then( () => {
                    this.handleResize();
                });
        });
        window.removeEventListener('resize', this.handleResize);
    }

    updateChatStateListener = (show, uncache = false) => {
        wait(200).then( () => {
            this.setState({ show: show });
        });
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-content');
        const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

        if(pageContent !== null)
        {
            let space = 250;

            const leftSidebar = document.querySelector('.left-sidenav');
            const games = document.querySelectorAll('.game-image');

            if(hasClass(leftSidebar, 'min') || !this.state.show)
            {
                space -= 170;

                if(!isMobile()){
                        games.forEach((g, i) => {
                            if(g !== null)
                                g.style.height = 160 + 'px';
                        })
                    }
            }
            else {
                games.forEach((g, i) => {
                    if(g !== null)
                        g.style.height = 120 + 'px';
                })
            }

            let pWidth = this.state.show === true ? clientWidth - (chatSideBarWidth + space): clientWidth;

            if(pWidth < 960){
                Event.emit('hide_games');
            }
        }

        if(!isMobile()){
            this.setState({height: clientHeight - 230});
        }
        else {
            this.setState({height: clientHeight - 210});
        }
    };

    setSide = () => {
        let show = !this.state.show;
        wait(1).then(() => {
            this.handleResize();
        });

        Event.emit('toggle_chat', show);
        
        // wait(200).then(() => {
        //     storage.setKey("chat_view", show);
        //     this.setState({ show: show });
        // });

    };
    
    render(){
        return(
            <>
                <div id="chat_btn" onClick={this.setSide} className={`p-2 ${this.state.show ? "chat-btn-active" : "chat-btn"}`}>
                    <ChatLogo className="chat-svg" />
                </div>
            </>
        );
    }
}


function loadScroll(){
    if(document.getElementById('messages') !== null){
        let ps = new PerfectScrollbar('#messages', {
            wheelSpeed: 1,
            suppressScrollX: true,
            wheelPropagation: true,
            minScrollbarLength: 2
        });
        ps.update();
    }
}

export default Chat;
