/* eslint-disable max-len */

import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { Event, wait, __, randomBetween, cryptoToUsd } from "../../../Helper";

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "/assets/images/baccarat";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 670,
      height: 410,
      resolution: 1,
      backgroundAlpha: false,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.amounts = [];
    this.currency = [];
    this.chips = [];
    this.cards = [];
    this.back = [];
    this.coin = "usdt";
    this.amount = 0;
    this.defaultAmount = 0.001;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("baccarat", this.app);
  }

  init() {
    let images = [];

    const SUIT = ["C", "D", "H", "S"];

    for (var i = 2; i < 10; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/cards/" + SUIT[j] + "/" + i + ".png";
        images.push(asset);
      }
    }

    const NAMES = ["J", "K", "Q", "A"];

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        var asset = ASSETS_PATH + "/cards/" + SUIT[i] + "/" + NAMES[j] + ".png";
        images.push(asset);
      }
    }

    this.loader.add([images]);
    this.loader.addArray({
      path: ASSETS_PATH,
      names: [
        // "bgb",
        "player",
        "banker",
        "tie",
        "bull_full",
        "places",
        "place_active",
        "reload",
        "back",
      ],
      resource: [
        // "bg.png",
        "player.png",
        "banker.png",
        "tie.png",
        "bull_full.png",
        "place.png",
        "place_active.png",
        "reload.png",
        "back.png",
      ],
    });

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true;
    this.container.sortableChildren = true;
    this.app.stage.addChild(this.container);

    wait(1500).then(() => {
      Event.emit("clear_amount");
    });

    // this.configBackground();
    this.configCardBack();
    this.configTables();
    this.configScore();
    this.configAmounts();
    this.configButton();
    this.configAnim();
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        count += 0.01;
      }
    });
  }

  configBackground() {
    this.bg = PIXI.Sprite.from(ASSETS_PATH + "/bull_full.png");
    this.bg.anchor.set(0.5);
    this.bg.width = 250;
    this.bg.height = 414;
    this.bg.x = this.centerX;
    this.bg.y = this.centerY + 5;
    this.bg.alpha = 0.7;
    var bg = PIXI.Sprite.from(ASSETS_PATH + "/bg.png");
    bg.x = -250;
    bg.y = 2;
    bg.width = 500;
    bg.height = 143;
    this.bg.addChild(bg);
    this.container.addChild(this.bg);
  }

  configCardBack() {
    for (var j = 0; j < this.back.length; j++) {
      let back = this.back[j];
      this.container.removeChild(back);
    }
    this.back = [];
    for (var i = 0; i < 4; i++) {
      let back = PIXI.Sprite.from(ASSETS_PATH + "/back.png");
      back.anchor.set(0.5);
      back.x = i * 20 + 100;
      back.y = 80;
      back.width = 100;
      back.height = 142;
      this.container.addChild(back);
      this.back.push(back);
    }
    for (var i = 0; i < 4; i++) {
      let back = PIXI.Sprite.from(ASSETS_PATH + "/back.png");
      back.anchor.set(0.5);
      back.x = i * 20 + 500;
      back.y = 80;
      back.width = 100;
      back.height = 142;
      this.container.addChild(back);
      this.back.push(back);
    }
  }

  configTables() {
    this.player = PIXI.Sprite.from(ASSETS_PATH + "/player.png");
    this.player.anchor.set(0.5);
    this.player.x = 130;
    this.player.y = 310;
    this.player.interactive = true;
    this.player.buttonMode = true;
    this.player.on("click", (e) => this.addChip("player", this.defaultAmount));
    this.player.on("touchstart", (e) =>
      this.addChip("player", this.defaultAmount)
    );
    this.tie = PIXI.Sprite.from(ASSETS_PATH + "/tie.png");
    this.tie.anchor.set(0.5);
    this.tie.x = 335;
    this.tie.y = 310;
    this.tie.width = 160;
    this.tie.height = 79;
    this.tie.interactive = true;
    this.tie.buttonMode = true;
    this.tie.on("click", (e) => this.addChip("tie", this.defaultAmount));
    this.tie.on("touchstart", (e) => this.addChip("tie", this.defaultAmount));

    this.banker = PIXI.Sprite.from(ASSETS_PATH + "/banker.png");
    this.banker.anchor.set(0.5);
    this.banker.x = 540;
    this.banker.y = 310;
    this.banker.interactive = true;
    this.banker.buttonMode = true;
    this.banker.on("click", (e) => this.addChip("banker", this.defaultAmount));
    this.banker.on("touchstart", (e) =>
      this.addChip("banker", this.defaultAmount)
    );
    this.chips["player"] = new PIXI.Container();
    this.chips["tie"] = new PIXI.Container();
    this.chips["banker"] = new PIXI.Container();
    this.player.addChild(this.chips["player"]);
    this.tie.addChild(this.chips["tie"]);
    this.banker.addChild(this.chips["banker"]);
    this.container.addChild(this.player, this.tie, this.banker);

    this.playerCards = new PIXI.Container();
    this.playerCards.x = 50;
    this.bankerCards = new PIXI.Container();
    this.bankerCards.x = 400;
    this.container.addChild(this.playerCards, this.bankerCards);
  }

  configScore() {
    let style = new PIXI.TextStyle({
      fontFamily: "Roboto, sans-serif",
      fontSize: 15,
      fontWeight: 700,
      fill: [0xffffff],
    });
    this.playerPlace = new PIXI.Sprite(
      PIXI.Texture.from(ASSETS_PATH + "/place.png")
    );
    this.playerPlace.x = 110;
    this.playerPlace.y = 170;
    this.playerScore = new PIXI.Text(0, style);
    this.playerScore.x = 21;
    this.playerScore.y = 17;
    this.playerPlace.addChild(this.playerScore);
    this.container.addChild(this.playerPlace);

    this.bankerPlace = new PIXI.Sprite(
      PIXI.Texture.from(ASSETS_PATH + "/place.png")
    );
    this.bankerPlace.x = 515;
    this.bankerPlace.y = 170;
    this.bankerScore = new PIXI.Text(0, style);
    this.bankerScore.x = 21;
    this.bankerScore.y = 17;
    this.bankerPlace.addChild(this.bankerScore);
    this.container.addChild(this.bankerPlace);
  }

  configAmounts() {
    let tables = ["player", "tie", "banker"];
    tables.forEach((table, i) => {
      let style = new PIXI.TextStyle({
        fontFamily: "Roboto, sans-serif",
        fontSize: 15,
        fill: [0xffffff],
      });
      this.amounts[table] = new PIXI.Text("0.00000000", style);
      this.amounts[table].x = -40;
      this.amounts[table].y = 10;
      this.currency[table] = new PIXI.Sprite(
        PIXI.Texture.from("/assets/images/" + __.toLower(this.coin) + ".png")
      );
      this.currency[table].width = this.currency[table].height = 16;
      this.currency[table].x = 91;
      this.currency[table].y = 1;
      this.amounts[table].addChild(this.currency[table]);
      this[table].addChild(this.amounts[table]);
    });
  }

  configButton() {
    let style = new PIXI.TextStyle({
      fontFamily: "Roboto, sans-serif",
      fontSize: 15,
      fill: [0xffffff],
    });
    this.clear = new PIXI.Text("Clear", style);
    this.clear.x = 585;
    this.clear.y = 375;
    let image = PIXI.Sprite.from(ASSETS_PATH + "/reload.png");
    image.x = -23;
    this.clear.addChild(image);
    this.clear.interactive = true;
    this.clear.buttonMode = true;
    this.clear.on("click", (e) => this.clearTable());
    this.clear.on("touchstart", (e) => this.clearTable());
    this.container.addChild(this.clear);
  }

  addChip(id, amount) {
    if (this.started) return;
    
    if(amount > 100) return;
    this.amount += parseFloat(amount);

    if (cryptoToUsd(this.amount, this.coin) > 100) return;

    Event.emit("add_amount", amount);

    let chip = PIXI.Sprite.from(
      "/assets/images/" + __.toLower(this.coin) + ".png"
    );
    chip.name = "chip";
    chip.width = chip.height = 25;
    Effect.MoveIn(chip, randomBetween(1, -90), randomBetween(-40, -37));
    Effect.HeartBeat(chip, 1);
    this.chips[id].addChild(chip);
    let plus = parseFloat(this.amounts[id].text) + parseFloat(amount);
    this.amounts[id].text = plus.toFixed(8);
    let target = {
      player: this.amounts["player"].text,
      banker: this.amounts["banker"].text,
      tie: this.amounts["tie"].text,
    };
    this.engine.target = target;
  }

  clearTable() {
    if (this.started) return;
    Event.emit("clear_amount");
    let tables = ["player", "tie", "banker"];
    tables.forEach((table, i) => {
      this.chips[table].removeChildren();
      this.amounts[table].text = "0.00000000";
    });
    let target = {
      player: this.amounts["player"].text,
      banker: this.amounts["banker"].text,
      tie: this.amounts["tie"].text,
    };
    this.engine.target = target;
    this.amount = 0;
  }

  showWin(winner) {
    this.started = false;
    if (winner === "tie") {
      this.tie.texture = PIXI.Texture.from(ASSETS_PATH + "/tie_active.png");
    } else {
      this[`${winner}Place`].texture = PIXI.Texture.from(
        ASSETS_PATH + "/place_active.png"
      );
    }
  }

  valueForCard(value) {
    if (value === "A") return 1;
    else if (value === "J" || value === "K" || value === "Q" || value === 10)
      return 0;
    else return value;
  }

  addCard(target, card, path, id, delay) {
    let sprite = PIXI.Sprite.from(
      ASSETS_PATH + `/cards/${card.suit}/${card.value}.png`
    );

    sprite.width = 100;
    sprite.height = 145;
    sprite.x = target === "banker" ? 650 : -150;
    this[`${target}Cards`].addChild(sprite);
    wait(delay).then(() => {
      Effect.MoveIn(sprite, 50 * id, 20);
      if (target === "banker") {
        this.bankerScore.text =
          parseFloat(this.bankerScore.text) + this.valueForCard(card.value);
        if (parseFloat(this.bankerScore.text) >= 10) {
          this.bankerScore.x = 16;
        }
      } else {
        this.playerScore.text =
          parseFloat(this.playerScore.text) + this.valueForCard(card.value);
        if (parseFloat(this.playerScore.text) >= 10) {
          this.playerScore.x = 15;
        }
      }
    });
  }

  play(result) {
    if (this.started) return;

    result = result.result;

    this.started = true;
    this.playerCards.removeChildren();
    this.bankerCards.removeChildren();
    this.playerPlace.texture = PIXI.Texture.from(ASSETS_PATH + "/place.png");
    this.bankerPlace.texture = PIXI.Texture.from(ASSETS_PATH + "/place.png");
    this.playerScore.x = 21;
    this.bankerScore.x = 21;
    this.playerScore.text = 0;
    this.bankerScore.text = 0;

    for (var j = 0; j < this.back.length; j++) {
      let back = this.back[j];
      this.container.removeChild(back);
    }

    let id = 1,
      delay;
    let self = this,
      i = 0;

    Object.keys(result.player).forEach(function (suit, key) {
      let card = {
        suit: suit,
        value: result.player[suit],
      };
      if (id === 1) {
        delay = 1000;
      } else if (id === 2) {
        delay = 3000;
      } else if (id === 3) {
        delay = 5000;
      }
      self.addCard("player", card, key, i, delay);
      id++;
      i++;
    });

    let c = 0;
    Object.keys(result.banker).forEach(function (suit, key) {
      let card = {
        suit: suit,
        value: result.banker[suit],
      };
      if (id === 4) {
        delay = 2000;
      } else if (id === 5) {
        delay = 4000;
      } else if (id === 6) {
        delay = 6000;
      }
      self.addCard("banker", card, key, c, delay);
      id++;
      c++;
    });

    wait(7000).then(() => {
      this.showWin(result.outcome);
    });
  }

  busted() {
    wait(1000).then(() => {
      this.playerCards.removeChildren();
      this.bankerCards.removeChildren();

      this.configCardBack();
      this.clearTable();
      this[`bankerPlace`].texture = PIXI.Texture.from(
        ASSETS_PATH + "/place.png"
      );
      this[`playerPlace`].texture = PIXI.Texture.from(
        ASSETS_PATH + "/place.png"
      );
      this.tie.texture = PIXI.Texture.from(ASSETS_PATH + "/tie.png");
      this.bankerScore.text = 0;
      this.playerScore.text = 0;
      this.amount = 0;
    });
  }

  destroy() {
    this.app = null;
  }
}

Canvas.prototype.setAmount = function (value) {
  this.defaultAmount = value;
};

Canvas.prototype.setCoin = function (value) {
  if (__.isUndefined(value)) return;
  this.coin = value;

  let tables = ["player", "tie", "banker"];
  tables.forEach((table, i) => {
    if (__.isUndefined(this.currency[table])) return;
    this.currency[table].texture = PIXI.Texture.from(
      "/assets/images/" + __.toLower(this.coin) + ".png"
    );
  });
};

export default Canvas;
