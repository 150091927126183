import React from "react";
import { Button, InputGroup, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  isMobile,
  Event,
  wait,
  isValidNumber,
  forceSatoshiFormat,
  isLogged,
  __,
  getDefaultBetAmount,
  sendNotfication,
  playAudio,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      buttonText: "Roll",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      disabled: false,
      formData: {
        risk: "Medium",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef2 = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    this.props.gameCoin();
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    if (isMobile()) {
      this.setState({ mt: "mt-2" });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
      Event.emit("set_amount", value);
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  busted(data) {
    if (this._isMounted) {
      let isWin = false;

      if (parseFloat(data.profit) !== 0) {
        isWin = true;
      }

      wait(500).then(() => {
        this.setState({ disabled: false });
      });

      wait(500).then(() => {
        if (isWin) {
          playAudio("win.mp3");
        } else {
          playAudio("lost.mp3");
        }
      });
    }
  }

  setBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let self = this;

      let { amount } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.setState({ disabled: true });
      this.placeBet();
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;

      wait(100).then(() => {
        engine.game = "diamond";
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.risk = this.state.formData.risk;
        engine.start();
      });
    }
  }

  handleChange = (value) => {
    this.setState({ type: value });
  };

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;

    this.setState({ [target.name]: value });
    Event.emit("set_amount", value);
  }

  handleRisk = (e, value) => {
    if (this._isMounted) {
      e.preventDefault();
      for (var i = 0; i < this.wrapperRef2.current.children.length; i++) {
        this.wrapperRef2.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      if (this.state.inputDisabled) return;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          risk: value,
        },
      }));
      Event.emit("diamond_risk", value);
    }
  };

  betMode = (type) => {
    this.setState({ bet: type });
  };

  setAmount = (value) => {
    if (!this.state.disabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        Event.emit("set_amount", value);
      }
    }
  };

  render() {
    let { disabled, amount, bet } = this.state;
    return (
      <>
        <Form className="w-100" onSubmit={(e) => this.setBet(e)}>
          <Row className={"mt-1"}>
            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                  className="form-control text-left"
                  required={true}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={6}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>RISK</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef2}>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "medium");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "high");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          {bet === "manual" && (
            <>
              <Row>
                <Col md={5} sm={12} className="m-auto">
                  <div className={"form-group rev mt-2 mb-0"}>
                    <Button
                      variant={"btn btn-block btn-bet btn-xl mb-2"}
                      id={"bet"}
                      disabled={disabled}
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>

        {bet === "auto" && (
          <>
            <AutoBet engine={this.state.engine} amount={this.state.amount} />
          </>
        )}
        <div
          className="btn-group btn-group-toggle mb-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={disabled}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={disabled}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
