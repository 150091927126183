import React from "react";
import GameModal from "../../../Components/Game/Stat/Modal";
import { __, isMobile } from "../../../../Helper";
import C from "../../../../Constant";

class HistoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameRow: [],
      game: this.props.game,
      engine: this.props.engine,
    };
  }

  componentDidMount() {
    let { engine } = this.state;

    engine.getHistory();
    engine.on(C.HISTORY_CRASH, (data) => this.gameSync(data));
    engine.on("busted_crash", (data) => this.busted(data));
  }

  gameSync(list) {
    if (list.history === undefined) return;
    if (list.history.length === 0) return;
    list = __.xor(list.history);

    if (isMobile()) {
      list.length = 4;
    } else {
      list.length = 16;
    }

    list.forEach((array, i) => {
      if (i < 16) {
        if (!__.isNull(array.busted))
          this.setState((state) => ({ gameRow: [array, ...state.gameRow] }));
      }
    });
  }

  busted(data) {
    this.setState((state) => ({ gameRow: [data, ...state.gameRow] }));
  }

  render() {
    const list = this.state.gameRow.map((g, i) => (
      <li
        key={i}
        className={
          (!__.isUndefined(g.amount) ? g.amount / 100 : g.busted) >= 1.9
            ? "success"
            : "danger"
        }
      >
        <img
          className="iconcrashbets rotate"
          src={
            (!__.isUndefined(g.amount) ? g.amount / 100 : g.busted) >= 1.9
              ? "/assets/images/point_green.png"
              : "/assets/images/point_red.png"
          }
        />
        <GameModal
          color={
            (!__.isUndefined(g.amount) ? g.amount / 100 : g.busted) >= 1.9
              ? "success"
              : "danger"
          }
          title={
            !__.isUndefined(g.amount)
              ? parseFloat((g.amount / 100)).toFixed(2)
              : parseFloat(g.busted).toFixed(2)
          }
          game_id={!__.isUndefined(g.gid) ? g.gid : g.game_id}
          font={13}
        />
      </li>
    ));

    if (isMobile()) {
      list.length = 4;
    } else {
      list.length = 16;
    }
    return (
      <div className="nav font-14 font-weight-bold mybets">
        <div className="overlay" />
        <ul>{list}</ul>
      </div>
    );
  }
}

export default HistoryList;
