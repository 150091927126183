import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Add from "./Add";
import Help from "./Help";
import List from "./List";
import Content from "./Content";

class Script extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { engine } = this.props;
    return (
      <Row>
        <Col lg="4" md="5">
          <h5 className="text-secondary">Script List</h5>
          <div>
            <List />
            <hr />
            <Add />
            <Help />
          </div>
        </Col>
        <Col lg="8" md="6">
          <h5 className="text-warning">Manage</h5>
          <div>
            <Content engine={engine} />
          </div>
        </Col>
      </Row>
    );
  }
}

export default Script;
