import React from "react";
import { __, Event, isMobile, isTablet, playAudio } from "../../../Helper";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;
    engine.on("play", (data) => this.play(data));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  play(data) {
    if (this._isMounted) {
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn">
          <div id="loading" className="ycenter" />
          <Canvas engine={this.state.engine} />
        </div>
      </>
    );
  }
}

export default Game;
