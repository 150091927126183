import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { encode, decode, __, Event, isMobile, isTablet } from "../../Helper";

class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          name: "amatic",
          image: "alc",
        },
        {
          name: "betgames",
          image: "avatar",
        },
        {
          name: "big time Gaming",
          image: "bigtime",
        },
        {
          name: "boominggames",
          image: "boom",
        },
        {
          name: "blueprint",
          image: "bull",
        },
        {
          name: "Endorphina",
          image: "end",
        },
        {
          name: "Evolution",
          image: "evo",
        },
        {
          name: "Habanero",
          image: "hab",
        },
        {
          name: "kalamba",
          image: "hacksaw",
        },
        {
          name: "Netent",
          image: "netent",
        },
        {
          name: "novomatic",
          image: "nolimit",
        },
        {
          name: "playson",
          image: "play",
        },
        {
          name: "platipus",
          image: "pop",
        },
        {
          name: "pragmaticplay",
          image: "pragmatic",
        },
        {
          name: "onetouch",
          image: "push",
        },
        {
          name: "Redrake",
          image: "red-tiger",
        },
        {
          name: "retrogaming",
          image: "rela",
        },
        {
          name: "threeoaks",
          image: "tada",
        },
        {
          name: "Thunderkick",
          image: "thu",
        },
        {
          name: "turbogames",
          image: "turbo",
        },
      ],
    };
  }

  render() {
    const providers = this.state.items.map((item, i) => (
      <>
        <Col md="2" sm="6" className="mb-2">
          <Link to={"/provider/" + item.name}>
            <img
              className="img-fluid"
              src={`/assets/images/provider/new/${item.image}.png`}
            />
          </Link>
        </Col>
      </>
    ));
    return (
      <>
        <h3 className="providers-title">Our Providers</h3>
        <div class="provider-containers">
          <Link to="/provider/pragmatic">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg"
                alt="pragmatic"
                class="css-152fblk"
              />
              <div class="counter-slots">717 Games</div>
            </div>
          </Link>
          <Link to="/provider/evolution">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/evolution.svg"
                alt="evolution"
                class="css-152fblk"
              />
              <div class="counter-slots">478 Games</div>
            </div>
          </Link>
          <Link to="/provider/nolimit">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/nolimit.svg"
                alt="nolimit"
                class="css-152fblk"
              />
              <div class="counter-slots">96 Games</div>
            </div>
          </Link>
          <Link to="/provider/relax">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/relax.svg"
                alt="relax"
                class="css-152fblk"
              />
              <div class="counter-slots">109 Games</div>
            </div>
          </Link>
          <Link to="/provider/bgaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/bgaming.svg"
                alt="bgaming"
                class="css-152fblk"
              />
              <div class="counter-slots">117 Games</div>
            </div>
          </Link>
          <Link to="/provider/pushgaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/pushgaming.svg"
                alt="pushgaming"
                class="css-152fblk"
              />
              <div class="counter-slots">45 Games</div>
            </div>
          </Link>
          <Link to="/provider/elk">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/elk.svg"
                alt="elk"
                class="css-152fblk"
              />
              <div class="counter-slots">101 Games</div>
            </div>
          </Link>
          <Link to="/provider/spinomenal">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/spinomenal.svg"
                alt="spinomenal"
                class="css-152fblk"
              />
              <div class="counter-slots">375 Games</div>
            </div>
          </Link>
          <Link to="/provider/redtiger">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/redtiger.svg"
                alt="redtiger"
                class="css-152fblk"
              />
              <div class="counter-slots">285 Games</div>
            </div>
          </Link>
          <Link to="/provider/playtech">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/playtech.svg"
                alt="playtech"
                class="css-152fblk"
              />
              <div class="counter-slots">269 Games</div>
            </div>
          </Link>
          <Link to="/provider/highfive">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/highfive.svg"
                alt="highfive"
                class="css-152fblk"
              />
              <div class="counter-slots">254 Games</div>
            </div>
          </Link>
          <Link to="/provider/wazdan">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/wazdan.svg"
                alt="wazdan"
                class="css-152fblk"
              />
              <div class="counter-slots">173 Games</div>
            </div>
          </Link>
          <Link to="/provider/evoplay">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/evoplay.svg"
                alt="evoplay"
                class="css-152fblk"
              />
              <div class="counter-slots">168 Games</div>
            </div>
          </Link>
          <Link to="/provider/bsg">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/bsg.svg"
                alt="bsg"
                class="css-152fblk"
              />
              <div class="counter-slots">150 Games</div>
            </div>
          </Link>
          <Link to="/provider/endorphina">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/endorphina.svg"
                alt="endorphina"
                class="css-152fblk"
              />
              <div class="counter-slots">132 Games</div>
            </div>
          </Link>
          <Link to="/provider/gameart">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/gameart.svg"
                alt="gameart"
                class="css-152fblk"
              />
              <div class="counter-slots">131 Games</div>
            </div>
          </Link>
          <Link to="/provider/pgsoft">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/pgsoft.svg"
                alt="pgsoft"
                class="css-152fblk"
              />
              <div class="counter-slots">127 Games</div>
            </div>
          </Link>
          <Link to="/provider/microgaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/microgaming.svg"
                alt="microgaming"
                class="css-152fblk"
              />
              <div class="counter-slots">127 Games</div>
            </div>
          </Link>
          <Link to="/provider/habanero">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/habanero.svg"
                alt="habanero"
                class="css-152fblk"
              />
              <div class="counter-slots">118 Games</div>
            </div>
          </Link>
          <Link to="/provider/quickspin">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/quickspin.svg"
                alt="quickspin"
                class="css-152fblk"
              />
              <div class="counter-slots">103 Games</div>
            </div>
          </Link>
          <Link to="/provider/isoftbet">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/isoftbet.svg"
                alt="isoftbet"
                class="css-152fblk"
              />
              <div class="counter-slots">97 Games</div>
            </div>
          </Link>
          <Link to="/provider/belatra">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/belatra.svg"
                alt="belatra"
                class="css-152fblk"
              />
              <div class="counter-slots">83 Games</div>
            </div>
          </Link>
          <Link to="/provider/thunderkick">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/thunderkick.svg"
                alt="thunderkick"
                class="css-152fblk"
              />
              <div class="counter-slots">82 Games</div>
            </div>
          </Link>
          <Link to="/provider/mascot">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/mascot.svg"
                alt="mascot"
                class="css-152fblk"
              />
              <div class="counter-slots">82 Games</div>
            </div>
          </Link>
          <Link to="/provider/amatic">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/amatic.svg"
                alt="amatic"
                class="css-152fblk"
              />
              <div class="counter-slots">80 Games</div>
            </div>
          </Link>
          <Link to="/provider/novomatic">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/novomatic.svg"
                alt="novomatic"
                class="css-152fblk"
              />
              <div class="counter-slots">70 Games</div>
            </div>
          </Link>
          <Link to="/provider/retrogaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/retrogaming.svg"
                alt="retrogaming"
                class="css-152fblk"
              />
              <div class="counter-slots">67 Games</div>
            </div>
          </Link>
          <Link to="/provider/octoplay">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/octoplay.svg"
                alt="octoplay"
                class="css-152fblk"
              />
              <div class="counter-slots">63 Games</div>
            </div>
          </Link>
          <Link to="/provider/platipus">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/platipus.svg"
                alt="platipus"
                class="css-152fblk"
              />
              <div class="counter-slots">54 Games</div>
            </div>
          </Link>
          <Link to="/provider/irondogstudio">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/irondogstudio.svg"
                alt="irondogstudio"
                class="css-152fblk"
              />
              <div class="counter-slots">53 Games</div>
            </div>
          </Link>
          <Link to="/provider/bigtimegaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/bigtimegaming.svg"
                alt="bigtimegaming"
                class="css-152fblk"
              />
              <div class="counter-slots">43 Games</div>
            </div>
          </Link>
          <Link to="/provider/popiplay">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/popiplay.svg"
                alt="popiplay"
                class="css-152fblk"
              />
              <div class="counter-slots">41 Games</div>
            </div>
          </Link>
          <Link to="/provider/maxwingaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/maxwingaming.svg"
                alt="maxwingaming"
                class="css-152fblk"
              />
              <div class="counter-slots">35 Games</div>
            </div>
          </Link>
          <Link to="/provider/all41studios">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/all41studios.svg"
                alt="all41studios"
                class="css-152fblk"
              />
              <div class="counter-slots">33 Games</div>
            </div>
          </Link>
          <Link to="/provider/slotmill">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/slotmill.svg"
                alt="slotmill"
                class="css-152fblk"
              />
              <div class="counter-slots">33 Games</div>
            </div>
          </Link>
          <Link to="/provider/avatarux">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/avatarux.svg"
                alt="avatarux"
                class="css-152fblk"
              />
              <div class="counter-slots">30 Games</div>
            </div>
          </Link>
          <Link to="/provider/gamebeat">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/gamebeat.svg"
                alt="gamebeat"
                class="css-152fblk"
              />
              <div class="counter-slots">25 Games</div>
            </div>
          </Link>
          <Link to="/provider/gameburgerstudios">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/gameburgerstudios.svg"
                alt="gameburgerstudios"
                class="css-152fblk"
              />
              <div class="counter-slots">22 Games</div>
            </div>
          </Link>
          <Link to="/provider/fantasma">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/fantasma.svg"
                alt="fantasma"
                class="css-152fblk"
              />
              <div class="counter-slots">22 Games</div>
            </div>
          </Link>
          <Link to="/provider/blueprint">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/blueprint.svg"
                alt="blueprint"
                class="css-152fblk"
              />
              <div class="counter-slots">20 Games</div>
            </div>
          </Link>
          <Link to="/provider/printstudios">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/printstudios.svg"
                alt="printstudios"
                class="css-152fblk"
              />
              <div class="counter-slots">16 Games</div>
            </div>
          </Link>
          <Link to="/provider/spinplay">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/spinplay.svg"
                alt="spinplay"
                class="css-152fblk"
              />
              <div class="counter-slots">14 Games</div>
            </div>
          </Link>
          <Link to="/provider/gamesinc">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/gamesinc.svg"
                alt="gamesinc"
                class="css-152fblk"
              />
              <div class="counter-slots">12 Games</div>
            </div>
          </Link>
          <Link to="/provider/fugaso">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/fugaso.svg"
                alt="fugaso"
                class="css-152fblk"
              />
              <div class="counter-slots">10 Games</div>
            </div>
          </Link>
          <Link to="/provider/backseatgaming">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/backseatgaming.svg"
                alt="backseatgaming"
                class="css-152fblk"
              />
              <div class="counter-slots">10 Games</div>
            </div>
          </Link>
          <Link to="/provider/bullsharkgames">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/bullsharkgames.svg"
                alt="bullsharkgames"
                class="css-152fblk"
              />
              <div class="counter-slots">7 Games</div>
            </div>
          </Link>
          <Link to="/provider/pearfiction">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/pearfiction.svg"
                alt="pearfiction"
                class="css-152fblk"
              />
              <div class="counter-slots">5 Games</div>
            </div>
          </Link>
          <Link to="/provider/tripleedgestudios">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/tripleedgestudios.svg"
                alt="tripleedgestudios"
                class="css-152fblk"
              />
              <div class="counter-slots">3 Games</div>
            </div>
          </Link>
          <Link to="/provider/gamomat">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/gamomat.svg"
                alt="gamomat"
                class="css-152fblk"
              />
              <div class="counter-slots">2 Games</div>
            </div>
          </Link>
          <Link to="/provider/netent">
            <div class="provider-box">
              <img
                src="https://cdn2.softswiss.net/logos/providers/white/netent.svg"
                alt="netent"
                class="css-152fblk"
              />
              <div class="counter-slots">1 Game</div>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default Providers;
