import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import Favourites from "./Favourites";
import Sounds from "./Sounds";
import Seed from "./Seed";
import Help from "./Help";

class TopBar extends Component {
  render() {
    let { gid, seed, t } = this.props;
    seed = seed === undefined ? true : false;
    return (
      <>
        <ReactTooltip />
        <div className="btn-group btn-group-toggle align-items-center">
          <Favourites gid={gid} />
          <Sounds />
          {seed && <Seed />}
          <Help content={this.props.help} t={t} image={this.props.image} />
        </div>
      </>
    );
  }
}

export default TopBar;
