import React, { Component } from 'react';
import {Modal} from "react-bootstrap";
import {Event, isEmail, decode, encode, sendNotfication, wait} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Forget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            disabled: false,
            email: ''
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.on(C.RESET_PASSWORD, data => this.resetPassword(decode(data)));
    }

    handleShow(e){
        this.props.clicked();
        this.setState({ show: true });
    }

    handleClose(){
        // this.setState({ show: false });
        this.props.handleClose();

    }

    register = (value) => {
        this.handleClose();
        Event.emit('register_form');
    };

    resetPassword = (data) => {
        let response = data;
        const { t } = this.props;

        this.setState({disabled: false});

        if(response.status)
        {
            return sendNotfication(t('your_password_sended_to_email'), 'success','top-center');
        }
        else {
            return sendNotfication(t('this_email_is_not_registred_on_our_system'), 'warning','top-center');
        }
    };

    submitForm(e){
        e.preventDefault();
        const { t } = this.props;

        if(!isEmail(this.state.email))
        {
            return sendNotfication(t('please_enter_valid_email_address'), 'warning','top-center');
        }

        this.setState({disabled: true});

        wait(700).then(() => {
            socket.emit(C.RESET_PASSWORD, encode({ email: this.state.email }));
        })
    }

    back = () => {
        this.props.clicked();
    };

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.loading ?
                    <>
                        <div className="text-center">
                            <div className="spinner-border text-info my-3" role="status"/>
                        </div>
                    </>
                    :
                    <>
                    <div className="text-center">
                        <img src="/assets/images/betsmixer-g.svg" className={'img-fluid auth-logo'} alt="Logo"/>
                        <div className="text-center auth-logo-text">
                        <p className="mt-0 mb-3 mt-3 font-new text-white resetpasswordtext font-15">
                           {t('get_your_password_in_your_email')}
                        </p>
                        </div>
                    </div>
                    <div className="px-4 mainlogin forgetpassswordmodal">
                        <form className="my-4" onSubmit={ (e) => this.submitForm(e)} >
                            <div className="form-group text-center">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text bgp">{t('email')}</span>
                                        </div>
                                        <input type="email" className="form-control" name="email" placeholder={t('enter_your_email')} autocomplete={"off"} style={{height: 40}}
                                            onChange={e => this.setState({ email: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-2 text-center forgetarea">
                                <button type="submit" className="btn btn-auth no-shadow btn-block" disabled={this.state.disabled}>
                                    <i className="mdi mdi-email" /> {t('send_password')}
                                </button>
                            </div>

                            <div className="form-group mt-2 text-center forgetarea forgetareabottom">
                                <img src="/assets/images/footer/footer-i1.svg" alt="logo" className="" />
                                <a href="#" className="registerlink registerlinkforget ms-2 opacity-75" onClick={this.register}>
                                    {t('or_register_new_account')}
                                </a>
                            </div>
                        </form>
                    </div>
                    </>
            }
            </>
        );
    }
}

export default Forget;
