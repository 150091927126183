import React from 'react';
import { justRandomString, addNewStyle, __ } from './Helper';

function Style(style)
{
	var classs = justRandomString(7);

	var c = 0, str = '';
	for(var i in style){
	    var val = style[i]

	    if(__.isNumber(val))
	        val = val + 'px';

	    var name = Object.keys(style);
	    var s = __.split(name, ',')
	    var tag = __.toLower(s[c]);
	    var t = tag =  '\t' +  __.replace(tag, ' ', '-') + ':' + val + ';\n';
	    str += t;
	    c++;
	}

	const make = `.${classs}{ \n ${str} }`;

	addNewStyle(make);

	return classs
}

export default Style
