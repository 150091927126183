import React from 'react'
import { Modal, Row, Col, Card, Form, Tab, Tabs } from "react-bootstrap";
import { __, decode, encode, getElement, wait, Event, isMobile, sendNotfication, forceSatoshiFormat, fixDate } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import Deposit from "../../Components/User/Wallet/Deposit";
import storage from "../../../Storage";
import { Helmet } from "react-helmet";

export default class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey("logged") !== null ? true : false,
            vip_level: 0,
            level_value: [],
            deposit_show: false,
        };
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        if(this.state.isLogged) {
            // let email =storage.getKey("email");
            socket.emit(C.GET_VIP_LEVEL);
            socket.on(C.GET_VIP_LEVEL, data => {
                let dec= decode(data);
                this.setState({ vip_level: dec});
            })
        }
        socket.emit(C.GET_VIP_LEVELS);
        socket.on(C.GET_VIP_LEVELS, data => {
            let dec= decode(data);
            this.setState({ level_value: JSON.parse(dec.vip_levels)});
        })
    }
    componentWillUnmount() {
        socket.off(C.GET_VIP_LEVEL);
        socket.off(C.GET_VIP_LEVELS);
    }
    handleClose() {
        this.setState({ deposit_show: false })
    }
    render() {
        const { t } = this.props;
        const basePath = 'assets/images/medals/betsmixer/';
        const numRows = 1;
        const numCols = 5;
        const level_span = ['bronze', 'gold', 'diamond', 'platinum', 'elite'];
        const activeImageStyle = {
            maxWidth: '55px', 
            height: 'auto', 
            padding: '0 0',
          };
        const noactiveImageStyle = {
            maxWidth: '90px', 
            height: 'auto', 
            filter: 'opacity(70%) blur(4px)',
            padding: '1rem 0',
        }
        const {vip_level, isLogged, level_value} = this.state;
        return (
            <>
                <Helmet>
                    <title>VIP Levels - BetsMixer.com</title>
                    <meta
                        name="description"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <meta
                        name="og:title"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <meta
                        name="og:description"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <link rel="canonical" href="/vip-levels" />
                </Helmet>
                <div className="container containerpromotion viplevels">
                    <div className='row rounded rounded-promotion no-time_differ text-center text-white py-3' style={{ backgroundImage: 'url("/assets/images/promo/promoMainVIP.png")', backgroundSize: '100% 115%', backgroundRepeat: 'no-repeat' }}>
                        <div className='col-3' />
                        <div className='col-6'>
                            <h2 className="loyaltyrewards1 viplevelstitle"> {t('vip_levels')}</h2>
                            <div className="d-flex justify-content-center"><div className='rounded-pill' style={{ backgroundColor: 'rgb(0 0 0 / 30%)' }}><h2 className="loyaltyrewards2">{t('achieve_up')} <span className='text-warning' style={{ fontSize: '37.5px' }}>5+</span> {t('levels_s')}</h2></div></div>
                            <h6 className="loyaltyrewards3" style={{ marginBottom: 0 }}>{t('wager_more_unlock_vip_levels_and')}</h6>
                            <h6 className="loyaltyrewards4">{t('enjoy')} <span className="text-vip-rewards">{t('hunky_vip')}</span> {t('rewards_s')}</h6>
                            <button type="button" className="btn btn-warning w-50 loyaltyrewardsbutton vipbutton" onClick={() => { isLogged ? this.setState({ deposit_show: true }): sendNotfication(t('you_must_log_in_first')) }}>{t('deposit_now')}<i></i></button>
                        </div>
                        <div className='col-3' />
                    </div>
                    <div className="row viprow my-2 text-white vippurple bg-purple" >
                        <div className="p-3 vipcard">
                            <img className="coinboy" src="/assets/images/vipbull.png" alt="Coin Boy" />
                            <h4 className="viptitles">
                            <img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" />
                            <strong>{t('vip_club_program_overview')}</strong></h4>
                            <p className='vipcard-des' style={{ fontSize: '13px', paddingRight: '209px', position: 'relative', zIndex: '2'}}>{t('our_vip_club_allows_you_etc')}</p>
                            <div className="progress vipageprogress">
                                <div role="progressbar" className="progress-bar" aria-valuenow={vip_level} aria-valuemin="0" aria-valuemax="6" style={vip_level > 0 ? {width: `${vip_level * 100 / 5}%`, height: '20px'} : {width: '25px', height: '20px'}}>
                                </div>
                            </div>
                            <div className="progressbar-tooltip-container d-flex">
                                <div className='position-absolute'><span className="progressbar-tooltip">VIP 0</span></div>
                                {vip_level > 0 && vip_level < 5 && <div className='position-absolute' style={{left:`calc(${vip_level * 100 / 5}% - 25px)`}}><span className="progressbar-tooltip-yellow">VIP {vip_level}</span></div>}
                                {vip_level == 5 && <div className='position-absolute' style={{left:'calc(100% - 50px)'}}><span className="progressbar-tooltip-yellow">VIP {vip_level}</span></div>}
                                {vip_level < 5 && <div className='position-absolute' style={{left:'calc(100% - 50px)'}}><span className="progressbar-tooltip">VIP 5</span></div>}
                            </div>
                            <div className="viplevels-radio">
                                <label htmlFor="one" style={{left: '0%'}}>{t('without_rank')}</label>
                                <label htmlFor="onehundred" style={{marginLeft: 'auto'}}>{t('bronze')}</label>
                            </div>
                            <i></i>
                        </div>
                    </div>
                    <Card className="row viprow bg-dark text-white">
                        <Card.Body>
                            <Card.Title className='text-center viptitle'>
                            <span style={{textTransform: 'uppercase'}}><img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" /><span className="text-warning vipyellow">VIP</span> {t('levels')}</span>
                            </Card.Title>
                            <Row>
                                <Col sm={6} className="viptab">
                                    <div>
                                        <div className="p-3 p-3-f">
                                            <h4>
                                            <img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" />{t('highest_profitability_for_player')}</h4>
                                            <p style={{ fontSize: '13px', marginBottom: '0'}}>
                                            {t('our_bonuses_are_absolutely_transparent_etc')}</p>
                                            <p style={{ fontSize: '13px' }}>{t('only_a_net_profit_without_additional_etc')}</p>
                                        </div>
                                        <div className="p-3 bullast">
                                            <h4>
                                            <img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" />{t('exclusive_vip_bonuses')}</h4>
                                            <p style={{ fontSize: '13px', marginBottom: '0'}}>{t('we_value_every_player_etc')}</p>
                                        
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} className="viptab viptablevels">
                                    <Tabs
                                        defaultActiveKey="tab-0"
                                        id="vip-tab"
                                        className="mb-3"
                                        justify
                                    >
                                        {[...Array(numCols)].map((_, colIndex) => ( 
                                            <Tab eventKey={`tab-${colIndex}`}
                                                title={
                                                    <div className="text-center medalvip">
                                                        <img
                                                            src={`${basePath}${colIndex + 1}.png`}
                                                            alt={`Photo ${colIndex + 1}`}
                                                            style={activeImageStyle}
                                                        />
                                                        <span className="levelSpan">{t(level_span[colIndex])}</span>
                                                    </div>
                                                }
                                            >
                                                <div className='my-3'>
                                                    <h4 className='text-danger'>{t(level_span[colIndex])}</h4>
                                                    <p className='text-secondary' style={{ fontSize: '13px' }}>{t('the_amount_of_bets_from')} {level_value[colIndex + 1]} $</p>
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'rakeback'}
                                                        label={'Rakeback'}
                                                        checked = {colIndex >= 0}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'level-up-bonus'}
                                                        label={'Level Up Bonus'}
                                                        checked = {colIndex >= 0}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'boost'}
                                                        label={'Boost'}
                                                        checked = {colIndex >= 1}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'bonus-growth'}
                                                        label={'Bonus growth'}
                                                        checked = {colIndex >= 2}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'reload'}
                                                        label={'Reload'}
                                                        checked = {colIndex >= 3}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'bespoke'}
                                                        label={'Bespoke'}
                                                        checked = {colIndex >= 3}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'personal-manager'}
                                                        label={'Personal manager'}
                                                        checked = {colIndex >= 4}
                                                    />
                                                    <Form.Check // prettier-ignore
                                                        type={'checkbox'}
                                                        id={'free-gifts'}
                                                        label={'Free Gifts'}
                                                        checked = {colIndex >= 4}
                                                    />

                                                </div>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="row viprow bg-dark text-white">
                        <Card.Body>
                            <Card.Title className='text-center viptitle'>
                                <span><img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" />{t('description_of')} <span className="text-warning vipyellow">{t('bonuses')}</span></span>
                            </Card.Title>
                            <Row>
                                <Col className="rounded viprounded text-white bg-warning m-3 background-pink-spec">
                                    <div className="bonusmarker"><img src="/assets/images/viplevels/1.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('LEVEL UP BONUS')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('go_to_the_next_level_and_earn')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                                <Col className="rounded viprounded text-white bg-primary m-3 background-pink-spec">
                                    <div className="bonusmarker"><img src="/assets/images/viplevels/2.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('rakeback_b')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('rakeback_is_a_part_of_the_player_etc')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                                <Col className="rounded viprounded text-white bg-danger m-3 background-pink-spec">
                                    <div className="bonusmarker"><img src="/assets/images/viplevels/3.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('bonus_growth')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('increase_of_all_types_of_bonuses_etc')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col className="rounded viprounded text-white bg-success m-3 background-purple-spec">
                                    <div className="bonusmarker bonusmarkerright"><img src="/assets/images/viplevels/4.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('reload')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('reload_is_a_bonus_that_etc')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                                <Col className="rounded viprounded text-white bg-info m-3 background-purple-spec">
                                    <div className="bonusmarker bonusmarkerright"><img src="/assets/images/viplevels/5.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('personal_manager')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('you_will_be_assigned_your_personal_vip_etc')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                                <Col className="rounded viprounded text-white bg-purple m-3 background-purple-spec">
                                    <div className="bonusmarker bonusmarkerright"><img src="/assets/images/viplevels/6.png" alt="" draggable="false" /></div>
                                    <div className="p-3 viprounded-title">
                                        <h4 className="my-2"><img className="diamondicon" src="/assets/images/diamonds-white.png" alt=""/>{t('bespoke_and_gifts')}</h4>
                                        <p className="mt-3" style={{ fontSize: '13px' }}>{t('contact_your_personal_vip_manager_etc')}</p>
                                    </div>
                                    <i></i>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="row viprow bg-dark text-white">
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <div className="becomevipplayertop">
                                        <h4 style={{textTransform: "uppercase"}}>
                                            <img className="diamondicon" src="/assets/images/diamonds-white.png" alt="VIP Progress" />
                                            {t('how_can_i_become_a')} <span className="text-warning">VIP {t('level')}</span> {t('player')}?
                                        </h4>
                                        <p style={{ fontSize: '13px', marginBottom: '0'}}>
                                            {t('for_each_next_level_of_the_vip_club_etc')}
                                        </p>
                                    </div>
                                    <div className="becomevipplayer">
                                        <div className="table-responsive top-winner-table num-style mb-0 tww">
                                            <table className="mb-2 table">
                                               <thead>
                                                  <tr>
                                                     <th>{t('rank')}</th>
                                                     <th>{t('level') + ' ' + t('name')}</th>
                                                     <th>VIP {t('level')}</th>
                                                     <th>{t('achievement')}</th>
                                                  </tr>
                                               </thead>
                                               <tbody>

                                                  <tr className="q-crash2">
                                                     <td>
                                                        <div className="d-flex h-100 winner-badge-wrapper">
                                                           <div className="deco-bar-first"></div>
                                                           <div className="d-flex align-items-center winner-badge"><img className="img-fluid rounded-circle ffi" src="/assets/images/viplevels/blue.png" /><span>1</span></div>
                                                        </div>
                                                     </td>
                                                     <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                           <a href="#">{t('brozon_c')}</a>
                                                        </div>
                                                     </td>
                                                     <td className="num-style text-success">{t('level')} 1</td>
                                                     <td className="num-style text-success">${level_value[1]}</td>
                                                  </tr>

                                                  <tr className="q-crash2">
                                                     <td>
                                                        <div className="d-flex h-100 winner-badge-wrapper">
                                                           <div className="deco-bar-first"></div>
                                                           <div className="d-flex align-items-center winner-badge"><img className="img-fluid rounded-circle ffi" src="/assets/images/viplevels/green.png" /><span>2</span></div>
                                                        </div>
                                                     </td>
                                                     <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                           <a href="#">{t('gold_c')}</a>
                                                        </div>
                                                     </td>
                                                     <td className="num-style text-success">{t('gold_c')} 2</td>
                                                     <td className="num-style text-success">${level_value[2]}</td>
                                                  </tr>

                                                   <tr className="q-crash2">
                                                     <td>
                                                        <div className="d-flex h-100 winner-badge-wrapper">
                                                           <div className="deco-bar-first"></div>
                                                           <div className="d-flex align-items-center winner-badge"><img className="img-fluid rounded-circle ffi" src="/assets/images/viplevels/bronze.png" /><span>3</span></div>
                                                        </div>
                                                     </td>
                                                     <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                           <a href="#">{t('diamond_s')}</a>
                                                        </div>
                                                     </td>
                                                     <td className="num-style text-success">{t('level')} 3</td>
                                                     <td className="num-style text-success">${level_value[3]}</td>
                                                  </tr>


                                                  <tr className="q-crash2">
                                                     <td>
                                                        <div className="d-flex h-100 winner-badge-wrapper">
                                                           <div className="deco-bar-first"></div>
                                                           <div className="d-flex align-items-center winner-badge"><img className="img-fluid rounded-circle ffi" src="/assets/images/viplevels/silver.png" /><span>4</span></div>
                                                        </div>
                                                     </td>
                                                     <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                           <a href="#">{t('platinum_s')}</a>
                                                        </div>
                                                     </td>
                                                     <td className="num-style text-success">{t('level')} 4</td>
                                                     <td className="num-style text-success">${level_value[4]}</td>
                                                  </tr>

                                                   <tr className="q-crash2">
                                                     <td>
                                                        <div className="d-flex h-100 winner-badge-wrapper">
                                                           <div className="deco-bar-first"></div>
                                                           <div className="d-flex align-items-center winner-badge"><img className="img-fluid rounded-circle ffi" src="/assets/images/viplevels/gold.png" /><span>5</span></div>
                                                        </div>
                                                     </td>
                                                     <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                          
                                                           <a href="#">{t('elite_s')}</a>
                                                        </div>
                                                     </td>
                                                     <td className="num-style text-success">{t('level')} 5</td>
                                                     <td className="num-style text-success">${level_value[5]}</td>
                                                  </tr>

                                               </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Modal
                        size={'lg'}
                        backdrop={'static'}
                        centered={true}
                        scrollable={false}
                        show={this.state.deposit_show}
                        onHide={this.handleClose}
                        aria-labelledby="wallet-md-modal"
                    >
                        <Modal.Header className={this.state.paddingHeader}>
                            <Row xs={1} md={2} style={{ width: '100%' }}>
                                <Col xs={6} md={6}>
                                    {/* <button
                                        onClick={this.showArchive}
                                        className={"btn bg-cs2 bg-cs5 btn-xs text-white"}
                                    > {this.state.headerText} */}

                                    {/* </button> */}
                                </Col>
                            </Row>
                            <button type="button" className="close p-2" onClick={this.handleClose}>
                                <i className={'mdi mdi-close'} />
                            </button>
                        </Modal.Header>
                        <Modal.Body className={this.state.padding + ' wallet-modal buttonswallet'}>
                            <Deposit t={t} />
                        </Modal.Body>
                    </Modal>
                </div >
            </>
        );
    }
}


