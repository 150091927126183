import React, {Component} from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, decode, encode, forceSatoshiFormat, fixDate} from "../../../../Helper";
import C from "../../../../Constant";

const C_ = React.createElement;

class WithdrawlArchive extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            withdrawls: [],
            pageIndex: 1,
            itemsPerPage: 10,
            total_items: 0
        };
    }

    componentDidMount(){
        this._isMounted = true;
        socket.emit(C.WALLET_HISTORY,  encode({pageIndex: this.state.pageIndex, items: this.state.itemsPerPage}));
        socket.on(C.WALLET_HISTORY, data => this.makeHistory(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off(C.WALLET_HISTORY);
    }

    makeHistory(data){
        if(this._isMounted)
        {
            let array = data.withdrawl;
            let withdraw_history = [];
            this.setState({ loading: false });
            if(array) {
                array.forEach((withdraw, i) => {
                    // let block = <WithdrawlTable key={i.toString()} data={withdraw}/>;
                    // this.setState(state => ({withdrawls: [block, ...state.withdrawls]}));
                    withdraw_history.push(<WithdrawlTable key={i.toString()} data={withdraw}/>)
                });
            }
            this.setState({withdrawls: [...withdraw_history], total_items: array.length > 0 ? Number(array[0].total_rows) : 0 , loading: false});
        }
    }

    nextPage = () => {
        if(this._isMounted)
        {
            // this.setState({ next: true });
            socket.emit(C.WALLET_HISTORY, encode({pageIndex: this.state.pageIndex + 1, items: this.state.itemsPerPage}));
            this.setState({ pageIndex: this.state.pageIndex + 1 });
        }
    };

    render() {
        let { loading } = this.state;
        const { t } =this.props;
        return(
            <>
                {loading ?
                    <div className="text-center  child-center" style={{ minHeight: 430 }}>
                         <div className="spinner-grow text-white my-3" role="status" />
                    </div>
                :
                    <>
                        <div className="table-responsive mt-2" style={{ minHeight: 430 }}>
                            <table className="table table-hover fix-table deposit">
                                <thead>
                                <tr>
                                    <th scope="col">{t('date')}</th>
                                    <th scope="col">{t('wallet')}</th>
                                    <th scope="col">{t('amount')}</th>
                                    <th scope="col">{t('status')}</th>
                                </tr>
                                </thead>
                                {this.state.withdrawls.length > 0 &&
                                    this.state.withdrawls
                                }
                            </table>
                            {this.state.withdrawls.length === 0 &&
                                <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                                    [ {t('your_withdarwal_list_is_empty')} ]
                                </div>
                            }
                            { (this.state.total_items / this.state.itemsPerPage) > (this.state.pageIndex) &&
                                <div className={'text-center'}>
                                    <button onClick={this.nextPage} className={'mb-2 btn bg-cs btn-xs'}>
                                        <i className={'mdi mdi-arrow-right-bold-circle'} /> {t('next_page')}
                                    </button>
                                </div>
                            }
                        </div>
                    </>
                }
            </>
        );
    }
}

class WithdrawlTable extends Component {
    createMarkup(str) {
        return { __html: str };
    }
    render(){
        let { amount, wallet, status, date, coin } = this.props.data;
        amount = forceSatoshiFormat(amount);
        status = <div dangerouslySetInnerHTML={this.createMarkup(status)} />;
        let A = C_( 'td', {}, fixDate(date));
        let B = C_( 'td', {}, wallet);
        let C = C_( 'td', {}, amount + ' ' + __.upperCase(coin));
        let D = C_( 'td', {className: "text-warning"}, status);

        return C_( 'tr',  {}, [ A, B, C, D ])
    }
}

export default WithdrawlArchive;
