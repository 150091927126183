import React from "react";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import { __, decode } from "../../../../Helper";

// function component
const AnimatedCard = ({ animation, digit }) => {
    return(
      <div className={`flipCard ${animation}`}>
        <span>{digit}</span>
      </div>
    );
};
  
  // function component
const StaticCard = ({ position, digit }) => {
    return(
        <div className={position}>
            <span>{digit}</span>
        </div>
    );
};
  
  // function component
const FlipUnitContainer = ({ pre_digit, digit, shuffle }) => {	
    
    // assign digit values
    let currentDigit = digit;
    // let previousDigit = digit - 1;
    let previousDigit = pre_digit;
  
    // shuffle digits
    const digit1 = shuffle 
      ? previousDigit 
      : currentDigit;
    const digit2 = !shuffle 
      ? previousDigit 
      : currentDigit;
  
    // shuffle animations
    const animation1 = shuffle 
      ? 'fold' 
      : 'unfold';
    const animation2 = !shuffle 
      ? 'fold' 
      : 'unfold';
  
    return(
      <div className={'flipUnitContainer'}>
        <StaticCard 
          position={'upperCard'} 
          digit={currentDigit} 
          />
        <StaticCard 
          position={'lowerCard'} 
          digit={previousDigit} 
          />
        <AnimatedCard 
          digit={digit1}
          animation={animation1}
          />
        <AnimatedCard 
          digit={digit2}
          animation={animation2}
          />
      </div>
    );
};

const individualChars = (num) => {
    let numString = num.toString();
    let numLength = numString.length;
    let numArray = [];
    for (let i = 0; i < 9 - numLength; i++) {
        numArray.push(0);
    }
    for (let i = 0; i < numLength; i++) {
        numArray.push(parseInt(numString.charAt(i)));
    }

    numArray.splice(0, 0, "+");
    numArray.splice(4, 0, ".");
    numArray.splice(8, 0, ".");

    return numArray;
  }
  
  // class component
class Betscounter extends React.Component {
      
    constructor(props) {
        super(props);
        this.state = {
            d_12: '+',
            d_12_shuffle: true,
            d_11: 0,
            pre_d_11: 0,
            d_11_shuffle: true,
            d_10: 0,
            pre_d_10: 0,
            d_10_shuffle: true,
            d_9: 0,
            pre_d_9: 0,
            d_9_shuffle: true,
            d_8: '.',
            d_8_shuffle: true,
            d_7: 0,
            pre_d_7: 0,
            d_7_shuffle: true,
            d_6: 0,
            pre_d_6: 0,
            d_6_shuffle: true,
            d_5: 0,
            pre_d_5: 0,
            d_5_shuffle: true,
            d_4: '.',
            d_4_shuffle: true,
            d_3: 0,
            pre_d_3: 0,
            d_3_shuffle: true,
            d_2: 0,
            pre_d_2: 0,
            d_2_shuffle: true,
            d_1: 0,
            pre_d_1: 0,
            d_1_shuffle: true

        };
    }
    
    componentDidMount() {
        this.updateTime();

        this.timerID = setInterval(
            () => this.updateTime(),
            5000
        );

        socket.on(C.GET_BETS_COUNT, data => {
            let bets_counts = decode(data).bets_count;
            let digit_arr = individualChars(bets_counts);

            if( digit_arr[1] !== this.state.d_11) {
                const hoursShuffle = !this.state.d_11_shuffle;
                this.setState({
                    d_11: digit_arr[1],
                    pre_d_11: this.state.d_11,
                    d_11_shuffle: hoursShuffle
                });
            }
            if( digit_arr[2] !== this.state.d_10) {
                const hoursShuffle = !this.state.d_10_shuffle;
                this.setState({
                    d_10: digit_arr[2],
                    pre_d_10: this.state.d_10,
                    d_10_shuffle: hoursShuffle
                });
            }
            if( digit_arr[3] !== this.state.d_9) {
                const hoursShuffle = !this.state.d_9_shuffle;
                this.setState({
                    d_9: digit_arr[3],
                    pre_d_9: this.state.d_9,
                    d_9_shuffle: hoursShuffle
                });
            }
            if( digit_arr[5] !== this.state.d_7) {
                const hoursShuffle = !this.state.d_7_shuffle;
                this.setState({
                    d_7: digit_arr[5],
                    pre_d_7: this.state.d_7,
                    d_7_shuffle: hoursShuffle
                });
            }
            if( digit_arr[6] !== this.state.d_6) {
                const hoursShuffle = !this.state.d_6_shuffle;
                this.setState({
                    d_6: digit_arr[6],
                    pre_d_6: this.state.d_6,
                    d_6_shuffle: hoursShuffle
                });
            }
            if( digit_arr[7] !== this.state.d_5) {
                const hoursShuffle = !this.state.d_5_shuffle;
                this.setState({
                    d_5: digit_arr[7],
                    pre_d_5: this.state.d_5,
                    d_5_shuffle: hoursShuffle
                });
            }
            if( digit_arr[9] !== this.state.d_3) {
                const hoursShuffle = !this.state.d_3_shuffle;
                this.setState({
                    d_3: digit_arr[9],
                    pre_d_3: this.state.d_3,
                    d_3_shuffle: hoursShuffle
                });
            }
            if( digit_arr[10] !== this.state.d_2) {
                const hoursShuffle = !this.state.d_2_shuffle;
                this.setState({
                    d_2: digit_arr[10],
                    pre_d_2: this.state.d_2,
                    d_2_shuffle: hoursShuffle
                });
            }
            if( digit_arr[11] !== this.state.d_1) {
                const hoursShuffle = !this.state.d_1_shuffle;
                this.setState({
                    d_1: digit_arr[11],
                    pre_d_1: this.state.d_1,
                    d_1_shuffle: hoursShuffle
                });
            }
            
        });
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
        socket.off(C.GET_BETS_COUNT);
    }
    
    updateTime() {
        socket.emit(C.GET_BETS_COUNT);
    }
    
    render() {
      
      // state object destructuring
        const { 
            d_12,
            d_12_shuffle,
            d_11,
            pre_d_11,
            d_11_shuffle,
            d_10,
            pre_d_10,
            d_10_shuffle,
            d_9,
            pre_d_9,
            d_9_shuffle,
            d_8,
            d_8_shuffle,
            d_7,
            pre_d_7,
            d_7_shuffle,
            d_6,
            pre_d_6,
            d_6_shuffle,
            d_5,
            pre_d_5,
            d_5_shuffle,
            d_4,
            d_4_shuffle,
            d_3,
            pre_d_3,
            d_3_shuffle,
            d_2,
            pre_d_2,
            d_2_shuffle,
            d_1,
            pre_d_1,
            d_1_shuffle
        } = this.state;

        return(
                <div className={'flipClock'}>
                    <FlipUnitContainer 
                        digit={d_12} 
                        pre_digit = {"+"}
                        shuffle={d_12_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_11} 
                        pre_digit = {pre_d_11}
                        shuffle={d_11_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_10} 
                        pre_digit = {pre_d_10}
                        shuffle={d_10_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_9} 
                        pre_digit = {pre_d_9}
                        shuffle={d_9_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_8} 
                        pre_digit = {'.'}
                        shuffle={d_8_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_7} 
                        pre_digit = {pre_d_7}
                        shuffle={d_7_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_6} 
                        pre_digit = {pre_d_6}
                        shuffle={d_6_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_5} 
                        pre_digit = {pre_d_5}
                        shuffle={d_5_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_4} 
                        pre_digit = {'.'}
                        shuffle={d_4_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_3} 
                        pre_digit = {pre_d_3}
                        shuffle={d_3_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_2} 
                        pre_digit = {pre_d_2}
                        shuffle={d_2_shuffle} 
                    />
                    <FlipUnitContainer 
                        digit={d_1} 
                        pre_digit = {pre_d_1}
                        shuffle={d_1_shuffle} 
                    />
                </div>
        );
    }
  }

  export default Betscounter;
