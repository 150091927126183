import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import md5 from "md5";
import socket from "../../../../Socket";
import UserModal from "../../User/Stat/Modal";
import {
  forceSatoshiFormat,
  decode,
  encode,
  __,
  fixDate,
  sendNotfication,
} from "../../../../Helper";
import C from "../../../../Constant";

class Index extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      gid: null,
      show: false,
      loading: true,
      playerRow: [],
      players: [],
    };
    this.socketEventGameDetails = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      gid: this.props.game_id,
      loading: true,
      playerRow: [],
      show: this.props.show,
    });
    socket.on(C.GAME_DETAILS, (data) => this.gameInfo(decode(data)));

    let id = this.props.params.pathname;
    id = id.replace("/game/", "");
    this.getGameInfo(id);
  }

  componentWillUnmount() {
    this.socketEventGameDetails.off();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // socket.off(C.GAME_DETAILS);
  }

  getGameInfo(id) {
    socket.emit(C.GAME_DETAILS, encode({ id: id }));
  }

  gameInfo(response) {
    if (!response.info) {
      this.setState({ show: false, loading: true, effect: "zoomOut" });
      return console.log("not have info");
    }
    this.setState({ loading: false, playerRow: [], haveData: "ok" });

    let gameInfo = response.info;
    let busted;

    if (
      gameInfo.busted !== null &&
      gameInfo.busted !== undefined &&
      gameInfo.busted !== "undefined" &&
      gameInfo.busted !== ""
    ) {
      busted = gameInfo.busted;
    } else {
      busted = gameInfo.numbers;
    }

    this.setState({
      busted: busted,
      sha256: md5(gameInfo.hash),
      hash: gameInfo.hash,
      date: gameInfo.date,
      gid: gameInfo.gid,
    });

    if (response.data.length !== 0) {
      let sort = sortByWinner(response.data);
      sort = __.xor(sort);
      let uniq = [];

      sort.forEach((player, i) => {
        var find = __.findIndex(uniq, function (o) {
          return o.uid === player.uid;
        });
        if (find === -1) uniq.push(player);
      });

      this.setState({ players: uniq });
    }
  }

  render() {
    const { t } = this.props;
    let { busted, date, sha256, hash, playerRow, gid, loading } = this.state;
    let heading = "h1";
    let color = busted >= 1.9 ? "success" : "danger";

    if (__.isArray(busted)) {
      heading = "h2";
      busted = busted.map((number, i) => (
        <MakeCircle key={__.toString(i)} t={t} type={"md"} number={number} />
      ));
    }

    var players = this.state.players.map((array, i) => (
      <PlayerRow clicked={this.handleClose} array={array} key={i} />
    ));

    return (
      <div className="container">
        <Card>
          <Card.Header>Game Stats</Card.Header>
          {this._isMounted && (
            <Card.Body>
              {busted && (
                <Row className="text-darker pt-0 mb-1 user-detail">
                  <Col md={12} sm={12}>
                    <div className="review-box align-item-center p-0">
                      {heading === "h1" ? (
                        <>
                          <h1 className={"text-center my-0 py-0 text-" + color}>
                            {busted}
                            <span className={"font-"}>x</span>
                          </h1>
                          <h5 className={"text-center mt-1 pt-0 text-" + color}>
                            {t("busted")}
                          </h5>
                        </>
                      ) : (
                        <h2 className={"text-center my-0 py-0 text-" + color}>
                          {busted}
                        </h2>
                      )}
                      <Row className="my-3">
                        <Col md={6} sm={6} className="text-success">
                          Game ID: {gid}
                        </Col>
                        <Col md={6} sm={6} className="text-white text-right">
                          {t("date")}: {fixDate(date)}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} sm={12} className="text-center">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            HASH
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer mb-2 bg-cs44"
                          value={__.toString(hash)}
                          readOnly={true}
                        />
                      </div>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            MD5
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer bg-cs44"
                          value={__.toString(sha256)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <hr className={"user-border"} />
              <h4 className={"my-4 font-20"}>Players List</h4>
              <div className="table-responsive game-stats">
                <table className="table table-striped table-bordered table-condensed table-hover">
                  <thead className="table-header">
                    <tr>
                      <th>PLAYER</th>
                      <th>BET</th>
                      <th>PROFIT</th>
                    </tr>
                  </thead>
                  <tbody>{players}</tbody>
                </table>
              </div>
            </Card.Body>
          )}
          {loading && (
            <div className="text-center pb-3">
              <div className="spinner-grow text-white" role="status" />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

function MakeCircle(props) {
  const copyHash = (value) => {
    var t = document.createElement("input");
    t.value = value;
    document.body.appendChild(t);
    t.select();
    t.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(t);
    sendNotfication("Copied !", "info", "top-center");
  };

  let result = props.number;

  if (!__.isUndefined(result.num)) {
    return (
      <>
        <div
          className={
            "avatar-box align-self-center mx-1 my-1 cpt thumb-" + props.type
          }
          data-tip={props.t("click_to_copy_hash") + ":" + result.hash}
          onClick={(e) => copyHash(result.hash)}
        >
          <span className="avatar-title bg-purple rounded text">
            {result.num}
          </span>
        </div>
      </>
    );
  }

  return (
    <div
      className={"avatar-box align-self-center mx-1 my-1 thumb-" + props.type}
    >
      <span className="avatar-title bg-purple rounded text">{result}</span>
    </div>
  );
}

class PlayerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { array, key, clicked } = this.props;

    let color = "text-success";
    let profit = __.toNumber(array.profit);
    let coin = __.upperCase(array.coin);

    if (profit === 0 || profit === 0.0 || profit === "0.00000000") {
      profit = "- " + array.amount;
      color = "text-warning";
    }

    return (
      <>
        <tr className={color} key={key}>
          <td>
            <UserModal
              id={array.uid}
              clicked={clicked}
              username={array.name}
              isWinner={color}
            />
          </td>
          <td className="num-style">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-8"}
              alt={coin}
            />
            {forceSatoshiFormat(array.amount)} {coin}
          </td>
          <td className="num-style">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-8"}
              alt={coin}
            />
            {forceSatoshiFormat(profit)} {coin}
          </td>
        </tr>
      </>
    );
  }
}

function sortByWinner(input) {
  function r(c) {
    return c.profit ? -c.profit : null;
  }
  return __.sortBy(input, r);
}

function sinceConvert(unixTimestamp) {
  const milliseconds = unixTimestamp * 1000;
  const dateObject = new Date(milliseconds);
  return dateObject.toLocaleString();
}

export default Index;
