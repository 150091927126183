import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputGroup, Form, Row, Col, Button } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  Event,
  __,
  isLogged,
  funPlay,
  isValidNumber,
  wait,
  forceSatoshiFormat,
  sendNotfication,
  isMobile,
  defaultBetAmount,
  getDefaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      mobile: false,
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
      hitDisabled: true,
      standDisabled: true,
      splitDisabled: true,
      insuranceDisabled: true,
      buttonText: "Deal",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    this.props.gameCoin();

    Event.on("actions", (data) => {
      const { hit, stand, split, insurance } = data;

      this.setState({ hitDisabled: !hit });
      this.setState({ standDisabled: !stand });
      this.setState({ splitDisabled: !split });
      this.setState({ insuranceDisabled: !insurance });
    });

    engine.on("play", (data) => this.play(data));
    engine.on("busted", () => this.busted());
    engine.on("error", (data) => this.error(data));
    engine.on("stop_playing", () => this.stopManual());

    this.winAmount(this.state.amount);

    if (isMobile()) {
      this.setState({ mobile: true });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  stopManual() {
    this.setState({
      buttonText: "Deal",
      inputDisabled: false,
    });
  }

  play() {
    if (this._isMounted) {
      this.setState({
        inputDisabled: true,
      });
    }
  }

  busted() {
    if (this._isMounted) {
      wait(1200).then(() => {
        this.setState({
          inputDisabled: false,
          hitDisabled: true,
          standDisabled: true,
          splitDisabled: true,
          insuranceDisabled: true,
        });
      });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });
    }
  }

  handleHit() {
    Event.emit("hit");
  }

  handleStand() {
    Event.emit("stand");
  }

  handleDouble = () => {
    Event.emit("double", this.state.amount);
  };

  handleSplit = () => {
    Event.emit("set_split");
  };

  handleInsurance = () => {
    Event.emit("set_insurance");
  };

  handleBet(e) {
    e.preventDefault();
    let { amount } = this.state;

    // Check User
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ inputDisabled: true });

    this.placeBet();
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    storage.setKey("lam", value);
    this.setState({ [target.name]: value });
    this.winAmount(value);
  }

  winAmount = (value) => {
    this.setState({ winAmount: forceSatoshiFormat(value * 2) });
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        this.winAmount(value);
      }
    }
  };

  render() {
    let {
      bet,
      mobile,
      inputDisabled,
      hitDisabled,
      standDisabled,
      splitDisabled,
      insuranceDisabled,
    } = this.state;
    return (
      <>
        <form
          className="w-100 bj"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col md={2} xl={2} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block my-1 btn-bet"}
                disabled={inputDisabled}
                id="deal"
                type="submit"
              >
                {this.state.buttonText}
              </Button>
            </Col>
            <Col md={2} xl={2} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block mt-2 btn-hilo blackjack_button"}
                disabled={hitDisabled}
                onClick={this.handleHit}
                type="button"
              >
                Hit
              </Button>
            </Col>
            {/* <Col md={2} xl={2} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block mt-2 btn-hilo blackjack_button blackjack_button_double"}
                disabled={inputDisabled}
                onClick={this.handleDouble}
                type="button"
              >
                Double
              </Button>
            </Col> */}
            <Col md={3} xl={3} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block mt-2 btn-hilo blackjack_button"}
                disabled={standDisabled}
                onClick={this.handleStand}
                type="button"
              >
                Stand
              </Button>
            </Col>
            <Col md={2} xl={2} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block mt-2 btn-hilo blackjack_button"}
                disabled={splitDisabled}
                onClick={this.handleSplit}
                type="button"
              >
                Split
              </Button>
            </Col>
            <Col md={3} xl={3} className={"col-4"}>
              <Button
                variant={"btn btn-md btn-block mt-2 btn-hilo blackjack_button"}
                disabled={insuranceDisabled}
                onClick={this.handleInsurance}
                type="button"
              >
                Insure
              </Button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xl={6} md={7} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5} sm={12}>
              <InputGroup className="mb-1 nw">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  WIN AMOUNT
                </InputGroup.Text>
                <Form.Control
                  disabled={true}
                  type="text"
                  className="form-control text-left"
                  placeholder=".."
                  value={this.state.winAmount}
                  autoComplete={"off"}
                ></Form.Control>
              </InputGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
