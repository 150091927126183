import { SET_SLOTS } from './types';
export const gameSlots = (slots) => dispatch => {
    const fetchData = data => {
        dispatch({
            type: SET_SLOTS,
            payload: data
        })
    };
    fetchData(slots)
};
