import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  __,
  forceSatoshiFormat,
  getDefaultBetAmount,
  isValidNumber,
  isLogged,
  funPlay,
  wait,
  sendNotfication,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      amount: defaultBetAmount(this.props.coin),
      profit: 0,
      cashout: false,
      inputDisabled: false,
      bet: "manual",
      buttonText: "Bet",
      btnType: "btn-bet",
      started: false,
      formData: {
        mine: 3,
        profit: 0,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    this.props.gameCoin();
    engine.on("play", () => this.play());
    engine.on("clicked", (data) => this.clicked(data));
    engine.on("cashout", () => this.end());
    engine.on("busted", () => this.busted());
    engine.on("error", (data) => this.error(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  busted = () => {
    if (this._isMounted) {
      this.setState({
        inputDisabled: true,
        inputDisabled2: true,
      });

      if (this.state.bet === "auto") {
        wait(500).then(() => {
          this.placeAutoBet();
        });
      } else return this.end();
    }
  };

  end() {
    if (this._isMounted) {
      wait(2200).then(() => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            profit: 0,
          },
        }));
        this.setState({
          cashout: false,
          buttonText: "Bet",
          btnType: "btn-bet",
          inputDisabled: false,
          inputDisabled2: false,
          started: false,
        });
      });
    }
  }

  play() {
    if (this._isMounted) {
      this.setState({
        started: true,
        inputDisabled: true,
        inputDisabled2: true,
        buttonText: "Game is Started!",
      });
    }
  }

  clicked(data) {
    if (this._isMounted) {
      let { profit, payout, coin } = data;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          profit: profit,
        },
      }));
      this.setState({
        inputDisabled: false,
        btnType: "btn-bet-success",
        buttonText:
          profit + " " + coin + " CashOut [ " + payout.toFixed(2) + "x ]",
        cashout: true,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false, started: false });
    }
  }

  handleBet(e) {
    e.preventDefault();
    let { amount, bet, started } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ inputDisabled: true });

    this.placeBet();
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine, cashout } = this.state;
      let { profit, mine } = this.state.formData;

      engine.init = true;

      if (cashout) {
        engine.profit = profit;
        engine.cashout();
        return;
      }

      wait(100).then(() => {
        engine.amount = amount;
        engine.mine = mine;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  handleInputMines = (e, value) => {
    e.preventDefault();
    for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
      this.wrapperRef.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    if (!this.state.started) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          mine: value,
        },
      }));
    }
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled2) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  betMode = (type) => {
    if (this._isMounted) {
      this.setState({ bet: type, buttonText: "Bet" });
    }
  };

  render() {
    let { bet, started, inputDisabled, inputDisabled2 } = this.state;
    let { mine } = this.state.formData;
    return (
      <>
        <form
          className="w-100"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled2}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>
                  <img
                    src="/assets/images/mines/mine.png"
                    class="img-bomb mr-2"
                  />
                  NUMBER OF MINES
                </InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleInputMines(e, 3);
                    }}
                    variant={"btn btn-s-4 btn-md active"}
                  >
                    3
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleInputMines(e, 5);
                    }}
                    variant={"btn btn-s-4 btn-md"}
                  >
                    5
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleInputMines(e, 10);
                    }}
                    variant={"btn btn-s-4 btn-md"}
                  >
                    10
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleInputMines(e, 24);
                    }}
                    variant={"btn btn-s-4 btn-md"}
                  >
                    24
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          {bet === "manual" && (
            <Row>
              <Col md={5} sm={12} className=" m-auto">
                <div className={"form-group rev mt-2 mb-0"}>
                  <Button
                    variant={"btn btn-md btn-block mt-2 " + this.state.btnType}
                    disabled={inputDisabled}
                    type="submit"
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          {/* {bet === 'auto' &&
                        <AutoBet engine={this.state.engine} formData={this.state.formData} amount={this.state.amount} />
                    }
                    <div className="btn-group btn-group-toggle my-2 ml-1" data-toggle="buttons">
                        <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                            <input disabled={started} onClick={e => this.betMode('manual')} type="radio" /> Manual
                        </label>
                        <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                            <input disabled={started} onClick={e => this.betMode('auto')} type="radio" /> Auto
                        </label>
                    </div> */}
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string.isRequired,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, {
  gameCoin,
  setWallet,
})(Bet);
