import * as PIXI from "pixi.js";
import Loader from "../Loader";
import { Event } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

const FULL_CIRCLE = 2 * Math.PI;
const ASSETS_PATH = "/assets/images/roulette";

class Canvas {
  constructor() {
    this.app = new PIXI.Application({
      width: 400,
      height: 400,
      resolution: 1,
      backgroundAlpha: false,
      view: document.getElementById("game"),
    });
    this.started = false;
    this.centerX = this.app.screen.width / 2;
    this.container = null;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("roulette", this.app);

    this._numbers = [
      "0",
      "32",
      "15",
      "19",
      "4",
      "21",
      "2",
      "25",
      "17",
      "34",
      "6",
      "27",
      "13",
      "36",
      "11",
      "30",
      "8",
      "23",
      "10",
      "5",
      "24",
      "16",
      "33",
      "1",
      "20",
      "14",
      "31",
      "9",
      "22",
      "18",
      "29",
      "7",
      "28",
      "12",
      "35",
      "3",
      "26",
    ];

    this._slotTotal = 37;

    this._arcAngle = FULL_CIRCLE / this._slotTotal;

    this._outerEdge = {
      radius: 0,
    };
    this._outerRing = {
      outerRadius: 0,
      innerRadius: 0,
    };
    this._innerRing = {
      outerRadius: 0,
      innerRadius: 0,
    };
    this._slotText = {
      size: 0,
      radius: 0,
    };

    this._startOfTime = 0;
    this._worldAngle = 0;

    this._ball = {
      x: 0,
      y: 0,
      radius: 0,
      vertOffset: 0,
      vertRange: 0,
    };

    this._ballSettings = {
      spinStartTime: 0,
      spinFinalTime: 0,
      spinTotalTime: 0,
      spinElapsedTime: 0,
      startPosition: 0,
      finalPosition: 0,
    };

    this._worldAngle = 0;

    this._outerEdge.radius = this.app.screen.width / 2;
    this._outerRing.outerRadius = (this.app.screen.width * 0.9) / 2.5;
    this._outerRing.innerRadius = (this.app.screen.width * 0.65) / 2;
    this._innerRing.outerRadius = this._outerRing.innerRadius;
    this._innerRing.innerRadius = this._innerRing.outerRadius * 0.8;
    this._slotText.radius =
      this._outerRing.innerRadius +
      (this._outerRing.outerRadius - this._outerRing.innerRadius) * 0.33;
  }

  init() {
    this.loader.add([
      ASSETS_PATH + "/wheel_center.svg",
      ASSETS_PATH + "/ball.png",
      ASSETS_PATH + "/w.png",
    ]);

    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    if (this.app === null) return;
    this.res = res;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.container.position.set(this.centerX, this.centerY);
    this.app.stage.sortableChildren = true;
    this.app.stage.addChild(this.container);
    this.configWheel();
    this.configBall();
    this.configAnim();
    Event.on("onNumber", (aaa) => {});
  }

  configAnim() {
    var count = 0;
    this.seconds = 0;
    this.app.ticker.add((delta) => {
      count = 0.1;
      this.wheel.rotation += count * 0.05;
      this.wheelCenter.rotation -= count * 0.08;

      if (this.started) {
        this._ballSettings.spinTimeElapsed =
          Date.now() - this._ballSettings.spinStartTime;

        let f =
          this._ballSettings.spinTimeElapsed / this._ballSettings.spinTotalTime;
        f = f > 1 ? 1 : f;

        this.ball.rotation =
          (this._worldAngle + this._ballSettings.finalPosition) /
          this.easeOutSine(f);

        if (this._ballSettings.finalPosition === this.ball.rotation) {
          this.started = false;
        }

        let vertDeceleration,
          g =
            this._ballSettings.spinTimeElapsed /
            this._ballSettings.spinTotalTime;

        g = g > 1 ? 1 : g;
        if (g < 0.1) {
          vertDeceleration = 1;
        } else {
          vertDeceleration =
            (1 - g) * Math.abs(Math.sin(10 * g * g * this._worldAngle));
        }

        let x =
          this._ball.vertOffset - 40 + this._ball.vertRange * vertDeceleration;
        this.ball.arc(x, 0, this._ball.radius, 0, FULL_CIRCLE);
        this.ball.endFill();
        this.seconds += delta / 60;
      }
    });
  }

  configBall() {
    this._ball.radius =
      ((this.app.screen.width / 2 - this._outerRing.outerRadius) / 2) * 0.2;
    this._ball.vertOffset = this._innerRing.innerRadius + this._ball.radius;
    this._ball.vertRange =
      this._outerRing.outerRadius - this._innerRing.innerRadius;
    this.ball = new PIXI.Graphics();
    this.ball.beginFill(0xffffff);
    this.wheel.addChild(this.ball);
  }

  configWheel() {
    this._startOfTime = Date.now();
    let elapsedTime = Date.now() - this._startOfTime;
    this._worldAngle = -((2 * Math.PI) / 180 / 100) * elapsedTime;

    this.wheel = new PIXI.Sprite.from(ASSETS_PATH + "/w.png");
    this.wheel.anchor.set(0.5);

    for (let i = 0; i < this._slotTotal; i++) {
      let angle = this._worldAngle + i * this._arcAngle;
      var style = new PIXI.TextStyle({
        fontFamily: "sans-serif",
        fontSize: 12,
        fill: ["#ffffff"],
        align: "center",
      });

      let n = this._numbers[i] < 10 ? " " + this._numbers[i] : this._numbers[i];

      let x = 3.8;

      x = parseFloat(n) === 31 ? 4.2 : x;
      x = parseFloat(n) === 14 ? 6.4 : x;
      x = parseFloat(n) === 28 ? 2.4 : x;
      x = parseFloat(n) === 33 ? 9.4 : x;
      x = parseFloat(n) === 20 ? 9.4 : x;
      x = parseFloat(n) === 34 ? 6.2 : x;
      x = parseFloat(n) === 4 ? 8.2 : x;
      x = parseFloat(n) === 21 ? 8.1 : x;
      x = parseFloat(n) === 3 ? 3 : x;
      x = parseFloat(n) === 35 ? 3 : x;

      var basicText = new PIXI.Text(n, style);
      basicText.x =
        Math.cos(angle + this._arcAngle / x) * this._slotText.radius;
      basicText.y =
        Math.sin(angle + this._arcAngle / 4.1) * this._slotText.radius;
      basicText.rotation = angle + this._arcAngle / 2 + Math.PI / 2;
      this.wheel.addChild(basicText);
    }

    this.wheelCenter = new PIXI.Sprite.from(ASSETS_PATH + "/wheel_center.svg");
    this.wheelCenter.width = 90;
    this.wheelCenter.height = 90;
    this.wheelCenter.anchor.set(0.5);
    this.wheel.addChild(this.wheelCenter);

    this.container.addChild(this.wheel);
  }

  easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }

  findIndexOfSlot(num) {
    let slotNum = this._numbers.indexOf(`${num}`);
    if (slotNum < 0) {
      return false;
    }
    return {
      index: slotNum,
      position: this._arcAngle * (slotNum + 0.5),
    };
  }

  play(num) {
    let slot = this.findIndexOfSlot(num);

    if (slot === false) {
      this.started = false;
      return console.log("error findIndexOfSlot");
    }

    this._ballSettings.spinTotalTime = randomBetween(3, 5, false) * 1000;
    this._ballSettings.spinStartTime = Date.now();
    this._ballSettings.spinFinalTime =
      this._ballSettings.spinStartTime + this._ballSettings.spinTotalTime;
    this._ballSettings.finalPosition =
      randomBetween(2, 5) * 2 * Math.PI + slot.position;
    this._ballSettings.startPosition = this._ballSettings.finalPosition;

    this.started = true;
  }

  busted() {}

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

function randomBetween(min, max, floor = true) {
  let r = Math.random() * max + min;
  if (floor === true) {
    return Math.floor(r);
  }
  return r;
}

export default Canvas;
