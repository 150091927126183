import { SET_LIVE_SLOTS } from './types';
export const livegameSlots = (live_slots) => dispatch => {
    const fetchData = data => {
        dispatch({
            type: SET_LIVE_SLOTS,
            payload: data
        })
    };
    fetchData(live_slots)
};
