import React from 'react';
import { Table, Row, Col, Card, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { decode, Event, encode, removeTrailingZeros, removeTrailingZerosFixNum } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import { width } from '@mui/system';
import storage from "../../../Storage";

export default class Giveaway extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            deposite_data: [],
            last_week_winner: {},
            all_winners_show: false,
            winners: [],
            currentDate: '',
            coin: this.props.coin ? this.props.coin : (storage.getKey('coin') !== null) ? storage.getKey('coin') : 'USDT'
        };
    }
    componentDidMount() {
        socket.emit(C.GET_WEEKLY_DEPOSITE);
        socket.on(C.GET_WEEKLY_DEPOSITE, (data) => {
            this.setState({ deposite_data: decode(data) });
        });
        socket.emit(C.GET_LAST_WEEK_WINNER);
        socket.on(C.GET_LAST_WEEK_WINNER, (data) => {
            this.setState({ last_week_winner: decode(data).winners[0], winners: decode(data).winners, currentDate: decode(data).currentDate});
        });
    }
    componentWillMount() {
        socket.off(C.GET_WEEKLY_DEPOSITE);
        socket.off(C.GET_LAST_WEEK_WINNER);
    }
    getCurrentWeekDates() {
        const currentDate = new Date(this.state.currentDate);
        const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)

        // Calculate the start date of the current week
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - currentDay + 1); // Go back to Sunday

        // Calculate the end date of the current week
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() + (6 - currentDay + 1)); // Go forward to Saturday

        // Format dates as "dd.mm.yyyy"
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedStartDate = startDate.toLocaleDateString(undefined, options);
        const formattedEndDate = endDate.toLocaleDateString(undefined, options);

        return {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };
    }
    getHoursToEndDate() {
        // Get the current date and time
        const currentDateTime = new Date(this.state.currentDate);

        // Determine the day of the week (0 = Sunday, 6 = Saturday)
        const currentDayOfWeek = currentDateTime.getDay();

        // Calculate days until next Sunday (assuming Sunday = 0)
        const daysUntilSunday = (7 - currentDayOfWeek) % 7;
        // Set the target time (Sunday 12:00 PM)
        const targetTime = new Date(currentDateTime);
        targetTime.setHours(23, 59, 59, 0);
        targetTime.setDate(currentDateTime.getDate() + daysUntilSunday);

        // Calculate the time difference in hours
        const timeDifference = targetTime - currentDateTime;
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursDifference;
    }
    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };
    getDetail = (id) => {
        Event.emit('single_game_modal_close');
        Event.emit('close_message_modal');
        socket.emit(C.USER_INFO, encode({ id: id, coin: this.state.coin, rate: null, game: 'all', first: true }));
        Event.emit('force_modal_user');
    }  
    showAllWinners = () => {
        this.setState({all_winners_show: true});
    }
    handleHistoryClose = () => {
        this.setState({ all_winners_show: false })
    }
    render() {
        const { t } = this.props;
        const {last_week_winner} = this.state;
        const { startDate, endDate } = this.getCurrentWeekDates();
        const hoursDifference = this.getHoursToEndDate();
        let deposite_list, winners_list;
        if (Array.isArray(this.state.deposite_data)) {
            deposite_list = this.state.deposite_data.map((item, index) => (
                // <EachDeposite key={index} t={t} amount={item.amount} coin={item.coin} avatar={item.avatar} name={item.name} />
                <EachDeposite key={index} amount={item.total_amount} coin={item.coin} avatar={item.avatar} name={item.name} uid = {item.uid} t= {t}/>
            ));
        }
        if (Array.isArray(this.state.winners)) {
            winners_list = this.state.winners.map((item, index) => (
                <HistoryTable key={index} avatar={item.avatar} username={item.name} uid={item.uid} created_at={item.created_at} />
            ))
        }
        return (
            <>
                <Helmet>
                    <title>Weekly Giveaway</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href="/giveaway" />
                </Helmet>
                <div className='giveaway-wrapper'>
                    <Card className="bg-dark text-white giveaway-card-board">
                        <Card.Body>
                            <button type='button' className='btn btn-purple giveaway-btn-terms' style={{ position: 'absolute' }} onClick={() => this.handleShow()}><span className='text-white'>{t('raffle_terms')}</span></button>
                            <Modal
                                size="lg"
                                backdrop="static"
                                centered={true}
                                show={this.state.show}
                                onHide={this.handleClose}
                                aria-labelledby="affiliate-lg-modal"
                            >
                                <Modal.Header className={'font-light'}>
                                    {t('giveaway')} / {t('raffle_terms')}
                                    <button type="button" className="close" onClick={this.handleClose}>
                                        <i className={'mdi mdi-close'} />
                                    </button>
                                </Modal.Header>
                                <Modal.Body className={'modal-reader'}>
                                    <p className={'text-white font-light mb-2 font-14'}>
                                        {t('giveaway_txt_1')}
                                        <br />
                                        <span className="text-warning">{t('eligibility')}</span>
                                        <br />
                                        {t('giveaway_txt_2')}
                                        <br />
                                        <span className="text-warning">{t('prize_details')}</span>
                                        <br />
                                        {t('giveaway_txt_3')}
                                        <br />
                                        <span className="text-warning">{t('winner_selection')}</span>
                                        <br />
                                        {t('giveaway_txt_4')}
                                        <br />
                                        <span className="text-warning">{t('notification_process')}</span>
                                        <br />
                                        {t('giveaway_txt_5')}
                                        <br />
                                        <span className="text-warning">{t('prize_delivery')}</span>
                                        <br />
                                        {t('giveaway_txt_6')}
                                        <br />
                                        <span className="text-warning">{t('general_terms')}</span>
                                        <br />
                                        {t('giveaway_txt_7')}

                                    </p>
                                </Modal.Body>
                            </Modal>
                            <h1 className="text-center rewardstitle">{t('weekly_giveaway_c')}</h1>
                            <img src="/assets/images/raffle_.png" className='giveaway-imageStyle' />
                            <h5 className='text-center'>{t('deposit_any_amount_and_win')} <span className='text-warning'>{t('ledger_nano_s')}</span></h5>
                            {
                                <div type="button" className='lasttimewinner' onClick={() => last_week_winner != null && this.getDetail(last_week_winner.uid)}>
                                    <h1 className="text-center rewardstitle">{t('last_week_winner')}</h1>
                                    <img src="/assets/images/t1.gif" className='giveaway-imageStyle' />
                                    <p>@{last_week_winner != null? last_week_winner.name : "betsmixer"}</p>
                                    <i></i>
                                </div>
                            }
                            <>
                                <button className='alltimewinners' onClick={() => this.showAllWinners()}>
                                    <span className="text-warning allgiveawaywinners"><i className="mdi mdi-information mr-1"></i> {t('see_all_winners')}</span>
                                </button>
                                <Modal
                                    size={'lg'}
                                    backdrop={'static'}
                                    centered={true}
                                    scrollable={false}
                                    show={this.state.all_winners_show}
                                    onHide={this.handleHistoryClose}
                                    aria-labelledby="wallet-md-modal"
                                >
                                    <Modal.Header>
                                        {t('all_winners_b')}
                                        <button type="button" className="close p-2" onClick={(e) => this.handleHistoryClose()}>
                                            <i className={'mdi mdi-close'}/>
                                        </button>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div className="user-history" style={{ height: 500 }}>
                                        <div className="table-responsive mt-2" id={'list'} >
                                            <table className="table table-hover font-13">
                                                <thead>
                                                <tr>
                                                    <th scope="col">{t('player')}</th>
                                                    <th scope="col">{t('date')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {winners_list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </>

                        </Card.Body>
                    </Card>
                    <div className='d-flex justify-content-between giveaway-title'>
                        <h6 className='text-white participants'>{t('participants_of_raffle')}</h6>
                        <div className='text-end'>
                            <h7 className='text-warning'>{t('week')} {startDate} - {endDate}</h7>
                            <p>{t('cooldown')}: {hoursDifference} {t('hours_till_another_raffle')}</p>
                        </div>
                    </div>
                    <Card className="bg-dark text-white giveaway-last-bets-card">
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <div className="table-responsive last-bets num-style mb-0 last-bets-index img-fix">
                                        <Table id="last_bets_table" className={"main-table mb-2"}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th></th>
                                                    <th>{t('username')}</th>
                                                    <th>{t('amount_deposited')}</th>
                                                    <th className='text-center bets-status'>{t('status')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deposite_list
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
class EachDeposite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
    showPlayerProfile (uid) {
        let id = uid;
        Event.emit('single_game_modal_close');
        Event.emit('close_message_modal');
        socket.emit(C.USER_INFO, encode({ id: id, coin: this.state.coin, rate: null, game: 'all', first: true }));
        Event.emit('force_modal_user');
    }
    render() {
        // const { name, amount, avatar, coin, t } = this.props;
        const { name, amount, avatar, coin, uid, t } = this.props;
        return (
            <tr className="q-crash2">
                <td className="cpt transf-capitalize">
                    <img src="/assets/images/badges/badge-7.svg" className={'img-fluid mr-2'} alt="badge" />
                </td>
                <td>
                    <div type="button" className="d-flex align-items-center gap-2" onClick={() => this.showPlayerProfile(uid)}>
                        <div className="d-flex justify-content-center align-items-center bets-avatar-wrapper">
                            <img className="rounded thumb-xs" alt="user_avatar" src={avatar} />
                        </div>
                        <p className='mt-2 bets-giveaway-user'>{name}</p>
                    </div>
                </td>
                <td className="num-style cpt" onClick={this.gameDetail}>
                    <div className="d-flex align-items-center">
                        <img src={`/assets/images/${coin}.png`} className={'mini-coin-2'} alt="bitcoin" />
                        {/* <span>{parseFloat(amount).toFixed(2)}</span> */}
                        <span>{removeTrailingZerosFixNum(amount, 2)}</span>
                        {/* <span>{typeof(amount) == 'string' ? parseFloat(amount).toFixed(4) : amount.toFixed(4)}</span> */}
                    </div>
                </td>
                <td className="num-style">
                    <div className='text-center btn-purple btn-inraffle' style={{ borderRadius: '20px', width: '80%' }}>
                        {t('accepted')}
                    </div>
                </td>
            </tr>
        );
    }
}
class HistoryTable extends React.Component{
    constructor(props){
        super(props);
    }
    
    render() {
        let {uid, username, avatar, created_at} = this.props;
        return(
            <tr className="cpt">
                    <td>
                        <div className="historyrow historyrowfirst">
                            {username}
                        </div>
                    </td>
                    <td className="text-success-2 num-style">
                        <div className="historyrow historyrowlast">
                            {created_at}
                        </div>
                    </td>
            </tr>
        );
    }
}