import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import Engine from "./Engine";
import Loading from "../Loading";
import { isMobile, wait, Event, encode } from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      margin: "mt-3",
      padding: "p-1",
      houseEdge: null,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { engine } = this.state;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    socket.emit(C.UPDATERECENT, encode({ gid: this.props.gid, sort: "home" }));

    // Start Engine
    engine.started = true;

    engine.listen();

    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    window.removeEventListener("resize", this.handleResize);

    wait(300).then(() => {
      this._Mounted = false;
    });

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
  }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", margin: "mt-1", ovh: "ovh" });
    }
  }
  render() {
    let { col, padding, ovh } = this.state;
    const { t, gid } = this.props;
    const help = (
      <p className={"font-light text-white font-15"}>
        In this game, In each round, you will be see 5 diamonds whose payouts
        must be the same as the previous or next diamond. <br />
        Payouts can change by risk value. for example, following payouts is for high risk: <br />
        Pink 0x <br />
        Yellow 10x
        <br />
        Purple 8x
        <br />
        orange 6x
        <br />
        blue 3x
        <br />
      </p>
    );

    return (
      <>
        <Helmet>
          <title>Diamond | Crypto Casino Games</title>
          <meta
            name="description"
            content={
              "In this game, In each round, you will be see 5 diamonds whose payouts must be the same as the previous or next diamond."
            }
          />
          <meta
            name="og:title"
            content="Play Online Diamond - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Diamond - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/diamond" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              <div className="d-none">
                Crypto Diamond Game Description: {help}
              </div>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                {/* BANKROLL */}
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            diamond
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"diamond"} />%
                          </span>
                        </p>
                        <BankRoll game={"diamond"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <TopBarGames gid={gid} help={help} image="Diamond" />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body
                    className={"bg-dice-options hash_diceBG p-1"}
                    id="roll-panel"
                  >
                    <Row>
                      <Col sm={12} md={12} className={"m-auto"}>
                        <Game engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* BET */}
                <Card className="mb-0">
                  <Card.Body className={padding}>
                    <Row>
                      <Col sm={12} md={12} lg={12} className={"m-auto"}>
                        <Bet engine={this.state.engine} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* QUEUE */}
                <Card className="mb-5 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Index;
