import { secureRandomNumber } from "../../../../../Helper";

// export const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min
export const rand = (min, max) => Math.floor(secureRandomNumber() * (max - min + 1)) + min

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export function isMobile() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth < 992
}
