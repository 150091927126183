import React from "react";
import { Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  Event,
  wait,
  isValidNumber,
  getDefaultBetAmount,
  forceSatoshiFormat,
  sendNotfication,
  funPlay,
  __,
  defaultBetAmount,
  isLogged,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      buttonText: "Bet",
      amount: defaultBetAmount(this.props.coin),
      disabled: false,
      started: false,
      formData: {
        betOn: 12,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    this.props.gameCoin();
    engine.on("busted", () => this.busted());
    engine.on("error", (data) => this.error(data));
    engine.on("stop_playing", () => this.stopManual());
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    this.setState({
      started: false,
      buttonText: "Bet",
      disabled: false,
    });
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });
    }
  }

  busted = () => {
    this.setState({ disabled: false });
  };

  setBet(e) {
    if (this._isMounted) {
      e.preventDefault();
      let { amount } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      this.setState({ disabled: true });

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.placeBet();
    }
  }

  placeBet() {
    if (this._isMounted) {
      this.setState({ disabled: true });

      const { engine, amount } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.betOn = this.state.formData.betOn;
        engine.start();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  setAmount = (value) => {
    if (!this.state.disabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  handleTarget = (e, value) => {
    e.preventDefault();
    if (!this.state.started) {
      for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
        this.wrapperRef.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          betOn: value,
        },
      }));
    }
  };

  render() {
    let { disabled, amount } = this.state;
    return (
      <>
        <Form onSubmit={(e) => this.setBet(e)}>
          <Row className={"mt-1"}>
            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                  className="form-control text-left"
                  required={true}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>Bet On</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleTarget(e, 2);
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    2
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleTarget(e, 12);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    12
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleTarget(e, 24);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    24
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleTarget(e, 36);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    36
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleTarget(e, 45);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    45
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md={5} sm={12} className="m-auto">
              <div className={"form-group rev mt-2 mb-0"}>
                <Button
                  variant={"btn btn-block btn-bet btn-xl mb-2"}
                  id={"bet"}
                  disabled={disabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
