import {
  scaleTime,
  scaleLinear,
  line,
  curveBasis,
  select,
  extent,
  bisector,
} from "d3";

const defaults = {
  numberGeneratorOptions: {
    dataPoints: 10, // Number of data points to generate
    interval: 100, // Number of ms between each data point
    initialValue: 68000.12, // Initial data value
    volatility: 0.001, // Maximum percent change that can occur
  },
  ticker: {
    enable: true, // Enable or disable ticker
  },
  margins: {
    x: 5,
    y: 10,
  },

  blur: {
    radius: 12,
  },
};

// Exchange Rate Line Chart class
// @param {string} [el] ID selector for chart
// @param {arr} [data] Sample data
class ExchangeChart {
  constructor(selector = "", data = [], mouse) {
    Object.assign(this, defaults);

    this.tooltipShown = false;
    this.started = false;
    this.selector = selector;
    this.el = document.getElementById(this.selector.replace("#", ""));
    this.data = data;
    this.mouse = mouse;
    this.resizeTimer;

    if (!this.selector.length || !this.data.length || !this.el) {
      if (!this.selector.length) {
        console.log("Error: No target element specified");
      }

      if (!this.el) {
        console.log("Error: Target element not found");
      }

      if (!this.data.length) {
        console.log("Error: No data provided");
      }

      return;
    }

    this.ranges = {};

    this.buildChart();
  }

  destroy() {
    window.removeEventListener("resize", (e) => {
      this.refreshChart(e);
    });
    clearTimeout(this.resizeTimer);
    clearInterval(this.tickerInterval);
    clearTimeout(this.tickerTimeout);
  }

  update(nexPrice) {
    if (!nexPrice) return;

    nexPrice = _.reverse(nexPrice);
    clearTimeout(this.resizeTimer);
    clearInterval(this.tickerInterval);
    clearTimeout(this.tickerTimeout);

    this.renderData(this.updateData(nexPrice));
    this.startTicker();
  }

  getMouse(mouse) {
    this.mouse = mouse;
  }

  buildChart() {
    window.addEventListener("resize", (e) => {
      this.refreshChart(e);
    });
    this.setChartDimensions();
    this.setRanges();
    this.defineLine();
    this.initialiseChart();
    this.renderData(this.data);
  }

  refreshChart(e) {
    if (e) e.preventDefault();

    clearTimeout(this.resizeTimer);

    this.resizeTimer = setTimeout(() => {
      clearInterval(this.tickerInterval);
      clearTimeout(this.tickerTimeout);

      this.setChartDimensions();

      if (this.ticker.enable) {
        this.startTicker();
      }
    }, 200);
  }

  cashout(name) {
    this.name = name;
    const x = this.ranges.x(this.data[this.data.length - 1].date);
    const y = this.ranges.y(this.data[this.data.length - 1].value);

    // Create text group
    this.wrapper.append("g").attr("class", "cashout");

    // Create inspector text
    this.svg.select(".cashout").append("text").text(name).attr("x", 12);

    setTimeout(() => {
      this.svg.select(".cashout").remove("text");
    }, 5000);
  }

  setChartDimensions() {
    this.dimensions = {
      width: this.el.clientWidth - 100,
      height: this.el.clientHeight,
    };

    if (this.svg) {
      this.svg
        .attr("width", this.dimensions.width)
        .attr("height", this.dimensions.height);

      this.wrapper
        .attr("width", this.dimensions.width)
        .attr("height", this.dimensions.height);
    }
  }

  // Set ranges based on SVG canvas dimensions
  setRanges() {
    this.ranges.x = scaleTime().range([
      0,
      this.dimensions.width - this.margins.x,
    ]);

    this.ranges.y = scaleLinear().range([
      this.dimensions.height - 2 * this.margins.y,
      0,
    ]);
  }

  // Define line function
  defineLine() {
    this.line = line()
      .curve(curveBasis)
      .x((data) => {
        return this.ranges.x(data.date);
      })
      .y((data) => {
        return this.ranges.y(data.value);
      });
  }

  // Set up SVG canvas
  initialiseChart() {
    this.svg = select(this.selector)
      .append("svg")
      .attr("width", this.dimensions.width)
      .attr("height", this.dimensions.height);

    this.wrapper = this.svg
      .append("g")
      .attr("width", this.dimensions.width - this.margins.x)
      .attr("height", this.dimensions.height - 2 * this.margins.y)
      .attr("class", "wrapper")
      .attr("transform", `translate(0, ${this.margins.y})`);

    this.buildGuide();
    this.buildLine();
    this.buildEndCircle();
    this.buildTooltip();
  }

  buildGuide() {
    // Create inspector guide
    this.wrapper.append("line").attr("class", "guide");
  }

  buildLine() {
    // Create chart line group
    this.wrapper.append("g").attr("class", "data");

    // Create chart line
    this.svg.select(".data").append("path").attr("class", "line");
  }

  buildEndCircle() {
    // Create circle group
    this.wrapper.append("g").attr("class", "circle");

    // Create inspector circle shadow
    this.svg
      .select(".circle")
      .append("circle")
      .attr("class", "circle-shadow")
      .attr("r", `${this.blur.radius}px`);

    // Create inspector circle
    this.svg.select(".circle").append("circle").attr("class", "circle");
  }

  buildTooltip() {
    var x = scaleTime()
        .domain(
          extent(this.data, function (d) {
            return d.time;
          })
        )
        .range([0, this.dimensions.width]),
      y = scaleLinear().domain([0, 120]).range([this.dimensions.height, 0]);

    var bisect = bisector(function (d) {
      return d.value;
    }).left;

    var mouseG = this.svg.append("g").attr("class", "mouse-over-effects");

    var mouseline = mouseG
      .append("path")
      .attr("class", "mouse-line")
      .style("stroke", "black")
      .style("stroke-width", "1px")
      .style("opacity", "0");

    var margin = { top: 2, right: 2, bottom: 2, left: 2 };
    var mouseCoords = [];

    this.tooltip = select("#tooltips");

    var self = this;

    var svgElement = document.getElementById("chart");
    var width =
      svgElement.getBoundingClientRect().width - margin.left - margin.right;
    var height =
      svgElement.getBoundingClientRect().height - margin.top - margin.bottom;

    var mouseEventsContainer = mouseG
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseout", function () {
        select(".mouse-line").style("opacity", "0");
        self.tooltip.style("opacity", "0");
        self.tooltipShown = false;
      })
      .on("mouseover", function () {
        select(".mouse-line").style("opacity", "1");
        self.tooltip.style("opacity", "1");
        self.tooltipShown = true;
      })
      .on("mousemove", function () {
        mouseCoords = self.mouse;
        var item = self.getValues(1);
        item = parseFloat(item);
        // var item = self.data[bisect(self.data, x.invert(mouseCoords[0]))];

        if (_.isUndefined(item)) return;

        try {
          self.tooltip
            .style("left", mouseCoords[0] - 350 + "px")
            .style("top", mouseCoords[1] - 170 + "px")
            .html("Price: " + item.toFixed(4));
          select(".mouse-line")
            .attr("d", function () {
              var d = "M" + mouseCoords[0] + "," + height;
              d += " " + mouseCoords[0] + "," + 0;
              return d;
            })
            .attr(
              "transform",
              "translate(" + margin.left + "," + margin.top + ")"
            );
        } catch (e) {}
      });
  }

  getValues(i) {
    let data = this.data[this.data.length - i].value;
    return ExchangeChart.roundNumber(data, 2, true);
  }

  // Renders all chart components and populates stats
  // @param {arr} [data] Sample data
  renderData(data) {
    this.data = data;

    // Set domains based on sample data
    this.ranges.x.domain(
      extent(data, (data) => {
        return data.date;
      })
    );

    this.ranges.y.domain(
      extent(data, (data) => {
        return data.value;
      })
    );

    this.renderGuide(data);
    this.renderLine(data);
    this.renderCircle(data);
    this.populateStats(data);
  }

  // Renders chart line
  renderLine() {
    this.svg
      .select(".line")
      .data([this.data])
      .interrupt()
      .transition()
      .duration(this.numberGeneratorOptions.interval * 2.5)
      .attr("d", this.line);
  }

  // Renders circle on latest value
  renderCircle() {
    const x = this.ranges.x(this.data[this.data.length - 1].date);
    const y = this.ranges.y(this.data[this.data.length - 1].value);

    this.point = this.svg
      .select(".circle")
      .interrupt()
      .transition()
      .duration(this.numberGeneratorOptions.interval * 2.5)
      .attr("transform", `translate(${x}, ${y})`);

    // this.transition.easing

    this.svg.select(".cashout").attr("transform", `translate(${x - 60}, ${y})`);
  }

  // Renders horizontal guide for latest value
  renderGuide() {
    var color = "red;";

    const current = this.ranges.y(this.data[this.data.length - 1].value);
    const prev = this.ranges.y(this.data[this.data.length - 2].value);

    if (current >= prev) color = "green;";

    const y = this.ranges.y(this.data[this.data.length - 1].value);

    this.svg
      .select(".guide")
      .interrupt()
      .transition()
      .duration(this.numberGeneratorOptions.interval * 2.5)
      .attr("x1", 0)
      .attr("y1", y)
      .attr("x2", this.dimensions.width * 2)
      .attr("y2", y)
      .attr("style", `stroke: ${color};`);
  }

  // Populates stats based on chart data
  populateStats() {
    const rate = document.getElementsByClassName("rate");
    const value = this.data[this.data.length - 1].value;

    if (!_.isNull(rate) && !_.isUndefined(rate[0]))
      rate[0].innerHTML = ExchangeChart.roundNumber(value, 4, true);
  }

  startTicker() {
    // this.tickerTimeout = setTimeout(() => {
    this.tickerInterval = setInterval(() => {
      this.tickData(this.data);
    }, this.numberGeneratorOptions.interval);
    // }, 1000);
  }

  // Progresses data and updates chart
  tickData() {
    this.data.shift();

    this.data.push({
      date: ExchangeChart.progressDate(this.data, this.numberGeneratorOptions),
      value: ExchangeChart.progressValue(
        this.data,
        this.numberGeneratorOptions
      ),
    });

    this.renderData(this.data);
  }

  updateData(val) {
    this.data = _.dropRight(this.data, this.data ? this.data.length - 1 : 0);
    // for (var i = 0; i < val.length; i++) {
    //   this.data.push({
    //     date: new Date(Date.now()),
    //     value: val[i],
    //   });
    // }

    var add = {
      dataPoints: 150, // Number of data points to generate
      interval: 100, // Number of ms between each data point
			initialValue: _.last(val), // Initial data value
      volatility: 0.001, // Maximum percent change that can occur
    };
    var result = ExchangeChart.generateSampleData(add);
    return result;
  }

  static generateSampleData(options) {
    const data = [];
    let n = options.dataPoints;

    // Set first data point
    data.push({
      date: new Date(Date.now()),
      value: options.initialValue,
    });

    n--;

    // Loop and create remaining data points
    while (n > 0) {
      data.push({
        date: ExchangeChart.progressDate(data, options),
        value: ExchangeChart.progressValue(data, options),
      });

      n--;
    }

    return data;
  }

  // Calculates next date in data set
  // @param {arr} [data] Sample data
  // @param {obj} [options] Generator options
  // @returns {obj} Next date
  static progressDate(data, options) {
    return new Date(
      new Date(data[data.length - 1].date.getTime() + options.interval)
    );
  }

  // Calculates next value in data set
  // @param {arr} [data] Sample data
  // @param {obj} [options] Generator options
  // @returns {float} Next value
  static progressValue(data, options) {
    const volatility = options.volatility / 100;

    const random = ExchangeChart.getRandomNumber(0, 1, 3);
    let percentChange = 2 * volatility * random;

    if (percentChange > volatility) {
      percentChange -= 2 * volatility;
    }

    const changeValue = data[data.length - 1].value * percentChange;
    return data[data.length - 1].value + changeValue;
  }

  // Generates a random number
  // @param {int} [min] Minimum number
  // @param {int} [max] Maximum number
  // @param {int} [decimalPlaces] Number of decimal places
  // @returns {float} Random float
  static getRandomNumber(min, max, decimalPlaces) {
    return parseFloat(
      (Math.random() * (max - min) + min).toFixed(decimalPlaces)
    );
  }

  // Rounds a number to specified decimal places
  // @param {float} [n] Number to be rounded
  // @param {int} [decimalPlaces] Number of decimal places
  // @param {bool} [pad] Pad output string with trailing zeroes if required
  // @returns {string} Rounded number string
  static roundNumber(n, decimalPlaces, pad) {
    let rounded = (
      Math.round(n * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    ).toString();

    if (pad) {
      let integerLength =
        rounded.indexOf(".") > -1 ? rounded.indexOf(".") : rounded.length;

      if (rounded.indexOf("-") > -1) {
        integerLength--;
      }

      if (rounded.indexOf(".") === -1) {
        rounded = `${rounded}.`;
      }

      const targetLength = decimalPlaces + integerLength + 1;

      if (rounded.length < targetLength) {
        for (let i = rounded.length; i < targetLength; i++) {
          rounded = `${rounded}0`;
        }
      }
    }

    return rounded;
  }
}

export default ExchangeChart;
