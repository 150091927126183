import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { searchBar } from "../../../../actions/searchGame";
import { useTranslation } from "react-i18next";
import { Event, isMobile } from "../../../../Helper";

function Searchbar({ searchBar }) {
  const { t } = useTranslation();
  return <Searchbarcom t={t} searchBar={searchBar} />;
}

class Searchbarcom extends React.Component {
  componentDidMount() {
    Event.on("clear_search", () => {
      this.props.searchBar();
    });

    Event.on("back_search", (key) => {
      this.props.searchBar(key);
    });
  }

  searchChange = (e) => {
    this.props.searchBar(e.target.value);
    Event.emit("search_key", e.target.value);
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Perform the action you want when Enter key is pressed
      // For example, initiate the search using the searchChange function
      this.props.searchBar(e.target.value);
      Event.emit("search_key", e.target.value);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div id="search_box" className="position-relative">
        <InputGroup>
          <Form.Control
            id="search_input"
            placeholder={t("search_for_game")}
            onChange={this.searchChange}
            onKeyDown={this.handleKeyPress}
          />
          <FaSearch className="search_icon position-absolute" />
        </InputGroup>
      </div>
    );
  }
}

// export default connect(null, { searchBar })(Searchbar);
export default connect(null, { searchBar })(Searchbar);
