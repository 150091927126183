import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Popover,
  InputGroup,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons/Range";
import {
  __,
  wait,
  isValidNumber,
  forceSatoshiFormat,
  sendNotfication,
  Event,
  playAudio,
  isLogged,
  funPlay,
  getDefaultBetAmount,
  defaultBetAmount,
  getRand,
  isMobile,
} from "../../../Helper";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";

class Bet extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      buttonText: "Bet",
      inputDisabled: false,
      risk: "low",
      numbers: [],
      multi: [],
      uniqueMulti: [],
      payout: [],
      lock: false,
      holding: false,
      amount: defaultBetAmount(this.props.coin),
      coin: storage.getKey("coin"),
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRisk = this.handleRisk.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.clearTable = this.clearTable.bind(this);
    this.autoPick = this.autoPick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    //Set Coin from Redux
    this.props.gameCoin();

    engine.on("play", () => this.play());
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  error(data) {
    if (this._isMounted) {
      if (data.message) sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });
    }
  }

  play() {
    if (this._isMounted) {
      this.setState({ inputDisabled: true });
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { numbers } = data;
      this.setState({ inputDisabled: false });

      numbers.forEach((number, i) => {
        if (document.getElementById("c_" + number.num) !== null) {
          wait(i * 100).then(() => {
            document
              .getElementById("c_" + number.num)
              .classList.remove("active");
          });

          if (this.state.numbers.includes(number.num)) {
            wait(i * 100).then(() => {
              document
                .getElementById("c_" + number.num)
                .classList.add("bg-danger");
              document
                .getElementById("c_" + number.num)
                .classList.add("text-white");
              playAudio("dice3.mp3");
            });
          } else {
            wait(i * 100).then(() => {
              document
                .getElementById("c_" + number.num)
                .classList.add("bg-success");
              document
                .getElementById("c_" + number.num)
                .classList.add("text-white");
            });
          }
        }
      });

      wait(4000).then(() => {
        this.clearTable();
      });
    }
  }

  handleRisk = (e, value) => {
    e.preventDefault();
    for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
      this.wrapperRef.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    if (!this.state.started) {
      this.setState({ risk: value });
    }
  };

  handleInputChange(event) {
    var target = event.target;
    var value = target.value;
    var name = target.name;
    this.setState({ [name]: value });
  }

  isCorrectBet(amount) {
    if (amount === 0) return false;

    if (!isValidNumber(amount)) return false;

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    } else this.setState({ amount: forceSatoshiFormat(amount) });

    var credit = storage.getKey("credit");

    if (!storage.getKey("playing") === false) {
      if (amount > credit || credit === 0) {
        return false;
      }
    }
    return true;
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount, numbers } = this.state;
      wait(100).then(() => {
        engine.init = true;
        engine.coin = this.props.coin;
        engine.numbers = numbers;
        engine.amount = forceSatoshiFormat(amount) * numbers.length;
        engine.start();
      });
    }
  }

  handleBet(e) {
    e.preventDefault();

    let { amount } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (this.state.numbers.length < 3) {
      return sendNotfication(
        "Please select at least 3 number",
        "info",
        "top-center"
      );
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ inputDisabled: true });
    this.placeBet();
  }

  clearTable() {
    if (this._isMounted) {
      this.setState({
        numbers: [],
        uniqueMulti: [],
        multi: [],
        payout: [],
      });

      for (var i = 0; i < 41; i++) {
        if (document.getElementById("c_" + i) !== null) {
          document.getElementById("c_" + i).classList.remove("bg-success");
          document.getElementById("c_" + i).classList.remove("text-white");
          document.getElementById("c_" + i).classList.remove("bg-danger");
        }
      }
    }
  }

  selectNum(num, auto = false) {
    if (this._isMounted) {
      if (!__.includes(this.state.numbers, num)) {
        if (this.state.numbers.length <= 10) {
          this.setState((state) => ({ numbers: [num, ...state.numbers] }));
          this.addMulti(num, auto);
          this.addPayout(num, auto);
        }
      } else {
        const removeN = (num) => {
          return __.without(this.state.numbers, num);
        };
        this.setState(() => ({ numbers: removeN(num) }));
        this.removeMulti(num);
        this.removePayout(num);
      }
    }
  }

  addMulti(num, isAuto) {
    const { risk } = this.state;
    var multis;

    if (risk === "low") {
      multis = [0, 0, 0, 1.4, 2.25, 4.5, 8.0, 17.0, 50.0, 80.0, 100.0];
    } else if (risk === "medium") {
      multis = [0, 0, 0, 1.6, 2.0, 4.0, 7.0, 26.0, 100.0, 500.0, 1000.0];
    } else if (risk === "high") {
      multis = [0, 0, 0, 0, 3.5, 8.0, 13.0, 63.0, 500.0, 800.0, 1000.0];
    }

    var n = this.state.numbers.length;
    if (isAuto) n = n - 1;

    this.state.multi.push(this.makeMulti(multis[n].toFixed(2) + "x"));
  }

  addPayout(num, isAuto) {
    var n = this.state.numbers.length;
    if (isAuto) n = n - 1;
    this.state.payout.push(this.makePayout(n));
  }

  removeMulti(num) {
    this.state.multi = __.take(this.state.multi, this.state.multi.length - 1);
  }

  removePayout(num) {
    this.state.payout = __.take(
      this.state.payout,
      this.state.payout.length - 1
    );
  }

  autoPick() {
    if (this._isMounted) {
      this.clearTable();

      var all = [];

      for (var i = 0; i < 40; i++) {
        all.push(i);
      }

      for (var i = 0; i < 10; i++) {
        wait(i * 135).then(() => {
          let rand = all[getRand(all.length)];

          __.remove(all, function (n) {
            return n === rand;
          });

          this.selectNum(rand, true);
        });
      }
    }
  }

  makePayout = (num) => {
    Event.on("coin_changed", (name) => {
      return this.clearTable();
    });

    var multis = [0, 0, 0, 1.4, 2.25, 4.5, 8.0, 17.0, 50, 80, 100];
    let choose = multis[num];
    var calc = forceSatoshiFormat(choose);
    const popover = (
      <Popover id="popover-basic">
        <Popover.Body className="p-0 bg-cs2">
          <div className="form-group mb-1">
            <div className="input-group">
              <div className="input-group-append">
                <span className="input-group-text">
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  Win Amount
                </span>
              </div>
              <input
                disabled={true}
                type="number"
                className="form-control text-left"
                value={calc}
              />
            </div>
          </div>
          <div className="form-group mb-0">
            <div className="input-group">
              <div className="input-group-append">
                <span className="input-group-text">Payout</span>
              </div>
              <input
                disabled={true}
                type="number"
                className="form-control text-left"
                value={num}
              />
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );

    return (
      <>
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
          <div className="keno-x">
            <div className="text-center bg-cs5 text-white p-1 btn-sm">
              {num}x
            </div>
            <div className="keno-payout text-center bg-cs5 text-white p-1 btn-sm">
              {num} Hits
            </div>
          </div>
        </OverlayTrigger>
      </>
    );
  };

  makeMulti = (num) => {
    var btn = "btn-sm";
    if (isMobile()) btn = "btn-xs";
    let button = React.createElement(
      "div",
      {
        id: "m_" + num,
        className: `text-center bg-cs2 text-white p-1 ${btn} `,
      },
      num
    );
    return React.createElement("div", { className: "keno-multi" }, button);
  };

  makeNumber = (num) => {
    let disabled = false;

    let active = false;

    this.state.numbers.forEach((item, i) => {
      if (item === num) {
        active = "active";
      }
    });

    let button = React.createElement(
      "button",
      {
        type: "button",
        id: "c_" + num,
        disabled: disabled,
        className: "btn btn-soft-secondary btn-xl btn-block " + active,
        onClick: () => this.selectNum(num),
      },
      num
    );
    return React.createElement("div", { className: "keno-numbers" }, button);
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  render() {
    const generateNumber = () => {
      let make = [];
      for (var i = 1; i < 41; i++) {
        make[i] = this.makeNumber(i);
      }
      return make;
    };

    let { inputDisabled, risk, buttonText, amount, multi, payout, numbers } =
      this.state;

    multi.length = Math.min(multi.length, 10);
    payout.length = Math.min(payout.length, 10);
    numbers.length = Math.min(numbers.length, 10);

    return (
      <>
        <form
          className="w-100"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <div className="grid-buttons">{generateNumber()}</div>
          <div className="keno-bottom">
            <div className="mb-0 mt-3 text-center font-14 text-white">
              {this.state.numbers.length === 0 ? (
                <>Select 1 - 10 numbers to play</>
              ) : (
                <>[ {this.state.numbers.length} ]</>
              )}
            </div>
            <div className="keno-addition h-">{multi}</div>
            <div className="keno-addition h-">{payout}</div>
          </div>
          <Row className="mt-2">
            <Col md={6} className={"col-6 px-2"}>
              <Button
                variant={"btn btn-outline-light btn-block btn-xs"}
                disabled={inputDisabled}
                onClick={this.autoPick}
              >
                Auto Pick
              </Button>
            </Col>
            <Col md={6} className={"col-6 px-2"}>
              <Button
                variant={"btn btn-outline-light btn-block btn-xs"}
                disabled={inputDisabled}
                onClick={this.clearTable}
              >
                Clear Table
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg={4} sm={12} className={"m-auto"}>
              <div className={"form-group rev mb-1"}>
                <Button
                  variant={"btn btn-sm btn-block mt-2 keno-bet btn-bet"}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {buttonText}
                </Button>
              </div>
            </Col>

            <Col lg={4} sm={12} className={"mt-3"}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  type="number"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  max="900000000"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  required={true}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col lg={4} sm={12} className={"mt-3"}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>Risk</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "low");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Low
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "medium");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "high");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
