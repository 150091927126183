import React, { Component } from "react";
import ManualBet from "./includes/ManualBet";

class Bet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bet: "manual",
    };
  }

  betMode = (type) => {
    this.setState({ bet: "manual" });
  };

  render() {
    return <ManualBet engine={this.props.engine} mobile={this.props.mobile} />;
  }
}

export default Bet;
