import { combineReducers } from 'redux';
import socketReducer from './socketReducer';
import searchReducer from './searchReducer';
import ipReducer from './ipReducer.js';

export default combineReducers({
    items: socketReducer,
    search: searchReducer,
    ip: ipReducer,
});
