import React from "react";
import storage from "../../../Storage";
import { wait, isMobile, playAudio, Event } from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      token: storage.getKey("token"),
      started: false,
      canvas: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;
    let canvas = new Canvas(this.state.engine);
    this.setState({ canvas: canvas });
    canvas.init();

    Event.on("change_risk", (value) => {
      if (!canvas.started) canvas.changeRisk(value);
    });

    //Check player in game
    engine.on("ready", () => {
      engine.ws.emit(C.CHECK_GOAL);
    });

    engine.on("play", () => this.play());
    engine.on("clicked", (data) => this.clicked(data));
    engine.on("cashout", (data) => this.endGame(data));
    engine.on("busted", (data) => this.endGame(data));

    if (isMobile()) {
      let canvas = document.getElementById("game");
      canvas.style.width = "100%";
      canvas.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  play = () => {
    if (this._isMounted) {
      this.state.canvas.play();
      this.setState({ started: true });
    }
  };

  clicked(data) {
    if (this._isMounted) {
      let { id } = data;
      this.state.canvas.nextRound();
      playAudio("win.mp3");
    }
  }

  endGame(data) {
    if (this._isMounted) {
      let { mines } = data;

      this.state.canvas.busted(mines);

      this.setState({ started: false });

      wait(2200).then(() => {
        this.state.canvas.reset();
      });
    }
  }

  render() {
    return (
      <div className="goal animated fadeIn">
        <div className="text-center" id="cc">
          <canvas id="game" />
        </div>
      </div>
    );
  }
}

export default Game;
