const coins = [
    {
        id: 6,
        name: "Dogecoin",
        preffix: "DOGE",
        image: "doge.png",
        min: '5.00000',
        active: true,
        test: false,
        network: 'BSC',
        locked: {
            active: false,
            name: "Bonus: "
        },
    },
    {
        id: 5,
        name: "Bitcoin Cash",
        preffix: "BCH",
        image: "bch.png",
        min: "0.00050000",
        active: true,
        test: false,
        network: 'BCH',
        locked: {
            active: false,
            name: "Bonus: "
        },
    },
    {
        id: 4,
        name: "Litcoin",
        preffix: "LTC",
        image: "ltc.png",
        min: "0.00200000",
        active: true,
        test: false,
        network: 'LTC',
        locked: {
            active: false,
            name: "Bonus: "
        },
    },
    {
        id: 3,
        name: "Bitcoin",
        preffix: "BTC",
        image: "btc.png",
        min: "0.00008000",
        active: true,
        test: false,
        network: 'BTC',
        locked: {
            active: false,
            name: "Bonus: "
        },
    },
    {
        id: 2,
        name: "Tether",
        preffix: "USDT",
        image: "usdt.png",
        min: "50.00000000",
        active: true,
        test: false,
        network: 'TRC-20',
        locked: {
            active: true,
            name: "Bonus: "
        },
    },
    {
        id: 1,
        name: "Ethereum",
        preffix: "ETH",
        image: "eth.png",
        min: "0.00850000",
        active: true,
        test: false,
        network: 'ETH',
        locked: {
            active: false,
            name: "Bonus: "
        },
    },
];

export default coins;
