import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { gameCoin } from "../../../../../actions/gameCoin";
import {
  __,
  Event,
  forceSatoshiFormat,
  isValidNumber,
  getDefaultBetAmount,
} from "../../../../../Helper";
import ScriptController from "./Controller";

class Content extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      content: "Click on a script to manage",
      coin: "usdt",
      payout: 2,
      amount: 1,
      started: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.props.gameCoin();
      Event.on("script", (data) => {
        // TODO
        // this.setState({ content: this.make(data) });
        this.setState({ content: null });
        this.setState({ name: data.name });
      });

      this.state.engine.on("waiting_crash", () => this.checkWaitingGame());
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createEngine(content) {
    var script = content.replace(/engine/g, "this.state.engine");

    function f() {
      return script;
    }
    return f;
  }

  make(data) {
    // TODO
    // var logicFn = this.createEngine(data.content);
    // var _engineController = new ScriptController(logicFn, this.state.engine);
    // return _engineController;
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.value;
      let name = target.name;
      if (name === "amount") {
        if (!isValidNumber(value)) return;
        value = __.clamp(getDefaultBetAmount(this.state.coin), 0.001, value);
        // value = forceSatoshiFormat(value);
      }
      if (name === "payout") {
        if (value > 999) return;
      }
      this.setState({ [name]: value });
    }
  }

  checkWaitingGame() {
    if (this._isMounted) {
      if (this.state.started) {
        this.start();
      }
    }
  }

  start() {
    if (this._isMounted) {
      this.setState({ started: true, inputDisabled: true });

      const { amount, payout } = this.state;

      this.state.engine.coin = this.props.coin;
      this.state.engine.amount = amount;
      this.state.engine.payout = parseInt(payout * 100);
      this.state.engine.play();
    }
  }

  stop() {
    if (this._isMounted) {
      this.setState({ started: false, inputDisabled: false });
    }
  }

  createMarkup(str) {
    return { __html: str };
  }

  render() {
    return (
      <div>
        {/*
        <div
          dangerouslySetInnerHTML={this.createMarkup(
            this.state.content
          )}
        />
       */}

       {this.state.content}

        {this.state.name === "Repeator" && (
          <>
            <div className={"form-group mb-1 bet-input "}>
              <div className="input-group">
                <div className="input-group-append">
                  <span className="input-group-text">Amount</span>
                </div>
                <input
                  disabled={this.state.inputDisabled}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder="1"
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className={"form-group mb-1 bet-input payout"}>
              <div className="input-group">
                <div className="input-group-append">
                  <span className="input-group-text">Payout</span>
                </div>
                <input
                  disabled={this.state.inputDisabled}
                  type="text"
                  className="form-control text-left"
                  id="payout"
                  name="payout"
                  placeholder="1"
                  value={this.state.payout}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="">
              <div className={"text-center form-group rev mt-1 mb-0 "}>
                <Button
                  onClick={this.start}
                  variant={"btn btn- btn-success mr-1"}
                  disabled={this.state.inputDisabled}
                  type="submit"
                >
                  {this.state.started && (
                    <i className="spinner-border spinner-border-sm mr-1" />
                  )}
                  Place Bet
                </Button>
                <Button
                  onClick={this.stop}
                  disabled={!this.state.inputDisabled}
                  variant={"btn btn- btn-danger"}
                  type="submit"
                >
                  Stop
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

Content.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, {
  gameCoin,
})(Content);
