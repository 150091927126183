import React from "react";
import gsap from "gsap";

class Loader extends React.Component {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        gsap
        .timeline({ cssName: "logo", paused: false, repeat: -1 })
        .to("#wave1Y", {
            cssSelector: "#wave1Y",
            y: -220,
            ease: "sine.inOut",
            duration: 1.5,
        })
        .to(
            "#wave2Y",
            { cssSelector: "#wave2Y", y: -220, ease: "sine.inOut", duration: 4.5 },
            "<"
        )
        .to(
            "#wave1X",
            {
            cssSelector: "#wave1X",
            x: -429,
            ease: "none",
            duration: 0.6,
            repeat: 3,
            },
            "<"
        )
        .to(
            "#wave2X",
            {
            cssSelector: "#wave2X",
            x: 429,
            ease: "none",
            duration: 0.5,
            repeat: 3,
            },
            "<"
        );

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return(
            <div id="loader_wrapper" className={this.props.loading_effect}>
                <div className="container_animation">
                    <svg
                    version="1.1"
                    id="betsmixer"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="1388px"
                    height="320px"
                    viewBox="0 0 1388 320"
                    enableBackground="new 0 0 1388 320"
                    xmlSpace="preserve"
                    >
                    <g>
                        <g>
                        <g>
                            <polygon
                            fill="#FFC41D"
                            points="1148.89,241.25 1227.29,241.25 1231.69,211.85 1190.29,211.85 1193.79,189.05 1231.69,189.05 
                                                                1236.49,159.95 1198.29,159.95 1201.79,137.45 1242.69,137.45 1248.29,105.75 1169.39,105.75 			"
                            />
                            <polygon
                            fill="#FFC41D"
                            points="880.59,105.75 847.89,180.65 835.59,105.75 795.99,105.75 754.19,241.25 788.59,241.25 
                                                                813.19,155.85 829.99,241.25 850.39,241.25 890.09,157.15 890.49,241.25 922.69,241.25 920.09,105.75 			"
                            />
                            <polygon
                            fill="#FFC41D"
                            points="961.49,105.75 940.59,241.25 975.79,241.25 996.99,105.75 			"
                            />
                            <polygon
                            fill="#FFC41D"
                            points="1148.89,105.75 1104.99,105.75 1077.09,139.65 1056.79,105.75 1017.89,105.75 1054.09,167.65 
                                                                993.69,241.25 1036.59,241.25 1072.09,198.45 1097.09,241.25 1137.99,241.25 1095.39,170.25 			"
                            />
                            <path
                            fill="#FFC41D"
                            d="M1367.79,151.55c3.8-31.7-19.2-45.8-42.9-45.8c-23.7,0-50.6,0-50.6,0l-20.9,135.6h34.5l8.4-52l27.8,52
                                                                h39.8l-34-53.5C1329.99,187.75,1363.99,183.25,1367.79,151.55z M1334.59,150.75c-2.3,13.8-12.3,17.9-24.3,17.9
                                                                c-4.7,0-8.2,0-10.7,0l6.1-37.5c0,0,5.4,0,13.3,0C1326.89,131.15,1336.89,136.95,1334.59,150.75z"
                            />
                        </g>
                        <g>
                            <polygon
                            fill="#FFE9B6"
                            points="445.39,241.25 523.79,241.25 528.19,211.85 486.79,211.85 490.29,189.05 528.19,189.05 
                                                                532.99,159.95 494.79,159.95 498.29,137.45 539.19,137.45 544.79,105.75 465.89,105.75 			"
                            />
                            <polygon
                            fill="#FFE9B6"
                            points="654.79,105.75 558.79,105.75 553.49,137.45 583.89,137.45 568.49,241.25 604.09,241.25 
                                                                619.19,137.45 650.19,137.45 			"
                            />
                            <path
                            fill="#FFE9B6"
                            d="M722.99,160.95c-14.2-7.4-23-13.5-19.1-23.5c3.5-8.9,19.9-7,35.7,6.1l14.6-25.5c0,0-12-14.6-41.1-14.6
                                                                s-44.4,15.8-46.7,38.8c-1.8,17.6,7.9,29.9,26.6,39.8c19.4,10.3,21.8,18.8,16.1,26.4c-7.1,9.5-25.3,7.3-43.1-12l-19,25.7
                                                                c0,0,14.9,21.7,49.7,21.7c34.7,0,48.9-18.3,51.5-41.1C750.69,181.75,741.29,170.55,722.99,160.95z"
                            />
                            <path
                            fill="#FFE9B6"
                            d="M404.99,105.95l3.4-22.9l-26.6-2.8l-3.7,25.4c-18.7,0-29.7,0-29.7,0l-21.7,135.6c0,0,15,0,31.5,0
                                                                l-2.4,16.2l26,5.4l3.2-21.6c27.3-0.1,43.4-10.7,47-34.1c3.6-23.5-8.5-33.7-24.6-36c14.7-2.1,24.3-13.3,26.1-28.3
                                                                C435.39,127.85,430.79,108.55,404.99,105.95z M398.09,200.45c-1.2,7-4.2,11.1-9,13.3c-3.2,1.4-7.2,2-11.8,2
                                                                c-10.1,0-12.1,0-12.1,0l4.7-31.8c0,0,1.2,0,13,0c4.3,0,7.8,0.7,10.3,2.1C397.59,188.45,399.29,193.25,398.09,200.45z
                                                                    M401.09,146.05c-0.4,3.3-1.5,5.8-3.1,7.8c-2.9,3.5-7.3,4.8-12.3,4.8c-7.7,0-12.1,0-12.1,0l3.9-26.1c0,0,3.2,0,11.6,0
                                                                c5.4,0,9.4,1.8,11.2,5.6C401.19,140.15,401.49,142.75,401.09,146.05z"
                            />
                        </g>
                        </g>
                        <path
                        fill="#FFC41D"
                        d="M232.49,239.65l-83.7,20.5l28,0.8l19.2,38.4c-14.4,4.4-29.7,6.8-45.5,6.8c-54.7,0-102.9-28.1-130.7-70.7
                                                l53.5-58.5l13.4,2.6c0,0-24.3-17.9-29.5-47.8c-5.7-32.8,9.3-67.2,42.8-86.3c35-19.9,43.4-31.6,43.4-31.6
                                                c-5.1,44.3-50.9,53.2-49.4,86.5s33.1,35.5,33.1,35.5s9.3,0.4,54.9-1.1c24.3-7.8,38.9-31.4,30.4-50.4c-9.7-21.9-46.5-48.1-46.5-48.1
                                                c42.2,7.1,70.2,38.7,69.7,73.5c-0.5,34-34,54.8-34,54.8l47.1,47.2L232.49,239.65z"
                        />
                    </g>
                    <g>
                        <defs>
                        <path
                            id="SVGID_1_"
                            d="M465.89,105.75h78.9l-5.6,31.7h-40.9l-3.5,22.5h38.2l-4.8,29.1h-37.9l-3.5,22.8h41.4l-4.4,29.4h-78.4
                                                        L465.89,105.75z M654.79,105.75h-96l-5.3,31.7h30.4l-15.4,103.8h35.6l15.1-103.8h31L654.79,105.75z M722.99,160.95
                                                        c-14.2-7.4-23-13.5-19.1-23.5c3.5-8.9,19.9-7,35.7,6.1l14.6-25.5c0,0-12-14.6-41.1-14.6s-44.4,15.8-46.7,38.8
                                                        c-1.8,17.6,7.9,29.9,26.6,39.8c19.4,10.3,21.8,18.8,16.1,26.4c-7.1,9.5-25.3,7.3-43.1-12l-19,25.7c0,0,14.9,21.7,49.7,21.7
                                                        c34.7,0,48.9-18.3,51.5-41.1C750.69,181.75,741.29,170.55,722.99,160.95z M433.49,142.85c-1.8,15-11.4,26.2-26.1,28.3
                                                        c16.1,2.3,28.2,12.5,24.6,36c-3.6,23.4-19.7,34-47,34.1l-3.2,21.6l-26-5.4l2.4-16.2c-16.5,0-31.5,0-31.5,0l21.7-135.6
                                                        c0,0,11,0,29.7,0l3.7-25.4l26.6,2.8l-3.4,22.9C430.79,108.55,435.39,127.85,433.49,142.85z M393.19,186.05
                                                        c-2.5-1.4-6-2.1-10.3-2.1c-11.8,0-13,0-13,0l-4.7,31.8c0,0,2,0,12.1,0c4.6,0,8.6-0.6,11.8-2c4.8-2.2,7.8-6.3,9-13.3
                                                        C399.29,193.25,397.59,188.45,393.19,186.05z M400.29,138.15c-1.8-3.8-5.8-5.6-11.2-5.6c-8.4,0-11.6,0-11.6,0l-3.9,26.1
                                                        c0,0,4.4,0,12.1,0c5,0,9.4-1.3,12.3-4.8c1.6-2,2.7-4.5,3.1-7.8S401.19,140.15,400.29,138.15z"
                        />
                        </defs>
                        <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" overflow="visible" />
                        </clipPath>
                        <g clipPath="url(#SVGID_2_)">
                        <g id="wave2Y" opacity="0.5">
                            <g id="wave2X">
                            <path
                                fill="#FFC41D"
                                stroke="#FFC41D"
                                strokeWidth="7"
                                strokeMiterlimit="10"
                                d="M-2.84,282.25
                                                                        c37.76-17.13,73.55-18.2,107.61-0.46l0,0c30.45,20.97,67.58,15.77,107.61-0.46c37.76-17.13,73.55-18.2,107.61-0.46l1.32,310.11
                                                                        l-215,0.92l0,0l-215,0.92L-110,282.71C-80.01,304.14-42.65,298.94-2.84,282.25z"
                            />
                            <path
                                fill="#FFC41D"
                                stroke="#FFC41D"
                                strokeWidth="7"
                                strokeMiterlimit="10"
                                d="M427.16,280.41
                                                                        c37.76-17.13,73.55-18.2,107.61-0.46l0,0c30.45,20.97,67.58,15.77,107.61-0.46c37.76-17.13,73.55-18.2,107.61-0.46l1.32,310.11
                                                                        l-215,0.92l0,0l-215,0.92L320,280.87C349.99,302.3,387.35,297.1,427.16,280.41z"
                            />
                            </g>
                        </g>
                        <g id="wave1Y">
                            <g id="wave1X">
                            <path
                                fill="#FFC41D"
                                stroke="#FFC41D"
                                strokeWidth="7"
                                strokeMiterlimit="10"
                                d="M1077.15,282.25
                                                                        c-37.76-17.13-73.55-18.2-107.61-0.46l0,0c-30.45,20.97-67.58,15.77-107.61-0.46c-37.76-17.13-73.55-18.2-107.61-0.46
                                                                        L753,590.98l215,0.92l0,0l215,0.92l1.32-310.11C1154.32,304.14,1116.96,298.94,1077.15,282.25z"
                            />
                            <path
                                fill="#FFC41D"
                                stroke="#FFC41D"
                                strokeWidth="7"
                                strokeMiterlimit="10"
                                d="M647.15,280.41
                                                                        c-37.76-17.13-73.55-18.2-107.61-0.46l0,0c-30.45,20.97-67.58,15.77-107.61-0.46c-37.76-17.13-73.55-18.2-107.61-0.46
                                                                        L323,589.14l215,0.92l0,0l215,0.92l1.32-310.11C724.31,302.3,686.96,297.1,647.15,280.41z"
                            />
                            </g>
                        </g>
                        </g>
                    </g>
                    </svg>
                </div>
            </div>
        )
    }
}

export default Loader;