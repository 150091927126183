// actions.js
import { SET_IP_ADDRESS, SET_IP_DETAILS, SET_PROVIDERS } from './types';

export const setIPAddress = (ipAddress) => dispatch => {
  const fetchData = ipAddress => {
      dispatch({
          type: SET_IP_ADDRESS,
          payload: ipAddress
      })
  };
  console.log("ACTION", ipAddress);
  fetchData(ipAddress)
};
export const setIpDetails = (remoteIP, country_code, country_name) => {
  console.log("setIpDetails", remoteIP, country_code, country_name);
  return {
      type: SET_IP_DETAILS,
      payload: {
          remoteIP,
          country_code,
          country_name
      }
  }
};


export const setProviders = (providers) => {
    return {
        type: SET_PROVIDERS,
        payload: providers
    }
}