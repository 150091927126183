import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Dropdown, Row, InputGroup, Form } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import {
  __,
  Event,
  forceSatoshiFormat,
  getDefaultBetAmount,
  isValidNumber,
  wait,
  sendNotfication,
  isLogged,
  defaultBetAmount,
} from "../../../Helper";
import { gameCoin } from "../../../actions/gameCoin";
import storage from "../../../Storage";
import { setWallet } from "../../../actions/gameWallet";
import C from "../../../Constant";

class Bet extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      amount: defaultBetAmount(this.props.coin),
      buttonText: "Bet",
      buttonType: "btn-bet",
      type: 1,
      typeName: "DEFAULT",
      disabled: false,
      deal: false,
      c: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    if (this._isMounted) {
      this.props.gameCoin();

      engine.on("play", () => this.play());
      engine.on("busted", () => this.busted());
      engine.on("error", (data) => this.error(data));
      engine.on("stop_playing", () => this.stopManual());
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    if (this._isMounted) {
      this.setState({
        started: false,
        buttonText: "Bet",
        disabled: false,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      this.setState({ disabled: false });

      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);
    }
  }

  play() {
    if (this._isMounted) {
      this.setState({
        disabled: false,
        buttonText: "Deal",
        deal: true,
        buttonType: "btn-bet-success text-white btn-p no-shadow",
      });
    }
  }

  busted() {
    if (this._isMounted) {
      this.setState({
        disabled: true,
        buttonText: "Bet",
        buttonType: "btn-bet",
        deal: false,
      });
      wait(500).then(() => {
        this.setState({ disabled: false });
      });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount, deal } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.coin = this.props.coin;
        engine.amount = amount;

        if (deal) {
          engine.emit("place_deal");
        } else {
          engine.start();
        }
      });
    }
  }

  isCorrectBet() {
    if (this._isMounted) {
      let { amount } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      if (_.isUndefined(amount)) return false;

      if (amount === "") return false;

      if (!isValidNumber(amount)) return false;

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      } else {
        amount = Math.min(
          parseFloat(storage.getKey("credit")),
          parseFloat(amount)
        );
        if (!__.isNaN(amount))
          this.setState({ amount: forceSatoshiFormat(amount) });
      }
      return true;
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      // Check is Correct Bet
      if (!this.isCorrectBet()) return false;

      this.setState({ disabled: true });
      this.placeBet();
    }
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.type === "checkbox" ? true : target.value;
      let name = target.name;

      if (name === "amount") {
        // Update Table
        this.state.engine.emit("update_table_amount", value);
      }

      this.setState({ [name]: value });
    }
  }

  handleMode = (value) => {
    if (this._isMounted) {
      this.setState({ type: value });
      let name;
      if (value === "1") name = "DEFAULT";
      if (value === "2") name = "HARD";
      this.setState({ typeName: name });
    }
  };
  
  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);

        // Update Table
        this.state.engine.emit("update_table_amount", value);
      }
    }
  };

  handleType = (e, value) => {
    if (this._isMounted) {
      e.preventDefault();
      for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
        this.wrapperRef.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      if (this.state.inputDisabled) return;

      this.setState({ type: value });
    }
  };

  render() {
    let { disabled, typeName } = this.state;

    return (
      <>
        <form
          className="w-100 mt-1"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={6} md={7} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  className="form-control"
                  type="number"
                  id="amount"
                  name="amount"
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>TYPE</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleType(e, "medium");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleType(e, "high");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={12} className="m-auto">
              <div className={"form-group rev mt-2 mb-0"}>
                <Button
                  variant={"btn btn-block btn-xl " + this.state.buttonType}
                  disabled={disabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
