import * as PIXI from "pixi.js";
import { Bodies, Composite, Engine, Events, World, Runner } from "matter-js";
import { playAudio, wait, rand, forceSatoshiFormat, __ } from "../../../Helper";
import Effect from "./components/Effect";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

const ASSETS_PATH = "/assets/images/plinko";

var loaded = false;

class Canvas {
  constructor(eng) {
    this.app = new PIXI.Application({
      width: 500,
      height: 500,
      resolution: 1,
      transparent: true,
      view: document.getElementById("game"),
    });

    this.game_engine = eng;
    this.ballnum = 0;
    this.rows = 9;
    this.risk = "low";
    this.pins = [];
    this.bockets = [];
    this.balls = [];
    this.container = null;
    this.explosion = [];
    this.bocketAnimation = [];
    this.started = false;
    this.scores = [5.6, 2.0, 1.1, 1.0, 0.0, 1.0, 1.1, 2.0, 5.6];
    this.scores2 = [6.0, 3.0, 0.7, 0.9, 0.0, 0.9, 0.7, 3.0, 6.0];
    this.scores3 = [9.0, 4.0, 0.7, 0.6, 0.0, 0.6, 0.7, 4.0, 9.0];
    this.selectedRisk = this.scores;
  }

  init() {
    if (this.app === null) return;
    this.engine = Engine.create();
    var runner = Runner.create();
    Runner.run(runner, this.engine);
    Events.on(this.engine, "collisionStart", (e) =>
      this.collisionStart(e, this)
    );
    this.engine.world.gravity.y = 1.1;

    this.container = new PIXI.Container();
    this.app.stage.addChild(this.container);

    if (!loaded) this.app.loader.load((e) => this.load());

    this.app.ticker.add((delta) => this.animation());
    this.addPlinko();
    this.addBocket();
  }

  load() {
    loaded = true;
  }

  animation() {
    if (this.started) {
      let bodies = Composite.allBodies(this.engine.world);
      bodies.forEach((body, i) => {
        if (body.label === "ball") {
          this.balls[body.id].x = body.position.x;
          this.balls[body.id].y = body.position.y;
        }
      });
    }
  }

  collisionStart({ pairs }, timestamp, source, name) {
    pairs.forEach(({ bodyA, bodyB }) => {
      [bodyA, bodyB].forEach((body) => {
        if (body.label === "pin") {
          playAudio("ding.mp3");
          this.pins[body.id].texture = PIXI.Texture.from(
            ASSETS_PATH + "/pin_active.png"
          );
          if (this.rows === 25) {
            this.pins[body.id].scale.set(0.8);
          } else {
            this.pins[body.id].y -= 1;
          }
          wait(100).then(() => {
            this.pins[body.id].texture = PIXI.Texture.from(
              ASSETS_PATH + "/pin.png"
            );
            this.pins[body.id].y += 1;
          });
        }

        if (body.label === "bocket") {
          this.sendScore(body.score);
          playAudio("win.mp3");
          this.bocketAnimation.push(body.id);
        }

        if (body.label === "ball") {
          let down = this.app.screen.height - 70;
          if (body.position.y >= down) {
            this.container.removeChild(this.balls[body.id]);
            World.remove(this.engine.world, body);

            this.bocketAnimation.forEach((bocket, i) => {
              this.bockets[bocket].y += 4;
              Effect.Particle(
                this.bockets[bocket],
                ASSETS_PATH + "/bocket_star.png"
              );
              wait(100).then(() => {
                this.bockets[bocket].texture = PIXI.Texture.from(
                  ASSETS_PATH + "/bocket.png"
                );
                this.bockets[bocket].y = this.app.screen.height - 45;
                this.bocketAnimation.splice(i, 1);
                wait(500).then(() => {
                  this.bockets[bocket].texture = PIXI.Texture.from(
                    ASSETS_PATH +
                      `/bocket_active_${this.bockets[bocket].color}.png`
                  );
                });
              });
            });
          }
        }
      });
    });
  }

  addPlinko() {
    var n = this.rows;
    let size = 18;
    let radius = 9;
    for (let i = 0; i <= n; i++) {
      for (let j = 0; j <= i; j++) {
        var y = (i + 1) / n;
        var x = 0.495 + (j - i / 2) / 11;

        if (n === 16) {
          y = (i + 1) / n + 0.1;
          x = 0.495 + ((j - i / 2) / n / n) * 13;
        } else if (n === 25) {
          y = (i + 1) / n + 0.12;
          x = 0.495 + ((j - i / 2) / n / n) * 21;
          size = 10;
          radius = 6;
        } else if (n === 45) {
          y = (i + 1) / n + 0.15;
          x = 0.495 + ((j - i / 2) / n / n) * 38;
          size = 4;
          radius = 3;
        }

        if (i !== 0) {
          let body = Bodies.circle(100 * x * 5, 400 * y - 40, radius, {
            isStatic: true,
            label: "pin",
          });
          World.add(this.engine.world, body);
          let pin = PIXI.Sprite.from(ASSETS_PATH + "/pin.png");
          pin.x = body.position.x;
          pin.y = body.position.y;
          pin.width = size;
          pin.height = size;
          pin.anchor.set(0.5);
          this.pins[body.id] = pin;
          this.container.addChild(pin);
        }
      }
    }
  }

  addBocket() {
    for (var i = 0; i < this.selectedRisk.length; i++) {
      var width = 45,
        height = 30,
        x = 59.5 + i * 47.5;

      var body = Bodies.rectangle(
        x,
        this.app.screen.height - 45,
        width,
        height,
        {
          isStatic: true,
          label: "bocket",
          score: this.selectedRisk[i],
          id: i,
        }
      );
      World.add(this.engine.world, body);

      // let color = i > 5 ? 0 : i

      let color;

      if (i === 0) color = 0;
      else if (i === 1) color = 1;
      else if (i === 2) color = 2;
      else if (i === 3) color = 3;
      else if (i === 4) color = 4;
      else if (i === 5) color = 3;
      else if (i === 6) color = 2;
      else if (i === 7) color = 1;
      else if (i === 8) color = 0;
      else if (i === 9) color = 0;
      else if (i === 10) color = 0;

      let bocket = PIXI.Sprite.from(
        ASSETS_PATH + `/bocket_active_${color}.png`
      );
      bocket.x = body.position.x;
      bocket.y = body.position.y;
      bocket.width = width;
      bocket.height = height;
      bocket.anchor.set(0.5);
      bocket.zIndex = 2;
      bocket.interactive = true;
      bocket.score = this.selectedRisk[i];
      bocket.id = body.id;
      bocket.color = color;
      bocket.on("pointerover", (e) => this.onOver(bocket));
      bocket.on("pointerout", (e) => this.onOut(bocket));

      const style = new PIXI.TextStyle({
        fontFamily: "Roboto",
        fontSize: 14,
        fill: "#FFF",
        fontWeight: 700,
      });

      var f =
        this.selectedRisk[i] > 9
          ? this.selectedRisk[i]
          : this.selectedRisk[i].toFixed(1);
      let score = new PIXI.Text(f + "x", style);
      score.x = -12;
      score.y = -7;

      this.bockets[body.id] = bocket;
      bocket.addChild(score);
      this.container.addChild(bocket);
    }
  }

  addBall() {
    this.ballnum++;
    let radius = 9;
    let size = 35;

    if (this.rows === 16) {
      size = 17;
      radius = 3;
    } else if (this.rows === 25) {
      size = 14;
      radius = 1;
    } else if (this.rows === 45) {
      size = 6;
      radius = 1;
    }

    var x = this.calculateDistance();

    let body = Bodies.circle(this.app.screen.width / 2 + x, 10, radius, {
      restitution: 0.35,
      label: "ball",
      friction: 1.1,
      density: 0.9,
      collisionFilter: {
        group: -1,
      },
    });
    World.add(this.engine.world, body);

    let ball = PIXI.Sprite.from(ASSETS_PATH + "/ball.png");
    ball.x = body.position.x;
    ball.y = body.position.y;
    ball.width = size;
    ball.height = size;
    ball.anchor.set(0.5);
    ball.zIndex = 1;
    ball.interactive = true;
    this.balls[body.id] = ball;
    this.container.addChild(ball);
  }

  onOut(e) {
    this.container.removeChild(this.popup);
  }

  onOver(e) {
    this.popup = new PIXI.Container();
    this.popup.sortableChildren = true;

    var x = 0;

    let g = new PIXI.Graphics();
    g.lineStyle(0);
    g.beginFill(0x555555);
    g.drawRoundedRect(e.getBounds().x - 7, e.getBounds().y - 45, 200, 60, 4);
    g.endFill();
    g.sortChildren(5);
    g.zIndex = 5;

    let t = Effect.Text("Win Amount : ", {
      family: "Roboto",
      size: 12,
      color: "#FFF",
    });

    t.x = e.getBounds().x + x + 10;
    t.y = e.getBounds().y - 35;
    t.zIndex = 5;

    let profit = forceSatoshiFormat(
      (parseFloat(this.game_engine.amount) * e.score) / 10
    );

    let coin = __.isUndefined(this.game_engine.coin)
      ? "USDT"
      : this.game_engine.coin;

    let t1 = Effect.Text(profit + " " + coin, {
      family: "Roboto",
      size: 11,
      color: "#FFF",
    });
    t1.x = e.getBounds().x + 100;
    t1.y = e.getBounds().y - 35;
    t1.zIndex = 5;

    let field1 = this.addField(e.getBounds().x + 90, e.getBounds().y - 37);

    field1.addChild(t1);

    let t2 = Effect.Text("Chance  :", {
      family: "Roboto",
      size: 12,
      color: "#FFF",
    });

    t2.x = e.getBounds().x + x + 10;
    t2.y = e.getBounds().y - 13;
    t2.zIndex = 5;

    var chance = this.getChance(parseFloat(e.score));

    let t3 = Effect.Text(chance + " %", {
      family: "Roboto",
      size: 11,
      color: "#FFF",
    });
    t3.x = e.getBounds().x + 100;
    t3.y = e.getBounds().y - 12;
    t3.zIndex = 5;

    let field2 = this.addField(e.getBounds().x + 90, e.getBounds().y - 15);
    field2.addChild(t3);

    var xx = 0;
    if (e.id === 6) {
      xx = -50;
    } else if (e.id === 7) {
      xx = -75;
    } else if (e.id === 8) {
      xx = -120;
    } else if (e.id === 9) {
      xx = -170;
    }

    g.addChild(t, field1, t2, field2);
    this.popopContainer = new PIXI.Container();
    this.popopContainer.y = -20;
    this.popopContainer.x = xx;
    this.popopContainer.addChild(g);
    this.popup.addChild(this.popopContainer);
    this.container.addChild(this.popup);
  }

  addField(x, y) {
    let g = new PIXI.Graphics();
    g.lineStyle(0);
    g.beginFill(0x444444, 1);
    g.drawRoundedRect(x, y, 90, 20, 3);
    g.endFill();
    g.zIndex = 5;
    return g;
  }

  addStar(bonus) {
    let g = new PIXI.Graphics();
    g.lineStyle(0);
    g.beginFill(0xffc107);
    g.drawRoundedRect(1, 2, 500, 25, 22);
    g.endFill();
    g.y = 470;

    this.container.addChild(g);

    const style = new PIXI.TextStyle({
      fontSize: 22,
      fill: "#111",
      fontWeight: 700,
    });
    let score = new PIXI.Text(bonus.toFixed(1) + "x", style);
    score.x = g.width / 2;
    score.y = 2;
    g.addChild(score);

    Effect.FadeIn(g, 0.6);

    wait(2000).then(() => {
      Effect.FadeOut(g, 0.7);

      wait(1000).then(() => {
        this.container.removeChild(g);
      });
    });

    for (var i = 0; i < 10; i++) {
      let c = PIXI.Sprite.from(ASSETS_PATH + "/star.png");
      c.x = i * 77;
      c.y = 1;
      c.width = 15;
      c.height = 20;
      g.addChild(c);

      Effect.MoveIn(c, c.x, rand(c.y - 20, c.y + 70), 6.8);

      Effect.Flash(c, 10);
    }
  }

  addPayout(bonus) {
    this.block = new PIXI.Graphics();
    this.block.lineStyle(0);
    let color = bonus <= 1.0 ? 0xf74f58 : 0xfda458;
    this.block.beginFill(color, 1);
    this.block.drawRoundedRect(1, 2, 60, 35, 7);
    this.block.endFill();
    this.block.y = 20;

    const style = new PIXI.TextStyle({
      fontFamily: "Roboto",
      fontSize: 17,
      fill: "#FFF",
      fontWeight: 500,
    });
    let score = new PIXI.Text(bonus.toFixed(1) + "x", style);
    score.x = 15;
    score.y = 8;
    this.block.addChild(score);
    this.container.addChild(this.block);

    if (this.block) {
      Effect.FadeOut(this.block, 1.5);
      wait(1000).then(() => {
        this.container.removeChild(this.block);
      });
    }
  }

  getChance(score) {
    var chance;
    if (score === 5.6 || score === 6.0 || score === 9.0) {
      chance = 0.3906;
    } else if (score === 2.0 || score === 3.0 || score === 4.0) {
      chance = 3.125;
    } else if (score === 1.1 || score === 0.7) {
      chance = 10.9375;
    } else if (score === 1.0 || score === 0.9 || score === 0.6) {
      chance = 21.875;
    } else if (score === 0.0) {
      chance = 27.3438;
    }

    return chance.toFixed(4);
  }

  calculateDistance() {
    var x;

    if (this.rows === 9) {
      if (this.result === 0.0) {
        x = 3.2; //x
      } else if (this.result === 1.6 || this.result === 0.7) {
        x = __.sample([8.9, 6.4]);
      } else if (
        this.result === 5.6 ||
        this.result === 6.0 ||
        this.result === 9.0
      ) {
        x = __.sample([-2.8, 3.9]);
      } else if (
        this.result === 4.0 ||
        this.result === 7.0 ||
        this.result === 2.0
      ) {
        x = __.sample([-3.8, 3.8]);
      } else if (
        this.result === 0.9 ||
        this.result === 0.6 ||
        this.result === 1.0
      ) {
        x = __.sample([3.0, -12.0]);
      }
    } else if (this.rows === 16) {
      if (this.result === 0.0) {
        x = 3.5; //x
      } else if (
        this.result === 5.6 ||
        this.result === 6.0 ||
        this.result === 9.0
      ) {
        x = __.sample([-5.81, -2.1]);
      } else if (
        this.result === 2.0 ||
        this.result === 4.0 ||
        this.result === 7.0
      ) {
        x = __.sample([-5.5, -3.6]);
      } else if (this.result === 1.6 || this.result === 0.7) {
        x = __.sample([4.8, -2.7]);
      } else if (
        this.result === 1.0 ||
        this.result === 0.9 ||
        this.result === 0.6
      ) {
        x = __.sample([-3.8, -3.1, 8.1]);
      }
    } else if (this.rows === 25) {
      if (this.result === 0.0) {
        x = -2.76; //x
      } else if (this.result === 1.6 || this.result === 0.7) {
        x = __.sample([4.8, -7.6]);
      } else if (
        this.result === 1.0 ||
        this.result === 0.6 ||
        this.result === 0.9
      ) {
        x = __.sample([-3.8, -4.354545, -8.1]);
      } else if (
        this.result === 5.6 ||
        this.result === 6.0 ||
        this.result === 9.0
      ) {
        x = __.sample([-3.5, -1.92]);
      } else if (
        this.result === 2.0 ||
        this.result === 4.0 ||
        this.result === 7.0
      ) {
        x = __.sample([-1.75, -2.1]);
      }
    }

    return x;
  }

  sendScore(score) {
    this.ballnum--;

    const e = this.game_engine;
    e.init = true;
    e.balls = this.ballnum;
    e.busted();

    if (this.ballnum === 0) {
      this.started = false;
    }

    // this.addPayout(score);
    if (score !== 1.0 || score !== 0.0) this.addStar(score);
  }

  changeRows(num) {
    this.rows = parseFloat(num);

    for (let i = 0; i <= this.pins.length; i++) {
      this.container.removeChild(this.pins[i]);
    }
    for (let i = 0; i <= this.bockets.length; i++) {
      this.container.removeChild(this.bockets[i]);
    }
    this.init(this.game_engine);
  }

  changeRisk(risk) {
    if (risk === "Low") {
      this.risk = 1;
      this.selectedRisk = this.scores;
    } else if (risk === "Medium") {
      this.risk = 2;
      this.selectedRisk = this.scores2;
    } else if (risk === "High") {
      this.risk = 3;
      this.selectedRisk = this.scores3;
    }

    for (let i = 0; i <= this.pins.length; i++) {
      this.container.removeChild(this.pins[i]);
    }
    for (let i = 0; i <= this.bockets.length; i++) {
      this.container.removeChild(this.bockets[i]);
    }
    for (let i = 0; i <= this.balls.length; i++) {
      this.container.removeChild(this.balls[i]);
    }
    this.init(this.game_engine);
  }

  play(result) {
    this.result = result;
    this.addBall();
    this.started = true;
  }

  stop() {
    this.started = false;
  }

  destroy() {
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
