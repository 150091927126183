import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import UserModal from "../Components/User/Stat/Modal";
import { __, defaultAvatar, isMobile, Game, toUSD, decode } from "../../Helper";
import socket from "../../Socket";
import C from "../../Constant";
import Loader from "../Components/Loader";

class Winners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: [],
            daily_prize_pool: 0,
            daily_prize_percent: [],
            mobile: isMobile()
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        socket.emit(C.TOP_WINNERS);
        socket.on(C.TOP_WINNERS, data => this.makeList(decode(data)));
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        socket.off(C.TOP_WINNERS);
        window.removeEventListener('resize', this.handleResize);
    }

    makeList = (data) => {
        this.setState({ loading: false, players: data.contest_data, daily_prize_pool: data.daily_prize_pool,  daily_prize_percent: data.daily_prize_percent});
    };
    
    handleResize() {
        this.setState({ mobile: isMobile() })
    }

    render() {
        const { t } = this.props;
        const list = this.state.players.map((player, i) => <Players key={__.toString(i)} medal={i + 1} player={player} prize={this.state.daily_prize_pool*this.state.daily_prize_percent[i]/100 } />)

        return (
            <Row>
                <Col sm={12} className="p-0">
                    <div className="table-responsive num-style mb-0 img-fix">
                        {this.state.loading ?
                            <div className="text-center">
                                <div className="spinner-grow text-white my-3" role="status" />
                            </div>
                            // <Loader />
                            :
                            <div className={'table-responsive top-winner-table num-style mb-0 tww'}>
                                <Table className={"mb-2"}>
                                    <thead>
                                        <tr>
                                            {!this.state.mobile &&
                                                <th>{t('rank')}</th>
                                            }
                                            <th>
                                                {t('player')}
                                            </th>
                                            <th>
                                                {t('wagered')}
                                            </th>
                                            <th>
                                                {t('prize')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{list}</tbody>
                                </Table>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        );
    }
}

function Players(props) {
    let { player, medal, prize } = props;
    let num = medal;

    if (medal === 10)
        medal = 1
        
    let username = player.name;
    // let avatar = defaultAvatar;
    let avatar = player.avatar;

    const coins = ['usdt', 'btc', 'eth', 'bch', 'ltc', 'doge' ];
    var highArr = [];
    var plusProfit = 0;

    // coins.forEach((coinName, i) => {
    //     if (player.profit) {
    //         var np = !__.isUndefined(player.profit[coinName]) ? player.profit[coinName] : 0;
    //         np = __.toNumber(np);

    //         if (np > 0 && np !== NaN) {
    //             var convertToUsd = np * __.toNumber(Game[__.upperCase(coinName)]);
    //             plusProfit += __.toNumber(convertToUsd);
    //             highArr.push(plusProfit);
    //         }
    //     }
    // });

    // let total = __.sum(highArr);

    // if (total === 0) return null;

    // let wagered = toUSD(total);
    let wagered = toUSD(player.total_amount);
    // let prize = toUSD(__.toNumber(player.total_amount / 7));

    if (__.isNaN(wagered)) return null
    if (wagered === NaN) return null

    if (parseFloat(wagered) === 0) return null;

    return (
        <tr className={'q-crash2'}>
            {!isMobile() &&
                (
                <td>
                    <div className="d-flex h-100 winner-badge-wrapper">
                        <div className="deco-bar-first"></div>
                        <div className="d-flex align-items-center winner-badge">
                            {medal < 4 ? <img className="img-fluid rounded-circle ffi" src={'/assets/images/badges/badge-' + medal + '.svg'} /> : <></>}
                            <span>{num}</span>
                        </div>
                    </div>
                </td>
                )
            }
            <td>
                <div className="d-flex align-items-center gap-2">
                    <div className="d-flex justify-content-center align-items-center winner-avatar-wrapper">
                        <img className="rounded thumb-xs" src={avatar} />
                    </div>
                    <UserModal id={player.id} username={username} isWinner={false} />

                </div>
            </td>
            <td className={'num-style text-success'}>
                {wagered}
            </td>
            <td className={'num-style text-success'}>
                {toUSD(prize)}
            </td>
            {/* <td>
                <img className="rounded-circle thumb-xs ffi" src={'/assets/images/badges/badge-' + medal + '.svg'} />
            </td> */}
        </tr>
    );
}

export default Winners;
