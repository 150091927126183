import MyEmitter from "events";
import socket from "../../../Socket";
import { encode, decode, forceSatoshiFormat, wait } from "../../../Helper";
import C from "../../../Constant";
/**
 * Plinko Engine
 */
function Engine() {
  var self = this;

  /**
   * Game Prefix
   */
  self.prefix = "_PLINKO";

  /**
   * Game Name
   */
  self.game = "plinko";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Game Status
   * 1 type: started
   */
  self.gameStatus = null;

  /**
   * Intialize Auto Mode
   */
  self.init = false;

  /**
   * Current User Coin
   */
  self.coin = "";

  /**
   * Round amount
   */
  self.amount = 0.001;

  /**
   * Current balls count in the game
   */
  self.balls = null;

  /**
   * Win or Lost
   */
  self.win = null;

  /**
   * Busted Game Hash
   */
  self.hash = null;

  /**
   * Bets Number
   */
  self.betNumber = 10;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Player is the Win or Lost
   */
  self.isWin = false;

  /**
   * Stop Playing if Won Amount
   */
  self.stopOnWin = 0;

  /**
   * Round Profit
   */
  self.roundProfit = 0;

  /**
   * Auto play
   */
  self.autoPlay = null;

  /**
   * Game risk
   */
  self.risk = "low";

  /**
   * Played rounds
   */
  self.round = 0;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_PLINKO, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_PLINKO);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_PLINKO,
    encode({
      command: "play",
      auto: false,
      coin: self.coin,
      amount: self.amount,
      risk: self.risk,
    })
  );
};

/**
 * End Playing Game
 */
Engine.prototype.busted = function () {
  let self = this;

  if (!self.init) return;

  self.ws.emit(
    C.PLAY_PLINKO,
    encode({
      command: "busted",
      balls: self.balls,
      hash: self.hash,
    })
  );
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
  let self = this;

  // Increase Amount on Win (player set increase amount on win in %)
  if (self.onWin !== 0) {
    // It Mean Player has win in this round
    if (self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onWin;
    }
  }

  // Increase Amount on Lost (player set increase amount on lost in %)
  if (self.onLost !== 0) {
    // It Mean Player has lost in this round
    if (!self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onLost;
    }
  }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.roundProfit) >= forceSatoshiFormat(self.stopOnWin)
    ) {
      self.roundProfit = 0;
      self.trigger.emit("stop");
      return;
    }
  }

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  var added = false;

  function auto() { 
    if (!self.init) {
      self.stop();
    }

    if (self.round <= 0) {
      self.stop();
    }

    if (!added) {
      added = true;

      self.ws.emit(
        C.PLAY_PLINKO,
        encode({
          command: "play",
          auto: true,
          coin: self.coin,
          amount: self.amount,
          risk: self.risk,
        })
      );

      wait(2500).then(() => {
        added = false;
      });
    }
  }

  self.autoPlay = window.requestAnimationFrame(auto);
};

/**
 * Stop auto playing
 */
Engine.prototype.stop = function () {
  window.cancelAnimationFrame(this.autoPlay);
  console.log("stoppedX", this.round);
};

/** Start Game Engine **/
export default Engine;
