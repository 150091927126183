import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { __, Event, decode, sendNotfication, encode } from "../../../../Helper";
import C from "../../../../Constant";

class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      isLogged: storage.getKey("logged") !== null ? true : false,
      country: "GLOBAL",
      chat_able: true,
      disable: false,
      streamer: false,
      promoUsed: false
    };
  }

  componentDidMount() {
    // socket.emit(C.CHAT_ABLE);
    // socket.on(C.CHAT_ABLE, (data) => {
    //   if(decode(data)) {
    //     this.setState({chat_able: true});
    //   }
    // })
    if (this.state.isLogged) {
      socket.emit(C.DEPOSIT_HISTORY);
      socket.on(C.DEPOSIT_HISTORY, (data) => {
        if (!decode(data)) {
          this.setState({ chat_able: false });
        } else {
          this.setState({ chat_able: true });
        }
      });

      if (storage.getKey("uid")) {
        socket.emit(C.IS_STREAMER, encode({ id: storage.getKey("uid") }));
        socket.emit(C.PROMO_USED, encode({ id: storage.getKey("uid") }));
      }

      socket.on(C.IS_STREAMER, (data) => {
        const { status } = decode(data);
        this.setState({ streamer: status });
      });

      socket.on(C.PROMO_USED, (data) => {
        const { status } = decode(data);
        this.setState({ promoUsed: status });
      });
    }

    Event.on("call_user", (data) => {
      this.setState({ message: data });
    });

    socket.on(C.ADD_CHAT, (data) => {
      if (data.success) {
        this.setState({ disable: false });
        let messageInput = document.getElementById("message_input");
        if (messageInput) {
          messageInput.focus();
        }
      }
    });
  }

  handleChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    this.setState({ [name]: value });
  };
  componentWillUnmount() {
    // socket.off(C.CHAT_ABLE);
    socket.off(C.DEPOSIT_HISTORY);
    socket.off(C.PROMO_USED);
  }
  submit = (e) => {
    e.preventDefault();

    if (this.state.message.trim() === "") return;
    // if(this.state.chat_able) {
    //   socket.emit(C.ADD_CHAT, {
    //     message: this.state.message,
    //     country: this.state.country,
    //   });
    //   this.setState({ message: "" });
    //   Event.emit("scrolldone");
    // } else {
    // socket.emit(C.DEPOSIT_HISTORY);
    // socket.on(C.DEPOSIT_HISTORY, (data) => {
    //   if (!decode(data)) {

    if(this.state.chat_able || this.state.streamer || this.state.promoUsed) {
      socket.emit(C.ADD_CHAT, {
        message: this.state.message,
        country: this.state.country,
      });
      this.setState({ message: "", disable: true });
      Event.emit("scrolldone");
    } else {
      sendNotfication(
        this.props.t("feel_free_to_start_chat_once_you_have_made_deposit")
      );
    }
  };

  handleSubmit = (e) => {
    let char = e.which || e.keyCode;
    if (char === 13) {
      this.submit(e);
    }
  };

  render() {
    const { t } = this.props;
    const style = {
      backgroundColor: "rgb(27 35 54)",
      color: "#F8F8F8",
      fontSize: 14,
      height: 90,
      fontFamily: "Titillium Web",
    };
    return (
      <>
        <div className="chat-input p-3">
          <form onKeyPress={(e) => this.handleSubmit(e)}>
            <div className="d-flex align-items-center">
              {this.state.isLogged ? (
                <>
                  {this.state.chat_able || this.state.streamer || this.state.promoUsed ? (
                    <textarea
                      id="message_input"
                      onChange={this.handleChange}
                      autoComplete={"off"}
                      name={"message"}
                      rows="1"
                      className="form-control message-input-window"
                      placeholder={t("message")}
                      value={this.state.message}
                      disabled={this.state.disable}
                    />
                  ) : (
                    <p className="m-0 msg-login-suggestion">
                      {t("you_must_deposit_to_chat")}
                    </p>
                  )}
                </>
              ) : (
                <>
                  {/* <textarea
                      rows="1" disabled={true} autoComplete={'off'} className="form-control message-input-window" placeholder={t('message')} value={t('please_login_to_use_chat')} /> */}
                  <p className="m-0 msg-login-suggestion">
                    {t("please_login_to_use_chat")}
                  </p>
                </>
              )}

              <button
                className="btn btn-send-chat"
                onClick={(e) => this.submit(e)}
              >
                <img
                  className="img-fluid"
                  src="/assets/images/icons/chat_send_icon.svg"
                />
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

Submit.propTypes = {
  name: PropTypes.string,
  country: PropTypes.string,
};

const mapStateToProps = (state) => ({
  name: state.items.name,
  country: state.items.country,
});

export default connect(mapStateToProps, {})(Submit);
