import React from "react";
import { Row, Col } from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import { decode, encode, wait, isEmail, sendNotfication, __ } from "../../../../Helper";
import ReactTooltip from "react-tooltip";
import C from "../../../../Constant";

class Parent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            firstUserName: storage.getKey('name'),
            firstEmail: storage.getKey('email'),
            email: storage.getKey('email'),
            username: storage.getKey('name'),
            first_name: storage.getKey('first_name') ? storage.getKey('first_name') : '',
            last_name: storage.getKey('last_name') ? storage.getKey('last_name') : '',
            address: storage.getKey('address') ? storage.getKey('address') : '',
            city: storage.getKey('city') ? storage.getKey('city') : '',
            country: storage.getKey('country') ? storage.getKey('country') : '',
            zip_code: storage.getKey('zip_code') ? storage.getKey('zip_code') : '',
            hide: storage.getKey('hide') === 'true' ? true : false,
            mod: storage.getKey('mod'),
            // checkbox2: storage.getKey('show_usd') === null ? false : true, 
            checkbox2: false,
            loadingEmail: false,
            loadingUsername: false,
            loadingFirstname: false,
            loadingLastname: false,
            loadingAddress: false,
            loadingCity: false,
            loadingCountry: false,
            loadingZip_code: false,

            loadingPrivacy: false,
            loading2Fa: false,
            loading: true,
            loadingCheck: false,

            err_username: false,
            err_username_txt: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log(typeof(this.state.last_name));
        socket.on(C.EDIT_ACCOUNT, data => this.updateProfile(decode(data)));
        socket.on(C.TOGGLE_HIDE_STATS, data => this.changeStats(decode(data)));
    }

    componentWillMount() {
        socket.off(C.EDIT_ACCOUNT);
        wait(1000).then(() => {
            this.setState({ loading: false });
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateProfile(data) {
        if (this._isMounted) {
            this.setState({ loadingUsername: false, loadingEmail: false, loadingFirstname: false, loadingLastname: false, loadingAddress: false, loadingCountry: false, loadingZip_code: false, loadingCity: false });
            if (data.status) {
                // console.log(data);
                data.username && storage.setKey('name', data.username);
                data.email && storage.setKey('email', data.email);
                data.first_name && storage.setKey('first_name', data.first_name);
                data.last_name && storage.setKey('last_name', data.last_name);
                data.address && storage.setKey('address', data.address);
                data.city && storage.setKey('city', data.city);
                data.country && storage.setKey('country', data.country);
                data.zip_code && storage.setKey('zip_code', data.zip_code);
                sendNotfication(this.props.t('your_account_setting_was_updated'), 'success', 'top-center');
                // window.location.replace('../');
            }
            else {
                return sendNotfication(this.props.t(data.error) + '.', 'success', 'top-center');
            }
        }
    }

    save(input) {
        if (this._isMounted) {
            let { email, username, firstUserName, firstEmail, first_name, last_name, address, country, city, zip_code } = this.state;

            if (input === 'email') {
                if (email === firstEmail) {
                    return sendNotfication(this.props.t('please_enter_new_email_address'), 'info', 'top-center');
                }

                if (!email) {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }

                if (email.trim() === "") {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }

                if (!isEmail(email)) {
                    document.getElementById('email').classList.add('border-danger');
                    return;
                }

                this.setState({ loadingEmail: true });

                wait(100).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        email: email
                    }));
                })
            }

            if (input === 'username') {
                if (username === firstUserName) {
                    return sendNotfication(this.props.t('please_enter_new_username'), 'info', 'top-center');
                }

                if (!username) {
                    return;
                }

                if (username.trim() === "") {
                    return;
                }

                if (this.state.err_username) return;

                this.setState({ loadingUsername: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        username: username
                    }));
                })
            }
            if (input === 'first_name') {
                if (first_name === storage.getKey('first_name')) {
                    return sendNotfication(this.props.t('please_enter_new_first_name'), 'info', 'top-center');
                }

                if (!first_name) {
                    return;
                }

                if (first_name.trim() === "") {
                    return;
                }

                this.setState({ loadingFirstname: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        first_name: first_name
                    }));
                })
            }
            if (input === 'last_name') {
                if (last_name === storage.getKey('last_name')) {
                    return sendNotfication(this.props.t('please_enter_new_last_name'), 'info', 'top-center');
                }

                if (!last_name) {
                    return;
                }

                if (last_name.trim() === "") {
                    return;
                }

                this.setState({ loadingLastname: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        last_name: last_name
                    }));
                })
            }
            if (input === 'address') {
                if (address === storage.getKey('address')) {
                    return sendNotfication(this.props.t('please_enter_new_address'), 'info', 'top-center');
                }

                if (!address) {
                    return;
                }

                if (address.trim() === "") {
                    return;
                }

                this.setState({ loadingAddress: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        address: address
                    }));
                })
            }
            if (input === 'city') {
                if (city === storage.getKey('city')) {
                    return sendNotfication(this.props.t('please_enter_new_city'), 'info', 'top-center');
                }

                if (!city) {
                    return;
                }

                if (city.trim() === "") {
                    return;
                }

                this.setState({ loadingCity: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        city: city
                    }));
                })
            }
            if (input === 'country') {
                if (country === storage.getKey('country')) {
                    return sendNotfication(this.props.t('please_enter_new_country'), 'info', 'top-center');
                }

                if (!country) {
                    return;
                }

                if (country.trim() === "") {
                    return;
                }

                this.setState({ loadingCountry: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        country: country
                    }));
                })
            }
            if (input === 'zip_code') {
                if (zip_code === storage.getKey('zip_code')) {
                    return sendNotfication(this.props.t('please_enter_new_zip_code'), 'info', 'top-center');
                }

                if (!zip_code) {
                    return;
                }

                if (zip_code.trim() === "") {
                    return;
                }

                this.setState({ loadingZip_code: true });

                wait(500).then(() => {
                    socket.emit(C.EDIT_ACCOUNT, encode({
                        zip_code: zip_code
                    }));
                })
            }
        }
    }

    changeStats = (data) => {
        let { status } = data;
        if(status) sendNotfication('hidden stats enbabled', 'info', 'top-center');
        else sendNotfication('hidden stats disabled', 'info', 'top-center');

        storage.setKey('hide', status)
    }

    handleCheckBox = () => {
        this.setState({ hide: !this.state.hide })

        socket.emit(C.TOGGLE_HIDE_STATS, encode({
            hide: this.state.hide
        }));
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.loading ?

                    <div className="text-center">
                        <div className="spinner-grow text-white my-2" role="status" />
                    </div>
                    :
                    <>
                        <ReactTooltip />
                        <Row className="mt-5">
                            <Col sm={6}>
                                <label htmlFor="email">{t('email_address')}</label>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        id="email"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_new_email')}
                                        value={this.state.email}
                                        required={true}
                                        onChange={e => this.setState({ email: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('email')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingEmail ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="username">{t('username')}</label>
                                <div className="input-group">
                                    <input
                                        type="username"
                                        id="username"
                                        autoComplete={'off'}
                                        className="form-control"
                                        value={this.state.username}
                                        required={true}
                                        onChange={(e) => {
                                                if (/^[a-zA-Z]*$/.test(e.target.value)) {
                                                    if(e.target.value.length > 11)
                                                        this.setState({ username: e.target.value, err_username: true, err_username_txt: 'Username can\'t be long than 11 characters.' });
                                                    else this.setState({ username: e.target.value, err_username: false, err_username_txt: '' })
                                                }
                                                else {
                                                        this.setState({ username: e.target.value, err_username: true, err_username_txt: 'Please enter text without spaces or special characters.' })
                                                }
                                            }
                                        }
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('username')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingUsername ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                                {this.state.err_username ? <div className="help-block">{this.state.err_username_txt}</div> : <></>}
                            </Col>
                            {/* <Col sm={12} className={this.state.mod == 1 ? "disabled-col" : ""}>
                             <label className="my-3" htmlFor="">{t('Options')}</label>
                            <div class="custom-control custom-checkbox">
                                <input onClick={this.handleCheckBox} type="checkbox" class="custom-control-input" id="hide" checked={this.state.hide} />
                                <label class="custom-control-label" for="hide">Hide my Stats</label>
                            </div>
                            </Col> */}
                        </Row>
                        <h4 className="mt-5 text-white">{t('your_details')}</h4>
                        <Row className="mt-2 userdetails">
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="first_name">{t('first_name')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="first_name"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_first_name')}
                                        value={this.state.first_name}
                                        required={true}
                                        onChange={e => this.setState({ first_name: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('first_name')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingFirstname ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="last_name">{t('last_name')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="last_name"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_last_name')}
                                        value={this.state.last_name}
                                        required={true}
                                        onChange={e => this.setState({ last_name: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('last_name')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingLastname ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 userdetails">
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="address">{t('address')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="address"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_your_address')}
                                        value={this.state.address}
                                        required={true}
                                        onChange={e => this.setState({ address: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('address')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingAddress ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="city">{t('city')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="city"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_your_city')}
                                        value={this.state.city}
                                        required={true}
                                        onChange={e => this.setState({ city: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('city')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingCity ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 userdetails userdetailslast">
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="country">{t('country')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="country"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_your_country')}
                                        value={this.state.country}
                                        required={true}
                                        onChange={e => this.setState({ country: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('country')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingCountry ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} className={this.state.mod == 1 ? "disabled-col" : ""}>
                                <label htmlFor="zip_code">{t('zip_code')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id="zip_code"
                                        autoComplete={'off'}
                                        className="form-control"
                                        placeholder={t('enter_your_zip_code')}
                                        value={this.state.zip_code}
                                        required={true}
                                        onChange={e => this.setState({ zip_code: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={e => this.save('zip_code')} type="button" className="btn bg-cs7 no-shadow btn-clipboard" data-tip="Save">
                                            {this.state.loadingZip_code ?
                                                <div className="spinner-border spinner-border-sm" role="status" />
                                                : <i className="mdi mdi-content-save-settings" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </>
        );
    }
}

export default Parent;
