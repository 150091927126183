import * as PIXI from "pixi.js";
import * as Filter from "pixi-filters";
import { Spine, SpineParser } from "pixi-spine";
import MyEmitter from "events";
import Loader from "../../../Loader";
import Dice from "../Dice/index.js";
import Sprite from "../Sprite/index.js";
import Text from "../Text/index.js";
import Message from "../Text/Message.js";
import { wait } from "../Helper/index.js";
import { playAudio, __ } from "../../../../../Helper";

class Canvas {
  constructor(engine) {
    let self = this;

    self.engine = engine;
    self.app = null;
    self.ticker = null;
    self.container = null;
    self.objects = {};
    self.started = false;
    self.currentLand = 0;
    self.oldLand = 0;
    self.dice = 0;
    self.lost = false;
    self.container = null;
    self.event = new MyEmitter();

    self.init = function () {
      self.app = new PIXI.Application({
        view: document.getElementById("game"),
        roundPixels: false,
        width: 610,
        height: 570,
        transparent: true,
        antialias: true,
        resolution: 1,
      });
      self.loader = new Loader("snake", self.app);
      self.app.loader.use(SpineParser.use);

      //Ticker
      self.ticker = new PIXI.Ticker();

      self.loader.set("dice1", "/assets/images/snake/1.png");
      self.loader.set("dice2", "/assets/images/snake/2.png");
      self.loader.set("dice3", "/assets/images/snake/3.png");
      self.loader.set("dice4", "/assets/images/snake/4.png");
      self.loader.set("dice5", "/assets/images/snake/5.png");
      self.loader.set("dice6", "/assets/images/snake/6.png");
      self.loader.set("background", "/assets/images/snake/background.png");
      self.loader.set("background2", "/assets/images/snake/background2.png");
      self.loader.set("trap", "/assets/images/snake/trap.png");
      self.loader.set("flag", "/assets/images/snake/flag.png");
      self.loader.set("snake", "/assets/images/snake/snake.png");
      self.loader.set("snake2", "/assets/images/snake/snake2.png");
      self.loader.set("gem", "/assets/images/snake/gem.png");
      self.loader.set("gem2", "/assets/images/snake/gem2.png");
      self.loader.set("gem3", "/assets/images/snake/gem3.png");
      self.loader.set("ladder", "/assets/images/snake/ladder.png");
      self.loader.addSpin("island", "/assets/spin/bg.json");
      self.loader.addSpin("island2", "/assets/spin/bg2.json");
      self.loader.addSpin("player", "/assets/spin/boy.json");
      self.loader.addSpin("jungle", "/assets/spin/skeleton.json");

      self.app.loader.onProgress.add((loader, res) =>
        self.loader.show(loader.progress, res)
      );
      self.app.loader.onComplete.add((loader, res) => self.load(loader, res));
      self.app.loader.load();
    };

    self.load = function (loader, res) {
      if (self.app === null) return;
      self.app.sortableChildren = true;

      self.container = new PIXI.Container();
      self.container.sortableChildren = true;
      self.app.stage.addChild(self.container);

      self.width = self.app.screen.width;
      self.height = self.app.screen.height;

      //Add Lands
      self.objects["land_container"] = new PIXI.Container();

      this.landNumber = 80;
      let c = 2;
      for (var i = 1; i <= 10; i++) {
        for (var j = 0; j <= 8; j++) {
          let x = 55 + j * 60;
          let y = i * 60;

          let name = "land" + this.landNumber;

          let bg = "background2.png";

          if (this.landNumber % 2 === 0) {
            bg = "background.png";
          }

          self.objects[name] = new Sprite(bg, {
            x,
            y,
            width: 60,
            height: 60,
            anchor: true,
          });

          self.objects["text" + this.landNumber] = new Text(this.landNumber, {
            size: 32,
            family: "Tahoma",
            color: "#999",
          });
          self.objects[name].object.addChild(
            self.objects["text" + this.landNumber].object
          );
          self.objects[name].object.id = this.landNumber;

          // const f = new Filter['CRTFilter']();
          // self.objects[name].object.filters = [f];

          //Traps

          let lands = [];

          for (var e = 0; e <= 90; e++) {
            if (e % 2 === 0) {
              lands.push(e);
            }
          }

          let inLand = __.includes(lands, this.landNumber);

          let expect = [24, 28, 46, 62, 2, 0, 80, 54, 26, 42, 74, 22];

          let leave = __.includes(expect, this.landNumber);

          if (inLand && !leave) {
            self.objects["trap" + this.landNumber] = new Sprite("trap.png", {
              width: 70,
              height: 70,
              anchor: true,
            });
            self.objects["trap" + this.landNumber].object.alpha = 0.7;
            self.objects["text" + this.landNumber].object.visible = false;
            self.objects[name].object.addChild(
              self.objects["trap" + this.landNumber].object
            );
          }

          //Finish
          if (this.landNumber === 80) {
            self.objects["flag" + this.landNumber] = new Sprite("flag.png", {
              width: 250,
              height: 280,
              x: -5,
              y: -40,
              anchor: true,
            });
            const displacementSprite = PIXI.Sprite.from(
              "./assets/images/snake/displacement_map_repeat.jpg"
            );
            displacementSprite.texture.baseTexture.wrapMode =
              PIXI.WRAP_MODES.REPEAT;
            const displacementFilter = new PIXI.filters.DisplacementFilter(
              displacementSprite
            );
            displacementFilter.padding = 10;
            self.objects["text" + this.landNumber].object.visible = false;

            displacementSprite.position =
              self.objects["flag" + this.landNumber].object.position;
            self.container.addChild(displacementSprite);
            self.objects["flag" + this.landNumber].object.filters = [
              displacementFilter,
            ];
            self.objects["flag" + this.landNumber].animate((item, delta) => {
              displacementSprite.x++;
              if (displacementSprite.x > displacementSprite.width) {
                displacementSprite.x = 0;
              }
            });
            self.objects[name].object.addChild(
              self.objects["land" + this.landNumber].object,
              self.objects["flag" + this.landNumber].object
            );
          }

          self.objects["land_container"].addChild(self.objects[name].object);

          this.landNumber--;
        }
      }

      self.container.addChild(self.objects["land_container"]);

      //Add Snake's
      // Land 24
      var options = {
        x: 125,
        y: self.height - 80,
        width: 120,
        height: 120,
        anchor: true,
      };
      self.objects["snake1"] = new Sprite("snake.png", options);
      self.objects["snake1"].object.alpha = 0.9;

      // Land 28
      var options = {
        x: self.width - 200,
        y: self.height - 150,
        width: 120,
        height: 120,
        rotation: 0.8,
        anchor: true,
        rotation: -270,
      };
      self.objects["snake2"] = new Sprite("snake2.png", options);
      self.objects["snake2"].object.alpha = 0.9;

      // Land 46
      var options = {
        x: self.width - 220,
        y: 215,
        width: 120,
        height: 120,
        rotation: 1.1,
        anchor: true,
      };

      self.objects["snake3"] = new Sprite("snake2.png", options);
      self.objects["snake3"].object.alpha = 0.9;

      // Land 62
      var options = {
        x: 25,
        y: self.height / 4.1,
        width: 120,
        height: 120,
        rotation: 1.5,
        anchor: true,
      };

      self.objects["snake5"] = new Sprite("snake.png", options);
      self.objects["snake5"].object.alpha = 0.9;

      self.container.addChild(
        self.objects["snake1"].object,
        self.objects["snake2"].object,
        self.objects["snake3"].object,
        self.objects["snake5"].object
      );

      //Add Ladder
      var options = {
        x: self.width / 2 + 50,
        y: self.height - 80,
        width: 25,
        height: 180,
        rotation: 14.95,
        anchor: true,
      };
      self.objects["ladder"] = new Sprite("ladder.png", options);

      var options = {
        x: 115,
        y: self.height - 195,
        width: 25,
        height: 180,
        rotation: 0.8,
        anchor: true,
      };
      self.objects["ladder2"] = new Sprite("ladder.png", options);

      var options = {
        x: self.width - 130,
        y: 125,
        width: 25,
        height: 165,
        rotation: 11.8,
        anchor: true,
      };
      self.objects["ladder3"] = new Sprite("ladder.png", options);

      self.container.addChild(
        self.objects["ladder"].object,
        self.objects["ladder2"].object,
        self.objects["ladder3"].object
      );

      //Add Gems
      let lands = [11, 17, 31, 37, 59, 65];

      __.forEach(lands, (land) => {
        let add = self.addGem(land);

        self.container.addChild(add);
      });

      //Spin Background
      self.addIsland(res);

      //Spin Player
      self.addPlayer(res);

      //Add Dice
      self.addDice();
    };

    self.addGem = function (id) {
      var options = {
        x: self.objects["land" + id].object.x,
        y: self.objects["land" + id].object.y,
        width: 50,
        height: 50,
        anchor: true,
      };

      let gem = "gem";

      if (id === 17 || id === 59) gem = "gem2";

      if (id === 31 || id === 65) gem = "gem3";

      self.objects["gem" + id] = new Sprite(gem + ".png", options);
      self.objects["gem" + id].object.alpha = 0.8;

      self.objects["gem" + id].animate((item, delta) => {
        item.rotation += 0.01 * delta;
      });

      return self.objects["gem" + id].object;
    };

    self.addIsland = function (res) {
      const jungle = new Spine(res.jungle.spineData);
      jungle.sortChildren(3);
      jungle.y = -120;
      jungle.x = 300;
      jungle.state.setAnimation(0, "right-tree", true);

      //Spin
      const island = new Spine(res.island.spineData);
      var scale = 0.2;
      island.x = 50;
      island.y = self.height / 3.2;
      island.scale.x = island.scale.y = scale;
      island.sortChildren(3);
      island.zIndex = 1;
      island.alpha = 0.7;
      island.state.setAnimation(0, "animation", true);

      const island2 = new Spine(res.island2.spineData);
      var scale = 0.22;
      island2.x = 120;
      island2.y = self.height / 2.4;
      island2.scale.x = island2.scale.y = scale;
      island2.sortChildren(3);
      island2.zIndex = 1;
      island2.alpha = 0.8;
      island2.state.setAnimation(0, "animation", true);

      self.container.addChild(jungle, island, island2);
    };

    self.addPlayer = function (res) {
      self.objects["player"] = new Spine(res.player.spineData);
      self.objects["player"].sortChildren(3);
      self.objects["player"].zIndex = 22;
      self.objects["player"].scale.x = -0.1;
      self.objects["player"].scale.y = 0.1;
      self.objects["player"].y = 50;

      self.objects["land0"].object.addChild(self.objects["player"]);
      self.objects["player"].state.setAnimation(0, "idle B", true);
    };

    self.addDice = function () {
      self.dice = new Dice(self);
      self.dice.sprite.x = self.width / 2.3;
      self.dice.sprite.y = self.height / 2.3;
      self.dice.sprite.visible = false;
      self.container.addChild(self.dice.sprite);
    };

    self.showMessage = function (txt) {
      const message = new Message(txt, {
        x: self.width / 2,
        y: self.height / 2,
      });
      self.container.addChild(message.object);

      let done = false,
        count = 1;
      message.animate(1500, (item, delta) => {
        if (!done) {
          count -= 0.1;
          item.alpha = count;
          if (item.alpha < 0) {
            self.container.removeChild(message.object);
            done = true;
            message.stop();
          }
        }
      });
    };

    self.restart = function () {
      self.objects["player"].state.setAnimation(0, "idle B", true);
      self.objects["land0"].object.addChild(self.objects["player"]);
      self.currentLand = 0;
      self.engine.currentLand = 0;
      self.oldLand = 0;
      self.lost = false;
    };

    self.play = function (target) {
      self.dice.sprite.visible = true;

      // Roll Dice
      self.dice.Roll(target);

      self.event.on("start", () => {
        if (!self.started) self.playGame();
      });
    };

    self.playGame = function () {
      self.started = true;
      self.oldLand = self.currentLand;
      self.currentLand += self.dice.result;
      self.engine.currentLand =
        self.objects["land" + self.currentLand].object.id;

      playAudio("dice2.mp3");

      wait(1500).then(() => {
        wait(1500).then(() => {
          self.dice.sprite.visible = false;
          self.movePlayer();
        });
      });
    };

    self.movePlayer = function () {
      self.objects["player"].state.setAnimation(0, "start rolling", false);

      wait(1000).then(() => {
        let move = self.oldLand + 1;

        let moved = 0;

        var timer = setInterval(function () {
          if (self.currentLand >= 80) {
            self.objects["player"].state.setAnimation(0, "victory", false);
            playAudio("win.mp3");
            self.showMessage("Game Was Ended!");

            self.engine.init = true;
            self.engine.cashout();

            clearInterval(timer);

            wait(2000).then(() => {
              self.restart();
            });
          } else {
            if (moved === self.dice.result) {
              self.objects["player"].state.setAnimation(0, "idle A", true);
              clearInterval(timer);
              self.landEvent();
              self.started = false;
              self.engine.land =
                self.objects["land" + self.currentLand].object.id;
              self.engine.currentLand =
                self.objects["land" + self.currentLand].object.id;
              self.engine.lost = self.lost;
              self.engine.clicked();
              playAudio("dice.mp3");
            } else {
              //Remove player from the land
              self.objects["land" + move].object.removeChild(
                self.objects["player"]
              );

              self.objects["player"].state.setAnimation(0, "ollie", false);

              //Add player to the land
              self.objects["land" + move].object.addChild(
                self.objects["player"]
              );

              playAudio("dice.mp3");
            }
          }

          move++;
          moved++;
        }, 500);

        //Reset the position caused by ladder
        self.objects["player"].y = 50;
        self.objects["player"].x = 0;

        self.objects["score"] = new Text("1.01x", {
          color: "yellow",
          size: 15,
          y: -10,
        });
        self.objects["player"].addChild(self.objects["score"].object);

        let count = 5;
        self.objects["score"].animate((item, delta) => {
          count -= 0.1;
          item.zIndex = 999999;
          item.alpha = count;
          item.y -= 0.5 * delta;

          if (item.y < -50) {
            self.objects["player"].removeChild(item);
            self.objects["score"].stop();
          }
        });
      });
    };

    self.landEvent = function () {
      // Gems
      if (
        self.currentLand === 16 ||
        self.currentLand === 31 ||
        self.currentLand === 37 ||
        self.currentLand === 59
      ) {
        let moved = false;

        playAudio("win2.mp3");

        self.objects["gem" + self.currentLand].animate((item, delta) => {
          item.rotation = 0;

          if (!moved) {
            item.scale.x += 0.1 * delta;
            item.scale.y += 0.1 * delta;

            if (item.scale.x > 1.5) {
              self.objects["score"] = new Text("1.50x", { size: 25 });
              item.addChild(self.objects["score"].object);
              wait(1500).then(() => {
                if (
                  !__.isUndefined(
                    self.objects["gem" + self.currentLand] &&
                      !__.isUndefined(self.objects["score"])
                  )
                ) {
                  self.objects["gem" + self.currentLand].object.removeChild(
                    self.objects["score"].object
                  );
                  self.container.removeChild(
                    self.objects["gem" + self.currentLand].object
                  );
                }
              });
              moved = true;
            }
          } else {
            item.scale.x -= 0.01 * delta;
            item.scale.y -= 0.01 * delta;
          }
        });
      }

      // Ladder
      if (self.currentLand === 2) {
        self.currentLand = 22;
        self.objects["land22"].object.addChild(self.objects["player"]);
        playAudio("win.mp3");
      }

      // Ladder
      if (self.currentLand === 26) {
        self.currentLand = 42;
        self.objects["land42"].object.addChild(self.objects["player"]);
        playAudio("win.mp3");
      }

      // Ladder
      if (self.currentLand === 54) {
        self.currentLand = 74;
        self.objects["land74"].object.addChild(self.objects["player"]);
        playAudio("win.mp3");
      }

      // Traps, Snaks
      let danger = [24, 28, 46, 62];

      for (var e = 0; e <= 90; e++) {
        if (e % 2 === 0) {
          danger.push(e);
        }
      }

      var lost = __.includes(danger, self.currentLand);

      let expect = [24, 28, 46, 62, 2, 0, 80, 54, 26, 42, 74, 22];

      let leave = __.includes(expect, this.currentLand);

      if (lost && !leave) {
        self.showMessage("Lost !");
        self.objects["player"].state.setAnimation(0, "fall front", false);
        playAudio("lost.mp3");
        wait(2000).then(() => {
          self.restart();
        });
      }
    };
  }
  destroy() {
    this.app = null;
    this.ticker.stop();
    if (this.container !== null) this.container.destroy();
    // PIXI.Assets.unloadBundle('snack');
  }
}

export default Canvas;
