import React from "react";
import { InputGroup, Form, Button, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  isMobile,
  Event,
  wait,
  getDefaultBetAmount,
  isValidNumber,
  forceSatoshiFormat,
  isLogged,
  __,
  sendNotfication,
  toCurrencyFomatter,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  progressTime = null;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      message: null,
      started: false,
      amount: defaultBetAmount(this.props.coin),
      disabled: false,
      placed: false,
      formData: {
        time: 15,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.wrapperRef = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside2);
    this.props.gameCoin();
    engine.on("play", (data) => this.play(data));
    engine.on("cashout", (data) => this.cashout(data));
    engine.on("error", (data) => this.error(data));

    if (isMobile()) {
      this.setState({ mt: "mt-2" });
    }
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside2);
    Event.on("amount", (value) => {
      this.setState({ amount: value });
    });
  }

  play(data) {
    // let time = parseFloat(data.time);
    let time = parseFloat(this.state.formData.time);

    this.setState({
      disabled: false,
      placed: true,
      started: true,
    });
  }

  cashout() {
    clearInterval(this.progressTime);

    this.setState({
      placed: false,
      started: false,
      disabled: false,
    });
  }

  error() {}

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false, placed: false });

      if (this.state.started) {
        this.setState({ started: false });
      }
    }
  }

  handleClickOutside2(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      this.setState({ amount: value });
    }
  }

  setBet(type) {
    if (this._isMounted) {
      let { amount } = this.state;

      // Check User
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.placeBet(type);
    }
  }

  placeBet(type) {
    if (this._isMounted) {
      let { amount, engine } = this.state;
      let { time } = this.state.formData;

      wait(100).then(() => {
        this.setState({ disabled: true });
        engine.init = true;
        engine.time = time;
        engine.type = type;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.play();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  setAmount = (val) => {
    if (!this.state.disabled) {
      var am = val;
      this.setState({ amount: am });
      storage.setKey("lam", am);
    }
  };

  changeTime(e, value) {
    e.preventDefault();
    for (var i = 0; i < this.wrapperRef2.current.children.length; i++) {
      this.wrapperRef2.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    if (!this.state.started) {
      this.state.engine.time = value;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          time: value,
        },
      }));
    }
  }

  render() {
    let { disabled, amount } = this.state;
    return (
      <>
        <Row className={"mt-1"}>
          <Col xl={4} lg={6} md={6} sm={12}>
            <Form.Group className="mb-1">
              <InputGroup>
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={disabled}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                />
                <InputGroup.Text>
                  <RangeCredit set={this.setAmount} amount={amount} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col xl={3} lg={6} md={6} sm={12}>
            <Form.Group className={"mb-1"}>
              <InputGroup>
                <InputGroup.Text>
                  <i className="mdi mdi-clock font-20 text-white mr-1" />
                  Time
                </InputGroup.Text>
                <InputGroup.Text
                  ref={this.wrapperRef2}
                  className="timertrading"
                >
                  <button
                    onClick={(e) => {
                      this.changeTime(e, "15");
                    }}
                    className={"btn btn-s-4 btn-xs active"}
                  >
                    15s
                  </button>
                  <button
                    onClick={(e) => {
                      this.changeTime(e, "30");
                    }}
                    className={"btn btn-s-4 btn-xs"}
                  >
                    30s
                  </button>
                  <button
                    onClick={(e) => {
                      this.changeTime(e, "60");
                    }}
                    className={"btn btn-s-4 btn-xs"}
                  >
                    1m
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col xl={5} lg={12} md={12} sm={12} className="cryptotradingbuttons">
            <Form.Group className={"bg- text-center mb-0"}>
              <div className="text-center fw btn-group">
                <Button
                  variant={
                    "btn btn-md btn-dangers btn-round btn- px-5 shadow-none"
                  }
                  onClick={(e) => this.setBet("down")}
                  disabled={disabled}
                  type="submit"
                >
                  <div class="skew-x-[23deg] background-trading"></div>

                  <div class="inside-btn-trading">
                    Down
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="relative w-6 h-6"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                        fill="currentColor"
                        fill-opacity="0.75"
                      ></path>
                    </svg>
                  </div>
                </Button>
                <Button
                  variant={
                    "btn btn-md btn-successs-2 btn-round btn- px-5 mr-1 shadow-none"
                  }
                  onClick={(e) => this.setBet("up")}
                  disabled={disabled}
                  type="submit"
                >
                  <div class="skew-x-[23deg] background-trading"></div>

                  <div class="inside-btn-trading">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="rotate-180 relative w-6 h-6"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      style={{ transform: "rotate(180deg)" }}
                    >
                      <path
                        d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                        fill="currentColor"
                        fill-opacity="0.75"
                      ></path>
                    </svg>
                    Up
                  </div>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
