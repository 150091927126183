import React from "react";
import {connect} from "react-redux";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Scroll from "react-scroll";
import { __, isMobile, wait, Event, requestFullScreen, decode, encode, getUID } from "../../Helper";
import Bets from "../Pages/Parts/Home/Bets";
import Frame from "./Frame";
import Loading from "../Games/Loading";
import socket from "../../Socket";
import C from "../../Constant";
import Rakeback from "../Pages/Parts/Home/Rakeback";

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Sport extends React.Component {
    _Mounted = true;
    constructor(props) {
        super(props);
        this.state = {
            game: null,
            gameName: '',
            gameUuid: props.history.location.pathname.replace('/sports/', ''),
            padding: 'p-1',
            provider: null,
            houseEdge: null,
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });

        window.addEventListener('resize', this.handleResize);

        SC.scrollTo('slotElement', {
            delay: 3,
            smooth: true,
            containerId: 'st'
        });
        this.getSport();
        socket.emit(C.GET_HOUSEEDGE, encode({game: 'sports'}));
        socket.on(C.GET_HOUSEEDGE, data => {
            let {edge} = decode(data);
            this.setState({houseEdge: edge})
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        socket.off(C.GET_HOUSEEDGE);
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
        }
        else {
            this.setState({ col: 'col-xl-11' });
        }
        if (isMobile()) {
            this.setState({ padding: 'p-1' });
        }
    }

    fullscreen = () => {
        var elem = document.querySelector(".fullScreen");
        if (elem !== null)
            requestFullScreen(elem);
    }

    getSport() {
        let selectedSport = this.props.sportsbooks.filter(sport => sport.uuid == this.state.gameUuid)[0];
        this.setState({gameName: selectedSport.name, provider: selectedSport.providerId, game: selectedSport})
    }

    render() {
        let { col, padding, game } = this.state;
        const { t } = this.props;
        var title = 'Sportbook, sports betting | Crypto Casino Games';
        title = __.startCase(title);
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href={"/" + game} />
                </Helmet>
                <div>
                    {this._Mounted ?
                        <Row>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>
                                <Card className="mb-0">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <p className="mt-2 mb-0 text-white text-upper d-flex">
                                                    <span className="badge bg-soft-secondary p-2 font-12">{this.state.gameName}</span>
                                                    <span className="badge bg-soft-success p-2 font-12 ml-2">{this.state.provider}</span>
                                                    <span className="badge bg-soft-danger p-2 font-12 ml-2">House Edge: {this.state.houseEdge}%</span>
                                                </p>
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <button className="btn bg-cs2 btn-lg py-0 mt-1 px-2" onClick={this.fullscreen}>
                                                    <i className="mdi mdi-fullscreen" />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'bg-dice-options p-1 -staticSnow'} id="roll-panel">
                                        <Frame game={game}/>
                                    </Card.Body>
                                </Card>

                                {/* QUEUE */}
                                <Card className="mb-5 lastbetscard">
                                    <Card.Body className="p-1 mt-4 game-last-bets card-body">
                                        <div className="p-2 mt-3 home-last-bets-wrapper">
                                            <Stack direction="horizontal" gap={3} className="mb-3">
                                                <div className="d-flex align-items-center">
                                                    <img className='img-fluid home-page-icons' src="/assets/images/icons/last-bets.svg" />
                                                    <p className="m-0 home-part-t"> {t('last_bets')}</p>
                                                </div>
                                            </Stack>
                                            <Bets t={t} />
                                        </div>
                                    </Card.Body>
                                    <Rakeback t={t} />
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    sportsbooks: state.items.sportsbooks,
});

export default connect(mapStateToProps, { })(Sport);
