import React from "react";
import {
  isMobile,
  isTablet,
  defaultBetAmount,
  wait,
  playAudio,
} from "../../../Helper";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      amount: defaultBetAmount(this.props.coin),
      cards: [],
      dealCards: [],
      winning: false,
      c: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { engine } = this.state;

    engine.on("play", (data) => this.play(data)); // from engine
    engine.on("busted", (data) => this.busted(data)); // from engine

    engine.on("place_deal", () => this.placeDeal()); // from bet

    engine.on("card", (data) => this.setCards(data)); // from canvas
    engine.on("deal_card", (data) => this.setDealCards(data)); // from canvas

    let canvas = new Canvas(engine);
    canvas.init();

    this.setState({ canvas: canvas });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  setCards(data) {
    this.setState((state) => ({ cards: [data, ...state.cards] }));
  }

  setDealCards(data) {
    this.setState((state) => ({ dealCards: [data, ...state.dealCards] }));
  }

  busted(data) {
    let { scoreName, profit } = data;
    let { engine } = this.state;

    if (scoreName !== 'Nothing') playAudio("win.mp3");
    else playAudio("lost.mp3");

    // Update Table
    engine.emit("update_table", scoreName);
  }

  play = (data) => {
    if (this._isMounted) {
      console.log(data.target)
      this.state.canvas.play(data.target);
    }
  };

  placeDeal() {
    if (this._isMounted) {
      wait(700).then(() => {
        this.state.canvas.deal();
      });
    }
  }

  render() {
    return (
      <div className="poker_container">
        <div className="text-center" id="">
          <div id="loading" className="ycenter" />
          <canvas id="game" />
        </div>
      </div>
    );
  }
}

export default Game;
