import * as PIXI from "pixi.js";
import * as Filter from "pixi-filters";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/*
    * Register Pixi Plugin for GSAP
*/
gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const Effect = {};

/** 
    *  Light On/Off Object
*/
Effect.Light = function (object, time) {
    let stop = false;

    const start = () => {
        if (stop) return;
        gsap.to(object, { duration: .5, pixi: { brightness: 3, combineCMF: true } });
        wait(500).then(() => {
            min()
        })
    }

    const min = () => {
        gsap.to(object, { duration: .5, pixi: { brightness: 1, combineCMF: true } });
        wait(500).then(() => {
            start()
        })
    }

    start()

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    *  Heartbeat Animate Object
*/
Effect.HeartBeat = function (object, time) {
    let stop = false;

    const start = () => {
        if (stop) return;
        gsap.to(object.scale, { x: object.scale.x + .05, y: object.scale.y + .05 });
        wait(500).then(() => {
            min()
        })
    }

    const min = () => {
        gsap.to(object.scale, { x: object.scale.x - .05, y: object.scale.y - .05 });
        wait(500).then(() => {
            start()
        })
    }

    start()

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    * Animate Flash object
*/
Effect.Flash = function (object, time) {
    let stop = false;

    const up = () => {
        gsap.to(object, { duration: 1, pixi: { alpha: 1 } });
        wait(700).then(() => {
            down()
        })
    }
    const down = () => {
        if (stop) return;
        gsap.to(object, { duration: 1, pixi: { alpha: .1 } });
        wait(700).then(() => {
            up()
        })
    }

    up();

    wait(time * 1000).then(() => {
        stop = true;
    })
}

/** 
    * Animate Fade In Object
*/
Effect.FadeIn = function (object) {
    object.alpha = 0;
    gsap.to(object, { duration: 2, pixi: { alpha: 1 } });
}

/** 
    * Animate Fade Out Object
*/
Effect.FadeOut = function (object) {
    object.alpha = 1;
    gsap.to(object, { duration: 2, pixi: { alpha: 0 } });
}

/** 
    * Animate Rotate  Object
*/
Effect.Angle = function (object, value) {
    gsap.to(object, { duration: .5, pixi: { angle: value } });
}

/** 
    * Animate Scale  Object
*/
Effect.Scale = function (object, value) {
    gsap.to(object, { duration: .5, pixi: { scale: value } });
}

/** 
    * Animate Move In Object
*/
Effect.MoveIn = function (object, x, y) {
    gsap.to(object, { duration: .5, pixi: { x: x, y: y } });
}

/** 
    * Add Filter to Object
    ** Etc: KawaseBlurFilter, GlowFilter, DropShadowFilter, BulgePinchFilter, BloomFilter , AsciiFilter, AdvancedBloomFilter , OutlineFilter, OldFilmFilter, SimpleLightmapFilter 
*/
Effect.Filter = function (object, filter) {
    const f = new Filter[filter];
    object.filters = [f]
}

/** 
    * Clear Filter
*/
Effect.Clear = function (object) {
    object.filters = []
}

/** 
    *  Modal 
*/
Effect.Modal = function (object, str) {
    var sprite = new PIXI.Sprite.from('static/images/popup.png');
    sprite.alpha = 0
    sprite.width = 200;
    sprite.height = 150;
    sprite.scale = .5
    sprite.x = 100
    sprite.y = 50
    let style = new PIXI.TextStyle({ fontFamily: 'Inter', fontSize: 25, fontWeight: 700, fill: [0x000000] });
    let text = new PIXI.Text(str, style);
    text.y = 90
    text.x = 110
    sprite.addChild(text)
    object.addChild(sprite);
    gsap.to(sprite, { duration: .5, pixi: { alpha: 1, scale: .7 } });

    wait(2000).then(() => {
        gsap.to(sprite, { duration: .5, pixi: { alpha: 0, scale: .5 } });
        wait(500).then(() => {
            object.removeChild(sprite)
        })
    })
}

export default Effect;
