import React from "react";
import { Wheel } from "react-custom-roulette";
import { __ } from "../../../../Helper";

const BLUE = "#6c65f2";
const YELLOW = "#b1aa25";
const GREEN = "#2aff44";
const RED = "#ff2a2a";

class Wheeler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsData: [
        {
          option: "0",
          style: { backgroundColor: BLUE, textColor: "white" },
        },
      ],
      risk: "medium",
      rows: 8,
      prizeNumber: 0,
      outerBorderColor: "#1a2130",
      outerBorderWidth: 2,
      innerRadius: 90,
      radiusLineColor: "#1a2130",
      innerBorderColor: "#1a2130",
      innerBorderWidth: 2,
      textDistance: 85,
      startingOptionIndex: 0,
      spinDuration: 0.5,
      radiusLineWidth: 10,
      fontSize: 0,
      pointerProps: {
        src: "/assets/images/arrow.gif",
        style: { transform: "rotateZ(140deg)", width: "40px", right: "10px" },
      },
    };
  }

  componentDidMount() {
    this.setupWheel();
    const { engine } = this.props;

    engine.on("play", (data) => this.play(data));
    engine.on("wheel_segment", (data) => this.handleSegment(data));
    engine.on("wheel_risk", (data) => this.handleRisk(data));
  }

  play(data) {
    if (!this.state.mustSpin) {
      var newPrizeNumber = this.getSectionByPayout(data.target);
      this.setState({
        mustSpin: true,
        endSpin: false,
        prizeNumber: newPrizeNumber,
      });
    }
  }

  getSectionByPayout(payout) {
    let data = this.state.rowsData;

    var find;

    payout = parseFloat(payout);

    if (this.state.risk === "medium") {
      if (payout === 1.2) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === BLUE;
        });
      } else if (payout === 1.5) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === YELLOW;
        });
      } else if (payout === 1.8) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === GREEN;
        });
      } else if (payout === 0.0) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === RED;
        });
      }
    } else if (this.state.risk === "high") {
      if (payout === 1.8) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === BLUE;
        });
      } else if (payout === 2.0) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === YELLOW;
        });
      } else if (payout === 2.5) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === GREEN;
        });
      } else if (payout === 0.0) {
        find = __.filter(data, function (o) {
          return o.style.backgroundColor === RED;
        });
      }
    }

    let ids = [];
    _.forEach(find, (value) => {
      ids.push(value.style.id);
    });

    return __.sample(ids);
  }

  setupWheel() {
    let arr = [];
    var rows = this.state.rows;
    for (var i = 0; i < rows; i++) {
      let color = BLUE;

      if (rows === 8) {
        color = i === 1 ? RED : color;
        color = i === 3 ? GREEN : color;
        color = i === 6 ? YELLOW : color;
        if (this.state.risk === "high") {
          color = i === 5 ? RED : color;
          color = i === 8 ? RED : color;
        }
      }

      if (rows === 16) {
        color = i === 1 ? RED : color;
        color = i === 3 ? GREEN : color;
        color = i === 6 ? YELLOW : color;
        color = i === 10 ? RED : color;
        if (this.state.risk === "high") {
          color = i === 5 ? RED : color;
          color = i === 8 ? RED : color;
          color = i === 12 ? RED : color;
          color = i === 14 ? RED : color;
        }
      }

      if (rows === 25) {
        color = i === 1 ? RED : color;
        color = i === 3 ? GREEN : color;
        color = i === 6 ? YELLOW : color;
        color = i === 10 ? RED : color;
        color = i === 22 ? RED : color;
        if (this.state.risk === "high") {
          color = i === 5 ? RED : color;
          color = i === 8 ? RED : color;
          color = i === 12 ? RED : color;
          color = i === 14 ? RED : color;
          color = i === 16 ? RED : color;
          color = i === 18 ? RED : color;
          color = i === 20 ? RED : color;
          color = i === 22 ? RED : color;
        }
      }

      if (rows === 35) {
        color = i === 1 ? RED : color;
        color = i === 3 ? GREEN : color;
        color = i === 6 ? YELLOW : color;
        color = i === 10 ? RED : color;
        color = i === 28 ? RED : color;
        color = i === 22 ? RED : color;
        if (this.state.risk === "high") {
          color = i === 5 ? RED : color;
          color = i === 8 ? RED : color;
          color = i === 12 ? RED : color;
          color = i === 14 ? RED : color;
          color = i === 16 ? RED : color;
          color = i === 18 ? RED : color;
          color = i === 20 ? RED : color;
          color = i === 24 ? RED : color;
          color = i === 26 ? RED : color;
          color = i === 30 ? RED : color;
          color = i === 32 ? RED : color;
        }
      }

      arr.push({
        style: { id: i, backgroundColor: color, textColor: "white" },
      });
    }

    this.setState({ rowsData: arr });
  }

  handleRisk = (data) => {
    var val = _.toLower(data.risk);
    this.setState({ risk: val, rowsData: this.state.rowsData });
    this.setupWheel();
  };

  handleSegment(data) {
    var val = parseFloat(data.segment);
    this.setState({ rows: val, rowsData: this.state.rowsData });
    this.setupWheel();
  }

  render() {
    let trophy;

    let dangers = dangerArea(this.state.rows, this.state.risk);

    if (!__.includes(dangers, this.state.prizeNumber)) {
      trophy = <img className={"img-fluid"} src="/assets/images/t2.gif" />;
    }

    return (
      <>
        <div className="wheeler">
          <Wheel
            mustStartSpinning={this.state.mustSpin}
            prizeNumber={this.state.prizeNumber}
            radiusLineWidth={this.state.radiusLineWidth}
            outerBorderColor={this.state.outerBorderColor}
            outerBorderWidth={this.state.outerBorderWidth}
            innerRadius={this.state.innerRadius}
            radiusLineColor={this.state.radiusLineColor}
            innerBorderColor={this.state.innerBorderColor}
            innerBorderWidth={this.state.innerBorderWidth}
            textDistance={this.state.textDistance}
            fontSize={this.state.fontSize}
            pointerProps={this.state.pointerProps}
            perpendicularText={false}
            disableInitialAnimation={true}
            startingOptionIndex={this.state.startingOptionIndex}
            spinDuration={this.state.spinDuration}
            data={this.state.rowsData}
            onStopSpinning={() => {
              this.setState({ mustSpin: false, endSpin: true });
            }}
          />
          <div
            className="bonus"
            style={{ display: this.state.endSpin ? "block" : "none" }}
          >
            {trophy}
          </div>
        </div>
      </>
    );
  }
}

function dangerArea(segment, risk) {
  var danger = [];
  if (segment === 8) {
    if (risk === "high") {
      danger = [1, 5, 7];
    } else {
      danger = [1];
    }
  } else if (segment === 16) {
    if (risk === "high") {
      danger = [1, 8, 10, 6, 12, 14];
    } else {
      danger = [1, 10];
    }
  } else if (segment === 25) {
    if (risk === "high") {
      danger = [1, 8, 6, 10, 12, 14, 16, 18, 20, 22];
    } else {
      danger = [1, 10, 22];
    }
  } else if (segment === 35) {
    if (risk === "high") {
      danger = [1, 8, 10, 6, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];
    } else {
      danger = [1, 10, 28, 22];
    }
  }
  return danger;
}

export default Wheeler;
