import React from "react";
import Menu from "./Menu";
import { Event } from "../../../../Helper";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    Event.on("toggle_sidebar", () => {
      this.setState({ show: !this.state.show });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Menu t={t} type={this.state.show ? "big" : "min"} />
      </>
    );
  }
}

export default SideBar;
