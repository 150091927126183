class Player {
  constructor() {
    this.winStats = [
      { name: "Royal Flush", count: 0, payout: 250 },
      { name: "Straight Flush", count: 0, payout: 50 },
      { name: "4 of a kind", count: 0, payout: 22 },
      { name: "Full House", count: 0, payout: 9 },
      { name: "Flush", count: 0, payout: 6 },
      { name: "Straight", count: 0, payout: 4 },
      { name: "3 of a kind", count: 0, payout: 3 },
      { name: "2 pair", count: 0, payout: 2 },
      { name: "Jacks or Better", count: 0, payout: 1 },
      { name: "Nothing", count: 0, payout: 0 },
    ];
  }
}

export default Player;
