import React from 'react'
import {Container, Form, Dropdown, Row, Col, InputGroup, Stack} from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';
import i18n from 'i18next';
import User from "./../User";
import Credit from "./../Credit";
import CreditMobile from "./../Credit/Mobile";
import Logo from "./../Logo";
import Chat from "./../Chat";
import RealTime from "./../RealTime";
import MobileMenu from "./../Menu/MobileMenu";
import Notifications from "./../Notification";
import {isMobile, Event, __} from "../../../../Helper";
import { connect } from "react-redux";
import { searchBar } from "../../../../actions/searchGame";
import Luckyspin from "../../Auth/Luckyspin";
import LoginBonus from "../../Auth/LoginBonus";
import Searchbar from './Searchbar';
import Translation from "../Translation";


class Header extends React.Component {  
    constructor(props){
        super(props);
        this.state = {
            header: !isMobile() ? "desktop" : "mobile",
            show: true
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        // mapDispatchToProps();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        if(!isMobile()){
            this.setState({ header: "desktop" });
        }
        else {
            this.setState({ header: "mobile" });
        }
    }

    searchChange = (e) => {
        this.props.searchBar(e.target.value);
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Perform the action you want when Enter key is pressed
            // For example, initiate the search using the searchChange function
            this.props.searchBar(e.target.value);
        }
    }
    
    render() {
        const { t } = this.props;
        return(<>
            {this.state.header == "desktop" ?
                <div className="topbar" id={'topbar'}>
                    <RealTime t={t} />
                    <Container id='header_bar' fluid >
                        <Row className="flex-nowrap">
                            <Col md="4" sm="4" className="flex-shrink-1">
                                <Stack direction='horizontal' className='justify-content-between gap-4'>
                                    <Logo {...this.props} />
                                    <Searchbar t={t} />
                                </Stack>
                            </Col>
                            <Col md="4" sm="4">
                                <Credit t={t} />
                            </Col>
                            <Col md="4" sm="4" className='user-header-third'>
                                <Stack direction='horizontal'>
                                    <div className='ms-auto'>
                                        <Stack direction='horizontal' className='user-header-stack-right'>
                                            <Luckyspin t={t} />
                                            <Translation/>
                                            <LoginBonus t={t} />
                                            <Chat t={t} />
                                            <Notifications t={t} />
                                            <User t={t}/>
                                            {/* <Translation /> */}
                                        </Stack>
                                    </div>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>    
                </div>
            :
                <div className="topbar" id={'topbar'}>
                    <Container id='mb_header_bar' fluid >
                        <Row>
                            <Col className='p-0 col-sm-auto header-m-auto-wa'>
                                <Stack direction='horizontal' className='header-mobile-menu-wrapper'>
                                    <MobileMenu t={t}/>
                                    <Logo />
                                </Stack>
                            </Col>
                            <Col className='p-0 col d-flex justify-content-evenly'>
                                <RealTime t={t} />
                                <CreditMobile t={t} />
                                <Luckyspin t={t} />
                            </Col>
                            <Col className='p-0 col-sm-auto header-m-auto-wa'>
                                <Stack direction='horizontal'>
                                    <div className='ms-auto'>
                                        <Stack direction='horizontal' className='user-header-stack-right'>
                                            <User t={t}/>
                                            <Chat t={t} />
                                        </Stack>
                                    </div>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
            </>
        )
    }
}

// export default Header;

export default connect(null, { searchBar })(Header);
