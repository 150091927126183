export const COIN = 'COIN';
export const PLAY = 'PLAY';
export const ROWS = 'ROWS';
export const RISK = 'RISK';
export const CHART_COIN = 'CHART_COIN';
export const CHAT_NAME = 'CHAT_NAME';
export const CHAT_COUNTRY = 'CHAT_COUNTRY';
export const CHAT_GIF = 'CHAT_GIF';
export const BANKROLL = 'BANKROLL';
export const TARGET_USER = 'TARGET_USER';
export const HIDE_BET = 'HIDE_BET';
export const SET_TARGET = 'SET_TARGET';
export const JOINED_PLAYERS_DATA = 'JOINED_PLAYERS_DATA';
export const SET_HEADS_OR_TAILS_RESULT = 'SET_HEADS_OR_TAILS_RESULT';
export const SET_CLASSIC_DICE_RESULT = 'SET_CLASSIC_DICE_RESULT';
export const CRASH_WINNER_TEXT = 'CRASH_WINNER_TEXT';
export const CRASH_IM_IN_GAME = 'CRASH_IM_IN_GAME';
export const CRASH_AMOUNT_COIN_GAME = 'CRASH_AMOUNT_COIN_GAME';
export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const GET_WALLET = 'GET_WALLET';
export const GET_BET_AMOUNT = 'GET_BET_AMOUNT';
export const NUMBERS_OF_LANDS = 'NUMBERS_OF_LANDS';
export const SET_MODAL = 'SET_MODAL';
export const FORCE_MODAL = 'FORCE_MODAL';
export const SET_BET = 'SET_BET';
export const SHOW_SINGLE_GAME_DETAIL = 'SHOW_SINGLE_GAME_DETAIL';
export const SIDEBAR_DISPLAY = 'SIDEBAR_DISPLAY';
export const SET_BOMB_WIRE = 'SET_BOMB_WIRE';
export const CURRENT_CREDIT = 'CURRENT_CREDIT';
export const GET_AUTH = 'GET_AUTH';
export const CLASSIC_DICE_SET_RANGE = 'CLASSIC_DICE_SET_RANGE';
export const CLASSIC_DICE_SET_CHANCE = 'CLASSIC_DICE_SET_CHANCE';
export const CLASSIC_DICE_SET_TYPE = 'CLASSIC_DICE_SET_TYPE';
export const ROULETTE_NUMBER = 'ROULETTE_NUMBER';
export const SET_PLAY_WHEEL = 'SET_PLAY_WHEEL';
export const SET_BUSTED_WHEEL = 'SET_BUSTED_WHEEL';
export const SET_VIDEO_CARD_DEAL = 'SET_VIDEO_CARD_DEAL';
export const SET_SLOTS = 'SET_SLOTS';
export const SET_LIVE_SLOTS = 'SET_LIVE_SLOTS';
export const SEARCH_GAME = 'SEARCH_GAME';
export const SET_SPORTS_BOOKS = 'SET_SPORTS_BOOKS';
export const CHAT_DISPLAY = 'CHAT_DISPLAY';
export const SET_IP_ADDRESS = 'SET_IP_ADDRESS';
export const SET_IP_DETAILS = 'SET_IP_DETAILS';
export const SET_PROVIDERS = 'SET_PROVIDERS';