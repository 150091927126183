import React from "react";
import { __, Event, isMobile, isTablet, playAudio } from "../../../Helper";
import Canvas from "./Canvas";

class Game extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      canvas: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    engine.on("play", (data) => this.play(data));

    var canvas = new Canvas(engine);
    canvas.init();

    this.setState({ canvas: canvas });

    Event.on("change_rows", (value) => {
      if (!canvas.started) canvas.changeRows(value);
    });

    Event.on("change_risk", (value) => {
      if (!canvas.started) canvas.changeRisk(value);
    });

    if (isMobile() || isTablet()) {
      let c = document.getElementById("game");
      c.style.width = "100%";
      c.style.height = "100%";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.canvas !== null) {
      this.state.canvas.destroy();
      document.getElementById("game").remove();
    }
  }

  play(data) {
    if (this._isMounted) {
      let target = __.toNumber(data.target);
      this.state.canvas.play(target);
    }
  }

  render() {
    return (
      <>
        <div className="text-center animated fadeIn">
          <div id="loading" className="ycenter" />
          <canvas id="game" />
        </div>
      </>
    );
  }
}

export default Game;
