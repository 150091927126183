import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import C from "../../Constant";
import storage from "../../Storage";
import socket from "../../Socket";
import { Event, decode, encode, __, sendNotfication } from "../../Helper";

export default class VerEmailResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: storage.getKey('name') ?storage.getKey('name'):'there',
            times: 5,
            status: 'not been verified',
            isVerified: false,
        };
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let token = this.props.params.pathname;
        token = token.replace('/verify-email/', '');
        socket.emit(C.VERIFY_EMAIL_TOKEN, encode({ token: token }));
        socket.on(C.VERIFY_EMAIL_TOKEN, data => this.verify(decode(data)));
        
    }

    componentWillUnmount() {
        socket.off(C.VERIFY_EMAIL_TOKEN);
    }

    verify(data) {
        let msg = '';
        if (data.success) {
            if (data.msg == 'AlreadyUpdated') {
                msg = "Your email address has already been verified.";
                this.setState({isVerified: true, status: 'already been verified'})
                // const timer = setInterval(() => {
                //     this.setState({times: this.state.times - 1});
                //     if (this.state.times - 1 < 0) {
                //         clearInterval(timer);
                //     }
                // }, 1000);
            } else {
                msg = "Your email address has been verified.";
                this.setState({isVerified: true, status: ' been verified'})
            }
            Event.emit('user_email_verified');
            sendNotfication(msg, 'danger','bottom-left');
        } else {
            if (data.msg == 'TokenExpiredError') {
                msg = "Your token was expired."
            } else if (data.msg == 'JsonWebTokenError') {
                msg = "Your token is invalid. Please check your link again."
            } else if (data.msg == 'NotSameUserId') {
                msg = "You are using other's verify link. Please use your own link."
            } else if (data.msg == 'InvalidUser') {
                msg = "You are invalid user."
            } else if (data.msg == 'ServerError') {
                msg = "There is some issues on our server. Please try it after several mins."
            } else if (data.msg == 'InvalidUser') {
                msg = "There is some issues on our server. Please try it after several mins."
            } else {
                msg = "There is some issues on our server. Please try it after several mins. "
            }
            sendNotfication(msg, 'danger','bottom-left');
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Verify your email | Crypto Casino Games</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href="/about-us" />
                </Helmet>
                <div>
                    <Card>
                        <Card.Body>
                            <ResultTemplate data = {this.state}/>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}

const ResultTemplate = (props) => {
    return (
        <>
            <table>
                <tbody>
                <tr>
                    <td style={{textAlign: 'start'}}>
                    <div>
                        <div>
                        <div style={{backgroundColor:'#f8f8f8'}}>
                            <div>
                            <div>
                                <div>
                                <a href="https://www.betsmixer.com" target="_blank">
                                    <div>
                                    <img style={{width:'100%'}} src="https://app.betsmixer.com/banner_email.jpg" />
                                    </div>
                                </a>
                                <div style={{color:'#555555',fontFamily:'arial, helvetica neue, helvetica, sans-serif',padding:'25px 15px 25px 15px'}}>
                                    <div>
                                    <p style={{lineHeight:1.2,margin:0}}>
                                        <span style={{fontSize:18}}>
                                        <strong style={{lineHeight:'inherit'}}>Hi, {props.data.name}!</strong>
                                        </span>
                                    </p>
                                    <p style={{fontSize:14,lineHeight:1.2,margin:0,wordBreak:'break-word'}}>&nbsp;</p>
                                    <p style={{lineHeight:1.2,margin:0}}>
                                        <span style={{fontSize:14}}>
                                        You Email address has {props.data.status}! 
                                        {/* {
                                            props.data.isVerified? 
                                            <span style={{display:'block',marginTop:5}}>
                                                Auto jump to the home page after <span id="time">{props.data.times}</span> seconds.
                                            </span>
                                            :
                                            null
                                        } */}
                                        </span>
                                    </p>
                                    </div>
                                </div>
                                <div style={{padding:10}}>
                                    <a href="https://www.betsmixer.com" style={{background:'#edbb27',borderColor:'#edbb27',borderRadius:36,borderStyle:'solid',borderWidth:'7px 35px 7px 35px',color:'#ffffff',fontFamily:'arial, helvetica neue, helvetica, sans-serif',textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="daria-goto-anchor" target="_blank" rel="noopener noreferrer">
                                    <span>
                                        <span style={{fontSize:16,lineHeight:2,wordBreak:'break-word'}}>JUMP NOW</span>
                                    </span>
                                    </a>
                                </div>
                                <div style={{fontFamily:'arial, helvetica neue, helvetica, sans-serif',lineHeight:1.2,padding:10}}>
                                    <div>
                                    <p style={{fontSize:14,lineHeight:1.2,margin:0}}>- Your BetsMixer Team</p>
                                    </div>
                                </div>
                                &nbsp; &nbsp;
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img align="center" alt="Image" border="0" title="Image" width="275" style={{maxWidth:275}} src="https://app.betsmixer.com/betsmixer-g.png" />
                                </div>
                                &nbsp;
                                <div style={{padding:10}}>
                                    <div>
                                    <p style={{margin:0,textAlign:'center'}}>&#169; 2023 BetsMixer.com</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </>

    );
  };
