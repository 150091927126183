import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { __, decode, encode, getElement, wait, Event, isMobile, sendNotfication, forceSatoshiFormat, fixDate } from "../../../Helper";
import isLogged from "../../../Auth";
import socket from "../../../Socket";
import C from "../../../Constant";
import Deposit from "../../Components/User/Wallet/Deposit";
import { userUID } from "../../../Auth";
import FreeCredit from "../../Components/User/FreeCredit";

export default class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time_differ: 0,
            offerwall_show: false,
            padding: '',
            headerText: '',
            paddingHeader: '',
            baseURL: "https://wall.lootably.com/?placementID=cligjs0lq03j8012t1m5nhiul&sid=",
            deposit_show: false,
        };
        this.handleClose = this.handleClose.bind(this);
        this.showArchive = this.showArchive.bind(this);
    }
    componentDidMount() {
        this.setState({ baseURL: this.state.baseURL + userUID() })
        if (isMobile()) {
            this.setState({ padding: 'p-1', paddingHeader: 'px-2' })
        }
        // socket.emit(C.REGISTER_TIME_DIFFER);
        // socket.on(C.REGISTER_TIME_DIFFER, (data) => {

        //     if (!__.isUndefined(decode(data))) {
        //         this.setState({ time_differ: decode(data) });
        //         // Use an arrow function to automatically bind to the component's context
        //         const intervalId = setInterval(() => {
        //             if (this.state.time_differ <= 0) {
        //                 clearInterval(intervalId);
        //             }
        //             else this.updateTime();
        //         }, 1000);
        //     }
        // })

        socket.on(C.LOOTABLY_POSTBACK, data => {
            let result = decode(data);
            if(result.success) {
              sendNotfication(result.message, 'success', 'bottom-left');
            } else {
              sendNotfication(result.message, 'info', 'bottom-left');
            }
        })
    }
    updateTime() {
        let t = this.state.time_differ - 1;
        this.setState({ time_differ: t });
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        socket.off(C.REGISTER_TIME_DIFFER);
        socket.off(C.LOOTABLY_POSTBACK);
    }
    handleClose() {
        this.setState({ offerwall_show: false, deposit_show: false })
    }
    showArchive() {
        this.setState({ headerText: 'Back' });
    }
    render() {
        const { t } = this.props;
        let { time_differ } = this.state;
        return (
            <>
                <Helmet>
                    <title>Promotion - BetsMixer.com</title>
                    <meta
                        name="description"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <meta
                        name="og:title"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <meta
                        name="og:description"
                        content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
                    />
                    <link rel="canonical" href="/promotion" />
                </Helmet>
                <div className="container containerpromotion">
                    {
                        time_differ > 0 ?
                            <div className="row rounded rounded-promotion time_differ" style={{ backgroundImage: 'url("/assets/images/promo/promoMainLimit.jpg")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', marginBottom: '22px' }}>
                                <div className="col-7 mt-3 leftpromo" style={{ paddingLeft: 0 }}>
                                    <h3 className="text-white my-3 mx-4 px-4">{t('first_deposit_bonus')}</h3>
                                    <div className="d-flex align-items-center my-3" style={{ backgroundImage: 'linear-gradient(to right, rgb(98 14 172), rgba(87, 143, 204, 0))' }}>
                                        <h1 className="text-warning" style={{ marginLeft: '3rem' }}>20%</h1>
                                        <h2 className="text-white" style={{ fontSize: '33px' }}>&nbsp;{t('rakeback')}&nbsp;</h2>
                                        <h4 className="text-warning">+ {t('level')} 5</h4>
                                    </div>
                                    <h6 className="mx-4 px-4 text-white">*{t('special_offer_for_newly_registered_players')}</h6>
                                    <h6 className="mx-4 px-4 text-white">{t('deposit_in_the_next')}  <span className="text-warning">15 {t('minutes_s')}</span> {t('and_get')} <span className="text-warning">20% {t('rakeback_s')}</span></h6>
                                </div>
                                <div className="col-5 text-center mt-3 rightpromo">
                                    <h5 className="text-white my-3">{t('limited_time_welcome_bonus')}</h5>
                                    <div className="d-flex text-white align-items-center justify-content-center">
                                        <div className="px-4 rounded timerpromo" style={{ backgroundColor: 'rgba(68,51,119,255)', opacity: 0.8 }}>
                                            <h6 style={{ fontSize: '9px', marginTop: '1rem' }}>{t('minute')}</h6>
                                            <h1>{Math.floor(this.state.time_differ / 60)}</h1>
                                        </div>
                                        <h2 className="px-2">:</h2>
                                        <div className="px-4 rounded timerpromo" style={{ backgroundColor: 'rgba(68,51,119,255)', opacity: 0.8 }}>
                                            <h6 style={{ fontSize: '9px', marginTop: '1rem' }}>{t('seconds')}</h6>
                                            <h1>{this.state.time_differ % 60}</h1>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-purple my-3 w-50" onClick={() => { isLogged() ? this.setState({ deposit_show: true }): sendNotfication("You Must Login First") }}>{t('deposit_now')}</button>
                                </div>
                            </div >
                            :
                            <div className='row rounded rounded-promotion no-time_differ text-center text-white py-3' style={{ backgroundImage: 'url("/assets/images/promo/promoMain.png")', backgroundSize: '100% 115%', backgroundRepeat: 'no-repeat' }}>
                                <div className='col-3' />
                                <div className='col-6'>
                                    <h2 className="loyaltyrewards1"> {t('loyalty_rewards')}</h2>
                                    <div className="d-flex justify-content-center"><div className='rounded-pill' style={{ backgroundColor: 'rgb(52, 190, 129)' }}><h2 className="loyaltyrewards2">{t('rakeback')} <span className='text-warning' style={{ fontSize: '37.5px' }}>20%</span> {t('up_to')}</h2></div></div>
                                    <h6 className="loyaltyrewards3" style={{ marginBottom: 0 }}>{t('level_up_your_rakeback_when_you_wager_more')}</h6>
                                    <h6 className="loyaltyrewards4">{t('and')} <span className='text-warning'>{t('enjoy_royal_rewards')}</span> {t('up_to')} 20% {t('return')}</h6>
                                    <button type="button" className="btn btn-warning w-50 loyaltyrewardsbutton" onClick={() => { isLogged() ? this.setState({ deposit_show: true }): sendNotfication("You Must Login First") }}>{t('deposit_now')}<i></i></button>
                                </div>
                                <div className='col-3' />
                            </div>
                    }
                    <Modal
                        size={'lg'}
                        backdrop={'static'}
                        centered={true}
                        scrollable={false}
                        show={this.state.deposit_show}
                        onHide={this.handleClose}
                        aria-labelledby="wallet-md-modal"
                    >
                        <Modal.Header className={this.state.paddingHeader}>
                            <Row xs={1} md={2} style={{ width: '100%' }}>
                                <Col xs={6} md={6}>
                                    {/* <button
                                                    onClick={this.showArchive}
                                                    className={"btn bg-cs2 bg-cs5 btn-xs text-white"}
                                                > {this.state.headerText} */}

                                    {/* </button> */}
                                </Col>
                            </Row>
                            <button type="button" className="close p-2" onClick={this.handleClose}>
                                <i className={'mdi mdi-close'} />
                            </button>
                        </Modal.Header>
                        <Modal.Body className={this.state.padding + ' wallet-modal buttonswallet'}>
                            <Deposit t={t} />
                        </Modal.Body>
                    </Modal>
                    <div className="row my-3 rowpromotion rowpromotionfirst">
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoFlexiblesavings.00970ae7.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', marginRight: '20px' }}>
                            <div className="p-3 boxpromotion boxpromotionup">
                                <h4 className="my-2">{t('rakeback_b')} 20%</h4>
                                <h6 className="mt-3" style={{ fontSize: '13px' }}>{t('get_bonuses_for_every_bet')} {t('the_higher_the')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('level_the_more')} <span className="text-warning">{t('rakeback_benefits')}</span></h6>
                            </div>
                        </div>
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoLuckywheel.93c795dd.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                            <div className="p-3 boxpromotion boxpromotionup">
                                <h4>{t('lucky_spin')}</h4>
                                <h6 className="my-1" style={{ fontSize: '13px' }}>{t('spin_for_a_chance_to_get_free_usdt_credit')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('There_will_be_a_reward_of_100_click_to_spin_more')}</h6>
                                <button type="button" className="btn btn-purple btn-sm w-25" onClick={() => { Event.emit("lucky_spin"); }}>{t('spin_now')}! <i></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3 rowpromotion">
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoVip.3cbbea15.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', marginRight: '20px' }}>
                            <div className="p-3 boxpromotion boxpromotionup">
                                <h4>{t('top_winners')}</h4>
                                <h6 className="my-1" style={{ fontSize: '13px' }}>{t('for_the_wagered_amount_you_will_be_part_of')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('daily_contest_for_prizes_from_the_bankroll_pool')}</h6>
                                <Link to={'/daily-contest'}><button type="button" className="btn btn-purple btn-sm w-25">{t('join_now')} <i></i></button></Link>
                            </div>
                        </div>
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoTask.e2875eef.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                            <div className="p-3 boxpromotion boxpromotionup">
                                <h4>{t('weekly_giveaway')}</h4>
                                <h6 className="my-1" style={{ fontSize: '13px' }}>{t('each_week_you_can_win_ledger_nano_s')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('with_just_one_deposit_you_are_in_the_raffle')}</h6>
                                <Link to={'/daily-contest'}><button type="button" className="btn btn-purple btn-sm w-25">{t('join_now')} <i></i></button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3 rowpromotion">
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoRakeBack.1d87507c.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat', marginRight: '20px' }}>
                            <div className="p-3 boxpromotion boxpromotionup">
                                <h4>{t('offerwall')}</h4>
                                <h6 className="my-1" style={{ fontSize: '13px' }}>{t('with_Offerwall_you_can_get_free_credits')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('and_play_games_on_usdt_from_lootably_earnings')}</h6>
                                <button type="button" className="btn btn-purple btn-sm w-25" onClick={() => { isLogged() ? Event.emit("offerwall") : sendNotfication(t('you_must_log_in_first')) }}>{t('claim_now')} 
                                    <i></i>
                                </button>
                                <FreeCredit t={t} disableButton={true}/>
                            </div>
                        </div>
                        <div className="col rounded rounded-promotion text-white" style={{ backgroundImage: 'url("/assets/images/promo/promoFreebox.8bf1e702.png")', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                            <div className="p-3 boxpromotion">
                                <h4>{t('7_days_bonus')}</h4>
                                <h6 className="my-1" style={{ fontSize: '13px' }}>{t('login_to_your_account_7_days_per_week_in_a_row')}</h6>
                                <h6 style={{ fontSize: '13px' }}>{t('and_you_can_claim_your_daily_bonus')}</h6>
                                <button type="button" className="btn btn-purple btn-sm w-25" onClick={() => { isLogged() ? Event.emit("login_bonus") : sendNotfication("You Must Login First") }}>{t('apply_now')} <i></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
