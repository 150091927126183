import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from 'react-redux';
import store from './store';
import WebApp from '@twa-dev/sdk'

// Styles
import "./Static/css/bootstrap.min.css"; 
import "./Static/css/animate.css";
import "./Static/css/icons.css";
import 'react-notifications-component/dist/theme.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "./Static/css/metisMenu.min.css";
import "./Static/css/extra.css";
import "./Static/css/app.css";

WebApp.ready();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);


// serviceWorker.unregister();
serviceWorker.register({
    onSuccess(registration) {
        console.log("serviceWorkerRegistration success.");
    }
});
