import React from "react";
import ReactTooltip from "react-tooltip";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import {
  __,
  decode,
  sendNotfication,
  toCurrencyFomatter,
} from "../../../../Helper";
import storage from "../../../../Storage";
import { Dropdown, Modal, Button, Row, Col } from "react-bootstrap";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { withRouter } from "react-router-dom";

class Rakeback extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      totalAmount: 0,
      amount: 0,
      level: 0,
      claimed: false,
      match: {
        level_0: { rakePercent: 0, rakeRPercent: "5%" },
        level_1: { rakePercent: 1, rakeRPercent: "10%" },
        level_2: { rakePercent: 5, rakeRPercent: "30%" },
        level_3: { rakePercent: 10, rakeRPercent: "50%" },
        level_4: { rakePercent: 15, rakeRPercent: "70%" },
        level_5: { rakePercent: 20, rakeRPercent: "100%" },
      },
      target: [0, 0, 0, 0, 0],
      showClaimMd: false,
      wagered_amount: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // let isLogged = storage.getKey("logged") !== null ? true : false;
    // if (isLogged) {
      socket.emit(C.GET_USER_RAKEBACK);
      socket.on(C.GET_USER_RAKEBACK, (data) => {
        this.updateRakeback(decode(data));
      });
    // }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let isLogged = storage.getKey("logged") !== null ? true : false;
      if (isLogged) {
        socket.emit(C.GET_USER_RAKEBACK);
      } else {
        this.setState({ loading: false });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.GET_USER_RAKEBACK);
    socket.off(C.CASHOUT_MINE);
  }

  updateRakeback(data) {
    if (this.props.hasOwnProperty("targetUpdate")) {
      this.props.targetUpdate(data.target);
    }

    if (__.isUndefined(data)) return;

    let isLogged = storage.getKey("logged") !== null ? true : false;

    if (isLogged) {
      this.setState({
        loading: false,
        totalAmount: data.total_amount,
        amount: data.amount,
        level: data.level,
        target: data.target,
        claimed: data.claimed,
        wagered_amount: data.wagered_amount,
      });
    } else {
      this.setState({ target: data.target });
    }
  }

  formatAmount(number) {
    // Should fix getWageredAmount function from rakeback/rule
    if (__.isUndefined(number)) return;
    if (__.isNull(number)) return;

    // Convert the input to a number if it's a string
    const numericValue =
      typeof number === "string" ? parseFloat(number) : number;

    // Check if the conversion to a number was successful
    if (isNaN(numericValue)) {
      // Handle the case where the conversion failed
      console.error("Invalid input. Cannot convert to a number.");
      return;
    }

    // Format the numeric value
    const formattedNumber = numericValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }

  handleClose() {
    this.setState({ showClaimMd: false });
  }
  handleModalShow() {
    this.setState({ showClaimMd: true });
  }
  cashOutNow() {
    socket.emit(C.CASHOUT_MINE);
    socket.on(C.CASHOUT_MINE, (result) => {
      if (!decode(result)) sendNotfication("Cash out Err!");
      else {
        sendNotfication(
          "You got $" +
            this.formatAmount(
              (
                (this.state.amount *
                  this.state.match["level_" + this.state.level].rakePercent) /
                100
              ).toFixed(2)
            ) +
            "!"
        );
        this.setState({ amount: 0 });
      }
      socket.off(C.CASHOUT_MINE);
    });
  }

  render() {
    let { t } = this.props;
    if (typeof t !== "function")
      t = function (s) {
        return s;
      };
    let progress = !_.isUndefined(
      this.state.match["level_" + this.state.level].rakeRPercent
    )
      ? this.state.match["level_" + this.state.level].rakeRPercent
      : "0%";

    return (
      <>
        {
          <div className="p-2 mt-3 home-last-bets-wrapper container-progress rakebackcontent">
            <div className="rakeback-left">
              <div className="mb-3 hstack gap-3 rakebacktop">
                <div className="d-flex align-items-center rakebackheader">
                  <img
                    className="img-fluid rakeback-icon"
                    src="/assets/images/icons/in-house-game.svg"
                  />{" "}
                  <p className="m-0 home-part-t">
                    {t("rewards")}{" "}
                    <span className="rakeback-amount-level">
                      Level {this.state.level}
                    </span>{" "}
                    <span className="rakeback-amount">
                      (${this.formatAmount(this.state.wagered_amount)}{" "}
                      {t("wagered_s")})
                    </span>
                  </p>
                </div>

                <div className="d-flex align-items-center rakebackheader-right">
                  <p
                    className="m-0 home-part-t"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleModalShow()}
                  >
                    {t("see_and_claim")}{" "}
                    <i className="mdi mdi-dots-horizontal"></i>{" "}
                  </p>
                </div>
                <Modal
                  size={"xlg"}
                  backdrop={"static"}
                  centered={true}
                  scrollable={false}
                  show={this.state.showClaimMd}
                  onHide={this.handleClose}
                  aria-labelledby="wallet-md-modal"
                  className={"rakebackmodal"}
                >
                  <Modal.Header>
                    <h3 className="ml-2" style={{ margin: 0 }}>
                      {t("claim_rakeback")}
                    </h3>
                    <button
                      type="button"
                      className="close p-2 mr-2"
                      onClick={() => this.handleClose()}
                    >
                      <i className={"mdi mdi-close"} />
                    </button>
                  </Modal.Header>
                  <Modal.Body
                    className={" wallet-modal buttonswallet modalrakebacktop"}
                  >
                    <Row xs={1} md={2} className="mx-2 rakebackroundedtop">
                      <Col
                        xs={5}
                        md={5}
                        className="rounded rakebackrounded rakebackroundedavailable"
                        style={{ backgroundColor: "rgba(25,32,51,255)" }}
                      >
                        <h3
                          className="rakebacktoptext"
                          style={{
                            color: "rgba(246,191,29,255)",
                            fontSize: "17.5px",
                          }}
                        >
                          {t("available_rakeback")}
                        </h3>
                        <h4 className="text-white" style={{ fontSize: "14px" }}>
                          {t("you_can_withdraw_your_rakeback")}
                        </h4>
                        <h4 className="text-white" style={{ fontSize: "14px" }}>
                          {t("and_use_in_your_balance")}
                        </h4>
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        className="text-center rakebackroundedcashout"
                      >
                        <div>
                          <h2
                            style={{
                              color: "rgba(254,195,1,255)",
                              fontSize: "34px",
                              fontSize: "33px",
                            }}
                            className="rakebackamount"
                          >
                            ${this.formatAmount(this.state.amount)}
                          </h2>
                          <button
                            type="button"
                            className="btn btn-warning cashout-link"
                            onClick={() => this.cashOutNow()}
                            disabled={this.state.amount == 0}
                          >
                            <h4 className="text-dark">{t("cashout_now")}</h4>
                          </button>
                        </div>
                        <img
                          src="/assets/images/rakeback/case_1.png"
                          className="caseRakeback"
                        />
                      </Col>
                    </Row>
                    <Row xs={1} md={2} className="mx-2 rakebackroundedtop">
                      <Col
                        xs={5}
                        md={5}
                        className="rounded rakebackrounded rakebackroundedlast"
                        style={{ backgroundColor: "rgba(25,32,51,255)" }}
                      >
                        <h3
                          className="rakebacktoptext"
                          style={{
                            color: "rgba(246,191,29,255)",
                            fontSize: "17.5px",
                          }}
                        >
                          {t("current_level")}
                        </h3>
                        <h4 className="text-white" style={{ fontSize: "14px" }}>
                          {t("on_betmixer_you_can_achieve")}
                        </h4>
                        <h4 className="text-white" style={{ fontSize: "14px" }}>
                          {t("almost_level_5_with_20_rakeback")}
                        </h4>
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        className="text-center text-warning rounded rakebackrounded"
                        style={{ backgroundColor: "rgba(25,32,51,255)" }}
                      >
                        <h1
                          className="wageredlevel"
                          style={{ color: "rgba(254,195,1,255)" }}
                        >
                          {t("level")} {this.state.level}
                        </h1>
                        <h4 className="text-white" style={{ fontSize: "14px" }}>
                          {t("wagered")}: $
                          {this.formatAmount(this.state.wagered_amount)}
                        </h4>
                        <i></i>
                      </Col>
                    </Row>

                    <p className="mt-1 mb-0 p-2 bg-soft-dark text-white infowithdrawal inforakeback">
                      <span className="text-yellow">{t("note")}</span>{" "}
                      {t(
                        "the_rakeback_is_essentially_when_betsmixer_return_percentage_etc"
                      )}
                    </p>
                    <span className="encryption encryption2">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="lock"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="svg-inline--fa fa-lock fa-w-14"
                      >
                        <path
                          fill="currentColor"
                          d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                        ></path>
                      </svg>
                      256-bit {t("encryption")}
                    </span>
                  </Modal.Body>
                </Modal>
              </div>

              <div className="progress">
                <div className="progress-bar" style={{ width: progress }}></div>
              </div>

              <div className="rakeback-radio">
                <ReactTooltip />
                <input
                  type="radio"
                  className="radio"
                  name="progress"
                  value="five"
                  id="five"
                  disabled
                  checked={this.state.level == 1}
                />
                <label
                  htmlFor="five"
                  className="label"
                  data-tip={`Level 1 (Wagered ${toCurrencyFomatter(
                    this.state.target[0]
                  )})`}
                >
                  1%
                </label>

                <input
                  type="radio"
                  className="radio"
                  name="progress"
                  value="twentyfive"
                  id="twentyfive"
                  disabled
                  checked={this.state.level == 2}
                />
                <label
                  htmlFor="twentyfive"
                  className="label"
                  data-tip={`Level 2 (Wagered ${toCurrencyFomatter(
                    this.state.target[1]
                  )})`}
                >
                  5%
                </label>

                <input
                  type="radio"
                  className="radio"
                  name="progress"
                  value="fifty"
                  id="fifty"
                  disabled
                  checked={this.state.level == 3}
                />
                <label
                  htmlFor="fifty"
                  className="label"
                  data-tip={`Level 3 (Wagered ${toCurrencyFomatter(
                    this.state.target[2]
                  )})`}
                >
                  10%
                </label>

                <input
                  type="radio"
                  className="radio"
                  name="progress"
                  value="seventyfive"
                  id="seventyfive"
                  disabled
                  checked={this.state.level == 4}
                />
                <label
                  htmlFor="seventyfive"
                  className="label"
                  data-tip={`Level 4 (Wagered ${toCurrencyFomatter(
                    this.state.target[3]
                  )})`}
                >
                  15%
                </label>

                <input
                  type="radio"
                  className="radio"
                  name="progress"
                  value="onehundred"
                  id="onehundred"
                  disabled
                  checked={this.state.level == 5}
                />
                <label
                  htmlFor="onehundred"
                  className="label"
                  data-tip={`Level 5 (Wagered ${toCurrencyFomatter(
                    this.state.target[4]
                  )})`}
                >
                  20%
                </label>
              </div>
            </div>

            <div className="rakeback-right">
              <img
                src="/assets/images/bull-rewards.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        }
      </>
    );
  }
}

export default Rakeback;
