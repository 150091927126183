import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Scroll from "react-scroll";
import { setName } from "../../../../actions/gameChat";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../../../Components/User/Stat/Modal";
import {
  getElement,
  __,
  wait,
  Event,
  sendNotfication,
} from "../../../../Helper";
import C from "../../../../Constant";
import isLogged from "../../../../Auth";

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Messages extends React.Component {
  css = "zoomIn";
  bubble = "none";
  bubbleMsg = 0;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      warnMsg: false,
      alertSpam: false,
      messages: [],
      loader: [],
      clientCountry: storage.getKey("country")
        ? storage.getKey("country")
        : "GLOBAL",
    };
    this.muteUser = this.muteUser.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setLoader();

    wait(1500).then(() => {
      socket.emit(C.CHATS, { country: this.state.clientCountry });
    });

    socket.on(C.CHATS, (data) => this.getChats(data));
    socket.on(C.ADD_CHAT, (data) => {
      this.getNewChats(data);
    });

    Event.on("scrolldone", () => this.updateScroll());
    this.updateScroll();
    // this.intervalID = setInterval(() => {
    socket.emit(C.CHATS, { country: this.state.clientCountry });
    // }, 3000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    // clearInterval(this.intervalID);
    socket.off(C.CHATS);
    socket.off(C.ADD_CHAT);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this._isMounted) {
      if (nextProps.country) {
        this.setState({ clientCountry: nextProps.country, messages: [] });
        this.setLoader();

        wait(500).then(() => {
          var c = nextProps.country;

          if (__.toLower(c) === "spam") {
            this.setState({ alertSpam: true });
            c = "BRAZIL";
            Event.emit("open_socket");
          } else {
            this.setState({ alertSpam: false });
            Event.emit("close_socket");
          }

          socket.emit(C.CHATS, { country: "global" });
        });
      }
      this.updateScroll();
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this._isMounted) {
      let e = getElement("#messages"),
        t = e.clientHeight,
        n = e.scrollHeight,
        r = e.scrollTop;
      this.scrollBottom = n - r - t;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this._isMounted) {
      this.updateScroll();
      if (this.state.messages.length >= 150) {
        var slice = __.slice(
          this.state.messages,
          this.state.messages.length - 1,
          this.state.messages.length
        );
        this.setState({ messages: slice });
        this.state.messages.length = 150;
      }
    }
  }

  updateScroll() {
    if (this._isMounted) {
      if (this.scrollBottom <= 150) {
        SC.scrollTo("chatBarElement", {
          delay: 3,
          smooth: true,
          containerId: "messages",
          offset: getElement("#sc").scrollHeight * 2,
        });
        getElement(".chat-bubble").classList.add("hidden");
        this.bubbleMsg = 0;
      } else {
        this.bubbleMsg += 1;
        if (this.bubbleMsg > 0) {
          getElement(".chat-bubble").classList.remove("hidden");
        }
      }
    }
  }

  setLoader = () => {
    if (this._isMounted) {
      const icon = (
        <div className="ycenter">
          <div className="spinner-grow text-white" role="status" />
        </div>
      );
      this.setState({ loader: icon });
    }
  };
  muteUser = (id) => {
    const updatedMessages = this.state.messages.filter(
      (item) => item.props.message.uid !== id
    );
    this.setState({ messages: updatedMessages });
    socket.emit(C.MUTE_USER, { id: id });
  };
  removeMessage = (id, sorter) => {
    // const updatedMessages = this.state.messages.filter(item => item.props.message.sorter !== sorter);
    // this.setState({ messages: updatedMessages });
    socket.emit(C.REMOVE_MESSAGE, { sorter: sorter, id: id });
  };

  getChats(data) {
    if (this._isMounted) {
      let messagesComps = [];
      sortByTime(data)
        .reverse()
        .forEach((message, i) => {
          messagesComps.push(
            <Message
              key={__.toString(i)}
              t={this.props.t}
              message={message}
              redux={this.props}
              muteUser={this.muteUser}
              removeMessage={this.removeMessage}
            />
          );
        });
      this.setState({ messages: messagesComps });
    }
  }

  getNewChats(message) {
    if (message.success) {
      if (this._isMounted) {
        let { country } = this.props;
        let currentCountry = country ? country : this.state.clientCountry;

        // if (__.upperCase(message.country) === __.upperCase(currentCountry)) {
        let add = this.state.messages.concat([
          <Message
            t={this.props.t}
            key={this.state.messages.length + 1}
            message={message}
            redux={this.props}
            muteUser={this.muteUser}
            removeMessage={this.removeMessage}
          />,
        ]);
        this.setState({ messages: add });
        // }
      }
    } else {
      if (message.name != "SystemBot") {
        this.setState({ warnMsg: true });
      }
    }
  }

  hideBubble = () => {
    if (this._isMounted) {
      SC.scrollTo("chatBarElement", {
        delay: 3,
        smooth: true,
        containerId: "messages",
        offset: getElement("#sc").scrollHeight * 2,
      });
      getElement(".chat-bubble").classList.add("hidden");
      this.bubbleMsg = 0;
    }
  };

  handleClose = () => {
    this.css = "zoomOut";
    this.setState({ warnMsg: false });
  };

  render() {
    const { t } = this.props;
    return (
      <Element name="chatBarElement">
        <ul className="nav chats flex-column gap-2" id={"sc"}>
          {this.bubbleMsg > 0 ? (
            <>
              <div onClick={this.hideBubble} className={"chat-bubble"}>
                <span className="chat-bubble-text">{this.bubbleMsg}</span>
                <span className="caret chat-bubble-caret"></span>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* {this.state.loader} */}
          {this.state.messages}
        </ul>
        <Modal
          show={this.state.warnMsg}
          onHide={this.handleClose}
          centered={true}
          backdrop={"static"}
          className={"animated " + this.css}
        >
          <Modal.Header>
            {t("warning_chat_rules")}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <p className="font-15 p-0 text-white">
              {t("this_word_blacklisted_etc")}
            </p>
          </Modal.Body>
        </Modal>
      </Element>
    );
  }
}

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: storage.getKey("name"),
      mod: storage.getKey("mod"),
    };
  }

  callUser(name) {
    Event.emit("call_user", "@" + name + " ");
  }

  createMarkup(msg) {
    return { __html: msg };
  }

  isSharing = (value) => {
    if (__.isString(value)) {
      let test = value.substr(0, 5);
      if (test === '{"gam' || test === '{"uid') return true;
      else return false;
    } else return true;
  };

  hiddenAlert = () => {
    return sendNotfication(
      this.props.t("all_users_in_spam_room_hidden"),
      "warning",
      "bottom-center"
    );
  };
  openTip = (id) => {
    if (isLogged()) {
      Event.emit("force_modal_tip", { direct: true, uid: id });
    } else {
      sendNotfication(this.props.t("you_must_log_in_first"));
    }
  };
  handleMute = (id) => {
    this.props.muteUser(id);
  };
  handleRemove = (id, sorter) => {
    this.props.removeMessage(id, sorter);
  };
  render() {
    const { t } = this.props;
    let { uid, name, message, avatar, time, level, sorter, type, vip_level } =
      this.props.message;

    if (__.isUndefined(message)) return null;

    let id = uid;

    let username = name;
    let style;
    let css = "msg font-light flex-fill";
    let isNotSelf = false;
    let highlight = "";

    //System Message
    if (username === "SystemBot") {
      style = {
        background: "#624aad url('assets/images/rain.png')",
        backgroundSize: "contain",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        fontSize: "13px",
        borderRadius: "6px",
        padding: "9px 10px",
      };
      css = "msg font-light flex-fill rain-it"
    }

    if (this.state.name === username) isNotSelf = true;

    let r = new RegExp("@" + this.state.name + "(?:$|[^a-z0-9_-])", "i");
    if (this.state.name !== username && r.test(message)) {
      highlight += "highlight-on";
    }

    //Check Bad Words
    var ex = /scam/gi;
    var rg = new RegExp(ex);
    if (message.match(rg)) {
      message = __.replace(message, "scam", "Good");
    }

    //Check Links and GIF's
    var expression = /giphy/gi;
    var reg = new RegExp(expression);
    if (!message.match(reg)) {
      var expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      var regex = new RegExp(expression);
      if (message.match(regex)) {
        let hidden = t("link_is_hidden");
        message = (
          <div
            dangerouslySetInnerHTML={this.createMarkup(
              `<span className="text-danger">[ ${hidden} ]</span>`
            )}
          />
        );
      }
    } else {
      message = (
        <div
          dangerouslySetInnerHTML={this.createMarkup(
            `<img alt="GIF" className="img-fluid" src="${message}" />`
          )}
        />
      );
    }

    var arr = [];

    //Check Usernames Calls
    let check = __.words(message, /@([a-zA-Z0-9]+)/gi);

    if (check.length !== 0) {
      for (let i = 0; i < check.length; i++) {
        let username = check[i];
        let link = (
          <span key={__.toString(i)} className="text-warning">
            {" "}
            @
            <UserModal
              id={__.replace(id, "@", "")}
              username={__.replace(username, "@", "")}
              cssClass="text-warning"
            />
          </span>
        );
        try {
          message = message
            .split(" ")
            .map((c) => (c === username ? link : " " + c));
        } catch (e) {}
      }
    }

    return (
      <li
        className={
          "d-flex nav-item chat-part animated XfadeIn gap-1 " + highlight
        }
      >
        <UserModal
          id={id}
          t={t}
          level={level}
          vip_level={vip_level}
          cssClass={"user-avatar"}
          username={username}
          chat={true}
          avatar={avatar}
          time={time}
        />
        {/* {(!isNotSelf) && <>
                    <div className="chat-option float-right">
                        <span className={'font-15'} onClick={() => this.callUser(username)}>
                            <span className={'list-inline-item mt-2 font-20'}>@</span>
                        </span>
                    </div>
                </>} */}
        <div className={css} style={style}>
          {type == "mod" ? (
            <span className="m-0 chat-user-name">
              {username}&nbsp;
              <span className="badge bg-primary text-dark">MOD</span>
              <span className="m-0 chat-user-msg"> {message}</span>
            </span>
          ) : (
            <span className="m-0 chat-user-name">{username}: 

          <span className="m-0 chat-user-msg"> {message}</span></span>
          )}
        </div>
        <div className="chat-more-btn d-flex">
          {this.state.mod == 1 ? (
            <>
              <button
                type="button"
                className="btn p-0 offacc"
                onClick={() => this.handleMute(id)}
              >
                <i className={"mdi mdi-account-off"} />
              </button>
              <button
                type="button"
                className="btn p-0"
                onClick={() => this.handleRemove(id, sorter)}
              >
                <i className={"mdi mdi-delete"} />
              </button>
            </>
          ) : name !== storage.getKey("name") ? (
            <button
              type="button"
              className="btn p-0"
              onClick={() => this.openTip(id)}
            >
              <i className={"mdi mdi-dots-horizontal"} />
            </button>
          ) : (
            <button
              type="button"
              className="btn p-0"
              onClick={() => this.handleRemove(id, sorter)}
            >
              <i className={"mdi mdi-delete"} />
            </button>
          )}
        </div>
        {/* <span className={'chat-date'}>{time}</span> */}
      </li>
    );
  }
}

function sortByTime(input) {
  function r(c) {
    return c.sorter ? -c.sorter : null;
  }
  return __.sortBy(input, r);
}

Messages.propTypes = {
  country: PropTypes.string,
};

const mapStateToProps = (state) => ({
  country: state.items.country,
});

export default connect(mapStateToProps, { setName })(Messages);
