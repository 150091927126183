import * as PIXI from "pixi.js";
import Loader from "../Loader";
import Effect from "./components/Effect";
import { __, wait, Event } from "../../../Helper";

PIXI.settings.ROUND_PIXELS = true;
PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

const ASSETS_PATH = "./assets/images/mines";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 450,
      height: 400,
      transparent: true,
      resolution: 1,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("mines", this.app);
    this.container = null;
    this.space = 55;
    this.landWidth = 47;
    this.landHeight = 47;
    this.disables = [];
    this.auto = false;
    this.bulls = [];
    this.callback = null;
  }

  init(callback) {
    this.callback = callback;

    this.loader.add([
      ASSETS_PATH + "/gem.png",
      ASSETS_PATH + "/mine.png",
      ASSETS_PATH + "/land.png",
      ASSETS_PATH + "/land_active.png",
    ]);
    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();

    Event.on("auto_mine", () => {
      this.auto = true;
    });
  }

  load(loader, res) {
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.app.stage.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configLands();
    this.configAnim();
  }

  configLands() {
    this.landContainer = new PIXI.Container();
    this.landContainer.scale.set(1.4);
    this.landContainer.x = 50;
    this.landContainer.y = 50;
    this.container.addChild(this.landContainer);
    let id = 0;
    for (var i = 0; i < 5; i++) {
      for (var j = 0; j < 5; j++) {
        let x = j * this.space;
        let y = i * this.space;
        this.addLand(id, x, y);
        id++;
      }
    }
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
      }
    });
  }

  addLand(id, x, y) {
    let land = new PIXI.Sprite(PIXI.Texture.from(ASSETS_PATH + "/land.png"));
    land.width = 52;
    land.height = 52;
    land.x = x;
    land.y = y;
    land.anchor.set(0.5);
    land.interactive = true;
    land.buttonMode = true;

    land.on("pointerover", (e) => this.onHover(id, x, y));
    land.on("pointerout", (e) => this.onOut(id));
    land.on("click", (e) => this.onClick(id, x, y));
    land.on("touchstart", (e) => this.onClick(id, x, y));

    this.bulls[id] = land;
    Effect.FadeIn(land, id * 0.6 * 0.1);
    this.landContainer.addChild(land);
  }

  onHover(id, x, y) {
    if (id !== this.clicked) {
      if (!__.includes(this.disables, id)) {
        this.bulls[id].texture = PIXI.Texture.from(
          ASSETS_PATH + "/land_active.png"
        );
      }
    }
  }

  onOut(id) {
    if (id !== this.clicked) {
      if (!__.includes(this.disables, id)) {
        this.bulls[id].texture = PIXI.Texture.from(ASSETS_PATH + "/land.png");
      }
    }
  }

  onClick(id, x, y) {
    if (!this.started) return;

    this.clicked = id;

    this.callback(id);
  }

  safe(id) {
    if (!__.includes(this.disables, id)) {
      this.bulls[id].texture = PIXI.Texture.from(ASSETS_PATH + "/gem.png");
      Effect.Flip(this.bulls[id]);
      this.disables.push(id);
    }
  }

  addBomb(id) {
    if (!__.includes(this.disables, id)) {
      this.bulls[id].texture = PIXI.Texture.from(ASSETS_PATH + "/mine.png");
      Effect.Flip(this.bulls[id]);
      this.disables.push(id);
    }
  }

  busted(mines) {
    this.started = false;

    mines.forEach((mine, i) => {
      this.addBomb(mine.num);
    });
  }

  play() {
    this.started = true;
  }

  reset() {
    for (var i = 0; i < this.bulls.length; i++) {
      this.landContainer.removeChild(this.bulls[i]);
    }
    this.container.removeChild(this.landContainer);
    this.started = false;
    this.clicked = null;
    this.bulls = [];
    this.disables = [];
    this.landContainer.destroy();
    this.container.destroy();

    return this.load();
  }

  destroy() {
    this.app = null;
    this.started = false;
    this.clicked = null;
    this.bulls = [];
    this.disables = [];
    this.landContainer.destroy();
    this.container.destroy();
  }
}

export default Canvas;
