import storage from "../../../../../Storage";
import { __ } from "../../../../../Helper";

class Script {
  constructor() {
    this.scripts = !_.isNull(storage.getKey("scripts"))
      ? JSON.parse(storage.getKey("scripts"))
      : [];
  }
  set(data) {
    this.scripts.push(data);
    storage.setKey("scripts", JSON.stringify(this.scripts));
  }
  edit() {
    var index = _.findIndex(this.scripts, function (o) {
      return o.name == data.name;
    });
    if (index > -1) {
      this.scripts[index].content = data.content;
    }
    storage.setKey("scripts", JSON.stringify(this.scripts));
  }
  delete() {
    var index = _.findIndex(this.scripts, function (o) {
      return o.name == name;
    });
    if (index > -1) {
      this.scripts.splice(index, 1);
    }
    storage.setKey("scripts", JSON.stringify(this.scripts));
  }
}

export default Script;
