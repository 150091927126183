import React from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Terms | Crypto Casino Games</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/terms-betsmixer" />
        </Helmet>
        <div>
          <Card className="card-page">
            <Card.Body>
              <h1 class="font-25">
                Terms  — <span className="text-yellow">FAQ</span>
              </h1>
              <hr />

              <p>
                Here you can find important questions and answers for your
                satisfaction to bring trust our customers and new players which
                want join Betsmixer commmunity.
                <br></br>
                <span className="text-yellow"> Betsmixer</span> is innovative casino with 6500+ slots games.{" "}
                <span className="text-yellow">Betsmixer</span> is not just casino
                but symbol of instant deposits and instant withdrawals with big
                community players.
              </p>

              <hr />
              <h5>
                <span className="text-yellow">How long takes deposit?</span>
              </h5>
              <p>
                Each deposit is credited in{" "}
                <span className="text-yellow">few minutes (1min - 5min)</span>{" "}
                when reach your account wallet which is dedicated for you.
              </p>

              <hr />
              <h5>
                <span className="text-yellow">How long takes withdrawal?</span>
              </h5>
              <p>
                Withdrawal is send processed{" "}
                <span className="text-yellow">automatically / instanstly</span>{" "}
                in few minutes once you send your withdrawal request.
                <br></br>Open User Menu - Click to Withdraw - Type Amount -
                Click to Withdraw Request Withdraw Button
              </p>

              <hr />
              <h5>
                <span className="text-yellow">
                  How I can unlock bonus balance?
                </span>
              </h5>
              <p>
                Bonus balance USDT you can{" "}
                <span className="text-yellow">unlock by wagering</span>, you can
                check status when you click in coin selection menu on bonus tab
                - then will show modal which show you current availanle bonus to
                unlock. To understand mathematical formula for unlock USDT bonus
                balance:
                <br></br>Unlock amount = wager amount * 1% * 20%
                <br></br>Example: $0,2 Unlock amount = $100 * 1% * 20%, $0,2 is
                unlocked when you wager $100.
              </p>

              <hr />
              <h5>
                <span className="text-yellow">
                  How works affiliate 15% from each deposit?
                </span>
              </h5>
              <p>
                From each user which register over your link and deposit money{" "}
                <span className="text-yellow">
                  you get 15%, for example from 100 USD deposit you will get 15
                  USD
                </span>{" "}
                once your referral deposit money into account.
                <br></br>You can also withdraw all money without any wagering or
                without any bets. What you get is yours and you can withdrawl
                immediately to your wallet.
              </p>

              <hr />
              <h5>
                <span className="text-yellow">
                  Where are money from Lucky Spin?
                </span>
              </h5>
              <p>
                From Lucky spin you will get{" "}
                <span className="text-yellow">bonus balance in USDT ERC20</span>
                , this you can unlock in your account with wagering. Please
                above "How I can unlock bonus balance".
                <br></br>
              </p>

              <hr />
              <h5>
                <span className="text-yellow">
                  Can I withdraw money from OfferWall?
                </span>
              </h5>
              <p>
                Betsmixer provide offerwall where you can{" "}
                <span className="text-yellow">
                  real USDT money into account
                </span>{" "}
                which you can withdraw or play on them. This money are yours in
                moment when you are credited from offerwall (ToroX, Lootably,
                AdGateMedia)
                <br></br>
              </p>
              <hr />
              <h5>
                <span className="text-yellow">
                  How works your affiliate system?
                </span>
              </h5>
              <p>
                From each referral you get <span className="text-yellow">15% commission</span> from deposit. <br></br>
                <span className="text-yellow" For example >If someone deposit 100 USD under your link, you will get 15 USD (15% from deposit).</span>

                <br></br>You can withdraw money without any limits directly to your USDT ERC20 wallet. You do not need wait to your commission.

                <br></br>Enjoy this advantage our unique affiliate system which brings large profits our affiliate partners. There are no limitation. 
              </p>
              <hr />
              <h5>
                <span className="text-yellow">
                  Which license has your casino?
                </span>
              </h5>
              <p>
                Betsmixer operates under{" "}
                <span className="text-yellow">Curacao license</span> to provide
                customers best possible gaming experience and trust of their
                deposits and withdrawals.
              </p>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}
