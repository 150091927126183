import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SHA256 from "crypto-js/sha256";
import { __, sendNotfication, Event, wait } from "../../../../Helper";
import VerifyResultIcon from "./Icons/VerifyResult";

export default class Verify extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      effect: "zoomIn",
      called: null,
      generalResult: null,
      hashDiceResult: null,
      rouletteResult: null,
      headsResult: null,
      mineResult: null,
      color: null,
      hash: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    Event.on("game_verify", (data) => {
      var { hash, result } = data;
      this.setState({ called: true, show: true, hash: hash, effect: "zoomIn" });
      let calc = calculateResult(hash, result, this.props.t);
      this.setState({
        generalResult: calc.crash,
        hashDiceResult: calc.hash_dice,
        rouletteResult: calc.roulette,
        headsResult: calc.heads,
        mineResult: calc.mine,
        color: calc.color,
      });
      Event.emit("single_game_modal_close");
      Event.emit("close_user_modal");
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  open = (e, hash) => {
    if (this._isMounted) {
      e.preventDefault();

      // this.props.mobileDrawer(false);

      if (hash !== null) this.setState({ hash: hash });

      this.setState({ show: true, effect: "zoomIn" });
    }
  };

  close = (e) => {
    if (this._isMounted) {
      this.setState({ show: false, effect: "zoomOut" });
      wait(200).then(() => {
        this.setState({
          generalResult: null,
          hashDiceResult: null,
          rouletteResult: null,
          headsResult: null,
          mineResult: null,
          color: null,
          called: null,
          hash: "",
        });
      });
    }
  };

  submit = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.hash) return;
      let calc = calculateResult(this.state.hash, this.props.t);

      if (!calc) return;

      this.setState({
        generalResult: calc.crash,
        hashDiceResult: calc.hash_dice,
        rouletteResult: calc.roulette,
        headsResult: calc.heads,
        mineResult: calc.mine,
        color: calc.color,
      });
    }
  };

  render() {
    const { t, onModal, hash } = this.props;
    return (
      <>
        {onModal ? (
          <>
            <button
              onClick={(e) => this.open(e, hash)}
              className="btn btn-cs8 btn-block btn-xs btn-block no-shadow"
            >
              <i className="mdi mdi-shield nts" /> {t('verify_result')}
            </button>
          </>
        ) : (
          <Link
            className="b-subtitle"
            to={"#"}
            onClick={(e) => this.open(e, null)}
          >
            {t('verify_result')}
          </Link>
        )}
        <Modal
          size="sm"
          centered={true}
          backdrop={"static"}
          show={this.state.show}
          onHide={this.close}
          aria-labelledby="verify-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header className={"font-light"}>
            {t("verify_result")}
            <button type="button" className="close p-2" onClick={this.close}>
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body>
            {this.state.called === null && (
              <p className={"text-white mb-3 font-light font-15 tsn"}>
                {t("v1")}
                <br />
                {t("v2")}
              </p>
            )}
            <form onSubmit={(e) => this.submit(e)}>
              <div className="form-group">
                <label htmlFor="hash">
                  {" "}
                  <i className={"mdi mdi mdi-code-equal"} /> <span>HASH</span>{" "}
                </label>
                <input
                  id="hash"
                  type="text"
                  className={"form-control"}
                  value={this.state.hash}
                  onChange={(e) => this.setState({ hash: e.target.value })}
                />
              </div>

              {this.state.called === null && (
                <div className="form-group mt-2 text-center">
                  <button className={"btn btn-s-2 btn-block"}>
                    {t("check_result")}
                  </button>
                </div>
              )}
            </form>
            {this.state.generalResult && (
              <>
                <div
                  className={
                    "alert text-center text-white mb-1  bg-" + this.state.color
                  }
                >
                  <span>{t('general_games')}</span>
                  <br />
                  <b className="font-15">{this.state.generalResult}</b>
                </div>
                <div
                  className={
                    "alert text-center text-white mb-1 bg-" + this.state.color
                  }
                >
                  <span>{t('classic_dice')}</span>
                  <br />
                  <b className="font-15">
                    {(this.state.generalResult * 10).toFixed(2)}
                  </b>
                </div>
                <div
                  className={
                    "alert text-center text-white mb-1  bg-" + this.state.color
                  }
                >
                  <span>{t('hash_dice')}</span>
                  <br />
                  <b className="font-15">{this.state.hashDiceResult}</b>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function calculateResult(hash, result, t) {
  if (hash.length !== 64) {
    return sendNotfication(
      t("please_input_valid_game_hash"),
      "danger",
      "top-center"
    );
  }

  let gameCrash = getGeneralResult(hash);
  let gameDice = getHashDiceResult(hash, result);
  let gameRoulette = getRouletteResult(hash);
  let gameHeads = getHeadsResult(hash);
  let gameMine = getMinesResult(hash);

  let color = "soft-info";

  return {
    color: color,
    crash: gameCrash,
    hash_dice: gameDice,
    roulette: gameRoulette,
    heads: gameHeads,
    mine: gameMine,
  };
}

function getGeneralResult(seed) {
  let hash = SHA256(seed).toString();
  let h = parseInt(hash.slice(0, 13), 16);
  let e = Math.pow(2, 52);
  let result = Math.floor((98 * e) / (e - h));
  let max = (result / 100).toFixed(2);
  return max;
}

function calcChance(value) {
  var c = ((1 / value) * 99).toFixed(5);
  return Math.max(c, 0.0001);
}

function getHashDiceResult(seed, r) {
  let hash = SHA256(seed).toString();
  let h = parseInt(hash.slice(0, 13), 16);
  let e = Math.pow(2, 52);
  let result = Math.floor((98 * e) / (e - h));
  result = result * 100;
  result = result.toFixed(0);
  return result;
}

function getRouletteResult(hash) {
  hash = hash.substring(0, 10);
  var result = parseInt(hash, 16);
  var r = result % 100;
  var color;

  let bets = {
    green: [2, 3, 0, 1],
    red: [18, 19, 20, 21, 10, 11, 12, 13, 14, 15, 16, 17],
    black: [8, 9, 4, 5, 6, 7],
  };
  if (1 <= r && r < 30) color = "green";
  else if (30 <= r && r < 530) color = "red";
  else if (530 <= r && r < 1000) color = "black";
  return r;
}

function getHeadsResult(hash) {
  let key = 10;

  let lucky = parseInt(hash.substr(0, key), 16);

  let result = 2,
    c;

  for (var i = 0; i < key; i++) {
    c = lucky.toString()[i];
    if (parseInt(c) === 1) result = 1;
    else if (parseInt(c) === 2) result = 2;
  }

  return result;
}

function getMinesResult(hash) {
  var arr = [];
  for (var i = 0; i < 3; i++) {
    var r = parseInt(parseInt(hash.substr(i, 5), 16) / 10000);
    r /= 4;
    r = parseInt(r);
    r = Math.max(r, 1);
    if (arr.indexOf(r) === -1) arr.push(r);
    else {
      arr.push(r + i);
    }
  }
  return arr;
}
