import React from "react";
import md5 from "md5";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, makeStyles } from '@mui/styles';
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import UserModal from "../../User/Stat/Modal";
import { __, fixDate, Event, wait, decode, encode, forceSatoshiFormat, timeConvertor, getSingleRandomInt, SITE, getRandomInt, getGameID, isMobile } from "../../../../Helper";
import C from "../../../../Constant";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function Queue(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let { game } = props;
    let isLogged = storage.getKey("logged") !== null ? true : false;

    let content;

    if (props.slots) {
        content = (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'displaynone'} />} label="All Bets" {...a11yProps(0)} />
                        <Tab icon={<i className={'displaynone'} />} label="My Bets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={'x'}>
                        <AllBets t={props.t} game={game} slot={true} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={'x'}>
                        {(isLogged) ?
                            <MyBets t={props.t} game={game} slot={true} />
                            :
                            <>
                                <div className={'alert bg-soft-dark mt-2 font-13 text-white text-center'}>
                                    You must be logged to see your stats
                                </div>
                            </>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        );
    }
    else {
        content = (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                        centered
                    >
                        <Tab icon={<i className={'mdi mdi-chart-bar'} />} label="All Bets" {...a11yProps(0)} />
                        <Tab icon={<i className={'mdi mdi-chart-areaspline'} />} label="My Bets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={'x'}>
                        <AllBets t={props.t} game={game} slot={false} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={'x'}>
                        {(isLogged) ?
                            <MyBets t={props.t} game={game} />
                            :
                            <>
                                <div className={'alert bg-cs2 mt-2 font-13 text-white text-center'}>
                                    You must be logged to see your stats
                                </div>
                            </>
                        }
                    </TabPanel>
                </SwipeableViews>
            </div>
        );
    }

    return content;
}

class AllBets extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            players: [],
            fakePlayers: [],
            loading: true,
            name: storage.getKey('name'),
            nobets: false,
            fakePlayers: [],
            game: this.props.slot ? getGameID(this.props.game) : this.props.game
        };
        this.socketEventLastBets = null;
        this.socketEventAddBet = null;
        this.socketEventAddSlot = null;
    }

    componentDidMount() {
        this._isMounted = true;
        wait(200).then(() => {
            socket.emit(C.LAST_BETS_BY_GAME, encode({ game: this.state.game }));
        });
        this.socketEventLastBets = socket.on(C.LAST_BETS_BY_GAME, data => this.makeList(decode(data)));
        this.socketEventAddBet = socket.on(C.ADD_BET, data => this.addBet(decode(data)));
        this.socketEventAddSlot = socket.on(C.ADD_SLOT_BET, data => this.addBet(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.socketEventLastBets.off();
        this.socketEventAddBet.off();
        this.socketEventAddSlot.off();
    }

    addBet(player) {
        if (this._isMounted) {
            if (this.props.slot) {
                if (parseFloat(player.game) === parseFloat(this.state.game)) {
                    this.setState(state => ({ players: [player, ...state.players] }));

                    if (this.state.players.length > 10)
                        this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
                }
            }
            else
                if (player.game === this.props.game) {
                    this.setState(state => ({ players: [player, ...state.players] }));

                    if (this.state.players.length > 10)
                        this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
                }
        }
    }

    //Make Bets List
    makeList(arr) {
        if (this._isMounted) {
            if (typeof arr.history === "undefined") return;
            this.setState({ loading: false });

            if (arr.history.length === 0) {
                this.setState({ nobets: true });
            }
            this.setState({ players: arr.history })
        }
    }

    render() {
        const list = this.state.players.map((player, i) =>
            <Players key={i.toString()} forMy={false} player={player} />
        );
        return (
            <>
                <div className={'table-responsive last-bets num-style'}>
                    {this.state.loading ?
                        <>
                            <div className="text-center">
                                <div className="spinner-grow text-white my-3" role="status" />
                            </div>
                        </>
                        :
                        <Table className={"mb-0"}>
                            <thead>
                                <tr>
                                    {!isMobile() &&
                                        <th>
                                            ID
                                        </th>
                                    }
                                    <th>
                                        USERNAME
                                    </th>
                                    <th>
                                        BET
                                    </th>
                                    <th>
                                        PROFIT
                                    </th>
                                    <th>
                                        DATE/TIME
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </Table>
                    }
                    {this.state.nobets &&
                        <div className={'alert bg-soft-dark mt-2 font-13 text-white text-center text-uppercase'}>
                            No Records
                        </div>
                    }
                </div>
            </>
        );
    }
}

class MyBets extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: [],
            status: null,
            name: storage.getKey('name'),
            game: this.props.slot ? getGameID(this.props.game) : this.props.game
        };
        this.socketEventMyBets = null;
        this.socketEventAddBet = null;
        this.socketEventAddSlot = null;
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.MY_BETS, encode({ game: this.state.game }));
        socket.on(C.MY_BETS, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addList(decode(data)));
        socket.on(C.ADD_SLOT_BET, data => this.addList(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.socketEventMyBets.off();
        this.socketEventAddBet.off();
        this.socketEventAddSlot.off();
    }

    addList(player) {
        if (this._isMounted) {
            if (player.name === this.state.name) {
                this.setState(state => ({ players: [player, ...state.players] }));

                if (this.state.players.length > 10)
                    this.setState({ players: __.dropRight(this.state.players, this.state.players.length - 10) });
            }
        }
    }

    makeList(arr) {
        if (this._isMounted) {
            if (typeof arr.history === "undefined") return;
            this.setState({ loading: false });

            if (arr.history.length === 0)
                this.setState({ status: 'You have not played yet !' })

            this.setState({ players: arr.history })
        }
    }

    render() {
        const list = this.state.players.map((player, i) =>
            <Players forMy={true} key={i.toString()} player={player} />
        );
        return (
            <div className={'table-responsive last-bets num-style'}>

                {this.state.loading ?
                    <>
                        <div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status" />
                        </div>
                    </>
                    :
                    <Table className={"mb-0"}>
                        <thead>
                            <tr>
                                {!isMobile() &&
                                    <th>
                                        ID
                                    </th>
                                }
                                <th>
                                    BET
                                </th>
                                <th>
                                    PROFIT
                                </th>
                                <th>
                                    DATE/TIME
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list}
                        </tbody>
                    </Table>
                }
                {this.state.status !== null &&
                    <div className={'alert text-uppercase bg-soft-dark mt-2 font-13 text-white text-center'}>You have not played yet !</div>
                }
            </div>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    gameDetail = () => {
        Event.emit('single_game_modal', { data: this.props.player })
    }

    render() {
        let { forMy } = this.props;
        let { name, uid, amount, profit, coin, created, gid, direct } = this.props.player;
        let isWinner = false;
        let date = fixDate(created);

        if (direct) {
            date = timeConvertor(created);
        }

        if (__.toNumber(profit) !== 0.00000000)
            isWinner = true;

        return (
            <tr className={'q-crash'}>
                {!isMobile() &&
                    <td className="cpt" onClick={this.gameDetail}>
                        {gid}
                    </td>
                }

                {forMy === false &&
                    <td>
                        <UserModal id={uid} username={name} isWinner={isWinner} />
                    </td>
                }

                <td onClick={this.gameDetail} className={"num-style cpt text-white"}>
                    <img src={'/assets/images/' + __.toLower(coin) + '.png'} className={'mini-coin-2 hidden-sm cpt'} alt={coin} />
                    {forceSatoshiFormat(amount, coin, true)}
                </td>
                <td onClick={this.gameDetail} className={(isWinner === true) ? 'text-success-2 num-style cpt' : 'text-warning num-style cpt'}>
                    <img src={'/assets/images/' + __.toLower(coin) + '.png'} className={'mini-coin-2 hidden-sm cpt'} alt={coin} />
                    {forceSatoshiFormat(profit, coin, true)}
                </td>
                <td className="cpt" onClick={this.gameDetail}>
                    {date}
                </td>
            </tr>
        );
    }
}

export default Queue;
