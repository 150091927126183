import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gameSlots } from "../../actions/gameSlots";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Scroll from "react-scroll";
import storage from "../../Storage";
import { gameCoin } from "../../actions/gameCoin";
import {
  __,
  isMobile,
  wait,
  Event,
  requestFullScreen,
  decode,
  encode,
  getUID,
  filterSlotTitle,
} from "../../Helper";
import Bets from "../Pages/Parts/Home/Bets";
import TopBarGames from "../Components/Game/Addons/TopBar";
import ReactTooltip from "react-tooltip";
import Frame from "./Frame";
import Loading from "../Games/Loading";
import socket from "../../Socket";
import C from "../../Constant";
import Rakeback from "../Pages/Parts/Home/Rakeback";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Game extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      game: null,
      gameName: "",
      games: [],
      padding: "p-1",
      provider: null,
      houseEdge: null,
      randomslotgames: [],
      isFullscreen: false,
      isLogged: storage.getKey("logged") !== null ? true : false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.divRef = React.createRef();
  }

  componentDidMount() {
    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);
    this.getGame(this.props.history.location.pathname.replace("/slots/", ""));
    socket.emit(C.GET_HOUSEEDGE, encode({ game: "slots" }));
    socket.on(C.GET_HOUSEEDGE, (data) => {
      let { edge } = decode(data);
      this.setState({ houseEdge: edge });
    });

    // socket.emit(C.RANDOM_SLOT_GAME, storage.getKey('slot_provider'));
    socket.on(C.RANDOM_SLOT_GAME, (data) => {
      this.setState({ randomslotgames: decode(data) });
    });

    this.props.gameCoin();
  }

  componentDidUpdate() {
    if (this._Mounted) {
      SC.scrollTo("slotElement", {
        delay: 0.5,
        smooth: true,
        containerId: "st",
      });
    }
  }

  componentWillUnmount() {
    this._Mounted = false;
    socket.off(C.GET_HOUSEEDGE);
    window.removeEventListener("resize", this.handleResize);
    socket.off(C.GET_SLOT_BY_ID);
    socket.off(C.RANDOM_SLOT_GAME);
  }
  getGame(gameId, gameProvider) {
    // First, attempt to find the game in props
    storage.setKey("slot_provider", gameProvider);
    socket.emit(C.RANDOM_SLOT_GAME, gameProvider);
    let foundGame =
      this.props.slots &&
      this.props.topSlots &&
      this.props.popularSlots &&
      this.props.liveSlots
        ? this.findGameInProps(gameId, this.props)
        : false;

    if (foundGame) {
      // If we found the game in props, use that data
      this._Mounted = true;
      this.setState({
        gameName: foundGame.name,
        provider: foundGame.provider,
        game: foundGame,
      });
    } else {
      // If not found in props, then we send sockets to retrieve the game
      socket.emit(
        C.GET_SLOT_BY_ID,
        encode({ uuid: gameId, sort: "slots", user_id: getUID })
      );
      socket.on(C.GET_SLOT_BY_ID, (data) => {
        socket.off(C.GET_SLOT_BY_ID);
        let slots = decode(data);
        if (slots.length != 0) {
          this.setState({
            gameName: slots[0].name,
            provider: slots[0].provider,
            game: slots[0],
          });
          this._Mounted = true;
        }
      });
    }
  }

  findGameInProps(gameId, props) {
    // These are all arrays of slots. We check each to find our game
    let allSlots = [
      ...props.slots,
      ...props.topSlots,
      ...props.popularSlots,
      ...props.liveSlots,
    ];

    // Try to find the game with the given id
    return allSlots.find((slot) => slot.uuid === gameId);
  }
  // getGame(gameId, gameProvider) {
  //   storage.setKey('slot_provider', gameProvider);
  //   socket.emit(C.RANDOM_SLOT_GAME, gameProvider);

  //   socket.emit(
  //     C.GET_SLOT_BY_ID,
  //     encode({ uuid: gameId, sort: "slots", user_id: getUID })
  //   );
  //   socket.on(C.GET_SLOT_BY_ID, (data) => {
  //     socket.off(C.GET_SLOT_BY_ID);
  //     let slots = decode(data);
  //     if (slots.length != 0) {
  //       this.setState({
  //         gameName: slots[0].name,
  //         provider: slots[0].provider,
  //         game: slots[0],
  //       });
  //       this._Mounted = true;
  //     }
  //   });
  // }

  handleResize() {
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
    } else {
      this.setState({ col: "col-xl-11" });
    }
    if (isMobile()) {
      this.setState({ padding: "p-1" });
    }
  }

  fullscreen = () => {
    const { isFullscreen } = this.state;
    console.log(isFullscreen);
    const divElement = this.divRef.current;
    if (!isFullscreen) {
      if (divElement.requestFullscreen) {
        divElement.requestFullscreen();
      } else if (divElement.mozRequestFullScreen) {
        // Firefox
        divElement.mozRequestFullScreen();
      } else if (divElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        divElement.webkitRequestFullscreen();
      } else if (divElement.msRequestFullscreen) {
        // IE/Edge
        divElement.msRequestFullscreen();
      }
    } else {
      if (divElement.exitFullscreen) {
        divElement.exitFullscreen();
      } else if (divElement.mozCancelFullScreen) {
        divElement.mozCancelFullScreen();
      } else if (divElement.webkitExitFullscreen) {
        divElement.webkitExitFullscreen();
      } else if (divElement.msExitFullscreen) {
        divElement.msExitFullscreen();
      }
    }
  };

  openDepositModal = () => {
    if (this.state.isLogged) {
      Event.emit("deposit_open");
    } else {
      Event.emit("login_form");
    }
  };

  render() {
    const { isFullscreen } = this.state;
    let { col, padding, game, randomslotgames } = this.state;
    const { t } = this.props;
    const gid = this.props.history.location.pathname.replace("/slots/", "");

    const help = t("slot_game_help_content_1");
    var title =
      __.trim(this.state.gameName.replace(/Mobile/gi, "")) +
      " by " +
      this.state.provider +
      " - Crypto Casino BetsMixer";
    const slot_game_list =
      randomslotgames && randomslotgames.length > 0 ? (
        randomslotgames.map(
          (game, i) =>
            game.uuid && (
              <SwiperSlide>
                <div
                  key={i.toString()}
                  className={"ovh animated fadeInUp"}
                  style={{ padding: "5px" }}
                  onClick={() => this.getGame(game.uuid, game.provider)}
                >
                  <Link
                    to={game.name !== null ? "/slots/" + game.uuid : "#"}
                    className="slot-link slot-pan game-card-wrapper"
                  >
                    <div
                      className={
                        game.name !== null ? "slot-image" : "slot-image orange"
                      }
                    >
                      {/* <img
                        src={
                          "https://app.betsmixer.com/assets/slotegrator/" +
                          game.provider +
                          "/" +
                          game.image
                        }
                        className="img-fluid"
                        alt={game.name}
                      /> */}
                      <img
                        src={game.origin_url}
                        className="img-fluid"
                        alt={game.name}
                      />
                    </div>
                    <div className="slot-title">
                      <span className="slot-t-name">{game.name}</span>
                      <span className="slot-t-provider">{game.provider}</span>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            )
        )
      ) : (
        <></>
      );
    // title = __.startCase(title);
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href={"/" + game} />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row>
              <Col sm={12} className={"slotsdiv m-auto " + col + " " + padding}>
                <Card className="mb-0 inhousemenu inhousemenuslots">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header game-header-slots align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-0 text-white text-upper d-flex">
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            <Link
                              className="text-dark"
                              to={"/provider/" + this.state.provider}
                            >
                              {this.state.provider}
                            </Link>
                          </span>
                          <span className="badge bg-soft-secondary p-2 font-12 ml-2">
                            {filterSlotTitle(this.state.gameName)}
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            CASINO
                          </span>
                        </p>
                      </Col>
                      <Col
                        md={5}
                        className={
                          "col-5 text-right inhousebuttons inhousebuttonsslots"
                        }
                      >
                        <TopBarGames
                          gid={gid}
                          image="Slots"
                          help={help}
                          t={t}
                        />
                        <ReactTooltip />

                        <button
                          className="btn bg-cs2 btn-lg py-0 mt-1 px-2 fullscreenbutton"
                          data-tip={"Fullscreen"}
                          onClick={() => this.fullscreen()}
                        >
                          <i className="mdi mdi-fullscreen" />
                        </button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* GAME */}
                <Card className="mb-0">
                  <Card.Body
                    className={"bg-dice-options slotsgame p-1 -staticSnow"}
                    id="roll-panel"
                  >
                    <Row>
                      <Col sm={12} md={12} className={"m-auto " + padding}>
                        <div>
                          <Element name="slotElement" id="slotElement">
                            <div id="st">
                              <div className="ifr">
                                <div className="iframe-wrap">
                                  <div
                                    className={`iframe-main fullscreen-div ${
                                      isFullscreen ? "fullscreen" : ""
                                    }`}
                                    ref={this.divRef}
                                  >
                                    <Frame key={game.uuid} game={game} t={t} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Element>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {/* QUEUE */}
                <Card className="mb-5 lastbetscard slotspage">
                  <Card.Body className="p-1 mt-4 game-last-bets card-body">
                    <div className="p-2 mt-3 ourselection">
                      <Stack direction="horizontal" gap={3} className="mb-3">
                        <div className="d-flex align-items-center">
                          <img
                            className="img-fluid home-page-icons"
                            src="/assets/images/icons/slot-game.svg"
                          />
                          <p className="m-0 home-part-t">
                            {" "}
                            {t("our_selection")}
                          </p>
                        </div>

                        <div
                          className="buycreditcards"
                          onClick={this.openDepositModal}
                        >
                          <div>{t("buy_crypto")}</div>
                          <img src="/assets/images/mastercard.png" />
                        </div>
                      </Stack>
                      <Swiper
                        // spaceBetween={24}
                        slidesPerView={7}
                        loop={true}
                        autoplay={{
                          delay: 4500,
                          disableOnInteraction: false,
                        }}
                        // pagination={{
                        //     clickable: true,
                        //     el: ".service-swiper-pagination",
                        // }}
                        modules={[Pagination, Autoplay]}
                        className="home-services"
                        breakpointsBase="container"
                        breakpoints={{
                          200: {
                            spaceBetween: 0,
                            slidesPerView: 2,
                            centeredSlides: false,
                          },
                          300: {
                            spaceBetween: 0,
                            slidesPerView: 3,
                            centeredSlides: true,
                          },
                          600: {
                            spaceBetween: 0,
                            slidesPerView: 4,
                            centeredSlides: false,
                          },
                          750: {
                            spaceBetween: 0,
                            slidesPerView: 5,
                            centeredSlides: false,
                          },
                          905: {
                            spaceBetween: 0,
                            slidesPerView: 6,
                            centeredSlides: false,
                          },
                          1080: {
                            spaceBetween: 0,
                            slidesPerView: 7,
                            centeredSlides: false,
                          },
                          1100: {
                            spaceBetween: 0,
                            slidesPerView: 8,
                            centeredSlides: false,
                          },
                          1500: {
                            spaceBetween: 0,
                            slidesPerView: 9,
                            centeredSlides: false,
                          },
                        }}
                      >
                        {slot_game_list}
                      </Swiper>
                    </div>
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>

                <div className="p-2 mt-2">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="mt-2 mb-3 slot-providers-title"
                  >
                    <div className="d-flex align-items-center">
                      <img src="/assets/images/icons/magician.png" />
                      <p className="m-0 home-part-t">
                        {" "}
                        {t("game_providers")}{" "}
                        <Link
                          to="/providers"
                          className="font-12 text-warning providers-view-all"
                        >
                          View All
                        </Link>
                      </p>
                    </div>
                  </Stack>

                  <div className="-container">
                    {this.state.mobile ? (
                      <>
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={4}
                          slidesPerGroup={3}
                          loop={true}
                          autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                          modules={[Pagination, Autoplay]}
                          className="home-services"
                          breakpoints={{
                            300: {
                              slidesPerView: 2,
                              centeredSlides: false,
                            },
                            450: {
                              slidesPerView: 3,
                              spaceBetween: 20,
                            },
                            568: {
                              slidesPerView: 4,
                              spaceBetween: 35,
                            },
                          }}
                        >
                          <SwiperSlide>
                            <Link
                              to="/provider/PragmaticPlay"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/evolution"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/evolution.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/nolimit" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/nolimit.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/playngo" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/playngo.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/hacksaw" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/hacksaw.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/relax" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/relax.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/bgaming" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/bgaming.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/pushgaming"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/pushgaming.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/elk" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/elk.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/spinomenal"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/spinomenal.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                        </Swiper>
                      </>
                    ) : (
                      <>
                        <Swiper
                          spaceBetween={3}
                          slidesPerView={5}
                          slidesPerGroup={1}
                          loop={true}
                          autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                          modules={[Pagination, Autoplay]}
                          className="home-services"
                        >
                          <SwiperSlide>
                            <Link
                              to="/provider/PragmaticPlay"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/evolution"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/evolution.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/spinomenal"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/spinomenal.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/bgaming" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/bgaming.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/netent" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/netent.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/platipus"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/platipus.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/habanero"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/habanero.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/betsoft" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/betsoft.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/endorphina"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/endorphina.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/gamebeat"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/gamebeat.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/quickspin"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/quickspin.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/novomatic"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/novomatic.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/habanero"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/habanero.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/wazdan" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/wazdan.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/gameart" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/gameart.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link to="/provider/mascot" className="game2-link">
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/mascot.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/slotmill"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/slotmill.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Link
                              to="/provider/blueprint"
                              className="game2-link"
                            >
                              <Col className="p-2 provider-wrapper">
                                <div className="provider-box">
                                  <img
                                    className="img-fluid"
                                    src="https://cdn2.softswiss.net/logos/providers/white/blueprint.svg"
                                  />
                                </div>
                              </Col>
                            </Link>
                          </SwiperSlide>
                        </Swiper>
                      </>
                    )}
                  </div>
                </div>

                {/* Recommanded */}
                {this.state.games.length > 1 && (
                  <>
                    <h5 className="text-white font-16">{t("recommended")}</h5>
                    <Row>{this.state.games}</Row>
                  </>
                )}
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  slots: state.items.slots,
  topSlots: state.items.topSlots,
  popularSlots: state.items.popularSlots,
  liveSlots: state.items.liveSlots,
});

export default connect(mapStateToProps, { gameSlots, gameCoin })(Game);
