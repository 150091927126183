import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import UserModal from "../../Components/User/Stat/Modal";
import { encode, decode, wait, fixDate } from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

class PromoMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { t } = this.props;

    return (
      <Card className="m-2">
        <Card.Body className="affiliatebox">
          <div className="table-responsive num-style mb-0 tww">
            {this.state.loading ? (
              <div className="text-center">
                <div className="spinner-border text-info my-3" role="status" />
              </div>
            ) : (
              <>
                <Table
                  className={"mb-2 affiliatetable table table-striped mb-0"}
                >
                  <thead>
                    <tr>
                      <th className="bg-transparent text-white">Name</th>
                      <th className="bg-transparent text-white">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bg-transparent">Banners</td>
                      <td className="bg-transparent"><a href="" target="_blank"><i class="mdi mdi-link-variant font-15"></i>Download</a></td>
                    </tr>
                     <tr>
                      <td className="bg-transparent">Promotion banners</td>
                      <td className="bg-transparent"><a href="" target="_blank"><i class="mdi mdi-link-variant font-15"></i>Download</a></td>
                    </tr>
                     <tr>
                      <td className="bg-transparent">Landing Pages</td>
                      <td className="bg-transparent"><a href="" target="_blank"><i class="mdi mdi-link-variant font-15"></i>Download</a></td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default PromoMaterial;
