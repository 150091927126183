import React from "react";
import $ from "jquery";
import { Col, Row, Table, Stack, Nav } from "react-bootstrap";
import UserStat from "../../../Components/User/Stat/Modal";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import storage from "../../../../Storage";
import { Link } from "react-router-dom";
import {
  __,
  convertToTime,
  isMobile,
  forceSatoshiFormat,
  removeTrailingZeros,
  removeTrailingZerosFixNum,
  encode,
  decode,
  wait,
  Event,
  isValidNumber,
} from "../../../../Helper";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

class Bets extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bets: [],
      _bets: [],
      fakePlayers: [],
      stop: false,
      mobile: isMobile(),
      isLogged: storage.getKey("logged") !== null ? true : false,
      my_withdrawals: [],
      last_withdrawals: [],
    };
    this.handleResize = this.handleResize.bind(this);
  }

  filter_item = storage.getKey("last_bets_filter")
    ? storage.getKey("last_bets_filter")
    : "all_bets";

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    this._isMounted = true;

    if (this._isMounted) {
      socket.emit(C.LAST_BETS, encode({ filter: this.filter_item }));
      socket.on(C.LAST_BETS, (data) => {
        this.makeList(decode(data));
      });
      socket.emit(C.LAST_WITHDRAWALS);
      socket.on(C.LAST_WITHDRAWALS, (data) => {
        this.setState({ loading: false });
        let { my_withdrawals, last_withdrawals } = decode(data);
        this.setState({ my_withdrawals: my_withdrawals });
        this.setState({ last_withdrawals: last_withdrawals });
      });

      socket.on(C.ADD_BET, (data) => this.addBet(decode(data)));

      Event.on("connect", () => {
        this.setState({ stop: false });
      });

      Event.on("disconnect", () => {
        this.setState({ stop: true });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this._isMounted = false;
    socket.off(C.LAST_BETS);
    socket.off(C.ADD_BET);
    socket.off(C.LAST_WITHDRAWALS);
  }

  componentDidUpdate(prevProps, prevState) {
    var self = this;

    if (this.state.bets.length > 8) {
      $("#last_bets_table").find("tr").eq(1).css("display", "none");
      $("#last_bets_table").find("tr").eq(1).fadeToggle("normal");
      $("#last_bets_table")
        .find("tr")
        .last()
        .animate("normal", function () {
          self.setState({
            bets: __.dropRight(self.state.bets, self.state.bets.length - 8),
          });
        });
    }

    // let {  bets } = self.state;

    // _.shuffle(bets).forEach((bet, i) => {
    //   wait(1000 * i).then(() => {
    //     if(!_.isUndefined(bet))
    //     self.addBet(bet);
    //   });
    // });
  }

  makeList(data) {
    if (this._isMounted) {
      this.setState({ loading: false });
      if (data.length < 1) return;
      if (__.isUndefined(data)) return;
      if (!__.isArray(data)) return;

      this.setState({ bets: data });
    }
  }

  addBet(player) {
    if (this._isMounted) {
      if (player.game !== "crash") {
        if (!__.isNumber(player.game)) {
          switch (this.filter_item) {
            case "all_bets":
              this.setState((state) => ({ bets: [player, ...state.bets] }));
              break;

            case "high_rollers":
              this.setState((state) => ({
                bets: [player, ...state.bets].sort(
                  (a, b) => b.profit - a.profit
                ),
              }));

              break;
            case "my_bets":
              if (player.uid == storage.getKey("uid")) {
                this.setState((state) => ({ bets: [player, ...state.bets] }));
              }
              break;
            default:
              break;
          }
        }
      }
    }
  }

  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  openDepositModal = () => {
    if (this.state.isLogged) {
      Event.emit("deposit_open");
    } else {
      Event.emit("login_form");
    }
  };

  handleLastBetsFilter = (eventKey) => {
    if (!eventKey) {
      console.error("Last Bets Filter Error");
      return;
    }
    this.filter_item = eventKey;
    storage.setKey("last_bets_filter", eventKey);
    switch (eventKey) {
      case "last_withdrawals":
        socket.emit(C.LAST_WITHDRAWALS);
        break;
      case "my_withdrawals":
        socket.emit(C.LAST_WITHDRAWALS);
        break;
      default:
        socket.emit(C.LAST_BETS, encode({ filter: this.filter_item }));
        break;
    }

    this.setState({ loading: true, bets: [] });
  };

  render() {
    const { t } = this.props;

    this.state.bets.length = 8;

    const list = this.state.bets.map((player, i) => (
      <MakeBets t={t} key={__.toString(i)} data={player} />
    ));

    const my_withdrawals = this.state.my_withdrawals.map((withdrawal, i) => (
      <MakeWithdrawals t={t} key={__.toString(i)} data={withdrawal} />
    ));
    const last_withdrawals = this.state.last_withdrawals.map(
      (withdrawal, i) => (
        <MakeAllWithdrawals t={t} key={__.toString(i)} data={withdrawal} />
      )
    );
    return (
      <>
        {this._isMounted && (
          <>
            <Stack direction="horizontal" gap={3} className="mb-3">
              <div className="d-flex align-items-center">
                <img
                  className="img-fluid home-page-icons"
                  src="/assets/images/icons/last-bets.svg"
                />
                <p className="m-0 home-part-t"> {t("last_bets")}</p>

                <div
                  className="buycreditcardsinhouse"
                  onClick={this.openDepositModal}
                >
                  <div>{t("buy_crypto")}</div>
                  <img src="/assets/images/mastercard.png" />
                </div>
              </div>
            </Stack>
            <Nav
              variant="underline"
              className="last-bets-filter"
              defaultActiveKey={this.filter_item}
              onSelect={this.handleLastBetsFilter}
            >
              <Nav.Item>
                <Nav.Link eventKey={"all_bets"}>
                  <div className="item-wrapper">{t("all_bets")}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={this.state.isLogged ? "hidden" : "hidden"}>
                <Nav.Link eventKey="high_rollers">
                  <div className="item-wrapper">{t("high_rollers")}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={this.state.isLogged ? "" : "hidden"}>
                <Nav.Link eventKey="my_bets">
                  <div className="item-wrapper">{t("my_bets")}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="last_withdrawals_table">
                <Nav.Link eventKey="last_withdrawals">
                  <div className="item-wrapper">{t("last_withdrawals")}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={this.state.isLogged ? "hidden" : "hidden"}>
                <Nav.Link eventKey="my_withdrawals">
                  <div className="item-wrapper">{t("my_withdrawals")}</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Row>
              <Col sm={12} className="last-bets-wrapper">
                <div className="table-responsive last-bets num-style mb-0 last-bets-index img-fix">
                  {this.state.loading ? (
                    <div className="text-center">
                      <div
                        className="spinner-grow text-white mt-5"
                        role="status"
                      />
                    </div>
                  ) : (
                    (() => {
                      switch (this.filter_item) {
                        case "my_withdrawals":
                          return this.state.my_withdrawals.length > 0 ? (
                            <Table
                              id="last_bets_table"
                              className={"main-table mb-"}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>wallet</th>
                                  <th>{t("amount")}</th>
                                  {!this.state.mobile && <th>{t("time")}</th>}
                                  <th>status</th>
                                </tr>
                              </thead>
                              <tbody>{my_withdrawals}</tbody>
                            </Table>
                          ) : (
                            <p className="last-bets-empty">{t("no_history")}</p>
                          );
                        case "last_withdrawals":
                          return this.state.last_withdrawals.length > 0 ? (
                            <Table
                              id="last_bets_table"
                              className={"main-table mb-2 last_withdrawals"}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>{t("username")}</th>
                                  <th>{t("amount")}</th>
                                  {!this.state.mobile && <th>{t("time")}</th>}
                                  <th>{t("status")}</th>
                                </tr>
                              </thead>
                              <tbody>{last_withdrawals}</tbody>
                            </Table>
                          ) : (
                            <p className="last-bets-empty">{t("no_history")}</p>
                          );
                        default:
                          return this.state.bets.length > 0 ? (
                            <Table
                              id="last_bets_table"
                              className={"main-table mb-2"}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>{t("game")}</th>
                                  <th>{t("username")}</th>
                                  <th>{t("amount")}</th>
                                  {!this.state.mobile && <th>{t("time")}</th>}
                                  <th>{t("profit")}</th>
                                </tr>
                              </thead>
                              <tbody>{list}</tbody>
                            </Table>
                          ) : (
                            <p className="last-bets-empty">{t("no_history")}</p>
                          );
                      }
                    })()
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

class MakeBets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  gameDetail = () => {
    Event.emit("single_game_modal", { data: this.props.data });
  };

  render() {
    if (__.isUndefined(this.props.data)) return "";
    let {
      game,
      gid,
      uid,
      created,
      name,
      amount,
      profit,
      coin,
      hash,
      force,
      avatar,
      slot_round_id,
      provider,
      image,
      uuid,
      result,
      origin_url,
    } = this.props.data;

    console.log(this.props.data);

    if (__.isUndefined(game)) return "";

    // if (__.isUndefined(result) || _.isNull(result)) return "";

    //if is slot game
    // if (isValidNumber(game)) return "";

    coin = __.toLower(coin);
    let isWinner = false;
    hash = hash !== null ? hash : "0000";
    amount = forceSatoshiFormat(amount, coin, false);
    profit = forceSatoshiFormat(profit, coin, false);

    // ###later
    if (
      parseFloat(profit) !== 0.0 &&
      parseFloat(profit) !== 0 &&
      parseFloat(profit) > 0 &&
      parseFloat(profit).toFixed(8) > 0.000001
    ) {
      isWinner = true;
    }

    game = __.replace(game, "_", " ");
    let gameName = __.replace(game, "snakeandladders", "Snake");
    gameName = __.replace(gameName, "singlekeno", "S-Keno");
    gameName = __.replace(gameName, "cardmonte", "Card Monte");

    // let my_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timestamp = new Date(created);
    let options = { hour: "numeric", minute: "numeric", hour12: true };
    let timeString = timestamp.toLocaleTimeString("en-US", options);

    const { t } = this.props;
    return (
      <tr className={isWinner ? "iswinner q-crash2" : "q-crash2"}>
        <td className="cpt transf-capitalize">
          <img
            src={
              slot_round_id && game != "sports"
                ? origin_url
                : "/assets/images/covers_new/" + game + ".svg"
            }
            className={"img-fluid mr-2"}
            alt={game}
          />
          {slot_round_id && game != "sports" ? (
            <Link
              to={
                name !== null
                  ? "/slots/" + uuid
                    ? "/slots/" + uuid
                    : gid
                  : "#"
              }
            >
              {!this.state.mobile && __.upperFirst(gameName)}
            </Link>
          ) : (
            <Link
              to={gameName !== null ? "/" + __.replace(game, " ", "-") : "#"}
            >
              {!this.state.mobile && __.upperFirst(gameName)}
            </Link>
          )}

          {/* {!this.state.mobile && __.upperFirst(gameName)} */}
        </td>
        <td>
          {/* <i className={'mdi mdi-checkbox-blank-circle align-middle mr-1'} /> */}
          {/* <div className="d-flex align-items-center gap-2">
            <div className="d-flex justify-content-center align-items-center bets-avatar-wrapper">
              <img className="rounded thumb-xs" src={avatar} />
            </div> */}
          <img
            src={avatar}
            alt="user"
            className="rounded-circle thumb-xs mr-1 hidden-sm"
          />
          {name}
          {/* </div> */}
        </td>
        <td className="num-style cpt" onClick={this.gameDetail}>
          <div className="d-flex align-items-center">
            <img
              src={"/assets/images/" + __.replace(coin, "_", "-") + ".png"}
              className={"mini-coin-2"}
              alt={coin}
            />
            {/* <span>{amount.replace(/\.?0+$/, "")}</span> */}
            {/* <span>{removeTrailingZerosFixNum(amount, 2)}</span> */}
            <span>{removeTrailingZeros(amount)}</span>
            <span className="last-bets-amount-wrapper">
              {__.split(coin, "_")[0]}
            </span>
          </div>
        </td>
        {!this.state.mobile && (
          <td className="num-style cpt" onClick={this.gameDetail}>
            {/* {time} */}
            {timeString}
          </td>
        )}
        <td className="num-style" onClick={this.gameDetail}>
          <img
            src={"/assets/images/" + __.replace(coin, "_", "-") + ".png"}
            className={"mini-coin-2"}
            alt={coin}
          />
          {/* <span>{removeTrailingZerosFixNum(profit, 2)}</span> */}
          <span>{parseFloat(profit).toFixed(8) > 0.000001 ? profit : 0}</span>
          <span className="last-bets-amount-wrapper">
            {__.split(coin, "_")[0]}
          </span>
        </td>
      </tr>
    );
  }
}

class MakeWithdrawals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  render() {
    if (__.isUndefined(this.props.data)) return "";
    let { date, amount, wallet, status, coin } = this.props.data;
    if (status === "Completed") status = "Paid";
    let timestamp = new Date(date);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    let dateTimeString = timestamp.toLocaleDateString("en-US", options);

    const { t } = this.props;
    return (
      <tr className="q-crash2">
        <td>{wallet}</td>
        <td className="num-style cpt">
          <div className="d-flex align-items-center">
            <img
              src={"/assets/images/" + __.replace(coin, "_", "-") + ".png"}
              className={"mini-coin-2"}
              alt={coin}
            />
            <span>{removeTrailingZeros(parseFloat(amount).toFixed(2))}</span>
            <span className="last-bets-amount-wrapper">
              {__.split(coin, "_")[0]}
            </span>
          </div>
        </td>
        {!this.state.mobile && (
          <td className="num-style cpt">{dateTimeString}</td>
        )}
        <td className="num-style">
          <span>{status}</span>
        </td>
      </tr>
    );
  }
}

class MakeAllWithdrawals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ mobile: isMobile() });
  }

  render() {
    if (__.isUndefined(this.props.data)) return "";
    let { date, amount, status, coin } = this.props.data;
    if (status === "Completed") status = "Paid";
    let timestamp = new Date(date);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    let dateTimeString = timestamp.toLocaleDateString("en-US", options);

    const { t } = this.props;
    return (
      <tr className="q-crash2">
        <td>
          <UserStat
            t={t}
            id={this.props.data.id}
            username={this.props.data.name}
            // chart_coin={coin}
            avatar={this.props.data.avatar}
            isWinner={true}
          />
        </td>
        <td className="num-style cpt">
          <div className="d-flex align-items-center">
            <img
              src={"/assets/images/" + __.replace(coin, "_", "-") + ".png"}
              className={"mini-coin-2"}
              alt={coin}
            />
            <span>{removeTrailingZeros(parseFloat(amount).toFixed(2))}</span>
            <span className="last-bets-amount-wrapper">
              {__.split(coin, "_")[0]}
            </span>
          </div>
        </td>
        {!this.state.mobile && (
          <td className="num-style cpt">{dateTimeString}</td>
        )}
        <td className="num-style">
          <span>{status}</span>
        </td>
      </tr>
    );
  }
}
export default Bets;
