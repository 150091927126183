import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { __ } from "../../Helper";

const RadioDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange }, ref) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="radio"
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
          className="d-flex align-items-center sort-dropdown-item"
        />
      </Form.Group>
    );
  }
);

export const RadioboxDropdown = observer(({ variant, items, checkUpdate, t }) => {
  const handleChecked = (key, event) => {
    checkUpdate(variant, key, event.target.checked);
    // items.find((i) => i.id === key).checked = event.target.checked;
  };

  return (
    <Dropdown>
        <Dropdown.Toggle variant="custom" id={"dropdown_" + variant + "_list"}>
            <span className="caret ms-1">{__.capitalize(variant)}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu
            className="sort-dropdown"
        >
            {items.map((i) => (
            <Dropdown.Item
                key={i.id}
                as={RadioDropdownItem}
                id={i.id}
                checked={i.checked}
                onChange={handleChecked}
            >
                {i.label}
            </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
  );
});
