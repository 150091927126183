import React from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Games from "../Pages/Parts/Home/Games";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: null
        }
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    change = (e) => {
        let value = e.target.value;
        this.setState({ search: value });
    }

    render() {
        return <>
            <Helmet>
                <title>Original Games | Crypto Casino Games, Bitcoin Casino</title>
                <meta name="description" content="BetsMixer, Best crypto casino Site, instant BTC deposits & withdrawals, Best alternative Roobet crash and bc.game, Provably fair bitcoin casino & slots. Play crash bitcoin game, fun cryptocurrency games!" />
                <meta name="og:title" content="BetsMixer, Best crypto casino Site, instant BTC deposits & withdrawals, Best alternative Roobet crash and bc.game, Provably fair bitcoin casino & slots. Play crash bitcoin game, fun cryptocurrency games!" />
                <meta name="og:description" content="BetsMixer, Best crypto casino Site, instant BTC deposits & withdrawals, Best alternative Roobet crash and bc.game, Provably fair bitcoin casino & slots. Play crash bitcoin game, fun cryptocurrency games!" />
                <link rel="canonical" href="/games" />
            </Helmet>
            <div>
                <div className="py-1">
                    <Card.Body>
                        <div id="st">
                            <h4 className="mt-2 mb-2 section-title"> Original Games</h4>
                            <Games />
                        </div>
                    </Card.Body>
                </div>
            </div>
        </>
    }
}

export default Index;
