import React from "react";
import { Table } from "react-bootstrap";
import storage from "../../../Storage";
import { Event, decode, wait, fixDate } from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

export default class Affiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
    };
  }

  componentDidMount() {
    wait(1700).then(() => {
      this.setState({ loading: false });
    });
    // socket.emit(C.MY_AFF, encode({token: storage.getKey('token')}));
    // socket.on(C.MY_AFF, data => this.makeList(data));

    socket.emit(C.GET_REFERRED_USER);
    socket.on(C.GET_REFERRED_USER, (data) => {
      if (data !== null) {
        this.makeList(decode(data));
        Event.emit("active_affilate");
      }
    });
  }

  componentWillUnmount() {
    // socket.off(C.MY_AFF);
    socket.off(C.GET_REFERRED_USER);
  }

  makeList = (data) => {
    this.setState({ loading: false, list: data });
  };

  render() {
    const { t } = this.props;
    const list = this.state.list.map((row, i) => <List key={i} user={row} />);

    return (
      <div className="table-responsive">
        {this.state.loading ? (
          <div className="text-center">
            <div className="spinner-border text-info my-3" role="status" />
          </div>
        ) : (
          <>
            {list.length === 0 ? (
              <>{t("you_have_not_introduced_anyone_yet")}</>
            ) : (
              <Table className={"mb-2 affiliatetable"}>
                <thead>
                  <tr>
                    <th className="bg-transparent text-white">Username</th>
                    <th className="bg-transparent text-white">
                      Date Registered
                    </th>
                    <th className="bg-transparent text-white">Total Wager</th>
                    <th className="bg-transparent text-white">
                      Total Comission
                    </th>
                    <th className="bg-transparent text-white text-start">
                      Total Deposits
                    </th>
                  </tr>
                </thead>
                <tbody>{list}</tbody>
              </Table>
            )}
          </>
        )}
      </div>
    );
  }
}

class List extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <tr>
        <td className="bg-transparent text-white-50">
          <a class="user-avatar">
            <img
              src={user.avatar}
              alt={user.name}
              class="rounded-circle"
              width="25px"
            />
          </a>{" "}
          <span className="nameaff">{user.name}</span>
        </td>
        <td className="bg-transparent text-white-50">
          {fixDate(user.created)}
        </td>
        <td className="bg-transparent text-white-50">
          ${parseFloat(user.wageredAmount).toFixed(2)}
        </td>
        <td className="bg-transparent text-white-50 text-yellow text-yellow-yellow">
          ${(parseFloat(user.wageredAmount) * 0.01 * 0.05).toFixed(2)}
        </td>
        <td className="bg-transparent text-white-50 text-start">
          {user.depositCount}
        </td>
      </tr>
    );
  }
}
