import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Row, Col, Dropdown } from "react-bootstrap";
import coins from "../../../coins";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import isLogged from "../../../../Auth";
import C from "../../../../Constant";
import {__, wait, forceSatoshiFormat, usdToCrypto, isValidNumber, sendNotfication} from "../../../../Helper";

class Rain extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      loading: false,
      show: false,
      disabled: false,
      coins: [],
      players: 2,
      amount: 1.000000,
      amountUSD: 1.000000,
      coin: "usdt",
      clientCountry: storage.getKey("country")
        ? storage.getKey("country")
        : "GLOBAL",
      currentCoin: "USDT",
      err_players: false,
      err_txt: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      coins.forEach((item, i) => {
        if (item.preffix === "NC") return;

        let list = (
          <Dropdown.Item
            key={__.toString(i)}
            as={"button"}
            className={"animated fadeIn"}
            onClick={(e) => this.selectCoin(e, item.preffix)}
          >
            <span>
              <img
                src={"/assets/images/" + item.image}
                className={"img-fluid mini-coin mr-1"}
                alt="Coin"
              />{" "}
              {item.preffix}
            </span>
          </Dropdown.Item>
        );

        this.setState((state) => ({ coins: [list, ...state.coins] }));
      });
      socket.on(C.RAIN, (data) => this.rain(data));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.RAIN);
  }

  selectCoin = (e, coin) => {
    e.preventDefault();
    this.setState({ amount: usdToCrypto(this.state.amountUSD, coin) });
    this.setState({ currentCoin: coin });
  };

  handleShow = () => {
    if (this._isMounted) {
      if (!isLogged()) {
        return sendNotfication(this.props.t("t0"), "warning", "top-center");
      }
      this.setState({ show: true, effect: "zoomIn" });

      wait(500).then(() => {
        this.setState({ loader: false });
      });
    }
  };

  handleClose = () => {
    if (this._isMounted) {
      this.setState({ 
        show: false, 
        effect: "zoomOut", 
        loader: true,
        disabled: false,
        players: 2,
        amount: usdToCrypto(1, "usdt"),
        amountUSD: 1,
        coin: "usdt",
        currentCoin: "USDT",
      });
    }
  };

  rain(data) {
    if (this._isMounted) {
      let { message } = data;
      this.setState({ loading: false, disabled: false });
      sendNotfication(this.props.t(message), "info", "top-center");
      socket.emit(C.GET_USER_PROFIT);
    }
  }

  handleInputChange = (e) => {
    if (this._isMounted) {
      let target = e.target;
      let value = target.value;
      let name = target.name;
      if (/^.*[a-zA-Z].*$/.test(value)) return;

      if (name === "amount") {
        let toNumber = __.toNumber(value);
        if (toNumber < 50e-8) {
          this.setState({ amount: "0.00000050" });
          return;
        }
        if (value >= 999999999) return;
        if (value.length > 10) return;
        if (!isValidNumber(value)) return;
        const amountUSD = parseFloat(forceSatoshiFormat(value, this.state.currentCoin, true));
        this.setState({ amountUSD } );
      }

      if (name === "players") {
        value = __.toNumber(value);
        if(!value) value = 0;
        if (value > 10) {
          this.setState({ err_players: true, err_txt: 'maximum_players' });
        }
        else if (value < 2) {
          this.setState({ err_players: true, err_txt: 'minimum_players' });
        }
        else {
          this.setState({ err_players: false });
        }
      }
      this.setState({ [name]: value });
    }
  };

  submit = (e) => {
    if (this._isMounted) {
      e.preventDefault();

      if(this.state.err_players) return;

      this.setState({ loading: true, disabled: true });

      var c = this.state.clientCountry;

      if (__.toLower(c) === "spam") c = "brazil";

      wait(500).then(() => {
        socket.emit(C.RAIN, {
          amount: this.state.amount,
          coin: this.state.currentCoin,
          players: parseInt(this.state.players),
          // room: c,
          room: "GLOBAL"
        });
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <button
          className={"btn btn-soft-light btn-xs chat-room-rain-btn"}
          onClick={this.handleShow}
        >
          <img className="img-fluid" src="/assets/images/icons/chat_rain.svg" />
        </button>
        <Modal
          size="md"
          backdrop="static"
          centered={true}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="rain-md-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header>
            {t("rain")}{" "}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className={"rain"}>
            {this.state.loader ? (
              <>
                <div className="text-center">
                  <div className="spinner-grow text-white my-3" role="status" />
                </div>
              </>
            ) : (
              <>
                <form onSubmit={(e) => this.submit(e)}>
                  <Row>
                    <Col sm={12} md={4} xl={4}>
                      <label htmlFor="coin">{t("coin")}</label>
                      <Dropdown bottom="true">
                        <Dropdown.Toggle
                          split
                          variant="x mt-1 text-white btn-block"
                          id="dropdown-split-coins"
                        >
                          <span stle={{ fontSize: 17 }}>
                            <img
                              src={
                                "/assets/images/" +
                                __.toLower(this.state.currentCoin) +
                                ".png"
                              }
                              className={"img-fluid mini-coin-12 mr-2"}
                              alt="Coin"
                            />
                            {this.state.currentCoin}
                          </span>
                          <span className="ml-1 nav-user-name hidden-sm">
                            <span className="caret"></span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className={"droper user-dropdown-detail coins-drop"}
                        >
                          {this.state.coins}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col sm={12} md={5} xl={5}>
                      <label htmlFor="amount">{t("amount")}</label>
                      <input
                        type="text"
                        name={"amount"}
                        className={"form-control"}
                        onChange={this.handleInputChange}
                        value={this.state.amount}
                      />
                    </Col>
                    <Col sm={12} md={3} xl={3}>
                      <label htmlFor="players">{t("players_num")}</label>
                      <input
                        required={true}
                        type="text"
                        name={"players"}
                        className={"form-control"}
                        onChange={this.handleInputChange}
                        value={this.state.players}
                        autoComplete="off"
                      />
                    </Col>
                    {this.state.err_players ? <div className="help-block p-1 mt-1">{t(this.state.err_txt)}</div> : <></>}
                    <Col sm={12} md={12} xl={12}>
                      <button
                        disabled={this.state.disabled}
                        type={"submit"}
                        className={
                          "btn btn-block btn-purple mt-2 no-shadow rainbutton"
                        }
                      >
                        {this.state.loading ? (
                          <>
                            <div
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                          </>
                        ) : (
                          <i className={"mdi mdi-parachute mr-1"} />
                        )}
                        {t("send_rain")}
                      </button>
                    </Col>
                  </Row>
                </form>
                <p className={"mt-2 text-white text-center mb-0 raintext"}>
                  {t("t1")}
                  <br />
                  {t("t2")}
                </p>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

Rain.propTypes = {
  country: PropTypes.func,
};

const mapStateToProps = (state) => ({
  country: state.items.country,
});

export default connect(mapStateToProps, {})(Rain);
