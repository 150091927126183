import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Slots from "../../Slots/Slots";

export default class SummerDrops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  componentWillMount() {}

  render() {
    const { t } = this.props;

    return (
      <>
        <Card className="bg-transparent">
          <Card.Body>
            <Row>
              <Col sm={12} className="text-center">
                <img
                  className="img-fluid"
                  src="/assets/images/tournamments/moneyrain.png"
                />
              </Col>

              <Col sm="12">
                
                <h1 className="font-30">Summer Drops $100,000
                </h1>
                <hr style={{ borderColor: "#555" }} />
                
                 <h2 className="font-20">
                  Total Prize <span className="text-warning">$100,000</span>
                </h2>
                 <h2 className="font-20">
                  Duration:  June 1, 2024, 00:00 UTC - August 31, 2024, 23:59:59
                  UTC
                </h2>
                <hr style={{ borderColor: "#555" }} />

                <h2 className="font-20">Prize Distribution</h2>
                <p className="font-14">
                  Prize Breakdown: <br />
                  <ul>
                  <li>
                  1 prize of{" "}
                  <span className="text-warning">$2,000</span>
                  </li>
                  <li>
                  13 prizes
                  of $1,000 
                  </li>
                  <li>
                   20 prizes of $500 
                  </li>
                  <li>
                   100 prizes of $100{" "}
                  </li>
                  <li>
                  200 prizes of $50 
                  </li>
                  <li>
                  1,300 prizes of $10 
                  </li>
                  <li>
                  8,400 prizes of $5{" "}</li>
                </ul>
                </p>
                <h2 className="font-20">Term & Conditions</h2>
                <ul>
                  <li> The event is open to all BetsMixer members. </li>
                  <li> Prize payouts may be delayed by up to 72 hours.</li>
                  <li> BetsMixer reserves the right to amend, suspend, or cancel the event at
                    any time without prior notice.
                  </li>
                  <li> BetsMixer decision regarding the event and its outcomes is
                    final.
                  </li>
                </ul>

                <br />

                <h2 className="font-20">Participating Games</h2>

                <div className="slotstournaments">
                  <Slots t={t} home={true} specific="top_game" items={21} />
                </div>

                <Link to="/slots" className="btn btn-lg btn-warning text-dark">
                  Play Now
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}
