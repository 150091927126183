import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import storage from "../../../Storage";
import {encode} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ask = this.ask.bind(this);
    }

    ask(){
        Swal.fire({
            title: this.props.t('are_you_sure_to_logout'),
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.props.t('yes') + '!',
            cancelButtonText: this.props.t('cancel')
        }).then((result) => {
            if (result.value)
            {
                socket.emit(C.LOGOUT_USER);
                localStorage.clear();
                Cookies.remove("session");
                Cookies.remove("auth");
                Cookies.remove("uid");
                window.location.replace('./');
            }
        });
    }

    render(){
        const { t } = this.props;
        return(<>
                <a onClick={this.ask} className={"dropdown-item"}>
                    {/* <i className="dripicons-exit text-muted ml-1 mr-2 text-drop"/> */}
                    <svg className="mr-2 text-drop" fill="#7a88af" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" stroke="#7a88af" strokeWidth="0.704">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"> <metadata> <sfw xmlns="&amp;ns_sfw;"> <slices> </slices> <slicesourcebounds width="505" height="984" bottomleftorigin="true" x="0" y="-120"> </slicesourcebounds> </sfw> </metadata> 
                            <g> 
                                <g> 
                                    <g> 
                                        <path d="M15,24H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v7c0,0.6-0.4,1-1,1s-1-0.4-1-1V2H2v20h12v-6 c0-0.6,0.4-1,1-1s1,0.4,1,1v7C16,23.6,15.6,24,15,24z"></path> 
                                    </g> 
                                </g> 
                                <g> 
                                    <g> 
                                        <path d="M23,13H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h15c0.6,0,1,0.4,1,1S23.6,13,23,13z"></path> 
                                    </g> 
                                </g> 
                                <g> 
                                    <g> 
                                        <path d="M23,13c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4,4c0.4,0.4,0.4,1,0,1.4C23.5,12.9,23.3,13,23,13z "></path> 
                                    </g> 
                                </g> 
                                <g> 
                                    <g> 
                                        <path d="M19,17c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-4,4C19.5,16.9,19.3,17,19,17z "></path> 
                                    </g> 
                                </g> 
                            </g> 
                        </g>
                    </svg>
                    {t('logout')}
                </a>
        </>);
    }
}

export default withRouter(Logout);
