import MyEmitter from "events";
import socket from "../../../Socket";
import { __, encode, decode, forceSatoshiFormat } from "../../../Helper";
import C from "../../../Constant";
/**
 * Game Engine
 */
function Engine() {
  let self = this;

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Game Name
   */
  self.game = "hilo";

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Intialize Game
   */
  self.init = false;

  /**
   * Game Status
   * 1 type: started
   */
  self.gameStatus = null;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /*
   * Game Cards
   */
  self.cards = [];

  /*
   * Game selected high or low
   */
  self.selected = null;

  /**
   * Player profit
   */
  self.profit = 0.0;

  /**
   * Round Winner
   */
  self.roundWin = 0;

  /**
   * Rounds
   */
  self.rounds = 1;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_HILO, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_HILO);
  self.cards = [];
  self.selected = null;
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  self.gameStatus = "started";

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_HILO,
    encode({
      command: "play",
      amount: self.amount,
      coin: self.coin
    })
  );
};

/**
 * Cashout Player
 */
Engine.prototype.cashOut = function () {
  let self = this;

  if (!self.init) return;

  self.gameStatus = null;

  self.ws.emit(
    C.PLAY_HILO,
    encode({
      command: "cashout",
      lost: false,
      rounds: self.rounds,
      amount: self.amount,
      coin: self.coin,
      cards: self.cards,
      selected: self.selected,
    })
  );

  self.roundWin = 0;
  self.cards = [];
  self.rounds = 1;
};

/**
 * Player has Lost the round
 */
Engine.prototype.lost = function () {
  let self = this;

  self.gameStatus = null;
  self.roundWin = 0;

  self.trigger.emit("lost");

  //Send Lost To server
  self.ws.emit(
    C.PLAY_HILO,
    encode({
      command: "cashout",
      lost: true,
      profit: 0.0,
      amount: self.amount,
      coin: self.coin,
      cards: self.cards,
      selected: self.selected,
    })
  );

  self.rounds = 1;
  self.cards = [];
};

/**
 * Add Cards
 */
Engine.prototype.addCards = function (cards, next) {
  let self = this;
  self.cards.push(cards, next);
};

/**
 * Set Higher Card
 */
Engine.prototype.next = function (type) {
  let self = this;

  self.ws.emit(
    C.PLAY_HILO,
    encode({
      command: "next",
      type: type,
      rounds: self.rounds,
    })
  );

  self.rounds++;
};

/**
 * Player has Win the round
 */
Engine.prototype.win = function () {
  let self = this;

  self.roundWin += 1;
  self.trigger.emit("win");
};

/** Start Game Engine **/
export default Engine;
