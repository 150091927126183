import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import md5 from "md5";
import ReactTooltip from "react-tooltip";
import { Router, BrowserRouter } from "react-router-dom";
import { Col, Row, Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";
// import { Button, Modal } from 'flowbite-react';
import { ReactNotifications } from "react-notifications-component";
import i18next from "i18next";
import i18n from "i18next";

import {
  I18nextProvider,
  initReactI18next,
  useTranslation,
} from "react-i18next";
import history from "./history";
import Route from "./Router";
import socket from "./Socket";
import storage from "./Storage";
import axios from "axios";
import { gameSlots } from "./actions/gameSlots";
import { setSportsBooks } from "./actions/gameSports";
import {
  setIPAddress,
  setIpDetails,
  setProviders,
} from "./actions/setIPAddress";
import C from "./Constant";
import Layout from "./Layout";
import Details from "./App/Components/User/Stat/Details";
import Withdrawl from "./App/Components/User/Wallet/Withdrawl";
import TipUser from "./App/Components/User/Tip";
import UserLink from "./App/Components/User/Stat/Modal";
import {
  __,
  Event,
  encode,
  decode,
  fixDate,
  forceSatoshiFormat,
  wait,
  timeConvertor,
  sendNotfication,
  chkd,
  getElement,
  DEVELOPMENT,
  SITE,
  isMobile,
  IPAPI,
} from "./Helper";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationES from "./locales/es/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationPL from "./locales/pl/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationHI from "./locales/hi/translation.json";
import translationCS from "./locales/cs/translation.json";
import translationVI from "./locales/vi/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationUK from "./locales/uk/translation.json";
import translationFI from "./locales/fi/translation.json";
import translationID from "./locales/id/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationKO from "./locales/ko/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import Loader from "./App/Components/Loader";
import WebApp from "@twa-dev/sdk";

const resources = {
  en: {
    translation: translationEN,
  },
  "en-US": {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  ar: {
    translation: translationAR,
  },
  pl: {
    translation: translationPL,
  },
  pt: {
    translation: translationPT,
  },
  hi: {
    translation: translationEN,
  },
  cs: {
    translation: translationCS,
  },
  vi: {
    translation: translationVI,
  },
  tr: {
    translation: translationTR,
  },
  uk: {
    translation: translationUK,
  },
  fi: {
    translation: translationFI,
  },
  id: {
    translation: translationID,
  },
  ja: {
    translation: translationJA,
  },
  zh: {
    translation: translationZH,
  },
  ko: {
    translation: translationKO,
  },
};

const DETECTION_OPTIONS = {
  order: [
    "localStorage",
    "navigator",
    "querystring",
    "cookie",
    "sessionStorage",
    "htmlTag",
    "path",
    "subdomain",
  ],
  // cacheds: ['localStorage']
};

i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    // lng: storage.getKey("lng") ? storage.getKey("lng") : 'en',
    lng: undefined,
    resources,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    // detection: DETECTION_OPTIONS,
  });

const delay = DEVELOPMENT ? 500 : 700;

// loading component for suspense fallback
function App() {
  return (
    <Suspense fallback={<></>}>
      <Page />
    </Suspense>
  );
}

function Page() {
  const { t } = useTranslation();
  return <ConnectedApplication t={t} />;
}

class Application extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      welcome: storage.getKey("welcome") ? true : false,
      loading_effect: "",
    };
    socket.emit(C.GET_LNG_DETECTION_SETTING);
    socket.on(C.GET_LNG_DETECTION_SETTING, (data) => {
      let detection_setting = Number(decode(data));
      if (detection_setting == 0) {
        i18next.use(initReactI18next).init({
          lng: storage.getKey("lng") ? storage.getKey("lng") : "en",
          resources,
          fallbackLng: "en",
          interpolation: { escapeValue: false },
          // detection: DETECTION_OPTIONS,
        });
      }
    });

    this.preload();
  }

  componentDidMount() {
    WebApp.expand();
    this._isMounted = true;
    // this.preload();
    socket.on("U2t5cGU6IEBQZWRyb3hhbQ", (key) => this.security(key));
    this.security();
    Event.on("security", () => {
      chkd();
    });
    // fetch("https://geolocation-db.com/json/")
    fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=2e352859d8cc4017acb33698fbede100"
    )
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log("user_detail", res);

        // if(!storage.getKey("is_lng_changed")) {
        //   const eventKey = res.country.languages[0].iso_code;
        //   i18n.changeLanguage(eventKey);
        //   storage.setKey("lng", eventKey);
        // }

        // this.props.setIPAddress(res.country.name);
        // this.props.setIpDetails(res.ip, res.country.iso_code, res.country.name);
        socket.emit(C.ACTIVE_PAYMENT, encode(res.country.name));
      })
      .catch((err) => console.log(err));
    wait(700).then(() => {
      this.setState({ loading_effect: "d-none" });
    });
    socket.on(C.ACTIVE_PAYMENT, (data) => {
      let providers = decode(data);
      this.props.setProviders(providers);
    });
    const h = window.location.host
      ? window.location.host
      : window.location.hostname;
    storage.setKey("key", md5(h));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  preload() {
    let self = this;
    socket.emit(C.GET_INITIAL_ALL_GAMES, encode({ is_mobile: isMobile() }));

    socket.on(C.GET_INITIAL_ALL_GAMES, (data) => {
      this.props.gameSlots(decode(data));
      socket.off(C.GET_INITIAL_ALL_GAMES);
      socket.emit(C.GET_ABLE_SPORTSBOOKS);
      socket.on(C.GET_ABLE_SPORTSBOOKS, (data1) => {
        socket.off(C.GET_ABLE_SPORTSBOOKS);
        self.props.setSportsBooks(decode(data1));
      });
      if (!self.state.welcome) {
        // sendNotfication('Register and get 0.00000030 BTC for FREE !', 'info', 'top-center', true);
        // storage.setKey('welcome', true);
      }
    });
    wait(delay).then(() => {
      self.setState({ loading: false });
    });
  }

  security = (key) => {
    try {
      const a = atob(key);
      console.log(
        "%cStop!",
        "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold"
      ),
        console.log(
          "%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a BetsMixer.com feature or 'hack' someone's account, it is a scam and will give them access to your BetsMixer.com account.",
          "font-family:system-ui;font-size:1.5rem;font-weight:bold;;color:#ffc107;"
        );
    } catch (e) {}

    chkd();
  };

  render() {
    const { t } = this.props;
    return (
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <Loader loading_effect={this.state.loading_effect} />
          <Router history={history}>
            {this._isMounted && (
              <>
                <ReactNotifications />
                <UserModal t={t} />
                <GameModal t={t} />
                <SingleGameModal t={t} props={this.props} />
              </>
            )}
            <Layout>
              <Route t={t} history={history} />
            </Layout>
          </Router>

          {/* {this.state.loading ? (
          <>
            <Loader />
          </>
        ) : (
          <Router history={history}>
            {this._isMounted && (
              <>
                <ReactNotifications />
                <UserModal t={t} />
                <GameModal t={t} />
                <SingleGameModal t={t} props={this.props} />
              </>
            )}
            <Layout>
              <Route t={t} history={history} />
            </Layout>
          </Router>
        )} */}
        </I18nextProvider>
      </BrowserRouter>
    );
  }
}

class SingleGameModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      effect: null,
      details: [],
      cards: [],
      clientName: storage.getKey("name"),
      country: storage.getKey("country") ? storage.getKey("country") : "Global",
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this._isMounted = true;
      Event.on("single_game_modal", (result) => {
        this.setState({ details: result.data, show: true, effect: "zoomIn" });
      });

      Event.on("single_game_modal_close", (result) => {
        this.handleClose();
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClose = () => {
    this.setState({
      detail: true,
      tip: false,
      show: false,
      loading: true,
      effect: "zoomOut",
    });
  };

  verify = () => {
    var result = this.state.details.result;

    Event.emit("game_verify", {
      hash: this.state.details.hash,
      result: result,
    });
  };

  getSuite(name) {
    switch (name) {
      case "spades":
        return "S";
        break;

      case "hearts":
        return "H";
        break;

      case "clubs":
        return "C";
        break;

      case "diamonds":
        return "D";
        break;
    }
  }

  render() {
    let {
      uid,
      name,
      amount,
      game,
      profit,
      won,
      coin,
      created,
      hash,
      gid,
      id,
      result,
      cashout,
      slot,
      direct,
      force,
    } = this.state.details;

    var { t } = this.props;
    if (typeof t !== "function")
      t = function (s) {
        return s;
      };

    let isLost = false;
    let listResult = null;
    let date = fixDate(created);

    cashout = cashout !== undefined ? parseFloat(cashout) / 100 : result;

    if (direct) {
      date = timeConvertor(created);
    }

    // if is fake bot
    if (force) {
      date = "a few minutes ago";
    }

    profit = profit !== undefined ? __.toNumber(profit) : __.toNumber(won);

    if (profit === 0 || profit === 0.0 || profit < 0) {
      isLost = true;
    }

    let flex = false;

    if (!__.isUndefined(result)) {
      if (!__.isArray(result)) listResult = result;
      else {
        console.log("array");
        flex = true;
      }
    }

    listResult = game === "crash" ? cashout : listResult;

    if (game === "crash") {
      if (listResult === "NaN") listResult = "Betting...";
    }

    if (game === "keno" || game === "singlekeno") {
      let nums = [];
      let self = this;

      __.forEach(result.numbers, (n, k) => {
        let marked = __.includes(result.marked, n);
        nums.push(
          <MakeCircle
            key={__.toString(i)}
            t={self.props.t}
            type={"sm"}
            number={n}
            isMark={marked}
          />
        );
      });

      listResult = <div className="keno_modal">{nums}</div>;
    }

    if (game === "3_cardmonte") {
      let cards = [];

      if (!__.isUndefined(result.cards) && __.isArray(result.cards)) {
        result.cards.forEach((name, i) => {
          let targetCard = "theree_cards";
          if (result.target === i) {
            targetCard = "active_card";
          }

          let image = `./assets/images/three_card_monte/card-${name}.png`;

          cards.push(
            <div className={targetCard}>
              <img alt="Card" src={image} />
            </div>
          );
        });
      }

      listResult = <div className="three_cards_modal">{cards}</div>;
    }

    let selected;

    if (game === "hilo") {
      let cards = [];
      selected = result.selected;
      let uniq = __.uniq(result.cards);
      for (var i = 0; i < uniq.length; i++) {
        var num = uniq[i];
        var card = (
          <>
            <img
              src={"/assets/images/video-poker/new/" + num + ".png"}
              className={"img-fluid mt-1"}
              alt="Last Card"
              data-tip={"Card " + num}
            />
          </>
        );
        cards.push(card);
      }
      result = <>{cards}</>;
    }

    if (game === "mine") {
      let arr = [];
      for (var i = 0; i < 25; i++) {
        arr.push(
          <img
            id={"mine_land_" + i}
            className="img-fluid"
            src="/assets/images/mines/land.png"
          />
        );
      }

      listResult = (
        <>
          <div className="mine-modal">{arr}</div>
        </>
      );
      wait(100).then(() => {
        for (var i = 0; i < result.selected.length; i++) {
          if (!__.isNull(getElement("#mine_land_" + result.selected[i])))
            getElement("#mine_land_" + result.selected[i]).setAttribute(
              "src",
              "/assets/images/mines/gem.png"
            );
          getElement("#mine_land_" + result.selected[i]).setAttribute(
            "class",
            "full_rotate"
          );
        }

        for (var i = 0; i < result.result.length; i++) {
          let bomb = result.result[i].num;
          if (!__.isNull(getElement("#mine_land_" + bomb)))
            getElement("#mine_land_" + bomb).setAttribute(
              "src",
              "/assets/images/mines/mine.png"
            );
          getElement("#mine_land_" + bomb).setAttribute("class", "full_rotate");
        }
      });
    }

    if (game === "goal") {
      let arr = [],
        arr2 = [];
      for (var i = 0; i < 16; i++) {
        arr.push(
          <>
            <img
              id={"mine_land_" + i}
              className="img-fluid"
              src="/assets/images/goal/land.png"
            />
          </>
        );
        arr2.push(
          <>
            <img
              id={"mine_landg_" + i}
              className="img-fluid"
              src="/assets/images/goal/land.png"
            />
          </>
        );
      }

      listResult = (
        <>
          <div className="goal-modal float-left">{arr}</div>
          <div className="goal-modal float-right">{arr2}</div>
        </>
      );
      wait(100).then(() => {
        for (var i = 0; i < result.selected.length; i++) {
          if (!__.isNull(getElement("#mine_land_" + result.selected[i])))
            getElement("#mine_land_" + result.selected[i]).setAttribute(
              "src",
              "/assets/images/goal/ball.png"
            );
          getElement("#mine_land_" + result.selected[i]).classList.add("ball");
        }

        for (var i = 0; i < result.result.length; i++) {
          if (!__.isNull(getElement("#mine_landg_" + result.result[i])))
            getElement("#mine_landg_" + result.result[i]).setAttribute(
              "src",
              "/assets/images/goal/ball2.png"
            );
          getElement("#mine_landg_" + result.result[i]).classList.add("ball");
        }
      });
    }

    if (game === "videopoker") {
      let initial = [];
      let final = [];

      if (__.isNull(result)) {
        return null;
      }

      if (!__.isNull(result)) {
        initial = result.initialHand.map((e, i) => <AddCard i={i} card={e} />);
        final = result.finalHand.map((e, i) => <AddCard i={i} card={e} />);
      }

      result = (
        <>
          <h4 className="font-14 my-0 text-white">{t("initial_hand")}</h4>
          <br />
          <div className="d-inline-flex">{initial}</div>
          <br />
          <h4 className="font-14 my-0 text-white">{t("final_hand")}</h4>
          <br />
          <div className="d-inline-flex">{final}</div>
          <br />
          <div className="scoreName mt-2">{result.scoreName}</div>
        </>
      );
    }

    if (game === "crypto_trading") {
      listResult = (
        <>
          <div className="modal-trading">
            <p className="text-white font-12">
              Type : <span className="text-warning">{result.type}</span>
            </p>
            <p className="text-white font-12">
              First Price :{" "}
              <span className="text-warning">
                {_.toNumber(result.price).toFixed(2)}
              </span>
            </p>
            <p className="text-white font-12">
              Last Price :{" "}
              <span className="text-warning">
                {_.toNumber(result.currentPrice).toFixed(2)}
              </span>
            </p>
          </div>
        </>
      );
    }

    if (game === "plinko") {
      listResult = (
        <>
          <div className="modal-plinko">
            <span>{result}</span>
            <img
              alt="bocket"
              src="/assets/images/plinko/bocket_active_1.png"
              className="img-fluid npct"
            />
          </div>
        </>
      );
    }

    if (game === "magic_wheel") {
      listResult = (
        <>
          <div className="modal-magic_wheel">
            <span>{result}</span>
          </div>
        </>
      );
    }

    if (game === "snakeandladders") {
      listResult = (
        <>
          <div className="modal-snake">{result}</div>
        </>
      );
    }

    if (game === "roulette") {
      let paint = (
        <img
          style={{ width: 225 }}
          className="img-fluid"
          src={"/assets/images/roulette/" + result.target + ".png"}
        />
      );

      const fixTableName = (name) => {
        if (name === 112) {
          return "1st 12";
        } else if (name === 212) {
          return "2nd 12";
        } else if (name === 312) {
          return "3rd 12";
        }
        return __.replace(name, "_", " to ");
      };

      var game_data = result.game_data.map((value, i) => (
        <p>[ {fixTableName(value.table)} ]</p>
      ));

      listResult = (
        <div className="roulette-modal">
          <p>{game_data}</p>
          <span>{result.target}</span>
          {paint}
        </div>
      );
    }

    if (game === "diamond") {
      let gems = [];

      if (__.isArray(result)) {
        result.forEach((gem, i) => {
          let image = `/assets/images/diamond/${getDiamond(gem)}.png`;
          gems.push(
            <div className="d-inline-block">
              <img className="cardimg" src={image} />
              <span>{gem.toFixed(2)}x</span>
            </div>
          );
        });
      } else {
        gems = result;
      }

      listResult = <div className="diamonds-modal">{gems}</div>;
    }

    if (game === "baccarat") {
      let { player, banker } = result;
      let p = [],
        b = [];

      Object.keys(player).forEach(function (suit, key) {
        let value = player[suit];
        let src = `assets/images/baccarat/cards/${suit}/${value}.png`;
        p.push(<img src={src} className="img-fluid" />);
      });

      Object.keys(banker).forEach(function (suit, key) {
        let value = banker[suit];
        let src = `assets/images/baccarat/cards/${suit}/${value}.png`;
        b.push(<img src={src} className="img-fluid" />);
      });

      let temp = (
        <div className="baccarat-result">
          <p className="mt-0 mb-2 text-warning">{t("banker_cards")}</p>
          {b}
          <p className="mb-2 mt-2 text-warning">{t("player_cards")}</p>
          {p}
          <p className="mb-2 mt-2 text-white">
            Winner: <span className="text-warning">{result.outcome}</span>
          </p>
        </div>
      );

      listResult = temp;
    }

    if (game === "blackjack") {
      let pCards = [];
      let dCards = [];

      if (!__.isNull(result) && __.isArray(result.pCards)) {
        result.pCards.forEach((card, i) => {
          const image = `assets/images/blackjack/cards/${this.getSuite(
            card.suite
          )}/${card.text}.png`;
          var c = (
            <div
              key={__.toString(i)}
              className="carder up"
              id={"pcard-" + card.value}
            >
              <span className="pos red">
                <span className="rank">
                  <img src={image} alt="Card" />
                </span>
              </span>
            </div>
          );
          pCards.push(
            <span className="rank">
              <img src={image} alt="Card" />
            </span>
          );
        });

        result.dCards.forEach((card, i) => {
          const image = `assets/images/blackjack/cards/${this.getSuite(
            card.suite
          )}/${card.text}.png`;
          var c = (
            <>
              <div
                key={__.toString(i)}
                className="carder up"
                id={"pcard-" + card.value}
              >
                <span className="pos red">
                  <span className="rank">
                    <img src={image} alt="Card" />
                  </span>
                </span>
              </div>
            </>
          );
          dCards.push(
            <span className="rank">
              <img src={image} alt="Card" />
            </span>
          );
        });

        let firstCards = <div id="phand">{pCards}</div>;
        let secondCards = <div id="phand">{dCards}</div>;

        result = (
          <div className="blackjack-modal">
            <div className="second">{secondCards}</div>
            <div className="first">{firstCards}</div>
            <div className="result">{result.result}</div>
          </div>
        );
      } else result = result;
    }

    if (__.isNaN(profit)) profit = 0;

    return (
      <>
        {this._isMounted && (
          <>
            <Modal
              size="lg"
              backdrop={"static"}
              centered={true}
              show={this.state.show}
              onHide={this.handleClose}
              aria-labelledby="game-modal"
            >
              <Modal.Header>
                {isLost ? (
                  <>
                    <img
                      src="/assets/images/lost.svg"
                      className="img-fluid gd-st"
                      alt="Image"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/assets/images/win.svg"
                      className="img-fluid gd-st"
                      alt="Image"
                    />
                  </>
                )}
                <button
                  type="button"
                  className="close p-2"
                  onClick={this.handleClose}
                >
                  <i className={"mdi mdi-close"} />
                </button>
              </Modal.Header>

              <Modal.Body
                className={
                  isLost ? "user-modal-bg p-1" : "user-modal-bg p-1 winImageX"
                }
              >
                <ReactTooltip />
                <Row className="text-center game-modal">
                  <Col lg="6" md="6" sm="12" className="font-12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <UserLink id={uid} username={name} />
                          </div>
                          <div className="r betid">
                            BET ID:{" "}
                            {gid !== undefined || !gid || gid !== "" ? gid : id}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12" className="text-white font-12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white">
                              {" "}
                              {t("date")} / {t("time")}
                            </span>
                          </div>
                          <div className="r">{date}</div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white">{t("amount")}</span>
                          </div>
                          <div className="r">
                            <img
                              className="mini-coin-7"
                              src={
                                "/assets/images/" + __.toLower(coin) + ".png"
                              }
                              alt="Coin"
                            />
                            <b className={"num-style"}>
                              {forceSatoshiFormat(amount)} {__.upperCase(coin)}
                            </b>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Card className={"mb-1 brd15 text-white"}>
                      <Card.Body className="p-2 text-uppercase">
                        <div className="media">
                          <div className="l">
                            <span className="text-white"> {t("profit")}</span>
                          </div>
                          <div className="r">
                            <img
                              className="mini-coin-7"
                              src={
                                "/assets/images/" + __.toLower(coin) + ".png"
                              }
                              alt="Coin"
                            />
                            <b
                              className={
                                !isLost
                                  ? "num-style text-success"
                                  : "num-style text-danger"
                              }
                            >
                              {forceSatoshiFormat(profit > 0 ? profit : 0)}{" "}
                              {__.upperCase(coin)}
                            </b>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  {game === "crash" ? (
                    <>
                      <Col lg="6" md="6" sm="12">
                        <Card className={"mb-1 brd15"}>
                          <Card.Body className="p-2 text-white text-uppercase">
                            <div className="media">
                              <div className="l">{t("payout")}</div>
                              <div className="r">
                                {parseFloat(listResult).toFixed(2)}x
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="6" md="6" sm="12">
                        <Card className={"mb-1 brd15"}>
                          <Card.Body className="p-2 text-white text-uppercase">
                            <div className="media">
                              <div className="l">Result</div>
                              <div className="r">
                                {result ? result + "x" : "Game is Running..."}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    <>
                      {game === "hilo" ? (
                        <>
                          <Col lg="12" md="12" sm="12">
                            <Card className={"mb-1 brd15"}>
                              <Card.Body className="p-2 text-white text-uppercase">
                                <img
                                  src={"/assets/images/icons/hilo.png"}
                                  className={
                                    "img-fluid w-15 single-game icon-single-game"
                                  }
                                  data-tip={"hilo"}
                                  alt={"Hilo"}
                                />
                                {t("result")}
                                <br />
                                <div className={"mt-2 font-15 hilo-result"}>
                                  {result}
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </>
                      ) : (
                        <>
                          {game === "videopoker" ? (
                            <Col lg="12" md="12" sm="12">
                              <Card className={"mb-1 brd15"}>
                                <Card.Body className="p-2 text-white text-uppercase">
                                  <img
                                    src={"/assets/images/icons/videopoker.png"}
                                    className={
                                      "img-fluid w-15 single-game icon-single-game"
                                    }
                                    data-tip={"Video Poker"}
                                    alt={"Video Poker"}
                                  />
                                  <div className={"vpoker-result"}>
                                    {result}
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ) : (
                            <>
                              {game === "blackjack" ? (
                                <>
                                  <Col lg="12" md="12" sm="12">
                                    <Card className={"mb-1 brd15"}>
                                      <Card.Body className="p-2 text-white text-uppercase">
                                        <img
                                          src={
                                            "/assets/images/icons/blackJack.png"
                                          }
                                          className={
                                            "img-fluid w-15 single-game icon-single-game"
                                          }
                                          data-tip={"Blackjack"}
                                          alt={"Blackjack"}
                                        />
                                        {t("result")}
                                        <br />
                                        <div className={"mt-2 font-15 -result"}>
                                          {result}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  {listResult !== null && (
                                    <Col
                                      lg={
                                        game === "baccarat" ||
                                        game === "roulette" ||
                                        game === "mine" ||
                                        game === "singlekeno" ||
                                        game === "plinko" ||
                                        game === "crypto_trading" ||
                                        game === "diamond" ||
                                        game === "3_cardmonte" ||
                                        game === "wheel" ||
                                        game === "magic_wheel" ||
                                        game === "snakeandladders" ||
                                        game === "goal" ||
                                        game === "keno"
                                          ? 12
                                          : 6
                                      }
                                      md={
                                        game === "baccarat" ||
                                        game === "roulette" ||
                                        game === "mine" ||
                                        game === "singlekeno" ||
                                        game === "plinko" ||
                                        game === "crypto_trading" ||
                                        game === "diamond" ||
                                        game === "3_cardmonte" ||
                                        game === "wheel" ||
                                        game === "magic_wheel" ||
                                        game === "snakeandladders" ||
                                        game === "goal" ||
                                        game === "keno"
                                          ? 12
                                          : 6
                                      }
                                      sm="12"
                                    >
                                      <Card className={"mb-1 brd15"}>
                                        <Card.Body className="p-2 text-white text-uppercase ovh">
                                          <img
                                            src={
                                              "/assets/images/icons/" +
                                              game +
                                              ".png"
                                            }
                                            className={
                                              "img-fluid w-15 single-game icon-single-game"
                                            }
                                            data-tip={game}
                                            alt={game}
                                          />
                                          {game === "crash"
                                            ? "Payout"
                                            : "Result"}
                                          <br />
                                          <div
                                            className={
                                              flex
                                                ? "mt-3 font-15 mediax center-flexx ovh"
                                                : "mt-2 font-15"
                                            }
                                          >
                                            {listResult}
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {game !== "dice" &&
                    game !== "blackjack" &&
                    game !== "hilo" &&
                    game !== "goal" &&
                    game !== "baccarat" &&
                    game !== "penalty" &&
                    game !== "snakeandladders" &&
                    game !== "magic_wheel" &&
                    game !== "3_cardmonte" &&
                    game !== "wheel" &&
                    game !== "plinko" &&
                    game !== "crypto_trading" &&
                    game !== "diamond" &&
                    game !== "high_low" &&
                    game !== "ctr" &&
                    game !== "lander" &&
                    game !== "videopoker" &&
                    game !== "roulette" &&
                    game !== "mine" &&
                    game !== "bash" &&
                    game !== "slide" &&
                    game !== "bingo" &&
                    game !== "singlekeno" &&
                    game !== "keno" && (

                      <>
                        {/* { hash !== null &&
                          <Col md={game === "crash" ? 12 : 6} sm={6}>
                        {__.toString(hash).length > 0 && (
                          <>
                            <Card className={"mb-1 brd15 verifybtn"}>
                              <Card.Body className="p-1 text-white text-uppercase">
                                <div className="form-group mb-1">
                                  <label className="text-white mt-1 crs-def">
                                    {t("check_game")}
                                  </label>
                                </div>
                                {!__.isUndefined(hash) && (
                                  <>
                                    {(__.isUndefined(slot) ||
                                      slot === null) && (
                                      <>
                                        <button
                                          onClick={this.verify}
                                          className="btn btn-cs8 btn-block btn-xs btn-block no-shadow"
                                        >
                                          <i className="mdi mdi-shield nts" />{" "}
                                          {t("verify_result")} 
                                          {hash}
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </Card.Body>
                            </Card>
                          </>
                        )}
                      </Col>} */}
                      </>
                    )}
                </Row>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

class UserModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      detail: true,
      tip: false,
      notFound: false,
      data: [],
      show_withdrawal: false,
      modal_name: props.t("user_information"),
      coin: this.props.coin
        ? this.props.coin
        : storage.getKey("coin") !== null
        ? storage.getKey("coin")
        : "SOL",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.modal) {
      this.setState({ show: false });
    }
  }

  // componentDidUpdate() {
  componentDidMount() {
    // this._isMounted = true;

    // if (this._isMounted) {
    Event.on("force_modal_user", () => {
      socket.off(C.USER_INFO);
      socket.on(C.USER_INFO, (data) => {
        this.getUserInfo(decode(data));
      });

      this.setState({
        show: true,
        tip: false,
        detail: true,
        effect: "zoomIn",
        haveData: "no",
        data: [],
      });
    });

    Event.on("force_modal_tip", (data) => {
      if (data.direct) {
        socket.off(C.USER_INFO);
        socket.on(C.USER_INFO, (data) => {
          this.getUserInfo(decode(data));
        });

        socket.emit(
          C.USER_INFO,
          encode({
            id: data.uid,
            coin: this.state.coin,
            rate: null,
            game: "all",
            first: true,
          })
        );
      }
      this.setState({
        show: true,
        tip: true,
        detail: false,
        modal_name: this.props.t("tip_user"),
      });
    });

    Event.on("force_modal_tip_close", () => {
      this.setState({
        tip: false,
        detail: true,
        modal_name: this.props.t("user_information"),
      });
    });

    Event.on("single_game_modal_by_chart", () => {
      this.setState({ modal_name: this.props.t("game_details") });
    });

    Event.on("back_from_chart", () => {
      this.setState({ modal_name: this.props.t("user_information") });
    });

    Event.on("close_user_modal", () => {
      this.handleClose();
    });
    Event.on("show_withdrawal", () => {
      this.setState({
        detail: false,
        modal_name: "Withdrawal Archive",
        show_withdrawal: true,
      });
    });
    // }
  }

  handleClose() {
    this.setState({
      // detail: true,
      detail: false,
      tip: false,
      notFound: false,
      show: false,
      effect: "zoomOut",
      show_withdrawal: false,
      modal_name: this.props.t("user_information"),
    });
    // socket.off(C.USER_INFO);
    // this._isMounted = false;
  }

  getUserInfo = (data) => {
    if (data.status) {
      this.setState({ haveData: "ok", data: data });
    } else {
      this.setState({ notFound: true });
    }
  };

  componentWillUnmount() {
    socket.off(C.USER_INFO);
  }

  render() {
    let { chart_coin, t } = this.props;
    chart_coin = chart_coin ? chart_coin : this.state.coin;

    return (
      <Modal
        size={this.state.tip ? "md" : "lg"}
        backdrop={"static"}
        centered={true}
        show={this.state.show}
        onHide={this.handleClose}
        aria-labelledby="user-modal"
        autoFocus={true}
        restoreFocus={true}
      >
        <Modal.Header className="modal-header px-4">
          {this.state.modal_name}
          <button
            type="button"
            className="close p-2"
            onClick={this.handleClose}
          >
            <i className={"mdi mdi-close"} />
          </button>
        </Modal.Header>
        <Modal.Body className={"user-modal-bg p-1"}>
          {this.state.notFound ? (
            <>
              <div
                className="text-center text-yellow"
                style={{ minHeight: 370 }}
              >
                {/* User Not Found */}[ {t("stats_is_hidden")} ]
              </div>
            </>
          ) : (
            <>
              {this.state.detail ? (
                <>
                  <div
                    className={
                      this.state.tip === true
                        ? "container-fluid mduser animated fadeOut"
                        : "container-fluid mduser"
                    }
                  >
                    <Details
                      t={t}
                      haveData={this.state.haveData}
                      name={this.state.data.name}
                      id={this.state.data.id}
                      coin={this.state.coin}
                      data={this.state.data}
                    />
                  </div>
                </>
              ) : (
                <>
                  {this.state.tip === true && (
                    <div className={"container-fluid animated fadeIn"}>
                      <TipUser
                        id={this.state.data.id}
                        name={this.state.data.name}
                      />
                    </div>
                  )}
                  {this.state.show_withdrawal && (
                    <div className={"buttonswallet"}>
                      <Withdrawl t={t} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

class GameModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: true,
      gid: null,
      playerRow: [],
      numbers: [],
      players: [],
      busted: null,
      date: null,
      sha256: null,
      hash: null,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.modal) {
      this.setState({ show: false });
    }
  }

  componentDidUpdate() {
    this._isMounted = true;

    if (this._isMounted) {
      socket.on(C.GAME_DETAILS, (data) => this.gameInfo(decode(data)));

      Event.on("force_modal_game", () => {
        this.setState({
          haveData: "no",
          playerRow: [],
          busted: null,
          date: null,
          sha256: null,
          hash: null,
          gid: null,
          loading: true,
          show: true,
          effect: "zoomIn",
          players: [],
        });
      });
    }
  }

  handleClose() {
    this.setState({
      show: false,
      loading: true,
      effect: "zoomOut",
      players: [],
    });
    this._isMounted = false;
    socket.off(C.GAME_DETAILS);
  }

  gameInfo(response) {
    if (!response.info) {
      this.setState({
        show: false,
        loading: true,
        effect: "zoomOut",
        players: [],
      });
      return;
    }
    this.setState({ loading: false, playerRow: [], haveData: "ok" });

    let gameInfo = response.info;
    let busted;

    if (
      gameInfo.busted !== null &&
      gameInfo.busted !== undefined &&
      gameInfo.busted !== "undefined" &&
      gameInfo.busted !== ""
    ) {
      busted = gameInfo.busted;
    } else {
      busted = gameInfo.numbers;
    }

    this.setState({
      busted: busted,
      sha256: !__.isNull(gameInfo.hash) ? md5(gameInfo.hash) : hash,
      hash: gameInfo.hash,
      date: gameInfo.date,
      gid: gameInfo.gid,
    });

    if (response.data.length !== 0) {
      let sort = sortByWinner(response.data);
      sort = __.xor(sort);

      let uniq = [];

      sort.forEach((player, i) => {
        var find = __.findIndex(uniq, function (o) {
          return o.uid === player.uid;
        });
        if (find === -1) uniq.push(player);
      });

      this.setState({ players: uniq });
    }
  }

  render() {
    const { t } = this.props;
    let { busted, date, sha256, hash, playerRow, gid, loading } = this.state;
    let heading = "h1";
    let color = busted >= 1.9 ? "success" : "danger";

    if (__.isArray(busted)) {
      heading = "h2";

      __.forEach(busted.numbers, (number, i) => {
        busted += (
          <MakeCircle
            key={__.toString(i)}
            t={t}
            type={"md"}
            number={number}
            isMark={number === busted.marked[i] ? true : false}
          />
        );
      });
    }

    var players = this.state.players.map((array, i) => (
      <PlayerRow
        key={__.toString(i)}
        clicked={this.handleClose}
        array={array}
      />
    ));

    return (
      <Modal
        size="md"
        centered={true}
        backdrop={"static"}
        show={this.state.show}
        onHide={this.handleClose}
        aria-labelledby="game-md-modal"
        className={"animated " + this.state.effect}
      >
        <Modal.Header className="Header">
          {t("game_stats")}{" "}
          <button
            type="button"
            className="close p-2"
            onClick={this.handleClose}
          >
            <i className={"mdi mdi-close"} />
          </button>
        </Modal.Header>
        {this.state.players && (
          <Modal.Body>
            {loading ? (
              <>
                <div className="text-center" style={{ minHeight: 200 }}>
                  <div className="spinner-grow text-white my-1" role="status" />
                </div>
              </>
            ) : (
              <>
                <Row className="text-darker pt-0 mb-1 user-detail">
                  <Col md={12} sm={12}>
                    <div className="review-box align-item-center p-0">
                      {heading === "h1" ? (
                        <>
                          <h1 className={"text-center my-0 py-0 text-" + color}>
                            {busted}
                            <span className={"font-"}>x</span>
                          </h1>
                          <h5 className={"text-center mt-1 pt-0 text-" + color}>
                            {t("busted")}
                          </h5>
                        </>
                      ) : (
                        <h2 className={"text-center my-0 py-0 text-" + color}>
                          {busted}
                        </h2>
                      )}
                      <Row className="my-3">
                        <Col md={6} sm={6} className="text-success">
                          {t("s_game")} ID: {gid}
                        </Col>
                        <Col md={6} sm={6} className="text-white text-right">
                          {t("date")}: {fixDate(date)}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} sm={12} className="text-center">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            HASH
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer mb-2 bg-cs44"
                          value={__.toString(hash)}
                          readOnly={true}
                        />
                      </div>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text h-40 bg-cs44">
                            MD5
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control no-radius pointer bg-cs44"
                          value={__.toString(sha256)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {loading ? (
              <>
                <div className="text-center" style={{ minHeight: 200 }}>
                  <div className="text-info my-2" role="status" />
                </div>
              </>
            ) : (
              <>
                <h4 className={"mb-3 mt-0 text-yellow"}>Players List</h4>
                <div className="table-responsive game-stats">
                  <table className="table">
                    <thead className="table-header">
                      <tr>
                        <th>{t("player")}</th>
                        <th>{t("bet")}</th>
                        <th>{t("profit")}</th>
                      </tr>
                    </thead>
                    <tbody>{players}</tbody>
                  </table>
                  {players.length === 0 && (
                    <p className="text-center text-muted">
                      {t("no_one_played_on_this_round")}
                    </p>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

function MakeCircle(props) {
  const copyHash = (value) => {
    var t = document.createElement("input");
    t.value = value;
    document.body.appendChild(t);
    t.select();
    t.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(t);
    sendNotfication("Copied !", "info", "top-center");
  };

  let marked = props.isMark ? "bg-success" : "bg-purple";
  let result = props.number;

  if (!__.isUndefined(result.num)) {
    return (
      <>
        <div
          className={
            `float-left ${marked} rounded cpt mx-1 thumb- p-2 mb-1 ` +
            props.type
          }
          data-tip={props.t("click_to_copy_hash") + ":" + result.hash}
          onClick={(e) => copyHash(result.hash)}
        >
          {result.num}
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={"avatar-box align-self-center mx-1 my-1 thumb-" + props.type}
      >
        <span className={`avatar-title ${marked} rounded text`}>{result}</span>
      </div>
    </>
  );
}

class PlayerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { array, key, clicked } = this.props;

    let color = "text-success";
    let profit = __.toNumber(array.profit);
    let coin = __.upperCase(array.coin);

    if (profit <= 0) {
      color = "text-warning";
    }

    return (
      <>
        <tr className={color} key={key}>
          <td>
            <div className="historyrow historyrowfirst">
              <UserLink
                cssClass={"text-dark"}
                id={array.uid}
                clicked={clicked}
                username={array.name}
                isWinner={color}
              />
            </div>
          </td>
          <td className="num-style">
            <div className="historyrow">
              <img
                src={"/assets/images/" + __.toLower(coin) + ".png"}
                className={"mini-coin-8"}
                alt={coin}
              />
              {forceSatoshiFormat(array.amount)} {coin}
            </div>
          </td>
          <td className="num-style">
            <div className="historyrow historyrowlast">
              <img
                src={"/assets/images/" + __.toLower(coin) + ".png"}
                className={"mini-coin-8"}
                alt={coin}
              />
              {forceSatoshiFormat(profit)} {coin}
            </div>
          </td>
        </tr>
      </>
    );
  }
}

class AddCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const card = this.props.card;
    const i = this.props.i;
    var name = card.name;
    var symbol = card.suit;
    var selected = card.selected;

    return (
      <div
        key={__.toString(i)}
        className={
          selected === true ? "handCard-modal holder" : "handCard-modal"
        }
        id="handCardDisplay0"
      >
        <img
          className="img-fluid"
          src={"/assets/images/video-poker/new/" + name + symbol + ".png"}
          alt="Card"
        />
      </div>
    );
  }
}

function sortByWinner(input) {
  function r(c) {
    return c.profit ? -c.profit : null;
  }
  return __.sortBy(input, r);
}

function getDiamond(payout) {
  switch (payout) {
    case 1.2:
      return 1;
      break;
    case 1.5:
      return 2;
      break;
    case 1.8:
      return 4;
      break;
    case 2.0:
      return 5;
      break;
    case 0.0:
      return 3;
      break;

    case 3.0:
      return 1;
      break;
    case 6.0:
      return 2;
      break;
    case 8.0:
      return 4;
      break;
    case 10.0:
      return 5;
      break;
    case 0.0:
      return 3;
      break;
  }
}

const mapDispatchToProps = {
  gameSlots,
  setSportsBooks,
  setIPAddress,
  setIpDetails,
  setProviders,
};

// Connect the Application component to the Redux store
const ConnectedApplication = connect(null, mapDispatchToProps)(Application);
App.propTypes = {
  coin: PropTypes.string,
  chart_coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  chart_coin: state.items.chart_coin,
});

export default connect(mapStateToProps, {})(App);
// export default App;
