import MyEmitter from "events";
import socket from "../../../Socket";
import { decode, encode, forceSatoshiFormat } from "../../../Helper";
import C from "../../../Constant";
/**
 * Keno Engine
 */
function Engine() {
  var self = this;

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Game Status
   * 3 type: waiting_keno | started_keno | busted_keno
   */
  self.gameStatus = null;

  /**
   * Current User id
   */
  self.currentUser = null;

  /**
   * Initialize User Play
   */
  self.init = false;

  /**
   * Round amount
   */
  self.amount = null;

  /**
   * Round Numbers
   */
  self.numbers = [];

  /**
   * User Coin
   */
  self.coin = null;

  /*
   * Start Engine
   */
  self.started = true;
}

Engine.prototype.listen = function () {
  var self = this;
  /**
   * Event on Getting Status game
   */
  self.ws.on(C.STATUS_KENO, function (data) {
    data = decode(data);
    self.emit(C.STATUS_KENO, data);
  });

  /**
   * Event on Playing game
   */
  self.ws.on(C.PLAY_KENO, function (data) {
    data = decode(data);
    self.emit(C.PLAY_KENO, data);
  });

  /**
   * Event on busted game
   */
  self.ws.on(C.BUSTED_KENO, function (data) {
    data = decode(data);
    self.gameStatus = "busted_keno";
    self.emit(C.BUSTED_KENO, data);

    // Stop User Playing
    self.init = false;
  });

  /**
   * Event on waiting game
   * User Must Add in Here
   */
  self.ws.on(C.WAITING_KENO, function (data) {
    data = decode(data);
    self.gameStatus = "waiting_keno";
    self.emit(C.WAITING_KENO, data);

    if (!self.init) return false;

    self.ws.emit(
      C.PLAY_KENO,
      encode({
        amount: self.amount,
        numbers: self.numbers,
        coin: self.coin,
      })
    );
  });

  /**
   * Event on started game
   */
  self.ws.on(C.STARTED_KENO, function (data) {
    data = decode(data);
    self.gameStatus = "started_keno";
    self.emit(C.STARTED_KENO, data);
  });

  /**
   * Event on Sending Numbers
   */
  self.ws.on(C.KENO_AMOUNT, function (data) {
    data = decode(data);
    self.emit(C.KENO_AMOUNT, data);
  });

  /**
   * Event on Error
   */
  self.ws.on(C.ERORR_KENO, function (data) {
    data = decode(data);
    self.emit(C.ERORR_KENO, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.KENO_AMOUNT);
  self.ws.off(C.STATUS_KENO);
  self.ws.off(C.WAITING_KENO);
  self.ws.off(C.BUSTED_KENO);
  self.ws.off(C.STARTED_KENO);
  self.ws.off(C.PLAY_KENO);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Event for add user to game
 */
Engine.prototype.force = function () {
  let self = this;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }
  self.ws.emit(
    C.PLAY_KENO,
    encode({
      amount: self.amount,
      numbers: self.numbers,
      coin: self.coin,
    })
  );
};

/**
 * Event for getting status
 */
Engine.prototype.getStatus = function () {
  let self = this;
  self.ws.emit(C.STATUS_KENO);
};

/** Start Game Engine **/
export default Engine;
