import React from "react";
import { connect } from "react-redux";
import { Modal, Card, Row, Col, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Scroll from "react-scroll";
import { __, isMobile, wait, Event, requestFullScreen, decode, encode, getUID, sendNotfication } from "../../Helper";
import Bets from "../Pages/Parts/Home/Bets";
// import Frame from "./Frame";
import Loading from "../Games/Loading";
import socket from "../../Socket";
import C from "../../Constant";
import Rakeback from "../Pages/Parts/Home/Rakeback";
import storage from "../../Storage";
import { gameCoin } from "../../actions/gameCoin";
import axios from "axios";
import i18n from "i18next";
import Deposit from "../Components/User/Wallet/Deposit";

const Element = Scroll.Element;
const SC = Scroll.scroller;

class Sports extends React.Component {
    _Mounted = true;
    token = '';
    constructor(props) {
        super(props);
        this.state = {
            game: null,
            gameName: 'BetBy',
            gameUuid: "d595248f-75ff-454b-8d6d-e6a232dff199",
            padding: 'p-1',
            provider: "BetBy",
            houseEdge: null,
            message: null,
            height: 505,
            token: storage.getKey('token'),
            url: null,
            isRealLoading: false,
            bt: null,
            sessionId: null,
            started: false,
            deposit_show: false,
            lang: 'en',
            prevLang: null // Add a state property for the previous language
        }
        // this.handleResize = this.handleResize.bind(this);
        this.getSport = this.getSport.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }
    componentDidMount() {
        // wait(600).then(() => {
        //     this.handleResize();
        // });

        // window.addEventListener('resize', this.handleResize);
        this.setState({ lang: storage.getKey("lng") ? storage.getKey("lng") : "en" });
        SC.scrollTo('slotElement', {
            delay: 3,
            smooth: true,
            containerId: 'st'
        });

        if (!this.props.sportsbooks) {
            this.setState({ started: false });
        } else {
            let selectedSport = this.props.sportsbooks.filter(sport => sport.uuid == this.state.gameUuid)[0];
            this.setState({ gameName: selectedSport.name, provider: selectedSport.providerId, game: selectedSport });
            socket.emit(C.GET_HOUSEEDGE, encode({ game: 'sports' }));
            socket.on(C.GET_HOUSEEDGE, data => {
                socket.off(C.GET_HOUSEEDGE);
                let { edge } = decode(data);
                this.setState({ houseEdge: edge });
            });
            this.getSport();
        }
        // this._isMounted = false;

        i18n.on('languageChanged', this.handleLanguageChange);
        this._Mounted = true;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.props.gameCoin();

        Event.on('coin_changed', () => this.restartGame());
        Event.on('game_changed', () => this.checkAndStart());
        Event.on('toggle_sports_menu', (sports_sub_filter) => {
            // this.getSport();
            this.state.bt.updateOptions({
                url: sports_sub_filter,
                target: document.getElementById('bettech'),
                // brand_id: "2304251608915120128",
                brand_id: "2372808000315465728",
            })
        });

        Event.emit('toggle_chat', false, true, "on");
    }

    componentDidUpdate(prevProps) {
        if (this.props.sportsbooks !== prevProps.sportsbooks) {
            // Event.emit('toggle_chat', false, true);
            this.getSport();
        }
    }

    componentWillUnmount() {
        // this._isMounted = false;
        this._Mounted = false;
        Event.emit('toggle_chat', false, true, "off");
        socket.off(C.GET_HOUSEEDGE);
        socket.off(C.GET_REAL_SPORT);
        i18n.off('languageChanged');
        // window.removeEventListener('resize', this.handleResize);
        // Event.emit('sportsbooks_page', false);
        if (this.state.bt) this.state.bt.kill();
    }
    handleLanguageChange(language) {
        if(language == this.state.lang) return;
        
        console.log(`Language has been changed to: ${language}`);
        console.log(`Previous language was: ${this.state.lang}`); // Log the previous language

        if (this.state.bt !== null) this.state.bt.kill();

        this.setState({ lang: language, prevLang: this.state.lang }, () => {
            // Re-initialize betting frame after language change
            this.getSport();
        });
    }


    restartGame = () => {
        if (this._isMounted) {
            if (this.state.started) {
                this.setState({ started: false, url: null });
                wait(400).then(() => {
                    this.getSport();
                })
            }
        }
    }
    onTokenExpired = () => {
        /*!-template-on-token-expired--*/
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://api.betsmixer.com/api/sports-callback/refresh-token/' + this.state.sessionId,
                method: 'GET'
            })
                .then(data => {
                    // console.log("newtoken", data.data.token);
                    resolve(data.data.token);
                })
                .catch(error => {
                    reject(error)
                });
        })
        /*--template-on-token-expired-!*/
    }
    onRecharge = () => {
        /*!-template-on-recharge--*/
        this.setState({ deposit_show: true });
        console.log("The deposit URL does not specified in contract configuration, nothing to do...");
        /*--template-on-recharge-!*/
    }
    // handleResize() {
    // let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

    // if (clientWidth < 1540) {
    //     this.setState({ col: 'col-xl-12' });
    // }
    // else {
    //     this.setState({ col: 'col-xl-11' });
    // }
    // if (isMobile()) {
    //     this.setState({ padding: 'p-1' });
    // }
    // }

    getSport() {
        socket.emit(C.GET_REAL_SPORT, encode({ game: this.state.gameUuid, coin: this.props.coin, lang: this.state.lang }));
        socket.on(C.GET_REAL_SPORT, (data) => {
            socket.off(C.GET_REAL_SPORT);
            // this.setState({ isRealLoading: false });
            let res = decode(data);
            if (res.success) {
                this.setState({ started: true, url: res.data.url, token: res.data.token, sessionId: res.data.session_id });
                this.token = res.data.token;
                // this.loadScript('https://ui.invisiblesport.com/bt-renderer.min.js');
                let betSlipOffsetBottom = 0;
                let stickyTop = 61;
                if (isMobile()) {
                    betSlipOffsetBottom = 55;
                    stickyTop = 51;
                }
                this.state.bt = new BTRenderer().initialize({
                    // brand_id: "2304251608915120128",
                    brand_id: "2372808000315465728",
                    token: res.data.token,
                    themeName: "demo-yellow-dark-table",
                    lang: this.state.lang,
                    target: document.getElementById('bettech'),
                    minFrameHeight: 700,
                    betSlipOffsetTop: 0,
                    betSlipOffsetBottom,
                    betslipZIndex: 999,
                    stickyTop,
                    onTokenExpired: this.onTokenExpired,
                    onRouteChange: function () {
                        /*!-template-on-route-change--*/
                        console.log("on route change", arguments);
                        /*--template-on-route-change-!*/
                    },
                    onLogin: function () {
                        /*!-template-on-login--*/
                        console.log("The login URL does not specified in contract configuration, nothing to do...");
                        Event.emit('login_form');
                        /*--template-on-login-!*/
                    },
                    onRegister: function () {
                        /*!-template-on-register--*/
                        console.log("The register URL does not specified in contract configuration, nothing to do...");
                        Event.emit('register_form');
                        /*--template-on-register-!*/
                    },
                    onSessionRefresh: function () {
                        /*!-template-on-session-refresh--*/
                        window.location.reload();
                        console.log("The login URL does not specified in contract configuration, nothing to do...");
                        /*--template-on-session-refresh-!*/
                    },
                    onRecharge: this.onRecharge,
                });
            } else {
                sendNotfication(this.props.t('issue_in_game_provider'), 'success', 'bottom-left');
            }
            // Event.emit('hide_chat', true);
        });
    }

    fullscreen = () => {
        var elem = document.querySelector(".fullScreen");
        if (elem !== null)
            requestFullScreen(elem);
    }
    handleClose = () => {
        this.setState({ deposit_show: false });
    }

    render() {
        const { t } = this.props;
        // let { col, padding, game } = this.state;
        let { game } = this.state;
        // var title = this.state.gameName + ' | Crypto Casino Games';
        var title = 'Betby | Crypto Casino Games';
        title = __.startCase(title);
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:title" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <meta name="og:description" content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE" />
                    <link rel="canonical" href={"/" + game} />
                </Helmet>
                <div className="loading-container">
                    {this._Mounted && this.state.started ?
                        <>
                            <div id="bettech"></div>
                        </>
                        :
                        <div className="loading-spinner">
                            <img src="assets/images/loader-sports.gif" alt="..." />
                        </div>
                    }
                </div>
                <Modal
                    size={'lg'}
                    backdrop={'static'}
                    centered={true}
                    scrollable={false}
                    show={this.state.deposit_show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-md-modal"
                >
                    <Modal.Header className={this.state.paddingHeader}>
                        <Row xs={1} md={2} style={{ width: '100%' }}>
                            <Col xs={6} md={6}>
                                {/* <button
                                                    onClick={this.showArchive}
                                                    className={"btn bg-cs2 bg-cs5 btn-xs text-white"}
                                                > {this.state.headerText} */}

                                {/* </button> */}
                            </Col>
                        </Row>
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className={this.state.padding + ' wallet-modal buttonswallet'}>
                        <Deposit t={t} />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit,
    sportsbooks: state.items.sportsbooks,
});

export default connect(mapStateToProps, { gameCoin })(Sports);
