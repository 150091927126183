import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { __, isMobile, wait, Event, decode, DEVELOPMENT } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import {connect} from "react-redux";
import gamelist from "../../../../Originalgame";
import storage from "../../../../Storage";

class Games extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            col: 'col-12',
            mdCol: '14.2%',
            iPadd: '10px',
            isMin: false,
            loading: true,
            games: gamelist,
            chat_show: storage.getKey("chat_view") ? (storage.getKey("chat_view") == "true" ? true : false) : true
        };

        socket.on(C.GET_ACTIVE_HOUSE, (data) => {
            let active_house_games = decode(data);
            
            let active_gamelist = gamelist.map((item, i) => {
                item.disabled = active_house_games[i];
                if(i == 0 || i == 19) item.disabled = 0;
                return item;
            }) 
            this.setState({games: active_gamelist});
         })
        socket.emit(C.GET_ACTIVE_HOUSE);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.specific == 'recent' && props.hgames?.length > 0) {
            return { games: props.hgames, loading: false};
        }
        return { loading: false };
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({ col: 'col-6' });
        }
        
        window.addEventListener('resize', this.handleResize);
        
        wait(50).then(() => {
            this.handleResize();
        })

        Event.on('hide_games', () => {
            wait(50)
                .then(() => {
                    this.handleResize();
                });
        })

        Event.on('toggle_chat', (show) => {
            wait(200).then(() => {
                this.setState({chat_show: show});
            })
        })

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        socket.off(C.GAMES);
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-content');

        if (pageContent !== null) {
            const width = parseFloat(pageContent.style.width);

            if (width < 700) {
                this.setState({ mdCol: '14.2%', isMin: true, iPadd:'5px' });
            }
            else {
                this.setState({ mdCol: '14.2%', isMin: false, iPadd: '10px' });
            }
        }
    }

    render() {
        let { games, col, mdCol, isMin } = this.state;
        const { t } = this.props;
        const list = games?.map((game, i) =>
            <AddGame t={t} key={__.toString(i)} col={col} mdCol={mdCol} item={game} isMin={isMin} chat_show={this.state.chat_show} />
        );
        return (
            <>
                <Row xs={7} md={7} lg={7} xl={7} sm={7} className="d-flex game-cards home-game m-0">
                    { list }
                </Row>
            </>
        );
    }
}

class AddGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
        };
    }

    render() {
        let { mdCol, item, isMin, iPadd, chat_show, t } = this.props;
        let style;
        if (item.prefix === 'crash') {
            mdCol = '28.5%'
            style = {
                width: 'auto'
            }
        }

        return (
            <>
                {!item.disabled ?
                    <Col className={chat_show ? 'game-items game-in-shrink' : 'game-items game-in-expand'} style={{maxWidth: mdCol, padding: iPadd}}>
                    {/* <Col className={chat_show ? 'game-items game-in-shrink' : 'game-items game-in-expand'}> */}
                        <Link to={item.prefix !== null ? '/' + item.prefix : '#'} className="game2-link">
                            <div className={item.prefix !== null ? 'game2-image' : 'game2-image orange'}>
                                <img src={item.image} className={'img-fluid'} alt={item.name} />
                                <div className="game2-home-title">
                                    {item.name ? <span>{item.name}</span> : ''}
                                </div>
                            </div>
                        </Link>
                    </Col>
                    :
                    <></>
                }
            </>
        );
    }

}
const mapStateToProps = state => ({
    originalGames: state.items.originalGames,
});

export default connect(mapStateToProps, {})(Games);
