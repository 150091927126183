import React, { Component } from "react";
import {Modal} from "react-bootstrap";
import socket from "../../../../Socket";
import {Event, decode, fixDate, wait} from "../../../../Helper";
import C from "../../../../Constant";

class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            notifications: []
        };
    }

    componentDidMount(){
        socket.emit(C.NOTIFICATION);
        socket.on(C.NOTIFICATION, data => this.getNotification(decode(data)));
    }

    componentWillUnmount() {
        socket.off(C.NOTIFICATION);
    }

    getNotification(data){
        wait(1000).then(() => {
            this.setState({ loading: false});
        })

        data.forEach((news, i) => {
            this.setState(state => ({ notifications: [<OpenModal t={this.props.t} key={i} title={news.title} content={news.content} date={news.date}/>, ...state.notifications] }));
        });
    }

    render() {
        return(
            <>
                { this.state.loading ?
                <>
                    <b className={"mt-1 d-block text-center"}>
                        <div className="spinner-grow text-pink" role="status" />
                    </b>
                </>
                : this.state.notifications
                }
            </>
        );
    }
}

class OpenModal extends React.Component {
    send = (data) => {
        Event.emit('show_notify', this.props)
    }
    render() {
        let { title, date, content } = this.props;
        return(
            <>
                <button className="dropdown-item notify-item mb-2 notification-box">
                    <div className="notification-div"><img src="/assets/images/notification-icon.png" width="57" heigth="57" alt="" className="notification-avatar" /></div>
                    <div className="notification-div">
                        <h5 className="notify-details-text notify-title">{title}</h5>
                        <small className="text-muted notify-date">{fixDate(date)}</small>

                        <p className="notify-details-text notify-content"><i className="mdi mdi-subdirectory-arrow-right"></i> {content}</p>
                     </div>
                </button>
            </>
        );
    }
}

export default Notification;
