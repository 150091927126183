import React from "react";
import { Button, Container, Row, Col, Dropdown, Stack } from "react-bootstrap";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import Logo from "./../Logo";
import Chat from "./../Chat";
import MobileMenu from "./../Menu/MobileMenu";
import Notifications from "./../Notification";
import { isMobile, Event, __ } from "../../../../Helper";
import Luckyspin from "../../Auth/Luckyspin";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import Resetpass from "../../Auth/Resetpass";
import RealTime from "./../RealTime";
import { connect } from "react-redux";
import { searchBar } from "../../../../actions/searchGame";
import Translation from "../Translation";
import Searchbar from "./Searchbar";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: !isMobile() ? "desktop" : "mobile",
      show: true,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (!isMobile()) {
      this.setState({ header: "desktop" });
    } else {
      this.setState({ header: "mobile" });
    }
  }

  collapse = () => {
    this.setState({ show: !this.state.show });
    Event.emit("toggle_sidebar", this.state.show);
  };

  searchChange = (e) => {
    this.props.searchBar(e.target.value);
  };

  render() {
    const { t } = this.props;
    return this.state.header == "desktop" ? (
      <div className="topbar guest-desktop-topbar" id="topbar">
        <RealTime t={t} />
        <Container id="header_bar" fluid>
          <Row>
            <Col md="6" sm="6">
              <Stack
                direction="horizontal"
                className="justify-content-between gap-4"
              >
                <Logo {...this.props} />
                <Searchbar t={t} />
              </Stack>
            </Col>

            <Col md="6" sm="6">
              <Stack direction="horizontal">
                <div className="ms-auto">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="guest-header-stack-right"
                  >
                    <Luckyspin t={t} />
                    <Translation />
                    <Login t={t} />
                    <Register t={t} />
                    <Resetpass t={t} />
                    <Chat t={t} location={this.props.location} />
                  </Stack>
                </div>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    ) : (
      <div className="topbar" id={"topbar"}>
        <RealTime t={t} />
        <Container id="header_bar" fluid>
          <Row>
            {/* <Col md="auto" sm="auto" className='p-0 w-auto'> */}
            <Col className="p-0">
              <Stack direction="horizontal" className="">
                <MobileMenu t={t} />
                <Logo />
              </Stack>
            </Col>
            <Col className="p-0 col-auto">
              <Luckyspin t={t} />
            </Col>
            <Col className="col">
              <Stack direction="horizontal">
                <div className="ms-auto">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="menu-button-right-group"
                  >
                    <Login t={t} />
                    <Register t={t} />
                    <Chat t={t} location={this.props.location} />
                  </Stack>
                </div>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

// export default Header;
export default connect(null, { searchBar })(Header);
