import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat, __ } from "../../../Helper";
/**
 * Game Engine
 */
function Engine() {
  let self = this;

  /**
   * Game Prefix
   */
  self.prefix = "_LIMBO";

  /**
   * Game Name
   */
  self.game = "limbo";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Itialize Game
   */
  self.init = false;

  /**
   * Game Status
   * 1 type: busted_limbo
   */
  self.gameStatus = null;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Round Payout
   */
  self.payout = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /**
   * Win Chance
   */
  self.chance = null;

  /**
   * Bet Number
   */
  self.betNumber = null;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Player is the Win or Lost
   */
  self.isWin = false;

  /**
   * Stop Playing if Won Amount
   */
  self.stopOnWin = 0;

  /**
   * Round Profit
   */
  self.roundProfit = 0;

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_LIMBO, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_LIMBO);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_LIMBO,
    encode({
      amount: self.amount,
      payout: self.payout,
      chance: self.chance,
      coin: self.coin,
    })
  );
};

/**
 * Auto Start
 */
Engine.prototype.autoStart = function () {
  let self = this;

  if (!self.init) return;

  if (parseInt(self.betNumber) <= 0) {
    self.trigger.emit("stop");
    return;
  }

  // Increase Amount on Win (player set increase amount on win in %)
  if (self.onWin !== 0) {
    // It Mean Player has win in this round
    if (self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onWin;
    }
  }

  // Increase Amount on Lost (player set increase amount on lost in %)
  if (self.onLost !== 0) {
    // It Mean Player has lost in this round
    if (!self.isWin) {
      self.amount = forceSatoshiFormat(self.amount) * self.onLost;
    }
  }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.roundProfit) >= forceSatoshiFormat(self.stopOnWin)
    ) {
      self.roundProfit = 0;
      self.trigger.emit("stop");
      return;
    }
  }

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_LIMBO,
    encode({
      amount: self.amount,
      payout: self.payout,
      chance: self.chance,
      coin: self.coin,
    })
  );
};

/** Start Game Engine **/
export default Engine;
