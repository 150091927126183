import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Stack } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import {
  __,
  isMobile,
  wait,
  Event,
  encode,
  decode,
  rand,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import storage from "../../../../Storage";

class TopGames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      games: [],
    };
  }

  componentDidMount() {
    socket.emit(C.LAST_BETS, encode({ filter: "all_bets", counts: 13 }));
    socket.on(C.LAST_BETS, (data) => {
      this.setState({ games: decode(data) });
    });
    socket.on(C.ADD_BET, (data) => this.addBet(decode(data)));
  }

  componentDidUpdate() {
    let self = this;
    if (this.state.games.length > 12) {
      self.setState({
        games: __.dropRight(self.state.games, self.state.games.length - 12),
      });
    }

    if (isMobile()) {
      if (this.state.games.length > 4) {
        self.setState({
          games: __.dropRight(self.state.games, self.state.games.length - 4),
        });
      }
    }
  }

  addBet(player) {
    if (player.game !== "crash") {
      if (!__.isNumber(player.game)) {
        this.setState((state) => ({ games: [player, ...state.games] }));
      }
    }
  }

  componentWillUnmount() {
    socket.off(C.TOP_SLOT_GAMES);
  }

  render() {
    const { t } = this.props;

    const list = this.state.games.map((item, i) => (
      <AddGame key={__.toString(i)} item={item} />
    ));

    return (
      <>
        <div className="topGamesHome">
          <div>
            <h2>
              <div class="intro-banner-vdo-play-btn-promo-2 pinkBg-promo-2 pinkBg-promo-warning">
                <span class="ripple pinkBg-promo-2"></span>
                <span class="ripple pinkBg-promo-2"></span>
                <span class="ripple pinkBg-promo-2"></span>
              </div>
              LIVE WINS
            </h2>
            <Row>{list}</Row>
          </div>
        </div>
      </>
    );
  }
}

function AddGame(props) {
  let { item } = props;

  let mdCol = 1;

  if (isMobile()) {
    mdCol = 3;
  }

  let wagered = rand(1, 1000) / 100;

  return (
    <Col className={"col-" + mdCol + " mb-2 ovh animated fadeInUp"}>
      <div className="lastwinstop">
        <Link
          to={item.uuid !== null ? "/slots/" + item.uuid : "#"}
          className="game2-link"
        >
          <div>
            <img
              src={item.origin_url}
              className="img-fluid w-100 brd-2"
              alt={item.name}
            />
          </div>
        </Link>
        <span className="lastWins text-warning text-center font-11 font-weight-light d-block mt-1">
          Win ${wagered}
        </span>
      </div>
    </Col>
  );
}

export default TopGames;
