import io from "socket.io-client";
import axios from "axios";
import {
  SOCKET,
  Event,
  sendNotfication,
  DEVELOPMENT,
  Game,
  SITE,
  API,
} from "./Helper";
import storage from "./Storage";

/**
 * Socket URL
 */
const ws = SOCKET;

let api = API + "/rate";

axios({
  url: api,
  method: "GET",
}).then(function (req) {
  var res = req.data;
  Object.keys(res).forEach(function (name, key) {
    let val = res[name];
    Game[name] = parseFloat(val);
  });
});

/**
 * Initialize Socket Connection
 */
let socket;

export const connect = (prefix, token) => {
  socket = io(ws + prefix, {
    secure: true,
    forceNew: true,
    withCredentials: true,
    query: {
      auth_token: token,
      user_key: storage.getKey("key"),
    },
  });
};

export function disconnect(name) {
  socket.emit(name + '-off')
  socket.off(name);
}

/*
if (!DEVELOPMENT) {
    if (storage.getKey('mthfk') === null) {
        connect(storage.getKey('token'));
    }
} else {
    connect(storage.getKey('token'));
}
*/
connect("", storage.getKey("token"));

let dis = false;

socket.on("connect", () => {
  Event.emit("connect");
  if (DEVELOPMENT) {
    if (dis) {
      console.clear();
    }
  }
});

/*
 * Disconnect Server
 */
socket.on("disconnect", () => {
  Event.emit("disconnect");
  // sendNotfication('Connection Lost, Trying to connect...', 'danger', 'top-center');
  dis = true;
});

/*
 * Disconnect Server for Hacker
 */
/*
Event.on('mthfk', () => {
    if (!DEVELOPMENT) socket.disconnect();
});
*/

export default socket;
