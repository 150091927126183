import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { __, isMobile, wait, Event, decode } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import storage from "../../../../Storage";

const popularGames = ["crash", "classic-dice", "blackjack", "wheel"];
class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      games: [],
      mobile: isMobile(),
      isLogged: storage.getKey("logged") !== null ? true : false,
    };
  }

  componentDidMount() {
    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize());
    socket.off(C.GAMES);
  }

  emitDepostModal = () => {
    if (this.state.isLogged) Event.emit("e_sevice_deposit");
    else Event.emit("register_form");
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Swiper
          id="service_swiper"
          spaceBetween={24}
          slidesPerView={2}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          // pagination={{
          //     clickable: true,
          //     el: ".service-swiper-pagination",
          // }}
          modules={[Pagination, Autoplay]}
          className="home-services"
          breakpointsBase="container"
          breakpoints={{
            300: {
              slidesPerView: 1.3,
              centeredSlides: true,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              centeredSlides: true,
              spaceBetween: 20,
            },
            815: {
              slidesPerView: 3,
              centeredSlides: false,
              spaceBetween: 15,
            },
          }}
        >
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="/tournament/high-roller">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/autumn-festival.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="/tournament/summer-drops">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/moneyrain.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="#">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/summer-drops-2.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="/tournament/high-roller">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/autumn-festival.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="/tournament/summer-drops">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/moneyrain.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="swipertournaments mt-3">
            <Link to="#">
              <div className="game2-image orange position-relative">
                <img
                  src="/assets/images/tournamments/summer-drops-2.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>

        {/* <div class="service-swiper-pagination"></div> */}
      </>
    );
  }
}

export default Services;
