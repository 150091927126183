import React, { Component, createRef } from "react";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import socket from "../../../../Socket";
import {
  __,
  forceSatoshiFormat,
  encode,
  decode,
  wait,
  Event,
  isMobile,
  removeTrailingZerosFixNum,
} from "../../../../Helper";
import C from "../../../../Constant";
import { t } from "i18next";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      paddingHeader: "",
      padding: "",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ padding: "p-1 px-3", paddingHeader: "px-4" });
    }
    Event.on("single_game_modal_close", this.handleClose);
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <button
          onClick={(e) => this.setState({ show: !this.state.show })}
          className={"dropdown-item font-14"}
        >
          <svg
            className="mr-2 text-drop"
            fill="#7a88af"
            width="17px"
            height="17px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#7a88af"
            strokeWidth="0.544"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title>rotate1</title>
              <path d="M26.034 16.767l-4.090-4.090h2.396c-0.022-0.060-0.045-0.119-0.068-0.178-1.421-3.602-4.933-6.15-9.040-6.15-5.365 0-9.715 4.35-9.715 9.715s4.35 9.716 9.715 9.716c3.947 0 7.344-2.354 8.864-5.733 0.015-0.031 0.024-0.066 0.038-0.099l2.605 2.589c-0.017 0.030-0.029 0.064-0.047 0.096-0.004 0.007-0.008 0.014-0.012 0.020-2.299 3.972-6.594 6.643-11.513 6.643-7.34-0.002-13.292-5.954-13.292-13.296s5.952-13.294 13.294-13.294c6.138 0 11.303 4.158 12.833 9.812 0.015 0.052 0.019 0.107 0.032 0.159h2.091l-4.091 4.090z"></path>
            </g>
          </svg>
          {t("history")}
        </button>
        <Modal
          size={"lg"}
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-md-modal"
        >
          <Modal.Header className={this.state.paddingHeader}>
            {t("history")}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className={this.state.padding + " history-modal"}>
            <Child t={t} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class Child extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDetails: false,
      next: false,
      history: [],
      pageIndex: 1,
      itemsPerPage: 10,
      total_items: 0,
    };
    this.socketEventHistory = null;
  }

  componentDidMount() {
    this._isMounted = true;
    wait(1000).then(() => {
      if (document.getElementById("list") !== null) {
        const ps = new PerfectScrollbar("#list", {
          wheelSpeed: 1,
          suppressScrollX: true,
          wheelPropagation: true,
          minScrollbarLength: 2,
        });
        ps.update();
      }
    });
    wait(500).then(() => {
      socket.emit(
        C.MY_HISTORY,
        encode({
          pageIndex: this.state.pageIndex,
          items: this.state.itemsPerPage,
        })
      );
    });
    this.socketEventHistory = socket.on(C.MY_HISTORY, (data) => {
      this.makeHistory(decode(data));
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.socketEventHistory.off();
  }

  makeHistory = (arr) => {
    if (this._isMounted) {
      var result = arr.history;
      let history = [];
      result.forEach((player, i) => {
        history.push(
          <HistoryTable
            clicked={(e) => this.clicked(player)}
            key={__.toString(i)}
            player={player}
            self={true}
          />
        );
      });
      this.setState((state) => ({
        history: [...history],
        total_items: result.length > 0 ? Number(result[0].total_rows) : 0,
      }));

      this.setState({ loading: false });
    }
  };

  clicked = (data) => {
    if (this._isMounted) {
      Event.emit("single_game_modal_close");
      Event.emit("single_game_modal", { data: data });
      this.setState({ showDetails: true });
    }
  };

  nextPage = () => {
    if (this._isMounted) {
      socket.emit(
        C.MY_HISTORY,
        encode({
          pageIndex: this.state.pageIndex + 1,
          items: this.state.itemsPerPage,
        })
      );
      this.setState({ pageIndex: this.state.pageIndex + 1 });
    }
  };

  back = () => {
    if (this._isMounted) {
      this.setState({ showDetails: false });
    }
  };

  render() {
    let { loading, history } = this.state;

    return (
      <>
        <>
          {loading ? (
            <div className="text-center my-3">
              <div className="spinner-grow text-white my-3" role="status" />
            </div>
          ) : (
            <>
              <>
                <div className="user-history" style={{ maxHeight: 500 }}>
                  <div className="table-responsive mt-2" id="list">
                    <table className="table table-hover font-13">
                      <thead>
                        <tr>
                          <th scope="col">{t("game")}</th>
                          <th scope="col">{t("amount")}</th>
                          <th scope="col">{t("profit")}</th>
                        </tr>
                      </thead>
                      <tbody>{history.length > 0 && history}</tbody>
                    </table>

                    {history.length === 0 && (
                      <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                        [ {t("you_have_not_played_yet")} ]
                      </div>
                    )}
                    {this.state.total_items / this.state.itemsPerPage >
                      this.state.pageIndex && (
                      <div className={"text-center"}>
                        <button
                          onClick={this.nextPage}
                          className={"mb-2 btn bg-cs btn-xs"}
                        >
                          <i className={"mdi mdi-arrow-right-bold-circle"} />{" "}
                          {t("next_page")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </>
          )}
        </>
      </>
    );
  }
}

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  render() {
    let { username, amount, profit, coin, game } = this.props.player;
    let isWinner = false;

    amount = removeTrailingZerosFixNum(
      Number(forceSatoshiFormat(amount, coin)),
      2
    );
    profit = removeTrailingZerosFixNum(
      Number(forceSatoshiFormat(profit, coin)),
      2
    );

    if (__.toNumber(profit) !== 0.0) isWinner = true;

    game = __.replace(game, "snakeandladders", "snake");
    game = __.replace(game, "cardmonte", "Card Monte");
    game = __.replace(game, "videopoker", "Video Poker");

    return (
      <tr className="cpt">
        <td onClick={this.props.clicked}>
          <div className="historyrow historyrowfirst">
            {__.replace(__.upperFirst(game), "_", " ")}
          </div>
        </td>
        <td
          onClick={this.props.clicked}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          <div className="historyrow">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-2 hidden-sm"}
              alt={coin}
            />
            {amount} {__.upperCase(coin)}
          </div>
        </td>
        <td
          onClick={this.props.clicked}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          <div className="historyrow historyrowlast">
            <img
              src={"/assets/images/" + __.toLower(coin) + ".png"}
              className={"mini-coin-2 hidden-sm"}
              alt={coin}
            />
            {profit} {__.upperCase(coin)}
          </div>
        </td>
      </tr>
    );
  }
}

export default History;
