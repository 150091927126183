import * as PIXI from "pixi.js";
import { wait } from "../Helper/index.js";

function Dice(obj) {
  this.game = obj;
  this.started = false;
  this.result = null;
  this.texture = PIXI.Texture.from("/assets/images/snake/1.png");
  this.sprite = PIXI.Sprite.from(this.texture);
}

Dice.prototype.Roll = function (result) {
  if (this.started) return;
  this.started = true;

  this.result = result;

  let ticker = new PIXI.Ticker();

  let done = false;
  ticker.add((delta) => {
    let rand = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
    this.texture = PIXI.Texture.from(`/assets/images/snake/${rand}.png`);
    this.sprite.texture = this.texture;

    wait(2000).then(() => {
      if (!done) {
        this.texture = PIXI.Texture.from(`/assets/images/snake/${result}.png`);
        this.sprite.texture = this.texture;
        done = true;
        this.started = false;
        this.game.event.emit("start");
        ticker.stop();
      }
    });
  });

  ticker.start();
};

export default Dice;
