import React from 'react'
import { Modal } from "react-bootstrap";
import { Event } from "../../../Helper";
export default class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }
    componentDidMount() {
     
    }
    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <button onClick={this.handleShow} className={'btn btn- btn-sm font-weight-bold  affilatetermsbtn'}>
                    <i className="mdi mdi-alert-circle-outline align-middle font-14 mr-1" />
                    {t('affiliate_terms')}
                </button>
                <Modal
                    size="lg"
                    backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="affiliate-lg-modal"
                >
                    <Modal.Header className={'font-light'}>
                        {t('affiliate_terms')}
                        <button type="button" className="close" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className={'modal-reader'}>
                        <p className={'text-white font-light mb-2 font-14'}>
                            {t('affiliate_terms_txt_1')}
                            <br />
                            <br />
                            <strong className='text-warning'>{t('affiliate_terms_txt_2')}</strong><br />
                            <span className='text-warning'>{t('commision')} = 15% from Deposits your Referrals</span><br /><br />
                            <span className='text-warning'>Example earnings: <br />Deposit: 1000 USD = You get 150 USD as Affiliate</span><br />
                            <br />
                            1. {t('affiliate_terms_subtxt_1')}
                            <br />

                            2. {t('affiliate_terms_subtxt_2')}
                            <br />

                            3. {t('affiliate_terms_subtxt_3')}
                            <br />

                            {t('affiliate_terms_subtxt_3_0')}
                            <br />

                            1. {t('affiliate_terms_subtxt_4')}
                            <br />
                            1.1. {t('affiliate_terms_subtxt_4_1')}
                            <br />

                            1.2. {t('affiliate_terms_subtxt_4_2')}
                            <br />

                            1.3. {t('affiliate_terms_subtxt_4_3')}
                            <br />

                            2. {t('affiliate_terms_subtxt_5')}
                            <br />
                            2.1. {t('affiliate_terms_subtxt_5_1')}
                            <br />

                            3. {t('affiliate_terms_subtxt_6')}
                            <br />
                            3.1. {t('affiliate_terms_subtxt_6_1')}
                            <br />

                            a) {t('affiliate_terms_subtxt_6_a')}
                            <br />

                            b) {t('affiliate_terms_subtxt_6_b')}
                            <br />

                            c) {t('affiliate_terms_subtxt_6_c')}
                            <br />

                            d) {t('affiliate_terms_subtxt_6_d')}
                            <br />

                            e) {t('affiliate_terms_subtxt_6_e')}
                            <br />

                            4. {t('affiliate_terms_subtxt_7')}
                            <br />
                            4.1. {t('affiliate_terms_subtxt_7_1')}
                            <br />

                            4.2. {t('affiliate_terms_subtxt_7_2')}
                            <br />

                            4.3. {t('affiliate_terms_subtxt_7_3')}
                            <br />

                            5. {t('affiliate_terms_subtxt_8')}
                            <br />
                            5.1. {t('affiliate_terms_subtxt_8_1')}
                            <br />

                            a) {t('affiliate_terms_subtxt_8_a')}
                            <br />

                            b) {t('affiliate_terms_subtxt_8_b')}
                            <br />

                            c) {t('affiliate_terms_subtxt_8_c')}
                            <br />

                            d) {t('affiliate_terms_subtxt_8_d')}
                            <br />

                            5.2. {t('affiliate_terms_subtxt_8_2')}

                            <br />
                            a) {t('affiliate_terms_subtxt_8_2_a')}
                            <br />

                            b) {t('affiliate_terms_subtxt_8_2_b')}
                            <br />

                            c) {t('affiliate_terms_subtxt_8_2_c')}
                            <br />

                            d) {t('affiliate_terms_subtxt_8_2_d')}
                            <br />

                            I. {t('affiliate_terms_subtxt_8_2_aa')}
                            <br />

                            II. {t('affiliate_terms_subtxt_8_2_bb')}
                            <br />

                            III. {t('affiliate_terms_subtxt_8_2_cc')}
                            <br />

                            e) {t('affiliate_terms_subtxt_8_2_e')}
                            <br />

                            f) {t('affiliate_terms_subtxt_8_2_f')}
                            <br />

                            6. {t('affiliate_terms_subtxt_9')}
                            <br />
                            6.1. {t('affiliate_terms_subtxt_9_1')}
                            <br />

                            6.2. {t('affiliate_terms_subtxt_9_2')}
                            <br />

                            6.3. {t('affiliate_terms_subtxt_9_3')}
                            <br />

                            6.4. {t('affiliate_terms_subtxt_9_4')}
                            <br />

                            6.5. {t('affiliate_terms_subtxt_9_5')}
                            <br />

                            6.6. {t('affiliate_terms_subtxt_9_6')}
                            <br />

                            6.7. {t('affiliate_terms_subtxt_9_7')}

                            <br />
                            6.8. {t('affiliate_terms_subtxt_9_8')}

                            <br />
                            6.9. {t('affiliate_terms_subtxt_9_9')}
                            <br />

                            6.10. {t('affiliate_terms_subtxt_9_10')}
                            <br />

                            6.11. {t('affiliate_terms_subtxt_9_11')}
                            <br />

                            7. {t('affiliate_terms_subtxt_10')}
                            <br />
                            7.1. {t('affiliate_terms_subtxt_10_1')}
                            <br />

                            7.2. {t('affiliate_terms_subtxt_10_2')}
                            <br />

                            a) {t('affiliate_terms_subtxt_10_2_a')}
                            <br />

                            b) {t('affiliate_terms_subtxt_10_2_b')}

                            <br />
                            c) {t('affiliate_terms_subtxt_10_2_c')}
                            <br />

                            d) {t('affiliate_terms_subtxt_10_2_d')}
                            <br />

                            e) {t('affiliate_terms_subtxt_10_2_e')}
                            <br />

                            f) {t('affiliate_terms_subtxt_10_2_f')}

                            <br />
                            7.3. {t('affiliate_terms_subtxt_10_3')}

                            <br />
                            8. {t('affiliate_terms_subtxt_11')}
                            <br />
                            8.1. {t('affiliate_terms_subtxt_11_1')}
                            <br />

                            8.2. {t('affiliate_terms_subtxt_11_2')}
                            <br />

                            9. {t('affiliate_terms_subtxt_12')}
                            <br />
                            9.1. {t('affiliate_terms_subtxt_12_1')}
                            <br />

                            a) {t('affiliate_terms_subtxt_12_1_a')}
                            <br />

                            b) {t('affiliate_terms_subtxt_12_1_b')}
                            <br />

                            c) {t('affiliate_terms_subtxt_12_1_c')}
                            <br />

                            d) {t('affiliate_terms_subtxt_12_1_d')}
                            <br />

                            e) {t('affiliate_terms_subtxt_12_1_e')}
                            <br />

                            f) {t('affiliate_terms_subtxt_12_1_f')}

                            <br />
                            g) {t('affiliate_terms_subtxt_12_1_g')}

                            <br />
                            h) {t('affiliate_terms_subtxt_12_1_h')}

                            <br />
                            9.2. {t('affiliate_terms_subtxt_12_2')}

                            <br />
                            10. {t('affiliate_terms_subtxt_13')}
                            <br />
                            10.1. {t('affiliate_terms_subtxt_13_1')}
                            <br />
                            10.2. {t('affiliate_terms_subtxt_13_2')}
                            <br />
                            11. {t('affiliate_terms_subtxt_14')}
                            <br />
                            11.1. {t('affiliate_terms_subtxt_14_1')}
                            <br />

                            12. {t('affiliate_terms_subtxt_15')}
                            <br />
                            12.1. {t('affiliate_terms_subtxt_15_1')}
                            <br />

                            12.2. {t('affiliate_terms_subtxt_15_2')}

                            <br />
                            13. {t('affiliate_terms_subtxt_16')}
                            <br />
                            13.1. {t('affiliate_terms_subtxt_16_1')}

                            <br />
                            14. {t('affiliate_terms_subtxt_17')}
                            <br />
                            14.1. {t('affiliate_terms_subtxt_17_1')}
                            <br />

                            15. {t('affiliate_terms_subtxt_18')}
                            <br />
                            15.1. {t('affiliate_terms_subtxt_18_1')}

                            <br />
                            16. {t('affiliate_terms_subtxt_19')}
                            <br />
                            16.1. {t('affiliate_terms_subtxt_19_1')}
                            <br />

                            17. {t('affiliate_terms_subtxt_20')}
                            <br />
                            17.1. {t('affiliate_terms_subtxt_20_1')}
                            <br />

                            17.2. {t('affiliate_terms_subtxt_20_2')}

                            <br />
                            18. {t('affiliate_terms_subtxt_21')}
                            <br />
                            18.1. {t('affiliate_terms_subtxt_21_1')}
                            <br />

                            18.2. {t('affiliate_terms_subtxt_21_2')}

                            <br />
                            19. {t('affiliate_terms_subtxt_22')}
                            <br />
                            19.1. {t('affiliate_terms_subtxt_22_1')}
                            <br />
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
