import React, { Component, createRef } from 'react';
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import {Event, wait, decode, encode, sendNotfication} from "../../../Helper";
import C from "../../../Constant";
class OneBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: {},
            loading: true,
            noPage: false
        };
    }
    componentDidMount() {
        let { title, id } = this.props.match.params;
        if(!isNaN(Number(id))) {
            socket.emit(C.GET_ONE_BLOGS_QUIZ, encode(id));
        }
        else {
            this.setState({noPage: true, loading: false});
        }
        socket.on(C.GET_ONE_BLOGS_QUIZ, data => {
            let decoded = decode(data);
            if (decoded) {
                this.setState({blog: decoded[0], loading: false});
            }
            else {
                this.setState({noPage: true, loading: false});
            }
        });
    }
    componentWillUnmount() {
        // socket.off(C.GET_BLOGS);
    }
    render() {
        let { blog } = this.state;
        const { t } = this.props;
        return (
            <>
                {this.state.loading ? 
                    <div className='spinner-wrapper'>
                        <div className="text-center">
                            <div className="spinner-grow text-white my-5" role="status" />
                        </div>
                    </div>
                :
                    this.state.noPage? 
                    <>
                        <p className='no-exist-blog'>Such a blog does not exist!</p>
                    </>
                    :
                    <>
                        <Helmet>
                            <title>{blog.title}</title>
                            <meta name="description" content={blog.description.substr(0, 160)} />
                            <meta name="og:title" content={blog.title} />
                            <meta name="og:description" content={blog.description.substr(0, 160)} />
                            <link rel="canonical" href="/blog" />
                        </Helmet>
                        <div className='one-blog-wrapper'>
                            <p className='one-blog-title'>{blog.title}</p>
                            <p className='one-blog-author'>{t('author')}: <span className='one-blog-author-name'>@{blog.author}</span></p>
                            <p className='one-blog-published'>{t('pulished')}: {blog.date}</p>
                            <p className='one-blog-content'>{t('content')}</p>
                            <div dangerouslySetInnerHTML={{ __html: blog.description }} />
                            <p className='one-blog-author ftext'>©BetsMixer.com — The article is for informational purposes only and reflects informative data for casino players.</p>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default withRouter(OneBlog);
