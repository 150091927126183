import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { __, wait, forceSatoshiFormat, encode, decode } from "../../../../Helper";
import { gameCoin } from "../../../../actions/gameCoin";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import C from "../../../../Constant";

class BankRoll extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            amount: 0,
            coin: storage.getKey('coin') ? storage.getKey('coin') : 'USDT',
            game: this.props.game
        }
        this.socketEventBankroll = null;
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.gameCoin();

        if (this._isMounted) {
            let coin = this.props.coin ? this.props.coin : this.state.coin;

            if (__.toLower(coin) === 'nc') {
                this.setState({ amount: 0 });
            }
            else {
                wait(700).then(() => {
                    socket.emit(C.BANKROLL, encode({ game: this.state.game, coin: coin }));
                });
            }

            this.socketEventBankroll = socket.on(C.BANKROLL, data => this.setBankRoll(decode(data)));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.coin) {
            if (__.toLower(nextProps.coin) === 'nc') {
                this.setState({ amount: 0 });
            }
            else {
                socket.emit(C.BANKROLL, encode({ game: this.state.game, coin: nextProps.coin }));
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.socketEventBankroll.off();
    }

    setBankRoll = (data) => {
        if (this._isMounted) {
            this.setState({ amount: data });
        }
    }

    help = (e) => {
        if (this._isMounted) {
            this.setState({ show: true, effect: 'zoomIn' });
        }
    }

    close = () => {
        if (this._isMounted) {
            this.setState({ show: false, effect: 'zoomOut' });
        }
    }

    render() {
        let amount = forceSatoshiFormat(this.state.amount);

        let { coin, style, style2 } = this.props;

        return null;

        return (
            <>
                <div style={style}>
                    <button className="btn btn-md bg-cs2 animated fadeInDown" onClick={this.help} style={style2}>
                        <img className="mini-coin-7" src={'/assets/images/' + coin + '.png'} alt="Coin" />
                        {amount} {coin}
                    </button>
                </div>
                <Modal
                    size="md"
                    centered={true}
                    backdrop={'static'}
                    show={this.state.show}
                    onHide={this.close}
                    aria-labelledby="bankroll-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        BankRoll
                        <button type="button" className="close p-2" onClick={this.close}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="font-15 text-white">
                            Bankroll is the pool of money that the bankroller uses to pay out winners of the game.
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

BankRoll.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { gameCoin })(BankRoll);
