import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputGroup, Form, Button, Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import { gameCoin } from "../../../actions/gameCoin";
import { setPlay } from "../../../actions/gamePlay";
import { setWallet } from "../../../actions/gameWallet";
import { setClassicDiceResult } from "../../../actions/gameResult";
import {
  Event,
  __,
  isLogged,
  funPlay,
  forceSatoshiFormat,
  isValidNumber,
  sendNotfication,
  wait,
  isMobile,
  playAudio,
  defaultBetAmount,
  getDefaultBetAmount,
  removeTrailingZeros
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      started: false,
      bet: "manual",
      buttonText: "roll",
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
      type: "Under",
      formData: {
        payout: this.props.classic_dice_payout,
        chance: this.props.classic_dice_chance,
        type: this.props.classic_dice_type,
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    if (this._isMounted) {
      let { engine } = this.state;

      wait(300).then(() => {
        if (isMobile()) {
          this.setState({ padding: "p-1" });
        }
      });

      this.props.gameCoin();
      engine.on("play", (data) => this.play(data));
      engine.on("busted", (data) => this.busted(data));
      engine.on("error", (data) => this.error(data));

      Event.on("change_type", (type) => {
        this.setState({ type: type });
      });
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  componentWillReceiveProps(props) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        payout: props.classic_dice_payout,
        chance: props.classic_dice_chance,
        type: props.classic_dice_type,
      },
    }));
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({
        inputDisabled: false,
        started: false,
      });
    }
  }

  play(data) {
    if (this._isMounted) {
      this.props.setClassicDiceResult(parseFloat(data.target));
      this.props.setPlay(true);
      playAudio("dice2.mp3");
    }
  }

  busted(data) {
    if (this._isMounted) {
      this.setState({ inputDisabled: false });

      let isWin = false;

      if (parseFloat(data.profit) !== 0) {
        isWin = true;
      }

      if (isWin) {
        playAudio("win.mp3");
        document.querySelector(
          ".dice-result span"
        ).style.background = `#35e179`;
        document.querySelector(".dice-result span").classList.add("add-sh");
        document.querySelector(".dice-result span").classList.remove("add-shg");
      } else {
        // playAudio('lost.mp3')
        document.querySelector(
          ".dice-result span"
        ).style.background = `#df3041`;
        document.querySelector(".dice-result span").classList.add("add-shg");
        document.querySelector(".dice-result span").classList.remove("add-sh");
      }
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount } = this.state;
      wait(500).then(() => {
        engine.init = true;
        engine.payout = this.props.classic_dice_payout;
        engine.chance = this.props.classic_dice_chance;
        engine.type = this.props.classic_dice_type;
        engine.coin = this.props.coin;
        engine.amount = amount;
        engine.start();
      });
    }
  }

  isCorrectBet(amount) {
    if (this._isMounted) {
      if (__.isUndefined(amount)) return;

      if (amount === "") return false;

      if (!isValidNumber(amount)) return false;

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      return true;
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      // Validate bet amount
      if (!this.isCorrectBet(amount)) return false;

      this.setState({ inputDisabled: true });
      this.placeBet();
    }
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.value;
      let name = target.name;
      storage.setKey("lam", value);
      this.setState({ [name]: value });
    }
  }

  betMode = (type) => {
    if (this._isMounted) {
      this.setState({ bet: type });
    }
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  render() {
    let { bet, inputDisabled, started } = this.state;
    const { t } = this.props; 
    return (
      <>
        {this._isMounted && (
          <>
            <Form
              className="w-100 mt-1"
              onSubmit={(e) => {
                this.handleBet(e);
              }}
            >
              <Row>
                <Col sm={12} className={"d-md-none d-sm-block"}>
                  {bet === "manual" && (
                    <Button
                      variant={"btn btn-xs btn-block btn-bet py-2 mb-2 "}
                      disabled={inputDisabled}
                      type="submit"
                    >
                      {t(this.state.buttonText)}
                    </Button>
                  )}
                </Col>

                <Col xl={6} md={7}>
                  <InputGroup className="mb-1">
                    <InputGroup.Text>
                      <img
                        src={
                          "/assets/images/" +
                          __.toLower(this.props.coin) +
                          ".png"
                        }
                        className={"mini-coin-7"}
                        alt=""
                      />
                      {t('bet_b')}
                    </InputGroup.Text>
                    <Form.Control
                      ref={this.outRef}
                      type="number"
                      disabled={inputDisabled}
                      className="form-control"
                      id="amount"
                      name="amount"
                      value={removeTrailingZeros(this.state.amount)}
                      autoComplete={"off"}
                      onKeyUp={this.handleInputChange}
                      onChange={this.handleInputChange}
                    ></Form.Control>
                    <InputGroup.Text>
                      <RangeCredit
                        set={this.setAmount}
                        amount={this.state.amount}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col xl={6} md={5}>
                  <InputGroup className="mb-1">
                    <InputGroup.Text>
                      <img
                        src={
                          "/assets/images/" +
                          __.toLower(this.props.coin) +
                          ".png"
                        }
                        className={"mini-coin-7"}
                        alt=""
                      />
                      {t('win_amount')}
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={removeTrailingZeros(forceSatoshiFormat(
                        this.props.classic_dice_payout
                          ? (this.state.amount * this.props.classic_dice_payout) - this.state.amount
                          : 0.00000004
                      ))}
                    ></Form.Control>
                  </InputGroup>
                </Col>
              </Row>

              {bet === "manual" && (
                <Row>
                  <Col md={5} sm={12} className="m-auto hidden-sm">
                    <div className={"form-group rev mb-1"}>
                      <Button
                        variant={"btn btn-md btn-block mt-2 btn-bet "}
                        disabled={inputDisabled}
                        type="submit"
                      >
                        {t(this.state.buttonText)}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
            {bet === "auto" && (
              <AutoBet
                engine={this.state.engine}
                formData={this.state.formData}
                amount={this.state.amount}
              />
            )}
            <div
              className="btn-group btn-group-toggle my-2 ml-1"
              data-toggle="buttons"
            >
              <label
                className={
                  "" + bet === "manual"
                    ? "btn bg-cs2 btn-xs active"
                    : "btn bg-cs2 btn-xs "
                }
              >
                <input
                  disabled={inputDisabled}
                  onClick={(e) => this.betMode("manual")}
                  type="radio"
                />{" "}
                {t('manual')}
              </label>
              <label
                className={
                  "" + bet === "auto"
                    ? "btn bg-cs2 btn-xs active"
                    : "btn bg-cs2 btn-xs "
                }
              >
                <input
                  disabled={inputDisabled}
                  onClick={(e) => this.betMode("auto")}
                  type="radio"
                />{" "}
                {t('auto')}
              </label>
            </div>
          </>
        )}
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
  classic_dice_payout: PropTypes.number,
  classic_dice_chance: PropTypes.number,
  classic_dice_type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
  classic_dice_payout: state.items.classic_dice_payout,
  classic_dice_chance: state.items.classic_dice_chance,
  classic_dice_type: state.items.classic_dice_type,
});

export default connect(mapStateToProps, {
  setPlay,
  gameCoin,
  setClassicDiceResult,
  setWallet,
})(Bet);
