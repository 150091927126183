import React, { createRef } from "react";
import { Button } from "react-bootstrap";
import storage from "../../../../../Storage";
import { __, Event } from "../../../../../Helper";
import data from "./data";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // scripts: !_.isNull(storage.getKey("scripts"))
      //   ? __.concat(JSON.parse(storage.getKey("scripts")), data)
      //   : [],
        scripts: data,
      list: [],
    };
  }

  componentDidMount() {
    let self = this;

    _.forEach(this.state.scripts, function (value) {
      self.setState((state) => ({
        list: [<Make data={value} clicked={self.clicked} />, ...state.list],
      }));
    });
  }

  clicked(e) {
    e.preventDefault();
    let actives = document.querySelectorAll(".script-list button");
    _.forEach(actives, function (button) {
      button.classList.remove("active");
    });
    e.target.classList.add("active");

    Event.emit("script", this.data);
  }

  render() {
    return (
      <div ref={this.ref} className="script-list">
        {this.state.list}
      </div>
    );
  }
}

function Make(props) {
  const { name, content } = props.data;

  return (
    <Button
      className={"bg-cs5 btn-sm shadow-none mr-1 btn-block"}
      onClick={(e) => props.clicked(e)}
    >
      <i className="mdi mdi-circle-outline" /> {name}
    </Button>
  );
}

export default List;
