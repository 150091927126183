import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Event } from "../../../../Helper";
import { Button, Image } from "react-bootstrap";

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {
  }

  loadHome = () => {
    Event.emit('clear_search')
  }
  render() {
    return (
      <>
        <Link onClick={this.loadHome} to={'/'} className="logo-wrapper">
          {/* <Image fluid src="/assets/images/betsmixer-g.png"/> */}
          <picture>
            <source
              media="(min-width:768px)"
              srcSet="/assets/images/betsmixer-g.svg"
            />
            <img className="img-fluid" src="/assets/images/avatar-m.svg" />
          </picture>
        </Link>
      </>
    );
  }
}

export default withRouter(Logo);
