import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, InputGroup, Form, Col, Button } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  Event,
  __,
  isLogged,
  funPlay,
  forceSatoshiFormat,
  getDefaultBetAmount,
  isValidNumber,
  wait,
  sendNotfication,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      amount: defaultBetAmount(this.props.coin),
      total_win: false,
      started: false,
      profit: 0,
      cashout: false,
      inputDisabled: false,
      buttonText: "BET",
      btnType: "btn-bet",
      formData: {
        risk: "low",
      },
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.wrapperRef = React.createRef();
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    this.props.gameCoin();
    engine.on("play", () => this.play());
    engine.on("clicked", (data) => this.clicked(data));
    engine.on("cashout", () => this.end());
    engine.on("busted", () => this.busted());
    engine.on("error", (data) => this.error(data));
    engine.on("stop_playing", () => this.stopManual());
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) return;
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    this.setState({
      started: false,
      buttonText: "BET",
      inputDisabled: false,
    });
  }

  busted = () => {
    if (this._isMounted) {
      this.setState({
        inputDisabled: true,
        inputDisabled2: true,
      });

      return this.end();
    }
  };

  end() {
    if (this._isMounted) {
      wait(1000).then(() => {
        this.setState({
          profit: 0,
          cashout: false,
          buttonText: "BET",
          btnType: "btn-bet",
          inputDisabled: false,
          inputDisabled2: false,
          started: false,
        });
      });
    }
  }

  play() {
    if (this._isMounted) {
      this.setState({
        started: true,
        inputDisabled: true,
        inputDisabled2: true,
        buttonText: "Game is Started!",
      });
    }
  }

  clicked(data) {
    if (this._isMounted) {
      let { profit, coin } = data;
      let calc = __.toNumber(profit) + __.toNumber(this.state.profit);
      let win = forceSatoshiFormat(calc);

      this.setState({
        inputDisabled: false,
        btnType: "btn-bet-success",
        buttonText: win + " " + coin + " CashOut",
        cashout: true,
        profit: win,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });
    }
  }

  handleBet(e) {
    e.preventDefault();
    let { amount } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ inputDisabled: true });
    this.placeBet();
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, engine, cashout, profit, formData } = this.state;
      engine.init = true;

      if (cashout) {
        engine.profit = profit;
        engine.cashout();
        return;
      }

      wait(100).then(() => {
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.risk = formData.risk;
        engine.start();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  handleRisk = (e, value) => {
    e.preventDefault();
    if (!this.state.started) {
      for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
        this.wrapperRef.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          risk: value,
        },
      }));
      Event.emit("change_risk", value);
    }
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled2) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  render() {
    let { inputDisabled, inputDisabled2 } = this.state;
    return (
      <>
        <form
          className="w-100"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={6} md={7} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled2}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>RISK</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <button
                    onClick={(e) => {
                      this.handleRisk(e, "low");
                    }}
                    className={"btn btn-s-4 btn-sm active"}
                  >
                    Low
                  </button>
                  <button
                    onClick={(e) => {
                      this.handleRisk(e, "medium");
                    }}
                    className={"btn btn-s-4 btn-sm"}
                  >
                    Medium
                  </button>
                  <button
                    onClick={(e) => {
                      this.handleRisk(e, "high");
                    }}
                    className={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md={5} sm={12} className=" m-auto">
              <div className={"form-group rev mt-2 mb-0"}>
                <Button
                  variant={"btn btn-md btn-block mt-2 " + this.state.btnType}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, {
  gameCoin,
  setWallet,
})(Bet);
