import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { forceSatoshiFormat, encode, decode } from "../../../Helper";
/**
 * Game Engine
 */
function Engine() {
  let self = this;

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Game Name
   */
  self.game = "blackjack";

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Itialize Game
   */
  self.init = false;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /**
   * Player profit
   */
  self.profit = false;

  /**
   * Game Result Cards
   */
  self.pCards = [];
  self.dCards = [];

  /*
   * Game history
   */
  self.history = [];

  /*
   * Start Engine
   */
  self.started = true;
}

/**
 * listen to socket
 */
Engine.prototype.listen = function () {
  var self = this;
  self.ws.on(C.PLAY_BLACKJACK, (data) => {
    data = decode(data);
    self.trigger.emit(data.command, data);
  });
};

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_BLACKJACK);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.001) {
    self.amount = forceSatoshiFormat(0.001);
  }

  self.ws.emit(
    C.PLAY_BLACKJACK,
    encode({
      command: "play",
      amount: self.amount,
      coin: self.coin,
    })
  );
};

/**
 * Busted Game
 */
Engine.prototype.busted = function () {
  let self = this;

  self.history = [];

  self.ws.emit(
    C.PLAY_BLACKJACK,
    encode({
      command: "busted",
      result: self.result,
      pCards: self.pCards,
      dCards: self.dCards,
    })
  );
};

/**
 * User playing hisotry (continue on reload page)
 */
Engine.prototype.saveHistory = function () {
  let self = this;

  self.ws.emit(
    C.PLAY_BLACKJACK,
    encode({
      command: "history",
      history: self.history,
    })
  );
};

/** Start Game Engine **/
export default Engine;
