import React, {useState} from "react";
import { Modal } from "react-bootstrap";

function Rules(props) {
    const [show, setShow] = useState(false);
  
    let css = 'zoomIn';

    const handleShow = () => setShow(true);

    const handleClose = () => {
        css = 'zoomOut';
        setShow(false)
    };

    const { t } = props;
    return (
        <>
            <button className={"btn btn-xs hvi chat-room-rule-btn"} onClick={handleShow}>
                <img className="img-fluid" src="/assets/images/icons/chat_rule_icon.svg" />
            </button>
            <Modal 
            	show={show} 
            	onHide={handleClose}
            	centered={true}
            	backdrop={"static"} 
            	className={"animated " + css}>
                <Modal.Header>
                    <div className="headerinside">{t("chat_rules")}</div>
                    <button type="button" className="close p-2" onClick={handleClose}>
                        <i className={"mdi mdi-close"} />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ul className="font-15 p-0 text-white chatinfo" style={{ lineHeight: "30px" }}>
                        <li><span className="text-warning">1.</span> {t("r2")}</li>
                        <li><span className="text-warning">2.</span> {t("r3")}</li>
                        <li><span className="text-warning">3.</span> {t("r4")}</li>
                        <li><span className="text-warning">4.</span> {t("r5")}</li>
                        <li><span className="text-warning">5.</span> Don't behave suspiciously, your actions can be seen fraudulent.</li>
                        <li><span className="text-warning">6.</span> Don't use your additional accounts in chat - strictly forbidden.</li>
                        <li><span className="text-warning">7.</span> Discussions about Twitch streamers is forbidden.</li>
                        <li><span className="text-warning">8.</span> It is forbidden to distribute software codes or bots.</li>
                        <li><span className="text-warning">9.</span> Use only that language, which is intended for this chat.</li>
                        <li><span className="text-warning">10.</span> Avoid discussion of politics and religion in the chat.</li>
                        <li><span className="text-warning">11.</span> Support team is ready at tags: <i className="text-warning">@moderator @support</i></li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Rules;
