import React, { Component } from "react";
import { Link } from "react-router-dom";

class SlotEnd extends Component {
    componentDidMount() {

    }
    render() {
        return (
            <>
                <body className="py-5 ovh bodyslots">
                    <div className="py-3 text-center">
                        <div className="container d-flex flex-column">
                            <div className="row my-auto">
                                <div className="col-md-12 text-white">
                                    <h2 className="mb-4 py-5">
                                        Not available in your region
                                    </h2>
                                    {/* <Link to="/slots"> */}
                                        <button type="button" class="btn affilateterms btn-lg">Play Other Games</button>
                                    {/* </Link> */}

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </body>
            </>
        );
    }
}

export default SlotEnd;
