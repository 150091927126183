import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Event, isMobile, wait } from "../../../../Helper";
import Deposit from "./Deposit";
import Withdrawl from "./Withdrawl";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      wallet: null,
      headerText: "",
      padding: "",
      paddingHeader: "",
      backFrom: null,
    };
    this.handleWallet = this.handleWallet.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.showArchive = this.showArchive.bind(this);
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ padding: "p-1", paddingHeader: "px-2" });
    }

    Event.on("deposit_archive_back", () => {
      this.setState({ headerText: "deposit_history", backFrom: null });
    });

    Event.on("withdraw_archive_back", () => {
      this.setState({ headerText: "withdraw_archive", backFrom: null });
    });

    Event.on("e_sevice_deposit", () => {
      this.handleWallet("deposit");
    });

    Event.on("e_sevice_withdraw", () => {
      this.handleWallet("withdraw");
    });

    Event.on("deposit_close", () => {
      this.handleClose();
    });

    Event.on("deposit_open", () => {
      this.handleWallet("deposit");
      wait(800).then(() => {
        Event.emit("force_show_cards");
      });
    });
  }

  handleWallet(wallet) {
    let text = wallet === "deposit" ? "deposit_history" : "withdraw_archive";
    this.setState({ show: true, wallet: wallet, headerText: text });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  showArchive() {
    this.setState({ headerText: "back" });
    if (this.state.wallet === "deposit") {
      Event.emit("deposit_archive");
    } else if (this.state.wallet === "withdraw") {
      Event.emit("withdraw_archive");
    }
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <button
          onClick={(e) => this.handleWallet("deposit")}
          className={"dropdown-item"}
        >
          {/* <i className="mdi mdi-briefcase-outline text-muted mr-2 text-drop" /> */}
          <svg className="mr-2 text-drop" fill="#7a88af" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px"  viewBox="0 0 461 461" stroke="#7a88af" strokeWidth="30">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
            <g> 
              <path d="M448.5,99.662h-0.015l-110.11,0.124v-47.49c0-3.315-1.317-6.495-3.661-8.839l-17.032-17.03 c-2.344-2.345-5.521-3.661-8.838-3.661H152.157c-3.315,0-6.494,1.316-8.839,3.66l-17.031,17.031 c-2.345,2.344-3.662,5.523-3.662,8.839V99.8H12.5C5.597,99.8,0,105.397,0,112.3v313.374c0,6.904,5.596,12.5,12.498,12.5l436,0.061 h0.002c3.314,0,6.494-1.316,8.838-3.66s3.662-5.523,3.662-8.84V112.162c0-3.317-1.319-6.499-3.666-8.844 C454.99,100.977,451.812,99.662,448.5,99.662z M157.336,47.766h146.33l9.709,9.708v42.404h-165.75V57.474L157.336,47.766z M436,413.232l-411-0.057V124.8h86.133c0.447,0.049,0.901,0.077,1.361,0.077h236.012c0.522,0,1.035-0.042,1.541-0.104L436,124.675 V413.232L436,413.232z"></path> 
              </g> 
              </g>
            </svg>
          {t("deposit")}
        </button>
        <button
          onClick={(e) => this.handleWallet("withdraw")}
          className={"dropdown-item"}
        >
          {/* <i className="dripicons-wallet text-muted mr-2 text-drop" /> */}
          <svg className="mr-2 text-drop" fill="#7a88af" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" stroke="#7a88af" strokeWidth="0.52">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.8160000000000001"></g>
            <g id="SVGRepo_iconCarrier"> <metadata> <sfw xmlns="&amp;ns_sfw;"> <slices> </slices> <slicesourcebounds width="505" height="984" bottomleftorigin="true" x="0" y="-552"> </slicesourcebounds> </sfw> </metadata> <g> <g> <g> <path d="M3,8C1.3,8,0,6.7,0,5s1.3-3,3-3c0.6,0,1,0.4,1,1S3.6,4,3,4C2.4,4,2,4.4,2,5s0.4,1,1,1c0.6,0,1,0.4,1,1S3.6,8,3,8z"></path> </g> </g> <g> <g> <path d="M3,22c-1.7,0-3-1.3-3-3c0-0.6,0.4-1,1-1s1,0.4,1,1c0,0.6,0.4,1,1,1c0.6,0,1,0.4,1,1S3.6,22,3,22z"></path> </g> </g> <g> <g> <path d="M23,22H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h19V8H3C2.4,8,2,7.6,2,7s0.4-1,1-1h20c0.6,0,1,0.4,1,1v14C24,21.6,23.6,22,23,22z"></path> </g> </g> <g> <g> <path d="M21,8c-0.6,0-1-0.4-1-1V4H3C2.4,4,2,3.6,2,3s0.4-1,1-1h18c0.6,0,1,0.4,1,1v4C22,7.6,21.6,8,21,8z"></path> </g> </g> <g> <g> <path d="M1,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C2,19.6,1.6,20,1,20z"></path> </g> </g> <g> <g> <circle cx="18" cy="14" r="2"></circle> </g> </g> </g> </g>
          </svg>
          {t("withdraw")}
        </button>
        <Modal
          size={"lg"}
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-md-modal"
          className="wallet-modal-wrapper"
        >
          <Modal.Header className={this.state.paddingHeader}>
            <Row xs={1} md={2} style={{ width: "100%" }}>
              <Col xs={6} md={6}>
                <button
                  onClick={this.showArchive}
                  className={"btn bg-cs2 bg-cs5 btn-xs text-white"}
                >
                  {" "}
                  {t(this.state.headerText)}
                </button>
              </Col>
            </Row>
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body
            className={this.state.padding + " wallet-modal buttonswallet"}
          >
            {this.state.wallet === "deposit" && <Deposit t={t} />}
            {this.state.wallet === "withdraw" && <Withdrawl t={t} handleClose={this.handleClose}/>}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Main;
