import React from "react";
import { Row, Col, Card, Dropdown, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";
import Chart from "./Chart";
import ChartAm from "./Chart_amc";
import Loading from "../Loading";
import { coinName, __, Event, wait } from "../../../Helper";
import coins from "../../coins";
import storage from "../../../Storage";
import { setCoin } from "../../../actions/gameCoin";

import "../../../Static/css/chart.css";

class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      times: [],
      coin: this.props.coin,
      high: 0,
      low: 0,
      price: 0,
      gid: null,
      display: "none",
      loading: true,
      currentChart: 1,
    };
    this.handleMouse = this.handleMouse.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ display: "block", loading: false });
    }, 2000);

    let options = {
      dataPoints: 10, // Number of data points to generate
      interval: 100, // Number of ms between each data point
      initialValue: 68000.12, // Initial data value
      volatility: 0.001, // Maximum percent change that can occur
    };

    var result = Chart.generateSampleData(options);
    let chart = new Chart("#chart", result, this.state.mouse);

    this.setState({ chart });

    Event.on("nexPrice", (data) => this.nexPrice(data));
    Event.on("play_trading", (data) => this.play(data));
    Event.on("cashout_trading", (data) => this.cashout(data));

    document.addEventListener("mousemove", this.handleMouse);
  }

  componentWillUnmount() {
    this.state.chart.destroy();
    document.removeEventListener("mousemove", this.handleMouse);
  }

  play(data) {
    if (__.isUndefined(data.gid)) return;

    this.createTimer(data.time, data.gid);
  }

  cashout(data) {
    if (__.isUndefined(data.gid)) return;

    $("#timer_" + data.gid).removeClass("badge-primary");

    if (data.win === 1) {
      $("#timer_" + data.gid).addClass("badge-success");
    } else if (data.win === 0) {
      $("#timer_" + data.gid).addClass("badge-danger");
    }

    this.state.chart.cashout(data.name);
  }

  handleMouse(e) {
    this.state.chart.getMouse([e.clientX, e.clientY]);
  }

  nexPrice(value) {
    if (value[0] !== this.state.close) {
      this.setPrice(value);
      this.state.chart.update(value);
    }
  }

  setPrice(price) {
    this.setState((state) => ({
      close: price[0],
      high: _.max(price).toFixed(4),
      low: _.min(price).toFixed(4),
      nexPrice: price,
    }));
  }

  createTimer(time, gid) {
    this.setState((state) => ({
      times: [<Timer key={gid} time={time} gid={gid} />, ...state.times],
    }));
  }

  toggleChart() {
    this.setState((state) => ({
      currentChart: state.currentChart === 1 ? 0 : 1,
    }));

    this.props.engine.getRates();
  }

  render() {
    const times = this.state.times;
    return (
      <>
        <>
          <div className="top-price-bar">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="8" sm="12">
                    <div className="media">
                      <img
                        src={
                          "/assets/images/" +
                          __.toLower(this.props.coin) +
                          ".png"
                        }
                        className="mr-3 thumb-sm align-self-center rounded-circle"
                        alt="Coin"
                      />
                      <div className="media-body align-self-center text-left">
                        <div className="coin-bal">
                          <h5 className="m-0 font-20 text-white">
                            <span className="rate" data-currency="x"></span>
                            <ReactTooltip />
                            <button
                              type="button"
                              data-tip="Change Chart"
                              data-place="right"
                              className="btn btn-soft-pink btn-round btn-xs waves-effect waves-light py-0 ml-2"
                              onClick={() => this.toggleChart()}
                            >
                              <i className="mdi mdi-chart-areaspline"></i>
                            </button>
                          </h5>
                          <span className="text-success">
                            {coinName(this.props.coin)}  
                          </span>
                          <div className="text-center fw btn-group">
                            {times}
                          </div>
                          {/* {this.props.tokens} */}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="2" sm="6" className="roundedpricediv">
                    <p className="mb-0 p-2 bg-soft-success rounded rounded-price">
                      <b>High: </b>
                      {this.state.high}
                    </p>
                  </Col>
                  <Col md="2" sm="6" className="roundedpricediv">
                    <p className="mb-0 p-2 bg-soft-danger rounded rounded-price">
                      <b>Low: </b>
                      {this.state.low}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="panel">
            <div className="exchange-rate">
              <div className="conversion-selector">{this.props.price} USD</div>
            </div>
            <div className="text-center animated fadeIn">
              <div
                style={{
                  display: this.state.currentChart === 1 ? "block" : "none",
                }}
              >
                <div id="tooltips" />
                <div id="chart" />
              </div>

              {this.state.currentChart === 0 && (
                <ChartAm price={this.state.nexPrice} />
              )}
            </div>
          </div>
        </>
      </>
    );
  }
}

class Canvas extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      coin: "BTC",
      started: false,
      data: {},
      name: null,
      timers: null,
      gid: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      let { engine } = this.props;

      engine.coin = this.state.coin;
      engine.getRates();

      // Game Events
      engine.on("play", (data) => this.play(data));
      engine.on("cashout", (data) => this.cashout(data));
      engine.on("get_rates", async (data) => await this.setRates(data));

      this.state.tick = setInterval(() => {
        engine.coin = this.state.coin;
        engine.getRates();
      }, 10000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.tick);
  }

  play(data) {
    Event.emit("play_trading", data);
  }

  cashout(data) {
    Event.emit("cashout_trading", data);
  }

  changeToken(coin) {
    // Event.emit("force_change_coin", coin);
    this.setState({ coin });
    let { engine } = this.props;
    engine.coin = coin;
    engine.getRates();
  }

  async setRates(data) {
    this.setState({ started: true });

    Event.emit("nexPrice", data.price);
  }

  render() {
    const tokens = (
      <div className="-left -group fw">
        <Dropdown>
          <Dropdown.Toggle split variant="" className={"p-0 mt-0"}>
            <span className="text-white text-center">
              <i className={"mdi mdi-chevron-down mr-1"} />
              {this.state.coin}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={"dopdown-menu-bottom"}>
            {coins.map((coin, i) => (
              <Dropdown.Item
                onClick={(e) => this.changeToken(coin.preffix)}
                className={"animated fadeIn"}
              >
                <img
                  src={"/assets/images/" + __.toLower(coin.preffix) + ".png"}
                  className="mini-coin-5 align-self-center mr-2"
                  alt="Coin"
                />
                {coin.preffix}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );

    return (
      <>
        {this.state.started ? (
          <ChartComponent
            tokens={tokens}
            engine={this.props.engine}
            coin={this.state.coin}
          ></ChartComponent>
        ) : (
          <>
            <Loading messages={"Please Wait..."} classList="l-3" />
          </>
        )}
      </>
    );
  }
}

function Timer(props) {
  let { time, gid } = props;

  const [timer, setTimer] = React.useState(time);

  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    let progressTime = setInterval(() => {
      --time;
      if (time <= 0) {
        clearInterval(progressTime);
        wait(5000).then(() => {
          setShow(false);
        });
        time = 0;
      }
      setTimer(time);
    }, 1000);

    return () => clearInterval(progressTime);
  }, [time]);

  return (
    <>
      {show && (
        <span
          key={gid}
          id={"timer_" + gid}
          className={"badge badge-primary mr-1"}
        >
          <i className="mdi mdi-clock font-12 align-bottom mr-1" />
          <b>{timer}s</b>
        </span>
      )}
    </>
  );
}

Canvas.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { setCoin })(Canvas);
