import React from "react";
import { Modal, Row, Col, Card, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import socket from "../../../Socket";
import C from "../../../Constant";
import {
  __,
  decode,
  removeTrailingZeros,
  sendNotfication,
  Event,
  getUID,
  encode,
  logged,
} from "../../../Helper";
import storage from "../../../Storage";
import Contestinfo from "./Contestinfo";

export default class Contest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: storage.getKey("logged") ? storage.getKey("name") : "Guest",
      avatar: storage.getKey("logged")
        ? storage.getKey("avatar")
        : "https://app.betsmixer.com/avatar/Avatar_2.svg",
      amount: 0,
      position: 0,
      wager_up_amount: 0,
      wager_up_position: 0,
      contest_prize_pool: 0,
      contest_rank: [],
      contest_prize_percent: [],
      hours: 0,
      minutes: 0,
      seconds: 0,
      last_winner_name: "",
      last_winner_avatar: "https://app.betsmixer.com/avatar/Avatar_1.svg",
      coin: this.props.coin
        ? this.props.coin
        : storage.getKey("coin") !== null
        ? storage.getKey("coin")
        : "USDT",
    };
  }

  componentWillMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  componentDidMount() {
    socket.emit(C.GET_CONTEST);
    socket.on(C.GET_CONTEST, (data) => {
      if (storage.getKey("logged")) {
        this.setState({
          amount: decode(data).user_rank.total_amount,
          position: decode(data).user_rank.rank,
          wager_up_amount: decode(data).wagered_up_amount,
          wager_up_position: decode(data).wagered_up_position,
          contest_prize_pool: decode(data).contest_prize_pool,
          contest_rank: decode(data).contest_rank,
          contest_prize_percent: decode(data).contest_prize_percent,
          hours: decode(data).hours,
          minutes: decode(data).minutes,
          seconds: decode(data).seconds,
          last_winner_name: decode(data).last_winner_name,
          last_winner_avatar: decode(data).last_winner_avatar,
          last_winner_id: decode(data).last_winner_id,
        });
      } else {
        this.setState({
          contest_prize_pool: decode(data).contest_prize_pool,
          contest_rank: decode(data).contest_rank,
          contest_prize_percent: decode(data).contest_prize_percent,
          hours: decode(data).hours,
          minutes: decode(data).minutes,
          seconds: decode(data).seconds,
          last_winner_name: decode(data).last_winner_name,
          last_winner_avatar: decode(data).last_winner_avatar,
          last_winner_id: decode(data).last_winner_id,
        });
      }
      const intervalId = setInterval(() => {
        let t =
          this.state.hours * 3600 +
          this.state.minutes * 60 +
          this.state.seconds -
          1;
        if (t <= 0) {
          socket.emit(C.GET_CONTEST);
          clearInterval(intervalId);
        } else this.updateTime();
      }, 1000);
    });
  }
  updateTime() {
    let t =
      this.state.hours * 3600 +
      this.state.minutes * 60 +
      this.state.seconds -
      1;
    this.setState({
      hours: Math.floor(t / 3600),
      minutes: Math.floor((t % 3600) / 60),
      seconds: (t % 3600) % 60,
    });
  }
  componentWillUnmount() {
    socket.off(C.START_CONTEST);
    socket.off(C.GET_CONTEST);
  }
  formatAmount(number) {
    number = removeTrailingZeros(number) * 1;
    if (number.toString().indexOf(".") === -1) {
      return number.toLocaleString();
    }
    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    // const formattedNumber = number.toLocaleString(undefined, { minimumFractionDigits: number.toString().split('.')[1].length, maximumFractionDigits: number.toString().split('.')[1].length });
    return removeTrailingZeros(formattedNumber.replace(",", ""));
  }
  getDetail = (id) => {
    Event.emit("single_game_modal_close");
    Event.emit("close_message_modal");
    socket.emit(
      C.USER_INFO,
      encode({
        id: id,
        coin: this.state.coin,
        rate: null,
        game: "all",
        first: true,
      })
    );
    Event.emit("force_modal_user");
  };
  render() {
    const { t } = this.props;
    const {
      last_winner_id,
      last_winner_avatar,
      last_winner_name,
      hours,
      minutes,
      seconds,
      contest_rank,
      amount,
      position,
      wager_up_amount,
      wager_up_position,
      contest_prize_pool,
      contest_prize_percent,
      getDetail,
    } = this.state;
    let topPlayer_list;
    if (Array.isArray(contest_rank)) {
      topPlayer_list = contest_rank.map((item, index) => (
        <TopPlayer
          key={index}
          uid={item.uid}
          rank={index + 1}
          amount={item.total_amount}
          prize={(contest_prize_pool * contest_prize_percent[index]) / 100}
          avatar={item.avatar}
          name={item.name}
          formatAmount={this.formatAmount}
        />
      ));
    }
    return (
      <>
        <Helmet>
          <title>Daily Contest - BetsMixer.com</title>
          <meta
            name="description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Best Crypto Casino.Biggest crypto crash game. Provably fair & Live dealer. Slot games.Unique bonus & free lucky spins. BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/daily-contest" />
        </Helmet>
        <div className="contestdiv">
          <div className="row contestpool">
            <div
              className="col-5 d-flex rounded m-2 p-3 align-items-center contestbox daily-prize-pool"
              style={{ backgroundColor: "#1a2033" }}
            >
              <img
                src="/assets/images/dailycontest/case_1.png"
                className="contestcase"
                style={{ width: "50%", height: "80%", marginRight: "2rem" }}
              />
              <div>
                <h4 className="text-warning dailycontesttop">{t("daily")}</h4>
                <span className="text-white dailycontestpool">
                  {t("contest_prize_pool")}
                </span>
                <h3
                  className="text-warning dailycontestprice"
                  style={{
                    backgroundColor: "rgb(18 24 43)",
                    padding: "0.5rem 2rem 0.5rem 1.5rem",
                  }}
                >
                  $ {contest_prize_pool}
                </h3>
              </div>
              <Contestinfo t={t} />
              <i></i>
            </div>
            <div
              className="col text-white text-center rounded m-2 d-flex align-items-center contestbox time-remain"
              style={{ backgroundColor: "#1a2033" }}
            >
              <div>
                <h6 className="mt-0 mb-4">{t("time_remaining")}</h6>
                <div className="d-flex text-white align-items-center justify-content-center contesttimer">
                  <div
                    className="px-4 rounded"
                    style={{ backgroundColor: "rgb(16 22 41)", opacity: 0.8 }}
                  >
                    <span style={{ fontSize: "9px" }}>{t("hours")}</span>
                    <h5>{hours}</h5>
                  </div>
                  <h2 className="px-2">:</h2>
                  <div
                    className="px-4 rounded"
                    style={{ backgroundColor: "rgb(16 22 41)", opacity: 0.8 }}
                  >
                    <span style={{ fontSize: "9px" }}>{t("minutes")}</span>
                    <h5>{minutes}</h5>
                  </div>
                  <h2 className="px-2">:</h2>
                  <div
                    className="px-4 rounded"
                    style={{ backgroundColor: "rgb(16 22 41)", opacity: 0.8 }}
                  >
                    <span style={{ fontSize: "9px" }}>{t("seconds")}</span>
                    <h5>{seconds}</h5>
                  </div>
                </div>
                <i></i>
              </div>
            </div>
            <div
              className="col text-white rounded m-2 d-flex align-items-center gap-2 justify-content-center contestwinner contestbox"
              style={{ backgroundColor: "#1a2033" }}
            >
              <div className="img-winner">
                <img src="/assets/images/dailycontest/winner.png" alt="" />
                <p>{t("winner")}</p>
              </div>
              <div>
                <h6 className="mt-0 mb-4">
                  <svg
                    version="1.1"
                    id="_x32_"
                    width="30px"
                    height="30px"
                    viewBox="0 0 512 512"
                  >
                    <g>
                      <path
                        className="st0"
                        d="M512,186.521c0-24.609-19.953-44.563-44.563-44.563c-24.625,0-44.563,19.953-44.563,44.563
        c0,15.984,8.438,29.969,21.094,37.828c-10.531,31.109-33.25,85.781-61.875,81.844c-36.438-4.984-44.641-63.969-30.938-167.641
        c16.156-6.016,28.031-21.188,28.969-39.5c1.25-24.594-17.656-45.531-42.219-46.781c-24.594-1.25-45.531,17.656-46.781,42.234
        c-0.828,16.266,7.203,30.906,19.844,39.328C304.344,239.396,285.688,287.24,256,287.24s-48.344-47.844-54.969-153.406
        c12.625-8.422,20.672-23.063,19.844-39.328c-1.25-24.578-22.188-43.484-46.781-42.234c-24.563,1.25-43.469,22.188-42.219,46.781
        c0.938,18.313,12.813,33.484,28.969,39.5c13.703,103.672,5.5,162.656-30.938,167.641c-28.625,3.938-51.344-50.734-61.891-81.844
        c12.672-7.859,21.109-21.844,21.109-37.828c0-24.609-19.938-44.563-44.563-44.563C19.953,141.959,0,161.912,0,186.521
        c0,20.156,13.406,37.172,31.75,42.672l54.531,230.594h161.188h17.063h161.188l54.531-230.594
        C498.594,223.693,512,206.678,512,186.521z"
                      />
                    </g>
                  </svg>
                  {t("last_winner")}
                </h6>

                <div
                  type="button"
                  className="winnerlast"
                  onClick={() => this.getDetail(last_winner_name)}
                >
                  <div className="d-flex justify-content-center align-items-center bets-avatar-wrapper">
                    <img
                      className="rounded thumb-xs winneravatar"
                      alt="user_avatar"
                      src={last_winner_avatar}
                    />
                  </div>
                  <p className="mt-2">@{last_winner_name}</p>
                </div>
              </div>
              <i></i>
            </div>
          </div>

          <div
            className="row rounded my-2 mx-1 contestbar"
            style={{ backgroundColor: "#1a2033" }}
          >
            <div className="col">
              <div
                type="button"
                className="d-flex justify-content-center align-items-center bets-avatar-wrapper"
                onClick={() => this.getDetail(storage.getKey("name"))}
              >
                <img
                  className="rounded thumb-xs"
                  alt="user_avatar"
                  src={this.state.avatar}
                />
                <p className="mt-2 contestuser">@{this.state.name}</p>
              </div>
            </div>
            <div className="col colborder">
              {t("my_position")}&nbsp;
              <span className="text-warning">
                {position == 1
                  ? "1st"
                  : position == 2
                  ? "2nd"
                  : position == 3
                  ? "3rd"
                  : position > 50
                  ? "50th+"
                  : position + "th"}
              </span>
            </div>
            <div className="col colborder">
              {t("wagered")}&nbsp;
              <span className="text-warning">
                {" "}
                $
                {typeof amount == "string"
                  ? parseFloat(amount).toFixed(4)
                  : amount.toFixed(4)}
              </span>
            </div>
            <div className="col wager-up colborder">
              {t("wager_up")}
              <span className="text-warning">
                {" "}
                $
                {position > 10
                  ? typeof wager_up_amount == "string"
                    ? parseFloat(wager_up_amount).toFixed(4)
                    : wager_up_amount.toFixed(4)
                  : "0.00"}{" "}
              </span>
              <span className="topten">
                <svg width="30px" height="30px" viewBox="0 0 16 16" fill="none">
                  <path
                    fill="#12182b"
                    d="M8 1.25a2.101 2.101 0 00-1.785.996l.64.392-.642-.388-5.675 9.373-.006.01a2.065 2.065 0 00.751 2.832c.314.183.67.281 1.034.285h11.366a2.101 2.101 0 001.791-1.045 2.064 2.064 0 00-.006-2.072L9.788 2.25l-.003-.004A2.084 2.084 0 008 1.25z"
                  />
                </svg>
                {t("top")} 10
              </span>
            </div>
          </div>
          <Card
            className="text-white my-2 wager-table-wrapper"
            style={{ backgroundColor: "#1a2033" }}
          >
            <Card.Body>
              <Row>
                <Col sm={12} className="p-0">
                  <div className="table-responsive num-style mb-0 img-fix">
                    <div
                      className={
                        "table-responsive top-winner-table num-style mb-0 tww"
                      }
                    >
                      <Table className={"mb-2"}>
                        <thead>
                          <tr>
                            {!this.state.mobile && <th>{t("Rank")}</th>}
                            <th>{t("Player")}</th>
                            <th>{t("wagered")}</th>
                            <th>{t("prize")}</th>
                          </tr>
                        </thead>
                        <tbody>{topPlayer_list}</tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

class TopPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coin: this.props.coin
        ? this.props.coin
        : storage.getKey("coin") !== null
        ? storage.getKey("coin")
        : "USDT",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}
  showPlayerProfile(uid) {
    let id = uid;
    Event.emit("single_game_modal_close");
    Event.emit("close_message_modal");
    socket.emit(
      C.USER_INFO,
      encode({
        id: id,
        coin: this.state.coin,
        rate: null,
        game: "all",
        first: true,
      })
    );
    Event.emit("force_modal_user");
  }
  render() {
    const { name, amount, avatar, prize, rank, formatAmount, uid } = this.props;
    return (
      <tr className="q-crash2">
        <td>
          <div className="d-flex h-100 winner-badge-wrapper">
            <div className="deco-bar-first"></div>
            <div className="d-flex align-items-center winner-badge">
              {rank < 4 ? (
                <img
                  className="img-fluid rounded-circle ffi"
                  src={"/assets/images/badges/badge-" + rank + ".svg"}
                />
              ) : (
                <></>
              )}
              <span>{rank}</span>
            </div>
          </div>
        </td>
        <td>
          <div
            type="button"
            className="d-flex align-items-center gap-2 winnerwrapper"
            onClick={() => this.showPlayerProfile(uid)}
          >
            <div className="d-flex justify-content-center align-items-center winner-avatar-wrapper">
              <img
                className="rounded thumb-xs"
                alt="user_avatar"
                src={avatar}
              />
            </div>
            <p className="mt-2">{name}</p>
          </div>
        </td>
        <td className="num-style text-success">
          $
          {typeof amount == "string"
            ? parseFloat(amount).toFixed(4)
            : amount.toFixed(4)}
        </td>
        <td className="num-style text-success">
          $
          {typeof prize == "string"
            ? parseFloat(prize).toFixed(2)
            : prize.toFixed(2)}
        </td>
      </tr>
    );
  }
}
