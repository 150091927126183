import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    slot: {
        width: 30,
        marginRight: 5
    }
});

export default function VerifyResult(){
    const classes = useStyles();
    return ""
}
