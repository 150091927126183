import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Canvas from "./Canvas";
import History from "./History";
import Bet from "./Bet";
import Bets from "../../Pages/Parts/Home/Bets";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import { wait, Event, encode } from "../../../Helper";
import socket, { disconnect } from "../../../Socket";
import Help from "./Help";
import Engine from "./Engine";
import Loading from "../Loading";
import C from "../../../Constant";
import HouseEdge from "../../Components/Game/Addons/TopBar/houseEdge";
import Rakeback from "../../Pages/Parts/Home/Rakeback";

class Game extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: new Engine(),
      mobile: false,
      padding: "p-1",
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (this._Mounted) {
      let { engine } = this.state;
      socket.emit("keno");

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      socket.emit(
        C.UPDATERECENT,
        encode({ gid: this.props.gid, sort: "home" })
      );

      // Start Engine
      engine.started = true;

      engine.listen();

      wait(600).then(() => {
        this._Mounted = true;
        engine.getStatus();
        this.handleResize();
      });
      window.addEventListener("resize", this.handleResize);

      if (this.isMobile()) {
        this.setState({ height: "unset", mobile: true });
      } else {
        this.setState({ height: this.getHeight(), mobile: false });
      }
    }
  }

  componentWillMount() {
    this._Mounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this._Mounted = false;

    let { engine } = this.state;

    // Stop Engine
    engine.started = false;
    engine.trigger = null;
    engine.off();
    disconnect("keno");
  }

  handleResize() {
    if (this.getWidth() < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (this.isMobile()) {
      this.setState({ mobile: true, ovh: "ovh", padding: "p-0" });
    } else {
      this.setState({ mobile: false });
    }
  }

  getHeight() {
    return document.documentElement.clientHeight || document.body.clientHeight;
  }

  getWidth() {
    return document.documentElement.clientWidth || document.body.clientWidth;
  }

  isMobile() {
    return this.getWidth() < 992;
  }

  render() {
    let { engine, col, ovh, padding } = this.state;
    const { t, gid } = this.props;
    return (
      <>
        <Helmet>
          <title>Keno | Crypto Casino Games</title>
          <meta
            name="description"
            content="Play Online Keno - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:title"
            content="Play Online Keno - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <meta
            name="og:description"
            content="Play Online Keno - Best crypto casino Games, Bitcoin Gambling, sportbooks, live slots & offerwall. Provably fair crypto casino & slots. Play with BTC, USDT, ETH, LTC, BCH & DOGE"
          />
          <link rel="canonical" href="/keno" />
        </Helmet>
        <div>
          {this._Mounted ? (
            <Row className={"animated fadeIn " + ovh}>
              {/* <Col sm={12} className={'m-auto ' + col + ' ' + padding}> */}
              <Col className={"m-auto inhousegame"}>
                <Card className="mb-0 inhousemenu">
                  <Card.Body className="p-0">
                    <Row className="game-wrapper-header align-items-center m-0">
                      <Col md={7} className={"col-7 text-left"}>
                        <p className="mt-2 mb-2 text-white text-upper d-flex">
                          <span className="badge bg-soft-secondary p-2 font-12">
                            Multiplayer Keno
                          </span>
                          <span className="badge bg-soft-success p-2 font-12 ml-2">
                            In-House
                          </span>
                          <span className="badge bg-soft-danger p-2 font-12 ml-2">
                            House Edge: <HouseEdge game={"keno"} />%
                          </span>
                        </p>
                        <BankRoll game={"keno"} />
                      </Col>
                      <Col md={5} className={"col-5 text-right inhousebuttons"}>
                        <History engine={engine} />
                        <Help t={t} gid={gid} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Canvas engine={engine} />
                <Card className="mb-0">
                  <Card.Body className="p-1">
                    <Row>
                      <Col
                        sm={12}
                        md={12}
                        className={"text-left m-auto kenogame"}
                      >
                        <Bet engine={engine} mobile={this.state.mobile} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-0 lastbetscard">
                  <Card.Body className="p-1 mt-4 game-last-bets">
                    <div className="p-2 mt-3 home-last-bets-wrapper">
                      <Bets t={t} />
                    </div>
                  </Card.Body>
                  <Rakeback t={t} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  }
}

export default Game;
