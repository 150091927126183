import * as PIXI from "pixi.js";
import Effect from "./components/Effect";
import Loader from "../Loader";
import { __, wait, Event } from "../../../Helper";

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

const ASSETS_PATH = "/assets/images/goal";

class Canvas {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 280,
      height: 310,
      resolution: 1,
      backgroundAlpha: false,
      view: document.getElementById("game"),
    });
    this.engine = engine;
    this.started = false;
    this.res = null;
    this.centerX = this.app.screen.width / 2;
    this.centerY = this.app.screen.height / 2;
    this.loader = new Loader("goal", this.app);
    this.lands = [];
    this.container = null;
    this.round = 0;
    this.payouts = [1, 2, 3, 4];
  }

  init() {
    this.loader.add([
      ASSETS_PATH + "/land.png",
      ASSETS_PATH + "/land_active.png",
      ASSETS_PATH + "/ball.png",
    ]);
    this.app.loader.onProgress.add((loader, res) =>
      this.loader.show(loader.progress, res)
    );
    this.app.loader.onComplete.add((loader, res) => this.load(loader, res));
    this.app.loader.load();
  }

  load(loader, res) {
    this.engine.emit("ready");
    this.res = res;
    if (this.app === null) return;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.app.stage.sortableChildren = true;
    this.app.stage.addChild(this.container);

    this.configAnim();
    this.configLands();
    this.configPayouts();
  }

  configAnim() {
    let count = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        count += 0.05;

        let round = this.round * 4;

        for (var i = 0 ; i < round; i++) {
          let land = this.lands[i];
          land.texture = PIXI.Texture.from(ASSETS_PATH + "/land_active.png");
          land.interactive = true;
          land.buttonMode = true;
          land.on("click", () => this.onClick(land));
          land.on("touchstart", () => this.onClick(land));
        }

        if (this.round === 2) {
          for (var i = 0; i < 4; i++) {
            let land = this.lands[i];
            land.texture = PIXI.Texture.from(ASSETS_PATH + "/land.png");
            land.interactive = false;
            land.buttonMode = false;
          }
        }

        if (this.round === 3) {
          for (var i = 0; i < 8; i++) {
            let land = this.lands[i];
            land.texture = PIXI.Texture.from(ASSETS_PATH + "/land.png");
            land.interactive = false;
            land.buttonMode = false;
          }
        }

        if (this.round === 4) {
          for (var i = 0; i < 12; i++) {
            let land = this.lands[i];
            land.texture = PIXI.Texture.from(ASSETS_PATH + "/land.png");
            land.interactive = false;
            land.buttonMode = false;
          }
        }
      }
    });
  }

  configPayouts() {
    this.payout = new PIXI.Container();
    this.container.addChild(this.payout);
    for (var i = 0; i < this.payouts.length; i++) {
      let payout = this.payouts[i];
      var graphics = new PIXI.Graphics();
      graphics.lineStyle(0);
      graphics.beginFill(0xffffff, 1);
      graphics.drawRect(i * 80, 285, 68, 68, 5);
      graphics.endFill();
      graphics.width = graphics.height = 68;
      graphics.x = i * 68;
      graphics.y = 285;
      var text = Effect.Text(payout.toFixed(2) + "x", {
        size: 12,
        color: 0xffffff,
      });
      text.x = 20 + i;
      graphics.addChild(text);
      this.payout.addChild(graphics);
    }
  }

  configLands() {
    let id = 0;
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        var land = new PIXI.Sprite(
          PIXI.Texture.from(ASSETS_PATH + "/land.png")
        );
        land.x = j * 70;
        land.y = i * 70;
        land.interactive = false;
        land.buttonMode = false;
        land.id = id;
        land.started = this.started;
        land.engine = this.engine;
        Effect.FadeIn(land, i * 0.5);
        this.lands[id] = land;
        this.container.addChild(land);
        id++;
      }
    }
  }

  onClick(land) {
    if (!this.started) return;
    if (land.clicked) return;
    var ball = new PIXI.Sprite(PIXI.Texture.from(ASSETS_PATH + "/ball.png"));
    ball.x = land.width / 4.5;
    ball.y = land.height / 4.5;
    Effect.ZoomIn(ball, 1.1, 0.4);
    land.addChild(ball);
    land.clicked = true;
    this.engine.land = parseInt(land.id);
    this.engine.clicked();
  }

  play() {
    var r = this.round + 1;
    var max = Math.min(4, r);
    this.round = max;
    this.started = true;
  }

  changeRisk(risk) {
    if (risk === "high") {
      this.payouts = [3, 7, 9, 12];
    } else if (risk === "medium") {
      this.payouts = [2, 4, 6, 8];
    } else if (risk === "low") {
      this.payouts = [1, 2, 3, 4];
    }
    this.container.removeChild(this.payout);
    this.configPayouts();
  }

  nextRound() {
    var r = this.round + 1;
    var max = Math.min(4, r);
    this.round = max;
    this.started = true;
  }

  busted(mines) {
    this.round = 0;
    this.started = false;
  }

  reset() {
    for (var i = 0; i < 16; i++) {
      let land = this.lands[i];
      land.texture = PIXI.Texture.from(ASSETS_PATH + "/land.png");
      land.clicked = false;
      land.interactive = false;
      land.buttonMode = false;
      land.removeChildren();
    }
  }

  destroy() {
    this.app = null;
    if (this.container !== null) this.container.destroy();
  }
}

export default Canvas;
