import * as PIXI from "pixi.js";
import * as Filter from "pixi-filters";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import Particle from "./Particle";

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/*
 * Register Pixi Plugin for GSAP
 */
gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const Effect = {};

/**
 *  Add a Text
 */
Effect.Text = function (str, config) {
  let bold = config.bold ? 700 : 300;
  let style = new PIXI.TextStyle({
    fontFamily: config.font ? config.font : "Tahoma",
    fontSize: config.size,
    fontWeight: bold,
    fill: [config.color],
  });
  return new PIXI.Text(str, style);
};

/**
 *  Light On/Off Object
 */
Effect.Light = function (object, time) {
  let stop = false;

  const start = () => {
    if (stop) return;
    gsap.to(object, {
      duration: 0.5,
      pixi: { brightness: 3, combineCMF: true },
    });
    wait(500).then(() => {
      min();
    });
  };

  const min = () => {
    gsap.to(object, {
      duration: 0.5,
      pixi: { brightness: 1, combineCMF: true },
    });
    wait(500).then(() => {
      start();
    });
  };

  start();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 *  Heartbeat Animate Object
 */
Effect.HeartBeat = function (object, time) {
  let stop = false;

  const start = () => {
    if (stop) return;
    gsap.to(object.scale, {
      x: object.scale.x + 0.02,
      y: object.scale.y + 0.02,
    });
    wait(500).then(() => {
      min();
    });
  };

  const min = () => {
    gsap.to(object.scale, {
      x: object.scale.x - 0.02,
      y: object.scale.y - 0.02,
    });
    wait(500).then(() => {
      start();
    });
  };

  start();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 * Animate Flash object
 */
Effect.Flash = function (object, time) {
  let stop = false;

  const up = () => {
    gsap.to(object, { duration: 1, pixi: { alpha: 1 } });
    wait(700).then(() => {
      down();
    });
  };
  const down = () => {
    if (stop) return;
    gsap.to(object, { duration: 1, pixi: { alpha: 0.1 } });
    wait(700).then(() => {
      up();
    });
  };

  up();

  wait(time * 1000).then(() => {
    stop = true;
  });
};

/**
 * Animate Zoom In Object
 */
Effect.ZoomIn = function (object, s, d) {
  object.scale.set(0);
  gsap.to(object, { duration: d, pixi: { scale: s } });
};

/**
 * Animate Fade In Object
 */
Effect.FadeIn = function (object, d) {
  object.alpha = 0;
  gsap.to(object, { duration: d, pixi: { alpha: 1 } });
};

/**
 * Animate Fade Out Object
 */
Effect.FadeOut = function (object, d) {
  object.alpha = 1;
  gsap.to(object, { duration: d, pixi: { alpha: 0 } });
};

/**
 * Animate Rotate  Object
 */
Effect.Angle = function (object, value) {
  gsap.to(object, { duration: 0.5, pixi: { angle: value } });
};

/**
 * Animate Scale  Object
 */
Effect.Scale = function (object, value) {
  gsap.to(object, { duration: 0.3, pixi: { scale: value } });

  wait(200).then(() => {
    gsap.to(object, { duration: 0.3, pixi: { scale: 1 } });
  });
};

/**
 * Animate Move In Object
 */
Effect.MoveIn = function (object, x, y) {
  if (y) {
    gsap.to(object, { duration: 0.6, pixi: { x: x, y: y } });
  } else {
    gsap.to(object, { duration: 0.6, pixi: { x: x } });
  }
};

/**
 * Add Filter to Object
 ** Etc: KawaseBlurFilter, GlowFilter, DropShadowFilter, BulgePinchFilter, BloomFilter , AsciiFilter, AdvancedBloomFilter , OutlineFilter, OldFilmFilter, SimpleLightmapFilter
 */
Effect.Filter = function (object, filter) {
  const f = new Filter[filter]();
  object.filters = [f];
};

/**
 * Clear Filter
 */
Effect.Clear = function (object) {
  object.filters = [];
};

/**
 *  Modal
 */
Effect.Modal = function (object, str) {
  var sprite = new PIXI.Sprite.from("static/images/popup.png");
  sprite.alpha = 0;
  sprite.width = 200;
  sprite.height = 150;
  sprite.scale = 0.5;
  sprite.x = 100;
  sprite.y = 50;
  let style = new PIXI.TextStyle({
    fontFamily: "Inter",
    fontSize: 25,
    fontWeight: 700,
    fill: [0x000000],
  });
  let text = new PIXI.Text(str, style);
  text.y = 90;
  text.x = 110;
  sprite.addChild(text);
  object.addChild(sprite);
  gsap.to(sprite, { duration: 0.5, pixi: { alpha: 1, scale: 0.7 } });

  wait(2000).then(() => {
    gsap.to(sprite, { duration: 0.5, pixi: { alpha: 0, scale: 0.5 } });
    wait(500).then(() => {
      object.removeChild(sprite);
    });
  });
};

/**
 * Add Particle to Object
 */
Effect.Particle = function (object, image) {
  new Particle(object, [null], {
    lifetime: {
      min: 0.1,
      max: 3,
    },
    frequency: 0.016,
    maxParticles: 22,
    autoUpdate: true,
    addAtBack: true,
    pos: {
      x: 55,
      y: 165,
    },
    behaviors: [
      {
        type: "rotationStatic",
        config: {
          min: 70,
          max: 105,
        },
      },
      {
        type: "alpha",
        config: {
          alpha: {
            list: [
              {
                time: 0,
                value: 0.2,
              },
              {
                time: 1,
                value: 0.1,
              },
            ],
          },
        },
      },
      {
        type: "moveSpeedStatic",
        config: {
          min: 50,
          max: 100,
        },
      },
      {
        type: "scale",
        config: {
          scale: {
            list: [
              {
                time: 0,
                value: 0.9,
              },
              {
                time: 1,
                value: 0.8,
              },
            ],
          },
        },
      },
      {
        type: "animatedSingle",
        config: {
          anim: {
            framerate: 30,
            loop: true,
            textures: [image],
          },
        },
      },
    ],
  });
};

export default Effect;
