import React from "react";
import { Row, Col, Card, Tab, Tabs, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { encode, decode, __, Event, isMobile, isTablet } from "../../Helper";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    window.addEventListener("resize", this.handleResize);

    if (this.props.history) {
      if (this.props.history.location.pathname !== "/") {
        let search = _.replace(
          this.props.history.location.pathname,
          "/search/",
          ""
        );
        Event.emit("back_search", search);
      }
    }

    Event.on("search_key", (key) => {
      this.props.history.push("/search/" + key);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ mobile: isMobile() });

    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;

    if (clientWidth < 1540) {
      this.setState({ col: "col-xl-12" });
    } else {
      this.setState({ col: "col-xl-11" });
    }
  }

  render() {
    const { t } = this.props;

    return <></>;
  }
}

export default Search;
