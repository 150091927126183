import React from "react";
import { Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { decode, isMobile, Event, __ } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import Messages from "./Messages";
import Submit from "./Submit";
import Rain from "./Rain";
import Rules from "./Rules";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      online: 0,
    };
  }

  componentDidMount() {
    const pageContent = document.getElementById("messages");

    socket.emit(C.ONLINE_USERS);

    socket.on(C.ONLINE_USERS, (data) => this.setOnline(decode(data)));

    loadScroll();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setOnline(data) {
    this.setState({ online: data });
  }

  handleResize = () => {
    const clientHeight =
      document.documentElement.clientHeight || document.body.clientHeight;

    if (!isMobile()) {
      this.setState({ height: clientHeight - 230 });
    } else {
      this.setState({ height: clientHeight - 210 });
    }
  };

  closeChatWindow = () => {
    Event.emit("toggle_chat", false);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="chat-window-wrapper">
          <div className="d-flex flex-column h-100">
            <div className="title-box d-flex p-2">
              <div className="d-flex flex-fill gap-2">
                <div className="chat-icon-wrapper d-flex justify-content-center align-items-center">
                  <img src="/assets/images/icons/chat_icon.svg" />
                </div>
                <div className="chat-room-title d-flex justify-content-center align-items-center">
                  <div class="chat-top-online">
                    <div class="chat-top-online-dot"></div>
                    <p>{this.state.online} online</p>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 flex-fill justify-content-end align-items-center">
                <Rules t={t} />
                <button className="btn btn-soft-light btn-xs chat-room-rain-btn add-friends-button">
                  <img
                    className="img-fluid"
                    src="/assets/images/icons/my-friends.png"
                  />
                </button>

                <Rain t={t} />
                <button
                  className={"btn chat-room-back-btn ms-2"}
                  onClick={this.closeChatWindow}
                >
                  <img
                    className="img-fluid"
                    src="/assets/images/icons/chat_back.svg"
                  />
                </button>
              </div>
            </div>
            <div id="messages" className="flex-fill p-3 p-chat">
              <Messages t={t} />
            </div>
            <Submit t={t} />
          </div>
        </div>
      </>
    );
  }
}

function loadScroll() {
  if (document.getElementById("messages") !== null) {
    let ps = new PerfectScrollbar("#messages", {
      wheelSpeed: 1,
      suppressScrollX: true,
      wheelPropagation: true,
      minScrollbarLength: 2,
    });
    ps.update();
  }
}

export default Content;
