import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Sidebar from "./Parts/Sidebar";
import Chatcontent from "./Parts/Chat/chatcontent";
import Footer from "./Parts/Footer";
import socket from "../../Socket";
import {
  Event,
  decode,
  encode,
  wait,
  isMobile,
  isTablet,
  sendNotfication,
} from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import storage from "../../Storage";
import Slots from "../Slots/Slots";
// import Rakeback from "./Parts/Home/Rakeback";
import Searchbar from "./Parts/Header/Searchbar";
import UserHeader from "./Parts/Header/Header-User";
import GuestHeader from "./Parts/Header/Header-Guest";
import Bottom from "./Parts/Header/bottombar";
import { hotjar } from "react-hotjar";
import { withRouter } from 'react-router-dom'; // Ensure you have react-router-dom installed and set up

const PAGE_WINDOW_EXPAND = "layout-page-content-expand";
const PAGE_WINDOW_SHRINK = "layout-page-content-shrink";

class Index extends React.Component {
  on_sport_game = false;
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
      auth: false,
      header: <GuestHeader t={this.props.t} location={this.props.location} />,
      effect:
        isMobile() || isTablet()
          ? "slideOutRight"
          : storage.getKey("chat_view") == "true"
          ? "slideInRight"
          : "slideInRight",
      pageeffect:
        isMobile() || isTablet()
          ? PAGE_WINDOW_EXPAND
          : storage.getKey("chat_view") == "true"
          ? PAGE_WINDOW_SHRINK
          : PAGE_WINDOW_EXPAND,
      sportsPage: props.sports ? true : false,
      // sportsPage: false,
    };

    Event.on("toggle_chat", (show, uncache = false, sports_game = "none") => {
      switch (sports_game) {
        case "on":
          this.on_sport_game = true;
          break;
        case "off":
          this.on_sport_game = false;
        default:
          break;
      }
      if (this.on_sport_game) {
        this.setState({
          effect: "slideOutRight",
          pageeffect: PAGE_WINDOW_EXPAND,
        });
      } else {
        if (!show) {
          this.setState({
            effect: "slideOutRight",
            pageeffect: PAGE_WINDOW_EXPAND,
          });
        } else {
          this.setState({
            effect: "slideInRight",
            pageeffect: PAGE_WINDOW_SHRINK,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.isMobileTablet = isMobile() || isTablet();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // _.some(gamelist, obj => {
    //     return _.some(obj, (val, key) => {
    //         if(key === "prefix") {
    //             if(val === window.location.pathname.replace("/", "")) {
    //                 this.setState({ effect: "slideInRight", pageeffect: PAGE_WINDOW_SHRINK})
    //             }
    //         }
    //     })
    // })

    socket.on(C.ONLINE, (status) => this.onlineUser(decode(status)));

    socket.emit(C.FUN_PLAY);
    socket.on(C.FUN_PLAY, (data) => this.funPlay(decode(data)));

    Event.on("showAuthModal", () => {
      sendNotfication(
        this.props.t("please_login_or_register"),
        "success",
        "bottom-left",
        true
      );
    });

    // Event.on('sportsbooks_page', (value) => {
    //     this.setState({sportsPage: value})
    //     if(this.state.sportsPage == true && value == false) {
    //         Event.emit('toggle_chat', true);
    //     }
    // })

    /**
     * Initialize Authentication
     */
    const sessionCookie = Cookies.get("session");

    if (sessionCookie) {
      socket.emit(
        C.ONLINE,
        encode({
          jwt_token: storage.getKey("jwt_token"), //fake
          user_token: storage.getKey("user_token"), //fake
          security_key: storage.getKey("security_key"), //fake
          secret_user: storage.getKey("secret_user"), //fake
          secret_realtime: storage.getKey("secret_realtime"), //fake
          client_system: storage.getKey("client_system"), //fake
          token_key: storage.getKey("token_key"), //fake
          secret_token: storage.getKey("secret_token"), //fake
        })
      );
    }
    window.addEventListener("resize", this.handleResize);
    const setHeight = (margin) => {
      document.getElementById("chat_div").style.height =
        window.innerHeight - margin + "px";
    };

    let deviceWidth = window.matchMedia("(min-width: 769px)");
    if (deviceWidth.matches) setHeight(75);

    let deviceWidth1 = window.matchMedia(
      "(min-width: 491px) and (max-width: 768px)"
    );
    if (deviceWidth1.matches) setHeight(120);

    let deviceWidth2 = window.matchMedia("(max-width: 490px)");
    if (deviceWidth2.matches) setHeight(108);

    const USER_ID = 4960581;

    const options = {
      id: USER_ID,
      sv: 6,
      debug: true,
    };

    hotjar.initialize(options);

    // Identify the user
    hotjar.identify(USER_ID, { userProperty: "value" });

    // Add an event
    hotjar.event("button-click");

    // Update SPA state
    hotjar.stateChange("/my/page");

    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
      hotjar.identify(USER_ID, { userProperty: "value" });
    }
  }

  handleResize = () => {
    this.setState({ mobile: isMobile() });

    let deviceWidth = window.matchMedia("(min-width: 769px)");
    if (deviceWidth.matches) {
      document.getElementById("chat_div").style.height =
        window.innerHeight - 75 + "px";
    }

    let deviceWidth1 = window.matchMedia(
      "(min-width: 491px) and (max-width: 768px)"
    );
    if (deviceWidth1.matches) {
      document.getElementById("chat_div").style.height =
        window.innerHeight - 120 + "px";
    }

    let deviceWidth2 = window.matchMedia("(max-width: 490px)");
    if (deviceWidth2.matches) {
      document.getElementById("chat_div").style.height =
        window.innerHeight - 108 + "px";
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    socket.off(C.ONLINE);
  }

  activeAuth = (status) => {
    this.setState({ auth: status });
  };

  funPlay = (data) => {
    const { mode } = data;
    storage.setKey("funPlay", mode);
  };

  onlineUser = (data) => {
    wait(500).then(() => {
      if (data.status === true) {
        storage.setKey("logged", true);
        Cookies.set("uid", data.id, { expires: 14 });
        Cookies.set("auth", true, { expires: 14 });
        storage.setKey("name", data.name);
        storage.setKey("email", data.email);
        storage.setKey("credit", data.credit);
        storage.setKey("avatar", data.avatar);
        storage.setKey("friends", data.friends);
        storage.setKey("room", data.room);
        storage.setKey("verified", data.verified);
        wait(100).then(() => {
          this.setState({
            header: (
              <UserHeader t={this.props.t} location={this.props.location} />
            ),
          });
        });
      } else {
        wait(1000).then(() => {
          localStorage.clear();
          this.setState({
            header: (
              <GuestHeader t={this.props.t} location={this.props.location} />
            ),
          });
        });
      }
    });
  };

  render() {
    let { header, mobile } = this.state;
    let { content, t, location } = this.props; // Pages / Games Contents
    const menuBarClass = location.pathname.includes('/sports') ? 'layout-menu-bar-fixed' : 'layout-menu-bar';
    let wallet; // Show Wallet if User don't Have Cash
    try {
      wallet = this.props.get_wallet.show;
    } catch (e) {}
    return (
      <>
        {header}
        {!mobile && <div className="under-top"></div>}
        <div className="page-wrapper row" id="page-wrapper">
          {!mobile && (
            <div className={menuBarClass}>
              <Sidebar t={t} />
            </div>
          )}
          <div
            className={"page-content layout_animated " + this.state.pageeffect}
            id={"page-content"}
          >
            {/* <div className={(this.state.sportsPage? "no-padding": "page-content-map") + (this.props.searchbar ? "" : " page-content-map-non-searchbar")}> */}
            <div
              className={`${
                this.props.sports ? "no-padding" : "page-content-map"
              } ${
                this.props.searchbar ? "" : "page-content-map-non-searchbar"
              }`}
            >
              {wallet && <WalletAlert t={t} uid={this.props.get_wallet.uid} />}
              {this.props.searchbar ? <Searchbar t={t} /> : <></>}
              {this.props.get_search ? (
                <Slots
                  t={t}
                  search={this.props.get_search}
                  mobile={isMobile()}
                />
              ) : (
                content
              )}
            </div>
            {/* {!this.state.sportsPage ? <Footer t={t} /> : ''} */}
            {!this.props.sports ? <Footer t={t} /> : ""}
          </div>
          <div
            id={"chat_div"}
            className={"pt-3 layout-chat-content animated " + this.state.effect}
          >
            <Chatcontent t={t} />
          </div>
        </div>
        <Bottom t={t} />
      </>
    );
  }
}

Index.propTypes = {
  get_wallet: PropTypes.string,
  get_search: PropTypes.string,
};

const mapStateToProps = (state) => ({
  get_wallet: state.items.get_wallet,
  get_search: state.search.search_game,
});

export default withRouter(connect(mapStateToProps, null)(Index));
