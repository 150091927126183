import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import UserModal from "../../Components/User/Stat/Modal";
import { encode, decode, wait, getSingleRandomInt } from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [
        {
          id: 1,
          avatar: false,
          name: "sakultah",
          refferals: "4.562",
          deposits: "354.565.45",
          earned: "53.184.75",
        },
        {
          id: 2,
          avatar: false,
          name: "Hidden",
          refferals: "4.111",
          deposits: "275.222.11",
          earned: "41.283.32",
        },
        {
          id: 3,
          avatar: false,
          name: "oblivionsw",
          refferals: "2.995",
          deposits: "195.441.76",
          earned: "29.316.15",
        },
        {
          id: 4,
          avatar: false,
          name: "Maxi883",
          refferals: "2.014",
          deposits: "114.899.72",
          earned: "17.234.85",
        },
        {
          id: 5,
          avatar: false,
          name: "tqh104d55q52",
          refferals: "1.233",
          deposits: "99.225.45",
          earned: "14.883.75",
        },
        {
          id: 6,
          avatar: false,
          name: "Hidden",
          refferals: "566",
          deposits: "54.445.99",
          earned: "8.166.75",
        },
        {
          id: 7,
          avatar: false,
          name: "4e2y2il4ccfg",
          refferals: "449",
          deposits: "32.777.91",
          earned: "4.916.55",
        },
        {
          id: 8,
          avatar: false,
          name: "xxxxxx88",
          refferals: "375",
          deposits: "28.546.54",
          earned: "4.281.91",
        },
        {
          id: 9,
          avatar: false,
          name: "ETHero",
          refferals: "299",
          deposits: "28.003.57",
          earned: "4.200.45",
        },
        {
          id: 10,
          avatar: false,
          name: "Spinner1",
          refferals: "251",
          deposits: "17.549.00",
          earned: "2.632.35",
        },
      ],
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { t } = this.props;

    console.log(t)

    const list = this.state.list.map((row, i) => (
      <CreateTable key={i} user={row} place={i} />
    ));

    return (
      <Card className="m-2">
        <Card.Body className="affiliatebox">
          <div className="table-responsive num-style mb-0 tww">
            {this.state.loading ? (
              <div className="text-center">
                <div className="spinner-border text-info my-3" role="status" />
              </div>
            ) : (
              <>
                {list.length === 0 ? (
                  <>{t("you_have_not_introduced_anyone_yet")}</>
                ) : (
                  <Table
                    className={"mb-2 affiliatetable table table-striped mb-0"}
                  >
                    <thead>
                      <tr>
                        <th className="bg-transparent text-white">{t('place')}</th>
                        <th className="bg-transparent text-white">{t('username')}</th>
                        <th className="bg-transparent text-white">{t('refferals')}</th>
                        <th className="bg-transparent text-white">{t('deposits')}</th>
                        <th className="bg-transparent text-white">
                        {t('earned')} (15%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>{list}</tbody>
                  </Table>
                )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

class CreateTable extends React.Component {
  render() {
    var { user, place } = this.props;
    place += 1;

    return (
      <tr>
        <td className="bg-transparent text-white-50">{place}</td>
        <td className="bg-transparent text-white-50">
          <UserModal
            id={user.uid}
            avatar={user.avatar}
            username={user.name}
            cssClass="user-avatar font-14 text-muted"
          />
        </td>
        <td className="bg-transparent text-white-50">{user.refferals}</td>
        <td className="bg-transparent text-white-50">${user.deposits}</td>
        <td className="bg-transparent text-white-50">${user.earned}</td>
      </tr>
    );
  }
}

export default List;
