import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Stack,
  Card,
  Nav,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  __,
  wait,
  Event,
  isMobile,
  decode,
  encode,
  filterSlotTitle,
} from "../../Helper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import socket from "../../Socket";
import storage from "../../Storage";
import C from "../../Constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchBar } from "../../actions/searchGame";
import { livegameSlots } from "../../actions/liveSlots";
import { useTranslation } from "react-i18next";
import { bool } from "prop-types";
import Favorites from "../Pages/Parts/Home/Favorites";
import { CheckboxDropdown } from "../Components/CheckboxDropdown";
import { RadioboxDropdown } from "../Components/RadioboxDropdown";

function Slots(props) {
  const { t } = useTranslation();
  return <Slotscom {...props} t={t} />;
}

class Slotscom extends React.Component {
  constructor(props) {
    super(props);
    this._slots = [];
    this.searched_slots = [];
    this._specific_slots = [];
    this._isMounted = false;
    this.GET_SPECIFIC_SLOTS = "";
    this.ADD_SPECIFIC_SLOTS = "";
    this.slots_title = "";
    this.slots_specific_icon = "";
    this.state = {
      bMobile: isMobile(),
      games: [],
      loader: false,
      disabled: false,
      search: false,
      pageIndex: 1,
      pageSize: this.props.specific ? 21 : 49,
      loading: false,
      chat_show: true,
    };
    if (this.props.live) {
      this.GET_SPECIFIC_SLOTS = C.GET_FEATURED_SLOTS;
      this.ADD_SPECIFIC_SLOTS = C.ADD_FEATURED_SLOTS;
      this.slots_title = "game_shows";
      this.slots_specific_icon = "/assets/images/icons/slot-game.png";
    }
    if (this.props.specific) {
      switch (this.props.specific) {
        case "popular_game":
          this.GET_SPECIFIC_SLOTS = C.GET_POPULAR_SLOTS;
          this.ADD_SPECIFIC_SLOTS = C.ADD_POPULAR_SLOTS;
          this.slots_title = this.props.specific;
          this.slots_specific_icon = "/assets/images/icons/slot-game.png";
          break;
        case "top_game":
          this.GET_SPECIFIC_SLOTS = C.GET_TOP_SLOTS;
          this.ADD_SPECIFIC_SLOTS = C.ADD_TOP_SLOTS;
          this.slots_title = this.props.specific;
          this.slots_specific_icon = "/assets/images/icons/slot-game.png";
          break;
        case "featured_game":
          this.GET_SPECIFIC_SLOTS = C.GET_FEATURED_SLOTS;
          this.ADD_SPECIFIC_SLOTS = C.ADD_FEATURED_SLOTS;
          this.slots_title = "game_shows";
          this.slots_specific_icon = "/assets/images/icons/slot-game.png";
          break;
        case "pragmatic_play":
          this.GET_SPECIFIC_SLOTS = C.GET_FEATURED_SLOTS;
          this.ADD_SPECIFIC_SLOTS = C.ADD_PRAGMATIC_SLOTS;
          this.slots_title = "pragmatic_play";
          this.slots_specific_icon = "/assets/images/icons/slot-game.png";
          break;
        default:
          break;
      }
    }
  }

  filter_item = storage.getKey("live_game_filter")
    ? storage.getKey("live_game_filter")
    : "lobby";

  componentDidMount() {
    this._isMounted = true;

    this.setState({ games: [] });
    this.bMobile = isMobile();

    if (this.props.hasOwnProperty("b_sort")) {
      Event.on("get_sorted_slots", (sortObj) => {
        socket.off(C.GET_SORTED_SLOTS);
        socket.emit(C.GET_SORTED_SLOTS, encode(sortObj));
        socket.on(C.GET_SORTED_SLOTS, (data) => {
          this._slots = decode(data).length > 0 ? decode(data) : [];
          this.props.completedBSort();
          this.setState({ pageIndex: 1 });
          this.setupGames();
        });
      });
    }

    socket.on(C.GET_LOADER_SLOTS, (data) => {
      this.addGames(decode(data));
    });

    if (this.props.home) {
      switch (this.props.specific) {
        case "top_game":
          this._specific_slots = this.props.topSlotsRedux || [];
          if (this.props.items)
            this._specific_slots = __.take(
              this._specific_slots,
              this.props.items
            );
          else
            this._specific_slots = this.props.topSlotsRedux?.slice(0, 21) || [];
          this.setupGames();
          break;
        case "popular_game":
          this._specific_slots =
            this.props.popularSlotsRedux?.slice(0, 21) || [];
          this.setupGames();
          break;
        case "featured_game":
          this._specific_slots = this.props.liveSlotsRedux?.slice(0, 21) || [];
          this.setupGames();
          break;
        case "pragmatic_play":
          this._specific_slots =
            this.props.pragmaticSlotsRedux?.slice(0, 21) || [];
          this.setupGames();
          break;
        default:
          break;
      }
    } else if (this.props.search) {
      this.setState({ games: [], search: true });
      let self = this;
      get_searched_slots_fun(this.props.search, this.props.mobile, self);
    } else if (this.props.live) {
      this._slots = this.props.liveSlotsRedux || [];
      this.setupGames();
      if (this._slots.length == 0 || this.filter_item != "lobby") {
        socket.emit(
          C.GET_FEATURED_SLOTS,
          encode({
            field: "featured_game",
            pageIndex: 1,
            pageSize: 49,
            filter: this.filter_item,
          })
        );
        this.setState({ loading: true });
      }
      socket.on(C.GET_FEATURED_SLOTS, (data) => {
        let decoded_live_games = decode(data);
        this._slots = decoded_live_games;
        this.setupGames();
      });
    } else {
      this._slots = this.props.slotsRedux || [];
      this.setState({ loading: true });
      socket.on(C.GET_SORTED_SLOTS, (data) => {
        this._slots = decode(data).length > 0 ? decode(data) : [];
        this.props.completedBSort();
        this.setState({ pageIndex: 1 });
        this.setupGames();
      });
    }

    window.addEventListener("resize", this.handleResize);

    Event.on("toggle_chat", (show) => {
      wait(200).then(() => {
        this.handleResize(show);
      });
    });

    socket.on(C.GET_SEARCHED_SLOTS, (data) => {
      this.searched_slots = decode(data);
      this.searched_slots = _.xor(this.searched_slots);
      if (__.isUndefined(this.searched_slots)) return;
      this.setState((state) => ({
        games: JSON.parse(JSON.stringify(this.searched_slots)),
        search: true,
      }));
      this.setupGames();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      if (this.props.home) return;

      let self = this;
      if (this.props.search !== null && this.props.search !== "") {
        get_searched_slots_fun(this.props.search, this.props.mobile, self);
      } else {
        this.setState({ search: false });
        this.setupGames();
      }
    }

    if (
      this.props.hasOwnProperty("b_sort") &&
      this.props.b_sort !== prevProps.b_sort
    ) {
      this.setState({ loading: this.props.b_sort });
    }

    if (this.props.home) {
      if (
        this.props.topSlotsRedux !== prevProps.topSlotsRedux &&
        this.props.specific == "top_game"
      ) {
        if (this.props.items)
          this.setState({
            games: __.take(this.props.topSlotsRedux, this.props.items) || [],
            loading: false,
          });
        else
          this.setState({
            games: this.props.topSlotsRedux?.slice(0, 21) || [],
            loading: false,
          });
      }
      if (
        this.props.popularSlotsRedux !== prevProps.popularSlotsRedux &&
        this.props.specific == "popular_game"
      ) {
        this.setState({
          games: this.props.popularSlotsRedux?.slice(0, 21) || [],
          loading: false,
        });
      }
      if (
        this.props.liveSlotsRedux !== prevProps.liveSlotsRedux &&
        this.props.specific == "featured_game"
      ) {
        this.setState({
          games: this.props.liveSlotsRedux?.slice(0, 21) || [],
          loading: false,
        });
      }
      if (
        this.props.pragmaticSlotsRedux !== prevProps.pragmaticSlotsRedux &&
        this.props.specific == "pragmatic_play"
      ) {
        this.setState({
          games: this.props.pragmaticSlotsRedux?.slice(0, 21) || [],
          loading: false,
        });
      }
    } else if (this.props.live) {
    } else {
      if (this.props.slotsRedux !== prevProps.slotsRedux) {
        this.setState({ games: this.props.slotsRedux || [], loading: false });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.GET_SEARCHED_SLOTS);
    // socket.off(C.GET_LOADER_SLOTS);  //This remover is called in Slots/index.js
    socket.off(C.GET_POPULAR_SLOTS);
    socket.off(C.ADD_POPULAR_SLOTS);
    socket.off(C.GET_TOP_SLOTS);
    socket.off(C.ADD_TOP_SLOTS);
    socket.off(C.GET_FEATURED_SLOTS);
    socket.off(C.ADD_FEATURED_SLOTS);
    socket.off(C.ADD_PRAGMATIC_SLOTS);
    // socket.off(C.GET_SORTED_SLOTS);  //This remover is called in Slots/index.js
    window.removeEventListener("resize", this.handleResize());
  }

  handleResize = (chat_show) => {
    this.bMobile = isMobile();
    const pageContent = document.getElementById("page-content");

    if (pageContent !== null) {
      const width = parseFloat(pageContent.offsetWidth);
      if (width < 630) {
        this.setState({ chat_show: chat_show, bMobile: true });
      } else {
        this.setState({ chat_show: chat_show, bMobile: isMobile() });
      }
    }
  };

  setupGames() {
    if (this.props.home) {
      //for Specific Slots Games in Home Page.
      this.setState((state) => ({
        games: [...this._specific_slots],
        loading: false,
      }));
    } else if (this.props.search) {
      this.setState((state) => ({
        games: [...this.searched_slots],
        loading: false,
      }));
    } else if (this.props.live) {
      this.setState((state) => ({ games: [...this._slots], loading: false }));
    } else {
      //Slots Games under Slots Side Bar Menu.
      this.setState((state) => ({ games: [...this._slots], loading: false }));
    }
  }

  loadMore = (e) => {
    this.setState({ loader: true, disabled: true });
    if (this.props.specific) {
      let slotPageInfo = {
        field: this.props.specific,
        pageIndex: this.state.pageIndex + 1,
        pageSize: this.state.pageSize,
      };
      return socket.emit(this.ADD_SPECIFIC_SLOTS, encode(slotPageInfo));
    }
    if (this.props.live) {
      let slotPageInfo = {
        field: "featured_game",
        pageIndex: this.state.pageIndex + 1,
        pageSize: this.state.pageSize,
        filter: this.filter_item,
      };
      return socket.emit(this.ADD_SPECIFIC_SLOTS, encode(slotPageInfo));
    }

    let slotPageInfo = {
      pageIndex: this.state.pageIndex + 1,
      pageSize: this.state.pageSize,
      sortObj: this.props.sortObj,
    };
    socket.emit(C.GET_LOADER_SLOTS, encode(slotPageInfo));
  };

  addGames(games) {
    let add = this.state.games.concat(games);
    this.setState({
      games: add,
      pageIndex: this.state.pageIndex + 1,
      loader: false,
      disabled: false,
    });
  }

  getGame(slot_provider) {
    this.props.searchBar("");
    Event.emit("toggle_chat", false, true);
    storage.setKey("slot_provider", slot_provider);
  }

  closeSearch() {
    Event.emit("clear_search");
  }

  render() {
    const { t, auto_scroll_load } = this.props;

    const games = this.state.games?.map((item, i) => (
      <Col
        key={i.toString()}
        className={
          this.state.chat_show
            ? "game-items game-in-shrink"
            : "game-items game-in-expand"
        }
        style={{ maxWidth: "14.2%" }}
        onClick={() => this.getGame(item.provider)}
      >
        <Link
          onClick={this.closeSearch}
          to={item.name !== null ? "/slots/" + item.uuid : "#"}
          className="slot-link slot-pan game-card-wrapper"
        >
          <div
            className={item.name !== null ? "slot-image" : "slot-image orange"}
          >
            <img src={item.origin_url} className="img-fluid" alt={item.name} />
          </div>
          <div className="slot-title">
            <span className="slot-t-name">{filterSlotTitle(item.name)}</span>
            <span className="slot-t-provider">{item.provider}</span>
          </div>
        </Link>
      </Col>
    ));

    return (
      <>
        {this._isMounted && (
          <>
            {this.state.loading && !this.props.live ? (
              <>
                <div className="text-center">
                  <div className="spinner-grow text-white my-5" role="status" />
                </div>
              </>
            ) : (
              <>
                {this.slots_title && (
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="home-game-title slotsside"
                  >
                    <div className="d-flex align-items-center">
                      <img
                        className="img-fluid home-page-icons"
                        src={this.slots_specific_icon}
                      />
                      <p className="m-0 home-part-t">
                        {" "}
                        {this.slots_title != "pragmatic_play"
                          ? t(this.slots_title)
                          : "Pragmatic Play"}
                      </p>
                    </div>
                  </Stack>
                )}

                {!this.props.live ? ( //Slots Side Menu and Searched games
                  <Row id="allslots" className="d-flex game-cards m-0 rowgames">
                    {games.length > 0 ? (
                      games
                    ) : this.props.search ? (
                      <p className="not-found">{t("no_result_found")}.</p>
                    ) : (
                      <></>
                    )}
                  </Row>
                ) : //Live Side Menu
                this.state.loading ? (
                  <div className={"text-center search-text-loader"}>
                    <div
                      className="spinner-grow text-white my-1"
                      role="status"
                    />
                  </div>
                ) : games.length > 0 ? (
                  <Row id="allslots" className="d-flex game-cards m-0 rowgames">
                    {games}
                  </Row>
                ) : (
                  <></>
                )}

                {!this.state.search && !this.props.items && (
                  <div className="text-center mt-3">
                    {this.state.pageIndex <
                    Math.ceil(
                      this.state.games.length > 0
                        ? this.state.games[0].total_rows / this.state.pageSize
                        : 1
                    ) ? (
                      <>
                        <div className="slot-load-pages">
                          <span className="px-2 py-1 font-12">
                            {this.state.pageIndex} / &nbsp;
                            {Math.ceil(
                              this.state.games.length > 0
                                ? this.state.games[0].total_rows /
                                    this.state.pageSize
                                : 1
                            )}
                          </span>
                          <ProgressBar
                            now={
                              Math.trunc(
                                (this.state.pageIndex /
                                  Math.ceil(
                                    this.state.games.length > 0
                                      ? this.state.games[0].total_rows /
                                          this.state.pageSize
                                      : 1
                                  )) *
                                  100
                              ) == 0
                                ? 1
                                : Math.trunc(
                                    (this.state.pageIndex /
                                      Math.ceil(
                                        this.state.games.length > 0
                                          ? this.state.games[0].total_rows /
                                              this.state.pageSize
                                          : 1
                                      )) *
                                      100
                                  )
                            }
                          />
                          <span className="px-2 py-1 font-12">
                            {(Math.trunc(
                              (this.state.pageIndex /
                                Math.ceil(
                                  this.state.games.length > 0
                                    ? this.state.games[0].total_rows /
                                        this.state.pageSize
                                    : 1
                                )) *
                                100
                            ) == 0
                              ? 1
                              : Math.trunc(
                                  (this.state.pageIndex /
                                    Math.ceil(
                                      this.state.games.length > 0
                                        ? this.state.games[0].total_rows /
                                            this.state.pageSize
                                        : 1
                                    )) *
                                    100
                                )) + "%"}
                          </span>
                        </div>
                        <button
                          id="load_more_game_btn"
                          disabled={this.state.disabled}
                          type="button"
                          className="m-auto shadow-none animated fadeIn"
                          onClick={this.loadMore}
                        >
                          {this.state.loader ? (
                            <div className="spinner-grow spinner-grow-sm align-middle" />
                          ) : (
                            t("load_more_games")
                          )}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

let get_searched_slots_fun = function (search_text, isMobile, self) {
  self.setState({ loading: true });
  debounce_get_searched_slots_fun(search_text, isMobile, self);
};

let debounce_get_searched_slots_fun = __.debounce(function (
  search_text,
  isMobile,
  self
) {
  socket.emit(
    C.GET_SEARCHED_SLOTS,
    encode({ param: __.toLower(search_text), isMobile: isMobile })
  );
},
1000);

const mapStateToProps = (state) => ({
  slotsRedux: state.items.slots,
  liveSlotsRedux: state.items.liveSlots,
  popularSlotsRedux: state.items.popularSlots,
  topSlotsRedux: state.items.topSlots,
  pragmaticSlotsRedux: state.items.pragmatic_slots,
});

export default connect(mapStateToProps, { searchBar, livegameSlots })(Slots);
